import "../unconnected.css";
import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { globalConstants } from "../../../app/constants";
import { connect } from "react-redux";

import PasswordField from "../../../components/form/field/PasswordField";
import ButtonField from "../../../components/form/field/ButtonField";
import { withTranslation } from "react-i18next";
import { PLAN_UGRAPDE, ROUTES } from "../../../app/routing";
import HeaderUnconnected from "../../../components/header/HeaderUnconnected";
import FooterUnconnected from "../../../components/footer/FooterUnconnected";
import StringHelper from "../../../app/helpers/StringHelper";
import HttpHelper from "../../../app/helpers/HttpHelper";
import SignupHelper from "../../../app/helpers/SignupHelper";
import LoadingView from "../../loading/LoadingView";
import { userService } from "../../../app/services";

class SignupStep4View extends React.Component {
  constructor(props) {
    super(props);
    const urlParams = HttpHelper.getUrlParameters(props.location.search);
    const plan = urlParams.plan || null;
    const freq = urlParams.freq || null;
    SignupHelper.deleteUser();
    this.state = {
      isLoaded: false,
      formData: {
        password: "",
        token: urlParams.token ? urlParams.token : null,
      },
      plan: plan,
      freq: freq,
      formErrors: null,
      loading: false,
    };

    this.checkToken = this.checkToken.bind(this);
    this.formIsValid = this.formIsValid.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getErrorMessage = this.getErrorMessage.bind(this);
  }

  componentDidMount() {
    this._ismounted = true;
    this.checkToken();
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  checkToken() {
    const token = HttpHelper.getUrlParameters(this.props.location.search);
    userService.checkValidEmailToken(
      token.token,
      (response) => {
        this.setState({ isLoaded: true });
      },
      (error) => {
        this.props.history.push(ROUTES.HOME);
      }
    );
  }

  handleChange(event) {
    const { t } = this.props;
    let formData = Object.assign({}, this.state.formData);
    formData[event.target.name] = event.target.value;
    let formErrors = null;
    if (
      event.target.name === "password" &&
      !StringHelper.isPasswordValid(event.target.value)
    ) {
      formErrors = { password: t("Please enter a valid password") };
    }
    this.setState({
      formData: formData,
      formErrors: formErrors,
    });
  }

  formIsValid() {
    return this.state.formErrors == null;
  }

  handleSubmit(event) {
    if (this.formIsValid()) {
      this.setState({ loading: true });
      userService.passwdSetNew(
        this.state.formData,
        (response) => {
          if (this.state.plan) {
            const url = this.state.freq
              ? HttpHelper.addParametersToUrl(PLAN_UGRAPDE(this.state.plan), {
                  freq: this.state.freq,
                })
              : PLAN_UGRAPDE(this.state.plan);
            this.props.history.push(url);
          } else {
            this.props.history.push(ROUTES.HOME);
          }
        },
        (error) => {
          this.setState({
            loading: false,
            formErrors: HttpHelper.getErrorMessage(error),
          });
        }
      );
    }
    event.preventDefault();
  }

  getErrorMessage(target) {
    if (this.state.formErrors && this.state.formErrors.hasOwnProperty(target)) {
      return this.state.formErrors[target];
    }
    return null;
  }

  render() {
    const { t } = this.props;
    let formElementClassName = this.state.error ? "error" : "";
    let formClassName = this.state.loading ? "loading-form" : "";
    let page_title = `${t("Create your password")} - ${
      globalConstants.WEBSITE_TITLE
    }`;
    if (!this.state.isLoaded) {
      return <LoadingView title={page_title} />;
    }
    return (
      <Fragment>
        <Helmet>
          <title>{page_title}</title>
        </Helmet>
        <div className="unconnected-content double-percent-50">
          <div className="content-white">
            <HeaderUnconnected />
            <div className="content-max-width-590 min-height-100vh margin-auto">
              <div className="c-content-table">
                <div className="c-content-table-cell">
                  <div className="centralized-content">
                    <div className="font-inter-700 font-size-36 text-align-center color-0e004b padding-bottom-50">
                      {t("Create your password")}
                    </div>
                    <form
                      onSubmit={this.handleSubmit}
                      className={formClassName}
                    >
                      <PasswordField
                        label={t("Password")}
                        name="password"
                        id="new-password"
                        autoComplete="new-password"
                        required={true}
                        disabled={this.state.loading}
                        value={this.state.formData.password}
                        onChange={this.handleChange}
                        withForceHelper={true}
                        errorMessage={this.getErrorMessage("password")}
                        formElementClassName={formElementClassName}
                      />
                      <div className="padding-top-20" />
                      <ButtonField
                        type="submit"
                        value={t("Validate")}
                        disabled={this.state.loading}
                        loading={this.state.loading}
                        className="font-work-sans-600 width-170 height-45"
                        formElementClassName="padding-vertical-20 text-align-center"
                      />
                      <div className="padding-bottom-30" />
                    </form>
                  </div>
                </div>
              </div>
              <FooterUnconnected />
            </div>
          </div>
          <div className="content-blue background-color-fff width-percent-40">
            <div className="icon pw-lock" />
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withTranslation()(connect(mapStateToProps)(SignupStep4View));
