import '../plan.css';
import './downgradeResultView.css';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { getAccountPlan, getPlanAdvantages, globalConstants } from "../../../app/constants";
import { connect } from 'react-redux';
import LoadingView from '../../loading/LoadingView';
import { withTranslation } from 'react-i18next';
import { ROUTES } from '../../../app/routing';
import Check from "../../../res/images/icons/check.svg"


import HttpHelper from '../../../app/helpers/HttpHelper';
import UserHelper from '../../../app/helpers/UserHelper';

import PlanHeader from '../elements/PlanHeader';
import { SessionStorageService } from '../../../app/services/storage.service';
import Link from '../../../components/Link';

class DowngradeResultView extends React.Component {
    constructor(props) {
        super(props);

        const targetPlan = getAccountPlan(props.match.params.target_plan);
        const urlParams = HttpHelper.getUrlParameters(props.location.search);
        const status = urlParams.status || 'error';
        const message = urlParams.message || null;
        const referrer = (props.location.state && props.location.state.referrer) ? props.location.state.referrer : null;
        this.state = {
            isLoaded: false,
            targetPlan: targetPlan,
            referrer: referrer,
            order: {
                plan: targetPlan.toLowerCase(),
                status: status,
                message: message
            }
        };
        this.fetchInitial = this.fetchInitial.bind(this);
        this.getTargetPlanAdvantages = this.getTargetPlanAdvantages.bind(this);
        if (!SessionStorageService.get('update_plan_referrer') && referrer) {
            SessionStorageService.set('update_plan_referrer', referrer)
        }
    }

    componentDidMount() {
        this._ismounted = true;
        this.fetchInitial();
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    fetchInitial() {
        const isAdmin = UserHelper.isAdmin();
        if (!isAdmin) {
            this.props.history.push(ROUTES.PLAN_CONTACT_YOUR_ADMINISTRATOR);
        }
        if (this.state.order.status === 'error' && !this.state.order.message) {
            this.props.history.push(ROUTES.HOME);
        }
        this.setState({ isLoaded: true });
    }

    getTargetPlanAdvantages() {
        const { t } = this.props;
        const targetPlanKey = this.state.targetPlan;
        const planAdvantages = getPlanAdvantages(t);
        return planAdvantages[targetPlanKey.toLowerCase()].advantages;
    }


    render() {
        const { t } = this.props;
        let page_title = `${t('Switch to {{plan_libelle}} plan', { plan_libelle: this.state.targetPlan })} - ${globalConstants.WEBSITE_TITLE}`;
        if (this.state.isLoaded) {
            const orderStatus = this.state.order.status;
            const bannerTitle = (orderStatus === 'success') ? t('You have been downgraded to {{plan_libelle}} plan', { plan_libelle: this.state.targetPlan }) : t('Something went wrong');
            const targetPlanAdvantages = this.getTargetPlanAdvantages();
            return (
                <Fragment>
                    <Helmet>
                        <title>{page_title}</title>
                    </Helmet>
                    <PlanHeader history={this.props.history} title={t('Switch to')} boldTitle={t('{{plan}} plan', { plan: this.state.targetPlan })} />
                    <div className={`central-content purchase-result ${orderStatus}`}>
                        <div className="purchase-banner">
                            {bannerTitle}
                        </div>
                        <div className="purchase-box-container">
                            <div className="content-column padding-left-56">
                                <div className={(orderStatus === 'success') ? 'column-percent-50 responsive-900' : ''}>
                                    <div className="padding-top-20" />
                                    {orderStatus === 'success' && (
                                        <Fragment>
                                            <div className="font-size-13 color-7f7f7f line-height-1-5 padding-bottom-20 font-weight-500">
                                                {t('By downgrading to {{plan}} plan you will no longer get to enjoy the features that you love. To see what other plans give you, view ', { plan: this.state.targetPlan })}
                                                <a rel="noreferrer" target={'_blank'} href={`${globalConstants.SHOWCASE_URL}/pricing`} className="link-blue text-decoration-underline">{t('our features')}</a>.
                                            </div>
                                            <div className="content-advantages no-margin-left no-height">
                                                {targetPlanAdvantages.map((element, y) => {
                                                    return (
                                                        <div key={element.title + y} className={`advantages-box ${y < (targetPlanAdvantages.length - 1) && 'padding-bottom-20'}`}>
                                                            <div className="title">{element.title}:</div>
                                                            {element.advantage.map((adv, i) => {
                                                                return (
                                                                    <div key={adv + i} className="raw-advantage">
                                                                        <img className="icon-check" src={Check} alt="" />
                                                                        <div className="advantage-lst">{adv}</div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </Fragment>
                                    )}
                                    {orderStatus === 'error' && (
                                        <Fragment>
                                            <div className="error-line">
                                                {this.state.order.message}
                                            </div>
                                        </Fragment>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="text-align-center padding-top-30">
                            {orderStatus === 'success' && <Link className="button blue" to={ROUTES.HOME}>{t('Continue')}</Link>}
                            {orderStatus === 'error' && !!this.state.referrer && <Link className="button blue" to={this.state.referrer}>{t('Go back')}</Link>}
                            {orderStatus === 'error' && !this.state.referrer && <Link className="button blue" to={ROUTES.HOME}>{t('Go home')}</Link>}
                        </div>
                    </div>
                    <div className="padding-top-30" />
                </Fragment>
            );
        }
        return <LoadingView title={page_title} />;
    }
}

function mapStateToProps(state) {
    return {};
}

export default withTranslation()(connect(mapStateToProps)(DowngradeResultView));