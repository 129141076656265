import React from 'react';
import './errors.css';
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { globalConstants } from '../../app/constants';
import Header from "../../components/header/Header";
import HeaderUnconnected from "../../components/header/HeaderUnconnected";
import Lottie from 'react-lottie-player';

import catLottieJson from '../../res/lotties/cat.json';
import Link from "../../components/Link";
import { ROUTES } from '../../app/routing';

class Error500View extends React.Component {

    componentDidMount() {
    }

    render() {
        const { t, user } = this.props;
        let page_title = `${t('Internal Server Error')} - ${globalConstants.WEBSITE_TITLE}`;
        return (
            <div className="page-not-found content-error-to-center" style={{ paddingTop: "70px" }}>
                {user && <Header errorPage={true} />}
                {!user && <HeaderUnconnected className="absolute" />}
                <Helmet>
                    <title>{page_title}</title>
                </Helmet>
                <div className="centered">
                    <Lottie
                        loop
                        animationData={catLottieJson}
                        play
                        style={{ width: 300, height: 300, margin: "0 auto" }}
                    />
                    <div className="padding-top-0 font-size-60 font-inter-700 color-000 line-height-1">{t('500')}</div>
                    <div className="font-size-36 font-inter-400 color-000">{t('Internal Server Error')}</div>
                    <div className="padding-top-5 font-size-16 font-inter-300 color-000">{t('Arf, the page you are looking seems to be broken!')}</div>
                    <div className="padding-top-20">
                        <Link to={ROUTES.HOME} className="button">{t('Return to homepage')}</Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Error500View);