import React, { Fragment } from "react";
import UserHelper from "../../../../app/helpers/UserHelper";
import StripeForm from "../../../../components/stripe/StripeForm";
import { billingService } from "../../../../app/services/billing.service";
import { withTranslation } from 'react-i18next';
import SettingsPaymentMethodListItem from "../../../../components/list/SettingsPaymentMethodListItem";
import '../../settings.css';
import HttpHelper from "../../../../app/helpers/HttpHelper";
import DeletePaymentMethodModal from "../modals/DeletePaymentMethodModal";
import { accountService } from "../../../../app/services/account.service";
import { ROUTES } from "../../../../app/routing";


class PaymentMethodsTab extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            paymentMethodList: [],
            itemIsLoading: {},
            account: null,
            delete: {
                display: false,
                item: null,
                isLast: false,
                loading: false,
                error: null
            },
        }

        this.fetchInitial = this.fetchInitial.bind(this);
        this.reloadData = this.reloadData.bind(this);
        this.fetchPaymentMethod = this.fetchPaymentMethod.bind(this);
        this.addPaymentMethodSuccess = this.addPaymentMethodSuccess.bind(this);
        this.chooseDefault = this.chooseDefault.bind(this);
        this.onCloseDeleteModal = this.onCloseDeleteModal.bind(this);
        this.onDeleteItem = this.onDeleteItem.bind(this)
        this.removeCard = this.removeCard.bind(this)
    }

    componentDidMount() {
        this._ismounted = true;
        this.fetchInitial()
    }

    componentWillUnmount () {
        this._ismounted = false;
    }

    fetchInitial() {
        if (this._ismounted) {
            this.setState({ inlineLoading: true });
        }
        const isAdmin = UserHelper.isAdmin();
        if (!isAdmin) {
            this.props.history.push(ROUTES.PLAN_CONTACT_YOUR_ADMINISTRATOR);
        }
        accountService.getAccountData(
            (response) => {

                this.setState({
                    account: response
                });

                this.reloadData()
            },
            (error) => {
                if(!this._ismounted){
                    return;
                }
                this.props.addToast(HttpHelper.getErrorMessage(error), { type: 'error', autoDismiss: true });
            }
        );
    }

    addPaymentMethodSuccess() {
        this.props.addToast("Payment method successfully added", { type: 'success', autoDismiss: true });
        this.reloadData()
    }

    reloadData() {
        
        this.fetchPaymentMethod();
    }

    fetchPaymentMethod() {
        billingService.getPaymentMethod(
            (response) => {
                this.setState({ paymentMethodList: response.data });
            }
        );
    }


    chooseDefault(item) {
        const { t } = this.props;
        let itemIsLoading = Object.assign({}, this.state.itemIsLoading);
        itemIsLoading[item.id] = true;
        this.setState({ itemIsLoading: itemIsLoading });
        billingService.setDefaultPaymentMethod(
            item.id,
            (response) => {
                let newItemIsLoading = Object.assign({}, this.state.itemIsLoading);
                delete newItemIsLoading[item.id];
                this.setState({ itemIsLoading: newItemIsLoading });
                this.fetchPaymentMethod();
                if (this.props.addToast) {
                    this.props.addToast(t('Default payment method successfully updated'), { type: 'success', autoDismiss: true });
                }
                this.props.fetchAccount()
            },
            (error) => {
                if(!this._ismounted){
                    return;
                }
                let errorItemIsLoading = Object.assign({}, this.state.itemIsLoading);
                delete errorItemIsLoading[item.id];
                this.setState({ itemIsLoading: errorItemIsLoading });
                this.fetchPaymentMethod();
                if (this.props.addToast) {
                    this.props.addToast(HttpHelper.getErrorMessage(error), { type: 'error', autoDismiss: true });
                }
            }
        );
    }

    removeCard(item) {
        this.setState({
            delete: {
                display: true,
                item: item,
                isLast: false,
                loading: false,
                error: null
            }
        });
    }

    onCloseDeleteModal() {
        this.setState({
            delete: {
                display: false,
                item: null,
                isLast: false,
                loading: false,
                error: null
            }
        });
    }

    onDeleteItem() {
        
        const { t } = this.props;
        let deleteData = Object.assign({}, this.state.delete);
        deleteData.loading = true;
        this.setState({ delete: deleteData });

        billingService.deletePaymentMethod(
            deleteData.item.id,
            (response) => {
                this.onCloseDeleteModal();
                this.fetchPaymentMethod();
                this.props.addToast(t('Payment method successfully deleted'), { type: 'success', autoDismiss: true });
            },
            (error) => {
                deleteData.loading = false;
                deleteData.error = HttpHelper.getErrorMessage(error);
                this.setState({
                    delete: deleteData
                });
            }
        );
    }


    render() {

        const { t } = this.props;
        const { paymentMethodList } = this.state;
        const hasCards = paymentMethodList.length > 0;

        return (
            <Fragment>
                <div className="font-inter-700 font-size-18 color-000 padding-bottom-8">
                    {t('Add a payment method')}
                </div>
                <div className="font-inter-500 font-size-15 color-7f7f7f line-height-22 padding-bottom-40">{t('Your card will not be charged at this time. It will be kept on file and will be used as the default for future charges')}</div>
                <div className="content-columns content-columns-payment-method">
                    {hasCards && <div className="column-percent-60 responsive-900">
                        <div className="padding-right-30">
                            {paymentMethodList.map((item, i) => {
                                return (
                                    <SettingsPaymentMethodListItem
                                        key={`list-item-${i}`}
                                        item={item}
                                        chooseDefault={this.chooseDefault}
                                        removeCard={this.removeCard}
                                        isLoading={this.state.itemIsLoading[item.id]}
                                    />
                                );
                            })}
                        </div>
                    </div>}
                    <div className={(hasCards) ? 'column-percent-40 responsive-900' : ''}>
                        <div>
                            <StripeForm
                                addToast={this.props.addToast}
                                account={this.state.account}
                                buttonText={t('Add payment method')}
                                handleSubmitSuccess={this.addPaymentMethodSuccess} />
                        </div>
                    </div>
                </div>
                {this.state.delete.display && <DeletePaymentMethodModal item={this.state.delete.item} isLast={this.state.delete.isLast} onClose={this.onCloseDeleteModal} loading={this.state.delete.loading} onDeleteItem={this.onDeleteItem} history={this.props.history}/>}
            </Fragment>
        );
    }
}

export default withTranslation()(PaymentMethodsTab);

