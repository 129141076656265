import React, { Fragment } from 'react';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import '../unconnected.css';
import { globalConstants } from "../../../app/constants";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { ROUTES } from '../../../app/routing';
import HeaderUnconnected from '../../../components/header/HeaderUnconnected';
import FooterUnconnected from '../../../components/footer/FooterUnconnected';
import illus from '../../../res/images/illustrations/illus-email.png';
import HttpHelper from '../../../app/helpers/HttpHelper';

class ForgotPasswordCheckYourEmailView extends React.Component {

    componentDidMount() {
    }
    render() {
        const { t } = this.props;
        const urlParams = HttpHelper.getUrlParameters(this.props.location.search);
        const email = urlParams.email;
        return (
            <Fragment>
                <Helmet>
                    <title>{t('Help is on the way')} - {globalConstants.WEBSITE_TITLE}</title>
                </Helmet>
                <div className="unconnected-content central">
                    <div className="content-white">
                        <HeaderUnconnected />
                        <div className="c-content-table">
                            <div className="c-content-table-cell">
                                <div className="centralized-content">
                                    <div className="margin-auto padding-bottom-20" style={{maxWidth: '300px'}}>
                                        <img alt="" src={illus} className="img-100"/>
                                    </div>
                                    <div className="font-inter-700 font-size-36 text-align-center color-0e004b padding-bottom-25">{t('Help is on the way')}</div>
                                    <div className="font-inter-400 font-size-16 color-000 text-align-center padding-bottom-10">
                                        {t('We\'re combing through our records to find the Sensia account')}{email && (<Fragment> {t('for')} <span className="font-weight-600">{email}</span></Fragment>)}.
                                        <br/><br/>{t('If we find a match, you\'ll get an email with further instructions')}
                                    </div>
                                    <div className="text-align-center padding-top-50">
                                        <div className="font-inter-400 font-size-15">
                                            {t('You didn’t receive the email?')}
                                        </div>
                                        <div className="font-inter-400 font-size-15">
                                            {t('Check your spam or')} <Link to={ROUTES.FORGOT_PASSWORD} className="link-blue font-size-15 font-inter-500">{t('click here')}</Link>.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <FooterUnconnected />
                    </div>
                </div>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

export default withTranslation()(connect(mapStateToProps)(ForgotPasswordCheckYourEmailView));