import React, { createRef, Fragment } from "react";

import {
  ACCOUNT_PLAN,
  globalConstants,
  PLAN_JSON_LOGIC_KEYS,
} from "../../app/constants";

import { withTranslation, Trans } from "react-i18next";
import { connect } from "react-redux";
import { projectService } from "../../app/services/project.service";
import { mediaService } from "../../app/services/media.service";
import HttpHelper from "../../app/helpers/HttpHelper";
import { ROUTES } from "../../app/routing";

import { SessionStorageService } from "../../app/services/storage.service";

import parse from "html-react-parser";

import "../projectDetail/tabViews/overview/OverView.css";
import { planJsonLogicService } from "../../app/services/plan.jsonLogic.service";

import "./style.css";
import moment from "moment";

import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import Print from "../../res/images/icons/print.svg";
import Export from "../../res/images/icons/export-doc.svg";
import ExportExcel from "../../res/images/icons/export-excel.svg";
import ExportPPT from "../../res/images/icons/export-ppt.png";
import ExportPDF from "../../res/images/icons/export-pdf.png";
import Share from "../../res/images/icons/share.svg";
import Tooltip from "@mui/material/Tooltip";
import Link from "../../components/Link";
import OpeninNewFilled from "../../res/images/icons/OpeninNewFilled.svg";
import ContentCopyFilled from "../../res/images/icons/ContentCopyFilled.svg";
import Globe from "../../res/images/icons/globe.svg";
import Close from "../../res/images/icons/pic-tag-clear.svg";
import sensiaLogo from "../../res/images/icons/SensiaLogo.svg";
import EmptyState from "./EmptyState";
import SkeletonLoad from "../projectReport/SkeletonLoad";
import CarouselComponent from "../../components/carroussel/Carousel";
import CarouselCard from "../../components/carroussel/Card";

import p1 from "../../res/images/carousel/p1.svg";
import p2 from "../../res/images/carousel/p2.svg";
import p3 from "../../res/images/carousel/p3.svg";
import p4 from "../../res/images/carousel/p4.svg";

import loadArrow from "../../res/images/icons/cached-filled.svg";
import endAnalyse from "../../res/images/icons/check-filled.svg";
import StatisticsView from "../project/components/StatisticsView";
import DataSourceSelect from "../project/components/DataSourceSelect";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import UserHelper from "../../app/helpers/UserHelper";

class ProjectNewIndightView extends React.Component {
  constructor(props) {
    super(props);

    const sortedBy =
      SessionStorageService.get("project_detail_sorted_by") || "updated_at";

    console.log("datasource id:", props.datasourceId);
    this.state = {
      projectId: props.projectId,
      project: null,
      datasourceId: props.datasourceId,
      nbSources: 0,
      datasourceList: [],
      isLoaded: false,
      inlineLoading: false,
      isFirstLoad: true,
      formData: {
        sorted_by: sortedBy,
      },
      export_variable_available: false,
      hasLimitedAccess: false,
      mediasAnalyseInProgress: false,
      mediasAnalyseJustFinish: false,
      mediaChatGpt: null,
      mediaStats: null,
      chatGpt: {},
      anchorEl: null,
      media: props.datasourceId != null,
      clipboardToast: false,
      sharePopup: false,
      print: false,
      limitation_data: false,
      isAllDatasource: props.datasourceId == null,
      exportLoader: false,
    };

    this.abortController = new AbortController();
    this.abortControllerSignal = this.abortController.signal;

    this.fetchProject = this.fetchProject.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.displayToastErrorMessage = this.displayToastErrorMessage.bind(this);
    this.pageTitleHandler = this.pageTitleHandler.bind(this);
    this.onChangeMediaScope = this.onChangeMediaScope.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleExport = this.handleExport.bind(this);
    this.copyShareLink = this.copyShareLink.bind(this);
    this.getShareTooltipContent = this.getShareTooltipContent.bind(this);
    this.closeSharePopup = this.closeSharePopup.bind(this);
    this.openSharePopup = this.openSharePopup.bind(this);
    this.handlePrint = this.handlePrint.bind(this);
    this.print = this.print.bind(this);
    this.closeFreePlanPopup = this.closeFreePlanPopup.bind(this);
    this.handleDatasourceTooltip = this.handleDatasourceTooltip.bind(this);
    this.checkDatasourcesStatus = this.checkDatasourcesStatus.bind(this);
    this.handleExportVariables = this.handleExportVariables.bind(this);
    this.generateReport = this.generateReport.bind(this);
    this.handleExportPPT = this.handleExportPPT.bind(this);
    this.handleExportPDF = this.handleExportPDF.bind(this);
    this.generatePDFPPT = this.generatePDFPPT.bind(this);
    this.getFilename = this.getFilename.bind(this);
    this.statisticsViewRef = createRef();
  }

  componentDidMount() {
    this._ismounted = true;
    window.addEventListener("scroll", () => {
      this.handleScroll();
    });
    this.fetchProject();
  }

  componentWillUnmount() {
    this.abortController.abort();
    this._ismounted = false;
    window.removeEventListener("scroll", () => {
      this.handleScroll();
    });
  }

  checkDatasourcesStatus() {
    const checkDatasourcesStatusInterval = setInterval(() => {
      mediaService.get.getProjectMedias(
        this.state.projectId,
        this.state.formData,
        (response) => {
          let result = response.data.filter(
            (elm) => elm.status === "INPROGRESS"
          );
          if (result == undefined || result.length == 0) {
            clearInterval(checkDatasourcesStatusInterval);
            this.fetchProject();
            this.statisticsViewRef.current.refreshStatistics();
          }
        },
        (error) => {
          this.handleErrorData(error, this.state.formData);
        },
        this.abortControllerSignal
      );
    }, 5000);
  }

  displayToastErrorMessage(error, onThen, updatedState) {
    if (!this._ismounted) {
      return;
    }
    this.props.addToast(HttpHelper.getErrorMessage(error), {
      type: "error",
      autoDismiss: true,
    });
    if (typeof onThen == "function") {
      onThen();
    }
    if (updatedState) {
      this.setState(updatedState);
    }
  }

  async fetchProject(first) {
    await projectService.get.get(
      this.state.projectId,
      (response) => {
        this.setState({
          project: response,
          nbSources: response.medias ? response.medias : 0,
          chatGpt: {
            updated_at: response.updated_at,
            reports: response.reports,
            export_variable_available: response.export_variable_available,
          },
        });

        if (response.medias > 0) {
          this.fetchData();
        } else {
          this.setState({
            isLoaded: true,
            isFirstLoad: false,
            inlineLoading: false,
          });
        }

        planJsonLogicService.playRule(
          PLAN_JSON_LOGIC_KEYS.GENERATIVE_REPORTS,
          {},
          (response) => {
            this.setState({
              hasLimitedAccess: response.status === "error",
              limitation_data: response.data,
            });
          },
          (error) => {
            if (!this._ismounted) {
              return;
            }
            this.props.addToast(HttpHelper.getErrorMessage(error), {
              type: "error",
              autoDismiss: true,
            });
          }
        );
      },
      (error) => {
        this.displayToastErrorMessage(error);
      },
      null,
      this.abortControllerSignal
    );
  }

  fetchData() {
    if (this._ismounted) {
      this.setState({ inlineLoading: true });
    }

    mediaService.get.getProjectMedias(
      this.state.projectId,
      this.state.formData,
      (response) => {
        let mediaChatGptList = {};
        let mediaStatsList = {};

        response.data.forEach((elm) => {
          mediaChatGptList[elm.id] = {
            updated_at: elm.updated_at,
            reports: elm.reports,
            export_variable_available: elm.export_variable_available,
          };

          mediaStatsList[elm.id] = {
            source: 1,
            rewiews: elm.total_obs.total_obs_used,
            total_obs: elm.total_obs.total_obs,
            total_obs_used: elm.total_obs.total_obs_used,
            pos: elm.percent_pos,
            neg: elm.percent_neg,
            score: elm.average_score,
            feeling: elm.main_emotion,
            updated_at: elm.updated_at,
          };
        });
        let hasMediaInProgress = false;
        let analyseJustFinish = this.state.mediasAnalyseJustFinish;

        const result = response.data.filter(
          (elm) => elm.status === "INPROGRESS"
        );
        if (result != undefined && result.length > 0) {
          hasMediaInProgress = true;
          this.checkDatasourcesStatus();
        }

        if (this.state.mediasAnalyseInProgress && !hasMediaInProgress) {
          analyseJustFinish = true;
        }

        const successList = response.data.filter(
          (elm) =>
            elm.status === "SUCCESS" ||
            (elm.status === "INPROGRESS" &&
              elm.main_emotion &&
              elm.percent_neg &&
              elm.percent_pos &&
              elm.reports &&
              elm.reports?.length)
        );

        const sources = response.data.filter((elm) => elm.status !== "FAILED");
        let nbSources = 0;
        if (sources) {
          nbSources = sources.length;
        }

        this.setState({
          isLoaded: true,
          isFirstLoad: false,
          inlineLoading: false,
          mediaChatGpt: mediaChatGptList,
          mediaStats: mediaStatsList,
          datasourceList: successList,
          mediasAnalyseInProgress: hasMediaInProgress,
          mediasAnalyseJustFinish: analyseJustFinish,
          nbSources: nbSources,
        });
      },
      (error) => {
        this.handleErrorData(error, this.state.formData);
      },
      this.abortControllerSignal
    );
  }

  onChangeMediaScope(datasource_id) {
    this.setState({
      datasourceId: datasource_id,
      isAllDatasource: datasource_id == null,
    });

    this.props.selectDatasourceId(datasource_id);

    if (datasource_id == null) {
      sessionStorage.removeItem("selectedDatasource");
    }
  }

  handleErrorData(error, newFormData) {
    this.displayToastErrorMessage(error, null, {
      formData: newFormData,
      isLoaded: true,
      inlineLoading: false,
    });
  }

  getMessageErrorPlan() {
    return (
      <Trans i18nKey="unlock_your_report">
        <div className="font-weight-bold padding-bottom-5 color-ff6f07 font-size-15">
          Unlock Insights
        </div>
        Please{" "}
        <Link
          to={ROUTES.PLAN_CHOOSE}
          className="text-decoration-underline color-000"
        >
          upgrade your plan
        </Link>{" "}
        first to the Pro or Business Plan to get all details in the current
        Insights report.
      </Trans>
    );
  }

  pageTitleHandler() {
    return `${this.state.project ? this.state.project.name + " -" : ""} ${
      globalConstants.WEBSITE_TITLE
    }`;
  }

  selectAnchor(_anchor) {
    if (this.state.isLoaded && this.state.nbSources > 0) {
      document.querySelectorAll(".anchor").forEach((anchor) => {
        anchor.classList.remove("active");
      });
      const menu_div = document.getElementById(`menu_${_anchor}`);
      menu_div.classList.add("active");
      const selectedAnchor = document.getElementById(_anchor);
      const selectedAnchorPos = selectedAnchor.offsetTop;

      window.scrollTo({ top: selectedAnchorPos - 30, behavior: "smooth" });
    }
  }

  handleScroll() {
    const mainContent = document.getElementById("main-content");

    if (
      this.state.isLoaded &&
      this.state.nbSources > 0 &&
      mainContent !== null &&
      mainContent !== undefined
    ) {
      let scrollValue = window.scrollY;
      let mainContentPos = mainContent.offsetTop;

      if (
        this.state.hasLimitedAccess &&
        !this.state.freePlanPopup &&
        scrollValue > 400
      ) {
        this.setState({ freePlanPopup: true });
        return;
      }

      let activeSection = null;
      let prviousPos = mainContentPos - 50;
      document.querySelectorAll(".report-section").forEach((section) => {
        const sectionPosition = section.offsetHeight + prviousPos;
        prviousPos += section.offsetHeight;

        if (!activeSection && scrollValue < sectionPosition) {
          activeSection = section;
        }
      });

      document.querySelectorAll(".anchor").forEach((anchor) => {
        anchor.classList.remove("active");
      });

      const menu_div = document.getElementById(`menu_${activeSection?.id}`);
      menu_div?.classList.add("active");
    }
  }

  handleClick(event) {
    this.setState({
      anchorEl: event.currentTarget,
    });
  }

  handleClose() {
    this.setState({
      anchorEl: null,
    });
  }

  handleExport() {
    const { datasourceId, datasourceList } = this.state;

    let selectedDatasourceName = null;

    if (datasourceId) {
      const datasource = datasourceList?.find((e) => e.id == datasourceId);
      if (datasource) {
        selectedDatasourceName = datasource.title;
      }
    }

    const filename = this.getFilename();
    projectService.get.getDocxReport(
      this.state.projectId,
      `${filename}.docx`,
      { datasource_id: this.state.datasourceId },
      null,
      (error) => {
        this.displayToastErrorMessage(error);
      }
    );
  }

  handlePrint() {
    const planKey = this.state.limitation_data.plan;
    if (planKey === ACCOUNT_PLAN.FREE) {
      this.handleClose();
      this.setState({
        freePlanPopup: true,
      });
      return;
    } else {
      this.handleClose();
      this.setState({
        print: true,
      });
    }
  }

  print() {
    setTimeout(() => {
      window.print();
    }, 200);
    window.addEventListener("afterprint", (event) => {
      this.setState({
        print: false,
      });
    });
  }

  copyShareLink(e) {
    e.preventDefault();
    navigator.clipboard.writeText(this.state.project.public_report_url_link);
    this.setState({
      clipboardToast: true,
    });
    setTimeout(() => {
      this.setState({
        clipboardToast: false,
      });
    }, 1500);
  }

  openSharePopup() {
    const planKey = this.state.limitation_data.plan;
    if (planKey === ACCOUNT_PLAN.FREE) {
      this.handleClose();
      this.setState({
        freePlanPopup: true,
      });
      return;
    } else {
      this.setState({
        sharePopup: true,
      });
      this.handleClose();
    }
  }

  closeSharePopup() {
    this.setState({
      sharePopup: false,
    });
  }

  getCurrentDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}${month}${day}`;
  }

  // Fonction pour générer le nom du fichier
  getFilename() {
    const { datasourceId, datasourceList, project } = this.state;

    let selectedDatasourceName = null;

    if (datasourceId) {
      const datasource = datasourceList?.find((e) => e.id == datasourceId);
      if (datasource) {
        selectedDatasourceName = datasource.title;
      }
    }

    let baseName = datasourceId ? selectedDatasourceName : project.name;
    baseName = baseName?.replace(/ /g, "_"); // Remplace les espaces par des underscores
    if (!baseName) {
      baseName = "report";
    }
    const currentDate = this.getCurrentDate();
    return `${baseName}_${currentDate}`;
  }

  // Fonction pour générer le nom du fichier
  getPPTPDFFilename(exportType) {
    const filename = this.getFilename();
    return `${filename}.${exportType}`;
  }

  handleExportVariables() {
    const planKey = this.state.limitation_data.plan;
    if (planKey === ACCOUNT_PLAN.FREE) {
      this.handleClose();
      this.setState({
        freePlanPopup: true,
      });
      return;
    }

    this.generateReport();
  }

  generateReport() {
    const { datasourceId, datasourceList } = this.state;

    let selectedDatasourceName = null;

    if (datasourceId) {
      const datasource = datasourceList?.find((e) => e.id == datasourceId);
      if (datasource) {
        selectedDatasourceName = datasource.title;
      }
    }

    const filename = this.getFilename();
    projectService.get.getExcelReport(
      this.state.projectId,
      `${filename}.xlsx`,
      { datasource_id: this.state.datasourceId },
      null,
      (error) => {
        this.displayToastErrorMessage(error);
      }
    );
  }

  handleExportPDF() {
    const planKey = this.state.limitation_data.plan;
    if (planKey === ACCOUNT_PLAN.FREE) {
      this.handleClose();
      this.setState({
        freePlanPopup: true,
      });
      return;
    }

    this.handleClose();
    this.generatePDFPPT("pdf");
  }

  handleExportPPT() {
    const planKey = this.state.limitation_data.plan;
    if (planKey === ACCOUNT_PLAN.FREE) {
      this.handleClose();
      this.setState({
        freePlanPopup: true,
      });
      return;
    }
    this.handleClose();
    this.generatePDFPPT("pptx");
  }

  generatePDFPPT(exportType) {
    const { projectId, datasourceId } = this.state;

    this.setState({ exportLoader: true });
    const filename = this.getPPTPDFFilename(exportType);

    projectService.get.getPPDFPPTReport(
      projectId,
      datasourceId,
      exportType,
      filename,
      () => {
        this.setState({ exportLoader: false });
      },
      (error) => {
        this.setState({ exportLoader: false });
        this.displayToastErrorMessage(error);
      }
    );
  }

  getShareTooltipContent() {
    const { t } = this.props;
    return (
      <div className="share-tooltip">
        <img
          onClick={this.closeSharePopup}
          className="close"
          src={Close}
          alt=""
        />

        <h3>Share "{this.state.project.name}"</h3>

        <p>
          <img src={Globe} alt="" />
          Anyone with this link can view this report
        </p>

        <div className="btn-box">
          <div className="content">
            <span className="actions" onClick={this.copyShareLink}>
              <img src={ContentCopyFilled} alt="" />
              {t("Copy link")}
            </span>

            <span className="preview">
              <img src={OpeninNewFilled} alt="" />
              <Link
                to={this.state.project.public_report_url_link}
                type="a"
                target="_blank"
              >
                <span className="actions">Preview</span>
              </Link>
            </span>

            {this.state.clipboardToast && (
              <span className="clipboard-toast">Copied on clipboard!</span>
            )}
          </div>

          <span onClick={this.closeSharePopup} className="button">
            Done
          </span>
        </div>
      </div>
    );
  }

  closeFreePlanPopup() {
    this.setState({
      freePlanPopup: false,
    });
  }

  getFreePlanPopup() {
    const { t } = this.props;
    return (
      <div className="position-absolute share-tooltip-free">
        <img
          onClick={this.closeFreePlanPopup}
          className="close"
          src={Close}
          alt=""
        />

        <h3>Upgrade your Insights</h3>

        <p style={{ letterSpacing: "0.15px" }}>
          You are currently on a Free plan. To access all the details in the
          current Insights report, please upgrade to either the Pro or Business
          plan.
        </p>

        <Link to={ROUTES.PLAN_CHOOSE} type="a" target="_blank">
          <span className="actions color-3a79e6">
            See what you get when you upgrade
          </span>
        </Link>

        <div className="btn-box" style={{ marginTop: "40px" }}>
          <div className="content" style={{ justifyContent: "flex-end" }}>
            <span
              className="actions color-3a79e6"
              onClick={this.closeFreePlanPopup}
            >
              {t("Maybe later")}
            </span>
            <span onClick={this.closeFreePlanPopup} className="button">
              <Link
                to={ROUTES.PLAN_CHOOSE}
                style={{ textDecoration: "none", color: "white" }}
              >
                Upgrade
              </Link>
            </span>
          </div>
        </div>
      </div>
    );
  }

  handleDatasourceTooltip() {
    if (Array.isArray(this.state.datasourceList)) {
      if (this.state.isAllDatasource) {
        return this.state.datasourceList;
      } else if (Number.isInteger(this.state.datasourceId)) {
        return this.state.datasourceList.filter(
          (ds) => ds.id === this.state.datasourceId
        );
      }
    }

    return [];
  }

  getCarousselCards() {
    return [
      {
        key: 1,
        title: "Summary",
        text: "The summary provides a comprehensive overview of the product analysis.",
        content: <CarouselCard imagen={p1} />,
      },
      {
        key: 2,
        title: "Weaknesses",
        text: "Weaknesses highlights the most significant product issues.",
        content: <CarouselCard imagen={p2} />,
      },
      {
        key: 3,
        title: "Recommendations",
        text: "Recommendations provides tips for product improvement.",
        content: <CarouselCard imagen={p3} />,
      },
      {
        key: 4,
        title: "Select data source",
        text: "Select data source provides the flexibility to generate the report from all data sources or from a single source.",
        content: <CarouselCard imagen={p4} />,
      },
    ];
  }

  render() {
    const { t } = this.props;
    const {
      isLoaded,
      datasourceId,
      print,
      mediasAnalyseInProgress,
      mediasAnalyseJustFinish,
      nbSources,
      hasLimitedAccess,
      datasourceList,
    } = this.state;

    const open = Boolean(this.state.anchorEl);

    if (!isLoaded) {
      return (
        <Fragment>
          <SkeletonLoad />
        </Fragment>
      );
    }

    if (print) {
      this.print();
    }

    const hasSource = nbSources > 0;
    const hasSourceWithData = datasourceList.length > 0;

    const displayTooltip = hasSourceWithData && !print;
    const displaySourceList = hasSourceWithData;

    const displayMessageErrorPlan = hasSourceWithData && hasLimitedAccess;

    let chatGpt = this.state.chatGpt;
    let score = this.state.project?.statistics?.product_score;
    if (datasourceId && hasSource && this.state.mediaChatGpt) {
      chatGpt = this.state.mediaChatGpt[datasourceId];
      const datasource = datasourceList.filter((ds) => ds.id === datasourceId);
      score = datasource?.average_score;
    }

    const sortedChatGptReports = chatGpt.reports.sort(
      (a, b) => a.display_order - b.display_order
    );

    const displayEmptyState = !hasSource;

    const displayCaroussel =
      hasSource && score == null && chatGpt.reports?.length == 0;

    const displayScore = !displayCaroussel && hasSourceWithData;

    const displayStayTuned =
      !displayCaroussel && mediasAnalyseInProgress && hasSourceWithData;
    const dsiplayLetsAnalyse = mediasAnalyseJustFinish && hasSourceWithData;

    const displayExecSummary = chatGpt.reports?.length > 0;

    const userIsCorellis = UserHelper.userIsCorellis();

    return (
      <Fragment>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.exportLoader}
          onClick={() => this.setState({ exportLoader: false })}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div
          id="projectNewInsight"
          className="responsive-920-padding-top-20"
          style={{ zIndex: "0" }}
        >
          <div className="central-content" id="scrollArea">
            <div className="position-relative content-title-project">
              {displayTooltip && (
                <div className="position-absolute-top-right margin-top-6">
                  <div className="content-round-select-like-button margin-left-10">
                    <div>
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? "long-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={this.handleClick}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="long-menu"
                        MenuListProps={{
                          "aria-labelledby": "long-button",
                        }}
                        anchorEl={this.state.anchorEl}
                        open={open}
                        onClose={this.handleClose}
                      >
                        <Tooltip title="Print" placement="left" arrow>
                          <MenuItem key={"print"} onClick={this.handlePrint}>
                            <img src={Print} alt="" />
                          </MenuItem>
                        </Tooltip>
                        {chatGpt.export_variable_available && (
                          <Tooltip title="Export (xlsx)" placement="left" arrow>
                            <MenuItem
                              key={"report"}
                              onClick={() => this.handleExportVariables()}
                            >
                              <img src={ExportExcel} alt="variable exporter" />
                            </MenuItem>
                          </Tooltip>
                        )}
                        <Tooltip title="Export (doc)" placement="left" arrow>
                          <MenuItem
                            key={"download"}
                            onClick={this.handleExport}
                          >
                            <img src={Export} alt="" />
                          </MenuItem>
                        </Tooltip>
                        {userIsCorellis && (
                          <Tooltip title="Export (PPT)" placement="left" arrow>
                            <MenuItem
                              key={"download ppt"}
                              onClick={this.handleExportPPT}
                            >
                              <img width="24px" src={ExportPPT} alt="" />
                            </MenuItem>
                          </Tooltip>
                        )}
                        {userIsCorellis && (
                          <Tooltip title="Export (PDF)" placement="left" arrow>
                            <MenuItem
                              key={"download pdf"}
                              onClick={this.handleExportPDF}
                            >
                              <img width="24px" src={ExportPDF} alt="" />
                            </MenuItem>
                          </Tooltip>
                        )}
                        <Tooltip title="Share" placement="left" arrow>
                          <MenuItem key={"share"} onClick={this.openSharePopup}>
                            <img src={Share} alt="" />
                          </MenuItem>
                        </Tooltip>
                      </Menu>
                    </div>
                  </div>
                </div>
              )}
              {print && (
                <div
                  style={{
                    fontSize: "9px",
                    marginTop: "15px",
                    alignSelf: "flex-end",
                    textAlign: "right",
                    width: "700px",
                    display: "flex",
                    alignItems: "center",
                    columnGap: "6px",
                    justifyContent: "flex-end",
                  }}
                >
                  Powered by <img src={sensiaLogo} alt="" />
                </div>
              )}
            </div>

            {displaySourceList && (
              <Box sx={{ mt: "10px", mr: "95px" }}>
                <DataSourceSelect
                  isLoading={this.state.datasourceList.length == 0}
                  menuTitle={t("Choose insight scope")}
                  datasources={this.state.datasourceList}
                  selectedDatasource={this.state.datasourceId}
                  onChange={this.onChangeMediaScope}
                />
              </Box>
            )}

            {/* display message error if the user is free and has uploaded a media with reviews */}
            {displayMessageErrorPlan && (
              <div className="content-error-plan margin-bottom-35 margin-top-10">
                {this.getMessageErrorPlan()}
              </div>
            )}

            {displayScore && (
              <div id="insights-score" className="margin-top-20">
                <StatisticsView
                  ref={this.statisticsViewRef}
                  projectId={this.state.projectId}
                  datasourceId={this.state.datasourceId}
                />
              </div>
            )}

            <div className="position-relative">
              <div
                style={
                  !print
                    ? { alignItems: "flex-end" }
                    : {
                        marginTop: "6px",
                        marginBottom: "33px",
                        alignItems: "flex-end",
                      }
                }
                className="display-flex"
              >
                {displayStayTuned && (
                  <div className="updateMessage">
                    <img className="rotate-img" src={loadArrow} alt="" />
                    {t("Almost there, your reports are shaping up!")}
                  </div>
                )}
                {dsiplayLetsAnalyse && (
                  <div className="updateMessage">
                    <img src={endAnalyse} alt="" />
                    {t("All set, let's analyse")}
                  </div>
                )}
              </div>
            </div>

            {this.state.sharePopup && (
              <Fragment>
                <div id="fond"></div>
                <div>{this.getShareTooltipContent()}</div>
              </Fragment>
            )}

            {this.state.freePlanPopup && hasSourceWithData && (
              <Fragment>
                <div id="fond"></div>
                {this.getFreePlanPopup()}
              </Fragment>
            )}

            {displayExecSummary && (
              <div id="main-content">
                {!print && (
                  <div id="menu">
                    <ul>
                      {sortedChatGptReports?.map((report, index) => {
                        return (
                          <li
                            onClick={() =>
                              this.selectAnchor(
                                report.title.split(" ").join("_")
                              )
                            }
                            id={`menu_${report.title.split(" ").join("_")}`}
                            className={`anchor ${index == 0 ? "active" : ""}`}
                          >
                            <p>{report.title}</p>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
                <div
                  id="text"
                  style={print ? { border: "none", paddingLeft: "0" } : {}}
                >
                  {sortedChatGptReports?.map((report, index) => {
                    return (
                      <section
                        style={
                          !print ? {} : { fontSize: "11px", width: "700px" }
                        }
                        className="report-section"
                        id={report.title.split(" ").join("_")}
                        menu={report.title.split(" ").join("_")}
                      >
                        <div
                          style={
                            !hasLimitedAccess || index == 0
                              ? {}
                              : { filter: "blur(5px)" }
                          }
                        >
                          {report.sections?.map((section, index) => {
                            return <div key={index}>{parse(section)}</div>;
                          })}
                        </div>
                      </section>
                    );
                  })}
                  <p
                    style={
                      !print
                        ? {}
                        : {
                            fontSize: "9px",
                            width: "700px",
                            alignSelf: "flex-start",
                          }
                    }
                    id="lastUpdate"
                  >
                    Last updated{" "}
                    {moment.utc(chatGpt.updated_at).local().format("L")}
                  </p>
                </div>
              </div>
            )}

            {/* si pas de datasources */}
            {displayEmptyState && (
              <EmptyState
                history={this.props.history}
                projectId={this.state.projectId}
              />
            )}

            {/* si 1 datasource mais pas encore charger a 100% */}
            {displayCaroussel && (
              <CarouselComponent
                cards={this.getCarousselCards()}
                height="500px"
                width="50%"
                margin="0 auto"
                offset={2}
                showArrows={false}
                mainTitle={"Waiting is rewarding. Explore features now! ⭐🔍"}
                secondTitle={
                  "While Sensia does its magic, why not take a breather or enjoy a quick virtual coffee? We'll have your insights ready before you know it! ☕✨"
                }
              />
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withTranslation()(
  connect(mapStateToProps)(ProjectNewIndightView)
);
