import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js";
import { BarElement } from "chart.js";
import { withTranslation } from "react-i18next";
import { backofficeService } from "../../app/services/backoffice.service";
import Loader from "../../components/loader/Loader";

ChartJS.register(BarElement);
   
class AdminSalesChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salesDetails: {
        currentMonthlyCa: null,
        previousMonthlyCa: null,
        selectedMonth: null,
        currentYearTotalCa: null,
        previousYearTotalCa: null,
        periodSum: 0,
        salesSinceLastMonth: "",
      },

      salesDetailsIsLoaded: false,
    };
  }

  componentDidMount() {
    this.fetchSaleInfo(this.props.year);
  }

  fetchSaleInfo(year = new Date().getFullYear()) {
    backofficeService.getSalesInfo(
      year,
      (response) => {
        let baseCurrentMonthlyCa = [];
        let basePreviousMonthlyCa = [];
        let baseSelectedMonth = [];

        response.monthly_ca_from_current_month.current.forEach((elm) => {
          baseSelectedMonth.push(elm.month);
          baseCurrentMonthlyCa.push(elm.sales_total);
        });

        response.monthly_ca_from_current_month.previous.forEach((elm) => {
          basePreviousMonthlyCa.push(elm.sales_total);
        });

        this.setState({
          salesDetails: {
            currentMonthlyCa: baseCurrentMonthlyCa,
            previousMonthlyCa: basePreviousMonthlyCa,
            selectedMonth: baseSelectedMonth,
            currentYearTotalCa: response.current_year_total_ca,
            previousYearTotalCa: response.previous_year_total_ca,
            periodSum: response.monthly_ca_from_current_month.period_sum,
            salesSinceLastMonth:
              response.monthly_ca_from_current_month
                .since_last_month_in_percentage,
          },
          salesDetailsIsLoaded: true,
        });
      },
      (error) => {
        console.log(error);
        this.setState({
          salesDetailsIsLoaded: true,
        });
      }
    );
  }

  chartData() {
    const chartData = {
      labels: this.state.salesDetails.selectedMonth,

      datasets: [
        {
          label: "This period",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
          borderWidth: 1,
          data: this.state.salesDetails.currentMonthlyCa,
        },
        {
          label: "Last year",
          backgroundColor: "#7f7f7f",
          data: this.state.salesDetails.previousMonthlyCa,
        },
      ],
    };

    return chartData;
  }

  options() {
    const options = {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        legend: {
          position: "bottom",
          align: "end",
        },
      },
      scales: {
        y: {
          ticks: {
            // Include a euros sign in the ticks
            callback: function (value, index, ticks) {
              return value + " €";
            },
          },
        },
      },
    };
    return options;
  }

  render() {
    const { t } = this.props;
    return (
      <div className="chartBox">
        {!this.state.salesDetailsIsLoaded && <Loader />}
        {this.state.salesDetailsIsLoaded && (
          <div className="saleChartInfos">
            <div className="saleInfos">
              <div>{t("Sales")}</div>
              <div>{this.state.salesDetails.periodSum + "€"}</div>
            </div>

            <div className="percentInfos">
              <div
                className={
                  this.state.salesDetails.salesSinceLastMonth[0] === "0"
                    ? "neutral"
                    : this.state.salesDetails.salesSinceLastMonth[0] === "↑"
                    ? "positive"
                    : "negative"
                }
              >
                {this.state.salesDetails.salesSinceLastMonth === "0"
                  ? t("No data source for this month")
                  : this.state.salesDetails.salesSinceLastMonth}
              </div>
              <div>{t("Since last month")}</div>
            </div>
          </div>
        )}
        {this.state.salesDetailsIsLoaded && (
          <Bar
            className="chart chart-modified"
            data={this.chartData()}
            options={this.options()}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(AdminSalesChart);
