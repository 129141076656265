// basic import
import React from "react";
import './MultiTab.css'
import Link from "../Link";
import { PROJECT_DETAIL, PROJECT_NEW_INSIGHTS, PROJECT_REPORT, PROJECT_WORKSPACE } from "../../app/routing";

class MultiTab extends React.Component {

    render() {

        const {full = true} = this.props

        return (
            <div id="multiTab" style={{ ...this.props.style }}>
                <Link to={PROJECT_DETAIL(this.props.projectId)}><button className={this.props.selectedTab === "Overview" ? "active" : ""}>Overview</button></Link>
                {full && <Link to={PROJECT_NEW_INSIGHTS(this.props.projectId)}><button className={this.props.selectedTab === "Insights" ? "active" : ""}>Insights</button></Link>}
                {full && <Link to={PROJECT_REPORT(this.props.projectId)}><button className={this.props.selectedTab === "In-Depth" ? "active" : ""}>In-Depth</button></Link>}
                {full && <Link to={PROJECT_WORKSPACE(this.props.projectId)}><button className={this.props.selectedTab === "Inspect" ? "active" : ""}>Inspect</button></Link>}
            </div>
        )
    }
}

export default MultiTab