import React from 'react'
import { withTranslation } from 'react-i18next'
import image from '../../res/images/illus-fin-de-page.svg'
import './PageEnd.css'

class PageEnd extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {

        const body = document.body;
        const html = document.documentElement;

        const documentHeight = Math.max(body.getBoundingClientRect().height, html.getBoundingClientRect().height);
        const windowHeight = window.innerHeight

        if (documentHeight > windowHeight) {
            return (
                <div id='PageEnd'>
                    <img src={image} alt="" />
                    <div className='font-size-24 font-weight-bold color-000'>You’ve reached the end of the page!</div>
                </div>
            )
        } else {
            return <div></div>
        }
    }
}

export default withTranslation()(PageEnd)