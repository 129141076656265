import { Editor } from "react-draft-wysiwyg";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
  Modifier,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

import { useState } from "react";
import { useEffect } from "react";
import { InputHelperText, useInput, useNotify } from "react-admin";
import { Typography, Box } from "@mui/joy";
import {
  Button,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  TextField,
} from "@mui/material";
import Loader from "../../../components/loader/Loader";

const CustomOption = (props) => {
  const { editorState, onChange, variables, isLoading } = props;
  const notify = useNotify();
  const [modalOpen, setModalOpen] = useState(false);

  const addVariable = (variable) => {
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      `{${variable}}`,
      editorState.getCurrentInlineStyle()
    );
    onChange(EditorState.push(editorState, contentState, "insert-characters"));
  };

  const copyToClipBoard = (variable) => {
    navigator.clipboard.writeText(`{${variable}}`);
    notify(`Variable copied to clipboard`, "success", undefined, true);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Box>
      <Dialog onClose={() => setModalOpen(false)} open={modalOpen}>
        <DialogTitle sx={{ fontWeight: 600 }}>Variables</DialogTitle>
        <List sx={{ maxHeight: "250px", pt: 0 }}>
          {variables.map((variable) => (
            <ListItem disableGutters key={variable.id}>
              {/* <ListItemButton onClick={() => addVariable(variable.name)}>*/}
              <ListItemButton onClick={() => copyToClipBoard(variable.name)}>
                <ListItemText
                  primary={variable.name}
                  secondary={variable.description}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Dialog>
      <Button variant="outlined" onClick={() => setModalOpen(true)}>
        VARIABLES
      </Button>
    </Box>
  );
};

export const PromptInputText = (props) => {
  const { variables, isLoading, onChange, onBlur, label, helperText, ...rest } =
    props;
  const {
    field,
    fieldState: { isTouched, invalid, error },
    formState: { isSubmitted },
    isRequired,
  } = useInput({
    // Pass the event handlers to the hook but not the component as the field property already has them.
    // useInput will call the provided onChange and onBlur in addition to the default needed by react-hook-form.
    onChange,
    onBlur,
    ...rest,
  });

  return (
    <>
      <Typography
        sx={{
          color: "#9e9e9e",
          fontWeight: 600,
          fontSize: "13px",
          fontFamily: '"Inter", sans-serif',
          mb: 1,
        }}
      >
        {props.label || field.name}
      </Typography>
      <CustomOption variables={variables} isLoading={isLoading} />
      <TextField
        {...field}
        multiline={true}
        fullWidth={true}
        label={""}
        error={(isTouched || isSubmitted) && invalid}
        helperText={
          helperText !== false || ((isTouched || isSubmitted) && invalid) ? (
            <InputHelperText
              touched={isTouched || isSubmitted}
              error={error?.message}
              helperText={helperText}
            />
          ) : (
            ""
          )
        }
        required={isRequired}
        {...rest}
      />
    </>
  );
};

export default PromptInputText;
