import React, { Fragment } from "react";
import "../form.css";
import { withTranslation, Trans } from "react-i18next";
import Box from "@mui/system/Box";
import uploadIcon from "../../../res/images/icons/upload_icon.png";
class UploadField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      uploadIsDragged: false,
    };
    this.handleDragEnter = this.handleDragEnter.bind(this);
    this.handleDragLeave = this.handleDragLeave.bind(this);
    this.uploadIsDraggedClass = this.uploadIsDraggedClass.bind(this);
    this.hasErrorClass = this.hasErrorClass.bind(this);
    this.filedClass = this.filedClass.bind(this);
    this.onHandleChange = this.onHandleChange.bind(this);
    this.onHandleDrop = this.onHandleDrop.bind(this);
  }

  handleDragEnter(e) {
    const { disabled } = this.props;
    if (disabled) {
      return false;
    }
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      uploadIsDragged: true,
    });
  }
  handleDragLeave(e) {
    const { disabled } = this.props;
    if (disabled) {
      return false;
    }
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      uploadIsDragged: false,
    });
  }

  uploadIsDraggedClass() {
    return this.state.uploadIsDragged ? "on-drag" : "";
  }

  hasErrorClass() {
    return this.props.hasError ? "error" : "";
  }

  filedClass() {
    return this.props.hasValidFile ? "has-valid-file" : "";
  }

  onHandleDrop(e) {
    const { handleChange } = this.props;
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.setState({
        uploadIsDragged: false,
      });
      if (handleChange) {
        handleChange(e);
      }
    }
  }

  onHandleChange(e) {
    const { handleChange } = this.props;
    this.setState({
      uploadIsDragged: false,
    });
    if (handleChange) {
      handleChange(e);
    }
  }

  render() {
    const {
      accept,
      disabled,
      withProgressBar,
      placeHolder,
      uploadProgress,
      inputKey,
    } = this.props;

    let defaultPlaceHolder = `Only ${accept} file type are supported`;
    if (accept.split(",").length > 1) {
      defaultPlaceHolder = `All ${accept.replaceAll(
        ",",
        " "
      )} file types are supported`;
    }
    return (
      <Fragment>
        <div
          className={`box-input-upload transition ${
            disabled ? "disabled" : ""
          } ${this.uploadIsDraggedClass()} ${this.filedClass()}`}
          onDragOver={this.handleDragEnter}
          onDragEnter={this.handleDragEnter}
          onDrop={this.onHandleDrop}
          onDragLeave={this.handleDragLeave}
        >
          {withProgressBar && uploadProgress !== null && (
            <div className="upload-field-full-content-progress-bar">
              <div className="content-progress-bar">
                <div
                  className="progress-bar"
                  style={{ width: `${uploadProgress}%` }}
                ></div>
              </div>
              <div className="percent-progression font-inter-600 font-size-12 text-align-center">
                {uploadProgress}%
              </div>
            </div>
          )}
          <div className="borders transition" />
          <label htmlFor="file" className="position-absolute-center uploadBox">
            <Trans i18nKey="choose_a_file">
              <Box
                sx={{
                  display: "flex",
                  position: "relative",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "24px 16px",
                  gap: "8px",
                }}
              >
                <img
                  className="transition"
                  src={uploadIcon}
                  alt="upload icon"
                />
                <div>
                  <span className="uploadBoxTextColored transition">
                    Choose a file
                  </span>{" "}
                  <span className="box__dragndrop">
                    or drag and drop your data.
                  </span>
                </div>
                {!!placeHolder && placeHolder}
                {!placeHolder && defaultPlaceHolder}
              </Box>
            </Trans>
          </label>
          <input
            key={inputKey}
            className="box__file"
            type="file"
            name="files[]"
            id="file"
            accept={accept}
            onChange={this.onHandleChange}
            disabled={disabled}
          />
        </div>
      </Fragment>
    );
  }
}

export default withTranslation()(UploadField);
