import '../settings/settings.css';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { globalConstants } from "../../app/constants";
import { connect } from 'react-redux';
import Header from "../../components/header/Header";
import Footer from '../../components/footer/Footer';

import { withTranslation } from 'react-i18next';
import { ROUTES } from '../../app/routing';
import { uniqueId } from 'lodash';
import Loader from '../../components/loader/Loader';
import ModelsLoadingView from '../loading/ModelsLoadingView';
import ModelsMenu from './elements/ModelsMenu';
import ModelSettingsTab from './elements/ModelSettingsTab';
import { Redirect } from 'react-router-dom';
import { classService } from '../../app/services/class.service';
import HttpHelper from '../../app/helpers/HttpHelper';
import { productService } from '../../app/services/product.service';

class ModelSettingsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false, 
            inlineLoading: false,
            formKey: uniqueId(),
            products: [],
        };

        this.fetchProducts = this.fetchProducts.bind(this);
    }

    componentDidMount() {
        this._ismounted = true;
        this.fetchProducts();
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    fetchProducts() {
        productService.products(
            (response) => {
                this.setState({
                    isLoaded: true,
                    products: response,
                });
            },
            (error) => {
                if (this._ismounted) {
                    this.setState({ isLoaded: true });
                    this.props.addToast(HttpHelper.getErrorMessage(error), { type: 'error', autoDismiss: true });
                }
            }
        );
    }

    render() {
        const { t } = this.props;
        let page_title = `${t('Model Settings')} - ${globalConstants.WEBSITE_TITLE}`;
        if (this.state.isLoaded) {
            if (this.state.hasNoAccess) {
                return (
                    <Redirect to={ROUTES.MODEL_SETTINGS} />
                );
            }
            return (
                <Fragment>
                    <Header isModels={true} />
                    <Helmet>
                        <title>{page_title}</title>
                    </Helmet>
                    <div className="padding-top-30" />
                    <div className="central-content settings-content responsive" key={this.state.formKey}>
                        <div className="left">
                            <ModelsMenu current={ROUTES.MODEL_SETTINGS} history={this.props.history} />
                        </div>
                        <div className="right">
                            <div className="padding-top-32" />
                            <div className="font-inter-700 font-size-28 color-000 padding-bottom-40">
                                {t('Model Settings')}
                            </div>
                            {this.state.inlineLoading && (
                                <div className="padding-vertical-30">
                                    <Loader />
                                </div>
                            )}
                            {!this.state.inlineLoading && <ModelSettingsTab fetchProducts={this.fetchProducts} products={this.state.products} addToast={this.props.addToast} location={this.props.location} history={this.props.history}/>}
                        </div>
                    </div>
                    <div className="padding-top-60" />
                    <Footer />
                </Fragment>
            );
        }
        return <ModelsLoadingView title={page_title} withHeader={true} rightTitle={t('Model Settings')} settingsMenuProps={{ current: ROUTES.MODEL_SETTINGS, history: this.props.history }} />;
    }
}

function mapStateToProps(state) {
    return {};
}

export default withTranslation()(connect(mapStateToProps)(ModelSettingsView));