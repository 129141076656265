import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import SettingsUserListItem from '../../../../components/list/SettingsUserListItem';
import CollectionHelper from '../../../../app/helpers/CollectionHelper';
import HttpHelper from '../../../../app/helpers/HttpHelper';
import { accountService } from '../../../../app/services/account.service';
import CreateUserModal from '../modals/CreateUserModal';
import EditUserModal from '../modals/EditUserModal';
import EmptyStateUsers from './EmptyStateUsers';
import { SETTINGS_USERS_AND_TEAMS } from '../../../../app/routing';
import { userService } from '../../../../app/services/user.service';
import UserHelper from '../../../../app/helpers/UserHelper';
import EmptyStateSearch from './EmptyStateSearch';
import DeactivateUserModal from '../modals/DeactivateUserModal';
import { getAccountPlan, PLAN_JSON_LOGIC_KEYS, USER_ACCOUNT_STATUS, USER_ROLES } from '../../../../app/constants';
import { planJsonLogicService } from '../../../../app/services/plan.jsonLogic.service';
import DeleteModal from '../../../../components/modal/DeleteModal';
import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

class UsersTab extends React.Component {
  constructor(props) {
    super(props);
    const urlParams = HttpHelper.getUrlParameters(props.location.search);
    const modale = urlParams.modale || null;
    const createDisplay = modale === "create";
    this.state = {
      hideRoundHover: false,
      sort: {
        key: null,
        order: null,
      },
      search: "",
      delete: {
        display: false,
        item: null,
        loading: false,
        error: null,
      },
      activate: {
        display: false,
        item: null,
        loading: false,
        error: null,
      },
      edit: {
        display: false,
        item: null,
        loading: false,
        error: null,
      },
      create: {
        display: createDisplay,
        loading: false,
        error: null,
        currentPlan: null,
        errorPlan: false,
      },
    };

    this.getUsers = this.getUsers.bind(this);
    this.getThContent = this.getThContent.bind(this);
    this.onThClick = this.onThClick.bind(this);
    this.onSearch = this.onSearch.bind(this);

    this.onOpenCreateModal = this.onOpenCreateModal.bind(this);
    this.onCloseCreateModal = this.onCloseCreateModal.bind(this);
    this.onCreateItem = this.onCreateItem.bind(this);

    this.onOpenEditModal = this.onOpenEditModal.bind(this);
    this.onCloseEditModal = this.onCloseEditModal.bind(this);
    this.onEditItem = this.onEditItem.bind(this);

    this.onOpenDeleteModal = this.onOpenDeleteModal.bind(this);
    this.onCloseDeleteModal = this.onCloseDeleteModal.bind(this);
    this.onDeleteItem = this.onDeleteItem.bind(this);

    this.onOpenActivateModal = this.onOpenActivateModal.bind(this);
    this.onCloseActivateModal = this.onCloseActivateModal.bind(this);

    this.onResetPasswordUser = this.onResetPasswordUser.bind(this);
    this.onResentInvitationEmail = this.onResentInvitationEmail.bind(this);
    this.onSetAsAdministrator = this.onSetAsAdministrator.bind(this);

    this.onActiveUserAccount = this.onActiveUserAccount.bind(this);

    this.flickRounHover = this.flickRounHover.bind(this);

    this.onActivateHandler = this.onActivateHandler.bind(this);
    this.onActivateUserAccountSuccess =
      this.onActivateUserAccountSuccess.bind(this);
  }

  componentDidMount() {
    this._ismounted = true;
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  getUsers() {
    const { users } = this.props;
    let ret = Object.assign([], users);

    // order if needed
    if (this.state.sort.key) {
      if (this.state.sort.key === "team__title") {
        //users can be affected to any team, we setup team object with empty values to make the sort work on team title
        const usersWithTeamDefautValue = ret.map((user) => {
          if (Object.keys(user.team).length === 0) {
            return { ...user, team: { id: null, title: "" } };
          }
          return user;
        });
        ret = CollectionHelper.sortBy(
          usersWithTeamDefautValue,
          this.state.sort.key,
          this.state.sort.order
        );
      } else {
        ret = CollectionHelper.sortBy(
          ret,
          this.state.sort.key,
          this.state.sort.order
        );
      }
    }
    // search if needed
    if (this.state.search) {
      const retEmail = CollectionHelper.findBy(
        ret,
        "email",
        this.state.search,
        {
          transform: "trim",
          comparison: "includes",
        }
      );
      const retFullname = CollectionHelper.findBy(
        ret,
        "fullname",
        this.state.search,
        {
          transform: "trim",
          comparison: "includes",
        }
      );

      ret = CollectionHelper.concatWithoutDuplicate(
        retEmail,
        retFullname,
        "email"
      );
    }
    return ret;
  }

  onThClick(item) {
    let newSort = Object.assign({}, this.state.sort);
    if (this.state.sort.key === item.value) {
      newSort.order = this.state.sort.order === "desc" ? "asc" : "desc";
    } else {
      newSort.key = item.value;
      newSort.order = "desc";
    }

    this.setState({ sort: newSort });
  }

  getThContent(item) {
    let classNames = ["sortable-th"];
    if (this.state.sort.key === item.value) {
      classNames.push("active");
      classNames.push(this.state.sort.order);
    }
    return (
      <span
        className={classNames.join(" ")}
        onClick={() => {
          this.onThClick(item);
        }}
      >
        {item.label}
        <span className="icon" />
      </span>
    );
  }

  onSearch(event) {
    this.setState({
      search: event.target.value,
    });
  }

  onOpenCreateModal() {
    this.setState({
      create: {
        display: true,
        loading: false,
        error: null,
      },
    });
  }

  onCloseCreateModal() {
    this.props.history.replace({ pathname: SETTINGS_USERS_AND_TEAMS("users") });
    this.setState({
      create: {
        display: false,
        loading: false,
        error: null,
        currentPlan: null,
        errorPlan: false,
      },
    });
  }

  onCreateItem(formData) {
    const { t } = this.props;
    let create = Object.assign({}, this.state.create);
    create.loading = true;
    this.setState({ create: create });
    planJsonLogicService.playRule(
      PLAN_JSON_LOGIC_KEYS.USERS_ADD_USER,
      {},
      (response) => {
        if (response.status === "error") {
          create.errorPlan = true;
          create.loading = false;
          create.currentPlan = getAccountPlan(response.data.plan);
          this.setState({
            create: create,
          });
        } else {
          let current_account = UserHelper.getCurrentAccount();
          formData.account_id = current_account.id;
          userService.invitation(
            formData,
            (response2) => {
              this.onCloseCreateModal();
              this.props.fetchUsers(false, true);
              this.props.addToast(t("User successfully invited!"), {
                type: "success",
                autoDismiss: true,
              });
            },
            (error) => {
              create.loading = false;
              create.error = HttpHelper.getErrorMessage(error);
              this.setState({
                create: create,
              });
            }
          );
        }
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        create.loading = false;
        create.error = HttpHelper.getErrorMessage(error);
        this.setState({
          create: create,
        });
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      }
    );
  }

  onResetPasswordUser(item) {
    const { t } = this.props;
    let formData = {};
    formData.email = item.email;
    this.flickRounHover();
    userService.lostPasswd(formData, (response) => {
      this.props.addToast(t("Password reset successfully"), {
        type: "success",
        autoDismiss: true,
      });
    });
  }

  onResentInvitationEmail(item) {
    let formData = {};
    const currentAccount = UserHelper.getCurrentAccount();
    formData.user_id = item.id;
    formData.user_account_id = item.user_account_id;
    formData.account_id = currentAccount.id;
    formData.team_id = item.team && item.team.id ? item.team.id : null;
    this.flickRounHover();
    userService.resentInvitationEmail(formData, (response) => {
      this.props.addToast(
        `Invitation email sent successfully to "${item.email}"`,
        { type: "success", autoDismiss: true }
      );
    });
  }

  flickRounHover() {
    this.setState({
      hideRoundHover: true,
    });
    setTimeout(() => {
      this.setState({
        hideRoundHover: false,
      });
    }, 300);
  }

  onSetAsAdministrator(item) {
    const { t } = this.props;
    const team_id = item.team && item.team.id ? item.team.id : null;
    let formData = {
      user_id: item.user_id,
      user_account_id: item.user_account_id,
      email: item.email,
      team_id: team_id,
      role: USER_ROLES.ADMIN,
    };
    let current_account = UserHelper.getCurrentAccount();
    formData.account_id = current_account.id;
    this.flickRounHover();
    userService.edit(
      formData,
      (response) => {
        this.props.fetchUsers(false, true);
        this.props.addToast(t("User setted as administrator!"), {
          type: "success",
          autoDismiss: true,
        });
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      }
    );
  }

  onOpenActivateModal(item) {
    this.setState({
      activate: {
        display: true,
        item: item,
        loading: false,
        error: null,
      },
    });
  }

  onCloseActivateModal() {
    this.setState({
      activate: {
        display: false,
        item: null,
        loading: false,
        error: null,
      },
    });
  }

  onOpenEditModal(item) {
    this.setState({
      edit: {
        display: true,
        item: item,
        loading: false,
        error: null,
      },
    });
  }

  onCloseEditModal() {
    this.setState({
      edit: {
        display: false,
        item: null,
        loading: false,
        error: null,
      },
    });
  }

  onActivateUserAccountSuccess(formData) {
    const { t } = this.props;
    this.onCloseActivateModal();
    this.props.fetchUsers(false, true);
    const message =
      formData.target_role === USER_ACCOUNT_STATUS.ACTIVE
        ? t("User successfully reactivated")
        : t("User successfully deactivated");
    this.props.addToast(message, { type: "success", autoDismiss: true });
  }

  onActiveUserAccount(formData) {
    formData.target_role = USER_ACCOUNT_STATUS.ACTIVE;
    accountService.changeUserActiveStatus(formData, (response) => {
      this.onActivateUserAccountSuccess(formData);
    });
  }

  onActivateHandler(formData) {
    accountService.changeUserActiveStatus(formData, (response) => {
      this.onActivateUserAccountSuccess(formData);
    });
  }

  onEditItem(formData) {
    const { t } = this.props;
    let edit = Object.assign({}, this.state.edit);
    edit.loading = true;
    this.setState({ edit: edit });
    formData.user_account_id = edit.item.user_account_id;
    formData.user_id = edit.item.user_id;
    let current_account = UserHelper.getCurrentAccount();
    formData.account_id = current_account.id;
    if (!formData.team_id) {
      formData.team_id = -1;
    }
    userService.edit(
      formData,
      (response) => {
        this.onCloseEditModal();
        this.props.fetchUsers(false, true);
        this.props.addToast(t("User successfully updated"), {
          type: "success",
          autoDismiss: true,
        });
      },
      (error) => {
        edit.loading = false;
        edit.error = HttpHelper.getErrorMessage(error);
        this.setState({
          edit: edit,
        });
      }
    );
  }

  onOpenDeleteModal(item) {
    this.setState({
      delete: {
        display: true,
        item: item,
        loading: false,
        error: null,
      },
    });
  }

  onCloseDeleteModal() {
    this.setState({
      delete: {
        display: false,
        item: null,
        loading: false,
        error: null,
      },
    });
  }

  onDeleteItem() {
    const { t } = this.props;
    let deleteData = Object.assign({}, this.state.delete);
    let current_account = UserHelper.getCurrentAccount();
    deleteData.loading = true;
    this.setState({ delete: deleteData });
    userService.deleteUser(
      deleteData.item.user_account_id,
      current_account.id,
      (response) => {
        this.onCloseDeleteModal();
        this.props.fetchUsers(false, true);
        this.props.addToast(t("User successfully deleted!"), {
          type: "success",
          autoDismiss: true,
        });
      },
      (error) => {
        deleteData.loading = false;
        deleteData.error = HttpHelper.getErrorMessage(error);
        this.setState({
          delete: deleteData,
        });
      }
    );
  }

  render() {
    const { t, account } = this.props;
    const users = this.getUsers();
    if (users.length === 0 && this.state.search === "") {
      return (
        <Fragment>
          <EmptyStateUsers onOpenCreateModal={this.onOpenCreateModal} />
          {this.state.create.display && (
            <CreateUserModal
              onClose={this.onCloseCreateModal}
              error={this.state.create.error}
              errorPlan={this.state.create.errorPlan}
              currentPlan={this.state.create.currentPlan}
              loading={this.state.create.loading}
              onCreateItem={this.onCreateItem}
              teams={this.props.teams}
            />
          )}
        </Fragment>
      );
    }

    return (
      <Fragment>
        <div className="font-inter-500 font-size-15 line-height-22 color-7f7f7f">
          {t(
            "Create new users, customize their permissions, and remove users from your account."
          )}
        </div>
        <div className="padding-top-44" />
        <div className="position-relative">
          <div className="position-absolute-top-right">
            <button className="button" onClick={this.onOpenCreateModal}>
              {t("Create user")}
            </button>
          </div>

          <TextField
            type="search"
            className="search-input"
            placeholder={t("Search")}
            onChange={this.onSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ mb: "32px", width: "200px", height: "34px" }}
          />
        </div>
        <div className="padding-top-25" />
        <table className="sensia-table table-layout-fixed width-percent-100">
          <thead>
            <tr>
              <th width={36} />
              <th>
                {this.getThContent({ label: t("Name"), value: "fullname" })}
              </th>
              <th width={130} className="hide-760">
                {this.getThContent({ label: t("Team"), value: "team__title" })}
              </th>
              <th width={100} className="hide-760">
                {this.getThContent({ label: t("Access"), value: "role" })}
              </th>
              <th width={30} />
            </tr>
          </thead>
        </table>
        <div className="padding-top-5" />
        {this.state.search !== "" && users.length === 0 && <EmptyStateSearch />}
        {users.map((item, i) => {
          return (
            <SettingsUserListItem
              key={`settings-user-list-item-${i}`}
              item={item}
              account={account}
              hideRoundHover={this.state.hideRoundHover}
              onResetPasswordUser={() => {
                this.onResetPasswordUser(item);
              }}
              onOpenEditModal={() => {
                this.onOpenEditModal(item);
              }}
              onOpenDeleteModal={() => {
                this.onOpenDeleteModal(item);
              }}
              onOpenActivateModal={() => {
                this.onOpenActivateModal(item);
              }}
              onResentInvitationEmail={() => {
                this.onResentInvitationEmail(item);
              }}
              onSetAsAdministrator={() => {
                this.onSetAsAdministrator(item);
              }}
              onActiveUserAccount={() => {
                this.onActiveUserAccount(item);
              }}
            />
          );
        })}
        {this.state.activate.display && (
          <DeactivateUserModal
            onClose={this.onCloseActivateModal}
            error={this.state.activate.error}
            loading={this.state.activate.loading}
            onActivateHandler={this.onActivateHandler}
            item={this.state.activate.item}
          />
        )}
        {this.state.create.display && (
          <CreateUserModal
            onClose={this.onCloseCreateModal}
            error={this.state.create.error}
            errorPlan={this.state.create.errorPlan}
            currentPlan={this.state.create.currentPlan}
            loading={this.state.create.loading}
            onCreateItem={this.onCreateItem}
            teams={this.props.teams}
          />
        )}
        {this.state.edit.display && (
          <EditUserModal
            onClose={this.onCloseEditModal}
            loading={this.state.edit.loading}
            item={this.state.edit.item}
            onEditItem={this.onEditItem}
            teams={this.props.teams}
          />
        )}
        {this.state.delete.display && (
          <DeleteModal
            itemValue={
              this.state.delete.item ? this.state.delete.item.email : null
            }
            title={t("Delete user")}
            subtitle={t("You are going to delete the user")}
            inputLabel={t("Enter email to confirm")}
            buttonLabel={t("Delete user")}
            onClose={this.onCloseDeleteModal}
            loading={this.state.delete.loading}
            onDeleteItem={this.onDeleteItem}
          />
        )}
      </Fragment>
    );
  }
}

export default withTranslation()(UsersTab);