import React, { Fragment } from "react";
// import Select from 'react-select';
import { components } from "react-select";
import { Helmet } from "react-helmet";
import { globalConstants } from "../../app/constants";
import { withTranslation } from "react-i18next";
import MediaHeader from "./elements/MediaHeader";
import Link from "../../components/Link";
import { mediaService } from "../../app/services/media.service";
import HttpHelper from "../../app/helpers/HttpHelper";
import MediaHelper from "../../app/helpers/MediaHelper";
import {
  PROJECT_ADD_MEDIA,
  PROJECT_ADD_MEDIA_CONFIRM,
  PROJECT_DETAIL,
} from "../../app/routing";
import Loader from "../../components/loader/Loader";
import { SessionStorageService } from "../../app/services/storage.service";
import { projectService } from "../../app/services/project.service";
import LoadingView from "../../components/loader/Loader";
import simple from "../../res/images/icons/pic-simple.svg";
import test from "../../res/images/icons/pic-test.svg";
import addDataSource2Img from "./../../res/images/AddDataSource2.png";
import Typography from "@mui/joy/Typography";

import {
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { Box } from "@mui/system";

const Option = (props) => {
  return (
    <div className="customOptions">
      <components.Option {...props}>
        <input
          className={`nothisone ${props.isSelected ? "checked" : ""}`}
          type="checkbox"
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

class SelectSheetFields extends React.Component {
  constructor(props) {
    super(props);
    const urlParams = HttpHelper.getUrlParameters(props.location.search);
    const nbOfSheet = urlParams.nbOfSheet;
    const removeHeader = urlParams.removeHeader;
    const mySelectedSheet = urlParams.selectedSheet;
    const title = urlParams.title || "";
    const url = urlParams.url || "";
    const type = urlParams.type || MediaHelper.type.YOUTUBE;
    const productSourcesId = urlParams.productSourcesId || null;
    const filepath = urlParams.filepath || "";
    const referrer =
      props.location.state && props.location.state.referrer
        ? props.location.state.referrer
        : null;
    this.state = {
      projectId: props.match.params.id,
      project: null,
      formData: {
        title: title,
        url: url,
        type: type,
        productSourcesId: productSourcesId,
        filepath: filepath,
      },
      fileData: {
        nbOfSheet: 1,
        nbOfColumn: 1,
        sheetList: [],
        columnBySheet: {},
      },
      nbOfSheet: nbOfSheet,
      mySelectedSheet: mySelectedSheet,
      disabledValue: [],
      columnKey: 0,
      defaultSheet: null,
      defaultColumn: null,
      selectedSheet: null,
      previewSelectedSheet: null,
      selectedCharacteristics: sessionStorage.getItem("selectedCharacteristics")
        ? JSON.parse(sessionStorage.getItem("selectedCharacteristics"))
        : [],
      selectedFields: sessionStorage.getItem("selectedFields")
        ? JSON.parse(sessionStorage.getItem("selectedFields"))
        : [],
      selectedReviews: sessionStorage.getItem("selectedReviews")
        ? JSON.parse(sessionStorage.getItem("selectedReviews"))
        : [],
      selectedProfileFields: [],
      removeHeader: removeHeader || 1,
      columnsOptions: sessionStorage.getItem("columnsOptions")
        ? JSON.parse(sessionStorage.getItem("columnsOptions"))
        : [],
      dataIsLoaded: false,
      referrer: referrer,
      isLoaded: false,
      languageInfos: urlParams.default_language,
    };

    this.fetchExcelDetails = this.fetchExcelDetails.bind(this);
    this.handleChangeSheet = this.handleChangeSheet.bind(this);
    this.handleColumnChange = this.handleColumnChange.bind(this);
    this.handleChangeCharacteristics =
      this.handleChangeCharacteristics.bind(this);
    this.handleChangeFields = this.handleChangeFields.bind(this);
    this.handleChangeReviews = this.handleChangeReviews.bind(this);
    this.findArrayElementByTitle = this.findArrayElementByTitle.bind(this);
    this.fetchProject = this.fetchProject.bind(this);
    this.next = this.next.bind(this);

    if (!SessionStorageService.get("update_media_referrer") && referrer) {
      SessionStorageService.set("update_media_referrer", referrer);
    }

    if (!this.state.formData.url && !this.state.formData.filepath) {
      this.props.history.push(PROJECT_ADD_MEDIA(this.state.projectId));
    }
  }

  componentDidMount() {
    this._ismounted = true;
    this.fetchExcelDetails(this.state.formData.filepath);
    this.fetchProject();
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  async fetchProject() {
    await projectService.get.get(
      this.state.projectId,
      (response) => {
        this.setState({
          project: response,
          isLoaded: true,
        });
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.setState({
          isLoaded: true,
        });
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      },
      { schema: "minimal" }
    );
  }

  fetchExcelDetails(filePath) {
    mediaService.get.getExcelFileData(
      filePath,
      (response) => {
        const data = {
          nbOfSheet: response.file_data.nb_sheet || 1,
          nbOfColumn: response.file_data.nb_column,
          sheetList: response.file_data.sheet_list,
          columnBySheet: response.file_data.column_by_sheet,
        };

        let firstSheetKey = this.state.mySelectedSheet || data.sheetList[0];
        let columns = data.columnBySheet[firstSheetKey];
        let columnsOptions = sessionStorage.getItem("columnsOptions")
          ? JSON.parse(sessionStorage.getItem("columnsOptions"))
          : [];
        let defaultColumn = {};

        if (columns.length > 0) {
          columns.map((d) =>
            columnsOptions.push({ label: d.title, value: d.index })
          );

          let firstColumn = columns[0];
          defaultColumn = {
            label: firstColumn.title,
            value: firstColumn.index,
          };
        }

        this.setState({
          fileData: data,
          selectedSheet: firstSheetKey,
          previewSelectedSheet: firstSheetKey,
          selectedColumn: defaultColumn.value,
          columnsOptions: sessionStorage.getItem("columnsOptions")
            ? JSON.parse(sessionStorage.getItem("columnsOptions"))
            : columnsOptions,
          defaultSheet: {
            label: firstSheetKey,
            value: firstSheetKey,
          },
          defaultColumn: defaultColumn,
          dataIsLoaded: true,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getSheetOptions() {
    let modelsOptions = [];
    this.state.fileData.sheetList.forEach((element) => {
      modelsOptions.push({
        label: element,
        value: element,
      });
    });

    return modelsOptions;
  }

  handleChangeSheet(event) {
    let newValue = event.value;
    let columns = this.state.fileData.columnBySheet[newValue];
    let columnsOptions = [];
    let selectedColumn = null;
    let defaultColumn = {};

    if (columns.length > 0) {
      columns.map((d) =>
        columnsOptions.push({ label: d.title, value: d.index })
      );

      let firstColumn = columns[0];
      defaultColumn = {
        label: firstColumn.title,
        value: firstColumn.index,
      };

      selectedColumn = 0;
    }

    this.setState({
      columnKey: this.state.columnKey + 1,
      selectedSheet: newValue,
      previewSelectedSheet: newValue,
      columnsOptions: columnsOptions,
      selectedColumn: defaultColumn.value,
      defaultColumn: defaultColumn,
    });
  }

  handleColumnChange(characteristics, fields, reviews) {
    const disabledValue = [
      ...(characteristics || []),
      ...(fields || []),
      ...(reviews || []),
    ];
    const valueList = this.state.columnsOptions;

    const uniqueDisabledValue = Array.from(
      new Set(disabledValue.map((a) => a.label))
    ).map((label) => {
      return disabledValue.find((a) => a.label === label);
    });

    valueList.forEach((elm) => {
      elm.isDisabled = false;
    });

    uniqueDisabledValue.forEach((elm) => {
      valueList.find((obj) => obj.label === elm.label).isDisabled = true;
    });

    sessionStorage.setItem("columnsOptions", JSON.stringify(valueList));
  }

  handleChangeCharacteristics(infos) {
    const {
      target: { value },
    } = infos;

    let characteristics = [...this.state.selectedCharacteristics];
    characteristics = typeof value === "string" ? value.split(",") : value;

    characteristics.forEach((elm) => {
      elm.isFrom = "characteristics";
    });

    characteristics.forEach((firstElm) => {
      characteristics.forEach((secondElm) => {
        if (
          characteristics.indexOf(firstElm) !==
            characteristics.indexOf(secondElm) &&
          firstElm.value === secondElm.value
        ) {
          characteristics.splice(characteristics.indexOf(firstElm), 1);
          characteristics.splice(characteristics.indexOf(secondElm), 1);
        }
      });
    });

    this.setState(
      {
        selectedCharacteristics: characteristics,
      },
      () => {
        sessionStorage.setItem(
          "selectedCharacteristics",
          JSON.stringify(characteristics)
        );
      }
    );

    this.handleColumnChange(
      characteristics,
      this.state.selectedFields,
      this.state.selectedReviews
    );
  }

  handleChangeFields(infos) {
    const {
      target: { value },
    } = infos;

    let fields = [...this.state.selectedFields];
    fields = typeof value === "string" ? value.split(",") : value;

    fields.forEach((elm) => {
      elm.isFrom = "fields";
    });

    fields.forEach((firstElm) => {
      fields.forEach((secondElm) => {
        if (
          fields.indexOf(firstElm) !== fields.indexOf(secondElm) &&
          firstElm.value === secondElm.value
        ) {
          fields.splice(fields.indexOf(firstElm), 1);
          fields.splice(fields.indexOf(secondElm), 1);
        }
      });
    });

    this.setState(
      {
        selectedFields: fields,
      },
      () => {
        sessionStorage.setItem("selectedFields", JSON.stringify(fields));
      }
    );

    this.handleColumnChange(
      this.state.selectedCharacteristics,
      fields,
      this.state.selectedReviews
    );
  }

  handleChangeReviews(infos) {
    const {
      target: { value },
    } = infos;

    let reviews = [...this.state.selectedReviews];
    reviews = typeof value === "string" ? value.split(",") : value;

    reviews.forEach((elm) => {
      elm.isFrom = "reviews";
    });

    reviews.forEach((firstElm) => {
      reviews.forEach((secondElm) => {
        if (
          reviews.indexOf(firstElm) !== reviews.indexOf(secondElm) &&
          firstElm.value === secondElm.value
        ) {
          reviews.splice(reviews.indexOf(firstElm), 1);
          reviews.splice(reviews.indexOf(secondElm), 1);
        }
      });
    });

    this.setState(
      {
        selectedReviews: reviews,
      },
      () => {
        sessionStorage.setItem("selectedReviews", JSON.stringify(reviews));
      }
    );

    this.handleColumnChange(
      this.state.selectedCharacteristics,
      this.state.selectedFields,
      reviews
    );
  }

  findArrayElementByTitle(array) {
    return array.find((element) => {
      return element.value === this.state.selectedColumn;
    });
  }

  displayToastErrorMessage(error) {
    if (!this._ismounted) {
      return;
    }
    this.props.addToast(HttpHelper.getErrorMessage(error), {
      type: "error",
      autoDismiss: true,
    });
  }

  next() {
    let selectedColumn = this.findArrayElementByTitle(
      this.state.columnsOptions
    );
    const urlParams = {
      title: this.state.formData.title,
      url: this.state.formData.url,
      index: selectedColumn.value,
      type: this.state.formData.type,
      productSourcesId: this.state.formData.productSourcesId,
      filepath: this.state.formData.filepath,
      selectedSheet: this.state.selectedSheet,
      selectedColumn: this.state.selectedColumn,
      selectedColumnName: selectedColumn.label,
      removeHeader: this.state.removeHeader,
      default_language: this.state.languageInfos,
    };

    const selectedCharacteristicsList = [];
    const selectedFieldsList = [];
    const selectedReviewsList = [];

    if (this.state.selectedCharacteristics !== null) {
      this.state.selectedCharacteristics.forEach((elm) => {
        selectedCharacteristicsList.push(elm.value);
      });
    }

    if (this.state.selectedFields !== null) {
      this.state.selectedFields.forEach((elm) => {
        selectedFieldsList.push(elm.value);
      });
    }

    if (this.state.selectedReviews !== null) {
      this.state.selectedReviews.forEach((elm) => {
        selectedReviewsList.push(elm.value);
      });
    }

    const analyseParams = {
      profileCharacteristics: selectedCharacteristicsList,
      profileFields: selectedFieldsList,
      reviews_feedback: selectedReviewsList,
      filepath: this.state.formData.filepath,
      sheet_name: this.state.selectedSheet,
    };

    mediaService.post.getFilePreview(
      analyseParams,
      (response) => {
        this.props.history.push(
          HttpHelper.addParametersToUrl(
            PROJECT_ADD_MEDIA_CONFIRM(this.state.projectId),
            urlParams
          ),
          {
            referrer: document.location.pathname,
            analyseParams: analyseParams,
            preview: response.file_data,
          }
        );
      },
      (error) => {
        this.displayToastErrorMessage(error);
      }
    );
  }

  render() {
    const { t } = this.props;
    let page_title = `${t("Add data source")} - ${
      globalConstants.WEBSITE_TITLE
    }`;
    const buttonIsEnabled = this.state.selectedReviews.length > 0;

    const project = this.state.project;

    const ITEM_HEIGHT = 41;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

    if (this.state.isLoaded) {
      return (
        <Fragment>
          <Helmet>
            <title>{page_title}</title>
          </Helmet>
          <MediaHeader
            history={this.props.history}
            title={t("Add data source")}
          />
          <div className="display-flex justify-content-center align-items-flex-start ">
            <div className="width-520 mobile-full-width padding-top-50 padding-bottom-20 selectSheetView">
              <div
                style={{ width: "90%", maxWidth: "768px", margin: "0 auto" }}
              >
                <div>
                  {project && (
                    <div className="padding-bottom-4">
                      <Link
                        to={PROJECT_DETAIL(project.id)}
                        className="font-size-18 link-grey transition-color text-break-line"
                      >
                        {project.name}
                      </Link>
                    </div>
                  )}
                  <div className="color-000000 font-size-32 font-inter-700">
                    Customize Import Options
                  </div>
                </div>
                {!this.state.dataIsLoaded && (
                  <div className="text-align-center padding-vertical-80 margin-bottom-20">
                    <Loader />
                  </div>
                )}
                {this.state.dataIsLoaded && (
                  <Fragment>
                    <p className="margin-top-16 font-size-15 link-grey transition-color text-break-line">
                      Let's match the columns in your uploaded file to Sensia.
                    </p>

                    <div className="detailsListSelect select-field">
                      <p>
                        <img src={simple} alt="#" />
                        Profile fields
                      </p>
                      <p>
                        A close-ended answer about the user that would be used
                        to make the filters you need.
                      </p>
                      <FormControl sx={{ width: "100%" }}>
                        <InputLabel className="report-multiple-checkbox">
                          Columns
                        </InputLabel>
                        <Select
                          className="multiple-checkbox"
                          multiple
                          value={this.state.selectedFields}
                          onChange={this.handleChangeFields}
                          input={<OutlinedInput label="Columns" />}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip
                                  style={{
                                    border: "2px solid #616161",
                                    borderRadius: "3px",
                                    backgroundColor: "transparent",
                                  }}
                                  key={value.value}
                                  label={value.label}
                                />
                              ))}
                            </Box>
                          )}
                          MenuProps={MenuProps}
                        >
                          {this.state.columnsOptions.map((name) => (
                            <MenuItem
                              key={name.value}
                              value={name}
                              disabled={
                                name.isFrom === "fields"
                                  ? false
                                  : name.isDisabled
                              }
                            >
                              <Checkbox
                                checked={this.state.selectedFields.some(
                                  (elm) => {
                                    if (
                                      elm.label === name.label &&
                                      elm.value === name.value &&
                                      elm.isFrom === name.isFrom &&
                                      elm.isDisabled === name.isDisabled
                                    ) {
                                      return true;
                                    } else {
                                      return false;
                                    }
                                  }
                                )}
                              />
                              <ListItemText primary={name.label} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="detailsListSelect select-field">
                      <p>
                        <img src={test} alt="#" />
                        Reviews / Feedback
                      </p>
                      <p>
                        Any open-ended comment or feedback about the product
                        that will be analyzed by our AI.
                      </p>
                      <FormControl sx={{ width: "100%" }}>
                        <InputLabel className="report-multiple-checkbox">
                          Columns
                        </InputLabel>
                        <Select
                          className="multiple-checkbox"
                          multiple
                          value={this.state.selectedReviews}
                          onChange={this.handleChangeReviews}
                          input={<OutlinedInput label="Columns" />}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip
                                  style={{
                                    border: "2px solid #616161",
                                    borderRadius: "3px",
                                    backgroundColor: "transparent",
                                  }}
                                  key={value.value}
                                  label={value.label}
                                />
                              ))}
                            </Box>
                          )}
                          MenuProps={MenuProps}
                        >
                          {this.state.columnsOptions.map((name) => (
                            <MenuItem
                              key={name.value}
                              value={name}
                              disabled={
                                name.isFrom === "reviews"
                                  ? false
                                  : name.isDisabled
                              }
                            >
                              <Checkbox
                                checked={this.state.selectedReviews.some(
                                  (elm) => {
                                    if (
                                      elm.label === name.label &&
                                      elm.value === name.value &&
                                      elm.isFrom === name.isFrom &&
                                      elm.isDisabled === name.isDisabled
                                    ) {
                                      return true;
                                    } else {
                                      return false;
                                    }
                                  }
                                )}
                              />
                              <ListItemText primary={name.label} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </Fragment>
                )}
                <div className="nextPageBtn margin-top-35">
                  <button
                    type="button"
                    className="button backgroundless color-6d9eee"
                    onClick={this.props.history.goBack}
                  >
                    {t("Back")}
                  </button>
                  <button
                    onClick={this.next}
                    disabled={!buttonIsEnabled}
                    className="margin-left-5 button backgroundless color-306ed6"
                  >
                    {t("Next")}
                  </button>
                </div>
              </div>
            </div>
            <div className="width-60" />

            <div className="width-520 padding-top-50 padding-bottom-20 display-only-large-screen">
              <Box
                sx={{
                  backgroundImage: `url(${addDataSource2Img})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  height: "545px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography
                  width="288px"
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "15px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "normal",
                    letterSpacing: "0.15px",
                    mt: "220px",
                    textAlign: "center",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    textWrap: "nowrap",
                  }}
                >
                  {this.state.previewSelectedSheet}
                </Typography>
              </Box>
            </div>
          </div>
        </Fragment>
      );
    }
    return (
      <LoadingView
        title={page_title}
        withHeader={false}
        headerProps={{ isProject: true }}
      />
    );
  }
}

export default withTranslation()(SelectSheetFields);
