import React from 'react';
import '../form.css';
import Select, { components } from 'react-select';
import TextField from './TextField';
import { countryPhonesList } from './fieldDatas';
import { customStyles } from './SelectField';

const SingleOption = (props) => {
    return (
        <components.Option {...props}>
            <span className="content-flag-select">
                <img alt={props.data.value} src={`https://flagcdn.com/w160/${props.data.value.toLowerCase()}.png`} />
                <span className="font-size-12 padding-left-5">{props.data.value}</span>
            </span>
        </components.Option>
    );
};

const SingleValue = (props) => {
    return (
        <components.SingleValue {...props}>
            <span className="content-flag-select">
                <img alt={props.data.value} src={`https://flagcdn.com/w160/${props.data.value.toLowerCase()}.png`} />
            </span>
        </components.SingleValue>
    );
};

class PhoneNumberSelectField extends React.Component {
    constructor(props) {
        super(props);

        let phoneCountryOptions = [];
        countryPhonesList.forEach(element => {
            phoneCountryOptions.push({
                label: element.name,
                value: element.code,
                dial_code: element.dial_code,
                target: {
                    name: props.namePhoneCountry,
                    value: element.code
                }
            })
        });

        this.state = {
            phoneCountryOptions: phoneCountryOptions,
            phone_number: this.props.defaultValue
        };
        this.renderErrorMessage = this.renderErrorMessage.bind(this);
        this.getPhoneCountryDefaultValue = this.getPhoneCountryDefaultValue.bind(this);
        this.handleChangePhoneCountry = this.handleChangePhoneCountry.bind(this);
        this.handleChangePhoneNumber = this.handleChangePhoneNumber.bind(this);
    }


    renderErrorMessage(errorMessage) {
        if (errorMessage) {
            return (
                <div className="form-error font-work-sans-400 color-fd3e7b font-size-12 text-align-right">
                    {errorMessage}
                </div>
            );
        }
        return null;
    }

    getPhoneCountryDefaultValue() {
        const target = this.props.defaultValuePhoneCountry ? this.props.defaultValuePhoneCountry : null;
        let ret = null;
        this.state.phoneCountryOptions.forEach(element => {
            if (element.value === target) {
                ret = element;
            }
        });
        return ret;
    }

    handleChangePhoneCountry(event) {
        if (this.props.onChange) {
            this.props.onChange(event);
            if (event.dial_code) {
                setTimeout(() => {
                    this.setState({
                        phone_number: event.dial_code
                    })
                    this.props.onChange(
                        {
                            target: {
                                name: this.props.name,
                                value: event.dial_code
                            }
                        },
                        true
                    );
                }, 300);
            }
        }
    }

    handleChangePhoneNumber(event) {
        this.setState({
            phone_number: event.target.value
        })
        this.props.onChange(event)
    }

    render() {
        let formElementClassName = ["form-element", "select"];
        if (this.props.formElementClassName) {
            formElementClassName.push(this.props.formElementClassName);
        }
        if (this.props.errorMessage) {
            formElementClassName.push('error');
        }
        return (
            <div className={formElementClassName.join(' ')}>
                <label className="font-inter-600 font-size-15 color-969696">
                    {this.props.label}
                </label>
                <div className="content-column no-hidden">
                    <div className="column-percent-25">
                        <Select
                            defaultValue={this.getPhoneCountryDefaultValue()}
                            isDisabled={this.props.disabled}
                            isClearable={false}
                            placeholder={this.props.placeholder}
                            isSearchable={true}
                            name={this.props.namePhoneCountry}
                            options={this.state.phoneCountryOptions}
                            onChange={this.handleChangePhoneCountry}
                            styles={customStyles}
                            components={{
                                SingleValue: SingleValue,
                                Option: SingleOption
                            }}
                        />
                    </div>
                    <div className="column-percent-75 padding-left-20">
                        <TextField
                            formElementClassName={'margin-bottom-0'}
                            type="text"
                            hideLabel={true}
                            name={this.props.name}
                            value={this.state.phone_number}
                            disabled={this.props.disabled}
                            onChange={this.handleChangePhoneNumber}
                        />
                    </div>
                </div>
                {this.renderErrorMessage(this.props.errorMessage)}
            </div>
        );
    }
}

export default PhoneNumberSelectField;