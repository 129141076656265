import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";
import { components } from "react-select";
import ReportHelper from "../../../app/helpers/ReportHelper";
import ColoredScore from "../../../components/ui/ColoredScore";
import picTagClear from "../../../res/images/pic-tag-clear.svg";
import AsyncSelect from "react-select/async";
import AjaxKeywordTooltipContent from "../../../components/tooltip/AjaxKeywordTooltipContent";
import TooltipWithAction from "../../../components/tooltip/TooltipWithAction";
import { workspaceService } from "../../../app/services/workspace.service";
import StringHelper from "../../../app/helpers/StringHelper";
import "./style.css";

const customSelectStyles = {
  menu: (base) => ({ ...base, zIndex: 17 }),
  multiValueLabel: (provided, state) => {
    const customStyle = {
      padding: "4px",
      fontSize: "15px",
      color: "#000000",
      paddingRight: "0",
    };
    return { ...provided, ...customStyle };
  },
  multiValue: (provided, state) => {
    const customStyle = {
      backgroundColor: "transparent",
      borderRadius: "4px",
      border: "2px solid #646267",
    };

    return { ...provided, ...customStyle };
  },
  input: (provided, state) => {
    const customStyle = {
      verticalAlign: "top",
    };
    return { ...provided, ...customStyle };
  },
  valueContainer: (provided, state) => {
    const customStyle = {
      width: "443px",
      paddingTop: "8px",
      paddingBottom: "8px",
      maxHeight: "120px",
      overflowY: "auto",
    };

    return { ...provided, ...customStyle };
  },
  control: (base) => ({
    ...base,
    borderColor: "#b4b4b4!important",
    boxShadow: "none",
  }),
  dropdownIndicator: (provided, state) => {
    const customStyle = {
      color: "#3a79e6",
    };
    return { ...provided, ...customStyle };
  },
  clearIndicator: (provided, state) => {
    const customStyle = {
      color: "#3a79e6",
    };
    return { ...provided, ...customStyle };
  },
  multiValueRemove: (provided, state) => {
    const customStyle = {
      backgroundColor: "transparent!important",
      backgroundImage: 'url("' + picTagClear + '")',
      width: "28px",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      backgroundRepeat: "no-repeat",
      color: "transparent",
      cursor: "pointer",
      transition: "opacity 0.3s ease",
      "&:hover": {
        opacity: "0.7",
        color: "transparent",
      },
    };

    return { ...provided, ...customStyle };
  },
};

const Input = (props) => {
  let newProps = Object.assign({}, props);
  const optionSelectedLength = newProps.getValue().length || 0;
  if (optionSelectedLength >= 20) {
    newProps.disabled = true;
  }
  return <components.Input {...newProps} />;
};
const Menu = (props) => {
  const optionSelectedLength = props.getValue().length || 0;
  if (optionSelectedLength < 20) {
    return <components.Menu {...props} />;
  }
  return null;
};

const Option = (props) => {
  const item = props.data;
  const { innerProps, innerRef } = props;
  let classNames = ["report-option", "transition-color"];
  if (props.isFocused) {
    classNames.push("isFocused");
  }
  if (props.isSelected) {
    classNames.push("isSelected");
  }
  return (
    <div className={classNames.join(" ")} ref={innerRef} {...innerProps}>
      <div className="font-size-16 font-inter-500 color-3a79e6 keyword-note">
        <ColoredScore score={item.average_score} />
      </div>
      <div className="text-ellipsis margin-right-45">
        <span className="font-size-16 font-inter-400 color-000">
          {StringHelper.capitalize(item.label)}
        </span>
        <span className="font-size-12 font-inter-400 color-7f7f7f padding-left-4">
          ({item.occurence})
        </span>
      </div>
    </div>
  );
};

class SelectKeyword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.defaultValue ? this.props.defaultValue : null,
      projectId: this.props.project_id,
      datasourceId: this.props.datasource_id,
      inputValue: "",
      word_data: this.props.defaultValue,
      newValue: this.props.defaultValue ? this.props.defaultValue : [],
      isFetchingData: false,
    };

    this.myRef = React.createRef();
    this.abortController = new AbortController();

    this.getTooltipContent = this.getTooltipContent.bind(this);
    this.MultiValueContainer = this.MultiValueContainer.bind(this);
    this.promiseOptions = this.promiseOptions.bind(this);
    this.abortRequest = this.abortRequest.bind(this);
  }

  componentWillUnmount() {
    this.abortRequest();
  }

  handleInputChange = (newValue) => {
    const inputValue = newValue.trim();
    this.setState({ inputValue });
    return inputValue;
  };

  handleChange = (newValue) => {
    this.setState({ newValue });
    if (this.props.onChange) {
      this.props.onChange(newValue);
    }
    return newValue;
  };

  getTooltipContent(item) {
    if (!item) {
      return null;
    }
    return (
      <div className="base-tooltip">
        <AjaxKeywordTooltipContent
          projectId={this.props.project_id}
          datasourceId={this.props.datasource_id}
          keyword={item.value}
          onOpenUpdateKeywordClassModal={
            this.props.onOpenUpdateKeywordClassModal
          }
        />
      </div>
    );
  }

  MultiValueContainer(props) {
    return (
      <TooltipWithAction
        content={this.getTooltipContent(props.data)}
        placement="bottom"
      >
        <components.MultiValueContainer {...props} />
      </TooltipWithAction>
    );
  }

  abortRequest() {
    this.abortController.abort();
  }

  promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      if (inputValue.length >= 2) {
        setTimeout(() => {
          if (this.state.isFetchingData) {
            this.abortRequest();
            this.abortController = new AbortController();
          }
          this.setState({ isFetchingData: true });
          const formData = this.props.datasource_id
            ? { datasource_id: this.props.datasource_id }
            : {};
          workspaceService.get.get(
            this.state.projectId,
            this.state.inputValue,
            formData,
            this.abortController.signal,
            (response) => {
              this.setState(
                {
                  input_word: response.data,
                  isFetchingData: false,
                },
                () => {
                  resolve(this.state.input_word);
                }
              );
            },
            (error) => {
              //console.error('error', error)
            }
          );
        }, 100);
      }
    });

  render() {
    const { t } = this.props;
    return (
      <div
        className={this.props.className}
        customlabel={this.props.customlabel}
      >
        <AsyncSelect
          isMulti
          cacheOptions
          components={{
            MultiValueContainer: this.MultiValueContainer,
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            Option,
          }}
          loadOptions={this.promiseOptions}
          onChange={this.handleChange}
          onInputChange={this.handleInputChange}
          noOptionsMessage={(props) => {
            if (props.inputValue === "") {
              return null;
            }
            return t("No attribute found");
          }}
          styles={customSelectStyles}
          defaultValue={this.state.value}
        />
      </div>
    );
  }
}

export default withTranslation()(SelectKeyword);
