import React from "react";
import { Button, Typography } from "@mui/material";
import google_logo from "../../../res/images/logo/google_icon.png";
import { useGoogleLogin } from "@react-oauth/google";

export const GoogleSigninButton = ({
  title = "Sign up with Google",
  onSuccess,
  onError,
}) => {
  const handleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => onSuccess(tokenResponse),
    onError: (errorResponse) => onError(errorResponse),
    scope:
      "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid",
  });

  return (
    <Button
      sx={{
        display: "flex",
        width: "100%",
        minWidth: "240px",
        padding: "6px 8px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        borderRadius: "6px",
        background: "#F2F3F5",
        textTransform: "none",
      }}
      onClick={() => handleLogin()}
    >
      <img src={google_logo} width="28px" />{" "}
      <Typography
        sx={{
          color: "#000",
          /* Components/Button Medium */
          fontFamily: "Inter",
          fontSize: "15px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "normal",
        }}
      >
        {title}
      </Typography>
    </Button>
  );
};
