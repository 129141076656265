import '../settings.css';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { globalConstants } from "../../../app/constants";
import { connect } from 'react-redux';
import Header from "../../../components/header/Header";
import Footer from '../../../components/footer/Footer';
import LoadingView from '../../loading/LoadingView';

import { withTranslation } from 'react-i18next';
import SettingsMenu from '../elements/SettingsMenu';
import { ROUTES, SETTINGS_USERS_AND_TEAMS } from '../../../app/routing';
import HttpHelper from '../../../app/helpers/HttpHelper';
import { uniqueId } from 'lodash';
import UsersTab from './elements/UsersTab';
import TeamsTab from './elements/TeamsTab';
import Loader from '../../../components/loader/Loader';
import { userService } from '../../../app/services/user.service';
import { accountService } from '../../../app/services/account.service';
import UserHelper from '../../../app/helpers/UserHelper';
import { teamService } from '../../../app/services/team.service';
import SettingsLoadingView from '../../loading/SettingsLoadingView';

const TABS = {
    USERS: 'users',
    TEAMS: 'teams'
};

class UsersAndTeamsView extends React.Component {
    constructor(props) {
        super(props);
        const activeTab = (props.match.params.tab) ? props.match.params.tab : TABS.USERS;
        this.state = {
            isLoaded: false,
            inlineLoading: false,
            formKey: uniqueId(),
            activeTab: activeTab,
            users: null,
            teams: null,
            account: null,
        };

        this.fetchInitial = this.fetchInitial.bind(this);
        this.fetchUsers = this.fetchUsers.bind(this);
        this.fetchTeams = this.fetchTeams.bind(this);
        this.fetchAccount = this.fetchAccount.bind(this);
        this.onTabClick = this.onTabClick.bind(this);
    }

    componentDidMount() {
        this._ismounted = true;
        this.fetchInitial();
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    fetchInitial() {
        if (this.state.activeTab === TABS.USERS) {
            this.fetchUsers(false, true);
        } else {
            this.fetchTeams();
        }
        this.fetchAccount();
    }

    fetchAccount() {
        accountService.getAccountData(
            (response) => {
                this.setState({
                    isLoaded: true,
                    inlineLoading: false,
                    account: response
                });
            },
            (error) => {
                if(!this._ismounted){
                    return;
                }
                this.setState({
                    isLoaded: true,
                    inlineLoading: false
                });
                this.props.addToast(HttpHelper.getErrorMessage(error), { type: 'error', autoDismiss: true });
            }
        );
    }

    fetchUsers(reset, fetchTeams) {
        if (this._ismounted) {
            this.setState({ inlineLoading: true });
        }
        let current_account = UserHelper.getCurrentAccount()
        userService.getAccountUsers(
            current_account.id,
            (response) => {
                const users = response.users;
                if (fetchTeams) {
                    this.setState({
                        users: users
                    });
                    this.fetchTeams();
                } else {
                    this.setState({
                        inlineLoading: false,
                        users: users
                    });
                }
                if (reset) {
                    this.setState({
                        teams: null
                    });
                }
            },
            (error) => {
                if(!this._ismounted){
                    return;
                }
                this.setState({
                    isLoaded: true,
                    inlineLoading: false,
                    users: []
                });
                this.props.addToast(HttpHelper.getErrorMessage(error), { type: 'error', autoDismiss: true });
            }
        );
    }

    fetchTeams(reset) {
        if (this._ismounted) {
            this.setState({ inlineLoading: true });
        }
        let account = UserHelper.getCurrentAccount()
        teamService.getAll(
            account.id,
            (response) => {
                const teams = response.teams;
                this.setState({
                    inlineLoading: false,
                    teams: teams
                });
                if (reset) {
                    this.setState({
                        users: null
                    });
                }
            },
            (error) => {
                if(!this._ismounted){
                    return;
                }
                this.setState({
                    inlineLoading: false,
                    teams: []
                });
                this.props.addToast(HttpHelper.getErrorMessage(error), { type: 'error', autoDismiss: true });
            }
        );
    }

    onTabClick(item) {
        if (this.state.activeTab !== item.value) {
            this.setState({ activeTab: item.value });
            this.props.history.replace({ pathname: SETTINGS_USERS_AND_TEAMS(item.value) });
            if (item.value === TABS.USERS && !this.state.users) {
                this.fetchUsers();
            } else if (item.value === TABS.TEAMS && !this.state.teams) {
                this.fetchTeams();
            }
        }
    }

    render() {
        const { t } = this.props;
        let page_title = `${t('Users & Teams')} - ${globalConstants.WEBSITE_TITLE}`;
        const tabs = [
            {
                active: (this.state.activeTab === TABS.USERS),
                label: t('Users'),
                value: TABS.USERS
            },
            {
                active: (this.state.activeTab === TABS.TEAMS),
                label: t('Teams'),
                value: TABS.TEAMS
            }
        ];
        if (this.state.isLoaded) {
            return (
                <Fragment>
                    <Helmet>
                        <title>{page_title}</title>
                    </Helmet>
                    <Header />
                    <div className="padding-top-30" />
                    <div className="central-content settings-content" key={this.state.formKey}>
                        <div className="left">
                            <SettingsMenu current={ROUTES.SETTINGS_USERS_AND_TEAMS_BASE} history={this.props.history} />
                        </div>
                        <div className="right">
                            <div className="padding-top-32" />
                            <div className="font-inter-700 font-size-28 color-000 padding-bottom-40">
                                {t('Users & Teams')}
                            </div>
                            <div className="content-settings-tabs">
                                {tabs.map((item, i) => {
                                    let classNames = ['tab', 'transition', 'font-inter-400'];
                                    if (item.active) {
                                        classNames.push('active');
                                    }
                                    return (
                                        <div key={`tab-${item.value}`} className={classNames.join(' ')} onClick={() => { this.onTabClick(item) }}>
                                            {item.label}
                                        </div>
                                    );
                                })}
                            </div>
                            {this.state.inlineLoading && (
                                <div className="padding-vertical-30">
                                    <Loader />
                                </div>
                            )}
                            {!this.state.inlineLoading && this.state.activeTab === TABS.USERS && <UsersTab users={this.state.users} teams={this.state.teams} fetchUsers={this.fetchUsers} fetchTeams={this.fetchTeams} addToast={this.props.addToast} location={this.props.location} history={this.props.history} account={this.state.account}/>}
                            {!this.state.inlineLoading && this.state.activeTab === TABS.TEAMS && <TeamsTab teams={this.state.teams} fetchTeams={this.fetchTeams} fetchUsers={this.fetchUsers} addToast={this.props.addToast} location={this.props.location} history={this.props.history} />}
                        </div>
                    </div>
                    <div className="padding-top-60" />
                    <Footer />
                </Fragment>
            );
        }
        return <SettingsLoadingView title={page_title} withHeader={true} rightTitle={t('Users & Teams')} settingsMenuProps={{current:ROUTES.SETTINGS_USERS_AND_TEAMS_BASE, history:this.props.history}} />;
    }
}

function mapStateToProps(state) {
    return {};
}

export default withTranslation()(connect(mapStateToProps)(UsersAndTeamsView));