import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import Select from 'react-select'
import picTagClear from '../../../res/images/pic-tag-clear.svg';


const customSelectStyles = {
    menu: base => ({ ...base, zIndex: 5 }),
    multiValueLabel: (provided, state) => {
        const customStyle = {
            fontSize: '15px',
            color: '#000000',
            padding: '5px',
            paddingRight: '0'
        };
        return { ...provided, ...customStyle };
    },
    multiValue: (provided, state) => {
        const customStyle = {
            backgroundColor: 'transparent',
            borderRadius: '4px',
            border: '2px solid #b4b4b4',
        };

        return { ...provided, ...customStyle };
    },
    input: (provided, state) => {
        const customStyle = {
            verticalAlign: 'top'
        };
        return { ...provided, ...customStyle };
    },
    valueContainer: (provided, state) => {
        const customStyle = {
            paddingTop: '0',
            paddingBottom: '0'
        };

        return { ...provided, ...customStyle };
    },
    control: base => ({
        ...base,
        borderColor: '#b4b4b4!important',
        boxShadow: 'none'
    }),
    multiValueRemove: (provided, state) => {
        const customStyle = {
            backgroundColor: 'transparent!important',
            backgroundImage: 'url("' + picTagClear + '")',
            width: "28px",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            color: "transparent",
            cursor: "pointer",
            transition: "opacity 0.3s ease",
            "&:hover": {
                opacity: "0.7",
                color: "transparent"
            }
        };

        return { ...provided, ...customStyle };
    },
    menuList: styles => ({
        ...styles,
        maxHeight: '90px'
    }),
};


class SelectIntegratedClass extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newValue: [],
        }
    }

    componentDidMount() {
        const { selected } = this.props;
        this.setState({ newValue: selected });
    }

    handleChange = (newValue) => {
        this.setState({ newValue });
        if (this.props.onChange) {
            this.props.onChange(newValue);
        }
        return newValue;
    };

    render() {
        const { t, classList, disabled } = this.props;
        return (
            <Fragment>
                <label className="padding-bottom-4 padding-top-40 display-block font-inter-500 color-969696 font-size-15">{this.props.labelTitle}</label>
                <Select
                    onChange={this.handleChange}
                    options={classList}
                    isMulti
                    styles={customSelectStyles}
                    value={this.state.newValue}
                    isDisabled={disabled}
                />
            </Fragment>
        );
    }
}

export default withTranslation()(SelectIntegratedClass);