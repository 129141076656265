import React from "react";
import { withTranslation } from "react-i18next";
import Loader from "../../../components/loader/Loader";
import Modal from "../../../components/modal/Modal";
import { modelService } from "../../../app/services/model.service";
import HttpHelper from "../../../app/helpers/HttpHelper";
import { classService } from "../../../app/services/class.service";
import SelectIntegratedClass from "../elements/SelectIntegratedClass";
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Option,
} from "@mui/joy";
import Select from "@mui/joy/Select";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";

class EditModelModal extends React.Component {
  constructor(props) {
    super(props);
    const { item } = props;

    this.state = {
      initialFormData: {
        title: item.title,
        description: item.description,
        sort_order: item.sort_order,
        key: item.key,
        main_category: item.main_category,
        product_category_id: item.id,
        emotion_model_id: item.emotion_model ? item.emotion_model.id : null,
        score_model_id: item.score_model ? item.score_model.id : null,
        accounts: item.accounts?.join(","),
        selectedClassesArray: [], //liste des classes séparées par des points virgules
      },
      formData: {
        title: item.title,
        description: item.description,
        sort_order: item.sort_order,
        key: item.key,
        main_category: item.main_category,
        product_category_id: item.id,
        emotion_model_id: item.emotion_model ? item.emotion_model.id : null,
        score_model_id: item.score_model ? item.score_model.id : null,
        accounts: item.accounts?.join(","),
        selectedClassesArray: [],
      },
      inputClassesList: null,
      score_models: null,
      emotion_models: null,
      errors: null,
      isLoaded: false,
    };

    this.handleChangeEmotion = this.handleChangeEmotion.bind(this);
    this.handleChangeScore = this.handleChangeScore.bind(this);
    this.formIsValid = this.formIsValid.bind(this);
    this.formIsUpdated = this.formIsUpdated.bind(this);
    this.getErrorMessage = this.getErrorMessage.bind(this);
    this.fetchModels = this.fetchModels.bind(this);
    this.handleChangeIntegratedClasses =
      this.handleChangeIntegratedClasses.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeDescription = this.handleChangeDescription.bind(this);
    this.handleChangeOrder = this.handleChangeOrder.bind(this);
    this.handleChangeKey = this.handleChangeKey.bind(this);
    this.handleChangeMainCategory = this.handleChangeMainCategory.bind(this);
    this.handleChangeAccounts = this.handleChangeAccounts.bind(this);
    this.getIntegratedClasses = this.getIntegratedClasses.bind(this);
    this.getSelectedClassesArray = this.getSelectedClassesArray.bind(this);
  }

  componentDidMount() {
    this.fetchModels();
    this.getIntegratedClasses();
    this.getSelectedClassesArray();
  }

  fetchModels() {
    modelService.getInfosModels(
      (response) => {
        let score_models = [];
        response.score_models.map((item, index) => {
          score_models.push({ value: item.id, label: item.title });
        });
        let emotion_models = [];
        response.emotion_models.map((item, index) => {
          emotion_models.push({ value: item.id, label: item.title });
        });

        this.setState({
          isLoaded: true,
          keys: response.keys,
          main_categories: response.main_categories,
          score_models: score_models,
          emotion_models: emotion_models,
        });
      },
      (error) => {
        if (this._ismounted) {
          this.setState({ isLoaded: true });
          this.props.addToast(HttpHelper.getErrorMessage(error), {
            type: "error",
            autoDismiss: true,
          });
        }
      }
    );
  }

  getSelectedClassesArray() {
    let classes = this.props.item.classes;
    let selectedClassesArray = [];
    classes.map((element, index) => {
      selectedClassesArray.push({
        value: element.id,
        label: element.title,
      });
    });
    this.setState({
      formData: {
        ...this.state.formData,
        selectedClassesArray: selectedClassesArray,
      },
      initialFormData: {
        ...this.state.initialFormData,
        selectedClassesArray: selectedClassesArray,
      },
    });
  }

  handleChangeName(event) {
    this.setState({
      formData: {
        ...this.state.formData,
        title: event.target.value,
      },
    });
  }

  handleChangeDescription(event) {
    this.setState({
      formData: {
        ...this.state.formData,
        description: event.target.value,
      },
    });
  }

  handleChangeOrder(event) {
    this.setState({
      formData: {
        ...this.state.formData,
        sort_order: event.target.value,
      },
    });
  }

  handleChangeKey(value) {
    this.setState({
      formData: {
        ...this.state.formData,
        key: value,
      },
    });
  }

  handleChangeMainCategory(value) {
    this.setState({
      formData: {
        ...this.state.formData,
        main_category: value,
      },
    });
  }

  handleChangeEmotion(value) {
    this.setState({
      formData: {
        ...this.state.formData,
        emotion_model_id: value,
      },
    });
  }

  handleChangeScore(value) {
    this.setState({
      formData: {
        ...this.state.formData,
        score_model_id: value,
      },
    });
  }

  handleChangeAccounts(event) {
    let errors = Object.assign({}, this.state.errors);
    if (/^(\d+,)*\d*$/.test(event.target.value) || event.target.value === "") {
      delete errors.accounts;
    } else {
      errors["accounts"] = "Only integers separated by commas are allowed";
    }

    this.setState({
      errors: errors,
      formData: {
        ...this.state.formData,
        accounts: event.target.value,
      },
    });
  }

  handleChangeIntegratedClasses(selectedClassesArray) {
    this.setState({
      formData: {
        ...this.state.formData,
        selectedClassesArray: selectedClassesArray,
      },
    });
  }

  getIntegratedClasses() {
    classService.get.getIntegrated(
      (response) => {
        let classListArray = [];
        response.map((item) => {
          classListArray.push({ value: item.id, label: item.display_name });
        });
        this.setState({
          inputClassesList: classListArray,
        });
      },
      (error) => {
        if (this._ismounted) {
          this.setState({ isLoaded: true });
          this.props.addToast(HttpHelper.getErrorMessage(error), {
            type: "error",
            autoDismiss: true,
          });
        }
      }
    );
  }

  getErrorMessage(target) {
    const { error } = this.props;
    if (this.state.errors && this.state.errors.hasOwnProperty(target)) {
      return this.state.errors[target];
    }
    if (error && error.hasOwnProperty(target)) {
      return error[target];
    }
    return null;
  }

  formIsValid() {
    if (
      this.state.formData.description != "" &&
      this.state.formData.sort_order != null &&
      this.state.formData.key != "" &&
      this.state.formData.main_category != "" &&
      this.state.formData.product_category_id &&
      this.state.formData.emotion_model_id &&
      this.state.formData.score_model_id &&
      (this.state.errors == null || Object.keys(this.state.errors).length == 0)
    ) {
      return true;
    }
    return false;
  }

  formIsUpdated() {
    return (
      this.state.formData.title !== this.state.initialFormData.title ||
      this.state.formData.description !==
        this.state.initialFormData.description ||
      this.state.formData.sort_order !==
        this.state.initialFormData.sort_order ||
      this.state.formData.key !== this.state.initialFormData.key ||
      this.state.formData.main_category !==
        this.state.initialFormData.main_category ||
      this.state.formData.score_model_id !==
        this.state.initialFormData.score_model_id ||
      this.state.formData.emotion_model_id !==
        this.state.initialFormData.emotion_model_id ||
      this.state.formData.accounts !== this.state.initialFormData.accounts ||
      this.state.formData.selectedClassesArray !==
        this.state.initialFormData.selectedClassesArray
    );
  }

  render() {
    const { t, item, loading } = this.props;
    const buttonIsEnabled = this.formIsValid() && this.formIsUpdated();
    return (
      <Modal display={this.props.display} onClose={this.props.onClose}>
        <div className="font-size-30 font-inter-700 color-000 padding-bottom-10">
          {t("Edit Product Category")}
        </div>
        <div className="font-size-25 font-inter-500 color-000 padding-bottom-40">
          {t(item.title)}
        </div>
        {this.state.isLoaded && (
          <Box>
            <div className="padding-bottom-36">
              <FormControl sx={{ mb: "10px" }}>
                <FormLabel>{t("Name")}</FormLabel>
                <Input
                  name="title"
                  disabled={loading}
                  defaultValue={this.props.item.title}
                  onChange={this.handleChangeName}
                  error={this.getErrorMessage("title")}
                  sx={{ background: "white" }}
                />
                <FormHelperText sx={{ color: "#e04f77" }}>
                  {this.getErrorMessage("title")}
                </FormHelperText>
              </FormControl>
              <FormControl sx={{ mb: "10px" }}>
                <FormLabel>{t("Description")}</FormLabel>
                <Input
                  name="description"
                  disabled={loading}
                  defaultValue={this.props.item.description}
                  onChange={this.handleChangeDescription}
                  sx={{ background: "white" }}
                />
              </FormControl>
              <FormControl sx={{ mb: "10px" }}>
                <FormLabel>{t("Order")}</FormLabel>
                <Input
                  name="sort_order"
                  type="number"
                  disabled={loading}
                  defaultValue={this.props.item.sort_order}
                  onKeyDown={(event) => {
                    console.log(event);
                    if ([",", ".", "e", "E"].includes(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={this.handleChangeOrder}
                  sx={{ background: "white" }}
                />
              </FormControl>
              <FormControl sx={{ mb: "10px" }}>
                <FormLabel>{t("Chose a main category")}</FormLabel>
                <Select
                  name="main_category"
                  disabled={loading}
                  defaultValue={this.props.item.main_category}
                  onChange={(e, newValue) =>
                    this.handleChangeMainCategory(newValue)
                  }
                  error={this.getErrorMessage("main_category")}
                  indicator={<KeyboardArrowDown />}
                  sx={{ background: "white" }}
                >
                  {this.state.main_categories?.map((elm) => {
                    return <Option value={elm.value}>{elm.label}</Option>;
                  })}
                </Select>
                <FormHelperText sx={{ color: "#e04f77" }}>
                  {this.getErrorMessage("main_category")}
                </FormHelperText>
              </FormControl>
              <FormControl sx={{ mb: "10px" }}>
                <FormLabel>{t("Chose a Key")}</FormLabel>
                <Select
                  name="key"
                  disabled={loading}
                  defaultValue={this.props.item.key}
                  onChange={(e, newValue) => this.handleChangeKey(newValue)}
                  error={this.getErrorMessage("key")}
                  indicator={<KeyboardArrowDown />}
                  sx={{ background: "white" }}
                >
                  {this.state.keys?.map((elm) => {
                    return <Option value={elm.value}>{elm.label}</Option>;
                  })}
                </Select>
                <FormHelperText sx={{ color: "#e04f77" }}>
                  {this.getErrorMessage("key")}
                </FormHelperText>
              </FormControl>
              <FormControl sx={{ mb: "10px" }}>
                <FormLabel>{t("Chose a Score Model")}</FormLabel>
                <Select
                  name="score_model"
                  disabled={loading}
                  defaultValue={this.props.item.score_model.id}
                  onChange={(e, newValue) => this.handleChangeScore(newValue)}
                  error={this.getErrorMessage("score_model")}
                  indicator={<KeyboardArrowDown />}
                  sx={{ background: "white" }}
                >
                  {this.state.score_models?.map((elm) => {
                    return <Option value={elm.value}>{elm.label}</Option>;
                  })}
                </Select>
                <FormHelperText sx={{ color: "#e04f77" }}>
                  {this.getErrorMessage("score_model")}
                </FormHelperText>
              </FormControl>
              <FormControl sx={{ mb: "10px" }}>
                <FormLabel>{t("Chose a Feeling Model")}</FormLabel>
                <Select
                  name="emotion_model"
                  disabled={loading}
                  defaultValue={this.props.item.emotion_model.id}
                  onChange={(e, newValue) => this.handleChangeEmotion(newValue)}
                  error={this.getErrorMessage("emotion_model")}
                  indicator={<KeyboardArrowDown />}
                  sx={{ background: "white" }}
                >
                  {this.state.emotion_models?.map((elm) => {
                    return <Option value={elm.value}>{elm.label}</Option>;
                  })}
                </Select>
                <FormHelperText sx={{ color: "#e04f77" }}>
                  {this.getErrorMessage("emotion_model")}
                </FormHelperText>
              </FormControl>
              <FormControl sx={{ mb: "10px" }}>
                <FormLabel>{t("Accounts")}</FormLabel>
                <Input
                  name="accounts"
                  disabled={loading}
                  defaultValue={this.props.item.accounts}
                  onChange={this.handleChangeAccounts}
                  error={this.getErrorMessage("accounts")}
                  sx={{ background: "white" }}
                />
                <FormHelperText sx={{ color: "#e04f77" }}>
                  {this.getErrorMessage("accounts")}
                </FormHelperText>
              </FormControl>
              {this.state.inputClassesList && (
                <SelectIntegratedClass
                  onChange={this.handleChangeIntegratedClasses}
                  classList={this.state.inputClassesList}
                  selected={this.state.formData.selectedClassesArray}
                  disabled={false}
                  labelTitle={"Select Default classe"}
                />
              )}
              {this.props.errorPlan && (
                <div className="content-error-plan">
                  {this.getMessageErrorPlan()}
                </div>
              )}
            </div>
            <div className="overflow-hidden">
              {!this.props.loading && (
                <div className="float-right">
                  <button
                    className="button backgroundless"
                    type="button"
                    onClick={this.props.onClose}
                  >
                    {t("Cancel")}
                  </button>
                  <button
                    className="button backgroundless margin-left-5"
                    disabled={!buttonIsEnabled}
                    onClick={() => {
                      this.props.onEditItem(this.state.formData);
                    }}
                  >
                    {t("Save")}
                  </button>
                </div>
              )}
              {this.props.loading && (
                <div className="float-right" style={{ padding: "2px 0" }}>
                  <Loader />
                </div>
              )}
            </div>
          </Box>
        )}
        {!this.state.isLoaded && <Loader />}
      </Modal>
    );
  }
}

export default withTranslation()(EditModelModal);
