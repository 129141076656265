import HttpHelper from '../helpers/HttpHelper';

function create(formData, onSuccess, onError) {
    return HttpHelper.authApiFetch("/api/v1/team/create", HttpHelper.method.POST, formData, onSuccess, onError);
}

async function getAll(account_id, onSuccess, onError) {
    return HttpHelper.authApiFetch("/api/v1/team/get-teams/" + account_id, HttpHelper.method.GET, null, onSuccess, onError);
}


function edit(formData, onSuccess, onError) {
    return HttpHelper.authApiFetch("/api/v1/team/edit-team", HttpHelper.method.POST, formData, onSuccess, onError);
}

async function deleteTeam(team_id, onSuccess, onError) {
    return HttpHelper.authApiFetch("/api/v1/team/delete-team/" + team_id, HttpHelper.method.DELETE, null, onSuccess, onError);
}


export const teamService = {
    create,
    getAll,
    edit,
    deleteTeam
}