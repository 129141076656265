import React from 'react';
import { withTranslation } from 'react-i18next';
import Loader from '../../../../components/loader/Loader';
import Modal from '../../../../components/modal/Modal';
import TextField from '../../../../components/form/field/TextField';
import SelectField from '../../../../components/form/field/SelectField';
import { USER_ROLES, USER_STATUS } from '../../../../app/constants';
import RadioGroupField from '../../../../components/form/field/RadioGroupField';
import StringHelper from '../../../../app/helpers/StringHelper';
import UserHelper from '../../../../app/helpers/UserHelper';


class EditUserModal extends React.Component {
    constructor(props) {
        super(props);
        const { t, item } = props;
        let teamsOptions = [];
        if (props.teams) {
            props.teams.forEach(element => {
                teamsOptions.push({
                    label: element.title,
                    value: element.id,
                    target: {
                        name: 'team_id',
                        value: element.id
                    }
                });
            });
        }
        const rolesOptions = [
            {
                label: t('Administrator'),
                value: USER_ROLES.ADMIN,
            },
            {
                label: t('User'),
                value: USER_ROLES.USER
            }
        ];
        const team_id = (item.team && item.team.id) ? item.team.id : null;
        this.state = {
            initialFormData: {
                email: item.email,
                team_id: team_id,
                role: item.role
            },
            formData: {
                email: item.email,
                team_id: team_id,
                role: item.role
            },
            errors: null,
            teamsOptions: teamsOptions,
            rolesOptions: rolesOptions
        };
        this.modalRef = React.createRef();
        this.handleChange = this.handleChange.bind(this);
        this.formIsValid = this.formIsValid.bind(this);
        this.formIsUpdated = this.formIsUpdated.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.getErrorMessage = this.getErrorMessage.bind(this);
        this.getTeamDefaultValue = this.getTeamDefaultValue.bind(this);
        this.getErrors = this.getErrors.bind(this);
    }

    onCancelClick() {
        if (this.modalRef && this.modalRef.current) {
            this.modalRef.current.onModalCloseClick();
        }
    }

    getTeamDefaultValue() {
        if (!this.state.formData.team_id || this.state.teamsOptions.length === 0) {
            return null;
        }
        let ret = null;
        this.state.teamsOptions.forEach(element => {
            if (element.value === this.state.formData.team_id) {
                ret = element;
            }
        });
        return ret;
    }

    getErrors(event) {
        const { t } = this.props;
        let errors = this.state.errors ? Object.assign({}, this.state.errors) : {};
        if (event.target.name === 'email') {
            if (!StringHelper.isEmailValid(event.target.value)) {
                errors = { email: t('Please enter a valid email') };
            } else {
                delete errors.email;
            }
        }
        return (Object.keys(errors).length === 0) ? null : errors
    }

    handleChange(event) {
        let formData = Object.assign({}, this.state.formData);
        formData[event.target.name] = event.target.value;
        let errors = this.getErrors(event);
        this.setState({
            formData: formData,
            errors: errors
        });
    }

    getErrorMessage(target) {
        const { error } = this.props;
        if (this.state.errors && (this.state.errors.hasOwnProperty(target))) {
            return this.state.errors[target];
        }
        if (error && (error.hasOwnProperty(target))) {
            return error[target];
        }
        return null;
    }

    formIsValid() {
        if (this.state.formData.email && !this.state.errors) {
            return true;
        }
        return false;
    }

    formIsUpdated() {
        return (
            this.state.formData.email !== this.state.initialFormData.email ||
            this.state.formData.team_id !== this.state.initialFormData.team_id ||
            this.state.formData.role !== this.state.initialFormData.role)
    }

    render() {
        const { t, item, loading } = this.props;

        const buttonIsEnabled = this.formIsValid() && this.formIsUpdated();
        const isEmailDisabled = (item.status === USER_STATUS.PENDING) ? loading : true;
        const currentUser = UserHelper.getUser();
        const isRadioGroupDisabled = (currentUser.id === item.user_id);
        return (
            <Modal ref={this.modalRef} display={this.props.display} onClose={this.props.onClose}>
                <div className="font-size-30 font-inter-700 color-000 padding-bottom-40">{t('Edit User')}</div>
                <form>
                    <div className="padding-bottom-36">
                        <TextField
                            type="text"
                            label={t('Email')}
                            name="email"
                            defaultValue={this.state.formData.email}
                            onChange={this.handleChange}
                            disabled={isEmailDisabled}
                            errorMessage={this.getErrorMessage('email')}
                        />
                        <SelectField
                            label={t('Assign to team')}
                            name="team_id"
                            defaultValue={this.getTeamDefaultValue()}
                            options={this.state.teamsOptions}
                            onChange={this.handleChange}
                            errorMessage={this.getErrorMessage('team_id')}
                        />
                        <RadioGroupField
                            label={t('Access')}
                            name="role"
                            defaultValue={this.state.formData.role}
                            options={this.state.rolesOptions}
                            disabled={isRadioGroupDisabled}
                            onChange={this.handleChange}
                            errorMessage={this.getErrorMessage('role')}
                        />
                    </div>
                    <div className="overflow-hidden">
                        {!this.props.loading &&
                            <div className="float-right">
                                <button className="button backgroundless" type="button" onClick={this.onCancelClick}>{t('Cancel')}</button>
                                <button className="button backgroundless margin-left-5" disabled={!buttonIsEnabled} onClick={() => { this.props.onEditItem(this.state.formData) }}>{t('Save')}</button>
                            </div>
                        }
                        {this.props.loading &&
                            <div className="float-right" style={{ padding: "2px 0" }}>
                                <Loader />
                            </div>
                        }
                    </div>
                </form>
            </Modal>
        );
    }
}

export default withTranslation()(EditUserModal);