import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import StringHelper from '../../../app/helpers/StringHelper';
import TooltipWithAction from '../../../components/tooltip/TooltipWithAction';
import ColoredScore from '../../../components/ui/ColoredScore';
import Loader from '../../../components/loader/Loader';

import '../../projectReport/projectReport.css';


class SuggestionsList extends React.Component {
    constructor(props) {
        super(props);
        this.getListContent = this.getListContent.bind(this);
    }


    getListContent() {
        const { t, suggestions } = this.props;
        return (
            <div className="content-list-insight width-165" key={`cli-suggestions`}>
                <table>
                    <tbody>
                        {suggestions.keywords.map((aKeyword, j) => {
                            if (!suggestions.meta.hasOwnProperty('limit') || (suggestions.meta.hasOwnProperty('limit') && j < suggestions.meta.limit)) {
                                return (
                                    <tr key={`cli-suggestions-${j}`}>
                                        <td>
                                            <div className="text-ellipsis" style={{ maxWidth: '115px' }}>
                                                <TooltipWithAction content={this.props.getKeywordTooltipContent(aKeyword.keyword)} placement="bottom">
                                                    <Fragment>
                                                        <span className="font-inter-500 color-000 font-size-16">{StringHelper.capitalizeFirstLetter(aKeyword.keyword)}</span>
                                                        <span className="padding-left-4 font-inter-400 color-969696 font-size-15">{aKeyword.occurence}</span>
                                                    </Fragment>
                                                </TooltipWithAction>
                                            </div>
                                        </td>
                                        <td width={55} className="text-align-right font-size-18 font-inter-500">
                                            <ColoredScore
                                                score={aKeyword.future_product_score ? parseFloat((aKeyword.future_product_score).toFixed(2)) : null}
                                                withHelpTooltip={true}
                                                tooltipType="score"
                                                tooltipScoreLibelle={t('Expected Product score')}
                                                tooltipItem={{
                                                    value: '',
                                                    occurence: 0,
                                                    score: aKeyword.future_product_score ? parseFloat((aKeyword.future_product_score).toFixed(2)) : null
                                                }} />
                                        </td>
                                    </tr>
                                );
                            }
                        })}
                    </tbody>
                </table>
                {(!suggestions.meta.stop && !suggestions.meta.loading && (suggestions.keywords.length >= suggestions.meta.page_size)) && (
                    <div className="padding-top-5">
                        <span className="link-blue font-size-15 font-inter-400" onClick={this.props.onSeeMore}>{t('See more')}</span>
                    </div>
                )}
                {(suggestions.meta.loading) && (
                    <div className="padding-top-5 text-align-left">
                        <Loader />
                    </div>
                )}
            </div>
        );

    }

    render() {
        const { t, suggestions, inner_loading } = this.props;
        if (suggestions.keywords.length === 0 && !inner_loading) {
            return null;
        }
        return (
            <Fragment>
                <div className="font-inter-700 font-size-24 color-000 padding-bottom-12 padding-top-14">{t('Suggestions')}</div>
                <div className="font-inter-400 font-size-16 color-7f7f7f padding-bottom-30">{t('We identified some attributes that could improve your product score significantly.')}</div>
                {inner_loading && (
                    <div className="padding-top-5 text-align-left">
                        <Loader />
                    </div>
                )}
                {!inner_loading && (
                    <div className="full-content-list-insights">
                        {this.getListContent()}
                    </div>
                )}
            </Fragment>
        );
    }
}

export default withTranslation()(SuggestionsList);