import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { ROUTES } from '../../../app/routing';
import { SessionStorageService } from '../../../app/services/storage.service';
import LogoSensia from "../../../res/images/logo/logo-sensia-modal.svg"
import '../plan.css';


class PlanHeader extends React.Component {
    constructor(props) {
        super(props);
        
        this.getContent = this.getContent.bind(this);
        this.onCloseClick = this.onCloseClick.bind(this);
        this.onLogoClick = this.onLogoClick.bind(this);
    }

    onCloseClick(){
        const update_plan_referrer = SessionStorageService.get('update_plan_referrer');
        SessionStorageService.delete('update_plan_referrer');
        if(update_plan_referrer){
            this.props.history.push(update_plan_referrer);
        }else{
            this.props.history.push(ROUTES.HOME);
        }
    }

    onLogoClick(){
        SessionStorageService.delete('update_plan_referrer');
        this.props.history.push(ROUTES.HOME);
    }


    getContent() {
        const { title, boldTitle } = this.props;
        return (
            <div className="central-content">
                <div className="logo">
                    <div onClick={this.onLogoClick}>
                        <img alt="" className="logo-sensia" src={LogoSensia} />
                    </div>
                </div>
                <div className="close" onClick={this.onCloseClick}/>
                {title && <div className="title">{title} <span className="font-weight-700">{boldTitle}</span></div>}
            </div>
        );
    }

    render() {
        return (
            <Fragment>
                <div className="plan-header">
                    {this.getContent()}
                </div>
                <div className="plan-header fake">
                    {this.getContent()}
                </div>
            </Fragment>
        );
    }
}

export default withTranslation()(PlanHeader);