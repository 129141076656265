import { userConstants } from "../constants";
import { userService } from "../services";
import { history } from "../helpers";
import { googleLogout } from "@react-oauth/google";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../authConfig";

export const userActions = {
  login,
  logout,
  msalLogout,
};

function login(email, password, onSuccess, onError) {
  return (dispatch) => {
    dispatch(request({ email, password }));

    userService.login(email, password, onSuccess, onError).then(
      (user) => {
        if (user) {
          dispatch(success(user));
          history.push("/");
        }
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  userService.logout();
  googleLogout();
  //msalLogout();
  return { type: userConstants.LOGOUT };
}

function msalLogout() {
  const myMsal = new PublicClientApplication(msalConfig);
  myMsal.initialize().then(() => {
    if (myMsal.getActiveAccount()) {
      const activeAccount = myMsal.getActiveAccount();
      const logoutRequest = {
        account: activeAccount,
      };
      myMsal.logout(logoutRequest);
    }
  });
}
