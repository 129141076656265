import HttpHelper from '../helpers/HttpHelper';

function getIndicatorsDetails (onSuccess, onError) {
    return HttpHelper.authApiFetch('/api/v1/backoffice-dashboard/get-indicators-details', HttpHelper.method.GET, null, onSuccess, onError);
}

function getSalesInfo (year, onSuccess, onError) {
    return HttpHelper.authApiFetch(`/api/v1/backoffice-dashboard/get-sales-ca-data/${year}`, HttpHelper.method.GET, null, onSuccess, onError);
}

function getPlanInfo (onSuccess, onError) {
    return HttpHelper.authApiFetch(`/api/v1/backoffice-dashboard/get-plan-data`, HttpHelper.method.GET, null, onSuccess, onError);
}

function getMediaByService(onSuccess, onError) {
  return HttpHelper.authApiFetch(
    "/api/v1/backoffice-dashboard/get-media-by-service",
    HttpHelper.method.GET,
    null,
    onSuccess,
    onError
  );
}

function getPlanUserRegister(onSuccess, onError) {
  return HttpHelper.authApiFetch(
    "/api/v1/backoffice-dashboard/get-user-register",
    HttpHelper.method.GET,
    null,
    onSuccess,
    onError
  );
}

function getProjectsStatus(onSuccess, onError) {
  return HttpHelper.authApiFetch(
    "/api/v1/backoffice-dashboard/get-project-status",
    HttpHelper.method.GET,
    null,
    onSuccess,
    onError
  );
}

function getIntegratedClassUseInfo(onSuccess, onError) {
  return HttpHelper.authApiFetch(
    "/api/v1/backoffice-dashboard/get-percent-used-class",
    HttpHelper.method.GET,
    null,
    onSuccess,
    onError
  );
}

function getProductCategoryUseInfo(onSuccess, onError) {
  return HttpHelper.authApiFetch(
    "/api/v1/backoffice-dashboard/get-used-product-category",
    HttpHelper.method.GET,
    null,
    onSuccess,
    onError
  );
}

export const backofficeService = {
  getIndicatorsDetails,
  getSalesInfo,
  getPlanInfo,
  getMediaByService,
  getPlanUserRegister,
  getProjectsStatus,
  getIntegratedClassUseInfo,
  getProductCategoryUseInfo,
};