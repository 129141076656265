import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import {
  MAX_DATASOURCE_BY_PROJECT,
  PLAN_JSON_LOGIC_KEYS,
  globalConstants,
} from "../../app/constants";
import LoadingView from "../loading/LoadingView";

import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { projectService } from "../../app/services/project.service";
import Link from "../../components/Link";
import { PROJECT_DETAIL, ROUTES } from "../../app/routing";
import "./addMedia.css";
import HttpHelper from "../../app/helpers/HttpHelper";
import MediaHeader from "./elements/MediaHeader";
import Typography from "@mui/joy/Typography";
import search_illus from "../../res/images/search-data-illustration.svg";
import start_icon from "../../res/images/icons/StarFilled.svg";
import Zoom from "../../res/images/empty-state-attribute.svg";
import placeholder_img from "../../res/images/placeholder-img.svg";

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import Search from "@mui/icons-material/Search";
import { mediaService } from "../../app/services/media.service";
import { planJsonLogicService } from "../../app/services/plan.jsonLogic.service";
import CountrySelect from "../../components/CountrySelect";
import { SessionStorageService } from "../../app/services/storage.service";

class SearchMediaView extends React.Component {
  constructor(props) {
    super(props);
    this.timeoutUrl = null;
    this.state = {
      projectId: props.match.params.id,
      project: null,
      isLoaded: false,
      formData: {
        q: null,
        country: null,
      },
      searchLoader: false,
      addLoader: false,
      searchResults: [],
      searchResultEmpty: false,
      selectedDatasourceUrls: [],
    };

    this.fetchProject = this.fetchProject.bind(this);
    this.search = this.search.bind(this);
    this.addDatasources = this.addDatasources.bind(this);
    this.handleClickProduct = this.handleClickProduct.bind(this);
    this.resultCard = this.resultCard.bind(this);
    this.remainingDatasourceCount = this.remainingDatasourceCount.bind(this);
    this.onCloseClick = this.onCloseClick.bind(this);
    this.handleWarningToastErrorList =
      this.handleWarningToastErrorList.bind(this);
  }

  componentDidMount() {
    this._ismounted = true;
    this.fetchProject();

    planJsonLogicService.playRule(
      PLAN_JSON_LOGIC_KEYS.ENTERPRISE_FEATURE,
      {},
      (response) => {
        const enterpriseFeatureIsDisabled = response.status === "error";
        if (enterpriseFeatureIsDisabled) {
          this.props.history.push(ROUTES.UNAUTHORIZED);
        }
      },
      (error) => {}
    );
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  remainingDatasourceCount() {
    return MAX_DATASOURCE_BY_PROJECT - this.state.project?.medias ?? 0;
  }

  onCloseClick() {
    const update_media_referrer = SessionStorageService.get(
      "update_media_referrer"
    );
    SessionStorageService.delete("update_media_referrer");

    if (update_media_referrer) {
      this.props.history.push(update_media_referrer);
    } else {
      this.props.history.push(ROUTES.HOME);
    }
  }

  async fetchProject() {
    await projectService.get.get(
      this.state.projectId,
      (response) => {
        this.setState({
          project: response,
          isLoaded: true,
        });
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.setState({
          isLoaded: true,
        });
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      },
      { schema: "minimal" }
    );
  }

  async search() {
    this.setState({ searchLoader: true, selectedDatasourceUrls: [] });
    await mediaService.post.search(
      this.state.formData,
      (response) => {
        this.setState({
          searchLoader: false,
          searchResults: response.data,
          searchResultEmpty: response.data?.length == 0,
        });
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.setState({
          searchLoader: false,
          searchResults: [],
        });
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      }
    );
  }

  handleWarningToastErrorList(errorList) {
    if (!errorList || errorList.length == 0) {
      return;
    }

    let msg = `Unfortunately, ${errorList.length} data source can’t be added : `;
    msg = msg + errorList.map((product) => product.title).join(", ");

    this.props.addToast(msg, {
      type: "warning",
      autoDismiss: true,
    });
  }

  async addDatasources() {
    const { selectedDatasourceUrls, searchResults, projectId } = this.state;
    this.setState({ addLoader: true });

    let data = [];
    selectedDatasourceUrls.forEach((url) => {
      const result = searchResults.find((result) => result.url === url);
      data.push({ title: result.name, url: result.url });
    });
    const formData = {
      products: data,
      country: this.state.formData.country,
    };
    await mediaService.post.createMultiple(
      projectId,
      formData,
      (response) => {
        this.setState({ addLoader: false });
        if (response.success_list.length > 0) {
          this.handleWarningToastErrorList(response.error_list);
          this.props.history.push(PROJECT_DETAIL(projectId));
        } else {
          this.props.addToast(
            "Unfortunately, none of your data sources could be added",
            {
              type: "error",
              autoDismiss: true,
            }
          );
        }
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.setState({ addLoader: false });
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      }
    );
  }

  handleClickProduct(url) {
    const limit = this.remainingDatasourceCount();
    var array = [...this.state.selectedDatasourceUrls];
    var index = array.indexOf(url);
    if (index !== -1) {
      array.splice(index, 1);
    } else {
      if (array.length >= limit) {
        this.props.addToast(`You reached your ${limit} datasources limit.`, {
          type: "warning",
          autoDismiss: true,
        });
        return;
      }
      array.push(url);
    }
    this.setState({ selectedDatasourceUrls: array });
  }

  emptyState() {
    const { t } = this.props;
    if (this.state.searchResultEmpty) {
      return (
        <div
          className="central-content"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            rowGap: "10px",
          }}
        >
          <img src={Zoom} alt="" style={{ width: "250", height: "200px" }} />
          <h3 className="font-size-24 color-616161">{t("No result found")}</h3>
          <p className="font-size-15 color-757575">
            {t("Try adjusting your search")} <br />{" "}
            {t("to find what you are looking for")}
          </p>
        </div>
      );
    }
    return (
      <Box
        sx={{
          maxWidth: "660px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "25px",
        }}
      >
        <img src={search_illus} />
        <Typography
          sx={{
            color: "#757575",
            textAlign: "center",
            fontFamily: "Inter",
            fontSize: "15px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "20.8px",
          }}
        >
          {t(
            "Use the search bar above to find products on Google Shopping. Once you find products, you can select them to add to your project for analysis."
          )}
        </Typography>
      </Box>
    );
  }

  resultCard(result) {
    return (
      <Grid item key={result.id}>
        <Box
          sx={{
            display: "flex",
            width: "198px",
            height: "220px",
            flexDirection: "column",
            alignItems: "center",
            gap: "7px",
            flexShrink: 0,
            borderRadius: "4px",
            border: this.state.selectedDatasourceUrls.includes(result.url)
              ? "1px solid #3A79E6"
              : "none",
            background: "#FFF",
            boxShadow:
              "0px 2px 1px -1px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
            cursor: "pointer",
            transition: "transform 0.2s",
            "&:hover": {
              transform: "scale(1.05)",
            },
          }}
          onClick={() => this.handleClickProduct(result.url)}
        >
          <Box
            sx={{
              display: "flex",
              padding: "10px",
              justifyContent: "center",
              alignItems: "center",
              flex: "1 0 0",
              alignSelf: "stretch",
            }}
          >
            <img
              src={result.image || placeholder_img}
              style={{ objectFit: "contain" }}
              width="180px"
              height="120px"
              alt="Sensia search datasource image"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              padding: "0px 16px",
              justifyContent: "flex-start",
              alignItems: "center",
              alignSelf: "stretch",
            }}
          >
            <Typography
              sx={{
                color: "#000",
                height: "32px",
                fontFamily: "Inter",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
              }}
            >
              {result.name}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              padding: "0px 16px 8px",
              justifyContent: "flex-start",
              alignItems: "center",
              alignSelf: "stretch",
              gap: "4px",
            }}
          >
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Inter",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
              }}
            >
              {result.averageRating}
            </Typography>
            <img src={start_icon} alt="start icon sensia" />
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Inter",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
              }}
            >
              ({result.numberOfReviews})
            </Typography>
          </Box>
        </Box>
      </Grid>
    );
  }

  render() {
    const { t } = this.props;
    const {
      project,
      searchResults,
      formData,
      selectedDatasourceUrls,
      searchLoader,
      addLoader,
    } = this.state;
    let page_title = `${t("Search")} - ${globalConstants.WEBSITE_TITLE}`;

    const enableSearch = formData.country && formData.q && formData.q.length;

    if (this.state.isLoaded) {
      return (
        <Fragment>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={searchLoader || addLoader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Helmet>
            <title>{page_title}</title>
          </Helmet>
          <MediaHeader history={this.props.history} title={t("Search")} />
          <Container
            maxWidth={false}
            disableGutters
            sx={{
              display: "flex",
              flexDirection: "column",
              height: { xs: "calc(100vh - 200px)", md: "calc(100vh - 150px)" },
            }}
          >
            <Box sx={{ flex: "0 0 auto", mt: "50px" }}>
              {project && (
                <Link
                  to={PROJECT_DETAIL(project.id)}
                  className="font-size-18 link-grey transition-color text-break-line"
                >
                  <Typography
                    sx={{
                      maxWidth: "1100px",
                      width: "100%",
                      px: "12px",
                      mx: "auto",
                      mt: "6px",
                      color: "#7F7F7F",
                      fontFamily: "Inter",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "22.8px",
                    }}
                  >
                    {project.name}
                  </Typography>
                </Link>
              )}
              <Typography
                sx={{
                  maxWidth: "1100px",
                  width: "100%",
                  px: "12px",
                  mx: "auto",
                  mt: "6px",
                  color: "#000",
                  fontFamily: "Inter",
                  fontSize: "30px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "normal",
                }}
              >
                {t("Search Data Source")}
              </Typography>
              <Typography
                sx={{
                  maxWidth: "1100px",
                  px: "12px",
                  mx: "auto",
                  mt: "16px",
                  mb: "20px",
                  color: "#000",
                  fontFamily: "Inter",
                  fontSize: "15px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "22px",
                }}
              >
                {t(
                  "Select the products you wish to analyze by adding them to your project"
                )}
              </Typography>
              <Box
                sx={{
                  maxWidth: "1100px",
                  width: "100%",
                  mx: "auto",
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  justifyContent: "space-between",
                  alignItems: "start",
                  px: "12px",
                }}
              >
                <TextField
                  placeholder={t("Search")}
                  sx={{
                    width: "-webkit-fill-available",
                    mx: "auto",
                    mb: { xs: 2, md: 0 },
                    mr: { xs: "32px", md: 0 },
                    fontFamily: "Inter",
                  }}
                  onChange={(e) =>
                    this.setState({
                      formData: { ...this.state.formData, q: e.target.value },
                    })
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search sx={{ color: "#3A79E6" }} />
                      </InputAdornment>
                    ),
                  }}
                />
                <CountrySelect
                  sx={{
                    width: { xs: "-webkit-fill-available", md: "477px" },
                    ml: { xs: 0, md: "20px" },
                    mb: { xs: 2, md: 0 },
                    mr: { xs: "32px", md: 0 },
                    fontFamily: "Inter",
                  }}
                  onChange={(country) => {
                    this.setState({
                      formData: {
                        ...this.state.formData,
                        country: country?.code,
                      },
                    });
                  }}
                />
                <Button
                  variant="contained"
                  disabled={!enableSearch || searchLoader}
                  sx={{
                    height: "56px",
                    ml: { xs: 0, md: "50px" },
                    p: "12px 30px",
                    mb: { xs: 2, md: 0 },
                    mr: { xs: "32px", md: "32px", lg: 0 },
                    backgroundColor: "#306ed6",
                    textTransform: "none",
                    borderRadius: "6px",
                    "&:hover": {
                      backgroundColor: "#0075ff",
                    },
                  }}
                  onClick={() => this.search()}
                >
                  <Typography
                    sx={{
                      fontSize: "15px",
                      letterSpacing: "normal",
                      lineHeight: "34px",
                      fontFamily: "Inter",
                      color: "#FFF",
                    }}
                  >
                    {t("Search")}
                  </Typography>
                </Button>
              </Box>
            </Box>
            {/* Section des résultats de recherche */}
            <Box
              sx={{
                flex: "1 1 auto",
                maxWidth: "1100px",
                mx: "auto",
                mt: 2,
                overflowY: "auto",
                p: 2,
              }}
            >
              {searchResults?.length > 0 ? (
                <Grid
                  container
                  spacing={"25px"}
                  sx={{
                    justifyContent: "center",
                  }}
                >
                  {searchResults.map((result) => this.resultCard(result))}
                </Grid>
              ) : (
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  {this.emptyState()}
                </Box>
              )}
            </Box>
            {/* Bottom bar */}
            <Box
              sx={{
                position: "fixed",
                bottom: 0,
                width: "100%",
                height: "34px",
                backgroundColor: "#FFF",
                py: "22px",
                borderTop: "1px solid #F1F5F6",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  flex: "1 1 auto",
                  maxWidth: "1100px",
                  mx: "auto",
                  mt: 2,
                  overflowY: "auto",
                  p: 2,
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <button
                  onClick={this.onCloseClick}
                  className="margin-left-5 button backgroundless color-306ed6"
                >
                  {t("Cancel")}
                </button>
                <button
                  disabled={selectedDatasourceUrls?.length < 1}
                  onClick={() => this.addDatasources()}
                  className="button backgroundless color-306ed6 "
                >
                  Add {selectedDatasourceUrls?.length} Data Sources
                </button>
              </Box>
            </Box>
          </Container>
        </Fragment>
      );
    }
    return (
      <LoadingView
        title={page_title}
        withHeader={false}
        headerProps={{ isProject: true }}
      />
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withTranslation()(connect(mapStateToProps)(SearchMediaView));
