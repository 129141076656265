import React from "react";
import { withTranslation } from "react-i18next";
import {
  ACCOUNT_PLAN,
  ACCOUNT_PLAN_FREQUENCY,
  globalConstants,
} from "../../app/constants";
import HttpHelper from "../../app/helpers/HttpHelper";
import StringHelper from "../../app/helpers/StringHelper";
import { PLAN_UGRAPDE } from "../../app/routing";

import Check from "../../res/images/icons/check.svg";
import Link from "../Link";
import "./pricingBox.css";

function PricingBox(props) {
  const {
    t,
    planType,
    title,
    subtitle,
    subprice,
    subpriceYearly,
    advantages,
    recommended,
    yearly,
    currentPlan,
    rawClass,
    yearlyMonthlyPrice,
    monthlyPrice,
    yearlyPrice,
    currency_code,
  } = props;
  const classes = ["content-text", "content-pricing-box"];

  if (recommended) {
    classes.push("recommended");
  }

  let displayPrice = "";
  let displaySubPrice = "";

  const language = navigator.language;

  const onClickContactSales = () => {
    window.open(globalConstants.SALES_URL, "_blank", "noreferrer");
  };

  const button_link_to = yearly
    ? HttpHelper.addParametersToUrl(PLAN_UGRAPDE(planType), {
        freq: ACCOUNT_PLAN_FREQUENCY.YEARLY.toLowerCase(),
      })
    : PLAN_UGRAPDE(planType);

  let button = (
    <div className="btn-position">
      <Link className="plan-button" dontSaveReferer={true} to={button_link_to}>
        {t("Upgrade now")}
      </Link>
    </div>
  );

  if (currentPlan === planType) {
    button = (
      <div className="btn-position">
        <div className="plan-button cursor-default-important">
          {t("Current plan")}
        </div>
      </div>
    );
  } else if (planType === ACCOUNT_PLAN.ENTERPRISE) {
    button = (
      <div className="btn-position">
        <button className="plan-button-business" onClick={onClickContactSales}>
          {t("Contact sales")}
        </button>
      </div>
    );
  } else {
    button = (
      <div className="btn-position">
        <Link
          className="button upgrade"
          dontSaveReferer={true}
          to={button_link_to}
        >
          {t("Upgrade to")} {StringHelper.capitalize(title.toLowerCase())}
        </Link>
      </div>
    );
  }

  if (planType === ACCOUNT_PLAN.ENTERPRISE) {
    displaySubPrice = <div className="sub-price only-text">{subprice}</div>;
  } else if (planType === ACCOUNT_PLAN.FREE) {
    displaySubPrice = <div className="sub-price only-text">{subprice}</div>;
    displayPrice = (
      <div className="price">
        <span className="maj">0/</span>
        <span className="min">{t("forever")}</span>
      </div>
    );
  } else if (yearly) {
    displayPrice = (
      <div className="price">
        <span className="maj">
          {new Intl.NumberFormat(language, {
            style: "currency",
            currency: currency_code,
            minimumFractionDigits: 0,
          }).format((yearlyPrice / 12).toFixed())}
          /
        </span>
        <span className="min">{t("month")}</span>
      </div>
    );
    displaySubPrice = (
      <div className="sub-price">
        {new Intl.NumberFormat(language, {
          style: "currency",
          currency: currency_code,
          minimumFractionDigits: 0,
        }).format(monthlyPrice.toFixed())}
        {subprice}
      </div>
    );
  } else {
    displayPrice = (
      <div className="price">
        <span className="maj">
          {new Intl.NumberFormat(language, {
            style: "currency",
            currency: currency_code,
            minimumFractionDigits: 0,
          }).format(monthlyPrice.toFixed())}
          /
        </span>
        <span className="min">{t("month")}</span>
      </div>
    );
    displaySubPrice = (
      <div className="sub-price">
        {new Intl.NumberFormat(language, {
          style: "currency",
          currency: currency_code,
          minimumFractionDigits: 0,
        }).format((yearlyPrice / 12).toFixed())}
        {subpriceYearly}
      </div>
    );
  }

  return (
    <>
      <div className={rawClass.join(" ") + " parent-content-pricing-box"}>
        <div className={classes.join(" ")}>
          <div className="title">{title}</div>
          <div className="subtitle">{subtitle}</div>
          {displayPrice}
          {displaySubPrice}
          <div className="content-advantages">
            {advantages.map((element, y) => {
              return (
                <div
                  key={element.title + y}
                  className="advantages-box padding-bottom-20"
                >
                  <div className="title">{element.title}:</div>
                  {element.advantage.map((adv, i) => {
                    return (
                      <div key={adv + i} className="raw-advantage">
                        <img className="icon-check" src={Check} alt="" />
                        <div className="advantage-lst">{adv}</div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          {button}
        </div>
      </div>
    </>
  );
}

PricingBox.defaultProps = {
  recommended: false,
};

export default withTranslation()(PricingBox);
