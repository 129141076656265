import HttpHelper from '../helpers/HttpHelper';



async function getPaymentMethod (onSuccess, onError) {
    return HttpHelper.authApiFetch('/api/v1/billing/paymentMethod/', HttpHelper.method.GET, null, onSuccess, onError);
}

async function setupIntent (onSuccess, onError) {
    return HttpHelper.authApiFetch('/api/v1/billing/setup-intent/', HttpHelper.method.GET, null, onSuccess, onError);
}

async function savePaymentMethod (paymentMethod, onSuccess, onError) {
    const formData = { 'paymentMethod': paymentMethod };
    return HttpHelper.authApiFetch('/api/v1/billing/savePaymentMethod/', HttpHelper.method.POST, formData, onSuccess, onError);
}


async function createSubscribtion (price_id, payment_method_id, plan_key, discount_code, onSuccess, onError) {
    const formData = {
        'price_id': price_id,
        'payment_method_id': payment_method_id,
        'plan_key': plan_key,
        'discount_code': discount_code
    };
    return HttpHelper.authApiFetch('/api/v1/billing/subscribtion', HttpHelper.method.POST, formData, onSuccess, onError);
}

async function updateSubscribtion (price_id, payment_method_id, plan_key, discount_code, onSuccess, onError) {
    const formData = {
        'price_id': price_id,
        'payment_method_id': payment_method_id,
        'plan_key': plan_key,
        'discount_code': discount_code
    };
    return HttpHelper.authApiFetch('/api/v1/billing/update-subscribtion', HttpHelper.method.POST, formData, onSuccess, onError);
}

async function downgradeSubscribtion (plan_key, onSuccess, onError) {
    const formData = {
        'plan_key': plan_key,
    };
    return HttpHelper.authApiFetch('/api/v1/billing/downgrade-subscribtion', HttpHelper.method.POST, formData, onSuccess, onError);
}

async function setDefaultPaymentMethod (cardId, onSuccess, onError) {
    const formData = {
        'paymentMethodId': cardId
    };
    return HttpHelper.authApiFetch('/api/v1/billing/setDefaultPaymentMethod/', HttpHelper.method.POST, formData, onSuccess, onError);
}

async function deletePaymentMethod (cardId, onSuccess, onError) {
    const formData = {
        'paymentMethodId': cardId
    };
    return HttpHelper.authApiFetch('/api/v1/billing/deletePaymentMethod/', HttpHelper.method.POST, formData, onSuccess, onError);
}


async function getPlan (planKey, discount_code, onSuccess, onError) {
    const formData = {
        'discount_code': discount_code
    };
    return HttpHelper.authApiFetch("/api/v1/billing/plan/" + planKey, HttpHelper.method.GET, formData, onSuccess, onError);
}

async function getPreviewChange (priceId, onSuccess, onError) {
    const formData = {
        'new_price_id': priceId
    };
    return HttpHelper.authApiFetch("/api/v1/billing/preview-change-subscribtion", HttpHelper.method.POST, formData, onSuccess, onError);
}

export const billingService = {
    getPaymentMethod,
    setupIntent,
    savePaymentMethod,
    createSubscribtion,
    updateSubscribtion,
    downgradeSubscribtion,
    setDefaultPaymentMethod,
    deletePaymentMethod,
    getPlan,
    getPreviewChange
}