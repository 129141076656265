import React, { createContext, useContext, useEffect, useState } from "react";
import { workspaceService } from "../app/services/workspace.service";

const ProjectDataManagerContext = createContext();

export const useProjectDataManager = () =>
  useContext(ProjectDataManagerContext);

export const ProjectDataManager = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [projectStatistics, setProjectStatistics] = useState(null);
  const [errors, setErrors] = useState(null);

  const clearStatistics = () => {
    setIsLoading(true);
    setErrors(null);
    setProjectStatistics(null);
  };

  useEffect(() => {
    clearStatistics();
  }, [children.key]);

  const fetchStatistics = async (projectId, formData = {}) => {
    setProjectStatistics(null);
    setIsLoading(true);
    return new Promise((resolve, reject) =>
      workspaceService.post.scoreEmotion(
        projectId,
        formData,
        (response) => {
          setProjectStatistics(response);
          setIsLoading(false);
          resolve(response);
        },
        (error) => {
          setErrors(error);
          setIsLoading(false);
          reject(error);
        }
      )
    );
  };

  return (
    <ProjectDataManagerContext.Provider
      value={{
        fetchStatistics,
        clearStatistics,
        isLoading,
        projectStatistics,
        errors,
      }}
    >
      {children}
    </ProjectDataManagerContext.Provider>
  );
};
