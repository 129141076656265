import HttpHelper from '../helpers/HttpHelper';

function getAccountData (onSuccess, onError) {
    return HttpHelper.authApiFetch('/api/v1/account/get-data', HttpHelper.method.GET, null, onSuccess, onError);
}

function editAccount(formData, onSuccess, onError) {
    return HttpHelper.authApiFetch('/api/v1/account/edit-account', HttpHelper.method.POST, formData, onSuccess, onError);
}

function editEmailBilling(formData, onSuccess, onError) {
    return HttpHelper.authApiFetch('/api/v1/account/edit-email', HttpHelper.method.POST, formData, onSuccess, onError);
}

function changeUserActiveStatus(formData, onSuccess, onError) {
    return HttpHelper.authApiFetch('/api/v1/account/change-status', HttpHelper.method.POST, formData, onSuccess, onError);
}

function getUserAccountData(accountId, userId, onSuccess, onError) {
    return HttpHelper.authApiFetch(`/api/v1/account/user-account-data/${accountId}/${userId}`, HttpHelper.method.GET, null, onSuccess, onError);
}

function deleteAccount(account_id, user_id, onSuccess, onError) {
    return HttpHelper.authApiFetch("/api/v1/account/delete/" + account_id + "/user/" + user_id, HttpHelper.method.DELETE, null, onSuccess, onError);
}

export const accountService = {
    getAccountData,
    editAccount,
    editEmailBilling,
    changeUserActiveStatus, //Change user_status in user_account :)
    getUserAccountData,
    deleteAccount
}