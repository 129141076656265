import HttpHelper from '../helpers/HttpHelper';


function getRules(formData, onSuccess, onError) {
    return HttpHelper.authApiFetch("/api/v1/plan-rules", HttpHelper.method.GET, formData, onSuccess, onError);
}

function playRule(ruleKey, formData, onSuccess, onError) {
    return HttpHelper.authApiFetch(`/api/v1/plan-rules/play/${ruleKey}`, HttpHelper.method.POST, formData, onSuccess, onError);
}


export const planJsonLogicService = {
    getRules,
    playRule
}