import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { withTranslation } from 'react-i18next';
import { backofficeService } from '../../app/services/backoffice.service';
import Loader from '../loader/Loader';
import { chartColors } from "./adminMediaTypeChart";

ChartJS.register(ArcElement, Tooltip, Legend);

class ProjectStatusChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      projectSatusDetail: [],
      projectStatusDetailsIsLoaded: false,
    };
  }

  componentDidMount() {
    this.fetchProjectStatusData();
  }

  fetchProjectStatusData() {
    backofficeService.getProjectsStatus(
      (response) => {
        this.setState({
          projectSatusDetail: [response.data.archived, response.data.actif],
          projectStatusDetailsIsLoaded: true,
        });
      },
      (error) => {
        console.log(error);
        this.setState({
          projectStatusDetailsIsLoaded: true,
        });
      }
    );
  }

  option() {
    const options = {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        legend: {
          position: "bottom",
          align: "center",
        },
      },
    };

    return options;
  }

  render() {
    const { t } = this.props;
    const data = {
      labels: ["Archived", "Active"],
      datasets: [
        {
          label: "Project Status",
          data: this.state.projectSatusDetail,
          backgroundColor: chartColors,
          borderWidth: 1,
        },
      ],
    };

    return (
      <div className="doughnutChartBox">
        {!this.state.projectStatusDetailsIsLoaded && <Loader />}
        {this.state.projectStatusDetailsIsLoaded && (
          <div className="chartTitle">{t("Project Status")}</div>
        )}
        {this.state.projectStatusDetailsIsLoaded && (
          <Doughnut
            className="chart chart-modified"
            data={data}
            options={this.option()}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(ProjectStatusChart);
