import HttpHelper from "../helpers/HttpHelper";
import { saveAs } from "file-saver";

async function getKeywordFromReport(
  projectId,
  keyword,
  formData,
  signal,
  onSuccess,
  onError
) {
  const url = "/api/v1/workspace/search/" + projectId + "/" + keyword;
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.GET,
    formData,
    onSuccess,
    onError,
    signal
  );
}

async function getAllReportsExport(
  projectId,
  filename,
  formData,
  onSuccess,
  onError
) {
  const requestOptions = {
    method: HttpHelper.method.POST,
    headers: HttpHelper.authHeader({ "Content-Type": "text/csv" }),
    responseType: "blob",
    body: JSON.stringify(formData),
  };
  const url = HttpHelper.getApiUrl(
    "/api/v1/workspace/export/" + projectId,
    HttpHelper.method.POST
  );
  return fetch(url, requestOptions)
    .then(HttpHelper.handleLoggedVanillaResponse)
    .then((response) => response.blob())
    .then(
      (blob) => {
        saveAs(blob, filename);
        HttpHelper.handleFunction(onSuccess);
        return blob;
      },
      (error) => {
        HttpHelper.handleFunction(onError, error);
      }
    )
    .catch((error) => {
      HttpHelper.handleFunction(onError, error);
    });
}

async function getContextReport(
  projectId,
  datasource_id,
  start_at,
  onSuccess,
  onError,
  signal = null
) {
  let url = `/api/v1/workspace/context/${projectId}/${datasource_id}/${start_at}`;
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.GET,
    null,
    onSuccess,
    onError,
    signal
  );
}

async function getAllEmotions(review_id, onSuccess, onError) {
  let url = "/api/v1/workspace/all_emotions/" + review_id;
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.GET,
    null,
    onSuccess,
    onError
  );
}

async function updateSentenceScore(projectId, formData, onSuccess, onError) {
  const url = "/api/v1/workspace/score-sentence/" + projectId;
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.PUT,
    formData,
    onSuccess,
    onError
  );
}

async function postWordScoreEmotion(
  projectId,
  formData,
  onSuccess,
  onError,
  signal = null
) {
  let url = "/api/v1/workspace/score-emotion/" + projectId;
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.POST,
    formData,
    onSuccess,
    onError,
    signal
  );
}

async function postkeywordReport(
  projectId,
  formData,
  onSuccess,
  onError,
  signal = null
) {
  let url = "/api/v1/workspace/keywords-report/" + projectId;
  return HttpHelper.authApiFetch(
    url,
    HttpHelper.method.POST,
    formData,
    onSuccess,
    onError,
    signal
  );
}

export const workspaceService = {
  get: {
    get: getKeywordFromReport,
    getContext: getContextReport,
    exportReport: getAllReportsExport,
    getEmotions: getAllEmotions,
  },
  put: {
    updateSentenceScore: updateSentenceScore,
  },
  post: {
    scoreEmotion: postWordScoreEmotion,
    keywordReport: postkeywordReport,
  },
};
