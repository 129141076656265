
import React, { Fragment } from 'react';
import './tooltip.css';
import { withTranslation } from "react-i18next";
import ColoredScore from '../ui/ColoredScore';
import ReportHelper from '../../app/helpers/ReportHelper';
import StringHelper from '../../app/helpers/StringHelper';


class ScoreTooltipContent extends React.Component {
  constructor(props) {
    super(props);

    this.getScoreClassName = this.getScoreClassName.bind(this);
  }

  getScoreClassName(item, occurences, workspace) {
    const { t } = this.props;
    let scoreClass = 'color-d2d2d2';
    let theScore = '-';

    if (item) {
      theScore = (item.average_score) ? item.average_score : item.score;
      scoreClass = ReportHelper.getScoreClass(theScore);
    }
    let nbOccurences = "";
    let scoreClassNames = ['font-inter-600', scoreClass]
    if (item && item.isGlobalScore && !workspace) {
      nbOccurences = (occurences > 1) ? t('{{count}} Attributes', { count: occurences }) : t('{{count}} Attribute', { count: occurences });
      scoreClassNames.push('font-size-36');
    } else {
      nbOccurences = (occurences > 1) ? t('{{count}} Occurences', { count: occurences }) : t('{{count}} Occurence', { count: occurences });
      scoreClassNames.push('font-size-22');
    }
    return { nbOccurences, scoreClassNames, theScore }
  }

  render() {
    const { t, item, withSeparator, type, scoreLibelle, workspace } = this.props;
    const occurences = (item) ? item.occurence : 0;
    const typeData = (type) ? type : 'default';

    const { nbOccurences, scoreClassNames, theScore } = this.getScoreClassName(item, occurences, workspace)

    const score_libelle = (scoreLibelle) ? scoreLibelle : t('score');
    return (
      <Fragment>
        {item && typeData == 'default' && (
          <div className="content-column">
            <div className="column-percent-55 padding-right-5">
              <div className="font-size-16 font-inter-600 padding-bottom-2">{StringHelper.capitalize(item.value)}</div>
              {occurences != null && <div className="font-size-13 color-7f7f7f">{nbOccurences}</div>}
              {/* <div className="font-size-12 color-000">{item.classes.join(' - ')}</div>*/}
            </div>
            <div className="column-percent-45 padding-left-5 text-align-right">
              {item.isGlobalScore && <div className={scoreClassNames.join(' ')}>{item ? <ColoredScore score={theScore} /> : '-'}</div>}
              {!item.isGlobalScore && <Fragment>
                <div className="font-size-13 color-7f7f7f">{t('Average score')}</div>
                <div className={scoreClassNames.join(' ')}>{item ? <ColoredScore score={theScore} /> : '-'}</div>
              </Fragment>}
            </div>
          </div>
        )}
        {item && typeData == 'score' && (
          <div className="position-relative">
            <div className="position-relative display-inline-block vertical-align-top">
              <div className={scoreClassNames.join(' ')}>{theScore}</div>
              <div className="font-inter-400 font-size-13 color-7f7f7f">{score_libelle}</div>
            </div>
          </div>
        )}
        {(item != null || withSeparator) && <div className="tooltip-separator" />}
        <div className="content-tooltip-help-score">
          <div className="text-align-center font-size-0">
            <div className="display-inline-block vertical-align-middle font-inter font-size-13 width-75 line-height-16 font-weight-500 color-969696">-0.5</div>
            <div className="display-inline-block vertical-align-middle font-inter font-size-13 width-75 line-height-16 font-weight-500 color-969696">0.5</div>
          </div>
          <div className="color-bar">
            <div className="bar" />
            <div className="bar" />
            <div className="bar" />
          </div>
          <div className="position-relative">
            <div className="position-absolute-top-left font-inter font-weight-400 font-size-13 color-000 line-height-19">{t('Negative')}</div>
            <div className="text-align-center font-inter font-weight-400 font-size-13 color-000 line-height-19">{t('Neutral')}</div>
            <div className="position-absolute-top-right font-inter font-weight-400 font-size-13 color-000 line-height-19">{t('Positive')}</div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withTranslation()(ScoreTooltipContent);