export const EMOTIONS_COLOR_GREEN = '#27c986';
export const EMOTIONS_COLOR_RED = '#e04f77';
export const EMOTIONS_COLOR_GREY = '#9f99b7';
export const EMOTIONS_COLOR_EMPTY = '#d2d2d2';
export const EMOTIONS_COLOR_WHITE = '#ffffff';
export const COLOR_BLUE_LOADING = '#3a79e6';


export const EMOTIONS_COLORS_ARRAY = {
  admiration: EMOTIONS_COLOR_GREEN,
  anger: EMOTIONS_COLOR_RED,
  annoyance: EMOTIONS_COLOR_RED,
  approval: EMOTIONS_COLOR_GREEN,
  caring: EMOTIONS_COLOR_GREEN,
  confusion: EMOTIONS_COLOR_RED,
  curiosity: EMOTIONS_COLOR_GREEN,
  desire: EMOTIONS_COLOR_GREEN,
  disappointment: EMOTIONS_COLOR_RED,
  disapproval: EMOTIONS_COLOR_RED,
  disgust: EMOTIONS_COLOR_RED,
  embarrassment: EMOTIONS_COLOR_RED,
  excitement: EMOTIONS_COLOR_GREEN,
  fear: EMOTIONS_COLOR_RED,
  gratitude: EMOTIONS_COLOR_GREEN,
  grief: EMOTIONS_COLOR_RED,
  joy: EMOTIONS_COLOR_GREEN,
  love: EMOTIONS_COLOR_GREEN,
  nervousness: EMOTIONS_COLOR_RED,
  optimism: EMOTIONS_COLOR_GREEN,
  pride: EMOTIONS_COLOR_GREEN,
  realization: EMOTIONS_COLOR_GREEN,
  relief: EMOTIONS_COLOR_GREEN,
  remorse: EMOTIONS_COLOR_RED,
  sadness: EMOTIONS_COLOR_RED,
  surprise: EMOTIONS_COLOR_GREEN,
  neutral: EMOTIONS_COLOR_GREY
};