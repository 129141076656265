import React, { Fragment } from "react";
import { Helmet } from "react-helmet";

import { globalConstants, PLAN_JSON_LOGIC_KEYS } from "../../app/constants";
import LoadingView from "../loading/LoadingView";

import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";

import { classService } from "../../app/services/class.service";
import { projectService } from "../../app/services/project.service";
import Footer from "../../components/footer/Footer";
import Loader from "../../components/loader/Loader";
import { PROJECT_DETAIL, ROUTES } from "../../app/routing";
import HttpHelper from "../../app/helpers/HttpHelper";
import LimitedTextareaField from "../../components/form/field/LimitedTextareaField";

import UserHelper from "../../app/helpers/UserHelper";
import { planJsonLogicService } from "../../app/services/plan.jsonLogic.service";
import { productService } from "../../app/services/product.service";

import ProjectHeader from "../addMedia/elements/MediaHeader";
import ProjectBanner from "./elements/ProjectBanner";
import CreateProjectBanner from "../../res/images/banner/Createprojectbanner.svg";
import "./createproject.css";

import upgradeIcon from "../../res/images/icons/upgrade_icon.svg";
import InfoPicto from "../../res/images/icons/InfoOutlined.svg";
import InterrogationPicto from "../../res/images/icons/interrogation.svg";
import product_performance_img from "./../../res/images/product_performance_img.svg";
import retail_performance from "./../../res/images/retail_performance.svg";
import competitive_benchmarking from "./../../res/images/competitive_benchmarking.svg";
import { Box, Typography } from "@mui/joy";
import ListDivider from "@mui/joy/ListDivider";
import ListItem from "@mui/joy/ListItem";
import List from "@mui/joy/List";
import Select, { selectClasses } from "@mui/joy/Select";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import TextField from "../../components/form/field/TextField";
import Tooltip from "@mui/joy/Tooltip";

import Option, { optionClasses } from "@mui/joy/Option";
import { listItemDecoratorClasses } from "@mui/joy/ListItemDecorator";
import IntercomHelper from "../../app/helpers/IntercomHelper";
import Link from "../../components/Link";

class CreateProjectView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      step: 1,
      // qualification datas for cards in the project creation step
      projectQualifications: [
        {
          key: "product_performance",
          img: product_performance_img,
          title: "Product Performance",
          subtitle:
            "Know how to improve your products with rapid, relevant and accurate insights. Create outstanding marketing campaigns. Grow your online sales.",
        },
        {
          key: "retail_performance",
          img: retail_performance,
          title: "Retail Performance",
          subtitle:
            "Maximize store performance with shop insights. Boost customer satisfaction and operational efficiency. Drive higher store revenue.",
        },
        {
          key: "competitive_benchmarking",
          img: competitive_benchmarking,
          title: "Competitive Benchmarking",
          subtitle:
            "Know your competitor’s success drivers. Gain insight into market trends. Remain agile in responding to market shifts.",
        },
      ],
      formData: {
        name: "",
        main_characteristics: "",
        qualification: null,
        product_category_id: null,
      },
      selected_category: null,
      selected_group_class: null,
      submitLoading: false,
      groupClasses: [],
      category: [],
      groupClassLabel: "",
    };

    this.fetchInitial = this.fetchInitial.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeMainCharacteristics =
      this.handleChangeMainCharacteristics.bind(this);
    this.handleChangeGroupClasses = this.handleChangeGroupClasses.bind(this);
    this.renderListItem = this.renderListItem.bind(this);
    this.selectProjectCategory = this.selectProjectCategory.bind(this);
  }

  componentDidMount() {
    this._ismounted = true;
    this.fetchInitial();
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  fetchInitial() {
    planJsonLogicService.playRule(
      PLAN_JSON_LOGIC_KEYS.CREATE_PROJECT,
      {},
      (response) => {
        if (response.status === "error") {
          this.props.history.push(
            HttpHelper.addParametersToUrl(ROUTES.HOME, {
              modale: "upgrade-plan",
            })
          );
        } else {
          this.fetchData();
        }
      },
      (error) => {
        if (this._ismounted) {
          this.props.addToast(HttpHelper.getErrorMessage(error), {
            type: "error",
            autoDismiss: true,
          });
          this.fetchData();
        }
      }
    );
  }

  fetchData() {
    let account = UserHelper.getCurrentAccount();
    classService.get.getAll(
      account["id"],
      (response) => {
        response.data.forEach((elm) => {
          this.state.groupClasses.push(elm);
          // this.state.formData.classe_ids.push(elm.id)
        });
        this.setState({ isLoaded: true });
      },
      (error) => {
        if (this._ismounted) {
          this.setState({ isLoaded: true });
          this.props.addToast(HttpHelper.getErrorMessage(error), {
            type: "error",
            autoDismiss: true,
          });
        }
      }
    );
    productService.productsForAccount(
      account["id"],
      (response) => {
        let defaultCategoryId = null;
        if (response.last_product_category_id) {
          defaultCategoryId = response.last_product_category_id;
        }

        const productCategories = []
          .concat(response.product_categories)
          .sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1));
        productCategories.forEach((elm) => {
          if (defaultCategoryId == null && elm.is_available) {
            defaultCategoryId = elm.id;
          }
          this.state.category.push({
            label: elm.title,
            value: elm.id,
            is_available: elm.is_available,
          });
        });
        const categoriesGroupedByMainCategory = productCategories.reduce(
          (acc, category) => {
            // Vérifiez si la main_category existe déjà dans l'accumulateur
            if (acc.hasOwnProperty(category.main_category)) {
              // Si elle existe, ajoutez la catégorie à la liste existante
              acc[category.main_category].push({
                label: category.title,
                value: category.id,
                description: category.description,
                is_available: category.is_available,
              });
            } else {
              // Sinon, créez une nouvelle liste pour cette main_category et ajoutez la catégorie
              acc[category.main_category] = [
                {
                  label: category.title,
                  value: category.id,
                  description: category.description,
                  is_available: category.is_available,
                },
              ];
            }
            return acc;
          },
          {}
        );

        this.setState({
          groupedCategory: categoriesGroupedByMainCategory,
          isLoaded: true,
        });

        this.handleChangeGroupClasses(defaultCategoryId);
      },
      (error) => {
        if (this._ismounted) {
          this.setState({ isLoaded: true });
          this.props.addToast(HttpHelper.getErrorMessage(error), {
            type: "error",
            autoDismiss: true,
          });
        }
      }
    );
  }

  handleChangeName(event) {
    let newFormData = Object.assign({}, this.state.formData);
    newFormData.name = event.target.value;
    this.setState({
      formData: newFormData,
    });
  }

  handleChangeMainCharacteristics(newValue) {
    let newFormData = Object.assign({}, this.state.formData);
    newFormData.main_characteristics = newValue;
    this.setState({
      formData: newFormData,
    });
  }

  handleChangeGroupClasses(value) {
    let newFormData = Object.assign({}, this.state.formData);
    newFormData.product_category_id = value;
    const categoryIndex = this.state.category.findIndex(
      (c) => c.value === value
    );
    let label = "Category";
    if (categoryIndex != -1) {
      label = this.state.category[categoryIndex].label;
    }
    this.setState({
      selected_category: this.state.category[categoryIndex],
      selected_group_class: value,
      formData: newFormData,
      groupClassLabel: label,
    });
  }

  onSubmitCreate = (e) => {
    // METHOD SUBMIT
    e.preventDefault();
    if (this.state.selected_group_class === "") {
      this.props.addToast("Please enter a type", {
        type: "error",
        autoDismiss: true,
      });
      return;
    }
    let formData = Object.assign({}, this.state.formData);
    const user = UserHelper.getUser();
    const account = UserHelper.getCurrentAccount();
    formData.user_id = user.id;
    formData.account_id = account.id;
    if (formData) {
      this.setState({ submitLoading: true });
      projectService.post.create(
        formData,
        (response) => {
          const projectId = response.id;
          this.setState({ submitLoading: false });
          setTimeout(() => {
            this.props.addToast(
              'Project "' + this.state.formData.name + '" successfully created',
              { type: "success", autoDismiss: true }
            );
          }, 800);
          IntercomHelper.trackEvent("project_created", {
            name: this.state.formData.name,
            id: projectId,
          });
          this.props.history.push(PROJECT_DETAIL(projectId));
        },
        (error) => {
          if (this._ismounted) {
            this.setState({ isLoaded: true });
            this.props.addToast(HttpHelper.getErrorMessage(error), {
              type: "error",
              autoDismiss: true,
            });
          }
        }
      );
    }
  };

  // render qualification cards
  renderListItem(item) {
    const { t } = this.props;
    return (
      <Box
        key={item?.key}
        onClick={() => {
          let formData = Object.assign({}, this.state.formData);
          formData.qualification = item.key;
          this.setState({ formData, step: 2 });
        }}
        sx={{
          mr: "10px",
          width: "344px",
          height: "417px",
          borderRadius: "6px",
          "&:hover": {
            cursor: "pointer",
            boxShadow:
              "0px 1px 8px 0px rgba(0, 0, 0, 0.12), 0px 3px 3px -2px rgba(0, 0, 0, 0.20)",
          },
        }}
      >
        <Box sx={{ m: "20px" }}>
          <img src={item.img} alt="" />
        </Box>
        <Typography
          sx={{
            mt: "20px",
            ml: "20px",
            color: "#424242",
            fontFamily: "Inter",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
          }}
        >
          {t(item.title)}
        </Typography>
        <Typography
          sx={{
            mt: "15px",
            ml: "20px",
            mr: "20px",
            color: "#7F7F7F",
            fontFamily: "Inter",
            fontSize: "15px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "22px",
          }}
        >
          {t(item.subtitle)}
        </Typography>
      </Box>
    );
  }

  selectProjectCategory() {
    if (!this.state.groupedCategory) {
      return <></>;
    }
    return (
      <Select
        placeholder="Select the category"
        onChange={(e, newValue) => this.handleChangeGroupClasses(newValue)}
        indicator={<KeyboardArrowDown />}
        defaultValue={this.state.formData.product_category_id}
        slotProps={{
          listbox: {
            component: "div",
            sx: {
              maxHeight: 240,
              overflow: "auto",
              "--List-padding": "0px",
              "--ListItem-radius": "0px",
            },
          },
        }}
        sx={{
          width: "100%",
          height: "50px",
          mb: "30px",
          background: "transparent",
          fontFamily: "Inter",
          fontSize: "15px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "normal",
          [`& .${selectClasses.indicator}`]: {
            transition: "0.2s",
            [`&.${selectClasses.expanded}`]: {
              transform: "rotate(-180deg)",
            },
          },
          [`& .${selectClasses.button}`]: {
            opacity: 1,
            color: this.state.formData?.product_category_id
              ? "black"
              : "#B6B6B6",
          },
        }}
      >
        {Object.entries(this.state.groupedCategory).map(
          ([name, categories], index) => (
            <React.Fragment key={index}>
              {index !== 0 && <ListDivider key={index} role="none" />}
              <List
                key={index}
                aria-labelledby={`select-group-${name}`}
                sx={{ "--ListItemDecorator-size": "28px" }}
              >
                <ListItem key={index} id={`select-group-${name}`} sticky>
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "15px",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "normal",
                      textTransform: "capitalize",
                    }}
                  >
                    {name.toLowerCase()}
                  </Typography>
                </ListItem>
                {categories.map((category) => (
                  <Option
                    key={category.id}
                    value={category.value}
                    label={<React.Fragment>{category.label}</React.Fragment>}
                    sx={{
                      pl: "17px",
                      justifyContent: "space-between",
                      width: "100%",
                      [`&.${optionClasses.selected} .${listItemDecoratorClasses.root}`]:
                        {
                          opacity: 1,
                        },
                    }}
                  >
                    {/* {category.label}
                    {category.description} */}
                    <Box
                      sx={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "normal",
                        }}
                      >
                        {category.label}
                      </Typography>
                      {category.description && (
                        <Typography
                          sx={{
                            fontFamily: "Inter",
                            fontSize: "13px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            color: "#969696",
                          }}
                        >
                          {category.description}
                        </Typography>
                      )}
                    </Box>
                    {!category.is_available && (
                      <img
                        style={{ width: "20px", height: "20px" }}
                        src={upgradeIcon}
                      />
                    )}
                  </Option>
                ))}
              </List>
            </React.Fragment>
          )
        )}
      </Select>
    );
  }

  render() {
    const { t } = this.props;
    let page_title = `${t("Create project")} - ${
      globalConstants.WEBSITE_TITLE
    }`;
    const buttonIsEnabled =
      this.state.selected_category &&
      this.state.selected_category.is_available &&
      this.state.formData.name.length > 0 &&
      this.state.formData.main_characteristics.length > 0 &&
      typeof this.state.formData.product_category_id === "number"
        ? true
        : 0;

    console.log("selected category: ", this.state.selected_category);

    if (this.state.step == 1) {
      return (
        <Fragment>
          <Helmet>
            <title>{page_title}</title>
          </Helmet>
          <ProjectHeader
            history={this.props.history}
            title={t("Create project")}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              minWidth: "400px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "fit-content",
              }}
            >
              <Typography
                sx={{
                  mt: "50px",
                  width: "100%",
                  fontFamily: "Inter",
                  fontSize: "32px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "normal",
                }}
              >
                {t("Select your primary focus for this project")}
              </Typography>
              <Box sx={{ maxWidth: "1110px" }}>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Box
                    sx={{
                      mt: "32px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                      }}
                    >
                      {this.state.projectQualifications.map((item, index) =>
                        this.renderListItem(item)
                      )}
                    </Box>
                    <Box
                      sx={{
                        mt: "65px",
                        mb: "20px",
                        ml: "10px",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <button
                        onClick={(e) => {
                          let formData = Object.assign({}, this.state.formData);
                          formData.qualification = null;
                          this.setState({ formData, step: 2 });
                        }}
                        style={{
                          color: "#6D9EEE",
                          backgroundColor: "transparent",
                          border: "none",
                          cursor: "pointer",
                          fontFamily: "Inter",
                          fontSize: "15px",
                          fontStyle: "normal",
                          fontWeight: "500",
                          lineHeight: "normal",
                        }}
                      >
                        {t("Skip")}
                      </button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Fragment>
      );
    }

    if (this.state.isLoaded) {
      return (
        <Fragment>
          <Helmet>
            <title>{page_title}</title>
          </Helmet>
          <ProjectHeader
            history={this.props.history}
            title={t("Create project")}
          />
          <div className="padding-top-50 padding-bottom-20 responsive-920-padding-top-20">
            <div className="central-content">
              <div className="display-flex responsive-flex justify-content-space-between">
                <form className="margin-right-30">
                  <Typography
                    sx={{
                      color: "#000",
                      fontFamily: "Inter",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "normal",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {t("Name")}
                      <Tooltip
                        sx={{
                          maxWidth: "400px",
                          color: "#E0E0E0",
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "20px",
                          letterSpacing: "0.14px",
                          background: "rgba(97, 97, 97, 1)",
                        }}
                        title={t(
                          "Choose a clear, business-oriented title for your project. Well-crafted titles lead to more relevant results and make it easier to understand."
                        )}
                        arrow
                      >
                        <img
                          style={{ marginLeft: "5px" }}
                          width="20"
                          height="20"
                          src={InterrogationPicto}
                          alt=""
                        />
                      </Tooltip>
                    </Box>
                  </Typography>

                  <TextField
                    className="max-width-560 responsive-full-width"
                    hideLabel={true}
                    placeholder="Enter a descriptive name for your project."
                    type="text"
                    name="name"
                    value={this.state.formData.name}
                    onChange={this.handleChangeName}
                    disabled={this.state.submitLoading}
                    maxLength={256}
                    style={{
                      height: "50px",
                      marginTop: "15px",
                      marginBottom: "30px",
                    }}
                  />

                  <Typography
                    sx={{
                      mt: "10px",
                      mb: "15px",
                      color: "#000",
                      fontFamily: "Inter",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "normal",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {t("Objective")}
                      <Tooltip
                        sx={{
                          maxWidth: "400px",
                          color: "#E0E0E0",
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "20px",
                          letterSpacing: "0.14px",
                          background: "rgba(97, 97, 97, 1)",
                        }}
                        title={t(
                          "Draft clear and concise objectives for your project, avoiding brand-specific details. This will enhance the quality of your results and recommendations."
                        )}
                        arrow
                      >
                        <img
                          style={{ marginLeft: "5px" }}
                          width="20"
                          height="20"
                          src={InterrogationPicto}
                          alt=""
                        />
                      </Tooltip>
                    </Box>
                  </Typography>

                  <LimitedTextareaField
                    defaultValue={this.state.formData.main_characteristics}
                    onChange={this.handleChangeMainCharacteristics}
                    placeholder={t(
                      "Please provide a brief overview of your project's goals and context. This information is essential for our AI to better understand the purpose and desired outcomes of your project"
                    )}
                    className="max-width-560 responsive-full-width min-height-166"
                    formElementClassName="max-width-560 responsive-full-width"
                    disabled={this.state.submitLoading}
                    limit={null}
                  />
                  <div
                    className="display-flex gap-7 font-size-12"
                    style={{
                      alignItems: "center",
                      color: "#9e9e9e",
                      marginTop: "15px",
                    }}
                  >
                    <img src={InfoPicto} alt="" />

                    <Typography
                      sx={{
                        color: "#9E9E9E",
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "20px",
                        letterSpacing: "0.14px",
                      }}
                    >
                      {t(
                        "If your story is not in English, it will be automatically translated for analysis. "
                      )}
                    </Typography>
                  </div>

                  <Typography
                    sx={{
                      mt: "30px",
                      mb: "15px",
                      color: "#000",
                      fontFamily: "Inter",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "normal",
                    }}
                  >
                    {t("Category")}
                  </Typography>
                  {this.selectProjectCategory()}
                  {this.state.selected_category &&
                    !this.state.selected_category.is_available && (
                      <Box
                        sx={{
                          maxWidth: "534px",
                          p: "15px 12px 16px 12px",
                          borderRadius: "4px",
                          border: "1px solid #FF9378",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Box sx={{ display: "flex" }}>
                          <img
                            style={{ width: "16px", height: "16px" }}
                            src={upgradeIcon}
                          />
                          <Typography
                            sx={{
                              ml: "13px",
                              color: "#F78F64",
                              fontFamily: "Inter",
                              fontSize: "15px",
                              fontStyle: "normal",
                              fontWeight: "600",
                              lineHeight: "normal",
                              letterSpacing: "0.15px",
                            }}
                          >
                            {t("Unlock Specialized AI")}
                          </Typography>
                        </Box>

                        <Typography
                          sx={{
                            mt: "20px",
                            mb: "20px",
                            color: "#757575",
                            fontFamily: "Inter",
                            fontSize: "15px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                          }}
                        >
                          <Trans i18nKey="unlock_relevant_insights">
                            Unlock relevant insights and maximize your data
                            analysis for your{" "}
                            <span style={{ fontWeight: 700 }}>
                              {this.state.groupClassLabel}
                            </span>{" "}
                            projects by adding a dedicated AI now.{" "}
                          </Trans>
                        </Typography>
                        <div
                          style={{
                            width: "100%",
                          }}
                          className="float-right width-100"
                        >
                          <Typography
                            className="float-right"
                            onClick={() => IntercomHelper.trackEvent("add_IA")}
                            style={{
                              cursor: "pointer",
                              marginRight: "10px",
                              color: "#6D9EEE",
                              fontFamily: "Inter",
                              fontSize: "15px",
                              fontStyle: "normal",
                              fontWeight: "500",
                              lineHeight: "normal",
                              textDecoration: "none",
                            }}
                          >
                            {t("Add AI")}
                          </Typography>
                        </div>
                      </Box>
                    )}

                  <div className="overflow-hidden">
                    {!this.state.submitLoading && (
                      <div className="float-right margin-top-15">
                        <button
                          type="button"
                          className="button backgroundless color-6d9eee"
                          onClick={(e) => {
                            let formData = Object.assign(
                              {},
                              this.state.formData
                            );
                            formData.qualification = null;
                            this.setState({ formData, step: 1 });
                          }}
                        >
                          {t("Back")}
                        </button>
                        <button
                          onClick={this.onSubmitCreate}
                          disabled={!buttonIsEnabled}
                          className="margin-left-5 button backgroundless"
                        >
                          {t("Create project")}
                        </button>
                      </div>
                    )}
                    {this.state.submitLoading && (
                      <div className="float-right" style={{ padding: "2px 0" }}>
                        <Loader />
                      </div>
                    )}
                  </div>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      flexWrap: "wrap",
                    }}
                  >
                    <Box sx={{ width: "277px" }} />
                    <Box sx={{ width: "277px" }} />
                  </Box>
                </form>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <div className="banner-container">
                    <div className="banner-titles banner-name">
                      {this.state.formData.name.length > 0
                        ? this.state.formData.name
                        : "Name"}
                    </div>

                    <div className="banner-titles banner-product-category">
                      {this.state.groupClassLabel.length > 0
                        ? this.state.groupClassLabel
                        : "Category"}
                    </div>

                    <ProjectBanner
                      imgWidth="396px"
                      withShapeDivider={true}
                      className="features"
                      img={CreateProjectBanner}
                    />
                  </div>
                </Box>
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
    return (
      <LoadingView
        title={page_title}
        withHeader={true}
        headerProps={{ isProject: true }}
      />
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withTranslation()(connect(mapStateToProps)(CreateProjectView));
