import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { globalConstants } from "../../app/constants";
import Header from "../../components/header/Header";

import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { projectService } from "../../app/services/project.service";
import HttpHelper from "../../app/helpers/HttpHelper";
import UserHelper from "../../app/helpers/UserHelper";

//import "./tabViews/overview/OverView.css";

import IntercomHelper from "../../app/helpers/IntercomHelper";
import { Box, Tab, Tabs, Typography, styled } from "@mui/material";
import ProjectDetailView from "../projectDetail/ProjectDetailView";
import ProjectNewInsight from "../projectNewInsight/projectNewInsight";
import ProjectReportView from "../projectReport/ProjectReportView";
import ProjectWorkspaceView from "../projectWorkspace/ProjectWorkspaceView";
import { PROJECT_DETAIL, ROUTES } from "../../app/routing";
import { ProjectDataManager } from "../../dataManagers/projectDataManager";

import EditProjectModal from "../../components/modal/EditProjectModal";
import DeleteModal from "../../components/modal/DeleteModal";
import Loader from "../../components/loader/Loader";
import { SessionStorageService } from "../../app/services/storage.service";

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
    backgroundColor: "#000",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: "#9e9e9e",
    "&.Mui-selected": {
      color: "#000",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

function getDefaultTab(search) {
  const urlParams = HttpHelper.getUrlParameters(search);
  if (urlParams && urlParams.hasOwnProperty("tab")) {
    return urlParams.tab;
  }

  return "overview";
}

function getDefaultDatasource(search) {
  const urlParams = HttpHelper.getUrlParameters(search);
  if (urlParams && urlParams.hasOwnProperty("datasourceId")) {
    return parseInt(urlParams.datasourceId);
  }

  return null;
}

class ProjectView extends React.Component {
  constructor(props) {
    super(props);
    const projectId = props.match.params.id
      ? parseInt(props.match.params.id)
      : null;

    this.state = {
      projectId: projectId,
      datasourceId: getDefaultDatasource(props.location.search),
      project: null,
      tabValue: getDefaultTab(props.location.search), // first tab value
      workspaceDefaultKeywords: [],
      workspaceDefaultClassId: null,
      displayOriginalReviews: false,
      editProject: {
        display: false,
        item: null,
        loading: false,
        error: null,
      },
      deleteProject: {
        display: false,
        loading: false,
        error: null,
      },
    };

    this.displayToastErrorMessage = this.displayToastErrorMessage.bind(this);
    this.renderTabChildren = this.renderTabChildren.bind(this);
    this.fetchProject = this.fetchProject.bind(this);
    this.pageTitleHandler = this.pageTitleHandler.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.onOpenEditProjectModal = this.onOpenEditProjectModal.bind(this);
    this.onCloseEditProjectModal = this.onCloseEditProjectModal.bind(this);
    this.onEditProject = this.onEditProject.bind(this);

    this.onOpenDeleteProjectModal = this.onOpenDeleteProjectModal.bind(this);
    this.onCloseDeleteProjectModal = this.onCloseDeleteProjectModal.bind(this);
    this.onDeleteProject = this.onDeleteProject.bind(this);

    this.abortController = new AbortController();
    this.abortControllerSignal = this.abortController.signal;

    SessionStorageService.set("update_media_referrer", props.location.pathname);

    IntercomHelper.updateIntercom();
  }

  componentDidMount() {
    this._ismounted = true;
    this.fetchProject();
  }

  componentWillUnmount() {
    this._ismounted = false;
    this.abortController.abort();
  }

  async fetchProject() {
    await projectService.get.get(
      this.state.projectId,
      (response) => {
        const accounts = UserHelper.getAllUserAccounts().filter(
          (account) => account.id === response.accountId
        );
        if (accounts && accounts.length)
          UserHelper.updateCurrentAccountById(response.accountId);

        this.setState({
          project: response,
        });
      },
      (error) => {
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      },
      { schema: "overview" },
      this.abortControllerSignal
    );
  }

  pageTitleHandler() {
    return `${this.state.project ? this.state.project.name + " -" : ""} ${
      globalConstants.WEBSITE_TITLE
    }`;
  }

  handleChange(event, newValue) {
    window.scrollTo(0, 0);
    let datasourceId = this.state.datasourceId;
    if (newValue == "overview") {
      datasourceId = null;
    }
    this.setState({ tabValue: newValue, datasourceId: datasourceId }, () => {
      this.updateUrl();
    });
  }

  updateUrl() {
    let search = "?tab=";
    search = search + this.state.tabValue;
    if (this.state.datasourceId) {
      search = search + "&datasourceId=" + this.state.datasourceId;
    }

    this.props.history.replace({
      pathname: PROJECT_DETAIL(this.state.projectId),
      search: search,
    });
  }

  // EDIT PROJECT
  onOpenEditProjectModal() {
    this.setState({
      editProject: {
        display: true,
        item: this.state.project,
        loading: false,
        error: null,
      },
    });
  }

  onCloseEditProjectModal() {
    this.setState({
      editProject: {
        display: false,
        item: null,
        loading: false,
        error: null,
      },
    });
  }

  onEditProject(formData) {
    let editProjectData = Object.assign({}, this.state.editProject);
    if (editProjectData.item) {
      editProjectData.loading = true;

      this.setState({
        editProject: editProjectData,
      });

      projectService.put.update_name(
        this.state.projectId,
        formData,
        (response) => {
          this.props.addToast("Project name successfully updated", {
            type: "success",
            autoDismiss: true,
          });
          this.setState({
            editProject: {
              display: false,
              item: null,
              loading: false,
              error: null,
            },
            project: response,
          });
        },
        (error) => {
          editProjectData.loading = false;
          this.displayToastErrorMessage(error, null, {
            editProject: editProjectData,
          });
        }
      );
    }
  }

  onOpenDeleteProjectModal() {
    this.setState({
      deleteProject: {
        display: true,
        item: this.state.project,
        loading: false,
        error: null,
      },
    });
  }

  onCloseDeleteProjectModal() {
    this.setState({
      deleteProject: {
        display: false,
        item: null,
        loading: false,
        error: null,
      },
    });
  }

  onDeleteProject() {
    let deleteData = Object.assign({}, this.state.deleteProject);
    deleteData.loading = true;
    this.setState({
      deleteProject: deleteData,
    });

    projectService.delete.delete(
      this.state.projectId,
      (response) => {
        setTimeout(() => {
          this.props.addToast(
            'Project "' +
              this.state.deleteProject.item.name +
              '" successfully deleted',
            { type: "success", autoDismiss: true }
          );
        }, 800);
        this.props.history.push(ROUTES.HOME);
      },
      (error) => {
        deleteData.loading = false;
        this.displayToastErrorMessage(error, null, {
          deleteProject: deleteData,
        });
      }
    );
  }

  displayToastErrorMessage(error, onThen, updatedState) {
    if (!this._ismounted) {
      return;
    }
    this.props.addToast(HttpHelper.getErrorMessage(error), {
      type: "error",
      autoDismiss: true,
    });
    if (typeof onThen == "function") {
      onThen();
    }
    if (updatedState) {
      this.setState(updatedState);
    }
  }

  renderTabChildren(tabValue) {
    if (tabValue === "overview") {
      return (
        <ProjectDetailView
          key="overview"
          history={this.props.history}
          addToast={this.props.addToast}
          projectId={this.state.projectId}
          updateProject={(project) => this.setState({ project: project })}
          selectDatasourceId={(datasourceId, tab = "insights") =>
            this.setState(
              {
                datasourceId: datasourceId,
                tabValue: tab,
              },
              () => {
                this.updateUrl();
              }
            )
          }
        />
      );
    } else if (tabValue === "insights") {
      return (
        <ProjectNewInsight
          key="insights"
          history={this.props.history}
          addToast={this.props.addToast}
          projectId={this.state.projectId}
          datasourceId={this.state.datasourceId}
          selectDatasourceId={(datasourceId) =>
            this.setState({ datasourceId: datasourceId }, () => {
              this.updateUrl();
            })
          }
        />
      );
    } else if (tabValue === "in-depth") {
      return (
        <ProjectReportView
          key="in-depth"
          history={this.props.history}
          addToast={this.props.addToast}
          projectId={this.state.projectId}
          datasourceId={this.state.datasourceId}
          selectDatasourceId={(datasourceId) =>
            this.setState({ datasourceId: datasourceId }, () => {
              this.updateUrl();
            })
          }
          openWorkspaceWithKeywords={(keywords) => {
            this.setState(
              {
                workspaceDefaultKeywords: keywords,
                tabValue: "inspect",
              },
              () => {
                this.updateUrl();
              }
            );
          }}
        />
      );
    } else if (tabValue === "inspect") {
      return (
        <ProjectWorkspaceView
          key="inspect"
          history={this.props.history}
          addToast={this.props.addToast}
          projectId={this.state.projectId}
          datasourceId={this.state.datasourceId}
          defaultKeywords={this.state.workspaceDefaultKeywords}
          defaultClassId={this.state.workspaceDefaultClassId}
          displayOriginalReviews={this.state.displayOriginalReviews}
          updateDisplayOriginalReviews={(val) =>
            this.setState({ displayOriginalReviews: val })
          }
          updateKeywordsSelection={(keywords) =>
            this.setState({ workspaceDefaultKeywords: keywords })
          }
          selectDatasourceId={(datasourceId) => {
            this.setState(
              {
                datasourceId: datasourceId,
              },
              () => {
                this.updateUrl();
              }
            );
          }}
        />
      );
    }

    return <Loader />;
  }

  render() {
    const { t } = this.props;
    const { tabValue, project } = this.state;
    let page_title = this.pageTitleHandler();

    return (
      <Fragment>
        <Header isProject={true} />
        <Helmet>
          <title>{page_title}</title>
        </Helmet>
        <div className="topTab responsive-920-padding-top-20">
          <div className="central-content">
            <div className="position-relative">
              <Box sx={{ width: "95%" }}>
                <Typography
                  sx={{
                    color: "#000",
                    fontFamily: "Inter",
                    fontSize: "24px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "normal",
                  }}
                >
                  {project?.name}
                </Typography>
              </Box>
            </div>
            <div className="position-relative">
              <Box sx={{ width: "95%", mt: "6px" }}>
                <Typography
                  sx={{
                    color: "#616161",
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal",
                  }}
                >
                  {project?.type_name}
                </Typography>
              </Box>
            </div>
            <Box
              sx={{
                width: "100%",
                mt: "20px",
                position: "sticky",
                top: "60px",
                zIndex: "18",
              }}
            >
              {/* full width grey background */}
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: "50%",
                  transform: "translateX(-50%)",
                  width: "100vw",
                  height: "100%",
                  background: "#f8f8f8",
                }}
              />
              <StyledTabs
                value={tabValue}
                onChange={this.handleChange}
                aria-label="project detail tabs"
              >
                <StyledTab value="overview" label="Overview" />
                <StyledTab value="insights" label="Insights" />
                <StyledTab value="in-depth" label="In-Depth" />
                <StyledTab value="inspect" label="Inspect" />
              </StyledTabs>
            </Box>

            <ProjectDataManager>
              {this.renderTabChildren(tabValue)}
            </ProjectDataManager>
          </div>
        </div>
        {this.state.editProject.display && (
          <EditProjectModal
            onClose={this.onCloseEditProjectModal}
            loading={this.state.editProject.loading}
            item={this.state.editProject.item}
            onEditItem={this.onEditProject}
          />
        )}
        {this.state.deleteProject.display && (
          <DeleteModal
            itemValue={
              this.state.deleteProject.item
                ? this.state.deleteProject.item.name
                : null
            }
            title={t("Delete project")}
            subtitle={t("You are going to delete the project")}
            inputLabel={t("Enter project name to confirm")}
            buttonLabel={t("Delete project")}
            onClose={this.onCloseDeleteProjectModal}
            loading={this.state.deleteProject.loading}
            onDeleteItem={this.onDeleteProject}
          />
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withTranslation()(connect(mapStateToProps)(ProjectView));
