import React, { useEffect, useState } from "react";
import SignupHelper from "../../../app/helpers/SignupHelper";
import HttpHelper from "../../../app/helpers/HttpHelper";
import {
  Box,
  Button,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
  FormControlLabel,
} from "@mui/material";
import sensia_logo from "../../../res/images/logo/logo_sensia.svg";
import createAccountImg from "../../../res/images/illustrations/illus-create-account.png";
import { userService } from "../../../app/services";
import { useTranslation } from "react-i18next";
import StringHelper from "../../../app/helpers/StringHelper";
import { ADD_TOKEN_TO_ROUTE, ROUTES } from "../../../app/routing";
import UserHelper from "../../../app/helpers/UserHelper";
import Loader from "../../../components/loader/Loader";

const SignupView = (props) => {
  const { t } = useTranslation();
  const isNonMobileScreen = useMediaQuery("(min-width:1000px)");
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const urlParameters = HttpHelper.getUrlParameters(props.location.search);
  const token = urlParameters.token;
  const invitation_token = urlParameters.invitation_token;

  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordChecks, setPasswordChecks] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    if (token) {
      setIsLoading(true);
      userService.checkValidEmailToken(
        token,
        (response) => {
          setIsLoading(false);
        },
        (error) => {
          console.log(error);
          setIsLoading(false);
          props.history.push(ROUTES.HOME);
        }
      );
    }

    if (invitation_token) {
      setIsLoading(true);
      userService.checkTokenInvitation(
        invitation_token,
        (response) => {
          setIsLoading(false);
        },
        (error) => {
          console.log(error);
          setIsLoading(false);
        }
      );
    }
  }, [token, invitation_token]);

  const handleSubmit = () => {
    setIsLoading(true);
    const formData = {
      token: token,
      invitation_token: invitation_token,
      first_name: firstName,
      last_name: lastName,
      password: password,
    };
    userService.createUser(
      formData,
      (response) => {
        setIsLoading(false);
        SignupHelper.updateUser(response.user);
        UserHelper.setUser(response.user);
        if (response.account) {
          UserHelper.updateCurrentAccount(response.account);
          props.history.push(ROUTES.HOME);
        } else if (
          !response.account &&
          (response.is_create_account_completed || invitation_token)
        ) {
          props.history.push(
            ADD_TOKEN_TO_ROUTE(ROUTES.LOGIN, "step=DELETED_ACCOUNT")
          );
        } else {
          props.history.push(ROUTES.CREATE_ACCOUNT);
        }
      },
      (error) => {
        setIsLoading(false);
        setError(HttpHelper.getErrorMessage(error));
      }
    );
  };

  const leftCentralContent = (
    <Box sx={{ height: "449px" }}>
      <Typography
        sx={{
          color: "#0E004B",
          fontFamily: "Inter",
          fontSize: "24px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "normal",
        }}
      >
        <p>
          Create your <span style={{ color: "#30BEEC" }}>account</span>
        </p>
      </Typography>
      <Typography
        sx={{
          color: "#666D80",
          fontFamily: "Inter",
          fontSize: "15px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "22px",
        }}
      >
        {t("Start Transforming Reviews into Strategic Assets")}
      </Typography>
      <TextField
        id="firstName"
        size="medium"
        label={t("First name")}
        name="firstname"
        required={true}
        disabled={isLoading}
        placeholder={t("Enter your first name")}
        value={firstName}
        onChange={(e) => {
          setFirstName(e.target.value);
        }}
        sx={{ mt: "30px", height: "50px", width: "100%" }}
      />
      <TextField
        id="lastName"
        size="medium"
        label={t("Last name")}
        name="lastname"
        required={true}
        disabled={isLoading}
        placeholder={t("Enter your last name")}
        value={lastName}
        onChange={(e) => {
          setLastName(e.target.value);
        }}
        sx={{ mt: "25px", height: "50px", width: "100%" }}
      />
      <TextField
        size="medium"
        type="password"
        label={t("Password")}
        name="password"
        autoComplete="new-password"
        required={true}
        disabled={isLoading}
        placeholder={t("Enter your password")}
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
          const checks = StringHelper.getPasswordChecks(e.target.value);
          setPasswordChecks(checks);
        }}
        sx={{ mt: "25px", height: "50px", width: "100%" }}
      />
      <Box sx={{ mt: "46px" }}>
        <RadioGroup
          name="use-radio-group"
          value={passwordChecks.size ? "ok" : "nok"}
        >
          <FormControlLabel
            value="ok"
            label={t("8 characters minimum")}
            control={
              <Radio sx={{ cursor: "auto" }} disabled={!passwordChecks.size} />
            }
          />
        </RadioGroup>

        <RadioGroup
          name="use-radio-group"
          value={passwordChecks.lowercase ? "ok" : "nok"}
        >
          <FormControlLabel
            value="ok"
            label={t("A lowercase character")}
            control={
              <Radio
                sx={{ cursor: "auto" }}
                disabled={!passwordChecks.lowercase}
              />
            }
          />
        </RadioGroup>

        <RadioGroup
          name="use-radio-group"
          value={passwordChecks.uppercase ? "ok" : "nok"}
        >
          <FormControlLabel
            value="ok"
            label={t("An uppercase character")}
            control={
              <Radio
                sx={{ cursor: "auto" }}
                disabled={!passwordChecks.uppercase}
              />
            }
          />
        </RadioGroup>

        <RadioGroup
          name="use-radio-group"
          value={passwordChecks.symbol ? "ok" : "nok"}
        >
          <FormControlLabel
            value="ok"
            label={t("A number, symbol or space")}
            control={
              <Radio
                sx={{ cursor: "auto" }}
                disabled={!passwordChecks.symbol}
              />
            }
          />
        </RadioGroup>
      </Box>

      <Box sx={{ mt: "46px", display: "flex", justifyContent: "flex-end" }}>
        {isLoading && (
          <Box sx={{ width: "20px", padding: "14px 52px" }}>
            <Loader />
          </Box>
        )}
        {!isLoading && (
          <Button
            variant="contained"
            disabled={
              isLoading ||
              !firstName ||
              !lastName ||
              !password ||
              !StringHelper.isPasswordValid(password)
            }
            onClick={() => handleSubmit()}
            sx={{
              background: "#306ED6",
              textTransform: "none",
              padding: "14px 52px",
            }}
          >
            <Typography
              sx={{
                color: "#FFF",
                fontFamily: "Inter",
                fontSize: "15px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
              }}
            >
              {t("Create")}
            </Typography>
          </Button>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          flexWrap: isSmallScreen ? "wrap" : "nowrap",
        }}
      >
        <Box sx={{ width: "240px" }} />
        <Box sx={{ width: "240px" }} />
      </Box>
    </Box>
  );

  const leftSide = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        height: "100%",
        pl: "10px",
        pr: "10px",
      }}
    >
      <Grid
        container
        direction="column"
        spacing={2}
        sx={{
          width: "min-content",
        }}
      >
        <Grid item sx={{ height: "20%" }}>
          <Box sx={{ pt: "46px" }}>
            <img src={sensia_logo} alt="logo sensia" width="126px" />
          </Box>
        </Grid>
        <Grid
          item
          sx={{ height: "80%", display: "flex", alignItems: "flex-start" }}
        >
          {leftCentralContent}
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <Grid container spacing={0} sx={{ position: "absolute", height: "100%" }}>
      {isNonMobileScreen ? (
        <>
          <Grid item xs={7}>
            {leftSide}
          </Grid>
          <Grid item xs={5}>
            <Box
              sx={{
                height: "100%",
                backgroundImage: `url("${createAccountImg}")`,
                backgroundRepeat: "no-repeat",
                backgroundPositionX: "center",
                backgroundPositionY: "bottom",
                backgroundSize: "contain",
                backgroundColor: "#1D1393",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          {leftSide}
        </Grid>
      )}
    </Grid>
  );
};

export default SignupView;
