import React from 'react';
import { withTranslation } from "react-i18next";
import '../form.css';

const defaultLimit = null;

class LimitedTextareaField extends React.Component {
    constructor(props) {
        super(props);
        const defaultValue = props.defaultValue || "";
        const limit = props.limit || defaultLimit;
        const chars_left = (limit) ? limit - defaultValue.length : limit;
        this.state = {
            chars_left: chars_left,
            limit: limit,
            value: defaultValue
        };
        this.onChange = this.onChange.bind(this);
        this.handleWordCount = this.handleWordCount.bind(this);
    }

    handleWordCount(newValue) {
        const charCount = newValue.length;
        const maxChar = this.state.limit;
        const charLength = maxChar - charCount;
        this.setState({ chars_left: charLength });
    }

    onChange(event) {
        let value = event.target.value;
        if(this.state.limit){
            value = value.slice(0, this.state.limit);
        }
        this.setState({value:value});
        this.handleWordCount(value)
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }
    render() {
        const { t } = this.props;
        let formElementClassName = ["limited-textarea-field"];
        if (this.props.formElementClassName) {
            formElementClassName.push(this.props.formElementClassName);
        }
        const libelleNbChars = (this.state.chars_left > 1) ? t('{{count}} characters left', { count: this.state.chars_left }) : t('{{count}} character left', { count: this.state.chars_left });
        return (
            <div className={formElementClassName.join(' ')}>
                <textarea
                    value={this.state.value}
                    onChange={this.onChange}
                    placeholder={this.props.placeholder || ""}
                    className={this.props.className}
                    disabled={this.props.disabled} />
                {!!this.state.limit &&                   
                <div className="text-align-right font-inter font-size-12 color-7f7f7f padding-top-4">
                    {libelleNbChars}
                </div>}
            </div>
        );
    }
}

export default withTranslation()(LimitedTextareaField);