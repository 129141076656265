import '../plan.css';
import './purchaseResultView.css';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { getAccountPlan, globalConstants } from "../../../app/constants";
import { connect } from 'react-redux';
import LoadingView from '../../loading/LoadingView';
import moment from 'moment-timezone';
import { withTranslation } from 'react-i18next';
import { ROUTES } from '../../../app/routing';


import HttpHelper from '../../../app/helpers/HttpHelper';
import UserHelper from '../../../app/helpers/UserHelper';

import PlanHeader from '../elements/PlanHeader';
import { SessionStorageService } from '../../../app/services/storage.service';
import Link from '../../../components/Link';

class PurchaseResultView extends React.Component {
    constructor(props) {
        super(props);

        const targetPlan = getAccountPlan(props.match.params.target_plan);
        const urlParams = HttpHelper.getUrlParameters(props.location.search);
        const planFreq = urlParams.plan_freq || null;
        const status = urlParams.status || 'error';
        const message = urlParams.message || null;
        const startDate = urlParams.start_date || null;
        const amount = urlParams.amount || null;
        const currencyCode = urlParams.currency_code || null;
        const referrer = (props.location.state && props.location.state.referrer) ? props.location.state.referrer : null;
        this.state = {
            isLoaded: false,
            targetPlan: targetPlan,
            referrer: referrer,
            order: {
                plan: targetPlan.toLowerCase(),
                status: status,
                message: message,
                plan_freq: planFreq,
                amount: amount,
                start_date: startDate,
                currency_code: currencyCode,
            }
        };
        this.fetchInitial = this.fetchInitial.bind(this);
        if (!SessionStorageService.get('update_plan_referrer') && referrer) {
            SessionStorageService.set('update_plan_referrer', referrer)
        }
    }

    componentDidMount() {
        this._ismounted = true;
        this.fetchInitial();
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    fetchInitial() {
        const isAdmin = UserHelper.isAdmin();
        if (!isAdmin) {
            this.props.history.push(ROUTES.PLAN_CONTACT_YOUR_ADMINISTRATOR);
        }
        if(this.state.order.status === 'error' && !this.state.order.message) {
            this.props.history.push(ROUTES.HOME);
        }
        this.setState({ isLoaded: true });
    }


    render() {
        const { t } = this.props;
        let page_title = `${t('Choose your perfect plan')} - ${globalConstants.WEBSITE_TITLE}`;
        const language = navigator.language
        if (this.state.isLoaded) {
            const orderStatus = this.state.order.status;
            const bannerTitle = (orderStatus === 'success') ? t('Your purchase is complete') : t('Something went wrong');
            return (
                <Fragment>
                    <Helmet>
                        <title>{page_title}</title>
                    </Helmet>
                    <PlanHeader history={this.props.history} title={t('Upgrade to')} boldTitle={t('{{plan}} plan', { plan: this.state.targetPlan })} />
                    <div className={`central-content purchase-result ${orderStatus}`}>
                        <div className="purchase-banner">
                            {bannerTitle}
                        </div>
                        <div className="purchase-box-container">
                            <div className="content-column padding-left-56">
                                <div className={(orderStatus === 'success') ? 'column-percent-50 responsive-900' : ''}>
                                    <div className="plan-title">
                                        {t('{{plan}} plan', { plan: this.state.targetPlan })}
                                    </div>
                                    {this.state.order.plan_freq &&
                                        <div className="plan-sub-title">
                                            {t('Billed {{plan_freq}}', { plan_freq: this.state.order.plan_freq.toLowerCase() })}
                                        </div>
                                    }
                                    {orderStatus === 'success' && (
                                        <Fragment>
                                            <div className="receipt-info">
                                                {t('Receipt information')}
                                            </div>
                                            <div className="grey-line"></div>
                                            <div className="content-column">
                                                <div className="column-percent-70">
                                                    <div className="font-size-13 color-7f7f7f line-height-1-4">
                                                        {t('{{plan}} plan - 1 {{freq}}', { plan: this.state.targetPlan, freq: this.state.order.plan_freq.toLowerCase()})}
                                                    </div>
                                                </div>
                                                <div className="column-percent-30 text-align-right">
                                                    <span className="font-weight-700 color-7f7f7f font-size-15 line-height-1-2">{new Intl.NumberFormat(language, { style: 'currency', currency: this.state.order.currency_code }).format(this.state.order.amount)}</span>
                                                </div>
                                            </div>
                                            <div className="grey-line"></div>
                                            <div className="content-column">
                                                <div className="column-percent-70">
                                                    <div className="font-size-13 color-7f7f7f line-height-1-4">
                                                        {t('Start Date')}
                                                    </div>
                                                </div>
                                                <div className="column-percent-30 text-align-right">
                                                    <span className="font-weight-700 color-7f7f7f font-size-15 line-height-1-2">{moment.utc(this.state.order.start_date).local().format('DD/MM/YYYY')}</span>
                                                </div>
                                            </div>
                                            <div className="grey-line"></div>
                                            <div className="content-column">
                                                <div className="column-percent-70">
                                                    <div className="font-size-13 font-weight-bold line-height-1-4">
                                                        {t('Amount paid')}
                                                    </div>
                                                </div>
                                                <div className="column-percent-30 text-align-right">
                                                    <span className="font-size-15 font-weight-bold ">{new Intl.NumberFormat(language, { style: 'currency', currency: this.state.order.currency_code }).format(this.state.order.amount)}</span>
                                                </div>
                                            </div>
                                            <div className="using-sensia">
                                                {t('Thanks for using Sensia! You can find the details of your purchase in the invoice section.We hope you enjoy the Pro plan experience. Happy invoicing!')}
                                            </div>
                                        </Fragment>
                                    )}
                                    {orderStatus === 'error' && (
                                        <Fragment>
                                            <div className="error-line">
                                                {this.state.order.message}
                                            </div>
                                        </Fragment>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="text-align-center padding-top-30">
                            {orderStatus === 'success' &&  <Link className="button blue" to={ROUTES.HOME}>{t('Get started')}</Link>}
                            {orderStatus === 'error' && !!this.state.referrer &&  <Link className="button blue" to={this.state.referrer}>{t('Go back')}</Link>}
                            {orderStatus === 'error' && !this.state.referrer &&  <Link className="button blue" to={ROUTES.HOME}>{t('Go home')}</Link>}
                        </div>
                    </div>
                    <div className="padding-top-30" />
                </Fragment>
            );
        }
        return <LoadingView title={page_title} />;
    }
}

function mapStateToProps(state) {
    return {};
}

export default withTranslation()(connect(mapStateToProps)(PurchaseResultView));