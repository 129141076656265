import React from 'react';
import { withTranslation } from 'react-i18next';

class UsersGuideShowMoreButton extends React.Component {
    render() {
        const { t, opened } = this.props;
        let classNames = ['user-guide-accordion-button-show-more', 'transition-color'];
        if (opened) {
            classNames.push('opened');
        }
        const libelle = (opened) ? t('Hide more tasks') : t('Show more tasks');
        return (
            <div className="position-relative text-align-center">
                <div className={classNames.join(' ')} onClick={this.props.onHandleOpen}>
                    <span className="icon" />
                    <span className="padding-left-8 font-inter-500 font-size-14 line-height-24">{libelle}</span>
                </div>
            </div>
        );
    }
}

export default withTranslation()(UsersGuideShowMoreButton);