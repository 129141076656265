import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const STypography = styled(Typography)({
  fontFamily: '"Inter", sans-serif',
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  color: "#000",
});

export default STypography;
