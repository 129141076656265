import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  DateField,
  SearchInput,
  useListContext,
  Loading,
} from "react-admin";
import { ScoreField } from "./elements/CustomFields";
import FilledDateField from "./elements/FilledDateField";

const postFilters = [
  <SearchInput source="q" className="remove-line-height" alwaysOn />,
  <TextInput
    label="Account name"
    source="account_name"
    className="remove-line-height"
  />,
];

export const ProjectList = (props) => (
  <List filters={postFilters} {...props} perPage={25}>
    <MyDatagrid />
  </List>
);

const MyDatagrid = () => {
  const { isLoading, isFetching } = useListContext();

  if (isLoading || isFetching) {
    return <Loading />;
  }

  return (
    <Datagrid bulkActionButtons={false}>
      <TextField label="Project ID" source="id" />
      <TextField
        label="Product Category"
        source="product_name"
        sortable={false}
      />
      <TextField label="Project Name" source="name" />
      <ScoreField
        label="Average score"
        source="product_score"
        sortable={false}
      />
      <FilledDateField label="Created at" source="created_at" hidden={false} />
      <TextField label="Account Name" source="account_name" />
      <TextField
        label="Number of data sources"
        source="medias"
        sortable={true}
      />
    </Datagrid>
  );
};