import React, { Fragment } from "react";
import { Trans, withTranslation } from "react-i18next";
import ButtonField from "../../../components/form/field/ButtonField";
import ProjectListItem from "../../../components/list/ProjectListItem";
import Loader from "../../../components/loader/Loader";
import ListOptSelect from "../../../components/listOptSelect/ListOptSelect";
import {
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  selectClasses,
} from "@mui/material";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import CheckIcon from "@mui/icons-material/Check";
import SearchIcon from "@mui/icons-material/Search";

class ProjectsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortedBy: [this.props.defaultSortedBy],
    };

    this.handleChange = this.handleChange.bind(this);

    this.sortLabels = {
      created_at: "Created",
      updated_at: "Last updated",
      name: "From A-Z",
    };
  }

  handleChange(e) {
    const newSort = e.target.value;
    const currentSort = Object.assign([], this.state.sortedBy);

    const newSortWithoutArchived = newSort.filter(
      (sort) => sort != "add_archived"
    );
    const currentSortWithoutArchived = currentSort.filter(
      (sort) => sort != "add_archived"
    );

    const newSortedBy = [];

    if (
      JSON.stringify(newSortWithoutArchived) ==
      JSON.stringify(currentSortWithoutArchived)
    ) {
      //nothing changed if we not considering archived selection
      newSortedBy.push(...newSortWithoutArchived);
    } else {
      newSort
        .filter((sort) => sort != "add_archived")
        .forEach((item) => {
          if (!currentSort.includes(item)) {
            newSortedBy.push(item);
          }
        });
    }

    if (newSort.includes("add_archived")) {
      newSortedBy.push("add_archived");
    }

    this.setState({ sortedBy: newSortedBy });

    const infos = {
      sorted_by: newSortedBy.filter((sort) => sort != "add_archived")[0],
      add_archived: newSort.includes("add_archived") ? 1 : 0,
    };

    this.props.onChangeSortList(infos);
  }

  render() {
    const { t } = this.props;
    const listItems = this.props.listItems;
    const inlineLoading = this.props.inlineLoading;
    const parentLoaderClass =
      listItems.length > 0
        ? "text-align-center padding-vertical-20"
        : "text-align-center padding-vertical-80";
    let listTitle = t("Project");
    if (listItems.length > 1) {
      listTitle = t("Projects");
    }

    return (
      <div className="padding-top-60 responsive-920-margin-vertical-20">
        <div className="central-content">
          <div className="overflow-hidden">
            <div className="float-left">
              <div className="font-size-36 font-inter-700 color-000">
                {t("Project")}
              </div>
            </div>
            <div className="float-right">
              <ButtonField
                className="button blue"
                loading={this.props.loadingCreateProjectButton}
                onClick={this.props.onClickCreateProjectButton}
              >
                {t("Create project")}
              </ButtonField>
            </div>
          </div>
          <div>
            <TextField
              type="search"
              className="large-search-input"
              placeholder={t("Search")}
              onChange={(e) => this.props.onChangeSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ mt: "32px", width: "540px", fontFamily: "Inter" }}
            />
          </div>
          <div className="position-relative display-flex justify-content-space-between margin-top-32 align-items-center">
            {!inlineLoading && listItems.length > 0 && (
              <Fragment>
                <div className="font-size-13">
                  {this.props.total} {listTitle}
                </div>

                <Select
                  labelId="projects-sort-select"
                  id="projects-sort-select"
                  multiple
                  value={this.state.sortedBy}
                  onChange={this.handleChange}
                  renderValue={(option) => {
                    return (
                      <Typography
                        sx={{
                          color: "#3a79e6 ",
                          fontFamily: "Inter",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                          letterSpacing: "0.15px",
                        }}
                      >
                        {this.sortLabels[option[0]]}
                      </Typography>
                    );
                  }}
                  indicator={
                    <KeyboardArrowDown sx={{ fontStyle: "#3a79e6" }} />
                  }
                  slotProps={{
                    listbox: {
                      component: "div",
                      sx: {
                        width: 280,
                        maxHeight: 240,
                        overflow: "auto",
                        "--List-padding": "0px",
                        "--ListItem-radius": "0px",
                      },
                    },
                  }}
                  MenuProps={{
                    sx: {
                      "&& .Mui-selected, && .Mui-selected:hover": {
                        backgroundColor: "white",
                      },
                    },
                  }}
                  sx={{
                    width: "280px",
                    height: "50px",
                    background: "transparent",
                    fontFamily: "Inter",
                    fontSize: "15px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                    textAlign: "end",
                    color: "#3a79e6",
                    [`& .${selectClasses.indicator}`]: {
                      transition: "0.2s",
                      [`&.${selectClasses.expanded}`]: {
                        transform: "rotate(-180deg)",
                      },
                    },
                    [`& .${selectClasses.button}`]: {
                      opacity: 1,
                    },
                    "&.MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  }}
                >
                  <MenuItem
                    key="created_at"
                    value="created_at"
                    sx={{ justifyContent: "space-between" }}
                  >
                    {t("Sort by Created")}
                    {this.state.sortedBy.includes("created_at") ? (
                      <CheckIcon color="info" />
                    ) : null}
                  </MenuItem>
                  <MenuItem
                    key="updated_at"
                    value="updated_at"
                    sx={{ justifyContent: "space-between" }}
                  >
                    {t("Sort by Last updated")}
                    {this.state.sortedBy.includes("updated_at") ? (
                      <CheckIcon color="info" />
                    ) : null}
                  </MenuItem>
                  <MenuItem
                    key="name"
                    value="name"
                    sx={{ justifyContent: "space-between" }}
                  >
                    {t("Sort from A-Z")}
                    {this.state.sortedBy.includes("name") ? (
                      <CheckIcon color="info" />
                    ) : null}
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    key="add_archived"
                    value="add_archived"
                    sx={{ justifyContent: "space-between" }}
                  >
                    {t("Show Archived Projects")}
                    {this.state.sortedBy.includes("add_archived") ? (
                      <CheckIcon color="info" />
                    ) : null}
                  </MenuItem>
                </Select>
              </Fragment>
            )}
          </div>
          <div className="padding-top-24 content-list-items">
            {listItems.map((item) => {
              return (
                <ProjectListItem
                  myKey={item.id}
                  key={item.id}
                  item={item}
                  onOpenDeleteModal={this.props.onOpenDeleteModal}
                  onArchiveItem={this.props.onArchiveItem}
                  onUnArchiveItem={this.props.onUnArchiveItem}
                />
              );
            })}
          </div>
          {inlineLoading && (
            <div className={parentLoaderClass}>
              <Loader />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(ProjectsList);
