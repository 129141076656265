import React, { Fragment } from 'react';
import './LoadingView.css';

import Loader from '../../components/loader/Loader';
import { Helmet } from "react-helmet";
import Header from '../../components/header/Header';

class LoadingView extends React.Component {

    render () {
        return (
            <Fragment>
                {this.props.withHeader && <Header {...this.props.headerProps}/>}
                <div className={`content-loading-view ${this.props.withHeader ? 'with-header' : ''}`}>
                    <Helmet>
                        <title>{this.props.title}</title>
                    </Helmet>
                    <Loader />
                </div>
            </Fragment>
        );
    }
}

export default LoadingView;