import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";
import star_icon from "../../../../res/images/icons/star-icon-orange.png";
import star_icon_blue from "../../../../res/images/icons/star-icon-blue.svg";
import "../../settings.css";
import { PLAN_DOWNGRADE, PLAN_UGRAPDE, ROUTES } from "../../../../app/routing";
import { ACCOUNT_PLAN, getAccountPlan } from "../../../../app/constants";

import { Box, Button, LinearProgress } from "@mui/material";
import ReviewsIcon from "@mui/icons-material/Reviews";
import STypography from "../../../../components/SMui/STypography";
import IntercomHelper from "../../../../app/helpers/IntercomHelper";
import Link from "../../../../components/Link";
import moment from "moment-timezone";

class OverviewTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      account: props.account,
    };
  }

  componentDidMount() {
    this._ismounted = true;
  }

  render() {
    const { t, account } = this.props;
    const planKey = getAccountPlan(this.props.account.plan_key);
    const unsubscribeLink = PLAN_DOWNGRADE(ACCOUNT_PLAN.FREE);
    const reniewDate = moment
      .utc(account.expiration_date)
      .local()
      .format("MMMM DD, YYYY");
    let reniewText = "";
    if (account.expiration_date) {
      reniewText = `Your plan renew on ${reniewDate}`;
    }
    const planText = (
      <>
        You are on a <span style={{ fontWeight: 700 }}>{planKey}</span>
         plan. {reniewText} (
        <span
          onClick={() => this.props.history.push(unsubscribeLink)}
          className="light-link-blue"
        >
          Unsuscribe
        </span>
        ).
      </>
    );

    let title = "";
    let subtitle = "";
    switch (planKey) {
      case ACCOUNT_PLAN.FREE:
        title = t(
          "Upgrade now to Pro Plan to get Deeper Insights and Advanced Analytics!"
        );
        subtitle = t(
          "Manage 3 projects, access detailed analytics, and create 1 custom classe. Collect data from all Sensia sources and easily import surveys. Elevate your marketing intelligence today!"
        );
        break;
      case ACCOUNT_PLAN.PRO:
        title = t(
          "Upgrade now to Supercharge your Analytics and Collaboration!"
        );
        subtitle = t(
          "Manage 5 projects, access even deeper analytics, and create up to 4 custom classes. collaborate with up to 3 users. Elevate your marketing capabilities today!"
        );
        break;
      case ACCOUNT_PLAN.BUSINESS:
        title = t(
          "Upgrade now to Unlock Limitless Potential and Full Integration!"
        );
        subtitle = t(
          "enjoy unlimited projects, users, and custom classes. Access 500 data sources per project, including advanced integrations with cloud apps, eCommerce reviews, and more."
        );
        break;
      case ACCOUNT_PLAN.ENTERPRISE:
        title = t("Maximize the Full Potential of your Enterprise Plan");
        subtitle = t(
          "With Sensia Enterprise, enjoy unlimited projects, users, custom classes, and advanced integrations. Need help or want to optimize further? We're here for you!"
        );
        break;
    }

    return (
      <Fragment>
        {/* PLAN */}
        <Box
          sx={{
            display: "flex",
            padding: "25px",
            flexDirection: "column",
            alignItems: "center",
            gap: "30px",
            borderRadius: "9px",
            background: "#FFF",
            boxShadow: "0px 1px 9px 0px rgba(0, 0, 0, 0.08)",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "14px",
                alignSelf: "stretch",
              }}
            >
              <STypography
                sx={{
                  fontSize: "24px",
                  fontWeight: 700,
                  gap: "3px",
                }}
              >
                {`${planKey} plan`}
              </STypography>
              {/* <STypography
              sx={{
                color: "#737373",
                fontSize: "18px",
                fontWeight: 600,
                flex: "1 0 0",
              }}
            >
              {planKey !== ACCOUNT_PLAN.ENTERPRISE && "0$/mo"}
            </STypography> */}

              {planKey !== ACCOUNT_PLAN.ENTERPRISE && (
                <STypography
                  className="light-link-blue"
                  sx={{
                    color: " #306ed6",
                    fontSize: "15px",
                    fontWeight: 500,
                    flex: "1 0 0",
                    textAlign: "end",
                  }}
                  onClick={() => this.props.history.push(ROUTES.PLAN_CHOOSE)}
                >
                  {t("View all plan")}
                </STypography>
              )}
            </Box>
            {planKey != ACCOUNT_PLAN.FREE &&
              planKey != ACCOUNT_PLAN.ENTERPRISE && (
                <STypography
                  sx={{
                    flex: "1 0 0",
                    textAlign: "start",
                    color: "#7F7F7F",
                    fontWeight: 400,
                    mt: "10px",
                  }}
                >
                  {planText}
                </STypography>
              )}
          </Box>

          <Box
            sx={{
              display: "flex",
              padding: "15px",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "24px",
              alignSelf: "stretch",
              borderRadius: "6px",
              background:
                planKey == ACCOUNT_PLAN.ENTERPRISE ? "#E2E2FB" : "#FDE7D4",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                gap: "15px",
              }}
            >
              <STypography
                sx={{
                  fontWeight: 600,
                }}
              >
                {title}
              </STypography>
              <STypography
                sx={{
                  color:
                    planKey == ACCOUNT_PLAN.ENTERPRISE ? "#000" : "#7F7F7F",
                  fontWeight: 400,
                }}
              >
                {subtitle}
              </STypography>
              {planKey == ACCOUNT_PLAN.ENTERPRISE && (
                <Button
                  sx={{
                    mt: "5px",
                    borderRadius: "6px",
                    background: "#7879F1",
                    display: "flex",
                    width: "208px",
                    height: "30px",
                    "&:hover": {
                      background: "#6B6DF3",
                    },
                  }}
                  onClick={() =>
                    IntercomHelper.trackEvent("contact-success-team")
                  }
                >
                  <STypography
                    sx={{
                      color: "#FFF",
                      fontSize: "15px",
                      textTransform: "none",
                    }}
                  >
                    {t("Contact our success team")}
                  </STypography>
                </Button>
              )}
            </Box>
            {planKey != ACCOUNT_PLAN.ENTERPRISE && (
              <Button
                sx={{
                  borderRadius: "6px",
                  background:
                    "linear-gradient(90deg, #F9B16E 0%, #F68080 100%)",
                  display: "flex",
                  width: "150px",
                  height: "30px",
                }}
                onClick={() => this.props.history.push(ROUTES.PLAN_CHOOSE)}
              >
                <STypography
                  sx={{
                    color: "#FFF",
                    fontSize: "15px",
                    textTransform: "none",
                  }}
                >
                  {t("Upgrade")}
                </STypography>
              </Button>
            )}
          </Box>
        </Box>

        {/* REVIEWS */}
        <Box
          sx={{
            mt: "26px",
            display: "flex",
            padding: "25px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "25px",
            borderRadius: "9px",
            background: "#FFF",
            boxShadow: "0px 1px 9px 0px rgba(0, 0, 0, 0.08)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "50px",
                height: "50px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "6px",
                background: "#FDE7D4",
              }}
            >
              <ReviewsIcon sx={{ height: "27px", color: "#FF9378" }} />
            </Box>

            <STypography
              sx={{
                fontSize: "16px",
                fontWeight: 600,
              }}
            >
              {t("Reviews")}
            </STypography>
          </Box>

          <Box
            sx={{
              display: "flex",
              padding: "5px 0px",
              alignItems: "center",
              gap: "90px",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <STypography
              sx={{
                fontSize: "24px",
                fontWeight: 700,
              }}
            >
              {this.state.account?.total_obs?.toLocaleString()}
            </STypography>
            <LinearProgress
              sx={{
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#27C986",
                },
                borderRadius: 5,
                flex: "1 0 0",
                height: "10px",
              }}
              variant="determinate"
              value={100}
            />
          </Box>

          {/* <STypography
            sx={{
              fontWeight: 400,
            }}
          >
            {
              "Increase your storage capacity to analyze more reviews. More space means more precise insights. Don't miss any opportunities, expand your storage!"
            }
          </STypography> */}
        </Box>

        {/* IA */}
        <Box
          sx={{
            mt: "26px",
            display: "flex",
            padding: "25px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "20px",
            borderRadius: "9px",
            background: "#FFF",
            boxShadow: "0px 1px 9px 0px rgba(0, 0, 0, 0.08)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Box
                sx={{
                  display: "flex",
                  width: "50px",
                  height: "50px",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "6px",
                  background: "#FDE7D4",
                }}
              >
                <img alt="Reviews" height="25px" src={star_icon} />
              </Box>
              <STypography
                sx={{
                  fontSize: "16px",
                  fontWeight: 600,
                }}
              >
                {t("IA")}
              </STypography>
            </Box>
            <Box
              className="light-link-blue"
              onClick={() => IntercomHelper.trackEvent("add_IA")}
              sx={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              <STypography
                className="light-link-blue"
                sx={{ color: "#3A79E6", fontSize: "15px", fontWeight: 500 }}
              >
                {t("Add New IA")}
              </STypography>
            </Box>
          </Box>

          {account.product_categories.map((category) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  justifyContent: "space-between",
                }}
              >
                <img src={star_icon_blue} alt="categories" height="15px" />

                <STypography
                  sx={{
                    fontWeight: 700,
                    letterSpacing: "0.15px",
                  }}
                >
                  {category.title}
                </STypography>
              </Box>
            );
          })}
          <STypography sx={{ fontWeight: 400 }}>
            {t(
              "Add AI to enhance your analyses. More AI means more detailed and personalized insights. Amplify your marketing intelligence today!"
            )}
          </STypography>
        </Box>

        <div className="padding-top-50" />
      </Fragment>
    );
  }
}

export default withTranslation()(OverviewTab);
