import React from 'react';
import { withTranslation } from "react-i18next";
import { Redirect } from 'react-router';
import { ROUTES } from '../../app/routing';

class RedirectView extends React.Component {
    render() {
        const redirectTo  = (this.props.location.state && this.props.location.state.redirectTo) ? this.props.location.state.redirectTo : ROUTES.HOME;
        return (
            <Redirect to={redirectTo} />
        );
    }
}

export default withTranslation()(RedirectView);