import React from 'react';
import ReactDOM from 'react-dom';

import './modal.css';

class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            overlayClass: ''
        };
        this.onModalCloseClick = this.onModalCloseClick.bind(this);
    }
    componentDidMount() {
        document.documentElement.classList.add('no-scroll');
    }
    componentWillUnmount() {
        document.documentElement.classList.remove('no-scroll');
    }

    onModalCloseClick() {
        const { onClose } = this.props;
        if (typeof onClose == 'function') {
            this.setState({
                overlayClass: 'c-modal__closed'
            });
            setTimeout(() => {
                onClose();
            }, 350);
        }
    }

    render() {
        const { children, className, parentClassName, title } = this.props;
        return ReactDOM.createPortal(
            <div className={`c-modal c-modal__overlay ${parentClassName} ${this.state.overlayClass}`}>
                <div className="c-modal-content-scroll">
                    <div className="c-modal-content-table">
                        <div className="c-modal-content-table-cell">
                            <div className={`c-modal__modal ${className}`}>
                                <div className="c-modal__close" onClick={this.onModalCloseClick} />
                                <div className="c-modal__content">
                                    {title && <div className="c-modal__title">{title}</div>}
                                    {children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>,
            document.getElementById('modal-root')
        );
    }
}

export default Modal;