import React from 'react';
import '../form.css';
import Select from 'react-select';

export const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderColor: '#b4b4b4',
        minHeight: '40px',
        height: '40px',
        boxShadow: null,
    }),

    valueContainer: (provided, state) => ({
        ...provided,
        height: '40px',
        padding: '0 12px'
    }),

    input: (provided, state) => ({
        ...provided,
        margin: '0px',
        lineHeight: '34px'
    }),
    indicatorSeparator: state => ({
        display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '40px',
    }),
    menu: base => ({ ...base, zIndex: 5 }),
    menuList: styles => ({
        ...styles,
        maxHeight: '165px'
    }),
};


class SelectField extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
        this.renderErrorMessage = this.renderErrorMessage.bind(this);
    }

    renderErrorMessage(errorMessage) {
        if (errorMessage) {
            return (
                <div className="form-error font-work-sans-400 color-fd3e7b font-size-12 text-align-right">
                    {errorMessage}
                </div>
            );
        }
        return null;
    }

    render() {
        let formElementClassName = ["form-element", "select"];
        let defaultValue = null
        if (this.props.errorMessage) {
            formElementClassName.push('error');
        }
        if (this.props.formElementClassName) {
            formElementClassName.push(this.props.formElementClassName);
        }
        if (this.props.defaultValue) {
            defaultValue = this.props.defaultValue
        }
        return (
            <div className={formElementClassName.join(' ')}>
                {!this.props.hideLabel && (
                    <label className="font-inter-600 font-size-15 color-969696">
                        {this.props.label}
                    </label>
                )}
                { this.props.customlabel && <div className="customLabelBis" customlabel={this.props.customlabel}></div>}
                <Select
                    className={this.props.className}
                    placeholder={this.props.placeholder}
                    defaultValue={defaultValue}
                    isDisabled={this.props.disabled}
                    isClearable={this.props.isClearable}
                    isSearchable={this.props.isSearchable}
                    name={this.props.name}
                    options={this.props.options}
                    onChange={this.props.onChange}
                    styles={customStyles}
                />
                {this.renderErrorMessage(this.props.errorMessage)}
            </div>
        );
    }
}

SelectField.defaultProps = {
    isSearchable: true
}

export default SelectField;
