// Graph1.js
import React from "react";

import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import {
  Box,
  Divider,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  selectClasses,
} from "@mui/material";
import { globalConstants } from "../../../app/constants";

const typographyStyle = {
  width: "100%",
  color: "#212121",
  fontFamily: "Inter",
  fontSize: 15,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  whiteSpace: "normal",
};

const DataSourceSelect = ({
  isLoading,
  menuTitle = "Choose scope",
  datasources,
  selectedDatasource,
  onChange,
}) => {
  if (isLoading) {
    return (
      <div
        className="central-content skeleton-box"
        style={{ width: "100%", height: "56px" }}
      />
    );
  }

  return (
    <Box sx={{ width: "fit-content" }}>
      <Select
        id="product_source_list"
        variant="standard"
        placeholder="Select a data source"
        onChange={(e) => onChange(e.target.value)}
        displayEmpty
        disableUnderline
        blurOnSelect={true}
        indicator={<KeyboardArrowDown />}
        sx={{
          width: "100%",
          [`& .${selectClasses.indicator}`]: {
            transition: "0.2s",
            top: 0,
            [`&.${selectClasses.expanded}`]: {
              transform: "rotate(-180deg)",
            },
          },
          "& .MuiSelect-select:focus": {
            backgroundColor: "white",
          },
          "& .MuiSelect-icon": {
            top: 3,
          },
        }}
        slotProps={{
          listbox: {
            component: "div",
            sx: {
              maxHeight: 240,
              overflow: "auto",
              "--List-padding": "0px",
              "--ListItem-radius": "0px",
            },
          },
        }}
        value={selectedDatasource != null ? selectedDatasource : undefined}
        renderValue={(option) => {
          const selected_datasource = datasources.find((d) => d.id === option);

          let title = "All data sources";
          let icon = null;
          if (selected_datasource) {
            title = selected_datasource.title;
            icon = selected_datasource.datasource_type[0].icon;
          }

          return (
            <Box
              sx={{
                display: "flex",
              }}
            >
              {icon && (
                <img
                  style={{ width: "20px", height: "20px", marginRight: "10px" }}
                  alt={title}
                  src={`${globalConstants.API_URL}/${icon}`}
                />
              )}
              <Typography sx={typographyStyle}>{title}</Typography>
            </Box>
          );
        }}
      >
        <Typography
          sx={Object.assign({}, typographyStyle, {
            ml: 2,
            mb: "8px",
            fontWeight: "bold",
          })}
        >
          {menuTitle}
        </Typography>
        <Divider sx={{ mb: "1px" }} />
        <MenuItem value={null} label="All data sources">
          <Typography sx={typographyStyle}>All data sources</Typography>
        </MenuItem>
        {datasources.map((data, index) => (
          <MenuItem key={index} value={data.id} label={data.title}>
            <Typography sx={typographyStyle}>{data.title}</Typography>
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default DataSourceSelect;
