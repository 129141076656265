import { userConstants } from '../constants';

let localStorageUser = localStorage.getItem("user");
let user =
  localStorageUser && localStorageUser != "undefined"
    ? JSON.parse(localStorage.getItem("user"))
    : null;
const initialState = user ? { logged: true, user } : {};

export function authentication(state = initialState, action = {}) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {};
    case userConstants.LOGIN_SUCCESS:
      return {
        logged: true,
        user: action.user
      };
    case userConstants.LOGIN_FAILURE:
      return {};
    case userConstants.LOGOUT:
      return {};
    default:
      return state
  }
}