export const COUNTRY_OPTION = [
    {
        "label": "Afghanistan",
        "value": "AF",
        "target": {
            "name": "Afghanistan",
            "value": "AF"
        }
    },
    {
        "label": "Åland Islands",
        "value": "AX",
        "target": {
            "name": "Åland Islands",
            "value": "AX"
        }
    },
    {
        "label": "Albania",
        "value": "AL",
        "target": {
            "name": "Albania",
            "value": "AL"
        }
    },
    {
        "label": "Algeria",
        "value": "DZ",
        "target": {
            "name": "Algeria",
            "value": "DZ"
        }
    },
    {
        "label": "American Samoa",
        "value": "AS",
        "target": {
            "name": "American Samoa",
            "value": "AS"
        }
    },
    {
        "label": "Andorra",
        "value": "AD",
        "target": {
            "name": "Andorra",
            "value": "AD"
        }
    },
    {
        "label": "Angola",
        "value": "AO",
        "target": {
            "name": "Angola",
            "value": "AO"
        }
    },
    {
        "label": "Anguilla",
        "value": "AI",
        "target": {
            "name": "Anguilla",
            "value": "AI"
        }
    },
    {
        "label": "Antarctica",
        "value": "AQ",
        "target": {
            "name": "Antarctica",
            "value": "AQ"
        }
    },
    {
        "label": "Antigua and Barbuda",
        "value": "AG",
        "target": {
            "name": "Antigua and Barbuda",
            "value": "AG"
        }
    },
    {
        "label": "Argentina",
        "value": "AR",
        "target": {
            "name": "Argentina",
            "value": "AR"
        }
    },
    {
        "label": "Armenia",
        "value": "AM",
        "target": {
            "name": "Armenia",
            "value": "AM"
        }
    },
    {
        "label": "Aruba",
        "value": "AW",
        "target": {
            "name": "Aruba",
            "value": "AW"
        }
    },
    {
        "label": "Australia",
        "value": "AU",
        "target": {
            "name": "Australia",
            "value": "AU"
        }
    },
    {
        "label": "Austria",
        "value": "AT",
        "target": {
            "name": "Austria",
            "value": "AT"
        }
    },
    {
        "label": "Azerbaijan",
        "value": "AZ",
        "target": {
            "name": "Azerbaijan",
            "value": "AZ"
        }
    },
    {
        "label": "Bahamas",
        "value": "BS",
        "target": {
            "name": "Bahamas",
            "value": "BS"
        }
    },
    {
        "label": "Bahrain",
        "value": "BH",
        "target": {
            "name": "Bahrain",
            "value": "BH"
        }
    },
    {
        "label": "Bangladesh",
        "value": "BD",
        "target": {
            "name": "Bangladesh",
            "value": "BD"
        }
    },
    {
        "label": "Barbados",
        "value": "BB",
        "target": {
            "name": "Barbados",
            "value": "BB"
        }
    },
    {
        "label": "Belarus",
        "value": "BY",
        "target": {
            "name": "Belarus",
            "value": "BY"
        }
    },
    {
        "label": "Belgium",
        "value": "BE",
        "target": {
            "name": "Belgium",
            "value": "BE"
        }
    },
    {
        "label": "Belize",
        "value": "BZ",
        "target": {
            "name": "Belize",
            "value": "BZ"
        }
    },
    {
        "label": "Benin",
        "value": "BJ",
        "target": {
            "name": "Benin",
            "value": "BJ"
        }
    },
    {
        "label": "Bermuda",
        "value": "BM",
        "target": {
            "name": "Bermuda",
            "value": "BM"
        }
    },
    {
        "label": "Bhutan",
        "value": "BT",
        "target": {
            "name": "Bhutan",
            "value": "BT"
        }
    },
    {
        "label": "Bolivia, Plurinational State of",
        "value": "BO",
        "target": {
            "name": "Bolivia, Plurinational State of",
            "value": "BO"
        }
    },
    {
        "label": "Bosnia and Herzegovina",
        "value": "BA",
        "target": {
            "name": "Bosnia and Herzegovina",
            "value": "BA"
        }
    },
    {
        "label": "Botswana",
        "value": "BW",
        "target": {
            "name": "Botswana",
            "value": "BW"
        }
    },
    {
        "label": "Bouvet Island",
        "value": "BV",
        "target": {
            "name": "Bouvet Island",
            "value": "BV"
        }
    },
    {
        "label": "Brazil",
        "value": "BR",
        "target": {
            "name": "Brazil",
            "value": "BR"
        }
    },
    {
        "label": "British Indian Ocean Territory",
        "value": "IO",
        "target": {
            "name": "British Indian Ocean Territory",
            "value": "IO"
        }
    },
    {
        "label": "Brunei Darussalam",
        "value": "BN",
        "target": {
            "name": "Brunei Darussalam",
            "value": "BN"
        }
    },
    {
        "label": "Bulgaria",
        "value": "BG",
        "target": {
            "name": "Bulgaria",
            "value": "BG"
        }
    },
    {
        "label": "Burkina Faso",
        "value": "BF",
        "target": {
            "name": "Burkina Faso",
            "value": "BF"
        }
    },
    {
        "label": "Burundi",
        "value": "BI",
        "target": {
            "name": "Burundi",
            "value": "BI"
        }
    },
    {
        "label": "Cambodia",
        "value": "KH",
        "target": {
            "name": "Cambodia",
            "value": "KH"
        }
    },
    {
        "label": "Cameroon",
        "value": "CM",
        "target": {
            "name": "Cameroon",
            "value": "CM"
        }
    },
    {
        "label": "Canada",
        "value": "CA",
        "target": {
            "name": "Canada",
            "value": "CA"
        }
    },
    {
        "label": "Cape Verde",
        "value": "CV",
        "target": {
            "name": "Cape Verde",
            "value": "CV"
        }
    },
    {
        "label": "Cayman Islands",
        "value": "KY",
        "target": {
            "name": "Cayman Islands",
            "value": "KY"
        }
    },
    {
        "label": "Central African Republic",
        "value": "CF",
        "target": {
            "name": "Central African Republic",
            "value": "CF"
        }
    },
    {
        "label": "Chad",
        "value": "TD",
        "target": {
            "name": "Chad",
            "value": "TD"
        }
    },
    {
        "label": "Chile",
        "value": "CL",
        "target": {
            "name": "Chile",
            "value": "CL"
        }
    },
    {
        "label": "China",
        "value": "CN",
        "target": {
            "name": "China",
            "value": "CN"
        }
    },
    {
        "label": "Christmas Island",
        "value": "CX",
        "target": {
            "name": "Christmas Island",
            "value": "CX"
        }
    },
    {
        "label": "Cocos (Keeling) Islands",
        "value": "CC",
        "target": {
            "name": "Cocos (Keeling) Islands",
            "value": "CC"
        }
    },
    {
        "label": "Colombia",
        "value": "CO",
        "target": {
            "name": "Colombia",
            "value": "CO"
        }
    },
    {
        "label": "Comoros",
        "value": "KM",
        "target": {
            "name": "Comoros",
            "value": "KM"
        }
    },
    {
        "label": "Congo",
        "value": "CG",
        "target": {
            "name": "Congo",
            "value": "CG"
        }
    },
    {
        "label": "Congo, the Democratic Republic of the",
        "value": "CD",
        "target": {
            "name": "Congo, the Democratic Republic of the",
            "value": "CD"
        }
    },
    {
        "label": "Cook Islands",
        "value": "CK",
        "target": {
            "name": "Cook Islands",
            "value": "CK"
        }
    },
    {
        "label": "Costa Rica",
        "value": "CR",
        "target": {
            "name": "Costa Rica",
            "value": "CR"
        }
    },
    {
        "label": "Côte d'Ivoire",
        "value": "CI",
        "target": {
            "name": "Côte d'Ivoire",
            "value": "CI"
        }
    },
    {
        "label": "Croatia",
        "value": "HR",
        "target": {
            "name": "Croatia",
            "value": "HR"
        }
    },
    {
        "label": "Cuba",
        "value": "CU",
        "target": {
            "name": "Cuba",
            "value": "CU"
        }
    },
    {
        "label": "Cyprus",
        "value": "CY",
        "target": {
            "name": "Cyprus",
            "value": "CY"
        }
    },
    {
        "label": "Czech Republic",
        "value": "CZ",
        "target": {
            "name": "Czech Republic",
            "value": "CZ"
        }
    },
    {
        "label": "Denmark",
        "value": "DK",
        "target": {
            "name": "Denmark",
            "value": "DK"
        }
    },
    {
        "label": "Djibouti",
        "value": "DJ",
        "target": {
            "name": "Djibouti",
            "value": "DJ"
        }
    },
    {
        "label": "Dominica",
        "value": "DM",
        "target": {
            "name": "Dominica",
            "value": "DM"
        }
    },
    {
        "label": "Dominican Republic",
        "value": "DO",
        "target": {
            "name": "Dominican Republic",
            "value": "DO"
        }
    },
    {
        "label": "Ecuador",
        "value": "EC",
        "target": {
            "name": "Ecuador",
            "value": "EC"
        }
    },
    {
        "label": "Egypt",
        "value": "EG",
        "target": {
            "name": "Egypt",
            "value": "EG"
        }
    },
    {
        "label": "El Salvador",
        "value": "SV",
        "target": {
            "name": "El Salvador",
            "value": "SV"
        }
    },
    {
        "label": "Equatorial Guinea",
        "value": "GQ",
        "target": {
            "name": "Equatorial Guinea",
            "value": "GQ"
        }
    },
    {
        "label": "Eritrea",
        "value": "ER",
        "target": {
            "name": "Eritrea",
            "value": "ER"
        }
    },
    {
        "label": "Estonia",
        "value": "EE",
        "target": {
            "name": "Estonia",
            "value": "EE"
        }
    },
    {
        "label": "Ethiopia",
        "value": "ET",
        "target": {
            "name": "Ethiopia",
            "value": "ET"
        }
    },
    {
        "label": "Falkland Islands (Malvinas)",
        "value": "FK",
        "target": {
            "name": "Falkland Islands (Malvinas)",
            "value": "FK"
        }
    },
    {
        "label": "Faroe Islands",
        "value": "FO",
        "target": {
            "name": "Faroe Islands",
            "value": "FO"
        }
    },
    {
        "label": "Fiji",
        "value": "FJ",
        "target": {
            "name": "Fiji",
            "value": "FJ"
        }
    },
    {
        "label": "Finland",
        "value": "FI",
        "target": {
            "name": "Finland",
            "value": "FI"
        }
    },
    {
        "label": "France",
        "value": "FR",
        "target": {
            "name": "France",
            "value": "FR"
        }
    },
    {
        "label": "French Guiana",
        "value": "GF",
        "target": {
            "name": "French Guiana",
            "value": "GF"
        }
    },
    {
        "label": "French Polynesia",
        "value": "PF",
        "target": {
            "name": "French Polynesia",
            "value": "PF"
        }
    },
    {
        "label": "French Southern Territories",
        "value": "TF",
        "target": {
            "name": "French Southern Territories",
            "value": "TF"
        }
    },
    {
        "label": "Gabon",
        "value": "GA",
        "target": {
            "name": "Gabon",
            "value": "GA"
        }
    },
    {
        "label": "Gambia",
        "value": "GM",
        "target": {
            "name": "Gambia",
            "value": "GM"
        }
    },
    {
        "label": "Georgia",
        "value": "GE",
        "target": {
            "name": "Georgia",
            "value": "GE"
        }
    },
    {
        "label": "Germany",
        "value": "DE",
        "target": {
            "name": "Germany",
            "value": "DE"
        }
    },
    {
        "label": "Ghana",
        "value": "GH",
        "target": {
            "name": "Ghana",
            "value": "GH"
        }
    },
    {
        "label": "Gibraltar",
        "value": "GI",
        "target": {
            "name": "Gibraltar",
            "value": "GI"
        }
    },
    {
        "label": "Greece",
        "value": "GR",
        "target": {
            "name": "Greece",
            "value": "GR"
        }
    },
    {
        "label": "Greenland",
        "value": "GL",
        "target": {
            "name": "Greenland",
            "value": "GL"
        }
    },
    {
        "label": "Grenada",
        "value": "GD",
        "target": {
            "name": "Grenada",
            "value": "GD"
        }
    },
    {
        "label": "Guadeloupe",
        "value": "GP",
        "target": {
            "name": "Guadeloupe",
            "value": "GP"
        }
    },
    {
        "label": "Guam",
        "value": "GU",
        "target": {
            "name": "Guam",
            "value": "GU"
        }
    },
    {
        "label": "Guatemala",
        "value": "GT",
        "target": {
            "name": "Guatemala",
            "value": "GT"
        }
    },
    {
        "label": "Guernsey",
        "value": "GG",
        "target": {
            "name": "Guernsey",
            "value": "GG"
        }
    },
    {
        "label": "Guinea",
        "value": "GN",
        "target": {
            "name": "Guinea",
            "value": "GN"
        }
    },
    {
        "label": "Guinea-Bissau",
        "value": "GW",
        "target": {
            "name": "Guinea-Bissau",
            "value": "GW"
        }
    },
    {
        "label": "Guyana",
        "value": "GY",
        "target": {
            "name": "Guyana",
            "value": "GY"
        }
    },
    {
        "label": "Haiti",
        "value": "HT",
        "target": {
            "name": "Haiti",
            "value": "HT"
        }
    },
    {
        "label": "Heard Island and McDonald Islands",
        "value": "HM",
        "target": {
            "name": "Heard Island and McDonald Islands",
            "value": "HM"
        }
    },
    {
        "label": "Holy See (Vatican City State)",
        "value": "VA",
        "target": {
            "name": "Holy See (Vatican City State)",
            "value": "VA"
        }
    },
    {
        "label": "Honduras",
        "value": "HN",
        "target": {
            "name": "Honduras",
            "value": "HN"
        }
    },
    {
        "label": "Hong Kong",
        "value": "HK",
        "target": {
            "name": "Hong Kong",
            "value": "HK"
        }
    },
    {
        "label": "Hungary",
        "value": "HU",
        "target": {
            "name": "Hungary",
            "value": "HU"
        }
    },
    {
        "label": "Iceland",
        "value": "IS",
        "target": {
            "name": "Iceland",
            "value": "IS"
        }
    },
    {
        "label": "India",
        "value": "IN",
        "target": {
            "name": "India",
            "value": "IN"
        }
    },
    {
        "label": "Indonesia",
        "value": "ID",
        "target": {
            "name": "Indonesia",
            "value": "ID"
        }
    },
    {
        "label": "Iran, Islamic Republic of",
        "value": "IR",
        "target": {
            "name": "Iran, Islamic Republic of",
            "value": "IR"
        }
    },
    {
        "label": "Iraq",
        "value": "IQ",
        "target": {
            "name": "Iraq",
            "value": "IQ"
        }
    },
    {
        "label": "Ireland",
        "value": "IE",
        "target": {
            "name": "Ireland",
            "value": "IE"
        }
    },
    {
        "label": "Isle of Man",
        "value": "IM",
        "target": {
            "name": "Isle of Man",
            "value": "IM"
        }
    },
    {
        "label": "Israel",
        "value": "IL",
        "target": {
            "name": "Israel",
            "value": "IL"
        }
    },
    {
        "label": "Italy",
        "value": "IT",
        "target": {
            "name": "Italy",
            "value": "IT"
        }
    },
    {
        "label": "Jamaica",
        "value": "JM",
        "target": {
            "name": "Jamaica",
            "value": "JM"
        }
    },
    {
        "label": "Japan",
        "value": "JP",
        "target": {
            "name": "Japan",
            "value": "JP"
        }
    },
    {
        "label": "Jersey",
        "value": "JE",
        "target": {
            "name": "Jersey",
            "value": "JE"
        }
    },
    {
        "label": "Jordan",
        "value": "JO",
        "target": {
            "name": "Jordan",
            "value": "JO"
        }
    },
    {
        "label": "Kazakhstan",
        "value": "KZ",
        "target": {
            "name": "Kazakhstan",
            "value": "KZ"
        }
    },
    {
        "label": "Kenya",
        "value": "KE",
        "target": {
            "name": "Kenya",
            "value": "KE"
        }
    },
    {
        "label": "Kiribati",
        "value": "KI",
        "target": {
            "name": "Kiribati",
            "value": "KI"
        }
    },
    {
        "label": "Korea, Democratic People's Republic of",
        "value": "KP",
        "target": {
            "name": "Korea, Democratic People's Republic of",
            "value": "KP"
        }
    },
    {
        "label": "Korea, Republic of",
        "value": "KR",
        "target": {
            "name": "Korea, Republic of",
            "value": "KR"
        }
    },
    {
        "label": "Kuwait",
        "value": "KW",
        "target": {
            "name": "Kuwait",
            "value": "KW"
        }
    },
    {
        "label": "Kyrgyzstan",
        "value": "KG",
        "target": {
            "name": "Kyrgyzstan",
            "value": "KG"
        }
    },
    {
        "label": "Lao People's Democratic Republic",
        "value": "LA",
        "target": {
            "name": "Lao People's Democratic Republic",
            "value": "LA"
        }
    },
    {
        "label": "Latvia",
        "value": "LV",
        "target": {
            "name": "Latvia",
            "value": "LV"
        }
    },
    {
        "label": "Lebanon",
        "value": "LB",
        "target": {
            "name": "Lebanon",
            "value": "LB"
        }
    },
    {
        "label": "Lesotho",
        "value": "LS",
        "target": {
            "name": "Lesotho",
            "value": "LS"
        }
    },
    {
        "label": "Liberia",
        "value": "LR",
        "target": {
            "name": "Liberia",
            "value": "LR"
        }
    },
    {
        "label": "Libyan Arab Jamahiriya",
        "value": "LY",
        "target": {
            "name": "Libyan Arab Jamahiriya",
            "value": "LY"
        }
    },
    {
        "label": "Liechtenstein",
        "value": "LI",
        "target": {
            "name": "Liechtenstein",
            "value": "LI"
        }
    },
    {
        "label": "Lithuania",
        "value": "LT",
        "target": {
            "name": "Lithuania",
            "value": "LT"
        }
    },
    {
        "label": "Luxembourg",
        "value": "LU",
        "target": {
            "name": "Luxembourg",
            "value": "LU"
        }
    },
    {
        "label": "Macao",
        "value": "MO",
        "target": {
            "name": "Macao",
            "value": "MO"
        }
    },
    {
        "label": "Macedonia, the former Yugoslav Republic of",
        "value": "MK",
        "target": {
            "name": "Macedonia, the former Yugoslav Republic of",
            "value": "MK"
        }
    },
    {
        "label": "Madagascar",
        "value": "MG",
        "target": {
            "name": "Madagascar",
            "value": "MG"
        }
    },
    {
        "label": "Malawi",
        "value": "MW",
        "target": {
            "name": "Malawi",
            "value": "MW"
        }
    },
    {
        "label": "Malaysia",
        "value": "MY",
        "target": {
            "name": "Malaysia",
            "value": "MY"
        }
    },
    {
        "label": "Maldives",
        "value": "MV",
        "target": {
            "name": "Maldives",
            "value": "MV"
        }
    },
    {
        "label": "Mali",
        "value": "ML",
        "target": {
            "name": "Mali",
            "value": "ML"
        }
    },
    {
        "label": "Malta",
        "value": "MT",
        "target": {
            "name": "Malta",
            "value": "MT"
        }
    },
    {
        "label": "Marshall Islands",
        "value": "MH",
        "target": {
            "name": "Marshall Islands",
            "value": "MH"
        }
    },
    {
        "label": "Martinique",
        "value": "MQ",
        "target": {
            "name": "Martinique",
            "value": "MQ"
        }
    },
    {
        "label": "Mauritania",
        "value": "MR",
        "target": {
            "name": "Mauritania",
            "value": "MR"
        }
    },
    {
        "label": "Mauritius",
        "value": "MU",
        "target": {
            "name": "Mauritius",
            "value": "MU"
        }
    },
    {
        "label": "Mayotte",
        "value": "YT",
        "target": {
            "name": "Mayotte",
            "value": "YT"
        }
    },
    {
        "label": "Mexico",
        "value": "MX",
        "target": {
            "name": "Mexico",
            "value": "MX"
        }
    },
    {
        "label": "Micronesia, Federated States of",
        "value": "FM",
        "target": {
            "name": "Micronesia, Federated States of",
            "value": "FM"
        }
    },
    {
        "label": "Moldova, Republic of",
        "value": "MD",
        "target": {
            "name": "Moldova, Republic of",
            "value": "MD"
        }
    },
    {
        "label": "Monaco",
        "value": "MC",
        "target": {
            "name": "Monaco",
            "value": "MC"
        }
    },
    {
        "label": "Mongolia",
        "value": "MN",
        "target": {
            "name": "Mongolia",
            "value": "MN"
        }
    },
    {
        "label": "Montenegro",
        "value": "ME",
        "target": {
            "name": "Montenegro",
            "value": "ME"
        }
    },
    {
        "label": "Montserrat",
        "value": "MS",
        "target": {
            "name": "Montserrat",
            "value": "MS"
        }
    },
    {
        "label": "Morocco",
        "value": "MA",
        "target": {
            "name": "Morocco",
            "value": "MA"
        }
    },
    {
        "label": "Mozambique",
        "value": "MZ",
        "target": {
            "name": "Mozambique",
            "value": "MZ"
        }
    },
    {
        "label": "Myanmar",
        "value": "MM",
        "target": {
            "name": "Myanmar",
            "value": "MM"
        }
    },
    {
        "label": "Namibia",
        "value": "NA",
        "target": {
            "name": "Namibia",
            "value": "NA"
        }
    },
    {
        "label": "Nauru",
        "value": "NR",
        "target": {
            "name": "Nauru",
            "value": "NR"
        }
    },
    {
        "label": "Nepal",
        "value": "NP",
        "target": {
            "name": "Nepal",
            "value": "NP"
        }
    },
    {
        "label": "Netherlands",
        "value": "NL",
        "target": {
            "name": "Netherlands",
            "value": "NL"
        }
    },
    {
        "label": "Netherlands Antilles",
        "value": "AN",
        "target": {
            "name": "Netherlands Antilles",
            "value": "AN"
        }
    },
    {
        "label": "New Caledonia",
        "value": "NC",
        "target": {
            "name": "New Caledonia",
            "value": "NC"
        }
    },
    {
        "label": "New Zealand",
        "value": "NZ",
        "target": {
            "name": "New Zealand",
            "value": "NZ"
        }
    },
    {
        "label": "Nicaragua",
        "value": "NI",
        "target": {
            "name": "Nicaragua",
            "value": "NI"
        }
    },
    {
        "label": "Niger",
        "value": "NE",
        "target": {
            "name": "Niger",
            "value": "NE"
        }
    },
    {
        "label": "Nigeria",
        "value": "NG",
        "target": {
            "name": "Nigeria",
            "value": "NG"
        }
    },
    {
        "label": "Niue",
        "value": "NU",
        "target": {
            "name": "Niue",
            "value": "NU"
        }
    },
    {
        "label": "Norfolk Island",
        "value": "NF",
        "target": {
            "name": "Norfolk Island",
            "value": "NF"
        }
    },
    {
        "label": "Northern Mariana Islands",
        "value": "MP",
        "target": {
            "name": "Northern Mariana Islands",
            "value": "MP"
        }
    },
    {
        "label": "Norway",
        "value": "NO",
        "target": {
            "name": "Norway",
            "value": "NO"
        }
    },
    {
        "label": "Oman",
        "value": "OM",
        "target": {
            "name": "Oman",
            "value": "OM"
        }
    },
    {
        "label": "Pakistan",
        "value": "PK",
        "target": {
            "name": "Pakistan",
            "value": "PK"
        }
    },
    {
        "label": "Palau",
        "value": "PW",
        "target": {
            "name": "Palau",
            "value": "PW"
        }
    },
    {
        "label": "Palestinian Territory, Occupied",
        "value": "PS",
        "target": {
            "name": "Palestinian Territory, Occupied",
            "value": "PS"
        }
    },
    {
        "label": "Panama",
        "value": "PA",
        "target": {
            "name": "Panama",
            "value": "PA"
        }
    },
    {
        "label": "Papua New Guinea",
        "value": "PG",
        "target": {
            "name": "Papua New Guinea",
            "value": "PG"
        }
    },
    {
        "label": "Paraguay",
        "value": "PY",
        "target": {
            "name": "Paraguay",
            "value": "PY"
        }
    },
    {
        "label": "Peru",
        "value": "PE",
        "target": {
            "name": "Peru",
            "value": "PE"
        }
    },
    {
        "label": "Philippines",
        "value": "PH",
        "target": {
            "name": "Philippines",
            "value": "PH"
        }
    },
    {
        "label": "Pitcairn",
        "value": "PN",
        "target": {
            "name": "Pitcairn",
            "value": "PN"
        }
    },
    {
        "label": "Poland",
        "value": "PL",
        "target": {
            "name": "Poland",
            "value": "PL"
        }
    },
    {
        "label": "Portugal",
        "value": "PT",
        "target": {
            "name": "Portugal",
            "value": "PT"
        }
    },
    {
        "label": "Puerto Rico",
        "value": "PR",
        "target": {
            "name": "Puerto Rico",
            "value": "PR"
        }
    },
    {
        "label": "Qatar",
        "value": "QA",
        "target": {
            "name": "Qatar",
            "value": "QA"
        }
    },
    {
        "label": "Réunion",
        "value": "RE",
        "target": {
            "name": "Réunion",
            "value": "RE"
        }
    },
    {
        "label": "Romania",
        "value": "RO",
        "target": {
            "name": "Romania",
            "value": "RO"
        }
    },
    {
        "label": "Russian Federation",
        "value": "RU",
        "target": {
            "name": "Russian Federation",
            "value": "RU"
        }
    },
    {
        "label": "Rwanda",
        "value": "RW",
        "target": {
            "name": "Rwanda",
            "value": "RW"
        }
    },
    {
        "label": "Saint Barthélemy",
        "value": "BL",
        "target": {
            "name": "Saint Barthélemy",
            "value": "BL"
        }
    },
    {
        "label": "Saint Helena, Ascension and Tristan da Cunha",
        "value": "SH",
        "target": {
            "name": "Saint Helena, Ascension and Tristan da Cunha",
            "value": "SH"
        }
    },
    {
        "label": "Saint Kitts and Nevis",
        "value": "KN",
        "target": {
            "name": "Saint Kitts and Nevis",
            "value": "KN"
        }
    },
    {
        "label": "Saint Lucia",
        "value": "LC",
        "target": {
            "name": "Saint Lucia",
            "value": "LC"
        }
    },
    {
        "label": "Saint Martin (French part)",
        "value": "MF",
        "target": {
            "name": "Saint Martin (French part)",
            "value": "MF"
        }
    },
    {
        "label": "Saint Pierre and Miquelon",
        "value": "PM",
        "target": {
            "name": "Saint Pierre and Miquelon",
            "value": "PM"
        }
    },
    {
        "label": "Saint Vincent and the Grenadines",
        "value": "VC",
        "target": {
            "name": "Saint Vincent and the Grenadines",
            "value": "VC"
        }
    },
    {
        "label": "Samoa",
        "value": "WS",
        "target": {
            "name": "Samoa",
            "value": "WS"
        }
    },
    {
        "label": "San Marino",
        "value": "SM",
        "target": {
            "name": "San Marino",
            "value": "SM"
        }
    },
    {
        "label": "Sao Tome and Principe",
        "value": "ST",
        "target": {
            "name": "Sao Tome and Principe",
            "value": "ST"
        }
    },
    {
        "label": "Saudi Arabia",
        "value": "SA",
        "target": {
            "name": "Saudi Arabia",
            "value": "SA"
        }
    },
    {
        "label": "Senegal",
        "value": "SN",
        "target": {
            "name": "Senegal",
            "value": "SN"
        }
    },
    {
        "label": "Serbia",
        "value": "RS",
        "target": {
            "name": "Serbia",
            "value": "RS"
        }
    },
    {
        "label": "Seychelles",
        "value": "SC",
        "target": {
            "name": "Seychelles",
            "value": "SC"
        }
    },
    {
        "label": "Sierra Leone",
        "value": "SL",
        "target": {
            "name": "Sierra Leone",
            "value": "SL"
        }
    },
    {
        "label": "Singapore",
        "value": "SG",
        "target": {
            "name": "Singapore",
            "value": "SG"
        }
    },
    {
        "label": "Slovakia",
        "value": "SK",
        "target": {
            "name": "Slovakia",
            "value": "SK"
        }
    },
    {
        "label": "Slovenia",
        "value": "SI",
        "target": {
            "name": "Slovenia",
            "value": "SI"
        }
    },
    {
        "label": "Solomon Islands",
        "value": "SB",
        "target": {
            "name": "Solomon Islands",
            "value": "SB"
        }
    },
    {
        "label": "Somalia",
        "value": "SO",
        "target": {
            "name": "Somalia",
            "value": "SO"
        }
    },
    {
        "label": "South Africa",
        "value": "ZA",
        "target": {
            "name": "South Africa",
            "value": "ZA"
        }
    },
    {
        "label": "South Georgia and the South Sandwich Islands",
        "value": "GS",
        "target": {
            "name": "South Georgia and the South Sandwich Islands",
            "value": "GS"
        }
    },
    {
        "label": "Spain",
        "value": "ES",
        "target": {
            "name": "Spain",
            "value": "ES"
        }
    },
    {
        "label": "Sri Lanka",
        "value": "LK",
        "target": {
            "name": "Sri Lanka",
            "value": "LK"
        }
    },
    {
        "label": "Sudan",
        "value": "SD",
        "target": {
            "name": "Sudan",
            "value": "SD"
        }
    },
    {
        "label": "Suriname",
        "value": "SR",
        "target": {
            "name": "Suriname",
            "value": "SR"
        }
    },
    {
        "label": "Svalbard and Jan Mayen",
        "value": "SJ",
        "target": {
            "name": "Svalbard and Jan Mayen",
            "value": "SJ"
        }
    },
    {
        "label": "Swaziland",
        "value": "SZ",
        "target": {
            "name": "Swaziland",
            "value": "SZ"
        }
    },
    {
        "label": "Sweden",
        "value": "SE",
        "target": {
            "name": "Sweden",
            "value": "SE"
        }
    },
    {
        "label": "Switzerland",
        "value": "CH",
        "target": {
            "name": "Switzerland",
            "value": "CH"
        }
    },
    {
        "label": "Syrian Arab Republic",
        "value": "SY",
        "target": {
            "name": "Syrian Arab Republic",
            "value": "SY"
        }
    },
    {
        "label": "Taiwan, Province of China",
        "value": "TW",
        "target": {
            "name": "Taiwan, Province of China",
            "value": "TW"
        }
    },
    {
        "label": "Tajikistan",
        "value": "TJ",
        "target": {
            "name": "Tajikistan",
            "value": "TJ"
        }
    },
    {
        "label": "Tanzania, United Republic of",
        "value": "TZ",
        "target": {
            "name": "Tanzania, United Republic of",
            "value": "TZ"
        }
    },
    {
        "label": "Thailand",
        "value": "TH",
        "target": {
            "name": "Thailand",
            "value": "TH"
        }
    },
    {
        "label": "Timor-Leste",
        "value": "TL",
        "target": {
            "name": "Timor-Leste",
            "value": "TL"
        }
    },
    {
        "label": "Togo",
        "value": "TG",
        "target": {
            "name": "Togo",
            "value": "TG"
        }
    },
    {
        "label": "Tokelau",
        "value": "TK",
        "target": {
            "name": "Tokelau",
            "value": "TK"
        }
    },
    {
        "label": "Tonga",
        "value": "TO",
        "target": {
            "name": "Tonga",
            "value": "TO"
        }
    },
    {
        "label": "Trinidad and Tobago",
        "value": "TT",
        "target": {
            "name": "Trinidad and Tobago",
            "value": "TT"
        }
    },
    {
        "label": "Tunisia",
        "value": "TN",
        "target": {
            "name": "Tunisia",
            "value": "TN"
        }
    },
    {
        "label": "Turkey",
        "value": "TR",
        "target": {
            "name": "Turkey",
            "value": "TR"
        }
    },
    {
        "label": "Turkmenistan",
        "value": "TM",
        "target": {
            "name": "Turkmenistan",
            "value": "TM"
        }
    },
    {
        "label": "Turks and Caicos Islands",
        "value": "TC",
        "target": {
            "name": "Turks and Caicos Islands",
            "value": "TC"
        }
    },
    {
        "label": "Tuvalu",
        "value": "TV",
        "target": {
            "name": "Tuvalu",
            "value": "TV"
        }
    },
    {
        "label": "Uganda",
        "value": "UG",
        "target": {
            "name": "Uganda",
            "value": "UG"
        }
    },
    {
        "label": "Ukraine",
        "value": "UA",
        "target": {
            "name": "Ukraine",
            "value": "UA"
        }
    },
    {
        "label": "United Arab Emirates",
        "value": "AE",
        "target": {
            "name": "United Arab Emirates",
            "value": "AE"
        }
    },
    {
        "label": "United Kingdom",
        "value": "GB",
        "target": {
            "name": "United Kingdom",
            "value": "GB"
        }
    },
    {
        "label": "United States",
        "value": "US",
        "target": {
            "name": "United States",
            "value": "US"
        }
    },
    {
        "label": "United States Minor Outlying Islands",
        "value": "UM",
        "target": {
            "name": "United States Minor Outlying Islands",
            "value": "UM"
        }
    },
    {
        "label": "Uruguay",
        "value": "UY",
        "target": {
            "name": "Uruguay",
            "value": "UY"
        }
    },
    {
        "label": "Uzbekistan",
        "value": "UZ",
        "target": {
            "name": "Uzbekistan",
            "value": "UZ"
        }
    },
    {
        "label": "Vanuatu",
        "value": "VU",
        "target": {
            "name": "Vanuatu",
            "value": "VU"
        }
    },
    {
        "label": "Venezuela, Bolivarian Republic of",
        "value": "VE",
        "target": {
            "name": "Venezuela, Bolivarian Republic of",
            "value": "VE"
        }
    },
    {
        "label": "Viet Nam",
        "value": "VN",
        "target": {
            "name": "Viet Nam",
            "value": "VN"
        }
    },
    {
        "label": "Virgin Islands, British",
        "value": "VG",
        "target": {
            "name": "Virgin Islands, British",
            "value": "VG"
        }
    },
    {
        "label": "Virgin Islands, U.S.",
        "value": "VI",
        "target": {
            "name": "Virgin Islands, U.S.",
            "value": "VI"
        }
    },
    {
        "label": "Wallis and Futuna",
        "value": "WF",
        "target": {
            "name": "Wallis and Futuna",
            "value": "WF"
        }
    },
    {
        "label": "Western Sahara",
        "value": "EH",
        "target": {
            "name": "Western Sahara",
            "value": "EH"
        }
    },
    {
        "label": "Yemen",
        "value": "YE",
        "target": {
            "name": "Yemen",
            "value": "YE"
        }
    },
    {
        "label": "Zambia",
        "value": "ZM",
        "target": {
            "name": "Zambia",
            "value": "ZM"
        }
    },
    {
        "label": "Zimbabwe",
        "value": "ZW",
        "target": {
            "name": "Zimbabwe",
            "value": "ZW"
        }
    }
]


