import React from 'react';
import { withTranslation } from 'react-i18next';
import imgNoResult from '../../../res/images/No-result.svg';
import './NoResult.css'

class NoResult extends React.Component {
    render() {
        const { t } = this.props;
        return (
            <div className="central-content" id='noResult'>
                <img src={imgNoResult} alt=""/>
                <h1>{t("No project found")}</h1>
                <p>Keep searching or start creating!</p>
            </div>
        );
    }
}

export default withTranslation()(NoResult);