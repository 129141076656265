import React from 'react';
import './list.css';
import { withTranslation } from "react-i18next";
import RoundSelect from '../roundSelect/RoundSelect';
import UserHelper from '../../app/helpers/UserHelper';
import Tooltip from '../tooltip/Tooltip';

class ModelSettingsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isProductTitleModalShown: false,
            isScoreTitleModalShown: false,
            isFeelingTitleModalShown: false,
        }
        const currentUser = UserHelper.getUser();
        this.isCurrentUser = (props.item && currentUser && currentUser.id === props.item.id)
        this.getRoundSelectOption = this.getRoundSelectOption.bind(this);
        this.userRoleHandlerRoundSelect = this.editRoundSelect.bind(this);
    }

    editRoundSelect(t, item) {
        let roundSelectList = {}

        roundSelectList['actions'] = [{ label: t('Edit'), action: this.props.onOpenEditModal }];
        return roundSelectList
    }

    displayRemainingClassesInModal(classes) {
        if (classes.length < 1) {
            return;
        }

        const remainingClasses = classes.slice(1);
        return (
            <div className='base-tooltip'>
                {remainingClasses.map((classe, i) => <p className='line-height-1-5' key={`class-${i}`} >{classe.display_name}</p>)}
            </div>
        )
    }

    getRoundSelectOption() {
        const { t, item } = this.props;
        let displayRoundSelect = true;
        let roundSelectList = this.editRoundSelect(t, item)

        return {
            roundSelectList,
            displayRoundSelect
        }
    }

    render() {
        const { t, item } = this.props;
        let listItemsClasses = ["settings-user-list-item", "transition-color"];
        const classes = item.classes;
        const { roundSelectList, displayRoundSelect } =
          this.getRoundSelectOption();
        if (item.title === null) {
          item.title = "";
        }

        return (
          <div className={listItemsClasses.join(" ")}>
            <table className="sensia-table table-layout-fixed width-percent-100">
              <tbody>
                <tr>
                  <td width={150}>
                    {item.title.length > 38 ? (
                      <div className="position-relative">
                        <div
                          className="font-inter-400 text-ellipsis line-height-20 color-7f7f7f font-size-13 white-space-normal height-40-px width-100-px"
                          onMouseOver={() => {
                            this.setState({ isProductTitleModalShown: true });
                          }}
                          onMouseOut={() => {
                            this.setState({ isProductTitleModalShown: false });
                          }}
                        >
                          {item.title}
                        </div>
                      </div>
                    ) : (
                      <div className="position-relative">
                        <div className="font-inter-400 text-ellipsis line-height-20 color-7f7f7f font-size-13 white-space-normal width-100-px">
                          {item.title}
                        </div>
                      </div>
                    )}
                    {this.state.isProductTitleModalShown && (
                      <div className="title-modale">{item.title}</div>
                    )}
                  </td>
                  <td width={150} className="hide-760">
                    {item.score_model ? (
                      item.score_model.title.length > 60 ? (
                        <div
                          className="font-inter-400 font-size-14 color-000 text-break-line text-ellipsis white-space-normal height-50-px width-150-px webkit-text"
                          onMouseOver={() => {
                            this.setState({ isScoreTitleModalShown: true });
                          }}
                          onMouseOut={() => {
                            this.setState({ isScoreTitleModalShown: false });
                          }}
                        >
                          {item.score_model.title}
                        </div>
                      ) : (
                        <span className="font-inter-400 font-size-14 color-000 text-break-line">
                          {item.score_model.title}
                        </span>
                      )
                    ) : (
                      <span className="font-inter-400 font-size-14 color-000 text-break-line">
                        N/A
                      </span>
                    )}
                    {this.state.isScoreTitleModalShown && (
                      <div className="title-modale">
                        {item.score_model.title}
                      </div>
                    )}
                  </td>
                  <td width={150} className="hide-760">
                    {item.emotion_model ? (
                      item.emotion_model.title.length > 60 ? (
                        <div
                          className="font-inter-400 font-size-14 color-000 text-break-line text-ellipsis white-space-normal height-50-px width-150-px webkit-text"
                          onMouseOver={() => {
                            this.setState({ isFeelingTitleModalShown: true });
                          }}
                          onMouseOut={() => {
                            this.setState({ isFeelingTitleModalShown: false });
                          }}
                        >
                          {item.emotion_model.title}
                        </div>
                      ) : (
                        <span className="font-inter-400 font-size-14 color-000 text-break-line">
                          {item.emotion_model.title}
                        </span>
                      )
                    ) : (
                      <span className="font-inter-400 font-size-14 color-000 text-break-line">
                        N/A
                      </span>
                    )}
                    {this.state.isFeelingTitleModalShown && (
                      <div className="title-modale">
                        {item.emotion_model.title}
                      </div>
                    )}
                  </td>
                  <td width={150} className="hide-760">
                    {classes ? (
                      <span className="font-inter-400 font-size-14 color-000">
                        {classes[0].display_name} <br />
                        {classes.length > 1 && (
                          <Tooltip
                            content={this.displayRemainingClassesInModal(
                              classes
                            )}
                            placement="bottom"
                          >
                            <span className="color-3a79e6 font-size-14">
                              {classes.length - 1} other
                              {classes.length - 1 === 1 ? "" : "s"}
                            </span>
                          </Tooltip>
                        )}
                      </span>
                    ) : (
                      <span className="font-inter-400 font-size-14 color-000">
                        No classes
                      </span>
                    )}
                  </td>
                  <td width={30}>
                    {displayRoundSelect === true ? (
                      <RoundSelect
                        lists={roundSelectList}
                        disableHover={this.props.hideRoundHover}
                      />
                    ) : null}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        );
    }
}

export default withTranslation()(ModelSettingsList);