import React, { Fragment } from 'react';
import { withTranslation, Trans } from "react-i18next";
import Loader from '../../../../components/loader/Loader';
import Modal from '../../../../components/modal/Modal';
import { PLAN_UGRAPDE } from '../../../../app/routing';
import Link from '../../../../components/Link';
import { ACCOUNT_PLAN } from '../../../../app/constants';
import { TextField } from "@mui/material";

class CreateTeamModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        title: "",
      },
    };
    this.modalRef = React.createRef();
    this.onInputChange = this.onInputChange.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
    this.getMessageErrorPlan = this.getMessageErrorPlan.bind(this);
  }

  onCancelClick() {
    if (this.modalRef && this.modalRef.current) {
      this.modalRef.current.onModalCloseClick();
    }
  }

  onInputChange(e) {
    let formData = Object.assign({}, this.state.formData);
    formData[e.target.name] = e.target.value;
    this.setState({
      formData: formData,
    });
  }

  getMessageErrorPlan() {
    const { currentPlan } = this.props;
    return (
      <Trans i18nKey="error_plan_add_user">
        You are on the {currentPlan} plan. Please{" "}
        <Link
          to={PLAN_UGRAPDE(ACCOUNT_PLAN.BUSINESS)}
          className="text-decoration-underline color-000"
        >
          upgrade to Business plan
        </Link>{" "}
        to manage teams to your organization.
      </Trans>
    );
  }

  render() {
    const { t } = this.props;
    const buttonIsEnabled = this.state.formData.title !== "";
    return (
      <Modal
        ref={this.modalRef}
        display={this.props.display}
        onClose={this.props.onClose}
      >
        <div className="font-size-30 font-inter-700 color-000 padding-bottom-40">
          {t("Create team")}
        </div>
        <form>
          <div className="padding-bottom-36">
            <label className="display-block color-969696 font-size-15 font-inter-600 padding-bottom-4">
              {t("Team name")}
            </label>
            <TextField
              type="text"
              name="title"
              placeholder={t("Name")}
              disabled={this.props.loading}
              onChange={this.onInputChange}
              sx={{ width: "100%" }}
            />
            {this.props.errorPlan && (
              <div className="content-error-plan margin-top-20">
                {this.getMessageErrorPlan()}
              </div>
            )}
          </div>
          <div className="overflow-hidden">
            {!this.props.loading && (
              <Fragment>
                <div className="float-right">
                  <button
                    className="button backgroundless"
                    type="button"
                    onClick={this.onCancelClick}
                  >
                    {t("Cancel")}
                  </button>
                  <button
                    className="button backgroundless margin-left-5"
                    disabled={!buttonIsEnabled}
                    onClick={() => {
                      this.props.onCreateItem(this.state.formData);
                    }}
                  >
                    {t("Create")}
                  </button>
                </div>
                {!!this.props.error && (
                  <div className="color-e04f77 font-size-15 line-height-34">
                    <span className="display-inline-block line-height-1-2">
                      {this.props.error}
                    </span>
                  </div>
                )}
              </Fragment>
            )}
            {this.props.loading && (
              <div className="float-right" style={{ padding: "2px 0" }}>
                <Loader />
              </div>
            )}
          </div>
        </form>
      </Modal>
    );
  }
}

export default withTranslation()(CreateTeamModal);