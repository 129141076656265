import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";
import ReportHelper from "../../../app/helpers/ReportHelper";
import DateHelper from "../../../app/helpers/DateHelper";
import RoundedEmotion from "../../../components/ui/RoundedEmotion";
import { uniqueId } from "lodash";
import Link from "../../../components/Link";
import MediaHelper from "../../../app/helpers/MediaHelper";

class LinedGraphTooltip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.getTooltipContent = this.getTooltipContent.bind(this);
    this.getTextWithColoredKeyword = this.getTextWithColoredKeyword.bind(this);
    this.getStartAtContent = this.getStartAtContent.bind(this);
  }

  getTextWithColoredKeyword(context, keyword, score) {
    const reactStringReplace = require("react-string-replace");
    const pluralize = require("pluralize");
    let text = context;
    let colorClass = ReportHelper.getScoreClass(score) + " underline-on-hover";
    const replace = `\\b(${keyword})\\b`;
    const regex = new RegExp(replace, "gi");
    text = reactStringReplace(text, regex, (match, i) => {
      return (
        <div
          onClick={() => this.props.getWorkspaceLinkByTarget(match)}
          key={`kt-${i}-${uniqueId()}`}
          className={colorClass}
        >
          {match}
        </div>
      );
    });
    const plural_keyword = pluralize.plural(keyword);
    const plural_replace = `\\b(${plural_keyword})\\b`;
    const plural_regex = new RegExp(plural_replace, "gi");
    text = reactStringReplace(text, plural_regex, (match, i) => {
      return (
        <div
          onClick={() => this.props.getWorkspaceLinkByTarget(match)}
          key={`ktp-${i}-${uniqueId()}`}
          className={colorClass}
        >
          {match}
        </div>
      );
    });
    return text;
  }

  getStartAtContent(target) {
    const { t } = this.props;
    if (target.source_type === MediaHelper.type.YOUTUBE) {
      return (
        <Fragment>
          {t("At")}{" "}
          <a
            href={target.youtube_link}
            rel="noreferrer"
            target="_blank"
            className="link-on-hover color-3a79e6"
          >
            {DateHelper.getSecondsTohhmmss(target.start_at)}
          </a>
        </Fragment>
      );
    }
    if (
      (target.source_type === MediaHelper.type.WEBSITE_URL &&
        target.website_link) ||
      (target.source_type === MediaHelper.type.AMAZON_URL &&
        target.website_link)
    ) {
      return (
        <Fragment>
          {t("At")}{" "}
          <a
            href={target.website_link}
            rel="noreferrer"
            target="_blank"
            className="link-on-hover color-3a79e6"
          >
            #{target.start_at}
          </a>
        </Fragment>
      );
    }
    return (
      <Fragment>
        {t("At")} <span className="color-3a79e6">#{target.start_at}</span>
      </Fragment>
    );
  }

  getTooltipContent() {
    const { t, item } = this.props;
    let target = Object.assign({ keyword: item.keyword }, this.props.target);
    target.score = parseFloat(target.score.toFixed(2));
    const scoreClasses = [
      "font-inter-500",
      "font-size-22",
      ReportHelper.getScoreClass(target.score),
      "padding-bottom-1",
    ];
    return (
      <div className="base-tooltip max-width-512">
        <div className="font-inter-400 line-height-1-71 font-size-14 color-7f7f7f">
          {target.title} • {this.getStartAtContent(target)}
        </div>
        <div className="font-inter-400 line-height-1-53 font-size-17 color-000 padding-bottom-15">
          {this.getTextWithColoredKeyword(
            target.context,
            target.keyword,
            target.score
          )}
        </div>
        <div className="position-relative">
          <div className="position-relative display-inline-block vertical-align-top">
            <div className={scoreClasses.join(" ")}>{target.score}</div>
            <div className="font-inter-400 font-size-13 color-7f7f7f">
              {t("score")}
            </div>
          </div>
          <div className="position-relative display-inline-block vertical-align-top padding-left-24">
            <div className="position-relative padding-top-2 padding-bottom-5">
              <RoundedEmotion emotion={target.emotion} />
            </div>
            <div className="font-inter-400 font-size-13 color-7f7f7f">
              {t("Top feeling")}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return <div id="lined-graph-tooltip">{this.getTooltipContent()}</div>;
  }
}

export default withTranslation()(LinedGraphTooltip);
