import React, { Fragment } from "react";
import "./tooltip.css";
import { withTranslation } from "react-i18next";
import ReportHelper from "../../app/helpers/ReportHelper";
import ScoreRangeField from "../form/field/ScoreRangeField";
import Loader from "../loader/Loader";

class WorkspaceScoreTooltipContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initialscore: props.item ? props.item.initialscore : null,
      defaultScore: props.item ? props.item.score : null,
      score: props.item ? props.item.score : null,
    };
    this.getScoreClassName = this.getScoreClassName.bind(this);
    this.onRangeChange = this.onRangeChange.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onResetScore = this.onResetScore.bind(this);

    if (
      props.scoreTooltipRefs &&
      props.scoreTooltipRefs.length > 0 &&
      typeof props.scoreTooltipRefIndex !== "undefined"
    ) {
      for (let i = 0; i < props.scoreTooltipRefs.length; i++) {
        if (
          i !== props.scoreTooltipRefIndex &&
          props.scoreTooltipRefs[i] &&
          props.scoreTooltipRefs[i].current &&
          props.scoreTooltipRefs[i].current.updateStayOpened
        ) {
          props.scoreTooltipRefs[i].current.updateStayOpened(false, false);
        }
      }
    }
  }

  getScoreClassName(occurences) {
    const { t } = this.props;
    let scoreClass = "color-d2d2d2";
    let theScore = "-";

    if (this.state.score !== null) {
      theScore = this.state.score.toFixed(2);
      scoreClass = ReportHelper.getScoreClass(theScore);
    }
    let nbOccurences = "";
    let scoreClassNames = ["font-inter-600", scoreClass];
    nbOccurences =
      occurences > 1
        ? t("{{count}} occurences", { count: occurences })
        : t("{{count}} occurence", { count: occurences });
    scoreClassNames.push("font-size-22");
    return { nbOccurences, scoreClassNames, theScore };
  }

  onRangeChange(newScore) {
    const { tooltipRef } = this.props;
    this.setState({ score: newScore });

    const scoreIsUpdated = this.state.defaultScore !== newScore;
    if (tooltipRef && tooltipRef.current) {
      if (tooltipRef.current.updateStayOpened) {
        tooltipRef.current.updateStayOpened(scoreIsUpdated);
      }
    }
  }

  onClose() {
    const { tooltipRef } = this.props;
    if (tooltipRef && tooltipRef.current) {
      if (tooltipRef.current.updateStayOpened) {
        tooltipRef.current.updateStayOpened(false, false);
      }
    }
  }

  onResetScore() {
    this.onRangeChange(this.state.initialscore);
  }

  render() {
    const { t, item, withSeparator, type, scoreLibelle } = this.props;
    const occurences = item ? item.occurence : 0;
    const typeData = type ? type : "default";

    const { nbOccurences, scoreClassNames, theScore } =
      this.getScoreClassName(occurences);

    const score_libelle = scoreLibelle ? scoreLibelle : t("score");
    if (!item) {
      return null;
    }
    const scoreIsUpdated = this.state.defaultScore !== this.state.score;
    const isInitialScore =
      this.state.initialscore?.toFixed(2) === this.state.score?.toFixed(2);
    return (
      <Fragment>
        <div className="position-relative">
          <div className="position-relative display-inline-block vertical-align-top">
            <div className={scoreClassNames.join(" ")}>{theScore}</div>
            <div className="font-inter-400 font-size-13 color-7f7f7f">
              {score_libelle}
            </div>
          </div>
        </div>
        <div className="tooltip-separator" />
        <div className="position-relative height-50">
          <div className="tooltip-adjust-score-text">{t("Adjust score")}</div>
          {!isInitialScore && !this.props.updateScoreLoading && (
            <button
              className="tooltip-adjust-score-button button backgroundless"
              style={{
                fontSize: "15px",
                fontWeight: 600,
                padding: 0,
                lineHeight: 1.2,
              }}
              onClick={() => this.onResetScore()}
            >
              {t("Reset")}
            </button>
          )}
        </div>
        <ScoreRangeField
          minRange={-this.props.maxRange}
          maxRange={this.props.maxRange}
          initialscore={this.state.initialscore}
          score={this.state.score}
          onChange={this.onRangeChange}
        />
        {scoreIsUpdated && !this.props.updateScoreLoading && (
          <div className="position-relative text-align-right padding-top-26">
            <button
              className="button backgroundless margin-right-14"
              style={{
                fontSize: "15px",
                fontWeight: 600,
                padding: 0,
                lineHeight: 1.2,
              }}
              onClick={() => this.onClose()}
            >
              {t("Cancel")}
            </button>
            <button
              className="button backgroundless update"
              style={{
                fontSize: "15px",
                fontWeight: 600,
                padding: 0,
                lineHeight: 1.2,
              }}
              onClick={() => this.props.onUpdateScore(this.state.score)}
            >
              {t("Update")}
            </button>
          </div>
        )}
        {this.props.updateScoreLoading && (
          <div className="position-relative text-align-right padding-top-26">
            <Loader />
          </div>
        )}
      </Fragment>
    );
  }
}

export default withTranslation()(WorkspaceScoreTooltipContent);
