import * as React from 'react';
import { useRecordContext } from 'react-admin';

const FilledDateField = ({ source, hidden }) => {
    const record = useRecordContext();

    const formatDate = (date) => {
        const pad = (s) => {
            return (s < 10) ? '0' + s : s;
        }
        const d = new Date(date.replace(/-/g, "/"));

        return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/')
    }

    let label = !record.hasOwnProperty(source) ? "Not found" :
        record[source] ? formatDate(record[source]) : "none";
        
    if (hidden && label === "none") {
        label = "";
    }

    return (
        <div className='RaLabeled-value-42'>
            <span className="MuiTypography-root MuiTypography-body2">{label}</span>
        </div>
    );
};

FilledDateField.defaultProps = {
    addLabel: true,
};

export default FilledDateField;