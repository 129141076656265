import React from 'react';
import { withTranslation } from 'react-i18next';
import { getAccountNextPlan, globalConstants } from '../../../app/constants';
import { ROUTES } from '../../../app/routing';
import Link from '../../../components/Link';
import Modal from '../../../components/modal/Modal';
import imgIllustration from '../../../res/images/illustrations/illus-upgrade-create-project.png';
class UpgradePlanToCreateProjectModal extends React.Component {
    constructor(props) {
        super(props);
        this.modalRef = React.createRef();
        this.onCancelClick = this.onCancelClick.bind(this);
    }

    onCancelClick() {
        if (this.modalRef && this.modalRef.current) {
            this.modalRef.current.onModalCloseClick();
        }
    }

    render() {
        const { t, currentPlan } = this.props;
        const nextPlan = getAccountNextPlan(currentPlan);
        const message = (this.props.maxNbProjects > 1) ? t('You reached your {{limit_size}} projects limit. Please upgrade your plan first to the {{next_plan}} Plan to create more projects and much more.', { limit_size: this.props.maxNbProjects, next_plan: nextPlan }) : t('You reached your {{limit_size}} project limit. Please upgrade your plan first to the {{next_plan}} Plan to create more projects and much more.', { limit_size: this.props.maxNbProjects, next_plan: nextPlan })
        return (
            <Modal ref={this.modalRef} display={this.props.display} onClose={this.props.onClose}>
                <div className="text-align-left font-inter-700 color-000 font-size-24">{t('Upgrade to create more projects')}</div>
                <div className="text-align-left padding-top-24 font-inter-500 color-7f7f7f font-size-18 line-height-1-4" style={{ letterSpacing: "-0.2px" }}>{message}</div>
                <div className="text-align-left padding-top-24">
                    <a className="font-inter-500 link-blue font-size-18 line-height-1-4 color-3a79e6" rel="noreferrer" target={'_blank'} href={ROUTES.PLAN_CHOOSE}>{t('See what you get when you upgrade')}</a>
                </div>
                <div className="text-align-right padding-top-40">
                    <button className="button white margin-right-20 responsive-370-margin-right-0 width-90 font-weight-500 margin-bottom-4 color-3a79e6" onClick={this.onCancelClick}>{t('Maybe later')}</button>
                    <Link className="button blue width-90 font-weight-500 margin-bottom-4" to={ROUTES.PLAN_CHOOSE}>{t('Upgrade')}</Link>
                </div>
            </Modal>
        );
    }
}

export default withTranslation()(UpgradePlanToCreateProjectModal);