import * as React from "react";
import { useRecordContext } from "react-admin";
import LinkIcon from "@mui/icons-material/Link";
import { MEDIA_TYPE } from "../../../app/constants/media.constants";

const IconUrlField = ({ source }) => {
  const record = useRecordContext();
  const url = record && record[source];

  if (url == null) {
    return null;
  }

  return (
    record.type !== MEDIA_TYPE.TXT_FILE &&
    record.type !== MEDIA_TYPE.SHEET_FILE && (
      <a href={url} target="_blank">
        <LinkIcon />
      </a>
    )
  );
};

IconUrlField.defaultProps = {
  addLabel: true,
};

export default IconUrlField;
