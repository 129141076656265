import React from "react";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { loginRequest } from "../../../app/authConfig";
import { Box, Button, Typography } from "@mui/material";
import ms_logo from "../../../res/images/logo/microsoft_logo.png";
/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 * Note the [useMsal] package
 */

export const MicrosoftSigninButton = ({
  title = "Sign up with Microsoft",
  handleResponse,
}) => {
  const { instance } = useMsal();

  const accounts = instance.getAllAccounts();
  if (accounts.length > 0) {
    instance.setActiveAccount(accounts[0]);
  }

  instance.addEventCallback(
    (event) => {
      console.log(event);
      // set active account after redirect
      if (event.eventType === "msal:loginSuccess" && event.payload.account) {
        const account = event.payload.account;
        instance.setActiveAccount(account);
      }

      if (event.eventType === "msal:handleRedirectEnd") {
        const account = instance.getActiveAccount();
        console.log("event.eventType, account :", account);
        handleResponse(account.idToken);
      }
    },
    (error) => {
      console.log("error", error);
    }
  );

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log(e);
    });
  };
  return (
    <Button
      sx={{
        display: "flex",
        width: "100%",
        minWidth: "240px",
        padding: "6px 8px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        borderRadius: "6px",
        background: "#F2F3F5",
        textTransform: "none",
      }}
      onClick={() => handleLogin()}
    >
      <img src={ms_logo} width="22px" />{" "}
      <Typography
        sx={{
          color: "#000",
          /* Components/Button Medium */
          fontFamily: "Inter",
          fontSize: "15px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "normal",
        }}
      >
        {title}
      </Typography>
    </Button>
  );
};
