import React from 'react';
import { withTranslation } from 'react-i18next';
import Loader from '../loader/Loader';
import Modal from './Modal';
import TextField from '../form/field/TextField';


class EditProjectModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                id: props.item.id,
                name: props.item.name
            },
            initialFormData: {
                id: props.item.id,
                name: props.item.name
            }
        };
        this.modalRef = React.createRef();
        this.onInputChange = this.onInputChange.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.buttonIsEnabled = this.buttonIsEnabled.bind(this);
    }

    onCancelClick() {
        if (this.modalRef && this.modalRef.current) {
            this.modalRef.current.onModalCloseClick();
        }
    }

    onInputChange(e) {
        let formData = Object.assign({}, this.state.formData);
        formData[e.target.name] = e.target.value;
        this.setState({
            formData: formData
        });
    }

    buttonIsEnabled() {
        return this.state.formData.name.length > 0 && JSON.stringify(this.state.formData) !== JSON.stringify(this.state.initialFormData);
    }

    render() {
        const { t } = this.props;
        const buttonIsEnabled = this.buttonIsEnabled();
        return (
            <Modal ref={this.modalRef} display={this.props.display} onClose={this.props.onClose}>
                <div className="font-size-30 font-inter-700 color-000 padding-bottom-40">{t('Rename')}</div>
                <form>
                    <div className="padding-bottom-36">
                        <TextField
                            label={t('Project name')}
                            type="text"
                            name="name"
                            value={this.state.formData.name}
                            onChange={this.onInputChange}
                            disabled={this.props.loading}
                            maxLength={256}
                        />
                    </div>
                    <div className="overflow-hidden">
                        {!this.props.loading &&
                            <div className="float-right">
                                <button className="button backgroundless" type="button" onClick={this.onCancelClick}>{t('Cancel')}</button>
                                <button className="button backgroundless margin-left-5" disabled={!buttonIsEnabled} onClick={() => { this.props.onEditItem(this.state.formData) }}>{t('Save')}</button>
                            </div>
                        }
                        {this.props.loading &&
                            <div className="float-right" style={{ padding: "2px 0" }}>
                                <Loader />
                            </div>
                        }
                    </div>
                </form>
            </Modal>
        );
    }
}

export default withTranslation()(EditProjectModal);