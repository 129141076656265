import StringHelper from "../helpers/StringHelper";

export const ACCOUNT_PLAN = {
    FREE: 'Free',
    PRO: 'Pro',
    BUSINESS: 'Business',
    ENTERPRISE: 'Enterprise'
}

export const STATUS_USER_ACCOUNT = {
    ACTIVE: 'Active',
    INACTIVE: 'Inactive'
}

export const ACCOUNT_PLAN_FREQUENCY = {
    YEARLY: 'YEARLY',
    MONTHLY: 'MONTHLY'
}

export const getAccountPlanFrequency = (planFreq) => {
    return StringHelper.capitalize(planFreq.toLowerCase());
}

export const getAccountPlan = (planKey) => {
    if (!planKey)
        return ACCOUNT_PLAN.FREE;
    planKey = StringHelper.capitalize(planKey.toLowerCase());
    if (isAccountPlanValid(planKey)) {
        return planKey;
    }
    return null
}

export const getAccountNextPlan = (planKey) => {
    const currentPlan = getAccountPlan(planKey);
    switch (currentPlan) {
        case ACCOUNT_PLAN.FREE:
            return ACCOUNT_PLAN.PRO;
        case ACCOUNT_PLAN.PRO:
            return ACCOUNT_PLAN.BUSINESS;
        case ACCOUNT_PLAN.BUSINESS:
            return ACCOUNT_PLAN.ENTERPRISE;
        case ACCOUNT_PLAN.ENTERPRISE:
            return ACCOUNT_PLAN.ENTERPRISE;
        default:
            return ACCOUNT_PLAN.PRO;
    }
}

export const getAllAccountPlans = () => {
    return [ACCOUNT_PLAN.FREE, ACCOUNT_PLAN.PRO, ACCOUNT_PLAN.BUSINESS, ACCOUNT_PLAN.ENTERPRISE];
}

export const isAccountPlanValid = (plan) => {
    return (getAllAccountPlans().includes(plan));
}

export const isValidUpgradePlan = (account, targetPlan) => {
    const currentPlan = getAccountPlan(account.plan_key);
    const upgradePlan = getAccountPlan(targetPlan);

    // Account is downgraded to FREE
    if (account.stripe_subscription_id === null && account.expiration_date !== null) {
        return true
    }

    switch (currentPlan) {
        case ACCOUNT_PLAN.FREE:
            return true;
        case ACCOUNT_PLAN.PRO:
            return upgradePlan == ACCOUNT_PLAN.PRO || upgradePlan == ACCOUNT_PLAN.BUSINESS || upgradePlan == ACCOUNT_PLAN.ENTERPRISE;
        case ACCOUNT_PLAN.BUSINESS:
            return upgradePlan == ACCOUNT_PLAN.BUSINESS || upgradePlan == ACCOUNT_PLAN.ENTERPRISE;
        case ACCOUNT_PLAN.ENTERPRISE:
            return upgradePlan == ACCOUNT_PLAN.ENTERPRISE;
        default:
            return false;
    }
}

export const getAdvantagesByPlan = (t, plan) => {
    const currentPlan = getAccountPlan(plan).toLowerCase();
    const advantages = getPlanAdvantages(t);
    if (advantages[currentPlan] !== undefined) {
        return advantages[currentPlan];
    }
    return null;
}

export const getDowngradeAdvantagesList = (t) => {
    return [
        "Up to 10 projects",
        "Customize integrated classes",
        "Up to 4 custom classes",
        "Reporting and Insights",
        "Exports",
        "Unlimited users",
        "Unlimited Youtube videos",
        "Mixed language support",
    ]
}

export const getPlanAdvantages = (t) => {
    return {
        free: {
            title: t('FREE'),
            planType: ACCOUNT_PLAN.FREE,
            subtitle: t('for individuals'),
            monthlyPrice: 0,
            yearlyMonthlyPrice: 0,
            price: t('/forever'),
            subpriceYearly: "",
            subprice: "",
            rawClass: [],
            advantages: [
                {
                    title: t('With Free plan, you can get'),
                    advantage: [
                        t('1 project'),
                        t('Default classes'),
                        t('Reviews Inspector')
                    ]
                },
                {
                    title: t('Datasources collection / Project'),
                    advantage: [
                        t('Up to 2 sources per project'),
                        t("Youtube videos and Amazon Product URL sources"),
                        t('One language support')
                    ]
                }
            ]
        },
        pro: {
            title: t('PRO'),
            planType: ACCOUNT_PLAN.PRO,
            subtitle: t('for individuals'),
            price: "39",
            monthlyPrice: 49,
            yearlyMonthlyPrice: 39,
            subprice: t('/month, when billed monthly'),
            subpriceYearly: t('/month, when billed yearly'),
            rawClass: [],
            advantages: [
                {
                    title: t('With Pro plan, you can get'),
                    advantage: [
                        t('Up to 3 projects'),
                        t('Insights Summaries'),
                        t('In-Depth Analytics'),
                        t('Reviews Inspector'),
                        t('Export (.csv) and sharing'),
                        t('Up to 1 custom class')
                    ]
                },
                {
                    title: t('Datasources collection / Project'),
                    advantage: [
                        t('Up to 3 sources per project'),
                        t('All sources including Walmart, Costco, Sephora etc'),
                        t('One language per source support'),
                    ]
                },
                {
                    title: t('Data import'),
                    advantage: [
                        t('Surveys (.txt, .csv, .xlsx)')
                    ]
                }
            ]
        },
        business: {
            title: t('BUSINESS'),
            planType: ACCOUNT_PLAN.BUSINESS,
            subtitle: t('for teams'),
            price: "99",
            monthlyPrice: 124,
            yearlyMonthlyPrice: 99,
            rawClass: [],
            subprice: t('/month, when billed monthly'),
            subpriceYearly: t('/month, when billed yearly'),
            advantages: [
                {
                    title: t('With Business plan, you can get'),
                    advantage: [
                        t('Up to 5 projects'),
                        t('Insights Summaries'),
                        t('In-Depth Analytics'),
                        t('Reviews Inspector'),
                        t('Export (.csv) and sharing'),
                        t('Up to 4 custom classes'),
                        t('Up to 3 users')
                    ]
                },
                {
                    title: t('Datasources collection / Project'),
                    advantage: [
                        t('Up to 5 sources per project'),
                        t('All sources including Walmart, Costco, Sephora etc'),
                        t('Mixed languages support'),
                    ]
                },
                {
                    title: t('Data import'),
                    advantage: [
                        t('Surveys (.txt, .csv, .xlsx)')
                    ]
                }
            ]
        },
        enterprise: {
            title: t('ENTERPRISE'),
            planType: ACCOUNT_PLAN.ENTERPRISE,
            subtitle: t('for companies'),
            price: "",
            monthlyPrice: null,
            yearlyMonthlyPrice: null,
            rawClass: [],
            subprice: t('To get a price estimate for Enterprise plan, please contact our sales team.'),
            subpriceYearly: t('To get a price estimate for Enterprise plan, please contact our sales team.'),
            advantages: [
                {
                    title: t('With Enterprise plan, you can get'),
                    advantage: [
                        t('Unlimited projects'),
                        t('Insights Summaries'),
                        t('In-Depth Analytics'),
                        t('Reviews Inspector'),
                        t('Exports (.csv)'),
                        t('Unlimited custom classes'),
                        t('Unlimited users')
                    ]
                },
                {
                    title: t('Datasources collection / Project'),
                    advantage: [
                        t('Up to 10 sources per project'),
                        t('All sources including Walmart, Costco, Sephora etc'),
                        t('Cloud / Internal apps integration'),
                        t('Other eCommerce reviews'),
                    ]
                },
                {
                    title: t('Data import'),
                    advantage: [
                        t('Surveys (.txt, .csv, .xlsx)'),
                        t('Business Intelligence'),
                        t('Chat conversations'),
                        t('Webservices')
                    ]
                }
            ]
        }
    }
}