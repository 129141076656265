import * as React from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import switch_start from "../../res/images/icons/switch_star.png";
import blue_circle from "../../res/images/icons/blue_circle_icon.png";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const MaterialUISwitch = styled(Switch)(() => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: "none",
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#9E9E9E",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#3A79E6",
    width: 32,
    height: 32,
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url(${switch_start})`,
      backgroundSize: "15px",
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "#9E9E9E",
    borderRadius: 20 / 2,
  },
}));

export default function MuiSwitch({ sx, checked = false, onChange }) {
  const checkedSX = {
    color: "#3A79E6",
    fontFamily: "Inter",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  };

  let uncheckedSX = Object.assign({}, checkedSX);
  uncheckedSX.color = "#9E9E9E";

  return (
    <FormGroup sx={sx}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography sx={checked ? uncheckedSX : checkedSX}>English</Typography>
        {/* <AntSwitch defaultChecked inputProps={{ "aria-label": "ant design" }} /> */}
        <MaterialUISwitch
          onChange={(_, value) => onChange(value)}
          sx={{ m: 1 }}
          checked={checked}
        />
        <Typography sx={checked ? checkedSX : uncheckedSX}>Original</Typography>
      </Stack>
    </FormGroup>
  );
}
