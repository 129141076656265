import React from 'react';
import './SkeletonLoad.css'

class SkeletonLoad extends React.Component {
    render() {
        return (
            <div className="central-content" id='skeletonLoad'>
                 <div className='skelontItem' style={{width: "403px", height: "31px", marginTop: "10px"}}></div>
                 <div className='skelontItem' style={{width: "99px", height: "18px", marginTop: "72px"}}></div>
                 <div className='skelontItem right-effect' style={{width: "828px", height: "53px", marginTop: "45px"}}></div>

                 <div style={{width: "100p%", height: "336px", marginTop: "34px", display: "flex", columnGap: "20px"}}>
                    <div className='skelontItem'  style={{width: "50%", height: "100%"}}></div>
                    <div className='skelontItem right-effect'  style={{width: "50%", height: "100%"}}></div>
                 </div>

                 <div className='skelontItem' style={{width: "209px", height: "31px", marginTop: "43px"}}></div>
                 <div className='skelontItem' style={{width: "571px", height: "53px", marginTop: "22px"}}></div>
                 <div className='skelontItem' style={{width: "230px", height: "31px", marginTop: "28px"}}></div>

                 <div style={{width: "100p%", height: "336px", marginTop: "11px", display: "flex", columnGap: "20px"}}>
                    <div className='skelontItem'  style={{width: "50%", height: "100%"}}></div>
                    <div className='skelontItem right-effect'  style={{width: "50%", height: "100%"}}></div>
                 </div>

                 <div className='skelontItem' style={{width: "201px", height: "18px", marginTop: "39px"}}></div>

                 <div style={{width: "100p%", height: "114px", marginTop: "25px", display: "flex", columnGap: "20px"}}>
                    <div className='skelontItem' style={{width: "50%", height: "100%"}}></div>
                    <div className='skelontItem' style={{width: "50%", height: "100%"}}></div>
                 </div>
                 <div style={{width: "100p%", height: "114px", marginTop: "13px", display: "flex", columnGap: "20px"}}>
                    <div className='skelontItem' style={{width: "50%", height: "100%"}}></div>
                    <div className='skelontItem' style={{width: "50%", height: "100%"}}></div>
                 </div>
                 <div style={{width: "100p%", height: "114px", marginTop: "13px", display: "flex", columnGap: "20px"}}>
                    <div className='skelontItem' style={{width: "50%", height: "100%"}}></div>
                    <div className='skelontItem' style={{width: "50%", height: "100%"}}></div>
                 </div>

                 <div className='skelontItem' style={{width: "201px", height: "18px", marginTop: "25px"}}></div>
                 <div className='skelontItem right-effect' style={{width: "100%", height: "227px", marginTop: "24px"}}></div>
            </div>
        );
    }
}

export default SkeletonLoad