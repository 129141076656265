import React from 'react';

import './loader.css';

class DotLoader extends React.Component {
    render() {
        const { className, color } = this.props;
        let allClassNames = ["dot-loader"];
        if (className) {
            allClassNames.push(className);
        }
        if (color) {
            allClassNames.push(color);
        }

        return (
            <div className={allClassNames.join(' ')}>
                <span className="one">.</span><span className="two">.</span><span className="three">.</span>
            </div>
        );
    }
}

export default DotLoader;