import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  ShowButton,
  TextInput,
  ReferenceInput,
  SelectInput,
  DateField,
  Show,
  SimpleShowLayout,
  ReferenceManyField,
  SearchInput,
  Filter,
} from "react-admin";
import { USER_STATUS } from "../../app/constants/user.constants";
import FilledDateField from "./elements/FilledDateField";

const UserFilters = (props) => {
  var statusFilterChoices = [];
  for (const [key, value] of Object.entries(USER_STATUS)) {
    statusFilterChoices.push({ id: key, name: value });
  }

  return (
    <Filter {...props}>
      <SearchInput source="q" className="remove-line-height" alwaysOn />
      <SelectInput
        choices={statusFilterChoices}
        label="Status"
        source="status"
        allowEmpty={false}
      />
    </Filter>
  );
};

const UserShowButton = ({ record }) => (
  <ShowButton label="Show" record={record} />
);

export const UserList = (props) => (
  <List filters={<UserFilters />} {...props} perPage={25}>
    <Datagrid bulkActionButtons={false} rowClick="show">
      <TextField label="User ID" source="id" />
      <TextField label="Lastname" source="lastname" />
      <TextField label="Firstname" source="firstname" />
      <EmailField source="email" />
      <FilledDateField
        label="Registered at"
        source="created_at"
        hidden={false}
      />
      <TextField label="Status" source="status" />
      <TextField label="Last connection" source="last_connection" />
      <TextField label="Number of accounts" source="nb_account" />
      <UserShowButton />
    </Datagrid>
  </List>
);

export const UserShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField label="User ID" source="id" />
      <TextField label="Lastname" source="lastname" />
      <TextField label="Firstname" source="firstname" />
      <EmailField source="email" />
      <TextField label="Registered at" source="created_at" />
      <TextField label="Status" source="status" />
      <TextField label="Last connection" source="last_connection" />
      <ReferenceManyField reference="accounts" target="id" label="Account">
        <Datagrid bulkActionButtons={false}>
          <TextField label="Account ID" source="id" />
          <TextField label="Name" source="title" />
          <TextField label="Access" source="user_role" />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
);
