import React from 'react';
import './switch.css';

const Switch = (props) => {
    const { onClick, className } = props
    let classes = ['switch']
    if(className){
        classes.push(className);
    }
    return (
        <label className={classes.join(' ')}>
            <input type="checkbox" onClick={onClick}/>
            <span className="slider round"></span>
        </label>
    );
};

export default Switch;