import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import {
  getAccountPlan,
  globalConstants,
  PLAN_JSON_LOGIC_KEYS,
} from "../../app/constants";
import LoadingView from "../loading/LoadingView";
import Header from "../../components/header/Header";

import { withTranslation, Trans } from "react-i18next";
import { connect } from "react-redux";
import EmptyState from "./elements/EmptyState";
import Footer from "../../components/footer/Footer";
import ProjectsList from "./elements/ProjectsList";
import { projectService } from "../../app/services/project.service";
import CollectionHelper from "../../app/helpers/CollectionHelper";
import UpgradePlanToCreateProjectModal from "./elements/UpgradePlanToCreateProjectModal";
import HttpHelper from "../../app/helpers/HttpHelper";
import UserHelper from "../../app/helpers/UserHelper";
import { SessionStorageService } from "../../app/services/storage.service";
import { planJsonLogicService } from "../../app/services/plan.jsonLogic.service";
import { ROUTES } from "../../app/routing";
import Link from "../../components/Link";
import DeleteModal from "../../components/modal/DeleteModal";
import PageEnd from "../../components/PageEnd/PageEnd";
import NoResult from "./elements/NoResult";
import IntercomHelper from "../../app/helpers/IntercomHelper";

class HomeView extends React.Component {
  constructor(props) {
    super(props);
    const sortedBy =
      SessionStorageService.get("project_list_sorted_by") || "updated_at";
    const urlParams = HttpHelper.getUrlParameters(props.location.search);
    const modale = urlParams.modale || null;
    const displayUpgradeCreateProjectModale = modale === "upgrade-plan";
    this.state = {
      isLoaded: false,
      inlineLoading: false,
      listItems: [],
      totalItems: 0,
      orangeHeaderMessage: null,
      displayEndOfList: false,
      formData: {
        page: 1,
        page_size: 12,
        sorted_by: sortedBy,
        add_archived: 0,
        search: null,
      },
      delete: {
        display: false,
        item: null,
        loading: false,
        error: null,
      },
      currentPlan: getAccountPlan(null),
      maxNbProjects: 1,
      displayUpgradeCreateProjectModale: displayUpgradeCreateProjectModale,
      loadingCreateProjectButton: false,
      searchHaveResult: true,
    };
    this.searchTimeout = null;
    this.fetchInitial = this.fetchInitial.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.onChangeSortList = this.onChangeSortList.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.handleScroll = this.handleScroll.bind(this);

    this.onArchiveItem = this.onArchiveItem.bind(this);
    this.onUnArchiveItem = this.onUnArchiveItem.bind(this);

    this.onOpenDeleteModal = this.onOpenDeleteModal.bind(this);
    this.onCloseDeleteModal = this.onCloseDeleteModal.bind(this);
    this.onDeleteItem = this.onDeleteItem.bind(this);

    this.onClickCreateProjectButton =
      this.onClickCreateProjectButton.bind(this);
    this.onCloseUpgradePlanToCreateProjectModal =
      this.onCloseUpgradePlanToCreateProjectModal.bind(this);
    this.fetchBanner = this.fetchBanner.bind(this);
    this.successServiceHandler = this.successServiceHandler.bind(this);
    this.unArchiveItemSuccessHandler =
      this.unArchiveItemSuccessHandler.bind(this);
    this.displayToastErrorMessage = this.displayToastErrorMessage.bind(this);

    //Refresh Intercom with user
    var user = UserHelper.getUser();
    IntercomHelper.updateIntercomUser(user);
  }

  componentDidMount() {
    this._ismounted = true;
    window.addEventListener("scroll", this.handleScroll);
    this.fetchInitial();
  }

  componentWillUnmount() {
    this._ismounted = false;
    clearTimeout(this.searchTimeout);
    window.removeEventListener("scroll", this.handleScroll);
  }

  fetchInitial() {
    this.fetchBanner(this.fetchData);
  }

  successServiceHandler(response2, onThen) {
    const maxNbProjects = response2.data.nb_projects;
    this.setState({
      maxNbProjects: maxNbProjects,
      currentPlan: response2.data.plan,
    });
    if (response2.status === "error") {
      const message =
        maxNbProjects > 1 ? (
          <Trans i18nKey="hit_free_projects_limit">
            You reached your {{ maxNbProjects }} projects limit. Go to{" "}
            <Link
              to={ROUTES.PLAN_CHOOSE}
              className="text-decoration-underline color-fff"
            >
              upgrade your plan
            </Link>{" "}
            to create more projects.
          </Trans>
        ) : (
          <Trans i18nKey="hit_free_project_limit">
            You reached your {{ maxNbProjects }} project limit. Go to{" "}
            <Link
              to={ROUTES.PLAN_CHOOSE}
              className="text-decoration-underline color-fff"
            >
              upgrade your plan
            </Link>{" "}
            to create more projects.
          </Trans>
        );
      this.setState({
        orangeHeaderMessage: (
          <Fragment>
            <span className="icon plan-lock-key margin-right-5" />
            <span className="display-inline-block vertical-align-middle">
              {message}
            </span>
          </Fragment>
        ),
      });
    } else {
      this.setState({
        orangeHeaderMessage: null,
      });
    }
    if (typeof onThen == "function") {
      onThen();
    }
  }

  displayToastErrorMessage(error, onThen, updatedState) {
    if (!this._ismounted) {
      return;
    }
    this.props.addToast(HttpHelper.getErrorMessage(error), {
      type: "error",
      autoDismiss: true,
    });
    if (typeof onThen == "function") {
      onThen();
    }
    if (updatedState) {
      this.setState(updatedState);
    }
  }

  fetchBanner(onThen) {
    planJsonLogicService.playRule(
      PLAN_JSON_LOGIC_KEYS.DOWNGRADE_PLAN_EXPIRATION,
      {},
      (response) => {
        if (response.status === "error") {
          const oldPlan = getAccountPlan(response.data.old_plan);
          const message = (
            <Trans i18nKey="hit_free_project_limit">
              Your {{ oldPlan }} plan has been downgraded. Go to{" "}
              <Link
                to={ROUTES.PLAN_CHOOSE}
                className="text-decoration-underline color-fff"
              >
                upgrade your plan
              </Link>{" "}
              to continue accessing any additional projects.
            </Trans>
          );
          this.setState({
            orangeHeaderMessage: (
              <Fragment>
                <span className="icon plan-lock-key margin-right-5" />
                <span className="display-inline-block vertical-align-middle">
                  {message}
                </span>
              </Fragment>
            ),
          });
          if (typeof onThen == "function") {
            onThen();
          }
        } else {
          planJsonLogicService.playRule(
            PLAN_JSON_LOGIC_KEYS.CREATE_PROJECT,
            {},
            (response2) => {
              this.successServiceHandler(response2, onThen);
            },
            (error) => {
              this.displayToastErrorMessage(error, onThen);
            }
          );
        }
      },
      (error) => {
        this.displayToastErrorMessage(error, onThen);
      }
    );
  }

  fetchData(data, resetListItem, search = false) {
    data = data || {};
    if (
      this.state.isLoaded &&
      !resetListItem &&
      this.state.listItems.length >= this.state.totalItems
    ) {
      return;
    }
    if (this._ismounted) {
      this.setState({ inlineLoading: true });
    }
    let newFormData = Object.assign(this.state.formData, data);
    let account = UserHelper.getCurrentAccount();
    newFormData.account_id = account.id;
    projectService.get.getAll(
      newFormData,
      (response) => {
        const newListItems = resetListItem
          ? response.data
          : CollectionHelper.concatWithoutDuplicate(
              this.state.listItems,
              response.data
            );
        newFormData.page = response.meta.page;
        newFormData.page_size = response.meta.page_size;
        this.setState({
          formData: newFormData,
          isLoaded: true,
          inlineLoading: false,
          listItems: newListItems,
          totalItems: response.meta.total,
          displayEndOfList:
            response.data.length === 0 && response.meta.page > 1,
        });

        if (search) {
          this.setState({
            searchHaveResult: response.data.length > 0 ? true : false,
          });
        }
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.setState({
          formData: newFormData,
          isLoaded: true,
          inlineLoading: false,
        });
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      }
    );
  }

  handleScroll() {
    const bottom =
      Math.ceil(window.innerHeight + window.scrollY) >=
      document.documentElement.scrollHeight;

    if (bottom && !this.state.inlineLoading) {
      if (this.state.listItems.length == this.state.totalItems) {
        this.setState({
          displayEndOfList: true,
        });
        return;
      }

      if (!this.state.displayEndOfList && this.state.listItems.length > 0) {
        const infos = {
          page: this.state.formData.page + 1,
        };

        this.fetchData(infos);
      }
    }
  }

  onChangeSortList(infos) {
    const currentInfos = {
      sorted_by: this.state.formData.sorted_by,
      add_archived: this.state.formData.add_archived,
    };
    if (JSON.stringify(infos) === JSON.stringify(currentInfos)) {
      return false;
    }
    infos.page = 1;
    if (infos.sorted_by) {
      SessionStorageService.set("project_list_sorted_by", infos.sorted_by);
    }
    if (infos.add_archived) {
      SessionStorageService.set(
        "project_list_add_archived",
        infos.add_archived
      );
    }
    this.setState({ listItems: [] });
    this.fetchData(infos, true, true);
  }

  onChangeSearch(search) {
    clearTimeout(this.searchTimeout);
    const infos = {
      sorted_by: this.state.formData.sorted_by,
      add_archived: this.state.formData.add_archived,
      search: search,
      page: 1,
    };
    this.setState({ listItems: [] });
    this.searchTimeout = setTimeout(() => {
      this.fetchData(infos, true, true);
    }, 500);
  }

  onOpenDeleteModal(itemId) {
    const item = CollectionHelper.findOneById(this.state.listItems, itemId);
    if (item) {
      this.setState({
        delete: {
          display: true,
          item: item,
          loading: false,
          error: null,
        },
      });
    }
  }

  onDeleteItem() {
    let deleteData = Object.assign({}, this.state.delete);
    if (deleteData.item) {
      deleteData.loading = true;
      this.setState({
        delete: deleteData,
      });
      projectService.delete.delete(
        deleteData.item.id,
        (response) => {
          this.props.addToast(
            'Project "' +
              this.state.delete.item.name +
              '" successfully deleted',
            { type: "success", autoDismiss: true }
          );
          const newListItems = CollectionHelper.removeOneById(
            this.state.listItems,
            deleteData.item.id
          );
          const newTotalItem = this.state.totalItems - 1;
          this.setState({
            delete: {
              display: false,
              item: null,
              loading: false,
              error: null,
            },
            listItems: newListItems,
            totalItems: newTotalItem,
          });
          this.fetchBanner();
        },
        (error) => {
          if (!this._ismounted) {
            return;
          }
          deleteData.loading = false;
          this.setState({
            delete: deleteData,
          });
          this.props.addToast(HttpHelper.getErrorMessage(error), {
            type: "error",
            autoDismiss: true,
          });
        }
      );
    }
  }

  onArchiveItem(itemId, onThen) {
    const item = CollectionHelper.findOneById(this.state.listItems, itemId);
    if (item) {
      let newListItems = CollectionHelper.updateOneById(
        this.state.listItems,
        itemId,
        { isArchived: true }
      );
      let newTotalItem = this.state.totalItems;
      if (!this.state.formData.add_archived) {
        newListItems = CollectionHelper.removeOneById(newListItems, itemId);
        newTotalItem -= 1;
      }
      this.setState({
        listItems: newListItems,
        totalItems: newTotalItem,
      });
      projectService.put.archive(
        itemId,
        (success) => {
          this.props.addToast(
            'Project "' + item.name + '" successfully archived',
            { type: "success", autoDismiss: true }
          );
          if (typeof onThen === "function") {
            onThen();
          }
        },
        (error) => {
          this.displayToastErrorMessage(error, onThen);
        }
      );
    } else {
      if (typeof onThen === "function") {
        onThen();
      }
    }
  }

  unArchiveItemSuccessHandler(response, itemId, item, onThen) {
    const displayUpgradeCreateProjectModale = response.status === "error";
    this.setState({
      maxNbProjects: response.data.nb_projects,
      currentPlan: response.data.plan,
      displayUpgradeCreateProjectModale: displayUpgradeCreateProjectModale,
      loadingCreateProjectButton: false,
    });
    if (response.status === "success") {
      projectService.put.unarchive(
        itemId,
        (newItem) => {
          let newListItems = CollectionHelper.updateOneById(
            this.state.listItems,
            itemId,
            newItem
          );
          this.setState({
            listItems: newListItems,
          });
          this.props.addToast(
            'Project "' + item.name + '" successfully unarchived',
            { type: "success", autoDismiss: true }
          );
          if (typeof onThen === "function") {
            onThen();
          }
        },
        (error) => {
          this.displayToastErrorMessage(error, onThen);
        }
      );
    } else {
      if (typeof onThen === "function") {
        onThen();
      }
    }
  }

  onUnArchiveItem(itemId, onThen) {
    const item = CollectionHelper.findOneById(this.state.listItems, itemId);
    if (item) {
      planJsonLogicService.playRule(
        PLAN_JSON_LOGIC_KEYS.CREATE_PROJECT,
        {},
        (response) => {
          this.unArchiveItemSuccessHandler(response, itemId, item, onThen);
        },
        (error) => {
          this.displayToastErrorMessage(error, null, {
            loadingCreateProjectButton: false,
          });
        }
      );
    } else {
      if (typeof onThen === "function") {
        onThen();
      }
    }
  }

  onCloseDeleteModal() {
    this.setState({
      delete: {
        display: false,
        item: null,
        loading: false,
        error: null,
      },
    });
  }

  onCloseUpgradePlanToCreateProjectModal() {
    this.setState({
      displayUpgradeCreateProjectModale: false,
    });
    this.props.history.replace({ pathname: ROUTES.HOME });
  }

  onClickCreateProjectButton() {
    this.setState({ loadingCreateProjectButton: true });
    planJsonLogicService.playRule(
      PLAN_JSON_LOGIC_KEYS.CREATE_PROJECT,
      {},
      (response) => {
        const displayUpgradeCreateProjectModale = response.status === "error";
        this.setState({
          maxNbProjects: response.data.nb_projects,
          displayUpgradeCreateProjectModale: displayUpgradeCreateProjectModale,
          loadingCreateProjectButton: false,
        });
        if (response.status === "success") {
          this.props.history.push(ROUTES.CREATE_PROJECT);
        }
      },
      (error) => {
        this.displayToastErrorMessage(error, null, {
          loadingCreateProjectButton: false,
        });
      }
    );
  }

  render() {
    const { t } = this.props;
    let page_title = `${t("Projects")} - ${globalConstants.WEBSITE_TITLE}`;

    if (this.state.isLoaded) {
      return (
        <Fragment>
          <Header
            isProject={true}
            orangeHeaderMessage={this.state.orangeHeaderMessage}
          />
          <Helmet>
            <title>{page_title}</title>
          </Helmet>
          {this.state.totalItems <= 0 &&
            !this.state.formData.search &&
            !this.state.inlineLoading && (
              <EmptyState
                onClickCreateProjectButton={this.onClickCreateProjectButton}
                loadingCreateProjectButton={
                  this.props.loadingCreateProjectButton
                }
              />
            )}
          {(this.state.totalItems > 0 || this.state.formData.search) && (
            <ProjectsList
              inlineLoading={this.state.inlineLoading}
              listItems={this.state.listItems}
              defaultSortedBy={this.state.formData.sorted_by}
              onChangeSortList={this.onChangeSortList}
              onChangeSearch={this.onChangeSearch}
              total={this.state.totalItems}
              onOpenDeleteModal={this.onOpenDeleteModal}
              onArchiveItem={this.onArchiveItem}
              onUnArchiveItem={this.onUnArchiveItem}
              onClickCreateProjectButton={this.onClickCreateProjectButton}
              loadingCreateProjectButton={this.props.loadingCreateProjectButton}
            />
          )}

          {!this.state.searchHaveResult && <NoResult />}

          {this.state.displayEndOfList && <PageEnd />}
          {this.state.totalItems > 0 && <Footer />}
          {this.state.delete.display && (
            <DeleteModal
              itemValue={
                this.state.delete.item ? this.state.delete.item.name : null
              }
              title={t("Delete project")}
              subtitle={t("You are going to delete the project")}
              inputLabel={t("Enter project name to confirm")}
              buttonLabel={t("Delete project")}
              onClose={this.onCloseDeleteModal}
              loading={this.state.delete.loading}
              onDeleteItem={this.onDeleteItem}
            />
          )}
          {this.state.displayUpgradeCreateProjectModale && (
            <UpgradePlanToCreateProjectModal
              onClose={this.onCloseUpgradePlanToCreateProjectModal}
              maxNbProjects={this.state.maxNbProjects}
              currentPlan={this.state.currentPlan}
            />
          )}
        </Fragment>
      );
    }
    return (
      <LoadingView
        title={page_title}
        withHeader={true}
        headerProps={{ isProject: true }}
      />
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withTranslation()(connect(mapStateToProps)(HomeView));
