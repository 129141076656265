import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";
import Loader from "../../../components/loader/Loader";
import InformationsAmazonUrl from "./InformationsAmazonUrl";
import { TextField } from "@mui/material";

class FormWebsiteUrl extends React.Component {
  render() {
    const {
      formData,
      handleChangeUrl,
      urlLoading,
      placeHolder,
      urlData,
      onCloseInformations,
      displayInformationUrl,
    } = this.props;
    const informationsData = urlData;
    return (
      <Fragment>
        <div className="padding-top-20" />
        <div className="position-relative padding-bottom-15">
          <TextField
            placeholder={placeHolder}
            value={formData.url}
            onChange={handleChangeUrl}
            type="text"
            hiddenLabel={true}
            name="url"
            disabled={urlLoading}
            sx={{ width: "100%" }}
          />
        </div>
        {urlLoading && (
          <div className="text-align-center position-relative padding-vertical-30">
            <Loader />
          </div>
        )}
        {!urlLoading && urlData && displayInformationUrl && (
          <InformationsAmazonUrl
            selectedProductSource={this.props.selectedProductSource}
            data={informationsData}
            onCloseClick={onCloseInformations}
          />
        )}
      </Fragment>
    );
  }
}

export default withTranslation()(FormWebsiteUrl);
