import React, { Fragment } from "react";
import "../header.css";
import { withTranslation } from "react-i18next";
import UserHelper from "../../../app/helpers/UserHelper";
import { ROUTES } from "../../../app/routing";
import Link from "../../Link";

class ProfileMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accounts_are_opened: false,
    };
    this.updateCurrentAccount = this.updateCurrentAccount.bind(this);
    this.onClickAccount = this.onClickAccount.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  updateCurrentAccount(account) {
    UserHelper.updateCurrentAccount(account);
    window.location.href = ROUTES.HOME;
  }

  onClickAccount() {
    this.setState({
      accounts_are_opened: !this.state.accounts_are_opened,
    });
  }

  onMouseLeave() {
    this.setState({
      accounts_are_opened: false,
    });
  }

  render() {
    const { t } = this.props;
    const user = UserHelper.getUser();
    if (!user) {
      return null;
    }
    let pictureStyle = null;
    let pictureClasses = ["picture", "transition-color"];
    let pictureContent = "";
    if (user.profile_picture) {
      pictureStyle = { backgroundImage: `url(${user.profile_picture})` };
    } else {
      pictureClasses.push("colored-round-user");
      pictureClasses.push("font-inter-500");
      pictureClasses.push(user.color);
      pictureContent = user.initial;
    }
    const currentAccount = UserHelper.getCurrentAccount();
    const accounts = UserHelper.getAllUserAccounts();
    const userIsAdmin = UserHelper.isAdmin();
    let classLinkAccount = ["link-account"];
    if (accounts.length > 1) {
      classLinkAccount.push("multi-account");
    }
    if (this.state.accounts_are_opened) {
      classLinkAccount.push("opened");
    }
    return (
      <div
        className="header-menu profile-menu"
        onMouseLeave={this.onMouseLeave}
      >
        <div className="displayed">
          <span className={pictureClasses.join(" ")} style={pictureStyle}>
            {pictureContent}
          </span>
          <span className="mini-arrow" />
        </div>
        <div className="submenu transition">
          <div className="content-user padding-horizontal-16 padding-bottom-4">
            <div className={pictureClasses.join(" ")} style={pictureStyle}>
              {pictureContent}
            </div>
            <div className="infos">
              <div className="font-size-18 line-height-1-2 color-000 font-inter-600 text-ellipsis">
                {user.firstname + " " + user.lastname}
              </div>
              <div className="font-size-15 line-height-1-2 color-7f7f7f font-inter-400 text-ellipsis">
                {user.email}
              </div>
            </div>
          </div>
          <ul className="padding-0">
            <li className="separator full"></li>
            <li className={classLinkAccount.join(" ")}>
              <div
                className="padding-horizontal-16 display-block text-decoration-none cursor-default content-switch-account"
                onClick={this.onClickAccount}
              >
                <span className="display-block font-size-16 color-000 font-inter-600">
                  {currentAccount && currentAccount.title}
                </span>
                <span className="display-block font-size-13 color-7f7f7f font-inter-400">
                  {currentAccount && (currentAccount.id + "").padStart(7, "0")}
                </span>
                <span className="icon transition" />
              </div>
              {accounts.length > 1 && (
                <div className="content-account-switcher">
                  <div className="account-switcher transition-opacity">
                    <div className="title font-inter-600 color-000 font-size-15">
                      All accounts
                    </div>
                    <div className="separator" />
                    <div className="content-accounts">
                      {accounts.map((item, i) => {
                        return (
                          <div
                            key={`account-${item.id}`}
                            className="account font-inter-400 font-size-15 cursor-pointer color-000 text-ellipsis transition-color"
                            onClick={() => {
                              this.updateCurrentAccount(item);
                            }}
                          >
                            {item.title}: {(item.id + "").padStart(7, "0")}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </li>
            {user.user_guide_percent_completion !== 100 && (
              <Fragment>
                <li className="separator"></li>
                <li className="setup-account">
                  <Link
                    to={ROUTES.SETTINGS_USER_GUIDE}
                    className="cursor-pointer font-inter-400"
                  >
                    <div className="padding-horizontal-16 font-size-16 color-000 font-inter-400 padding-top-7 line-height-1">
                      {t("Set up your Sensia account")}
                    </div>
                    <div className="padding-horizontal-16 padding-top-10 padding-bottom-12 line-height-1-2">
                      <div className="padding-bottom-4">
                        <div className="overflow-hidden font-inter-400 font-size-13 color-7f7f7f">
                          <div className="float-left">{t("Progress")}</div>
                          <div className="float-right">
                            {user.user_guide_percent_completion}%
                          </div>
                        </div>
                      </div>
                      <div className="content-progress-bar">
                        <div
                          className="progress-bar"
                          style={{
                            width: `${user.user_guide_percent_completion}%`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </Link>
                </li>
              </Fragment>
            )}
            <li className="separator"></li>
            <li className="transition-color">
              <Link
                to={ROUTES.SETTINGS_PROFILE_AND_PREFERENCES}
                className="cursor-pointer font-inter-400 font-size-16 menu-action"
              >
                {t("Profile & Preferences")}
              </Link>
            </li>
            {userIsAdmin && (
              <Fragment>
                <li className="transition-color">
                  <Link
                    to={ROUTES.SETTINGS_DEFAULT_ACCOUNT}
                    className="cursor-pointer font-inter-400 font-size-16 menu-action"
                  >
                    {t("Default Account Settings")}
                  </Link>
                </li>
                <li className="transition-color">
                  <Link
                    to={ROUTES.SETTINGS_USERS_AND_TEAMS_BASE}
                    className="cursor-pointer font-inter-400 font-size-16 menu-action"
                  >
                    {t("Users & Teams")}
                  </Link>
                </li>
                <li className="transition-color">
                  <Link
                    to={ROUTES.SETTINGS_PLAN_AND_BILLING_BASE}
                    className="cursor-pointer font-inter-400 font-size-16 menu-action"
                  >
                    {t("Plan & Billing")}
                  </Link>
                </li>
              </Fragment>
            )}
            <li className="separator"></li>
            <li className="transition-color">
              <a
                href="/logout"
                className="cursor-pointer font-inter-400 font-size-16 menu-action"
              >
                <span className="color-e04f77">{t("Sign out")}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ProfileMenu);
