export const HOME = () => `/`;
export const PROJECT_DETAIL = (id) => `/project/${id}`;
export const PROJECT_WORKSPACE = (id) => `/project/${id}?tab=inspect`;
export const PROJECT_WORKSPACE_BY_KEYWORD = (id, keyword) =>
  `/project/${id}?tab=inspect&keyword=${keyword}`;
export const PROJECT_WORKSPACE_BY_KEYWORDS = (id, keywords) =>
  `/project/${id}?tab=inspect&keywords=${keywords.join(",")}`;
export const PROJECT_MEDIA_WORKSPACE_BY_KEYWORD = (id, keyword) =>
  `/project/${id}?tab=inspect&keyword=${keyword}`;
export const WORKSPACE_BY_KEYWORDS = (projectId, datasourceId, keywords) => {
  if (datasourceId) {
    if (Array.isArray(keywords)) {
      return `/project/${projectId}?tab=inspect&datasourceId=${datasourceId}&keywords=${keywords.join(
        ","
      )}`;
    }
    return `/project/${projectId}?tab=inspect&datasourceId=${datasourceId}&keywords=${keywords}`;
  }
  if (Array.isArray(keywords)) {
    return `/project/${projectId}?tab=inspect&keywords=${keywords.join(",")}`;
  }
  return `/project/${projectId}?tab=inspect&keyword=${keywords}`;
};
export const PROJECT_REPORT = (id) => `/project/${id}?tab=indepth`;
export const PROJECT_INSIGHTS = (id) => `/project/${id}/indepth/insights`;
export const PROJECT_NEW_INSIGHTS = (id) => `/project/${id}?tab=insights`;
export const PROJECT_EDIT = (id) => `/project/${id}/edit`;
export const PROJECT_REFINE = (id) => `/project/${id}/refine`;
export const PROJECT_SEARCH_MEDIA = (id) => `/project/${id}/search-datasource`;
export const PROJECT_ADD_MEDIA = (id) => `/project/${id}/add-datasource`;
export const PROJECT_ADD_MEDIA_CONFIRM = (id) =>
  `/project/${id}/add-datasource/confirm`;
export const PROJECT_ADD_MEDIA_SELECT = (id) =>
  `/project/${id}/add-datasource/select`;
export const PROJECT_ADD_MEDIA_SELECT_FIELDS = (id) =>
  `/project/${id}/add-datasource/select-fields`;
export const PROJECT_MEDIA_REPORT = (id, datasourceId) =>
  `/project/${id}?tab=indepth&datasourceId=${datasourceId}`;
export const PROJECT_MEDIA_INSIGHTS = (id, datasourceId) =>
  `/project/${id}/datasource/${datasourceId}/testinsights`;
export const PROJECT_MEDIA_NEW_INSIGHTS = (id, datasourceId) =>
  `/project/${id}?tab=insights&datasourceId=${datasourceId}`;
export const PROJECT_MEDIA_WORKSPACE = (id, datasourceId, classId) =>
  `/project/${id}?tab=inspect&datasourceId=${datasourceId}`;
export const PROJECT_MEDIA_WORKSPACE_CLASS = (id, datasourceId, classId) =>
  `/project/${id}/datasource/${datasourceId}/inspect/${classId}`;
export const PROJECT_MEDIA_EDIT_CAPTION = (id, datasourceId) =>
  `/project/${id}/datasource/${datasourceId}/edit-caption`;
export const CLASS_EDIT = (id, classId) =>
  `/project/${id}/class/${classId}/edit`;
export const CLASS_ADD = (id, classId) => `/project/${id}/class/add`;
export const SETTINGS_USERS_AND_TEAMS = (tab) =>
  `/settings/users-and-teams/${tab}`;
export const MODEL_SETTINGS = (tab) => `/models/settings/${tab}`;
export const SETTINGS_PLAN_AND_BILLING = (tab) =>
  `/settings/plan-and-billing/${tab}`;
export const BILLING_OVERVIEW = (tab) => `/settings/plan-and-billing/${tab}`;
export const PROJECT_LOGIN_INVITATION = (token) => `/login?token=${token}`;
export const ADD_TOKEN_TO_ROUTE = (route, token) => {
  if (token) {
    return `${route}?token=${token}`;
  }
  return route;
};
export const PLAN_DOWNGRADE = (target_plan) =>
  `/plan/downgrade/${target_plan.toLowerCase()}`;
export const PLAN_DOWNGRADE_RESULT = (target_plan) =>
  `/plan/downgrade-result/${target_plan.toLowerCase()}`;
export const PLAN_UGRAPDE = (target_plan) =>
  `/plan/upgrade/${target_plan.toLowerCase()}`;
export const PLAN_PURCHASE = (target_plan) =>
  `/plan/purchase/${target_plan.toLowerCase()}`;
export const PLAN_PURCHASE_RESULT = (target_plan) =>
  `/plan/purchase-result/${target_plan.toLowerCase()}`;

export const BACK_OFFICE_USER_SHOW = (id) => `/admin/users/${id}/show`;
export const BACK_OFFICE_ACCOUNT_SHOW = (id) => `/admin/accounts/${id}/show`;

export const ROUTES = {
  OOPS: "/oops", // 404 error
  ARF: "/arf", // 500 error
  REDIRECT: "/r",
  UNAUTHORIZED: "/unauthorized",
  OPTIMIZE_MODEL: "/optimize-model",
  HOME: "/",
  LOGIN: "/login",
  LOGOUT: "/logout",
  FORGOT_PASSWORD: "/forgot-password",
  FORGOT_PASSWORD_CHECK_YOUR_EMAIL: "/forgot-password/check-your-email",
  FORGOT_PASSWORD_SET_NEW: "/forgot-password/set-new-password",
  SIGN_UP: "/sign-up",
  CREATE_ACCOUNT: "/create-account",
  SIGN_UP_STEP_1: "/sign-up/step-1",
  SIGN_UP_STEP_2: "/sign-up/step-2",
  SIGN_UP_STEP_3: "/sign-up/step-3",
  SIGN_UP_STEP_4: "/sign-up/step-4",
  REGISTER_INVITATION: "/invitation",
  CATALOG: "/catalog",
  MODELS_SCORE: "/models/score",
  MODELS_EMOTION: "/models/emotion",
  MODELS_ANALYSE: "/models/analyse",
  MODEL_SETTINGS: "/models/settings",
  MODELS: "/models",
  BACK_OFFICE: "/admin",
  CREATE_PROJECT: "/project/create",
  PROJECT_DETAIL: "/project/:id",
  PROJECT_WORKSPACE: "/project/:id/inspect",
  PROJECT_EDIT: "/project/:id/edit",
  PROJECT_SEARCH_MEDIA: "/project/:id/search-datasource",
  PROJECT_ADD_MEDIA: "/project/:id/add-datasource",
  PROJECT_ADD_MEDIA_CONFIRM: "/project/:id/add-datasource/confirm",
  PROJECT_ADD_MEDIA_SELECT: "/project/:id/add-datasource/select",
  PROJECT_ADD_MEDIA_SELECT_FIELDS: "/project/:id/add-datasource/select-fields",
  PROJECT_REFINE: "/project/:id/refine",
  PROJECT_MEDIA_REPORT: "/project/:id/datasource/:datasource_id/indepth",
  PROJECT_MEDIA_INSIGHTS: "/project/:id/datasource/:datasource_id/testinsights",
  PROJECT_MEDIA_NEW_INSIGHTS: "/project/:id/datasource/:datasource_id/insights",
  PROJECT_MEDIA_WORKSPACE: "/project/:id/datasource/:datasource_id/inspect",
  PROJECT_MEDIA_WORKSPACE_CLASS:
    "/project/:id/datasource/:datasource_id/inspect/:class_id",
  PROJECT_REPORT: "/project/:id/indepth",
  PROJECT_INSIGHTS: "/project/:id/indepth/insights",

  PROJECT_NEW_INSIGHTS: "/project/:id/insights",

  PROJECT_MEDIA_EDIT_CAPTION:
    "/project/:id/datasource/:datasource_id/edit-caption",
  CLASS_EDIT: "/project/:id/class/:class_id/edit",
  CLASS_ADD: "/project/:id/class/add",
  SETTINGS_PROFILE_AND_PREFERENCES: "/settings/profile-and-preferences",
  SETTINGS_DEFAULT_ACCOUNT: "/settings/default-account",
  SETTINGS_USERS_AND_TEAMS_BASE: "/settings/users-and-teams",
  SETTINGS_USERS_AND_TEAMS: "/settings/users-and-teams/:tab",
  SETTINGS_PLAN_AND_BILLING_BASE: "/settings/plan-and-billing",
  SETTINGS_PLAN_AND_BILLING: "/settings/plan-and-billing/:tab",
  SETTINGS_PLAN_AND_BILLING_OVERVIEW: "/settings/plan-and-billing/overview",
  SETTINGS_USER_GUIDE: "/settings/user-guide",
  TERMS_OF_SERVICE: "https://sensia.ai/terms-of-service",
  PRIVACY_POLICY: "https://sensia.ai/privacy-policy",
  PLAN_CHOOSE: "/plan/choose",
  PLAN_CONTACT_YOUR_ADMINISTRATOR: "/plan/contact-your-administrator",
  TEST: "/test/graph-components",
  PLAN_UPGRADE: "/plan/upgrade/:target_plan",
  PLAN_DOWNGRADE: "/plan/downgrade/:target_plan",
  PLAN_DOWNGRADE_RESULT: "/plan/downgrade-result/:target_plan",
  PLAN_PURCHASE: "/plan/purchase/:target_plan",
  PLAN_PURCHASE_RESULT: "/plan/purchase-result/:target_plan",
  PLAN_PRICING_WS: "/api/v1/plan",
};
