import { useRecordContext } from "react-admin";

const MediaTypeField = ({ source }) => {
  const record = useRecordContext();
  const type = record && record[source];

  switch (type) {
    case "WEBSITE_URL":
      return "WEB";
    case "AMAZON_URL":
      return "AMAZON";
    case "TXT_FILE":
      return "TEXT";
    case "SHEET_FILE":
      return "SHEET";
    case "YOUTUBE":
      return type;
    default:
      return "Undefined type";
  }
};

export default MediaTypeField;
