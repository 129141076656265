import React, { useCallback } from "react";
import { Line, LineRadial } from "@visx/shape";
import { scaleLinear } from "@visx/scale";
import {
  Tooltip,
  defaultStyles,
  useTooltip,
} from "@visx/tooltip";
import { Group } from "@visx/group";
import ColoredScore from "../ui/ColoredScore";
import "./radarGraphComponents.css";
import { Point } from "@visx/point";
import { Text } from "@visx/text";

function RadarChart({
  data,
  name,
  legend,
  width,
  height,
  levels = 5,
  margin = { top: 40, left: 80, right: 80, bottom: 80 },
}) {

  const blue = "#3a79e6";
  const blueLight = "#072df624";
  const pumpkin = "#ff9378";
  const pumpkinLight = "#f1f1f1b8";
  const lineColor = "#eee";

  const degrees = 360;

  const tooltipName = (d) => d.name;
  const y = (d) => d.score;
  const yBis = (d) => d.industry;

  const genAngles = (length: number) =>
    [...new Array(length + 1)].map((_, i) => ({
      angle:
        i * (degrees / length) + (length % 2 === 0 ? 0 : degrees / length / 2),
    }));

  const genPoints = (length: number, radius: number) => {
    const step = (Math.PI * 2) / length;
    return [...new Array(length)].map((_, i) => ({
      x: radius * Math.sin(i * step),
      y: radius * Math.cos(i * step),
    }));
  };

  function genPolygonPoints<Datum>(
    dataArray: Datum[],
    scale: (n: number) => number,
    getValue: (d: Datum) => number
  ) {
    const step = (Math.PI * 2) / dataArray.length;
    const points: { x: number, y: number }[] = new Array(dataArray.length).fill(
      { x: 0, y: 0 }
    );
    const pointString: string = new Array(dataArray.length + 1)
      .fill("")
      .reduce((res, _, i) => {
        if (i > dataArray.length) return res;
        const xVal = scale(getValue(dataArray[i - 1])) * Math.sin(i * step);
        const yVal = scale(getValue(dataArray[i - 1])) * Math.cos(i * step);
        points[i - 1] = {
          x: xVal,
          y: yVal,
          name: dataArray[i - 1].name,
          date: dataArray[i - 1].date,
        };
        res += `${xVal},${yVal} `;

        return res;
      });

    return { points, pointString };
  }

  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;
  const radius = Math.min(xMax, yMax) / 1.85;
  const textRadius = Math.min(xMax, yMax) / 1.5;

  const radialScale = scaleLinear({
    range: [0, Math.PI * 2],
    domain: [degrees, 0],
  });

  const yScale = scaleLinear({
    range: [0, radius],
    domain: [ Math.min( -6, ...data.map(y)), Math.max(+6, ...data.map(y))],
  });

  const webs = genAngles(data.length);
  const points = genPoints(data.length, radius);
  const textPoints = genPoints(data.length, textRadius);
  const polygonPoints = genPolygonPoints(data, (d) => yScale(d) ?? 0, y);
  const polygonPointsBis = genPolygonPoints(data, (d) => yScale(d) ?? 0, yBis);
  const zeroPoint = new Point({ x: 0, y: 0 });

  const tooltipStyles = {
    ...defaultStyles,
    border: "none",
    color: "white",
    backgroundColor: "white",
    boxShadow:
      "0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 3px 5px -1px rgba(0, 0, 0, 0.2)",
  };

  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip();

  const handleMouseOver = useCallback(
    (coords, datum) => {
      showTooltip({
        tooltipLeft: coords.x,
        tooltipTop: coords.y,
        tooltipData: datum,
      });
    },
    [showTooltip]
  );

  const getTextAnchor = (x) => {
    let position = "middle";

    if (x > 0) {
      position = "start";
    } else if (x < 0) {
      position = "end";
    }

    return position;
  };

  return (
    <div
      id="radarGraphComponents"
      style={{ position: "relative", width: width + 40 }}
    >
      <div id="header" style={{ width: width }}>
        <h3 id="title">{name}</h3>
      </div>
      <svg width={width} height={height}>
        <rect
          x={0}
          y={0}
          width={width}
          height={height}
          fill={"transparent"}
          rx={14}
        />
        <Group top={height / 1.65 - margin.top} left={width / 2}>
          {[...new Array(levels)].map((_, i) => (
              <LineRadial
                key={`web-${i}`}
                data={webs}
                angle={(d) => radialScale(d.angle) ?? 0}
                radius={((i + 1) * radius) / levels}
                fill="none"
                stroke={lineColor}
                strokeWidth={1}
                strokeOpacity={0.8}
                strokeLinecap="round"
              />
          ))}
          {[...new Array(data.length)].map((_, i) => {
            return (
              <Group key={`radar-line-${i}-box`}>
                <Line
                  key={`radar-line-${i}`}
                  from={zeroPoint}
                  to={points[i]}
                  stroke={lineColor}
                />
                <Text
                  key={`radar-line-${i}-text`}
                  textAnchor={getTextAnchor(Math.trunc(points[i].x))}
                  verticalAnchor="middle"
                  dx={textPoints[i].x}
                  dy={textPoints[i].y}
                  width={Math.trunc(textPoints[i].x) !== 0 ? 130 : 200}
                  fill="#757575"
                >
                  {
                    data[data[i - 1] === undefined ? data.length - 1 : i - 1]
                      .name
                  }
                </Text>
              </Group>
            )})}
          <polygon
            points={polygonPointsBis.pointString}
            fill={pumpkinLight}
            fillOpacity={1}
            stroke={pumpkin}
            strokeWidth={1}
            strokeDasharray={4}
          />
          <polygon
            points={polygonPoints.pointString}
            fill={blueLight}
            fillOpacity={1}
            stroke={blue}
            strokeWidth={1}
          />

          {polygonPoints.points.map((point, i) => {
            return (
              <Group key={`group-${i}`}>
                <circle
                  key={`radar-point-${i}`}
                  cx={point.x}
                  cy={point.y}
                  r={4}
                  fill={"white"}
                />
                <circle
                  key={`radar-point-${i}-bis`}
                  cx={point.x}
                  cy={point.y}
                  r={3}
                  fill={blue}
                  onMouseOver={() => {
                    handleMouseOver(point, data[i]);
                  }}
                  onMouseOut={hideTooltip}
                />
              </Group>
            );
          })}
        </Group>
      </svg>

      {tooltipOpen && (
        <Tooltip
          key={Math.random()}
          top={tooltipTop + height / 1.75}
          left={tooltipLeft + width / 2.35}
          style={tooltipStyles}
        >
          <div id="box">
            <h3 className="font-size-18 color-000 font-inter-600">
              {tooltipName(tooltipData)}
            </h3>

            <div className="col-box margin-top-10">
              <div className="col-box-item">
                <h3 className="font-weight-500 color-7f7f7f">
                  {y(tooltipData) ? (
                    <ColoredScore score={y(tooltipData)} />
                  ) : (
                    "-"
                  )}
                </h3>
                <div className="font-size-13 color-7f7f7f padding-top-5">
                  {"Average score"}
                </div>
              </div>
            </div>
          </div>
        </Tooltip>
      )}

      <div id="legend" style={{ width: width }}>
        <div className="legend-item">
          <div id="project"></div>
          {legend.x}
        </div>
        {legend.aux_x && (
          <div className="legend-item">
            <div id="industry"></div>
            {legend.aux_x}
          </div>
        )}
      </div>
    </div>
  );
}

export default RadarChart;
