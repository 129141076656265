import { useEffect, useState } from "react";
export const useInputEvent = () => {
  const [key, setKey] = useState(null);
  const [keyCollection, setKeyCollection] = useState({
    codes: [],
    keys: []
  });
  useEffect(() => {
    const keyDownHandler = (event) => {
      const el = document.activeElement;
      if(el instanceof HTMLInputElement || el instanceof HTMLTextAreaElement){
        return;
      }
      setKey(event.keyCode);

      let newKeyCollection = Object.assign({}, keyCollection);
      newKeyCollection.codes.push(event.keyCode);
      newKeyCollection.keys.push(event.key);
      setKeyCollection(newKeyCollection);
    };
    const keyUpHandler = () => setKey(null);
    global.addEventListener('keydown', keyDownHandler);
    global.addEventListener('keyup', keyUpHandler);
    return () => {
      global.removeEventListener("keydown", keyDownHandler);
      global.removeEventListener("keyup", keyUpHandler)
    }
  }, []);
  return key;
}