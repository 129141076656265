import "../plan.css";
import "./upgradePlanView.css";
import React from "react";
import {
  ACCOUNT_PLAN,
  getAccountPlan,
  getAccountPlanFrequency,
  globalConstants,
  isAccountPlanValid,
  isValidUpgradePlan,
} from "../../../app/constants";
import { connect } from "react-redux";
import LoadingView from "../../loading/LoadingView";
import { withTranslation } from "react-i18next";
import { PLAN_PURCHASE_RESULT, ROUTES } from "../../../app/routing";

import HttpHelper from "../../../app/helpers/HttpHelper";
import UserHelper from "../../../app/helpers/UserHelper";

import { accountService } from "../../../app/services/account.service";
import { userService } from "../../../app/services/user.service";
import { SessionStorageService } from "../../../app/services/storage.service";
import { COUNTRY_OPTION } from "../../../components/selectFieldCountry/SelectFieldCountry";

import { billingService } from "../../../app/services/billing.service";

import { format } from "date-fns";
import add from "date-fns/add";

import icon_ame from "../../../res/images/icons/card_ame.png";
import icon_mastercard from "../../../res/images/icons/card_mastercard.png";
import icon_visa from "../../../res/images/icons/card_visa.png";
import icon_sepa from "../../../res/images/icons/banktransfer.svg";
import UpgradePlanStep1 from "./UpgradePlanStep1";
import UpgradePlanStep0 from "./UpgradePlanStep0";

class UpgradePlanView extends React.Component {
  constructor(props) {
    super(props);

    const targetPlan = getAccountPlan(props.match.params.target_plan);
    const urlParams = HttpHelper.getUrlParameters(props.location.search);
    const freq = urlParams.freq || "Monthly";
    const selectedFreq = getAccountPlanFrequency(freq);
    const hasDefaultFreq = urlParams.freq;
    this.state = {
      formData: {
        country: null,
        company: null,
        adress1: null,
        adress2: null,
        city: null,
        state: null,
        zip_code: null,
        is_business_purchase: "no",
        vat_id: null,
        discount_code: null,
      },
      canSwitchFreq: false,
      forcedPlanFreq: null,
      selectedFreq: selectedFreq,
      isFreqChanged: false,
      planData: {
        name: null,
        monthlyPrice: null,
        monthlyPriceId: null,
        monthlyCredit: null,
        monthlyVat: null,
        monthlyTTC: null,
        yearlyPrice: null,
        yearlyPriceId: null,
        yearlyCredit: null,
        yearlyVat: null,
        yearlyTTC: null,
        currency_code: null,
      },
      currentPlan: {
        priceId: null,
        priceHT: null,
        credit: null,
        vat: null,
        priceTTC: null,
      },
      purchaseLoading: false,
      targetPlan: targetPlan,
      isLoaded: false,
      isPriceLoaded: false,
      inlineLoading: false,
      account: null,
      paymentMethodId: null,
      paymentMethodType: null,
      paymentMethodBrand: null,
      paymentMethodLast4: null,
      billing_step: 0,
      hasDefaultFreq: hasDefaultFreq,
      formErrors: {},
    };

    this.fetchInitial = this.fetchInitial.bind(this);
    this.fetchPlan = this.fetchPlan.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeCountry = this.handleChangeCountry.bind(this);
    this.handleChangeDiscount = this.handleChangeDiscount.bind(this);
    this.getErrorMessage = this.getErrorMessage.bind(this);
    this.onChangeFreq = this.onChangeFreq.bind(this);
    this.setCurrentPlan = this.setCurrentPlan.bind(this);
    this.handleSubmitSuccess = this.handleSubmitSuccess.bind(this);
    this.handleSubmitError = this.handleSubmitError.bind(this);
    this.purchasePlan = this.purchasePlan.bind(this);
    this.checkMandatoryFields = this.checkMandatoryFields.bind(this);
    this.onPurchasePlanSuccess = this.onPurchasePlanSuccess.bind(this);
    this.onPurchasePlanError = this.onPurchasePlanError.bind(this);
    this.getSelectedFrequence = this.getSelectedFrequence.bind(this);
    this.getFrequence = this.getFrequence.bind(this);
    this.nextRenewalDateHandler = this.nextRenewalDateHandler.bind(this);
    this.paymentIconSrcHandler = this.paymentIconSrcHandler.bind(this);
    this.goBackToStep0 = this.goBackToStep0.bind(this);
    this.handleErrorInitial = this.handleErrorInitial.bind(this);
    this.displayToastErrorMessage = this.displayToastErrorMessage.bind(this);

    const referrer =
      props.location.state && props.location.state.referrer
        ? props.location.state.referrer
        : null;
    if (!SessionStorageService.get("update_plan_referrer") && referrer) {
      SessionStorageService.set("update_plan_referrer", referrer);
    }

    if (!targetPlan || (targetPlan && !isAccountPlanValid(targetPlan))) {
      SessionStorageService.delete("update_plan_referrer");
      if (referrer) {
        props.history.push(referrer);
      } else {
        props.history.push(ROUTES.HOME);
      }
    }
  }

  componentDidMount() {
    this._ismounted = true;
    this.fetchInitial();
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  fetchInitial() {
    if (this._ismounted) {
      this.setState({ inlineLoading: true });
    }
    const isAdmin = UserHelper.isAdmin();
    if (!isAdmin) {
      this.props.history.push(ROUTES.PLAN_CONTACT_YOUR_ADMINISTRATOR);
    }
    accountService.getAccountData(
      (response) => {
        if (!isValidUpgradePlan(response, this.state.targetPlan)) {
          this.props.history.push(ROUTES.HOME);
          return;
        }

        let formData = Object.assign({}, this.state.formData);
        formData.country = response.country;
        formData.company = response.company;
        formData.adress1 = response.adress1;
        formData.adress2 = response.adress2;
        formData.city = response.city;
        formData.state = response.state;
        formData.zip_code = response.zip_code;
        formData.vat_id = response.vat_id;
        formData.account_id = response.id;
        formData.discount_code = response.discount_code;
        if (response.vat_id != null && response.vat_id != "") {
          formData.is_business_purchase = "yes";
        }
        this.setState({
          inlineLoading: false,
          account: response,
          formData: formData,
        });
        this.checkMandatoryFields(formData);

        this.fetchPlan();
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.setState({
          isLoaded: true,
          inlineLoading: false,
        });
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      }
    );
  }

  fetchPlan(billingStep = 0) {
    billingService.getPlan(
      this.state.targetPlan,
      this.state.formData.discount_code,
      (response) => {
        let planData = Object.assign({}, this.state.planData);

        let selectedFreq = this.state.selectedFreq;
        if (response.forced_plan_freq) {
          selectedFreq = getAccountPlanFrequency(response.forced_plan_freq);
        } else if (
          this.state.account.plan_freq &&
          !this.state.hasDefaultFreq &&
          getAccountPlan(this.state.account.plan_key) !== ACCOUNT_PLAN.FREE &&
          !this.state.isFreqChanged
        ) {
          selectedFreq = getAccountPlanFrequency(this.state.account.plan_freq);
        }
        planData.name = response.data.name;
        planData.monthlyPrice = response.data.monthly_price;
        planData.monthlyPriceId = response.data.monthly_priceid;
        planData.monthlyCredit = response.data.monthly_credit;
        planData.monthlyDiscount = response.data.monthly_discount;
        planData.monthlyDiscountCode = response.data.monthly_discount_code;
        planData.monthlyVat = response.data.monthly_vat;
        planData.monthlyTTC = response.data.monthly_ttc;

        planData.yearlyPrice = response.data.yearly_price;
        planData.yearlyPriceId = response.data.yearly_priceid;
        planData.yearlyCredit = response.data.yearly_credit;
        planData.yearlyDiscount = response.data.yearly_discount;
        planData.yearlyDiscountCode = response.data.yearly_discount_code;
        planData.yearlyVat = response.data.yearly_vat;
        planData.yearlyTTC = response.data.yearly_ttc;
        planData.currency_code = response.data.currency_code;

        if (response.error_message)
          this.props.addToast(
            HttpHelper.getErrorMessage(response.error_message),
            { type: "error", autoDismiss: false }
          );

        this.setState(
          {
            isPriceLoaded: true,
            planData: planData,
            canSwitchFreq: response.can_switch_freq,
            forcedPlanFreq: response.forced_plan_freq,
            selectedFreq: selectedFreq,
            billing_step: billingStep,
          },
          () => {
            this.setCurrentPlan(selectedFreq);
          }
        );
      },
      (error) => {
        this.setState({
          isLoaded: true,
          inlineLoading: false,
        });
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: false,
        });
      }
    );
  }

  setCurrentPlan(selectedFreq) {
    let currentPlan = Object.assign({}, this.state.currentPlan);
    currentPlan.priceId =
      selectedFreq == "Monthly"
        ? this.state.planData.monthlyPriceId
        : this.state.planData.yearlyPriceId;
    currentPlan.priceHT =
      selectedFreq == "Monthly"
        ? this.state.planData.monthlyPrice
        : this.state.planData.yearlyPrice;
    currentPlan.credit =
      selectedFreq == "Monthly"
        ? this.state.planData.monthlyCredit
        : this.state.planData.yearlyCredit;
    currentPlan.discount =
      selectedFreq == "Monthly"
        ? this.state.planData.monthlyDiscount
        : this.state.planData.yearlyDiscount;
    currentPlan.vat =
      selectedFreq == "Monthly"
        ? this.state.planData.monthlyVat
        : this.state.planData.yearlyVat;
    currentPlan.discountCode =
      selectedFreq == "Monthly"
        ? this.state.planData.monthlyDiscountCode
        : this.state.planData.yearlyDiscountCode;
    let priceTTC =
      selectedFreq == "Monthly"
        ? this.state.planData.monthlyTTC
        : this.state.planData.yearlyTTC;
    currentPlan.priceTTC = priceTTC.toFixed(2);

    this.setState({
      isLoaded: true,
      currentPlan: currentPlan,
    });
  }

  handleSubmitSuccess(payment_method) {
    this.setState({
      paymentMethodId: payment_method.data ? payment_method.data.id : null,
      paymentMethodType: payment_method.data ? payment_method.data.type : null,
      paymentMethodBrand: payment_method.data
        ? payment_method.data.brand
        : null,
      paymentMethodLast4: payment_method.data
        ? payment_method.data.last4
        : null,
      isLoaded: false,
    });

    const formData = Object.assign({}, this.state.formData);
    let current_account = UserHelper.getCurrentAccount();
    formData.account_id = current_account.id;
    formData.account_name = this.state.formData.company;
    accountService.editAccount(
      formData,
      (response) => {
        if (response) {
          this.setState({
            isPriceLoaded: false,
            account: response,
          });
          this.fetchPlan(1);
        }
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      }
    );
  }

  handleSubmitError(error) {
    console.error(error);
  }

  checkMandatoryFields(customFormData) {
    const { t } = this.props;
    const mandatoryFields = ["country", "company", "adress1", "city"];
    let hasNoError = true;
    let formErrors = {};
    const formData = customFormData || Object.assign({}, this.state.formData);
    for (const key of mandatoryFields) {
      if (!formData[key]) {
        formErrors[key] = t("This field is required");
        hasNoError = false;
      }
    }
    this.setState({
      formErrors: formErrors,
    });
    return hasNoError;
  }

  onPurchasePlanSuccess() {
    this.setState({ purchaseLoading: false });
    const urlParams = {
      status: "success",
      plan_freq: this.state.selectedFreq,
      start_date: format(new Date(), "yyyy-MM-dd"),
      amount: this.state.currentPlan.priceTTC,
      currency_code: this.state.planData.currency_code,
    };
    this.props.history.push(
      HttpHelper.addParametersToUrl(
        PLAN_PURCHASE_RESULT(this.state.targetPlan.toLowerCase()),
        urlParams
      ),
      { referrer: document.location.pathname }
    );
  }

  onPurchasePlanError(error) {
    this.setState({ purchaseLoading: false });
    const error_msg =
      HttpHelper.getErrorMessage(error) ||
      this.props.t("An unknown error appeared during your purchase.");
    this.props.addToast(error_msg, { type: "error", autoDismiss: true });
    const urlParams = {
      status: "error",
      plan_freq: this.state.selectedFreq,
      message: error_msg,
    };
    this.props.history.push(
      HttpHelper.addParametersToUrl(
        PLAN_PURCHASE_RESULT(this.state.targetPlan.toLowerCase()),
        urlParams
      ),
      { referrer: document.location.pathname }
    );
  }

  purchasePlan() {
    this.setState({ purchaseLoading: true });

    if (this.state.currentPlan.credit) {
      billingService.updateSubscribtion(
        this.state.currentPlan.priceId,
        this.state.paymentMethodId,
        this.state.targetPlan,
        this.state.currentPlan.discountCode,
        (response) => {
          this.onPurchasePlanSuccess();
        },
        (error) => {
          this.onPurchasePlanError(error);
        }
      );
    } else {
      billingService.createSubscribtion(
        this.state.currentPlan.priceId,
        this.state.paymentMethodId,
        this.state.targetPlan,
        this.state.currentPlan.discountCode,
        (response) => {
          this.onPurchasePlanSuccess();
        },
        (error) => {
          this.onPurchasePlanError(error);
        }
      );
    }
  }

  handleChangeCountry(event) {
    let formData = Object.assign({}, this.state.formData);
    formData.country = event.target.value;
    this.setState({
      formData: formData,
    });
    let current_account = UserHelper.getCurrentAccount();
    formData.account_id = current_account.id;
    accountService.editAccount(
      formData,
      (response) => {
        this.setState({
          inlineLoading: false,
        });
        this.fetchPlan();
        userService.updateCurrentUser();
      },
      (error) => {
        this.handleErrorInitial(error);
      }
    );
  }

  handleChangeDiscount(event) {
    let formData = Object.assign({}, this.state.formData);
    this.setState({
      formData: formData,
    });
    let current_account = UserHelper.getCurrentAccount();
    formData.account_id = current_account.id;
    accountService.editAccount(
      formData,
      (response) => {
        this.setState({
          inlineLoading: false,
        });
        this.fetchPlan();
        userService.updateCurrentUser();
      },
      (error) => {
        this.handleErrorInitial(error);
      }
    );
  }

  handleErrorInitial(error) {
    this.displayToastErrorMessage(error, {
      isLoaded: true,
      inlineLoading: false,
    });
  }

  displayToastErrorMessage(error, updatedState) {
    if (!this._ismounted) {
      return;
    }
    this.props.addToast(HttpHelper.getErrorMessage(error), {
      type: "error",
      autoDismiss: true,
    });
    if (updatedState) {
      this.setState(updatedState);
    }
  }

  handleChange(event) {
    let formData = Object.assign({}, this.state.formData);
    formData[event.target.name] = event.target.value;
    this.checkMandatoryFields(formData);
    this.setState({
      formData: formData,
    });
  }

  onChangeFreq(infos) {
    this.setState({
      selectedFreq: infos.frequence,
      isFreqChanged: true,
    });

    this.setCurrentPlan(infos.frequence);
  }

  getErrorMessage(target) {
    if (this.state.formErrors && this.state.formErrors.hasOwnProperty(target)) {
      return this.state.formErrors[target];
    }
    return null;
  }

  getCountryLabelFromIsoCode(isoCode) {
    let option = COUNTRY_OPTION.filter((opt) => {
      return opt.value === isoCode;
    });
    return option[0].label;
  }

  getFrequence() {
    const { t } = this.props;
    let frequence = [];

    if (this.state.canSwitchFreq) {
      frequence = [
        {
          label: t("Billed monthly"),
          value: "Monthly",
          activeLabel: t("Billed monthly"),
        },
        {
          label: t("Billed yearly"),
          value: "Yearly",
          activeLabel: t("Billed yearly"),
        },
      ];
    } else {
      frequence = [
        {
          label: t("Billed yearly"),
          value: "Yearly",
          activeLabel: t("Billed yearly"),
        },
      ];
    }
    return frequence;
  }

  getSelectedFrequence() {
    const { t } = this.props;
    return this.state.selectedFreq === "Monthly"
      ? t("Billed monthly")
      : t("Billed yearly");
  }

  nextRenewalDateHandler() {
    return add(new Date(), {
      years: this.state.selectedFreq === "Monthly" ? 0 : 1,
      months: this.state.selectedFreq === "Monthly" ? 1 : 0,
    });
  }

  paymentIconSrcHandler() {
    let paymentIconSrc = null;
    if (this.state.paymentMethodType === "sepa_debit") {
      paymentIconSrc = icon_sepa;
    } else if (this.state.paymentMethodBrand === "amex") {
      paymentIconSrc = icon_ame;
    } else if (this.state.paymentMethodBrand === "mastercard") {
      paymentIconSrc = icon_mastercard;
    } else if (this.state.paymentMethodBrand === "visa") {
      paymentIconSrc = icon_visa;
    }
    return paymentIconSrc;
  }

  goBackToStep0() {
    this.setState({ billing_step: 0 });
  }

  render() {
    const { t } = this.props;
    let page_title = `${t("Upgrade to {{plan_libelle}} Plan", {
      plan_libelle: this.state.targetPlan,
    })} - ${globalConstants.WEBSITE_TITLE}`;
    let defaultOptions = COUNTRY_OPTION.filter((option) => {
      return option.value === this.state.formData.country;
    });
    if (
      !this.state.isLoaded ||
      !this.state.isPriceLoaded ||
      ![0, 1].includes(this.state.billing_step)
    ) {
      return <LoadingView title={page_title} />;
    }
    if (this.state.billing_step === 0) {
      let frequence = this.getFrequence();
      return (
        <UpgradePlanStep0
          pageTitle={page_title}
          history={this.props.history}
          targetPlan={this.state.targetPlan}
          currentPlan={this.state.currentPlan}
          forcedPlanFreq={this.state.forcedPlanFreq}
          selectedFreq={this.state.selectedFreq}
          onChangeFreq={this.onChangeFreq}
          frequence={frequence}
          defaultOptions={defaultOptions}
          formData={this.state.formData}
          handleChangeCountry={this.handleChangeCountry}
          handleChangeDiscount={this.handleChangeDiscount}
          handleChange={this.handleChange}
          inlineLoading={this.state.inlineLoading}
          account={this.state.account}
          checkMandatoryFields={this.checkMandatoryFields}
          handleSubmitSuccess={this.handleSubmitSuccess}
          handleSubmitError={this.handleSubmitError}
          addToast={this.props.addToast}
          formErrors={this.state.formErrors}
          currencyCode={this.state.planData.currency_code}
        />
      );
    } else if (this.state.billing_step === 1) {
      const nextRenewalDate = this.nextRenewalDateHandler();
      let paymentIconSrc = this.paymentIconSrcHandler();
      const paymentIcon = (
        <img
          className="vertical-align-middle"
          alt=""
          src={paymentIconSrc}
          height="20px"
          style={{ marginRight: "7px" }}
        />
      );
      const last4str = "•••• •••• •••• " + this.state.paymentMethodLast4;
      return (
        <UpgradePlanStep1
          pageTitle={page_title}
          history={this.props.history}
          targetPlan={this.state.targetPlan}
          planData={this.state.planData}
          selectedFreq={this.state.selectedFreq}
          currentPlan={this.state.currentPlan}
          nextRenewalDate={nextRenewalDate}
          account={this.state.account}
          paymentIcon={paymentIcon}
          last4str={last4str}
          getCountryLabelFromIsoCode={this.getCountryLabelFromIsoCode}
          purchaseLoading={this.state.purchaseLoading}
          goBack={this.goBackToStep0}
          purchasePlan={this.purchasePlan}
          currencyCode={this.state.planData.currency_code}
        />
      );
    }
  }
}

function mapStateToProps(state) {
  return {};
}

export default withTranslation()(connect(mapStateToProps)(UpgradePlanView));
