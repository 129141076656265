import React, { Fragment } from 'react';
import { withTranslation } from "react-i18next";
import i18n from 'i18next';
import '../createproject.css';

function ProjectBanner (props) {
    const { className, img, imgWidth, withShapeDivider } = props;
    const classes = ['banner-showcase']
    if (className) {
        classes.push(className)
    }
    return (
        <Fragment>
            <div className={classes.join(' ')}>
                <div className="central-content-1200">
                    <div className="content-column no-hidden">
                        <div className="column-percent-55">
                            <img src={img} style={{maxWidth:imgWidth, width: imgWidth}} alt="" className="display-block margin-auto img-100 responsive-banner" />
                        </div>
                    </div>
                </div>
                {withShapeDivider &&  <div className="shape-divider"></div>}
            </div>
        </Fragment>
    );
}

ProjectBanner.defaultProps =  {
    withShapeDivider: false,
    buttonType: 'link',
    imgWidth: '736px',
    buttonLibelle: i18n.t('Get started for free')
}

export default withTranslation()(ProjectBanner);