import React, { Fragment } from 'react';
import './list.css';
import { withTranslation } from "react-i18next";
import Loader from '../loader/Loader';
import StringHelper from '../../app/helpers/StringHelper';

class SettingsPaymentMethodListItem extends React.Component {

    constructor(props) {
        super(props);

        this.chooseDefault = this.chooseDefault.bind(this);
        this.removeCard = this.removeCard.bind(this);
    }


    chooseDefault() {
        this.props.chooseDefault(this.props.item)
    }

    removeCard() {
        this.props.removeCard(this.props.item)
    }

    render() {
        const { t } = this.props;
        const item = this.props.item;
        let listItemsClasses = ["settings-payment-methods-list-item", "transition-color"];
        const isCard = (item.type === 'CARD');
        const iconClassName = isCard ? 'icon-card-type card-icon' : 'icon-card-type bank-icon';
        const title = isCard ? item.brand : t('Bank transfer');
        const card_subtitle = t('Ending in {{last4}} • {{ext_month}}/{{exp_year}}', { last4: StringHelper.padLeadingZeros(item.last4), ext_month: item.exp_month, exp_year: item.exp_year });
        const sepa_subtitle = item.sepa_customer_name + ' : ' + item.last4;
        const subtitle = isCard ? card_subtitle : sepa_subtitle;
        const future_payment_text = isCard ? t('Future payment will use this card.') : t('Future payment will use this account');
        const will_expire_card = item.will_expire;
        const is_expired_card = item.is_expired;
        const will_expired_card_text = t('Expires on {{ext_month}}/{{exp_year}}', { ext_month: item.exp_month, exp_year: item.exp_year });
        const expired_card_text = t('Expired {{ext_month}}/{{exp_year}}', { ext_month: item.exp_month, exp_year: item.exp_year });
        return (
            <Fragment>
                <div className={listItemsClasses.join(" ")}>
                    <div className={iconClassName} />
                    <div className="content-table">
                        <table className="table-layout-fixed width-percent-100 margin-bottom-4">
                            <tbody>
                                <tr>
                                    <td width="200">
                                        <div className="font-inter-500 font-size-15 line-height-1-6 color-000">{title}</div>
                                    </td>
                                    {this.props.isLoading && (
                                        <Fragment>
                                            <td className="text-align-right padding-right-8" />
                                            <td width="24">
                                                <Loader className="size-20" />
                                            </td>
                                        </Fragment>
                                    )}
                                    {!this.props.isLoading && (
                                        <Fragment>
                                            {!item.isDefault && !is_expired_card &&
                                                <td className="text-align-right padding-right-8">
                                                    <button className="button height-20 choose-as-default-button transition" onClick={this.chooseDefault}>{t('Choose default')}</button>
                                                </td>
                                            }
                                            {!item.isDefault &&
                                                <td width="24">
                                                    <div className='remove-card-div' onClick={this.removeCard} />
                                                </td>
                                            }
                                        </Fragment>
                                    )}
                                </tr>
                            </tbody>
                        </table>
                        <table className="table-layout-fixed width-percent-100">
                            <tbody>
                                <tr>
                                    <td width="230">
                                        <div className="font-inter-500 font-size-13 line-height-1-8 color-7f7f7f">{subtitle}</div>
                                    </td>
                                    {isCard &&
                                        <td className="text-align-right">
                                            {is_expired_card && <div className="font-inter-500 font-size-13 line-height-1-8 color-e04f77">{expired_card_text}</div>}
                                            {will_expire_card && <div className="font-inter-500 font-size-13 line-height-1-8 color-969696">{will_expired_card_text}</div>}
                                        </td>
                                    }
                                </tr>
                                {item.isDefault &&
                                    <tr>
                                        <td width="230">
                                            <div className="font-inter-700 font-size-13 line-height-1-8 color-7f7f7f">{future_payment_text}</div>
                                        </td>
                                        <td className="text-align-right">
                                            <div className="font-inter-500 font-size-13 line-height-1-8 color-2d75ff">{t('Default')}</div>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="settings-payment-methods-list-item-divider" />
            </Fragment>
        );
    }
}

export default withTranslation()(SettingsPaymentMethodListItem);