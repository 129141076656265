import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import Loader from '../../../../components/loader/Loader';
import Modal from '../../../../components/modal/Modal';
import TextField from '../../../../components/form/field/TextField';
import { USER_ACCOUNT_STATUS } from '../../../../app/constants';
import StringHelper from '../../../../app/helpers/StringHelper';
import UserHelper from '../../../../app/helpers/UserHelper';

class DeactivateUserModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                email: '',
                role: this.props.item.user_status
            },
            item: props.item,
            errors: null,
        };
        this.modalRef = React.createRef();
        this.handleChange = this.handleChange.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.getErrorMessage = this.getErrorMessage.bind(this);
        this.getErrors = this.getErrors.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
    }

    onCancelClick() {
        if (this.modalRef && this.modalRef.current) {
            this.modalRef.current.onModalCloseClick();
        }
    }

    getErrors(event) {
        const { t } = this.props;
        let errors = this.state.errors ? Object.assign({}, this.state.errors) : {};
        if (event.target.name === 'email') {
            if (!StringHelper.isEmailValid(event.target.value)) {
                errors = { email: t('Please enter a valid email') };
            } else {
                delete errors.email;
            }
        }
        return (Object.keys(errors).length === 0) ? null : errors
    }

    handleChange(event) {
        let formData = Object.assign({}, this.state.formData);
        formData[event.target.name] = event.target.value;
        let errors = this.getErrors(event);
        this.setState({
            formData: formData,
            errors: errors
        });
    }

    getErrorMessage(target) {
        const { error } = this.props;
        if (this.state.errors && (this.state.errors.hasOwnProperty(target))) {
            return this.state.errors[target];
        }
        if (error && (error.hasOwnProperty(target))) {
            return error[target];
        }
        return null;
    }

    formIsValid() {
        if (this.state.formData.email && !this.state.errors && this.state.formData.email === this.state.item.email) {
            return true;
        }
        return false;
    }

    onClickSave(e) {
        e.preventDefault();
        if (this.props.onActivateHandler) {
            let formData = Object.assign({}, this.state.formData);
            const currentAccount = UserHelper.getCurrentAccount()
            formData.account_id = currentAccount.id
            formData.user_account_id = this.state.item.user_account_id;
            formData.target_role = USER_ACCOUNT_STATUS.INACTIVE
            this.props.onActivateHandler(formData)
        }
    }

    render() {
        const { t, item } = this.props;
        const buttonIsEnabled = this.formIsValid();
        return (
            <Modal ref={this.modalRef} display={this.props.display} onClose={this.props.onClose}>
                <div className="font-size-30 font-inter-700 color-000 padding-bottom-40">{t('Deactivate User')}</div>
                <div className="sub-title-activate">{t('Please enter email to confirm deactivation{{points}} {{confirm_email}}', { points: ":", confirm_email: item.email })}</div>
                <form>
                    <TextField
                        type="text"
                        name="email"
                        defaultValue={""}
                        disabled={this.props.loading}
                        onChange={this.handleChange}
                        errorMessage={this.getErrorMessage('company_name')}
                    />
                    <div className="overflow-hidden">
                        {!this.props.loading &&
                            <Fragment>
                                <div className="float-right">
                                    <button className="button backgroundless" type="button" onClick={this.onCancelClick}>{t('Cancel')}</button>
                                    <button className="button backgroundless margin-left-5" disabled={!buttonIsEnabled} onClick={this.onClickSave}>{t('Deactivate')}</button>
                                </div>
                                {!!this.props.error && <div className="color-e04f77 font-size-15 line-height-34"><span className="display-inline-block line-height-1-2">{this.props.error}</span></div>}
                            </Fragment>
                        }
                        {this.props.loading &&
                            <div className="float-right" style={{ padding: "2px 0" }}>
                                <Loader />
                            </div>
                        }
                    </div>
                </form>
            </Modal>
        );
    }
}

export default withTranslation()(DeactivateUserModal);