import React from 'react';
import { withTranslation } from 'react-i18next';
import ButtonField from '../../../components/form/field/ButtonField';
import imgEmptyState from '../../../res/images/empty-state-project.svg';
import './EmptyState.css'

class EmptyState extends React.Component {
    render() {
        const { t } = this.props;
        return (
            <div className="central-content" style={{height: "calc(100vh - 60px)", display: "flex", alignItems: "center"}}>
                <div style={{width:"100%"}} className="empty-state-content padding-vertical-80 display-flex justify-content-space-between responsive-emptyState">
                    <div id='EmptyState-box'>
                        <div className="font-inter-700 font-size-24 color-616161 text-align-left">{t('Time to take action!')} <br /> {t('Create your first project.')}</div>
                        <div style={{maxWidth:"352px"}} className="font-inter-400 font-size-15 color-7f7f7f text-align-left margin-top-32 line-height-1-7">{t('You\'ve taken the first step by joining Sensia - now let\'s get to work! To start turning data into actionable insights, it\'s time to create your first project. Don\'t worry, it\'s quick and easy - let\'s go!')}</div>
                        <div>
                            <ButtonField className="button blue margin-top-32" loading={this.props.loadingCreateProjectButton} onClick={this.props.onClickCreateProjectButton}>{t('Create project')}</ButtonField>
                        </div>
                    </div>
                    <div style={{ maxWidth: '540px'}}>
                        <img alt="" src={imgEmptyState} className="img-100" />
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(EmptyState);