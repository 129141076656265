import HttpHelper from "../helpers/HttpHelper";

async function getInsightCount(projectId, formData, onSuccess, onError, signal = null) {
    const url = "/api/v1/report/insight-count/" + projectId;
    return HttpHelper.authApiFetch(url, HttpHelper.method.GET, formData, onSuccess, onError, signal);
}

async function getInsightWord(projectId, formData, onSuccess, onError, signal = null) {
    const url = "/api/v1/report/insight/" + projectId;
    return HttpHelper.authApiFetch(url, HttpHelper.method.GET, formData, onSuccess, onError, signal);
}

async function getInsightWordMore(projectId, formData, onSuccess, onError, signal = null) {
    const url = "/api/v1/report/insight-more/" + projectId;
    return HttpHelper.authApiFetch(url, HttpHelper.method.GET, formData, onSuccess, onError, signal);
}

async function getGraphDataReport(projectId, formData, onSuccess, onError, signal = null) {
    const url = "/api/v1/report/graph-data/" + projectId;
    return HttpHelper.authApiFetch(url, HttpHelper.method.GET, formData, onSuccess, onError, signal);
}

async function getMostReleventReviews (projectId, formData, onSucces, onError) {
    const url = "/api/v1/report/most-relevant/" + projectId;
    return HttpHelper.authApiFetch(url, HttpHelper.method.GET, formData, onSucces, onError)
}

export const reportService = {
    get: {
        getInsight: getInsightWord,
        getInsightCount: getInsightCount,
        getInsightMore: getInsightWordMore,
        getGraphData: getGraphDataReport,
        getMostReleventReviews: getMostReleventReviews
    }
}