import React, { Fragment } from 'react';
import { Redirect, Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import '../unconnected.css';
import TextField from '../../../components/form/field/TextField';
import ButtonField from '../../../components/form/field/ButtonField';
import {globalConstants} from "../../../app/constants";
import {withTranslation} from 'react-i18next';
import {connect} from "react-redux";
import { ROUTES } from '../../../app/routing';
import HeaderUnconnected from '../../../components/header/HeaderUnconnected';
import FooterUnconnected from '../../../components/footer/FooterUnconnected';
import StringHelper from '../../../app/helpers/StringHelper';
import { userService } from '../../../app/services';
import HttpHelper from '../../../app/helpers/HttpHelper';
import illus from '../../../res/images/illustrations/illus-pw-unlock.png';

class ForgotPasswordView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                email: ''
            },
            loading: false,
            formErrors: null,
            redirectToReferer: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getErrorMessage = this.getErrorMessage.bind(this);
        this.getFormErrors = this.getFormErrors.bind(this);
    }

    componentDidMount() {
        this._ismounted = true;
    }

    getFormErrors(event) {
        const { t } = this.props;
        let formErrors = (this.state.formErrors) ? Object.assign({}, this.state.formErrors) : {};
        if (event.target.name === 'email') {
            if (!StringHelper.isEmailValid(event.target.value)) {
                formErrors.email = t('Please enter a valid email')
            } else {
                delete formErrors.email;
            }
        }
        return (Object.keys(formErrors).length === 0) ? null : formErrors
    }

    handleChange(event) {
        let formData = Object.assign({}, this.state.formData);
        formData[event.target.name] = event.target.value;
        let formErrors = this.getFormErrors(event)
        this.setState({
            formData: formData,
            formErrors: formErrors
        });
    }

    handleSubmit(event) {
        if(!this.state.formErrors){
            this.setState({loading: true});
            userService.lostPasswd(
                this.state.formData,
                (response) => {
                    const url = HttpHelper.addParametersToUrl(ROUTES.FORGOT_PASSWORD_CHECK_YOUR_EMAIL, {
                        email: this.state.formData.email
                    })
                    this.props.history.push(url);
                },
                (error) => {
                    this.setState({
                        loading: false,
                        formErrors: HttpHelper.getErrorMessage(error)
                    });
                }
            );
        }
        event.preventDefault();
    }

    getErrorMessage(target){
        if(this.state.formErrors && (this.state.formErrors.hasOwnProperty(target))){
            return this.state.formErrors[target];
        }
        return null;
    }

    render() {
        const {t} = this.props;
        const { from } =  { from: { pathname: "/" } };
        let formElementClassName = (this.state.error) ? 'error' : '';
        if(this.state.redirectToReferer){
            return <Redirect to={from} />;
        }
        let formClassName = (this.state.loading) ? 'loading-form' : '';
        return (
            <Fragment>
                <Helmet>
                    <title>{t('Reset password')} - {globalConstants.WEBSITE_TITLE}</title>
                </Helmet>
                <div className="unconnected-content central">
                    <div className="content-white">
                        <HeaderUnconnected />
                        <div className="c-content-table">
                            <div className="c-content-table-cell">
                                <div className="centralized-content">
                                    <div className="margin-auto" style={{maxWidth: '300px'}}>
                                        <img alt="" src={illus} className="img-100"/>
                                    </div>
                                    <div className="font-inter-700 font-size-36 text-align-center color-0e004b padding-bottom-50">{t('Reset password')}</div>
                                    <div className="font-inter-400 font-size-16 color-000 text-align-center padding-bottom-30">
                                        {t('Please enter your email address. We will send you an email with a link to reset your password.')}
                                    </div>
                                    <form onSubmit={this.handleSubmit} className={formClassName}>
                                        <TextField
                                            type="text"
                                            label={t('Email')}
                                            name="email"
                                            required={true}
                                            disabled={this.state.loading}
                                            value={this.state.formData.email}
                                            onChange={this.handleChange}
                                            errorMessage={this.getErrorMessage('email')}
                                            formElementClassName={formElementClassName}
                                        />
                                        <div className="padding-top-30"/>
                                        <ButtonField
                                            type="submit"
                                            value={t('Reset password')}
                                            disabled={this.state.loading}
                                            loading={this.state.loading}
                                            className="font-work-sans-600 height-45 width-170"
                                            formElementClassName="padding-vertical-20 text-align-center"
                                        />
                                        <div className="text-align-center padding-top-50">
                                            <div className="font-inter-400 font-size-15">
                                                {t('Come back to')} <Link to={ROUTES.LOGIN} className="link-blue font-size-15 font-inter-500">{t('Sign in page')}</Link>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <FooterUnconnected />
                    </div>
                </div>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

export default withTranslation()(connect(mapStateToProps)(ForgotPasswordView));