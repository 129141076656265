import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";
import defaultImage from "../../../res/images/media-types/amazon-url-default.png";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";

class InformationsWebUrl extends React.Component {
  render() {
    const { t, data } = this.props;
    let listItemsClasses = ["content-information-media", "amazon-url"];
    if (data.status) {
      listItemsClasses.push(data.status);
    }

    const imageStyle = data.picture
      ? {
          backgroundImage:
            "url(" + data.picture + "), url(" + defaultImage + ")",
        }
      : {};
    let errorMessage = data.message;
    if (data.message && data.message == "The provided URL is not supported") {
      errorMessage = (
        <p>
          This URL does not appear to be{" "}
          {this.props.selectedProductSource.service} URL.
          <br />
          Check the list of supported data sources{" "}
          <a
            style={{ color: "#e04f77" }}
            href="https://intercom.help/sensia-app/en/articles/9083807-find-supported-data-sources"
            target="_blank"
          >
            here
          </a>
        </p>
      );
    }
    return (
      <div className={listItemsClasses.join(" ")}>
        <div className="close-btn" onClick={this.props.onCloseClick} />
        {data.status === "error" && (
          <div className="display-flex">
            <ReportProblemOutlinedIcon />
            <div className="padding-5">{errorMessage}</div>
          </div>
        )}
        {data.status === "success" && (
          <Fragment>
            <div
              className="picture background-image-contain"
              style={imageStyle}
            />
            <div className="content-left">
              <div className="font-inter-600 font-size-15 color-000 text-ellipsis-2">
                {data.title}
              </div>
              <div className="content-captions-infos">
                <div className="font-inter-400 font-size-13 color-7f7f7f padding-bottom-2">
                  {t("Reviews")}: {data.number_of_reviews || 0}
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

export default withTranslation()(InformationsWebUrl);
