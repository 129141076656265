import React, { Fragment } from 'react';
import './header.css';
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ROUTES } from '../../app/routing';
import logo from '../../res/images/logo/logo-blue.svg';
import StagingRibbon from '../stagingRibbon/StagingRibbon';


class HeaderUnconnected extends React.Component {
    render() {
        const { withStaggingRibbon, className } = this.props;
        let contentClassNames = ['content-header-unconnected'];
        if(className){
            contentClassNames.push(className);
        }
        return (
            <Fragment>
                {withStaggingRibbon && <StagingRibbon />}
                <div className={contentClassNames.join(' ')}>
                    <div className="content-max-width-590 margin-auto">
                        <Link to={ROUTES.LOGIN} className="header-unconnected">
                            <img src={logo} alt="Logo" className="website-logo" width={123} />
                        </Link>
                    </div>
                </div>
            </Fragment>
        );
    }
}

HeaderUnconnected.defaultProps = {
    withStaggingRibbon: false
}

export default withTranslation()(HeaderUnconnected);