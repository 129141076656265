import React, {  } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { withTranslation } from 'react-i18next';
import { backofficeService } from '../../app/services/backoffice.service';
import Loader from '../loader/Loader';
import { chartColors } from "./adminMediaTypeChart";

ChartJS.register(ArcElement, Tooltip, Legend);

class ProductCategoryUseChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      productCategoryUseIsLoaded: false,
    };
  }

  componentDidMount() {
    this.fetchProductCategoryUseData();
  }

  fetchProductCategoryUseData() {
    backofficeService.getProductCategoryUseInfo(
      (response) => {
        this.setState({
          data: {
            labels: response.product_category_label,
            datasets: [
              {
                label: "Product Category Used",
                data: response.product_category_count,
                backgroundColor: chartColors,
                borderWidth: 1,
              },
            ],
          },
          productCategoryUseIsLoaded: true,
        });
      },
      (error) => {
        console.log(error);
        this.setState({
          productCategoryUseIsLoaded: true,
        });
      }
    );
  }

  option() {
    const options = {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        legend: {
          position: "bottom",
          align: "end",
        },
      },
    };

    return options;
  }

  render() {
    const { t } = this.props;

    return (
      <div className="doughnutChartBox">
        {!this.state.productCategoryUseIsLoaded && <Loader />}
        {this.state.productCategoryUseIsLoaded && (
          <div className="chartTitle">{t("Product Category Used")}</div>
        )}
        {this.state.productCategoryUseIsLoaded && (
          <Doughnut data={this.state.data} options={this.option()} />
        )}
      </div>
    );
  }
}

export default withTranslation()(ProductCategoryUseChart);
