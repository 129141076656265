import {
  SimpleForm,
  TextInput,
  required,
  Create,
  useNotify,
  FormDataConsumer,
  useGetList,
  ReferenceInput,
  AutocompleteInput,
  useRefresh,
  SaveButton,
  Toolbar,
  BooleanInput,
} from "react-admin";

import PromptInputText from "./elements/PromptInputText";
import { Paper, Typography } from "@mui/material";

const optionRenderer = (choice) => `${choice.id} - ${choice.name}`;
const optionRendererDatasource = (choice) => `${choice.id} - ${choice.title}`;

let promptResponse = "";
let jsonResponse = null;

const PromptResponse = ({title, promptResponse}) => (
    <>
        <Typography
            sx={{
            mt: "6px",
            color: "#9e9e9e",
            fontWeight: 600,
            fontSize: "13px",
            fontFamily: '"Inter", sans-serif',
            }}
        >
            {title}
        </Typography>
        <Paper
            title="prompt-response"
            sx={{ p: "20px", mt: "8px", width: "100%", minHeight: "200px" }}
        >
        <Typography
        sx={{
            mb: "50px",
            color: "#000",
            fontFamily: "Inter",
            fontSize: "15px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "22px",
        }}
        >
        {promptResponse}
        </Typography>
        </Paper>
    </>
);

const PromptTestToolbar = () => (
  <Toolbar>
    <SaveButton icon={null} label="Execute" />
  </Toolbar>
);

const ConditionalPromptResponse = ({title, response}) => {
    return response ? <PromptResponse title={title} promptResponse={response} /> : null;
}

export const PromptTestCreate = () => {
  const notify = useNotify();
  const refresh = useRefresh();

  const onSuccess = (data) => {
    notify(`Prompt successfully executed`);
    promptResponse = data["text"];
    jsonResponse = data["json_response"];
    refresh();
  };

  const { data, isLoading } = useGetList("feature-list");

  return (
    <Create resource="prompt-test" mutationOptions={{ onSuccess }}>
      <SimpleForm toolbar={<PromptTestToolbar />}>
        <ReferenceInput
          label="Project"
          source="project_id"
          reference="projects"
          sort="ASC"
        >
          <AutocompleteInput
            optionText={optionRenderer}
            optionValue="id"
            validate={[required()]}
            sx={{ width: "50%" }}
          />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.project_id != undefined ? (
              <ReferenceInput
                label="Datasource"
                source="datasource_id"
                reference="data-sources"
                filter={{ project_id: formData.project_id }}
                sort="ASC"
              >
                <AutocompleteInput
                  optionText={optionRendererDatasource}
                  optionValue="id"
                  sx={{ width: "50%" }}
                />
              </ReferenceInput>
            ) : null
          }
        </FormDataConsumer>
        <TextInput
          source="max_tokens"
          label="Max Tokens"
          type="number"
          validate={[required()]}
        />
        <TextInput source="classes" label="Class" />
        <PromptInputText
          source="prompt"
          label="Prompt"
          isRequired
          variables={data}
          isLoading={isLoading}
        />
        <BooleanInput source="generate_variable" label="Generate variable" />
        <ConditionalPromptResponse title='GPT Response' response={promptResponse}/>
        <ConditionalPromptResponse title='JSON Variable' response={jsonResponse}/>
      </SimpleForm>
    </Create>
  );
};
