import React from 'react';
import { withTranslation } from 'react-i18next';
import StringHelper from '../../../../../app/helpers/StringHelper';
import TextField from '../../../../../components/form/field/TextField';
import Loader from '../../../../../components/loader/Loader';
import Modal from '../../../../../components/modal/Modal';


import './changeBillingMailModal.css'

class ChangeBillingMailModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            originalEmail: props.currentEmail,
            formData: {
                email: props.currentEmail,
            },
            errors: null,
        };
        this.modalRef = React.createRef();
        this.handleChange = this.handleChange.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.getErrorMessage = this.getErrorMessage.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
    }

    onCancelClick() {
        if (this.modalRef && this.modalRef.current) {
            this.modalRef.current.onModalCloseClick();
        }
    }

    getErrors(event) {
        const { t } = this.props;
        let errors = this.state.errors ? Object.assign({}, this.state.errors) : {};
        if (event.target.name === 'email') {
            if (!StringHelper.isEmailValid(event.target.value)) {
                errors = { email: t('Please enter a valid email') };
            } else {
                delete errors.email;
            }
        }
        return (Object.keys(errors).length === 0) ? null : errors
    }

    handleChange(event) {
        let isChanged = !(this.state.originalEmail === event.target.value)
        let formData = Object.assign({}, this.state.formData);
        formData[event.target.name] = event.target.value;
        let errors = this.getErrors(event);
        this.setState({
            formData: formData,
            errors: errors,
            isChanged: isChanged,
        });
    }

    getErrorMessage(target) {
        const { error } = this.props;
        if (this.state.errors && (this.state.errors.hasOwnProperty(target))) {
            return this.state.errors[target];
        }
        if (error && (error.hasOwnProperty(target))) {
            return error[target];
        }
        return null;
    }

    onClickSave(e) {
        e.preventDefault();
        if (this.props.onEditMail) {
            this.props.onEditMail(this.state.formData)
        }
    }

    render() {
        const { t, currentEmail } = this.props;
        return (
            <Modal ref={this.modalRef} display={this.props.display} onClose={this.props.onClose}>
                <div className="font-size-30 font-inter-700 color-000 padding-bottom-16">{t('Billing notifications')}</div>
                <div className="mail-modal-sub-title">{t('To receive billing notifications, please enter your email address')}:</div>
                <form>
                    <TextField
                        type="text"
                        label={t('Email')}
                        name="email"
                        defaultValue={currentEmail}
                        disabled={this.props.loading}
                        onChange={this.handleChange}
                        errorMessage={this.getErrorMessage('email')}
                    />
                    <div className="overflow-hidden">
                        {!this.props.loading &&
                            <div className="float-right">
                                <button className="button backgroundless" type="button" onClick={this.onCancelClick}>{t('Cancel')}</button>
                                <button className="button backgroundless margin-left-5" disabled={!(!this.state.errors && this.state.isChanged)} onClick={this.onClickSave}>{t('Save')}</button>
                            </div>
                        }
                        {this.props.loading &&
                            <div className="float-right" style={{ padding: "2px 0" }}>
                                <Loader />
                            </div>
                        }
                    </div>
                </form>
            </Modal>
        );
    }
}

export default withTranslation()(ChangeBillingMailModal);