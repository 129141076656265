import "../unconnected.css";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { globalConstants } from "../../../app/constants";
import { connect } from "react-redux";

import TextField from "../../../components/form/field/TextField";
import ButtonField from "../../../components/form/field/ButtonField";
import { withTranslation } from "react-i18next";
import { PLAN_UGRAPDE, ROUTES } from "../../../app/routing";
import HeaderUnconnected from "../../../components/header/HeaderUnconnected";
import FooterUnconnected from "../../../components/footer/FooterUnconnected";
import StringHelper from "../../../app/helpers/StringHelper";
import HttpHelper from "../../../app/helpers/HttpHelper";
import SignupHelper from "../../../app/helpers/SignupHelper";
import { userService } from "../../../app/services";
import { Typography } from "@mui/material";

class SignupStep2View extends React.Component {
  constructor(props) {
    super(props);
    const currentStep = SignupHelper.getCurrentStep();
    if (currentStep < 2) {
      props.history.push(ROUTES.SIGN_UP_STEP_1);
    }
    const currentUser = SignupHelper.getUser();
    const companyName =
      currentUser.company_name || SignupHelper.guessCompanyName();
    this.state = {
      formData: {
        company_name: companyName,
      },
      formErrors: null,
      loading: false,
    };

    this.formIsValid = this.formIsValid.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getErrorMessage = this.getErrorMessage.bind(this);
    this.getFormErrors = this.getFormErrors.bind(this);

    this.getStartedLink = this.getStartedLink.bind(this);
  }

  componentDidMount() {}

  getFormErrors(event) {
    const { t } = this.props;
    let formErrors = this.state.formErrors
      ? Object.assign({}, this.state.formErrors)
      : {};
    if (event.target.name === "email") {
      if (!StringHelper.isEmailValid(event.target.value)) {
        formErrors.email = t("Please enter a valid email");
      } else {
        delete formErrors.email;
      }
    }
    return Object.keys(formErrors).length === 0 ? null : formErrors;
  }

  handleChange(event) {
    let formData = Object.assign({}, this.state.formData);
    formData[event.target.name] = event.target.value;
    let formErrors = this.getFormErrors(event);
    this.setState({
      formData: formData,
      formErrors: formErrors,
    });
  }

  formIsValid() {
    return this.state.formErrors == null;
  }

  getStartedLink() {
    const currentUser = SignupHelper.getUser();
    if (!currentUser.plan) {
      return ROUTES.HOME;
    }
    return currentUser.freq
      ? HttpHelper.addParametersToUrl(PLAN_UGRAPDE(currentUser.plan), {
          freq: currentUser.freq,
        })
      : PLAN_UGRAPDE(currentUser.plan);
  }

  handleSubmit(event) {
    if (this.formIsValid()) {
      this.setState({ loading: true });
      const formData = Object.assign(
        SignupHelper.getUser(),
        this.state.formData
      );
      const currentUser = Object.assign({}, this.state.formData);
      currentUser.invitation_token = null;
      userService.signup(
        formData,
        (response) => {
          currentUser.id = response.user_id;
          SignupHelper.updateUser(currentUser);
          if (currentUser.is_google_login || currentUser.is_microsoft_login) {
            this.props.history.push(this.getStartedLink());
          } else {
            this.props.history.push(ROUTES.SIGN_UP_STEP_3);
          }
        },
        (error) => {
          SignupHelper.updateUser(currentUser);
          this.setState({
            loading: false,
            formErrors: error.formErrors,
          });
        }
      );
    }
    event.preventDefault();
  }

  getErrorMessage(target) {
    if (this.state.formErrors && this.state.formErrors.hasOwnProperty(target)) {
      return this.state.formErrors[target];
    }
    return null;
  }

  render() {
    const { t } = this.props;
    let formElementClassName = this.state.error ? "error" : "";
    let formClassName = this.state.loading ? "loading-form" : "";
    return (
      <Fragment>
        <Helmet>
          <title>
            {t("What’s your company name?")} - {globalConstants.WEBSITE_TITLE}
          </title>
        </Helmet>
        <div className="unconnected-content double-percent-50">
          <div className="content-white">
            <HeaderUnconnected />
            <div className="content-max-width-590 min-height-100vh margin-auto">
              <div className="c-content-table">
                <div className="c-content-table-cell">
                  <div className="centralized-content">
                    <div className="font-inter-700 font-size-36 text-align-center color-0e004b padding-bottom-50">
                      {t("What’s your company name?")}
                    </div>
                    <form
                      onSubmit={this.handleSubmit}
                      className={formClassName}
                    >
                      <TextField
                        type="text"
                        label={t("Company name")}
                        name="company_name"
                        required={true}
                        disabled={this.state.loading}
                        value={this.state.formData.company_name}
                        onChange={this.handleChange}
                        errorMessage={this.getErrorMessage("company_name")}
                        formElementClassName={formElementClassName}
                      />
                      {this.state.formErrors && (
                        <Typography sx={{ color: "#e04f77", textAlign: "end" }}>
                          {this.state.formErrors.email}
                        </Typography>
                      )}
                      <div className="padding-top-20" />
                      <ButtonField
                        type="submit"
                        value={t("Next")}
                        disabled={this.state.loading}
                        loading={this.state.loading}
                        className="font-work-sans-600 width-170 height-45"
                        formElementClassName="padding-vertical-20 text-align-center"
                      />
                      <div className="text-align-center">
                        <Link
                          to={ROUTES.SIGN_UP_STEP_1}
                          className="link-grey font-size-14 font-inter-500"
                        >
                          {t("Previous")}
                        </Link>
                      </div>
                      <div className="padding-bottom-30" />
                    </form>
                  </div>
                </div>
              </div>
              <FooterUnconnected />
            </div>
          </div>
          <div className="content-blue background-color-fff">
            <div className="icon width-percent-40" />
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withTranslation()(connect(mapStateToProps)(SignupStep2View));
