import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";

import { globalConstants } from '../../app/constants';
import LoadingView from '../loading/LoadingView';
import Header from "../../components/header/Header";

import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import './editMediaCaption.css';

import { projectService } from '../../app/services/project.service';
import Footer from '../../components/footer/Footer';
import Loader from '../../components/loader/Loader';
import { mediaService } from '../../app/services/media.service';
import HttpHelper from '../../app/helpers/HttpHelper';
import Link from "../../components/Link";
import { PROJECT_DETAIL } from '../../app/routing';
import TextField from '../../components/form/field/TextField';

class EditClassView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            isMediaLoaded: false,
            isModified: false,
            formError: null,
            formData: {
                title: "",
                data: "",
            },
            initialFormData: {
                title: "",
            },
            submitLoading: false,
            project: null,
            original_captions: "",
        };
        this.fetchData = this.fetchData.bind(this);
        this.handleChangeTextarea = this.handleChangeTextarea.bind(this);
        this.handleChangeName = this.handleChangeName.bind(this);
        this.getCaptionsString = this.getCaptionsString.bind(this);
        this.onSubmitModif = this.onSubmitModif.bind(this);
    }

    componentDidMount() {
        this._ismounted = true;
        this.fetchData();
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    getCaptionsString(response, withCustom) {
        withCustom = (typeof withCustom != 'undefined') ? withCustom : true;
        let captions = response.original_captions;
        if (withCustom) {
            captions = (response.custom_captions) ? response.custom_captions : response.original_captions;
        }
        if (captions) {
            return JSON.stringify(captions, null, 2);
        }
        return "";
    }

    fetchData() {
        projectService.get.get(
            this.props.match.params.id,
            (response) => {
                this.setState({
                    isLoaded: true,
                    project: response
                });
            },
            (error) => {
                if (this._ismounted) {
                    this.setState({ isLoaded: true });
                    this.props.addToast(HttpHelper.getErrorMessage(error), { type: 'error', autoDismiss: true });
                }
            },
            { schema: 'minimal' }
        );

        let projectId = this.props.match.params.id;         // PROJECT ID 
        let datasourceId = this.props.match.params.datasource_id;     // MEDIA ID

        mediaService.get.get(
            projectId, datasourceId,
            (response) => {
                const captions = this.getCaptionsString(response);
                this.setState({
                    isMediaLoaded: true,
                    formData: {
                        data: captions,
                        title: response.title,
                    },
                    initialFormData: {
                        title: response.title,
                    },
                    original_captions: this.getCaptionsString(response, false),
                });
            },
            (error) => {
                if (this._ismounted) {
                    this.setState({ isMediaLoaded: true });
                    this.props.addToast(HttpHelper.getErrorMessage(error), { type: 'error', autoDismiss: true });
                }
            }
        );
    }

    handleChangeTextarea(event) {
        let newValue = event.target.value;
        this.setState({
            isModified: (newValue !== this.state.original_captions && this.state.formData.title.length !== 0),
            formData: {
                ...this.state.formData,
                data: newValue,
            }
        });
    }

    handleChangeName(event) {
        let newValue = event.target.value;
        this.setState({
            isModified: (newValue !== this.state.initialFormData.title && newValue.length !== 0),
            formData: {
                ...this.state.formData,
                title: newValue,
            }
        });
    }

    onResetCaptions = (e) => {
        e.preventDefault();
        let project_Id = this.props.match.params.id;         // PROJECT ID 
        let datasource_id = this.props.match.params.datasource_id;     // MEDIA ID
        mediaService.get.restoreCaptions(
            project_Id, datasource_id,
            (response) => {
                setTimeout(() => {
                    this.props.addToast('Original observations successfully restored', { type: 'success', autoDismiss: true });
                }, 800);
                this.props.history.goBack();
            },
            (error) => {
                if (this._ismounted) {
                    this.props.addToast(HttpHelper.getErrorMessage(error), { type: 'error', autoDismiss: true });
                }
            }
        )
    }

    onSubmitModif = (e) => { // METHOD SUBMIT NEW CAPTIONS
        e.preventDefault();
        let formData = Object.assign({}, this.state.formData);
        this.setState({ submitLoading: true, formError: null });
        if (formData) {
            let project_Id = this.props.match.params.id;         // PROJECT ID 
            let datasource_id = this.props.match.params.datasource_id;     // MEDIA ID
            mediaService.put.update(
                project_Id, datasource_id, formData,
                (response) => {
                    this.setState({
                        submitLoading: false
                    });
                    setTimeout(() => {
                        this.props.addToast('Data source successfully updated', { type: 'success', autoDismiss: true });
                    }, 800);
                    this.props.history.goBack();
                },
                (error) => {
                    this.setState({ submitLoading: false, formError: HttpHelper.getErrorMessage(error) });
                }
            );
        }
    }

    render() {
        const { t } = this.props;
        let page_title = `${t('Edit')} - ${globalConstants.WEBSITE_TITLE}`;
        let textareaClasses = ['min-height-450', 'width-percent-100'];
        if (this.state.formError) {
            textareaClasses.push('error');
        }
        if (this.state.isLoaded) {
            const project = this.state.project;
            return (
                <Fragment>
                    <Header isProject={true} />
                    <Helmet>
                        <title>{page_title}</title>
                    </Helmet>
                    <div className="padding-top-60 padding-bottom-20 responsive-920-padding-top-20">
                        <div className="central-content">
                            {project &&
                                <div className="padding-bottom-4">
                                    <Link to={PROJECT_DETAIL(project.id)} className="font-size-18 link-grey transition-color text-break-line">{project.name}</Link>
                                </div>
                            }
                            <div className="font-size-36 font-inter-700 color-000">{t('Edit data source')}</div>
                            {this.state.isMediaLoaded && (
                                <div className="content-column">
                                    <div className="column-percent-65 responsive-900 padding-top-40">
                                        <form>
                                            <TextField
                                                label={t('Name')}
                                                type="text"
                                                name="title"
                                                value={this.state.formData.title}
                                                onChange={this.handleChangeName}
                                                disabled={this.state.submitLoading}
                                                maxLength={256}
                                            />
                                            <label className="display-block font-inter-600 color-969696 font-size-15 padding-bottom-4">{t('Observations')}</label>
                                            <textarea value={this.state.formData.data} rows={5} cols={5} onChange={this.handleChangeTextarea} className={textareaClasses.join(' ')} disabled={this.state.submitLoading} />
                                            {this.state.formError && <div className="color-e04f77 font-size-15 padding-top-4">{this.state.formError}</div>}
                                            <div className="padding-bottom-36" />
                                            <div className="overflow-hidden">
                                                {!this.state.submitLoading &&
                                                    <div>
                                                        <div className="restore-button float-left">
                                                            <button onClick={this.onResetCaptions} className="padding-left-0 button backgroundless pink font-inter-100">
                                                                {t('Restore original observations')}
                                                            </button>
                                                        </div>
                                                        <div className="save-cancel-button float-right">
                                                            <button type="button" className="button backgroundless" onClick={this.props.history.goBack}>{t('Cancel')}</button>
                                                            <button disabled={!this.state.isModified} onClick={this.onSubmitModif} className="button backgroundless">
                                                                {t('Save')}
                                                            </button>
                                                        </div>
                                                    </div>
                                                }
                                                {this.state.submitLoading &&
                                                    <div className="float-right" style={{ padding: "2px 0" }}>
                                                        <Loader />
                                                    </div>
                                                }
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            )}
                            {!this.state.isMediaLoaded && (
                                <div className="text-align-left padding-vertical-80">
                                    <Loader />
                                </div>
                            )}
                        </div>
                    </div>
                    <Footer />
                </Fragment >
            );
        }
        return <LoadingView title={page_title} withHeader={true} headerProps={{ isProject: true }} />;
    }
}

function mapStateToProps(state) {
    return {};
}

export default withTranslation()(connect(mapStateToProps)(EditClassView));