import React, { Fragment } from "react";
import { Helmet } from "react-helmet";

import { globalConstants } from "../../app/constants";
import Header from "../../components/header/Header";

import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import "./models.css";

import Footer from "../../components/footer/Footer";
import Loader from "../../components/loader/Loader";
import HttpHelper from "../../app/helpers/HttpHelper";
import UserHelper from "../../app/helpers/UserHelper";
import { modelService } from "../../app/services/model.service";
import SelectField from "../../components/form/field/SelectField";
import ColoredScore from "../../components/ui/ColoredScore";
import Lottie from "react-lottie-player";
import underConstructionLottieJson from "../../res/lotties/under-construction.json";
import UploadField from "../../components/form/field/UploadField";
import ModelsMenu from "./elements/ModelsMenu";
import { ROUTES } from "../../app/routing";
import ModelsLoadingView from "../loading/ModelsLoadingView";
import { TextField } from "@mui/material";

class ModelsScoreView extends React.Component {
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      isLoaded: false,
      formError: null,
      testFormError: null,
      deleteFormError: null,
      formData: {
        target_model: null,
        data: "",
        resetModel: false,
        epochs: 25,
      },
      zipToUpload: null,
      zipFileName: null,
      model_type: "SCORE",
      testFormData: {
        sentence: "",
        target_model_id: 0,
      },
      deleteFormData: {
        target_model: "default",
      },
      models: [],
      not_selected_model: [],
      hasNoAccess: false,
      submitLoading: false,
      uploadProgress: null,
      testLoading: false,
      testScore: null,
      uploadModelName: "",
      deleteLoading: false,
      usedModel: "default",
      isModelUploaded: false,
    };
    this.fetchInitial = this.fetchInitial.bind(this);
    this.onLaunchUpload = this.onLaunchUpload.bind(this);
    this.isDisabled = this.isDisabled.bind(this);
    this.getModelsOptions = this.getModelsOptions.bind(this);

    this.getModelDefaultValue = this.getModelDefaultValue.bind(this);
    this.handleChangeTestModel = this.handleChangeTestModel.bind(this);
    this.handleChangeTestSentence = this.handleChangeTestSentence.bind(this);
    this.onLaunchTest = this.onLaunchTest.bind(this);
    this.handleDeleteModel = this.handleDeleteModel.bind(this);
    this.onDeleteModel = this.onDeleteModel.bind(this);

    this.onSwitchUsedModel = this.onSwitchUsedModel.bind(this);

    this.handleChangeUploader = this.handleChangeUploader.bind(this);
    this.handleChangeModelName = this.handleChangeModelName.bind(this);
    this.onCloseClick = this.onCloseClick.bind(this);

    // Get className
    this.getClassTestModel = this.getClassTestModel.bind(this);
  }

  componentDidMount() {
    this._ismounted = true;
    this.fetchInitial();
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  fetchInitial() {
    if (UserHelper.userIsCorellis()) {
      modelService.getInfosModels(
        (response) => {
          let first_model_id = response.score_models[0].id;
          let newFormData = Object.assign({}, this.state.formData);
          if (response.score_models.length > 0) {
            newFormData.target_model = first_model_id;
          }
          this.setState({
            hasNoAccess: false,
            isLoaded: true,
            models: response.score_models,
            not_selected_model: response.not_selected_score_models,
            submitLoading: false,
            testFormData: {
              sentence: "",
              target_model_id: first_model_id,
            },
            formData: newFormData,
          });
        },
        (error) => {
          if (!this._ismounted) {
            return;
          }
          this.setState({
            isLoaded: true,
            submitLoading: false,
          });
          this.props.addToast(HttpHelper.getErrorMessage(error), {
            type: "error",
            autoDismiss: true,
          });
        }
      );
    } else {
      this.setState({
        isLoaded: true,
        hasNoAccess: true,
      });
    }
  }

  handleChangeTestModel(event) {
    let formData = Object.assign({}, this.state.testFormData);
    formData.target_model_id = event.value;
    this.setState({
      testFormData: formData,
    });
  }

  handleChangeTestSentence(event) {
    let newValue = event.target.value;
    let formData = Object.assign({}, this.state.testFormData);
    formData.sentence = newValue;
    this.setState({
      testFormData: formData,
    });
  }

  onSwitchUsedModel(e) {
    const currentUsedModel = this.state.usedModel;
    const newUsedModel = e.target.value;
    const label = e.label;
    this.setState({
      usedModel: newUsedModel,
    });
    modelService.switchUsedModel(
      {
        used_model: newUsedModel,
      },
      (response) => {
        this.props.addToast(`Used model switched to ${label}!`, {
          type: "success",
          autoDismiss: true,
        });
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.setState({
          usedModel: currentUsedModel,
        });
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      }
    );
  }

  isDisabled() {
    return this.state.submitLoading;
  }

  removeExtension(filename) {
    let newFileName = filename.split(".");
    newFileName.splice(1);
    return newFileName[0];
  }

  getModelsOptions() {
    const models = this.state.models;
    let modelsOptions = [];
    models.forEach((element) => {
      modelsOptions.push({
        label: element.title,
        value: element.id,
      });
    });
    return modelsOptions;
  }

  getNotSelectedModelsOptions() {
    const models = this.state.not_selected_model;
    let modelsOptions = [];
    if (models) {
      models.forEach((element) => {
        modelsOptions.push({
          label: element.title,
          value: element.id,
        });
      });
    }
    return modelsOptions;
  }

  getModelDefaultValue() {
    const first_model = this.state.models[0];
    let modelsOptions = { label: first_model.title, value: first_model.id };
    return modelsOptions;
  }

  getNotSelectedModelDefaultValue() {
    const first_model = this.state.not_selected_model[0];
    let modelsOptions = {};
    if (first_model) {
      modelsOptions = { label: first_model.title, value: first_model.id };
    }
    return modelsOptions;
  }

  onLaunchUpload(e) {
    // Method to launch upload
    const { t } = this.props;
    e.preventDefault();
    let currentFormData = this.state.formData;
    if (!this.state.zipToUpload) {
      this.setState({
        formError: t("Please upload a Zip file."),
      });
      return;
    }
    const formData = new FormData();
    formData.append("file", this.state.zipToUpload);
    formData.append("file_name", this.state.uploadModelName);
    formData.append("model_type", this.state.model_type);
    formData.append("target_model", currentFormData.target_model);
    this.setState({ submitLoading: true, formError: null, uploadProgress: 0 });
    if (formData) {
      modelService.uploadModel(
        formData,
        (progress) => {
          this.setState({
            uploadProgress: progress,
          });
        },
        (response) => {
          this.setState({
            submitLoading: false,
            formError: null,
            zipToUpload: null,
            uploadProgress: 100,
            uploadModelName: "",
            isModelUploaded: false,
          });
          this.props.addToast(t("Model successfully uploaded"), {
            type: "success",
            autoDismiss: true,
          });
          this.fetchInitial();
        },
        (error) => {
          if (!this._ismounted) {
            return;
          }
          this.setState({
            submitLoading: false,
            uploadProgress: null,
            isModelUploaded: false,
          });
          this.props.addToast(HttpHelper.getErrorMessage(error), {
            type: "error",
            autoDismiss: true,
          });
        }
      );
    }
  }

  onLaunchTest(e) {
    const { t } = this.props;
    e.preventDefault();
    let formData = this.state.testFormData;
    if (!formData.sentence) {
      this.setState({
        testFormError: t("Please enter a sentence"),
      });
      return;
    }
    this.setState({ testLoading: true, testFormError: null });
    if (formData) {
      modelService.getScore(
        formData,
        (response) => {
          this.setState({
            testScore: response.score,
            testLoading: false,
          });
        },
        (error) => {
          if (!this._ismounted) {
            return;
          }
          this.setState({
            testScore: null,
            testLoading: false,
          });
          this.props.addToast(HttpHelper.getErrorMessage(error), {
            type: "error",
            autoDismiss: true,
          });
        }
      );
    }
  }

  handleDeleteModel(event) {
    let modelId = event.value;
    this.setState({
      deleteFormData: {
        target_model: modelId,
      },
    });
  }

  onDeleteModel(event) {
    const { t } = this.props;
    event.preventDefault();
    let formData = this.state.deleteFormData;
    if (this.state.usedModel === formData.target_model) {
      this.setState({
        deleteFormError: t("Please select a non used model"),
      });
      return;
    }
    this.setState({ deleteLoading: true, deleteFormError: null });

    if (formData) {
      modelService.deleteModel(
        formData.target_model,
        (response) => {
          this.setState({
            deleteLoading: false,
            deleteFormError: null,
            deleteFormData: null,
          });
          this.props.addToast(t("Model successfully deleted"), {
            type: "success",
            autoDismiss: true,
          });
          this.fetchInitial();
        },
        (error) => {
          if (!this._ismounted) {
            return;
          }
          this.setState({
            deleteLoading: false,
            deleteFormError: null,
          });
          this.props.addToast(HttpHelper.getErrorMessage(error), {
            type: "error",
            autoDismiss: true,
          });
        }
      );
    }
    window.location.reload();
  }

  handleChangeUploader(event) {
    const { t } = this.props;
    const file =
      event.dataTransfer && event.dataTransfer.files
        ? event.dataTransfer.files[0]
        : event.target.files[0];
    if (!file) {
      return;
    }
    const type = file.type;
    const extension = file.name.split(".").pop().toLowerCase();
    if (
      (type !== "application/zip" && type !== "application/x-zip-compressed") ||
      extension !== "zip"
    ) {
      this.setState({
        formError: t("Please upload a Zip file."),
      });
    } else {
      this.setState({
        formError: null,
        zipToUpload: file,
        zipFileName: this.removeExtension(file.name),
        uploadModelName: this.removeExtension(file.name),
        isModelUploaded: true,
      });
      this.props.addToast(t("Model successfully dropped"), {
        type: "success",
        autoDismiss: true,
      });
    }
  }

  handleChangeModelName(event) {
    this.setState({
      uploadModelName: event.target.value,
    });
  }

  getClassTestModel() {
    return this.state.testFormError
      ? "error width-percent-100"
      : "width-percent-100";
  }

  onCloseClick() {
    this.setState({
      formError: null,
      zipToUpload: null,
      zipFileName: null,
      uploadModelName: "",
      isModelUploaded: false,
      submitLoading: false,
    });
  }

  render() {
    const { t } = this.props;
    let page_title = `${t("Models")} - ${globalConstants.WEBSITE_TITLE}`;
    const isDisabled = this.isDisabled();
    if (this.state.isLoaded) {
      if (this.state.hasNoAccess) {
        return (
          <Fragment>
            <Header isModels={true} />
            <div className="central-content">
              <div className="empty-state-content padding-top-60 padding-bottom-0 text-align-center">
                <div className="font-inter-700 font-size-36 padding-bottom-12 color-000">
                  {t("Work in progress")}
                </div>
                <div className="font-inter-400 font-size-20 color-7f7f7f">
                  {t("This section is under construction")}
                </div>
                <div className="margin-auto" style={{ maxWidth: "500px" }}>
                  <Lottie
                    loop
                    animationData={underConstructionLottieJson}
                    play
                    style={{ maxHeight: 500, maxWidth: 500, margin: "0 auto" }}
                  />
                </div>
              </div>
            </div>
            <Footer />
          </Fragment>
        );
      }
      return (
        <Fragment>
          <Header isModels={true} />
          <Helmet>
            <title>{page_title}</title>
          </Helmet>
          <div className="padding-top-30" />
          <div
            className="central-content settings-content responsive"
            key={this.state.formKey}
          >
            <div className="left">
              <ModelsMenu
                current={ROUTES.MODELS_SCORE}
                history={this.props.history}
              />
            </div>
            <div className="right">
              <div className="padding-top-32" />
              <div className="font-inter-700 font-size-28 color-000 padding-bottom-40 responsive-hide-1000">
                {t("Score models")}
              </div>
              <div className="padding-top-30" />
              <div className="font-size-24 font-inter-700 color-000">
                {t("Upload a model")}
              </div>
              <form className="position-relative">
                <div className="padding-top-20" />
                {this.state.isModelUploaded && (
                  <div
                    className="close-btn close-btn-model-upload"
                    onClick={this.onCloseClick}
                  />
                )}
                <UploadField
                  accept=".zip"
                  hasValidFile={!!this.state.zipToUpload}
                  hasError={this.state.formError}
                  fileTypeLibelle={t("zip file")}
                  handleChange={this.handleChangeUploader}
                  withProgressBar={true}
                  uploadProgress={this.state.uploadProgress}
                />
                {this.state.zipToUpload && this.state.zipFileName && (
                  <Fragment>
                    <div className="padding-top-20" />
                    <label className="display-block font-inter-500 color-969696 font-size-15 padding-bottom-4">
                      {t("Model name")}
                    </label>
                    <TextField
                      hideLabel={true}
                      type="text"
                      name="title"
                      value={this.state.uploadModelName}
                      onChange={this.handleChangeModelName}
                      disabled={this.state.isDisabled}
                      maxLength={256}
                      sx={{ width: "100%" }}
                    />
                  </Fragment>
                )}
                {this.state.formError && (
                  <div className="color-e04f77 font-size-15 padding-top-4">
                    {this.state.formError}
                  </div>
                )}
                <div className="overflow-hidden">
                  {!isDisabled && (
                    <div>
                      <div className="float-right">
                        <button
                          onClick={this.onLaunchUpload}
                          className="button"
                          disabled={this.state.uploadModelName.length < 1}
                        >
                          {t("Upload model")}
                        </button>
                      </div>
                    </div>
                  )}
                  {this.state.submitLoading && (
                    <div className="float-right" style={{ padding: "2px 0" }}>
                      <Loader />
                    </div>
                  )}
                </div>
              </form>
              {!isDisabled && (
                <Fragment>
                  <div className="padding-top-60" />
                  <div className="font-size-24 font-inter-700 color-000">
                    {t("Test models")}
                  </div>
                  <div className="padding-top-20">
                    <SelectField
                      label={t("Choose a model")}
                      name="target_model"
                      disabled={this.state.testLoading}
                      isSearchable={false}
                      options={this.getModelsOptions()}
                      defaultValue={this.getModelDefaultValue()}
                      onChange={this.handleChangeTestModel}
                      placeholder={"Select a model"}
                    />
                    <div>
                      <label className="display-block font-inter-500 color-969696 font-size-15 padding-bottom-4">
                        {t("Please enter a sentence.")}
                      </label>
                      <TextField
                        disabled={this.state.testLoading}
                        placeholder={t("sentence")}
                        onChange={this.handleChangeTestSentence}
                        sx={{ width: "100%" }}
                      />
                    </div>
                    {this.state.testFormError && (
                      <div className="color-e04f77 font-size-15 padding-top-4">
                        {this.state.testFormError}
                      </div>
                    )}
                  </div>
                  <div className="overflow-hidden padding-top-20">
                    {this.state.testScore !== null && (
                      <div className="float-left padding-top-5 font-inter-500 font-size-20">
                        <span className="font-size-15 color-000 padding-right-5">
                          {t("Score")}:
                        </span>
                        <ColoredScore score={this.state.testScore} />
                      </div>
                    )}
                    {!this.state.testLoading && (
                      <div>
                        <div className="float-right">
                          <button
                            onClick={this.onLaunchTest}
                            className="button"
                          >
                            {t("Get score")}
                          </button>
                        </div>
                      </div>
                    )}
                    {this.state.testLoading && (
                      <div className="float-right" style={{ padding: "2px 0" }}>
                        <Loader />
                      </div>
                    )}
                  </div>
                  <div className="padding-top-60" />
                  <div className="font-size-24 font-inter-700 color-000">
                    {t("Delete model")}
                  </div>
                  <div className="padding-top-20">
                    <SelectField
                      label={t("Choose a model to delete")}
                      name="target_model"
                      disabled={this.state.deleteLoading}
                      isSearchable={false}
                      options={this.getNotSelectedModelsOptions(true, true)}
                      defaultValue={this.getNotSelectedModelDefaultValue(
                        true,
                        true
                      )}
                      onChange={this.handleDeleteModel}
                      placeholder={"Select a model"}
                    />
                    {this.state.deleteFormError && (
                      <div className="color-e04f77 font-size-15 padding-top-4">
                        {this.state.deleteFormError}
                      </div>
                    )}
                  </div>
                  <div className="overflow-hidden padding-top-20">
                    {!this.state.deleteLoading && (
                      <div>
                        <div className="float-right">
                          <button
                            onClick={this.onDeleteModel}
                            className="button"
                          >
                            {t("Delete model")}
                          </button>
                        </div>
                      </div>
                    )}
                    {this.state.deleteLoading && (
                      <div className="float-right" style={{ padding: "2px 0" }}>
                        <Loader />
                      </div>
                    )}
                  </div>
                </Fragment>
              )}
            </div>
          </div>
          <Footer />
        </Fragment>
      );
    }
    return (
      <ModelsLoadingView
        title={page_title}
        withHeader={true}
        rightTitle={t("Score models")}
        settingsMenuProps={{
          current: ROUTES.MODELS_SCORE,
          history: this.props.history,
        }}
      />
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withTranslation()(connect(mapStateToProps)(ModelsScoreView));
