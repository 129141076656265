import React from 'react';
import { withTranslation } from 'react-i18next';
import Link from "../../../../components/Link";
class UsersGuideAccordion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            opened: props.defaultOpened,
        }

        this.onHandleOpen = this.onHandleOpen.bind(this);
        this.getContentStatus = this.getContentStatus.bind(this);
    }

    onHandleOpen() {
        this.setState({
            opened: !this.state.opened
        });
    }

    getContentStatus() {
        const { t, item } = this.props;
        if (item.is_completed) {
            return (
                <div className="status">
                    <div className="icon-completed" />
                </div>
            );
        }
        if (item.is_skipped) {
            return (
                <div className="status">
                    <div className="font-inter-500 font-size-14 color-7f7f7f line-height-24">{t('Skipped')}</div>
                </div>
            );
        }
        return null;
    }

    render() {
        const { t, item } = this.props;
        if (!item) {
            return null;
        }
        let classNames = ['user-guide-accordion'];
        if (this.state.opened) {
            classNames.push('opened');
        }
        if (item.is_completed) {
            classNames.push('completed');
        }
        if (item.is_skipped) {
            classNames.push('skipped');
        }
        return (
            <div className={classNames.join(' ')}>
                <div className="button-open transition" onClick={this.onHandleOpen} />
                <div>
                    <span className="title font-inter-600 font-size-18" onClick={this.onHandleOpen}>{item.title}</span>
                </div>
                <div className="content">
                    <div className="font-inter-400 font-size-16 color-7f7f7f padding-bottom-16">{item.description}</div>
                    {item.button && (
                        <div>
                            <Link to={item.button.to} className="button">{item.button.label}</Link>
                        </div>
                    )}
                    {!item.is_completed && !item.is_skipped && (
                        <div className="padding-top-20">
                            <span className="link-blue font-inter-500 font-size-14" onClick={this.props.onSkipTask}>{t('Skip for now')}</span>
                        </div>
                    )}
                </div>
                {this.getContentStatus()}
            </div>
        );
    }
}

UsersGuideAccordion.defaultProps = {
    defaultOpened: false,
    item: null
};

export default withTranslation()(UsersGuideAccordion);