import React from 'react';
import './list.css';
import { withTranslation } from "react-i18next";
import RoundSelect from '../roundSelect/RoundSelect';

class SettingsTeamListItem extends React.Component {

    render() {
        const { t } = this.props;
        const item = this.props.item;
        let listItemsClasses = ["settings-user-list-item", "transition-color"];
        let roundSelectList = {};
        roundSelectList['actions'] = [
            { label: t('Edit team'), action: this.props.onOpenEditModal },
        ];
        roundSelectList['other'] = [
            { label: t('Delete team…'), action: this.props.onOpenDeleteModal, style: { color: '#e04f77' } }
        ];
        let libelleMembers = (item.nb_members > 1) ? t('{{count}} members', { count: item.nb_members }) : t('{{count}} member', { count: item.nb_members });
        if (item.nb_pendings > 0) {
            libelleMembers += ' • ';
            libelleMembers += (item.nb_pendings > 1) ? t('{{count}} pendings', { count: item.nb_pendings }) : t('{{count}} pending', { count: item.nb_pendings });
        }
        const nb_other_members = ((item.nb_members - 4) > 0) ? item.nb_members - 4 : 0;
        return (
            <div className={listItemsClasses.join(" ")}>
                <table className="sensia-table table-layout-fixed width-percent-100">
                    <tbody>
                        <tr>
                            <td className="empty-left-td-th"/>
                            <td>
                                <div className="position-relative">
                                    <div className="font-inter-600 text-ellipsis line-height-20 color-000 font-size-15">{item.title}</div>
                                    <div className="font-inter-400 text-ellipsis line-height-20 color-7f7f7f font-size-13">
                                        {libelleMembers}
                                    </div>
                                </div>
                            </td>
                            <td width={270} className="hide-760">
                                <div className="content-stacked-colored-round-user">
                                    {nb_other_members > 0 && (<div key={`settings-team-round-user-other-${item.id}`} className={['colored-round-user', 'font-inter-500', 'background-other'].join(' ')}>+{nb_other_members}</div>)}
                                    {item.members.length > 0 && item.members.slice(0, 4).map((aUser, i) => {
                                        let roundClasses = ['colored-round-user', 'font-inter-500', aUser.color];
                                        let roundStyle = {}
                                        if(aUser.profile_picture){
                                            roundStyle = { backgroundImage: `url("${aUser.profile_picture}")`}
                                            roundClasses = ['colored-round-user', 'pictured']
                                        }
                                        return (
                                            <div key={`settings-team-round-user-${item.id}-${aUser.id}-${i}`} className={roundClasses.join(' ')} style={roundStyle}>{aUser.initial}</div>
                                        );
                                    })}
                                </div> 
                            </td>
                            <td width={30}>
                                <RoundSelect lists={roundSelectList} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withTranslation()(SettingsTeamListItem);