import '../plan.css';
import './downgradePlanView.css';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment-timezone';
import { getAccountPlan, getPlanAdvantages, globalConstants } from "../../../app/constants";
import { connect } from 'react-redux';
import LoadingView from '../../loading/LoadingView';
import { withTranslation } from 'react-i18next';

import PlanHeader from '../elements/PlanHeader';
import UserHelper from '../../../app/helpers/UserHelper';
import { PLAN_DOWNGRADE_RESULT, ROUTES } from '../../../app/routing';
import { accountService } from '../../../app/services/account.service';
import HttpHelper from '../../../app/helpers/HttpHelper';
import { SessionStorageService } from '../../../app/services/storage.service';

// img
import redCross from '../../../res/images/icons/pic-not-valide.svg'

import ButtonField from '../../../components/form/field/ButtonField';
import { billingService } from '../../../app/services/billing.service';

class DowngradePlanView extends React.Component {
    constructor(props) {
        super(props);

        const targetPlan = getAccountPlan(props.match.params.target_plan);
        this.state = {
            isLoaded: false,
            targetPlan: targetPlan,
            current_plan: null,
            account: null,
            downgradeLoading: false
        };
        this.fetchInitial = this.fetchInitial.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onDowngradeClick = this.onDowngradeClick.bind(this);
        this.getDowngradeAdvantagesList = this.getDowngradeAdvantagesList.bind(this);

        const referrer = (props.location.state && props.location.state.referrer) ? props.location.state.referrer : null;
        if (!SessionStorageService.get('update_plan_referrer') && referrer) {
            SessionStorageService.set('update_plan_referrer', referrer)
        }
    }

    componentDidMount() {
        this._ismounted = true;
        this.fetchInitial();
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    fetchInitial() {
        const isAdmin = UserHelper.isAdmin();
        if (!isAdmin) {
            this.props.history.push(ROUTES.UNAUTHORIZED);
        }
        accountService.getAccountData(
            (response) => {
                var expiration_date =  moment(response.expiration_date, "YYYY-MM-DD").add(1, 'days');
                var formattedDate = response.expiration_date ? expiration_date.format("DD/MM/YYYY") : null;
                this.setState({
                    isLoaded: true,
                    formattedDate: formattedDate,
                    current_plan: getAccountPlan(response.plan_key)
                });
            },
            (error) => {
                if(!this._ismounted){
                    return;
                }
                this.setState({
                    isLoaded: true
                });
                this.props.addToast(HttpHelper.getErrorMessage(error), { type: 'error', autoDismiss: true });
            }
        );
    }

    onCancelClick() {
        const update_plan_referrer = SessionStorageService.get('update_plan_referrer');
        SessionStorageService.delete('update_plan_referrer');
        if (update_plan_referrer) {
            this.props.history.push(update_plan_referrer);
        } else {
            this.props.history.push(ROUTES.HOME);
        }
    }

    getDowngradeAdvantagesList() {
        const { t } = this.props;
        let ret = [];
        let targetRet = [];
        const targetPlanKey = this.state.targetPlan;
        const currentPlanKey = this.state.current_plan;
        const planAdvantages = getPlanAdvantages(t);
        const targetPlan = planAdvantages[targetPlanKey.toLowerCase()];
        const currentPlan = planAdvantages[currentPlanKey.toLowerCase()];

        for (const advantageGroup of targetPlan.advantages) {
            for (const advantage of advantageGroup.advantage) {
                targetRet.push(advantage);
            }
        }
        for (const advantageGroup of currentPlan.advantages) {
            for (const advantage of advantageGroup.advantage) {
                if (!targetRet.includes(advantage)) {
                    ret.push(advantage);
                }
            }
        }
        return ret;
    }

    onDowngradeClick() {
        this.setState({ downgradeLoading: true });
        billingService.downgradeSubscribtion(
            this.state.targetPlan,
            (response) => {
                const urlParams = {
                    status: 'success'
                }
                this.props.history.push(HttpHelper.addParametersToUrl(PLAN_DOWNGRADE_RESULT(this.state.targetPlan), urlParams), { referrer: document.location.pathname });
            }, (error) => {
                this.setState({ downgradeLoading: false });
                const error_msg = HttpHelper.getErrorMessage(error);
                const urlParams =  {
                    status: 'error', 
                    message: error_msg 
                }
                this.props.history.push(HttpHelper.addParametersToUrl(PLAN_DOWNGRADE_RESULT(this.state.targetPlan), urlParams), { referrer: document.location.pathname });
            });
    }

    render() {
        const { t, history } = this.props;
        let page_title = `${t('Switch to {{plan_libelle}} plan', {plan_libelle: this.state.targetPlan})} - ${globalConstants.WEBSITE_TITLE}`;
        if (this.state.isLoaded) {
            const downgradeAdvantages = this.getDowngradeAdvantagesList();
            return (
                <Fragment>
                    <Helmet>
                        <title>{page_title}</title>
                    </Helmet>
                    <PlanHeader history={history} title={t('Switch to ')} boldTitle={t('{{plan_libelle}} plan', { plan_libelle: this.state.targetPlan })} />
                    <div className="central-content downgrade-box-content">
                        <div className="padding-top-20 resume-downgrade">
                            <span>{t('Your switch to Free plan will take effect on ')}<span className="expired-date">{this.state.formattedDate}</span></span><br />
                            <span>{t('Choose which projects you want to archive before this date otherwise, ')}</span><br />
                            <span className="">{t('the oldest projects will be automatically archived.')}</span>
                        </div>
                        <div className="downgrade-box">
                            <div className="title">
                                {t('What changes for you')}
                            </div>
                            <div className="sub-title">
                                {t('With {{plan_libelle}} plan you will no longer take advantage of these features', { plan_libelle: this.state.targetPlan })}
                            </div>
                            <div className="list-advantages">
                                {downgradeAdvantages.map((item, i) => {
                                    return (
                                        <div key={item} className="advantage-raw">
                                            <img className="icon-check" src={redCross} alt="cross_img" />
                                            <div className="advantage-item">{item}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="btn-position-downgrade">
                            <button className="button cancel-btn" onClick={this.onCancelClick} disabled={this.state.downgradeLoading}>{t('Cancel')}</button>
                            <ButtonField
                                loaderPosition="right"
                                className="button light-blue"
                                formElementClassName="display-inline-block"
                                loading={this.state.downgradeLoading}
                                onClick={this.onDowngradeClick}>
                                {t('Downgrade to {{plan_libelle}} plan', { plan_libelle: this.state.targetPlan })}
                            </ButtonField>
                        </div>
                    </div>
                    <div className="padding-top-20" />
                </Fragment>
            );
        }
        return <LoadingView title={page_title} />;
    }
}

function mapStateToProps(state) {
    return {};
}

export default withTranslation()(connect(mapStateToProps)(DowngradePlanView));