import '../settings.css';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { globalConstants } from "../../../app/constants";
import { connect } from 'react-redux';
import Header from "../../../components/header/Header";
import Footer from '../../../components/footer/Footer';
import LoadingView from '../../loading/LoadingView';

import { withTranslation } from 'react-i18next';
import SettingsMenu from '../elements/SettingsMenu';
import { ROUTES } from '../../../app/routing';
import { accountService } from '../../../app/services/account.service';
import TextField from '../../../components/form/field/TextField';
import SelectField from '../../../components/form/field/SelectField';
import { getTimeZones } from "@vvo/tzdb";
import DateHelper from '../../../app/helpers/DateHelper';
import HttpHelper from '../../../app/helpers/HttpHelper';
import SettingsSaveBar from '../elements/SettingsSaveBar';
import { uniqueId } from 'lodash';
import Link from "../../../components/Link";
import UserHelper from '../../../app/helpers/UserHelper';
import { userService } from '../../../app/services/user.service';
import { COUNTRY_OPTION } from '../../../components/selectFieldCountry/SelectFieldCountry';
import SettingsLoadingView from '../../loading/SettingsLoadingView';

class DefaultAccountSettingsView extends React.Component {
    constructor(props) {
        super(props);

        let groups = {};
        let timezonesOptions = [];
        const timeZones = getTimeZones();
        timeZones.forEach(element => {
            if (element.continentName) {
                if (!groups.hasOwnProperty(element.continentName)) {
                    groups[element.continentName] = [];
                }
                let simpleName = element.name.replace(element.continentName + "/", "");
                if (element.mainCities && element.mainCities.length > 0) {
                    simpleName = element.mainCities[0];
                }
                let time = element.currentTimeOffsetInMinutes;
                let timeString = (time >= 0) ? '+' : '-';
                timeString += DateHelper.getMinutesTohhmm(Math.abs(time));
                let name = `UTC ${timeString} ${simpleName}`;

                groups[element.continentName].push({
                    label: name,
                    value: element.name,
                    target: {
                        name: 'timezone',
                        value: element.name
                    }
                });
            }
        });
        for (const [key, value] of Object.entries(groups)) {
            timezonesOptions.push({
                label: key,
                options: value
            });
        }

        this.state = {
            isLoaded: false,
            inlineLoading: false,
            formKey: uniqueId(),
            timezonesOptions: timezonesOptions,
            formData: {},
            formErrors: {}
        };

        this.fetchInitial = this.fetchInitial.bind(this);
        this.getNbChanges = this.getNbChanges.bind(this);
        this.cancelChanges = this.cancelChanges.bind(this);
        this.handleChangeCountry = this.handleChangeCountry.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.getTimeZoneDefaultValue = this.getTimeZoneDefaultValue.bind(this);
        this.getErrorMessage = this.getErrorMessage.bind(this);
        this.checkMandatoryFields = this.checkMandatoryFields.bind(this);
        this.displayToastErrorMessage = this.displayToastErrorMessage.bind(this);
        this.handleErrorInitial = this.handleErrorInitial.bind(this);
    }

    componentDidMount() {
        this._ismounted = true;
        this.fetchInitial();
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    displayToastErrorMessage(error, updatedState){
        if(!this._ismounted){
            return;
        }
        this.props.addToast(HttpHelper.getErrorMessage(error), { type: 'error', autoDismiss: true });
        if (updatedState) {
            this.setState(updatedState);
        }
    }

    fetchInitial() {
        if (this._ismounted) {
            this.setState({ inlineLoading: true });
        }
        accountService.getAccountData(
            (response) => {
                this.setState({
                    formData: response,
                    initialFormData: response,
                    isLoaded: true,
                    inlineLoading: false
                });
                this.checkMandatoryFields(response);
            },
            (error) => {
                this.handleErrorInitial(error);
            }
        );
    }

    getTimeZoneDefaultValue() {
        if (!this.state.formData.timezone) {
            return null;
        }
        let ret = null;
        this.state.timezonesOptions.forEach(group => {
            group.options.forEach(element => {
                if (element.value === this.state.formData.timezone) {
                    ret = element;
                }
            });
        });
        return ret;
    }

    handleChangeCountry(event) {
        let formData = Object.assign({}, this.state.formData);
        formData.country = event.target.value;
        this.setState({
            formData: formData
        });
    }

    handleChange(event) {
        let formData = Object.assign({}, this.state.formData);
        formData[event.target.name] = event.target.value;
        this.checkMandatoryFields(formData);
        this.setState({
            formData: formData
        });
    }

    getNbChanges() {
        let nbChanges = 0;
        const formData = this.state.formData;
        const initialFormData = this.state.initialFormData;
        for (const [key, value] of Object.entries(formData)) {
            if (!initialFormData.hasOwnProperty(key) || initialFormData[key] !== value) {
                nbChanges++;
            }
        }
        return nbChanges;
    }

    cancelChanges() {
        this.setState({
            formData: this.state.initialFormData,
            formKey: uniqueId()
        });
        this.checkMandatoryFields(this.state.initialFormData);
    }

    handleErrorInitial(error) {
        this.displayToastErrorMessage(error, {
            isLoaded: true,
            inlineLoading: false
        });
    }

    saveChanges() {
        const { t } = this.props;
        if(!this.checkMandatoryFields()){
            return false;
        }
        this.setState({
            inlineLoading: true
        });
        const formData = Object.assign({}, this.state.formData);
        let current_account = UserHelper.getCurrentAccount()
        formData.account_id = current_account.id
        accountService.editAccount(
            formData,
            (response) => {
                this.setState({
                    inlineLoading: false,
                    initialFormData: this.state.formData,
                });
                this.props.addToast(t('Changes saved!'), { type: 'success', autoDismiss: true });
                userService.updateCurrentUser()
            },
            (error) => {
                this.handleErrorInitial(error);
            }
        );
    }

    checkMandatoryFields(customFormData){
        const { t } = this.props;
        const mandatoryFields = [
            'country',
            'company',
            'adress1',
            'city'
        ];
        let hasNoError = true;
        let formErrors = {};
        const formData = customFormData || Object.assign({}, this.state.formData);
        for (const key of mandatoryFields) {
            if(!formData[key]){
                formErrors[key] = t('This field is required');
                hasNoError = false;
            }
        }
        this.setState({
            formErrors: formErrors
        })
        return hasNoError;
    }

    getErrorMessage(target) {
        if (this.state.formErrors && (this.state.formErrors.hasOwnProperty(target))) {
            return this.state.formErrors[target];
        }
        return null;
    }

    render() {
        const { t } = this.props;
        let page_title = `${t('Default Account Settings')} - ${globalConstants.WEBSITE_TITLE}`;
        if (this.state.isLoaded) {
            let defaultOptions = COUNTRY_OPTION.filter(option => {
                return option.value === this.state.formData.country
            });
            return (
                <Fragment>
                    <Helmet>
                        <title>{page_title}</title>
                    </Helmet>
                    <Header />
                    <div className="padding-top-30" />
                    <div className="central-content settings-content" key={this.state.formKey}>
                        <div className="left">
                            <SettingsMenu current={ROUTES.SETTINGS_DEFAULT_ACCOUNT} history={this.props.history} />
                        </div>
                        <div className="right">
                            <div className="padding-top-32" />
                            <div className="font-inter-700 font-size-28 color-000 padding-bottom-40">{t('Default Account Settings')}</div>
                            <TextField
                                type="text"
                                label={t('Account Name')}
                                name="account_name"
                                defaultValue={this.state.formData.account_name}
                                disabled={this.state.inlineLoading}
                                onChange={this.handleChange}
                                errorMessage={this.getErrorMessage('account_name')}
                            />
                            <SelectField
                                label={t('Time Zone')}
                                name="timezone"
                                defaultValue={this.getTimeZoneDefaultValue()}
                                options={this.state.timezonesOptions}
                                onChange={this.handleChange}
                                errorMessage={this.getErrorMessage('timezone')}
                            />
                            <div className="padding-top-60" />
                            <div className="font-inter-700 font-size-18 color-000 padding-bottom-8">
                                {t('Company Informations')}
                            </div>
                            <div className="font-inter-500 font-size-15 color-7f7f7f line-height-22 padding-bottom-40">
                                {t("These informations will be used by default where necessary. If you're looking to update your business information for billing, go to")} <Link to={ROUTES.SETTINGS_PLAN_AND_BILLING_BASE} className="link-blue">{t('Plan & Billing')}</Link>.
                            </div>
                            <TextField
                                type="text"
                                label={t('Company Name')}
                                name="company"
                                defaultValue={this.state.formData.company}
                                disabled={this.state.inlineLoading}
                                onChange={this.handleChange}
                                errorMessage={this.getErrorMessage('company')}
                            />
                            <TextField
                                type="text"
                                label={t('Company Domain')}
                                name="domain"
                                defaultValue={this.state.formData.domain}
                                disabled={this.state.inlineLoading}
                                onChange={this.handleChange}
                                errorMessage={this.getErrorMessage('domain')}
                            />
                            <TextField
                                type="text"
                                label={t('Company Address')}
                                name="adress1"
                                defaultValue={this.state.formData.adress1}
                                disabled={this.state.inlineLoading}
                                onChange={this.handleChange}
                                errorMessage={this.getErrorMessage('adress1')}
                            />
                            <TextField
                                type="text"
                                label={t('Suit / Unit')}
                                name="adress2"
                                defaultValue={this.state.formData.adress2}
                                disabled={this.state.inlineLoading}
                                onChange={this.handleChange}
                                errorMessage={this.getErrorMessage('adress2')}
                            />
                            <TextField
                                type="text"
                                label={t('City')}
                                name="city"
                                defaultValue={this.state.formData.city}
                                disabled={this.state.inlineLoading}
                                onChange={this.handleChange}
                                errorMessage={this.getErrorMessage('city')}
                            />
                            <TextField
                                type="text"
                                label={t('State / Province')}
                                name="state"
                                defaultValue={this.state.formData.state}
                                disabled={this.state.inlineLoading}
                                onChange={this.handleChange}
                                errorMessage={this.getErrorMessage('state')}
                            />
                            <TextField
                                type="text"
                                label={t('Postal Code')}
                                name="zip_code"
                                defaultValue={this.state.formData.zip_code}
                                disabled={this.state.inlineLoading}
                                onChange={this.handleChange}
                                errorMessage={this.getErrorMessage('zip_code')}
                            />
                            <SelectField
                                type="text"
                                label={t('Country')}
                                name="country"
                                options={COUNTRY_OPTION}
                                defaultValue={defaultOptions}
                                onChange={this.handleChangeCountry}
                                errorMessage={this.getErrorMessage('country')}
                            />
                        </div>
                    </div>
                    <div className="padding-top-20" />
                    <Footer />
                    <SettingsSaveBar
                        nbChanges={this.getNbChanges()}
                        cancelChanges={this.cancelChanges}
                        saveChanges={this.saveChanges}
                        loading={this.state.inlineLoading}
                    />
                </Fragment>
            );
        }
        return <SettingsLoadingView title={page_title} withHeader={true} rightTitle={t('Default Account Settings')} settingsMenuProps={{current:ROUTES.SETTINGS_DEFAULT_ACCOUNT, history:this.props.history}} />;
    }
}

function mapStateToProps(state) {
    return {};
}

export default withTranslation()(connect(mapStateToProps)(DefaultAccountSettingsView));