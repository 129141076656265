import React, { Fragment } from 'react';
import { loadStripe } from "@stripe/stripe-js";
import { billingService } from '../../app/services/billing.service';
import { Elements, ElementsConsumer, PaymentElement } from "@stripe/react-stripe-js";
import Loader from '../loader/Loader';
import HttpHelper from '../../app/helpers/HttpHelper';
import { globalConstants } from '../../app/constants';

const stripePromise = loadStripe(globalConstants.STRIPE_API_PUBLIC_KEY, { locale: globalConstants.AVAILABLE_LANGUAGES[0] });
const appearance = { theme: 'stripe' };
const paymentElementOption = {
    fields:{
        billingDetails:{
            email: 'never',
            phone: 'never',
            address:{
                country: 'never',
                postalCode: 'never',
                state: 'never',
                city: 'never',
                line1: 'never',
                line2: 'never'
            }
        }
    }
}
class StripeForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isFormReady: false,
            hasFormLoadError: false,
            clientSecret: null,
        };

        this.reloadData = this.reloadData.bind(this);
        this.fetchClientSecret = this.fetchClientSecret.bind(this);
        this.isPaymentElementReady = this.isPaymentElementReady.bind(this);
        this.savePaymentMethod = this.savePaymentMethod.bind(this);
        this.checkSetup = this.checkSetup.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this._ismounted = true;
        this.fetchClientSecret();
    }

    componentWillUnmount () {
        this._ismounted = false;
    }

    reloadData() {
        this.setState({
            clientSecret: null,
            isFormReady: false,
        });

        this.fetchClientSecret();
    }

    fetchClientSecret() {
        billingService.setupIntent(
            (response) => {
                this.setState({ clientSecret: response.clientSecret });
            },
            (error) => {
                this.setState({ hasFormLoadError: true })
                if (this.props.addToast && this._ismounted) {
                    this.props.addToast(HttpHelper.getErrorMessage(error), { type: 'error', autoDismiss: true });
                }
            }
        );
    }


    isPaymentElementReady() {
        this.setState({
            isFormReady: true,
        })
    }

    async handleSubmit (event, stripe, elements) {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        if(this.props.checkBeforeSubmit) {
            const check = this.props.checkBeforeSubmit();
            if(!check){
                return false;
            }
        }
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        this.setState({ isFormReady: false })
        const result = await stripe.confirmSetup({
            //`Elements` instance that was used to create the Payment Element
            elements,
            redirect: "if_required",
            confirmParams: {
                return_url: `${window.location.protocol}//${window.location.host}/`,
                payment_method_data:{
                    billing_details:{
                        email: this.props.account.billingEmail,
                        phone: '',
                        address:{
                            country: this.props.account.country,
                            postal_code: this.props.account.zip_code,
                            state: this.props.account.state,
                            city: this.props.account.city,
                            line1: this.props.account.adress1,
                            line2: this.props.account.adress2
                        }
                    }
                },
            }
        });
        if (result.error) {
            if (this.props.addToast) {
                this.props.addToast(HttpHelper.getErrorMessage(result.error.message), { type: 'error', autoDismiss: true });
            }
            if (this.props.handleSubmitError) {
                this.props.handleSubmitError(result.error)
            }
            //this.reloadData()
            this.setState({ isFormReady: true })
        } else {
            this.checkSetup(stripe)
        }
    }

    checkSetup = async (stripe) => {
        const result = await stripe.retrieveSetupIntent(this.state.clientSecret)
        if (result.setupIntent.status == 'succeeded') {
            this.savePaymentMethod(result.setupIntent.payment_method)
        } 
        else if (result.setupIntent.status == 'processing') {
            if (this.props.addToast) {
                this.props.addToast('Processing payment details. We\'ll update you when processing is complete.', { type: 'error', autoDismiss: true });
            }
        } 
        else if (result.setupIntent.status == 'requires_payment_method') {
            if (this.props.addToast) {
                this.props.addToast('Failed to process payment details. Please try another payment method.', { type: 'error', autoDismiss: true });
            }
        }
    };

    savePaymentMethod(paymentMethod) {
        billingService.savePaymentMethod(
            paymentMethod,
            (response) => {
                if (this.props.handleSubmitSuccess) {
                    this.props.handleSubmitSuccess(response)
                }
                this.reloadData()
            },
            (error) => {
                if (this.props.addToast && this._ismounted) {
                    this.props.addToast(HttpHelper.getErrorMessage(error), { type: 'error', autoDismiss: true });
                }
                if (this.props.handleSubmitError) {
                    this.props.handleSubmitError(error)
                }
                this.reloadData()
            }
        );
    }


    render() {
        const { clientSecret } = this.state;
        return (
            <Fragment>
                {clientSecret && (
                    <Elements options={{ clientSecret, appearance }} stripe={stripePromise}>
                        <ElementsConsumer>
                            {({ stripe, elements }) => (
                                <form onSubmit={(e) => this.handleSubmit(e, stripe, elements)}>
                                    <PaymentElement options={paymentElementOption} onReady={this.isPaymentElementReady} />
                                    <div className="padding-top-48">
                                        {this.state.isFormReady && <button className="button">{this.props.buttonText}</button>}
                                        {!this.state.isFormReady && <Loader />}
                                    </div>
                                </form>
                            )}
                        </ElementsConsumer>

                    </Elements>
                )}
                {!clientSecret && !this.state.hasFormLoadError && <div className="padding-top-48"><Loader /></div>}
            </Fragment>
        );
    }
}

export default StripeForm;