class DateHelper { }


DateHelper.getSecondsTohhmmss = (secs, fullInfo) => {
    secs = parseInt(secs);
    fullInfo = (typeof fullInfo != 'undefined') ? fullInfo : false;
    const pad = (num) => {
        return ("0"+num).slice(-2);
    }
    var minutes = Math.floor(secs / 60);
    secs = secs % 60;
    var hours = Math.floor(minutes / 60)
    minutes = minutes % 60;
    if(fullInfo || hours){
        return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
    }
    if(minutes){
        return `00:${pad(minutes)}:${pad(secs)}`;
    }
    if(secs){
        return `00:00:${pad(secs)}`;
    }
    return '0';
}

DateHelper.getMinutesTohhmm = (minutes) => {
    minutes = parseInt(minutes);
    const pad = (num) => {
        return ("0"+num).slice(-2);
    }
    var hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    return `${pad(hours)}:${pad(minutes)}`;
}

DateHelper.getProperNb = (nb, k) => {
    return (Math.round(nb * 100) / 100)+' '+k;
}

export default DateHelper;