import '../plan.css';
import './upgradePlanView.css';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import ButtonField from '../../../components/form/field/ButtonField';
import TextField from '../../../components/form/field/TextField';
import SelectField from '../../../components/form/field/SelectField';
import PlanHeader from '../elements/PlanHeader';
import RadioGroupField from '../../../components/form/field/RadioGroupField';
import { COUNTRY_OPTION } from '../../../components/selectFieldCountry/SelectFieldCountry';

import StripeForm from '../../../components/stripe/StripeForm';
import ListOptSelect from '../../../components/listOptSelect/ListOptSelect';
import StringHelper from '../../../app/helpers/StringHelper';


class UpgradePlanStep0 extends React.Component {
    constructor(props) {
        super(props);
        this.getErrorMessage = this.getErrorMessage.bind(this);
    }

    getErrorMessage(target) {
        if (this.props.formErrors && (this.props.formErrors.hasOwnProperty(target))) {
            return this.props.formErrors[target];
        }
        return null;
    }

    getSelectedFrequence() {
        const { t, selectedFreq } = this.props;
        return selectedFreq === 'Monthly' ? t('Billed monthly') : t('Billed yearly')
    }

    render() {
        const { t, pageTitle, history, currentPlan, targetPlan, forcedPlanFreq, selectedFreq, onChangeFreq, frequence, defaultOptions, formData, handleChangeCountry, handleChange, handleChangeDiscount, inlineLoading, account, checkMandatoryFields, handleSubmitSuccess, handleSubmitError, addToast, currencyCode } = this.props;
        const language = navigator.language
        return (
            <Fragment>
                <Helmet>
                    <title>{pageTitle}</title>
                </Helmet>
                <PlanHeader history={history} title={t('Upgrade to ')} boldTitle={t('{{plan_libelle}} plan', { plan_libelle: targetPlan })} />
                <div className="central-content">
                    <div className="padding-top-20" />
                    <div className="content-column">
                        <div className="column-percent-33 responsive-900 text-align-right float-right">
                            <div className="upgrade-plan-info-card">
                                <div className="upgrade-plan-info-box-selector">
                                    <div className="font-size-15 line-height-1 color-fff font-inter-700">{targetPlan} {t('plan')}</div>
                                    {!!forcedPlanFreq && (
                                        <div className="font-size-13 line-height-1-2 color-f2f2f2 font-inter-500 padding-top-2">
                                            {this.getSelectedFrequence()}
                                        </div>
                                    )}
                                    {!forcedPlanFreq && (
                                        <ListOptSelect
                                            displayFamily="frequence"
                                            customClass="little-white"
                                            defaultActive={selectedFreq}
                                            position="left"
                                            onChange={onChangeFreq}
                                            lists={{
                                                frequence: frequence
                                            }} />
                                    )}
                                </div>
                                <div className="upgrade-plan-info-box-price">
                                    <div className="content-column">
                                        <div className="column-percent-50">
                                            <div className="font-size-13 line-height-1-5 color-7f7f7f font-inter-500 text-break-line">
                                                {targetPlan} {t('plan')} - {selectedFreq === 'Monthly' ? '1 month' : '1 year'}
                                            </div>
                                        </div>
                                        <div className="column-percent-50">
                                            <div className="font-size-15 color-7f7f7f font-inter-700 text-align-right">
                                                {new Intl.NumberFormat(language, { style: 'currency', currency: currencyCode }).format(currentPlan.priceHT)}
                                            </div>
                                        </div>
                                    </div>
                                    {(currentPlan.credit !== 0) &&
                                        <Fragment>
                                            <div className="content-column margin-top-16">
                                                <div className="column-percent-50">
                                                    <div className="font-size-13 line-height-1-5 color-7f7f7f font-inter-500">
                                                        {t('Credits')}
                                                    </div>
                                                </div>
                                                <div className="column-percent-50">
                                                    <div className="font-size-15 color-7f7f7f font-inter-700 text-align-right">
                                                        {new Intl.NumberFormat(language, { style: 'currency', currency: currencyCode }).format(currentPlan.credit)}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="content-column">
                                                <div className="font-size-10 line-height-1-5 color-7f7f7f font-inter-500">
                                                    {t('Credits for {{plan}} end', { plan: targetPlan })}: {new Intl.NumberFormat(language, { style: 'currency', currency: currencyCode }).format(currentPlan.credit)}
                                                </div>
                                            </div>
                                        </Fragment>
                                    }
                                    {(currentPlan.discount !== 0) &&
                                        <Fragment>
                                            <div className="content-column margin-top-16">
                                                <div className="column-percent-50">
                                                    <div className="font-size-13 line-height-1-5 color-7f7f7f font-inter-500">
                                                        {t('Discount')}
                                                    </div>
                                                </div>
                                                <div className="column-percent-50">
                                                    <div className="font-size-15 color-7f7f7f font-inter-700 text-align-right">
                                                        {new Intl.NumberFormat(language, { style: 'currency', currency: currencyCode }).format(currentPlan.discount)}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="content-column">
                                                <div className="font-size-10 line-height-1-5 color-7f7f7f font-inter-500">
                                                    {t('Discount for {{plan}}', { plan: targetPlan })} {t(': {{discountCode}}', { discountCode: currentPlan.discountCode})}
                                                </div>
                                            </div>
                                        </Fragment>
                                    }
                                    <div className="upgrade-plan-info-box-divider-2" />
                                    <div className="content-column">
                                        <div className="column-percent-50">
                                            <div className="font-size-13 line-height-1-5 color-7f7f7f font-inter-500">{t('VAT')}</div>
                                        </div>
                                        <div className="column-percent-50">
                                            <div className="font-size-15 color-7f7f7f font-inter-700 text-align-right">
                                                {new Intl.NumberFormat(language, { style: 'currency', currency: currencyCode }).format(currentPlan.vat)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="upgrade-plan-info-box-divider" />
                                    <div className="content-column">
                                        <div className="column-percent-50">
                                            <div className="font-size-15 line-height-1-3 color-2f2f2f font-inter-700">{t('Due today')}</div>
                                        </div>
                                        <div className="column-percent-50">
                                            <div className="font-size-15 color-2f2f2f font-inter-700 text-align-right">
                                                {new Intl.NumberFormat(language, { style: 'currency', currency: currencyCode }).format(currentPlan.priceTTC)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column-percent-66 responsive-900 float-right">
                            <div className="padding-top-32" />
                            <div className="font-weight-500 font-size-18 color-000 padding-bottom-20">{t('1. Company info')}</div>
                            <div className="content">
                                <SelectField
                                    label={t('Country')}
                                    name="country"
                                    defaultValue={defaultOptions}
                                    options={COUNTRY_OPTION}
                                    onChange={handleChangeCountry}
                                    errorMessage={this.getErrorMessage('country')}
                                />
                                <TextField
                                    label={t('Company name')}
                                    type="text"
                                    name="company"
                                    defaultValue={formData.company}
                                    onChange={handleChange}
                                    errorMessage={this.getErrorMessage('company')}
                                />
                                <div className="content-column content-column-form-upgrade-plan">
                                    <div className="column-percent-50 responsive-900">
                                        <TextField
                                            type="text"
                                            label={t('Company Address')}
                                            name="adress1"
                                            defaultValue={formData.adress1}
                                            disabled={inlineLoading}
                                            onChange={handleChange}
                                            errorMessage={this.getErrorMessage('adress1')}
                                        />
                                    </div>
                                    <div className="column-percent-50 responsive-900">
                                        <TextField
                                            type="text"
                                            label={t('Suit / Unit (optional)')}
                                            name="adress2"
                                            defaultValue={formData.adress2}
                                            disabled={inlineLoading}
                                            onChange={handleChange}
                                            errorMessage={this.getErrorMessage('adress2')}
                                        />
                                    </div>
                                </div>
                                <div className="content-column content-column-form-upgrade-plan">
                                    <div className="column-percent-50 responsive-900">
                                        <TextField
                                            type="text"
                                            label={t('City')}
                                            name="city"
                                            defaultValue={formData.city}
                                            disabled={inlineLoading}
                                            onChange={handleChange}
                                            errorMessage={this.getErrorMessage('city')}
                                        />
                                    </div>
                                    <div className="column-percent-50 responsive-900">
                                        <TextField
                                            type="text"
                                            label={t('State / Province (optional)')}
                                            name="state"
                                            defaultValue={formData.state}
                                            disabled={inlineLoading}
                                            onChange={handleChange}
                                            errorMessage={this.getErrorMessage('state')}
                                        />
                                    </div>
                                </div>
                                <div className="content-column content-column-form-upgrade-plan">
                                    <div className="column-percent-50 responsive-900">
                                        <TextField
                                            type="text"
                                            label={t('Postal code (optional)')}
                                            name="zip_code"
                                            defaultValue={formData.zip_code}
                                            disabled={inlineLoading}
                                            onChange={handleChange}
                                            errorMessage={this.getErrorMessage('zip_code')}
                                        />
                                    </div>
                                </div>
                                <div className="font-size-15 color-7f7f7f padding-bottom-10">
                                    {t('Is this a business purchase?')}
                                </div>
                                <RadioGroupField
                                    name="is_business_purchase"
                                    hideLabel={true}
                                    options={[
                                        { value: 'yes', label: t('Yes') },
                                        { value: 'no', label: t('No') }
                                    ]}
                                    defaultValue={formData.is_business_purchase || 'no'}
                                    onChange={handleChange}
                                    errorMessage={this.getErrorMessage('is_business_purchase')}
                                />
                                {formData.is_business_purchase === 'yes' &&
                                    <Fragment>
                                        <div className="content-column content-column-form-upgrade-plan">
                                            <div className="column-percent-50 responsive-900">
                                                <TextField
                                                    type="text"
                                                    label={t('VAT ID (optional)')}
                                                    name="vat_id"
                                                    defaultValue={formData.vat_id}
                                                    disabled={false}
                                                    onChange={handleChange}
                                                    errorMessage={this.getErrorMessage('vat_id')}
                                                />
                                            </div>
                                        </div>
                                        <div className="include-country-line">
                                            {t('Please include your country code')}
                                        </div>
                                    </Fragment>
                                }
                                <div className="content-column content-column-form-upgrade-plan">
                                    <div className="column-percent-50 responsive-900">
                                        <TextField
                                            type="text"
                                            label={t('Add promotion code')}
                                            name="discount_code"
                                            defaultValue={formData.discount_code}
                                            disabled={inlineLoading}
                                            onChange={handleChange}
                                            errorMessage={this.getErrorMessage('discount_code')}
                                        />
                                        
                                        <ButtonField
                                            onClick={handleChangeDiscount}
                                            value={t('Apply code')}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="padding-top-40" />
                            <div className="upgrade-plan-divider" />
                            <div className="padding-top-40" />
                            <div className="font-weight-500 font-size-18 color-000 padding-bottom-20">{t('2. Payment method')}</div>
                            <div className="content">
                                <StripeForm
                                    addToast={addToast}
                                    account={account}
                                    buttonText={t('Review order')}
                                    checkBeforeSubmit={checkMandatoryFields}
                                    handleSubmitSuccess={handleSubmitSuccess}
                                    handleSubmitError={handleSubmitError}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="padding-top-80" />
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

export default withTranslation()(connect(mapStateToProps)(UpgradePlanStep0));