import React from "react";
import { withTranslation } from "react-i18next";
import ButtonField from "../../components/form/field/ButtonField";
import imgEmptyState from "../../res/images/empty-state-inspect.svg";
import "./EmptyState.css";
import Link from "../../components/Link";
import { PROJECT_ADD_MEDIA, PROJECT_SEARCH_MEDIA } from "../../app/routing";
import { planJsonLogicService } from "../../app/services/plan.jsonLogic.service";
import { PLAN_JSON_LOGIC_KEYS } from "../../app/constants";
import { Typography } from "@mui/material";

class EmptyState extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayEnterpriseLink: false,
    };
  }

  componentDidMount() {
    planJsonLogicService.playRule(
      PLAN_JSON_LOGIC_KEYS.ENTERPRISE_FEATURE,
      {},
      (response) => {
        const enterpriseFeatureIsDisabled = response.status === "error";
        this.setState({
          displayEnterpriseLink: !enterpriseFeatureIsDisabled,
        });
      },
      (error) => {}
    );
  }
  render() {
    const { t } = this.props;
    return (
      <div className="central-content">
        <div
          style={{ paddingTop: "78px" }}
          className="empty-state-content display-flex justify-content-space-between responsive-emptyState"
        >
          <div id="EmptyState-box">
            <div className="font-inter-700 font-size-24 padding-bottom-12 color-616161 text-align-left">
              {t("Reveal hidden insights at granular level ")}{" "}
              {t("with Inspect")}
            </div>
            <div className="font-inter-400 font-size-15 padding-bottom-36 color-7f7f7f text-align-left margin-top-32 line-height-1-5">
              {t(
                "Inspect is your playground to dive into the nitty-gritty of your data. Get insights at the level of a single keyword, and understand its context. Unlock the full potential of Inspect by adding data sources and take your analysis to the next level."
              )}
            </div>
            <div style={{ textAlign: "center" }}>
              <Link to={PROJECT_ADD_MEDIA(this.props.projectId)}>
                <button className="button blue">{t("Add data source")}</button>
              </Link>
            </div>
            {this.state.displayEnterpriseLink && (
              <div className="font-inter-400 font-size-15 color-000 text-align-left margin-top-32 line-height-1-5 display-flex align-items-center gap-7">
                {t("Don’t have link or file to import ?")}

                <Typography
                  onClick={() =>
                    this.props.history.push(
                      PROJECT_SEARCH_MEDIA(this.props.projectId)
                    )
                  }
                  sx={{
                    color: "#306ED6",
                    fontFamily: "Inter",
                    fontSize: "15px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal",
                    cursor: "pointer",
                  }}
                >
                  {t("Search for it")}
                </Typography>
              </div>
            )}
          </div>
          <div style={{ width: "50%" }}>
            <img alt="" src={imgEmptyState} className="img-100" />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(EmptyState);
