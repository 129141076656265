import HttpHelper from '../helpers/HttpHelper';
import axios from 'axios';


function uploadModel (formData, onUploadProgress, onSuccess, onError) {
    return axios.request({
        method: HttpHelper.method.POST,
        url: HttpHelper.getApiUrl('/api/v1/model/upload'),
        headers: HttpHelper.authHeader(),
        data: formData,
        onUploadProgress: (p) => {
            let progress = Math.round((p.loaded / p.total) * 100);
            if (progress >= 100) {
                progress = 99
            }
            HttpHelper.handleFunction(onUploadProgress, progress);
        }
    }).then(
        response => {
            const { data } = response;
            HttpHelper.handleFunction(onSuccess, data);
            return data;
        },
        error => {
            const { response } = error;
            HttpHelper.handleFunction(onError, response.data);
        }
    )
}

function switchUsedModel (formData, onSuccess, onError) {
    return HttpHelper.authApiFetch('/api/v1/model/switch', HttpHelper.method.POST, formData, onSuccess, onError);
}

function getInfosModel (formData, onSuccess, onError) {
    return HttpHelper.authApiFetch('/api/v1/model', HttpHelper.method.GET, formData, onSuccess, onError);
}

function getInfosModels (onSuccess, onError) {
    return HttpHelper.authApiFetch('/api/v1/models', HttpHelper.method.GET, null, onSuccess, onError);
}

function getScore (formData, onSuccess, onError) {
    return HttpHelper.authApiFetch('/api/v1/model/get-sentence-score', HttpHelper.method.POST, formData, onSuccess, onError);
}

function getEmotion (formData, onSuccess, onError) {
    return HttpHelper.authApiFetch('/api/v1/model/get-sentence-emotion', HttpHelper.method.POST, formData, onSuccess, onError);
}

function launchAnalyse (formData, onSuccess, onError) {
    return HttpHelper.authApiFetch('/api/v1/model/launch-analyse', HttpHelper.method.POST, formData, onSuccess, onError);
}

function launchReload (formData, onSuccess, onError) {
    return HttpHelper.authApiFetch('/api/v1/model/launch-reload', HttpHelper.method.POST, formData, onSuccess, onError);
}

function deleteModel(model_id, onSuccess, onError) {
    return HttpHelper.authApiFetch('/api/v1/model/' + model_id, HttpHelper.method.DELETE, null, onSuccess, onError);
}

export const modelService = {
    switchUsedModel,
    getInfosModel,
    getInfosModels,
    getScore,
    getEmotion,
    uploadModel,
    launchAnalyse,
    launchReload,
    deleteModel
}