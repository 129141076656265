import React from 'react';
import { withTranslation } from "react-i18next";
import '../form.css';


class ScoreRangeField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            initialscore: props.initialscore,
            currentScore: props.score,
            defaultScore: props.score,
            minRange: props.minRange <= -1 ? props.minRange * 100 : -100,
            maxRange: props.maxRange >= 1 ? props.maxRange * 100 : 100,
        }


        this.onChange = this.onChange.bind(this);
        this.getScoreClassName = this.getScoreClassName.bind(this);
        this.getAllSizes = this.getAllSizes.bind(this);
    }

    onChange(e) {
        const {magneticCenterRadius} = this.props;
        var p = Math.pow(10, 2);
        let newScore = Math.round((e.target.value/100) * p)/p;

        const delta = Math.abs(newScore - this.state.initialscore)
        // Add magnetisme to Zero
        if (newScore >= -magneticCenterRadius && newScore <= magneticCenterRadius) {
            newScore = 0;
        }

        // Add magnetisme to initial score
        if (delta >= -magneticCenterRadius && delta <= magneticCenterRadius) {
            newScore = this.state.initialscore;
        }


        this.setState({
            currentScore: newScore
        });
        if(this.props.onChange){
            this.props.onChange(newScore);
        }
    }

    getScoreClassName(score) {
        const {neutralRadius} = this.props;
        const targetScore = (typeof score !== 'undefined') ? score : this.state.currentScore;
        if(targetScore >= -neutralRadius && targetScore <= neutralRadius) {
            return 'neutral';
        }else if(targetScore < -neutralRadius){
            return 'negative';
        }else {
            return 'positive';
        }
    }

    getAllSizes() {
        const totalSize = Math.abs(this.state.minRange) + Math.abs(this.state.maxRange);
        const center = totalSize/2;
        const less50 = (center - 50) / totalSize * 100;
        const more50 = (center + 50) / totalSize * 100;
        let ret = {
            'totalSize': totalSize,
            'negative': {
                left: 0+'%',
                width: (less50 - 0.5)+'%'
            },
            'neutral': {
                left: (less50 + 0.5)+'%',
                width: (more50 - less50 - 1)+'%',
            },
            'positive': {
                left: (more50 + 0.5)+'%',
                width: (100 - more50 - 0.5)+'%'
            },
            '-0.5': {
                left: (less50)+'%',
            },
            '0.5': {
                left: (more50)+'%',
            }
        }

        return ret;
    }

    render() {
        const { t, initialscore, score, neutralRadius } = this.props;
        const initialRangeValue = parseInt(initialscore * 100);
        const rangeValue = parseInt(score * 100);
        const scoreClassName = this.getScoreClassName();
        const defaultScoreClassName = this.getScoreClassName(this.state.initialscore);
        const allSizes = this.getAllSizes();
        return (
            <div className='content-score-range-field'>
                <div className="header">
                    <div className='item' style={allSizes['-0.5']}>-{neutralRadius}</div>
                    <div className='item' style={allSizes['0.5']}>{neutralRadius}</div>
                </div>
                <div className="content">
                    <div className='bar'>
                        <div className="item negative" style={allSizes.negative} />
                        <div className="item neutral" style={allSizes.neutral} />
                        <div className="item positive" style={allSizes.positive} />
                    </div>
                    <div className='center-bar' />
                    <div className="range-container">
                        <input type="range" disabled={true} min={this.state.minRange} max={this.state.maxRange} defaultValue={initialRangeValue} onChange={this.onChange} className={`default-range input-range ${defaultScoreClassName}`}/>
                        <input type="range" min={this.state.minRange} max={this.state.maxRange} value={rangeValue} onChange={this.onChange} className={`input-range ${scoreClassName}`}/>
                    </div>
                </div>
                <div className="footer">
                    <div className='item'>{t('Negative')}</div>
                    <div className='item'>{t('Neutral')}</div>
                    <div className='item'>{t('Positive')}</div>
                </div>
            </div>
        );
    }
}

ScoreRangeField.defaultProps = {
    minRange: -2,
    maxRange: 2,
    neutralRadius: 0.5,
    magneticCenterRadius: 0.15
}

export default withTranslation()(ScoreRangeField);