class BlurDataHelper { }

BlurDataHelper.getRandomClass = () => {
    const min = 1;
    const max = 3;
    const rand1 = Math.floor(Math.random() * (max - min + 1) + min);
    const rand2 = Math.floor(Math.random() * (max - min + 1) + min);
    return `blured-data-${rand1}-${rand2}`;
}

BlurDataHelper.getPosNegClass = (value) => {
    if (value === "positive") {
        return `blured-data-3-1`;
    }
    else if (value === "negative") {
        return `blured-data-1-1`;
    }
}

export default BlurDataHelper;