import HttpHelper from "../helpers/HttpHelper";

async function getKeyword(keyword, formData, onSuccess, onError) {
    const url = "/api/v1/keywords/" + keyword + "/";
    return HttpHelper.authApiFetch(url, HttpHelper.method.GET, formData, onSuccess, onError);
}

async function getKeywordEmotion(keyword, formData, onSuccess, onError) {
    const url = "/api/v1/keywords/" + keyword + "/emotion";
    return HttpHelper.authApiFetch(url, HttpHelper.method.GET, formData, onSuccess, onError);
}
export const keywordService = {
    get: {
        get: getKeyword,
        emotion: getKeywordEmotion
    }
}