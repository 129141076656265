import React from "react";
import { withTranslation } from "react-i18next";

class ProjectDetailTextViewMoreItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false,
      classContentClaimedValue:
        "font-size-15 font-inter-400 line-height-1-4 nl2br text-limit-12",
    };
    this.contentElement = null;
    this.onClickViewMore = this.onClickViewMore.bind(this);
  }

  onClickViewMore() {
    this.setState({
      opened: true,
      classContentClaimedValue:
        "font-size-15 font-inter-400 line-height-1-4 nl2br text-break-line text-break-line",
    });
  }

  componentDidMount() {
    const height = this.contentElement ? this.contentElement.clientHeight : 261;
    if (!this.props.children || (this.props.children && height < 211)) {
      this.setState({ opened: true });
    }
  }
  render() {
    const { t, children } = this.props;
    let classContentViewMore = ["content-text-view-more"];
    if (this.state.opened) {
      classContentViewMore.push("opened");
    }
    return (
      <div className="full-content-text-view-more">
        <div className={classContentViewMore.join(" ")}>
          <div
            className={this.state.classContentClaimedValue}
            ref={(el) => {
              this.contentElement = el;
            }}
          >
            {children}
          </div>
        </div>
        {!this.state.opened && (
          <button
            className="button backgroundless padding-0 margin-top-5"
            onClick={this.onClickViewMore}
          >
            {t("More")}
          </button>
        )}
      </div>
    );
  }
}

export default withTranslation()(ProjectDetailTextViewMoreItem);
