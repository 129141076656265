import { SessionStorageService } from "../../app/services/storage.service";
import { useBilalCode } from "./useBilalCode";
import { useKonamiCode } from "./useKonamiCode";

export const useWebsiteTheme = () => {
    const konami = useKonamiCode();
    const bilal = useBilalCode();
    if (konami) {
        if (SessionStorageService.get('theme') == 'theme3') {
            SessionStorageService.delete('theme');
        } else {
            SessionStorageService.set('theme', 'theme3');
        }
        window.location.reload();
    }
    if (bilal) {
        if (SessionStorageService.get('theme') == 'theme2') {
            SessionStorageService.delete('theme');
        } else {
            SessionStorageService.set('theme', 'theme2');
        }
        window.location.reload();
    }

    return null;
};