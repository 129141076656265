import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  TextInput,
  ReferenceInput,
  SelectInput,
  DateField,
  UrlField,
  SearchInput,
  Loading,
  useListContext,
} from "react-admin";
import IconUrlField from "../../components/form/field/IconUrlField";
import MediaTypeField from "./elements/MediaTypeField";
import FilledDateField from "./elements/FilledDateField";

const postFilters = [
  <SearchInput source="q" className="remove-line-height" alwaysOn />,
  <TextInput
    label="Project name"
    source="project_name"
    className="remove-line-height"
  />,
  <SelectInput
    allowEmpty={false}
    source="type"
    choices={[
      { id: "YOUTUBE", name: "YOUTUBE" },
      { id: "WEBSITE_URL", name: "WEB" },
      { id: "AMAZON_URL", name: "AMAZON" },
      { id: "TXT_FILE", name: "TEXT" },
      { id: "SHEET_FILE", name: "SHEET" },
    ]}
  />,
];

export const MediaList = (props) => (
  <List filters={postFilters} {...props} perPage={25}>
    <MyDatagrid />
  </List>
);

const MyDatagrid = () => {
  const { isLoading, isFetching } = useListContext();

  if (isLoading || isFetching) {
    return <Loading />;
  }

  return (
    <Datagrid bulkActionButtons={false}>
      <TextField label="ID" source="id" />
      <TextField label="Name" source="title" />
      <MediaTypeField label="Type" source="type" />
      <IconUrlField label="Link" source="data" />
      <TextField label="Score" source="average_score" />
      <FilledDateField label="Created at" source="created_at" hidden={false} />
      <FilledDateField label="Updated at" source="updated_at" hidden={false} />
      <TextField label="Project Name" source="project_name" />
    </Datagrid>
  );
};