import React, { Fragment } from "react";
import { Helmet } from "react-helmet";

import { globalConstants, PLAN_JSON_LOGIC_KEYS } from "../../app/constants";
import LoadingView from "../loading/LoadingView";
import Header from "../../components/header/Header";
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";

import Link from "../../components/Link";
import { classService } from "../../app/services/class.service";
import Footer from "../../components/footer/Footer";
import Loader from "../../components/loader/Loader";
import { PROJECT_EDIT } from "../../app/routing";
import HttpHelper from "../../app/helpers/HttpHelper";

import UserHelper from "../../app/helpers/UserHelper";
import { planJsonLogicService } from "../../app/services/plan.jsonLogic.service";
import { Checkbox, TextField } from "@mui/material";

class AddClassView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      formData: {
        name: "",
        display_name: "",
        keywords_txt: "",
        classe_ids: [],
        consumerChecked: false,
        expertChecked: false,
        product_type: [],
      },
      classId: "",
      submitLoading: false,
    };
    this.fetchInitial = this.fetchInitial.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeDisplayName = this.handleChangeDisplayName.bind(this);
    this.handleChangeWords = this.handleChangeWords.bind(this);
    this.onSubmitCreateClass = this.onSubmitCreateClass.bind(this);
    this.onCheckConsumer = this.onCheckConsumer.bind(this);
    this.onCheckExpert = this.onCheckExpert.bind(this);
    this.handleChangeProductType = this.handleChangeProductType.bind(this);
  }

  componentDidMount() {
    this._ismounted = true;
    this.fetchInitial();
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  fetchInitial() {
    planJsonLogicService.playRule(
      PLAN_JSON_LOGIC_KEYS.CREATE_CUSTOM_CLASS,
      {},
      (response) => {
        if (response.status === "error") {
          const project_id = this.props.match.params.id;
          this.props.history.push(
            HttpHelper.addParametersToUrl(PROJECT_EDIT(project_id), {
              modale: "upgrade-plan",
            })
          );
        } else {
          this.setState({ isLoaded: true });
        }
      },
      (error) => {
        if (this._ismounted) {
          this.props.addToast(HttpHelper.getErrorMessage(error), {
            type: "error",
            autoDismiss: true,
          });
          this.setState({ isLoaded: true });
        }
      }
    );
  }

  handleChangeName(event) {
    let classTitle = event.target.value;
    let keywords_txt = this.state.formData.keywords_txt;
    let display_name = this.state.formData.display_name;
    this.setState({
      formData: {
        ...this.state.formData,
        name: classTitle,
        display_name: display_name,
        keywords_txt: keywords_txt,
      },
    });
  }

  handleChangeDisplayName(event) {
    let display_name = event.target.value;
    let keywords_txt = this.state.formData.keywords_txt;
    let classTitle = this.state.formData.name;
    this.setState({
      formData: {
        ...this.state.formData,
        name: classTitle,
        display_name: display_name,
        keywords_txt: keywords_txt,
      },
    });
  }

  handleChangeWords(event) {
    let newWords = event.target.value;
    let name = this.state.formData.name;
    let display_name = this.state.formData.display_name;
    this.setState({
      formData: {
        ...this.state.formData,
        keywords_txt: newWords,
        name: name,
        display_name: display_name,
      },
    });
  }

  onSubmitCreateClass(event) {
    event.preventDefault();
    let formData = Object.assign({}, this.state.formData);
    if (formData) {
      let account = UserHelper.getCurrentAccount();
      formData["account_id"] = account.id;
      this.setState({ submitLoading: true });
      classService.post.create(
        formData,
        (response) => {
          this.setState({ submitLoading: false });
          setTimeout(() => {
            this.props.addToast(
              'Class "' + formData.name + '" successfully created',
              { type: "success", autoDismiss: true }
            );
          }, 800);
          this.props.history.push(PROJECT_EDIT(this.props.match.params.id));
        },
        (error) => {
          if (this._ismounted) {
            this.setState({ isLoaded: true });
            this.props.addToast(HttpHelper.getErrorMessage(error), {
              type: "error",
              autoDismiss: true,
            });
          }
        }
      );
    }
  }

  onCheckConsumer(event) {
    this.setState({
      formData: {
        ...this.state.formData,
        consumerChecked: !this.state.formData.consumerChecked,
      },
    });
  }

  onCheckExpert(event) {
    this.setState({
      formData: {
        ...this.state.formData,
        expertChecked: !this.state.formData.expertChecked,
      },
    });
  }

  handleChangeProductType(value) {
    this.setState({
      formData: {
        ...this.state.formData,
        product_type: value,
      },
    });
  }

  render() {
    const { t } = this.props;
    let page_title = `${t("Add class")} - ${globalConstants.WEBSITE_TITLE}`;

    if (this.state.isLoaded) {
      const buttonIsEnabled =
        this.state.formData.name !== "" &&
        this.state.formData.display_name !== "" &&
        (this.state.formData.consumerChecked ||
          this.state.formData.expertChecked);
      return (
        <Fragment>
          <Header isProject={true} />
          <Helmet>
            <title>{page_title}</title>
          </Helmet>
          <div className="padding-top-60 padding-bottom-20 responsive-920-padding-top-20">
            <div className="central-content">
              <div className="font-size-36 font-inter-700 color-000 padding-bottom-40">
                {t("Add class")}
              </div>
              <div className="content-column">
                <div className="column-percent-75 responsive-900">
                  <form>
                    <label className="padding-bottom-4 display-block font-inter-500 color-969696 font-size-15">
                      {t("Class name")}
                    </label>

                    <TextField
                      placeholder="Name"
                      value={this.state.formData.name}
                      onChange={this.handleChangeName}
                      type="text"
                      disabled={this.state.submitLoading}
                      sx={{ width: "100%" }}
                    />
                    <label className="padding-top-15 padding-bottom-4 display-block font-inter-500 color-969696 font-size-15">
                      {t("Display name")}
                    </label>

                    <TextField
                      placeholder="Display name"
                      value={this.state.formData.display_name}
                      onChange={this.handleChangeDisplayName}
                      type="text"
                      maxLength="65"
                      disabled={this.state.submitLoading}
                      sx={{ width: "100%" }}
                    />
                    <label className="padding-top-40 display-block font-inter-500 color-969696 font-size-15 padding-bottom-4">
                      {t("Test type")}
                    </label>
                    <div className="typology-checkboxes-createClass">
                      <div className="checkbox margin-right-25">
                        <Checkbox
                          className="consumer-checkbox"
                          id="consumer"
                          name="consumer"
                          checked={this.state.formData.consumerChecked}
                          onClick={this.onCheckConsumer}
                          sx={{
                            color: "#b4b4b4",
                            "&$checked": {
                              color: "#3a79e6",
                            },
                            "&:hover": {
                              backgroundColor: "transparent !important",
                            },
                          }}
                        />
                        <div className="typology-label">{t("Consumer")}</div>
                      </div>
                      <div className="checkbox">
                        <Checkbox
                          className="lab_expert-checkbox"
                          id="expert"
                          name="lab / expert"
                          checked={this.state.formData.expertChecked}
                          onClick={this.onCheckExpert}
                          sx={{
                            color: "#b4b4b4",
                            "&$checked": {
                              color: "#3a79e6",
                            },
                            "&:hover": {
                              backgroundColor: "transparent !important",
                            },
                          }}
                        />
                        <div className="typology-label">
                          {t("Lab / Expert")}
                        </div>
                      </div>
                    </div>

                    <label className="padding-top-40 display-block font-inter-500 color-969696 font-size-15 padding-bottom-4">
                      {t("Attributes")}
                    </label>
                    <textarea
                      value={this.state.formData.keywords_txt}
                      rows={5}
                      cols={5}
                      onChange={this.handleChangeWords}
                      className="min-height-450 width-percent-100"
                      disabled={this.state.submitLoading}
                    />
                    <div className="padding-bottom-36" />
                    <div className="overflow-hidden">
                      {!this.state.submitLoading && (
                        <div className="float-right">
                          <Link
                            className="button backgroundless"
                            to={PROJECT_EDIT(this.props.match.params.id)}
                          >
                            {t("Cancel")}
                          </Link>
                          <button
                            onClick={this.onSubmitCreateClass}
                            disabled={!buttonIsEnabled}
                            className="margin-left-5 button backgroundless"
                          >
                            {t("Save class")}
                          </button>
                        </div>
                      )}
                      {this.state.submitLoading && (
                        <div
                          className="float-right"
                          style={{ padding: "2px 0" }}
                        >
                          <Loader />
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </Fragment>
      );
    }
    return (
      <LoadingView
        title={page_title}
        withHeader={true}
        headerProps={{ isProject: true }}
      />
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withTranslation()(connect(mapStateToProps)(AddClassView));
