
import React from 'react';
import moment from 'moment-timezone';
import './list.css';
import { withTranslation } from "react-i18next";
import Link from "../Link";
import { PROJECT_DETAIL } from '../../app/routing';
import { PROJECT_STATUS } from '../../app/constants';
import defaultImage from '../../res/images/placeholder-img.svg'

class MiniProjectListItem extends React.Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        const { t } = this.props;
        const item = this.props.item;
        let listItemsClasses = ["mini-project-list-item", "transition-color"];
        if(item.isArchived){
            listItemsClasses.push('archived');
        }
        if(item.status === PROJECT_STATUS.LOCKED){
            listItemsClasses.push('locked');
        }
        const pictureStyle = (item.picture) ? { backgroundImage: "url('" + item.picture + "', url('"+defaultImage+"'))" } : {};
        let nbMedias = ""
        {item.medias > 1 ? nbMedias = t('{{count}} data sources', { count: item.medias }) : nbMedias = t('{{count}} data source', { count: item.medias });}
        return (
          <div className={listItemsClasses.join(" ")}>
            <div
              className="picture background-image-contain"
              style={pictureStyle}
            >
              {item.status === PROJECT_STATUS.LOCKED && (
                <div className="banner-locked-project">{t("Locked")}</div>
              )}
            </div>
            <div className="content-left">
              <div className="font-size-15 color-000 font-inter-600 text-ellipsis padding-bottom-2">
                {item.name}
              </div>
              <div className="font-size-13 color-7f7f7f font-inter-400 text-ellipsis">
                {nbMedias} • {moment.utc(item.updated_at).local().fromNow(true)}
              </div>
            </div>
            {!item.isArchived && item.status !== PROJECT_STATUS.LOCKED && (
              <Link className="link-absolute" to={PROJECT_DETAIL(item.id)} />
            )}
          </div>
        );
    }
}

export default withTranslation()(MiniProjectListItem);