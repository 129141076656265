import "../unconnected.css";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { globalConstants } from "../../../app/constants";
import { connect } from "react-redux";
import TextField from "../../../components/form/field/TextField";
import ButtonField from "../../../components/form/field/ButtonField";
import { withTranslation, Trans } from "react-i18next";
import { ROUTES, ADD_TOKEN_TO_ROUTE } from "../../../app/routing";
import HeaderUnconnected from "../../../components/header/HeaderUnconnected";
import FooterUnconnected from "../../../components/footer/FooterUnconnected";
import StringHelper from "../../../app/helpers/StringHelper";
import { userService } from "../../../app/services/user.service";
import LoadingView from "../../loading/LoadingView";
import SignupHelper from "../../../app/helpers/SignupHelper";
import HttpHelper from "../../../app/helpers/HttpHelper";
import { GoogleLogin } from "@react-oauth/google";
import { Box, Typography } from "@mui/material";
import { MicrosoftSigninButton } from "../login/MicrosoftSigninButton";

class SignupStep1View extends React.Component {
  constructor(props) {
    super(props);
    const currentUser = SignupHelper.getUser();
    const urlParameters = HttpHelper.getUrlParameters(props.location.search);
    const token = urlParameters.token || currentUser.invitation_token;
    const plan = urlParameters.plan || currentUser.plan;
    const freq = urlParameters.freq || currentUser.freq;
    this.state = {
      isLoaded: false,
      invitation_token: token,
      invitation_account: null,
      googleAuthErrorMessage: null,
      msalAuthErrorMessage: null,
      formData: {
        id: currentUser.id ? currentUser.id : null,
        email: currentUser.email,
        firstname: currentUser.firstname,
        lastname: currentUser.lastname,
        invitation_token: token,
        plan: plan,
        freq: freq,
      },
      formErrors: null,
      loading: false,
    };
    this.fetchInitial = this.fetchInitial.bind(this);
    this.formIsValid = this.formIsValid.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitGoogleAuth = this.handleSubmitGoogleAuth.bind(this);
    this.handleSubmitMicrosoftAuth = this.handleSubmitMicrosoftAuth.bind(this);
    this.getErrorMessage = this.getErrorMessage.bind(this);
    this.getFormErrors = this.getFormErrors.bind(this);
  }

  componentDidMount() {
    this._ismounted = true;
    this.fetchInitial();
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  fetchInitial() {
    if (!this.state.invitation_token) {
      this.setState({ isLoaded: true });
      return null;
    }
    userService.checkTokenInvitation(
      this.state.invitation_token,
      (response) => {
        this.setState({
          isLoaded: true,
          invitation_account: response.account,
        });
      },
      (error) => {
        this.props.history.push(ROUTES.LOGIN);
      }
    );
  }

  getFormErrors(event) {
    const { t } = this.props;
    let formErrors = this.state.formErrors
      ? Object.assign({}, this.state.formErrors)
      : {};
    if (event.target.name === "email") {
      if (!StringHelper.isEmailValid(event.target.value)) {
        formErrors.email = t("Please enter a valid email");
      } else {
        delete formErrors.email;
      }
    }
    return Object.keys(formErrors).length === 0 ? null : formErrors;
  }

  handleChange(event) {
    let formData = Object.assign({}, this.state.formData);
    formData[event.target.name] = event.target.value;
    let formErrors = this.getFormErrors(event);
    this.setState({
      formData: formData,
      formErrors: formErrors,
    });
  }

  formIsValid() {
    return this.state.formErrors == null;
  }

  handleSubmit(event) {
    if (this.formIsValid()) {
      this.setState({ loading: true });
      userService.checkEmail(
        this.state.formData,
        (response) => {
          SignupHelper.updateUser(this.state.formData);
          this.props.history.push(ROUTES.SIGN_UP_STEP_2);
        },
        (error) => {
          this.setState({
            loading: false,
            formErrors: error.formErrors,
          });
        }
      );
    }
    event.preventDefault();
  }

  handleSubmitGoogleAuth(credentialResponse) {
    this.setState({ loading: true });
    const formData = {
      google_credential: credentialResponse,
    };
    userService.checkEmailFromGoogleCredential(
      formData,
      (response) => {
        SignupHelper.updateUser(response.user);
        this.props.history.push(ROUTES.SIGN_UP_STEP_2);
      },
      (error) => {
        this.setState({
          loading: false,
          googleAuthErrorMessage: error.message,
        });
      }
    );
  }

  handleSubmitMicrosoftAuth(accessTokenResponse) {
    this.setState({ loading: true });
    const formData = {
      msal_access_token: accessTokenResponse,
    };
    userService.checkEmailFromMicrosoftAccessToken(
      formData,
      (response) => {
        SignupHelper.updateUser(response.user);
        this.props.history.push(ROUTES.SIGN_UP_STEP_2);
      },
      (error) => {
        this.setState({
          loading: false,
          msalAuthErrorMessage: error.message,
        });
      }
    );
  }

  getErrorMessage(target) {
    if (this.state.formErrors && this.state.formErrors.hasOwnProperty(target)) {
      return this.state.formErrors[target];
    }
    return null;
  }

  render() {
    const { t } = this.props;
    let formElementClassName = this.state.error ? "error" : "";
    let formClassName = this.state.loading ? "loading-form" : "";
    const page_title = this.state.invitation_account
      ? `${t("Join {{account_title}} workspace!", {
          account_title: this.state.invitation_account.title,
        })} - ${globalConstants.WEBSITE_TITLE}`
      : `${t("Sign up")} - ${globalConstants.WEBSITE_TITLE}`;
    if (!this.state.isLoaded) {
      return <LoadingView title={page_title} />;
    }
    const main_title = this.state.invitation_account ? (
      <Trans i18nKey="icu_and_trans">
        Join{" "}
        <span className="color-3a79e6">
          {this.state.invitation_account.title}
        </span>{" "}
        workspace!
      </Trans>
    ) : (
      <Fragment>
        {t("Welcome to")}
        <br />
        <span className="color-30beec">{t(globalConstants.WEBSITE_TITLE)}</span>
      </Fragment>
    );
    return (
      <Fragment>
        <Helmet>
          <title>{page_title}</title>
        </Helmet>
        <div className="unconnected-content double-percent-50">
          <div className="content-white">
            <HeaderUnconnected />
            <div className="content-max-width-590 min-height-100vh margin-auto">
              <div className="c-content-table padding-bottom-90">
                <div className="c-content-table-cell">
                  <div className="centralized-content">
                    <div className="font-inter-700 font-size-36 text-align-center color-0e004b padding-bottom-50">
                      {main_title}
                    </div>
                    <form
                      onSubmit={this.handleSubmit}
                      className={formClassName}
                    >
                      <TextField
                        type="email"
                        label={t("Email")}
                        name="email"
                        required={true}
                        disabled={this.state.loading}
                        value={this.state.formData.email}
                        onChange={this.handleChange}
                        errorMessage={this.getErrorMessage("email")}
                        formElementClassName={formElementClassName}
                      />
                      <TextField
                        type="text"
                        label={t("Firstname")}
                        name="firstname"
                        required={true}
                        disabled={this.state.loading}
                        value={this.state.formData.firstname}
                        onChange={this.handleChange}
                        errorMessage={this.getErrorMessage("firstname")}
                        formElementClassName={formElementClassName}
                      />
                      <TextField
                        type="text"
                        label={t("Last name")}
                        name="lastname"
                        required={true}
                        disabled={this.state.loading}
                        value={this.state.formData.lastname}
                        onChange={this.handleChange}
                        errorMessage={this.getErrorMessage("lastname")}
                        formElementClassName={formElementClassName}
                      />
                      <div className="padding-top-20" />
                      <ButtonField
                        type="submit"
                        value={t("Next")}
                        disabled={this.state.loading}
                        loading={this.state.loading}
                        className="font-work-sans-600 width-170 height-45"
                        formElementClassName="padding-vertical-20 text-align-center"
                      />

                      <Box
                        sx={{
                          mt: "30px",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <GoogleLogin
                          onSuccess={(credentialResponse) => {
                            this.handleSubmitGoogleAuth(credentialResponse);
                          }}
                          onError={(errorResponse) => {
                            this.setState({
                              googleAuthErrorMessage: errorResponse.error,
                            });
                          }}
                        />
                        {this.state.googleAuthErrorMessage && (
                          <Typography
                            sx={{
                              mt: "10px",
                              color: "#e04f77",
                              fontSize: "12px",
                            }}
                          >
                            {this.state.googleAuthErrorMessage}
                          </Typography>
                        )}
                      </Box>
                      <Box
                        sx={{
                          mt: "30px",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <MicrosoftSigninButton
                          handleResponse={this.handleSubmitMicrosoftAuth}
                        />
                        {this.state.msalAuthErrorMessage && (
                          <Typography
                            sx={{
                              mt: "10px",
                              color: "#e04f77",
                              fontSize: "12px",
                            }}
                          >
                            {this.state.msalAuthErrorMessage}
                          </Typography>
                        )}
                      </Box>
                      <div className="text-align-center padding-top-30">
                        <div className="font-inter-400 font-size-15">
                          {t("Already have an account?")}{" "}
                          <Link
                            to={ADD_TOKEN_TO_ROUTE(
                              ROUTES.LOGIN,
                              this.state.formData.invitation_token
                            )}
                            className="link-blue font-size-15 font-inter-500"
                          >
                            {t("Sign in")}
                          </Link>
                        </div>
                      </div>

                      <div className="padding-bottom-30" />
                    </form>
                  </div>
                </div>
              </div>
              <FooterUnconnected>
                <div className="text-align-left color-7f7f7f padding-bottom-20">
                  <Trans i18nKey="footer-unconnected">
                    By creating a Sensia account, you're agreeing to accept our{" "}
                    <a
                      href={ROUTES.TERMS_OF_SERVICE}
                      className="link-blue font-inter-500"
                    >
                      Terms of Service
                    </a>{" "}
                    and{" "}
                    <a
                      href={ROUTES.PRIVACY_POLICY}
                      className="link-blue font-inter-500"
                    >
                      Privacy Policy
                    </a>
                    .
                  </Trans>
                </div>
              </FooterUnconnected>
            </div>
          </div>
          <div className="content-blue background-color-fff">
            <div className="icon width-percent-40" />
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withTranslation()(connect(mapStateToProps)(SignupStep1View));
