import React from 'react';
import '../form.css';


class ProfilePictureField extends React.Component {
    constructor(props) {
        super(props);
        const currentPicture = props.defaultValue;
        this.state = {
            currentPicture: currentPicture
        };
        this.renderErrorMessage = this.renderErrorMessage.bind(this);
        this.handlerOnChange = this.handlerOnChange.bind(this);
        this.getPictureStyle = this.getPictureStyle.bind(this);
    }


    renderErrorMessage(errorMessage) {
        if (errorMessage) {
            return (
                <div className="form-error font-work-sans-400 color-fd3e7b font-size-12 text-align-right">
                    {errorMessage}
                </div>
            );
        }
        return null;
    }

    handlerOnChange(event){
        if(event.target.files[0]){
            this.setState({
                currentPicture: URL.createObjectURL(event.target.files[0])
            });
        }
        if(this.props.onChange){
            this.props.onChange(event);
        }
    }

    getPictureStyle(){
        if(this.state.currentPicture){
            return {
                backgroundImage: `url(${this.state.currentPicture})`
            };
        }
        return {};
    }

    render() {
        let formElementClassName = ["form-element"];
        if (this.props.formElementClassName) {
            formElementClassName.push(this.props.formElementClassName);
        }
        if (this.props.errorMessage) {
            formElementClassName.push('error');
        }
        let fieldClasses = ['content-profile-picture-field'];
        if(this.props.disabled){
            fieldClasses.push('disabled');
        }
        return (
            <div className={formElementClassName.join(' ')}>
                <label className="font-inter-600 font-size-15 color-969696">
                    {this.props.label}
                </label>
                <div className={fieldClasses.join(' ')}>
                    <div className="picture" style={this.getPictureStyle()}/>
                    <div className="hover transition-color" />
                    <input
                        type="file"
                        accept="image/*"
                        className={this.props.className}
                        style={this.props.style}
                        disabled={this.props.disabled}
                        readOnly={this.props.readonly}
                        required={this.props.required}
                        placeholder={this.props.placeholder}
                        name={this.props.name}
                        value={this.props.value}
                        onChange={this.handlerOnChange}
                    />
                </div>
                {this.renderErrorMessage(this.props.errorMessage)}
            </div>
        );
    }
}

export default ProfilePictureField;