import React, { Fragment } from "react";
import CardWithIcon from "./CardWithIcon";
import { backofficeService } from "../../app/services/backoffice.service";
import Loader from "../../components/loader/Loader";
import BusinessIcon from "@mui/icons-material/Business";
import DescriptionIcon from "@mui/icons-material/Description";
import FolderIcon from "@mui/icons-material/Folder";
import GroupIcon from "@mui/icons-material/Group";

class AllIndicators extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      indicatorsDetails: {
        nbOfAccount: null,
        nbOfMedia: null,
        nbOfProject: null,
        nbOfUser: null,
      },

      indicatorsDetailsIsLoaded: false,
    };
  }

  componentDidMount() {
    this.fetchIndicatorsDetails();
  }

  fetchIndicatorsDetails() {
    backofficeService.getIndicatorsDetails(
      (response) => {
        this.setState({
          indicatorsDetails: {
            nbOfAccount: response.data.nb_of_account,
            nbOfMedia: response.data.nb_of_media,
            nbOfProject: response.data.nb_of_project,
            nbOfUser: response.data.nb_of_user,
          },

          indicatorsDetailsIsLoaded: true,
        });
      },
      (error) => {
        console.log(error);
        this.setState({
          indicatorsDetailsIsLoaded: true,
        });
      }
    );
  }

  render() {
    return (
      <Fragment>
        {!this.state.indicatorsDetailsIsLoaded && <Loader />}
        {this.state.indicatorsDetailsIsLoaded && (
          <div className="indicators">
            {/* /!\ ATTENTION /!\ routes en dur ! */}
            <CardWithIcon
              to="/accounts"
              icon={<BusinessIcon htmlColor="#2d75ff" fontSize="large" />}
              title={"Accounts"}
              subtitle={this.state.indicatorsDetails.nbOfAccount}
            />

            <CardWithIcon
              to="/users"
              icon={<GroupIcon htmlColor="#2d75ff" fontSize="large" />}
              title={"Users"}
              subtitle={this.state.indicatorsDetails.nbOfUser}
            />

            <CardWithIcon
              to="/projects"
              icon={<FolderIcon htmlColor="#2d75ff" fontSize="large" />}
              title={"Projects"}
              subtitle={this.state.indicatorsDetails.nbOfProject}
            />

            <CardWithIcon
              to="/data-sources"
              icon={<DescriptionIcon htmlColor="#2d75ff" fontSize="large" />}
              title={"Data sources"}
              subtitle={this.state.indicatorsDetails.nbOfMedia}
            />
          </div>
        )}
      </Fragment>
    );
  }
}

export default AllIndicators;
