import HttpHelper from '../helpers/HttpHelper';

async function getInvoicesData(account_id, formData, onSuccess, onError) {
    return HttpHelper.authApiFetch("/api/v1/invoice/get-data/" + account_id, HttpHelper.method.POST, formData, onSuccess, onError);
}

async function resendInvoice(invoice_id, onSuccess, onError) {
    return HttpHelper.authApiFetch("/api/v1/invoice/resent/" + invoice_id, HttpHelper.method.POST, null, onSuccess, onError);
}



export const invoiceService = {
    getInvoicesData,
    resendInvoice
}