import React from 'react'
import PropTypes from 'prop-types'
import './../../../components/ui/rounded-emotion.css';
import StringHelper from '../../../app/helpers/StringHelper';
import EmotionHelper from '../../../app/helpers/EmotionHelper';
import AjaxEmotionTooltipContent from '../../../components/tooltip/AjaxEmotionTooltipContent';
import Tooltip from '../../../components/tooltip/Tooltip';
import BlurDataHelper from '../../../app/helpers/BlurDataHelper';
import EmotionTooltipContent from '../../../components/tooltip/EmotionTooltipContent';


export const ProjectRoundedEmotion = ({ emotion, className, withTooltip, projectId, datasourceId, start_at, keyword, hasLimitedAccess, allEmotions, inScore=false, ...props }) => {
  let sortedEmotions = {}
  if (allEmotions ){
    sortedEmotions = Object.fromEntries(
      Object.entries(allEmotions).sort(([, a], [, b]) => b - a)
    );
  }
  
  let keyTab = Object.keys(sortedEmotions);
  let topEmotion = keyTab[0];

  let contentClasses = ['content-rounded-emotion', 'display-flex', 'align-items-center', 'height-100'];
  if (className) {
    contentClasses.push(className);
  }
  if (hasLimitedAccess) {
    return <div className={`blured-emotion ${BlurDataHelper.getRandomClass()}`} />
  }
  if (!topEmotion) {
    return <div className="color-7f7f7f" style={ inScore ? { fontSize:"24px" } : {}}>-</div>
  }

  const getTooltipContent = () => {
    if (allEmotions) {
      return <EmotionTooltipContent data={allEmotions} />
    }
    if (!projectId) {
      return null;
    }
    return (
      <AjaxEmotionTooltipContent projectId={projectId} datasourceId={datasourceId} keyword={keyword} start_at={start_at} />
    );
  }

  if (withTooltip) {
    return (
      <Tooltip content={getTooltipContent()} placement="bottom">
        <span className={contentClasses.join(' ')} {...props}>
          <div className="round-emotion-color" style={{ backgroundColor: EmotionHelper.getEmotionColor(topEmotion) }} />
          <div className="vertical-align-middle display-inline-block font-inter font-size-15 line-height-19 color-7f7f7f cursor-default">
            {topEmotion && StringHelper.capitalizeFirstLetter(topEmotion)}
          </div>
        </span>
      </Tooltip>
    )
  }

  return (
    <span className={contentClasses.join(' ')} {...props}>
      <div className="round-emotion-color" style={{ backgroundColor: EmotionHelper.getEmotionColor(topEmotion) }} />
      <div className="vertical-align-middle display-inline-block font-inter font-size-15 line-height-19 color-7f7f7f cursor-default">
        {topEmotion && StringHelper.capitalizeFirstLetter(topEmotion)}
      </div>
    </span>
  )
}

ProjectRoundedEmotion.propTypes = {
  topEmotion: PropTypes.string,
  className: PropTypes.string,
}

export default ProjectRoundedEmotion
