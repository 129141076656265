import React from 'react';
import {withTranslation} from 'react-i18next';


class EmptyStateTeams extends React.Component {
    render() {
        const {t} = this.props;
        return (
            <div className="padding-vertical-55 border-radius-6 padding-horizontal-30 text-align-center background-color-fafafa">
                <div className="font-inter-700 font-size-20 padding-bottom-4 color-000">{t('No teams yet')}</div>
                <div className="font-inter-400 font-size-16 padding-bottom-25 color-7f7f7f">{t('Create teams, and add users to teams')}</div>
                <div>
                    <button className="button blue" onClick={this.props.onOpenCreateModal}>{t('Create team')}</button>
                </div>
            </div>
        );
    }
}

export default withTranslation()(EmptyStateTeams);