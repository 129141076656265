import React from 'react';
import Modal from './Modal';
import { withTranslation } from 'react-i18next';
import HttpHelper from '../../app/helpers/HttpHelper';
import StringHelper from '../../app/helpers/StringHelper';

import './modal.css';
import Loader from '../loader/Loader';
import ClassListItem from '../classListItem/ClassListItem';
import { classService } from '../../app/services/class.service';
import ReportHelper from '../../app/helpers/ReportHelper';
import UserHelper from '../../app/helpers/UserHelper';
import { planJsonLogicService } from '../../app/services/plan.jsonLogic.service';
import { ACCOUNT_PLAN, PLAN_JSON_LOGIC_KEYS } from '../../app/constants';
import UpgradePlanToAddClassToAttributeModal from '../../views/manageClasses/elements/UpgradePlanToAddClassToAttributeModal';


class UpdateKeywordClassModal extends React.Component {
    constructor(props) {
        super(props);
        const keywordValue = (props.keyword && props.keyword.keyword) ? props.keyword.keyword : null;
        let defaultClassIds = [];
        if (props.keyword && props.keyword.class) {
            props.keyword.class.forEach(element => {
                if (element.id != ReportHelper.CLASS_IDS.UNKNOWN && element.id != ReportHelper.CLASS_IDS.MAIN_CHARACTERISTICS) {
                    defaultClassIds.push(element.id);
                }
            });
        }
        this.state = {
            is_loaded: false,
            formData: {
                keyword: keywordValue,
                classe_ids: defaultClassIds
            },
            classesList: [],
            otherClassesList: [],
            submitLoading: false,
            currentPlan: "Free",
            displayUpgradePlanToAddClassToAttributeModal: false
        };
        this.modalRef = React.createRef();

        this.fetchData = this.fetchData.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.onCancelClick = this.onCancelClick.bind(this);
        this.onSave = this.onSave.bind(this);

        this.getClassesList = this.getClassesList.bind(this);
        this.getOtherClassesList = this.getOtherClassesList.bind(this);
        this.currentPlan = this.currentPlan.bind(this);
        this.onCloseUpgradePlanToAddClassModal = this.onCloseUpgradePlanToAddClassModal.bind(this);

    }

    componentDidMount() {
        this.fetchData();
        this.currentPlan()
    }

    handleChange(e) {
        let newFormData = Object.assign({}, this.state.formData);
        const isChecked = e.target.checked;
        const value = parseInt(e.target.value);
        if (isChecked) {
            newFormData.classe_ids.push(value);
        } else {
            const index = newFormData.classe_ids.indexOf(value);
            if (index > -1) {
                newFormData.classe_ids.splice(index, 1);
            }
        }
        this.setState({
            formData: newFormData
        });
    }


    fetchData() {
        let current_account = UserHelper.getCurrentAccount()
        classService.get.getAll(
            current_account.id,
            (response) => {
                this.setState({
                    is_loaded: true,
                    classesList: this.getClassesList(response.data),
                    otherClassesList: this.getOtherClassesList(response.data)
                });
            },
            (error) => {
                this.setState({ is_loaded: true });
                this.props.parentProps.addToast(HttpHelper.getErrorMessage(error), { type: 'error', autoDismiss: true });
            }
        );
    }

    displayToastErrorMessage(error) {
        if (!this._ismounted) {
            return;
        }
        this.props.addToast(HttpHelper.getErrorMessage(error), { type: 'error', autoDismiss: true });
    }

    currentPlan() {
        planJsonLogicService.playRule(
            PLAN_JSON_LOGIC_KEYS.ENTERPRISE_FEATURE,
            {},
            (response) => {
                this.setState({
                    currentPlan: response.data.plan,
                });
            }, (error) => {
                this.displayToastErrorMessage(error);
            });
    }

    getClassesList(allClasses) {
        const { project } = this.props;
        const projectClassIds = (project) ? project.classe_ids : null;
        let ret = [];
        allClasses.forEach(element => {
            if (!ReportHelper.classIsExcludedWords(element) && (!projectClassIds || (projectClassIds && projectClassIds.includes(element.id)))) {
                ret.push(element);
            }
        });
        return ret;
    }

    getOtherClassesList(allClasses) {
        let ret = []
        allClasses.forEach(element => {
            if (ReportHelper.classIsExcludedWords(element)) {
                ret.push(element);
            }
        });
        return ret;
    }

    onCancelClick() {
        if (this.modalRef && this.modalRef.current) {
            this.modalRef.current.onModalCloseClick();
        }
    }

    onSave(e) {
        const { t, projectId, keyword } = this.props;

        if (this.state.currentPlan === "Free") {
            e.preventDefault()
            e.stopPropagation()
            this.setState({
                displayUpgradePlanToAddClassToAttributeModal: true
            })
        } else {
            this.setState({ submitLoading: true });
            const formData = this.state.formData;
            const keywordLibelle = (keyword && keyword.keyword) ? StringHelper.capitalizeFirstLetter(keyword.keyword) : "";

            classService.put.updateKeywordClass(
                projectId,
                formData,
                (response) => {
                    if (this.props.onSuccess) {
                        this.props.onSuccess(response);
                    }
                    this.onCancelClick();
                    if (this.props.parentProps) {
                        this.props.parentProps.addToast(t('Class updated for the attribute « {{keyword}} »', { keyword: keywordLibelle }), { type: 'success', autoDismiss: true });
                    }
                },
                (error) => {
                    this.onCancelClick();
                    if (this.props.parentProps) {
                        this.props.parentProps.addToast(HttpHelper.getErrorMessage(error), { type: 'error', autoDismiss: true });
                    }
                });
        }
    }

    onCloseUpgradePlanToAddClassModal() {
        this.setState({
            displayUpgradePlanToAddClassToAttributeModal: false
        });
    }

    render() {
        const { t, keyword } = this.props;
        const keywordLibelle = (keyword && keyword.keyword) ? StringHelper.capitalizeFirstLetter(keyword.keyword) : "";

        return (
            <Modal ref={this.modalRef} onClose={this.props.onClose}>
                <div className="font-size-30 font-inter-700 color-000 padding-bottom-16">{keywordLibelle}</div>
                <div className="font-size-20 font-inter-700 color-000 padding-bottom-20">{t('Select class for the attribute « {{keyword}} »', { keyword: keywordLibelle })}</div>
                {this.state.is_loaded &&
                    (
                        <form>
                            <div className="padding-bottom-20">
                                {
                                    this.state.classesList && this.state.classesList.map((item, i) => {
                                        return (<ClassListItem
                                            key={`classListItem${i}`}
                                            classItem={item}
                                            name="classe_ids"
                                            projectId={this.state.projectId}
                                            disabled={this.state.submitLoading}
                                            displayButtonEdit={false}
                                            onChange={this.handleChange}
                                            hideDeleteAction={true}
                                            rightIsLabel={true}
                                            checked={this.state.formData.classe_ids.includes(item.id)} />)
                                    }
                                    )
                                }
                            </div>
                            <div className="padding-bottom-36 content-modal-helper-idea">
                                <span className="icon" />
                                <span className="text font-inter-400 font-size-15 color-7f7f7f">
                                    {t('Attributes are organized in classes, which are groups of words with a specific common theme. To create a new attribute, add it to an existing class above.')}
                                </span>
                            </div>
                            <div className="overflow-hidden">
                                {!this.state.submitLoading &&
                                    <div className="float-right">
                                        <button className="button backgroundless" type="button" onClick={this.onCancelClick}>{t('Cancel')}</button>
                                        <button className="button backgroundless margin-left-5" onClick={(e) => this.onSave(e)}>{t('Save')}</button>
                                    </div>
                                }
                                {this.state.submitLoading &&
                                    <div className="float-right" style={{ padding: "2px 0" }}>
                                        <Loader />
                                    </div>
                                }
                            </div>
                            {this.state.displayUpgradePlanToAddClassToAttributeModal && <UpgradePlanToAddClassToAttributeModal onClose={this.onCloseUpgradePlanToAddClassModal} />}
                        </form>
                    )
                }
                {!this.state.is_loaded && (
                    <Loader />
                )
                }
            </Modal>
        );
    }
}


export default withTranslation()(UpdateKeywordClassModal);