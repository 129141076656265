import "./Card.css";
import React, { useState } from "react";
import { useSpring, animated } from "react-spring";

function CarouselCard({ imagen, title, text }) {
  const [show, setShown] = useState(false);

  const props3 = useSpring({
    transform: show ? "scale(1.03)" : "scale(1)"
  });
  return (
    <animated.div
      className={"card"}
      style={props3}
      onMouseEnter={() => setShown(true)}
      onMouseLeave={() => setShown(false)}
    >
      <img src={imagen} alt="" />
      {/* <h2>{title}</h2>
      <p>{text}</p> */}
    </animated.div>
  );
}

export default CarouselCard;
