import HttpHelper from "../helpers/HttpHelper";

async function getProducts(onSuccess, onError) {
  return HttpHelper.authApiFetch(
    "/api/v1/categories-products",
    HttpHelper.method.GET,
    null,
    onSuccess,
    onError
  );
}

async function getPublicProducts(onSuccess, onError) {
  return HttpHelper.authApiFetch(
    "/api/v1/public-categories-products",
    HttpHelper.method.GET,
    null,
    onSuccess,
    onError
  );
}

async function getProductsForAccount(account_id, onSuccess, onError) {
  return HttpHelper.authApiFetch(
    `/api/v1/categories-products-for-account/${account_id}`,
    HttpHelper.method.GET,
    null,
    onSuccess,
    onError
  );
}

async function editProductModel(formData, onSuccess, onError) {
  return HttpHelper.authApiFetch(
    "/api/v1/model/update",
    HttpHelper.method.GET,
    formData,
    onSuccess,
    onError
  );
}

async function createProductModel(formData, onSuccess, onError) {
  return HttpHelper.authApiFetch(
    "/api/v1/model/create",
    HttpHelper.method.GET,
    formData,
    onSuccess,
    onError
  );
}

export const productService = {
  products: getProducts,
  publicProducts: getPublicProducts,
  productsForAccount: getProductsForAccount,
  editProductModel: editProductModel,
  createProductModel: createProductModel,
};
