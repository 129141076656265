import React, { Fragment } from 'react';
import './footer.css';
import {withTranslation} from 'react-i18next';
//import logoCorellis from '../../res/images/logo-corellis.svg'; 

class Footer extends React.Component {
    constructor(props) {
        super(props);
        // this.state = {
        //     counter: 0,
        //     counterK: 0
        // };
        // this.contentFooter = this.contentFooter.bind(this);
        // this.onKickassClick = this.onKickassClick.bind(this);
    }

    // Je laisse le footer en commentaire au cas où on veut le rétablir plus tard

    // contentFooter() {
    //     const { t } = this.props;
    //     return (
    //         <div className="padding-vertical-40 text-align-center">
    //             <div className="central-content">
    //                 <div className="font-inter-500 color-b4b4b4 font-size-13 padding-bottom-30">{t('Corellis Sensia Feeling Value Analysis')}   •   <a className="link color-b4b4b4 opacity-on-hover transition-opacity" href="https://www.corellis.eu/" rel="noreferrer" target="_blank">{t('About Corellis')}</a></div>
    //                 <a className="link color-b4b4b4 opacity-on-hover transition-opacity display-block margin-auto" href="https://www.corellis.eu/" rel="noreferrer" target="_blank" style={{ width: "80px"}}>
    //                     <img src={logoCorellis} width="80" />
    //                 </a>
    //             </div>
    //         </div>
    //     );
    // }

    // onKickassClick() {
    //     if (this.state.counterK < 14) {
    //         let newCounter = this.state.counterK + 1;
    //         this.setState({ counterK: newCounter });
    //         if (newCounter > 12) {
    //             var s = document.createElement('script');
    //             s.type='text/javascript';
    //             document.body.appendChild(s);
    //             s.src='//hi.kickassapp.com/kickass.js';void(0);
    //         }
    //     }
    // }

    render() {
        return (
            <Fragment>
                <div style={{width: "100%", height: "200px"}}></div>
                {/* <div className="fake-main-footer">
                    {this.contentFooter()}
                </div>
                <div className="main-footer">
                    {this.contentFooter()}
                    <div className="position-absolute-bottom-left" style={{width: "30px", height: "30px"}} onClick={this.onKickassClick}/>
                </div> */}
            </Fragment>
        );
    }
}

export default withTranslation()(Footer);
