class CollectionHelper { }

CollectionHelper.concatWithoutDuplicate = (originalList, addedList, key = "id") => {
    let arr = originalList.concat(addedList);
    let ids = arr.map(o => o[key])
    return arr.filter((item, index) => !ids.includes(item[key], index + 1))
}

CollectionHelper.findOneById = (list, id) => {
    for (const item of list) {
        if (item.id === id) {
            return item;
        }
    }
    return null;
}

CollectionHelper.findOneBy = (list, key, value) => {
    for (const item of list) {
        if (item[key] === value) {
            return item;
        }
    }
    return null;
}

CollectionHelper.findBy = (list, key, value, opt = {}) => {
    let ret = [];
    if (opt['transform'] === 'trim') {
        value = value.trim().toLowerCase();
    }
    for (const item of list) {
        if (item[key] === null) {
            item[key] = ""
        }
        let comparator = item[key];
        if (opt['transform'] === 'trim' && comparator) {
            comparator = comparator.trim().toLowerCase();
        }
        if (opt['comparison'] === 'includes') {
            if (comparator.includes(value)) {
                ret.push(item);
            }
        } else if (comparator == value) {
            ret.push(item);
        }
    }
    return ret;
}

CollectionHelper.findOneByKey = (list, key) => {
    for (const item of list) {
        if (item.key == key) {
            return item;
        }
    }
    return null;
}

CollectionHelper.updateOneById = (list, id, updates) => {
    for (let i = 0; i < list.length; i++) {
        if (list[i].id === id) {
            list[i] = Object.assign(list[i], updates);
        }
    }
    return list;
}

CollectionHelper.removeOneById = (list, targetId) => {
    return list.filter(({ id }, index) => targetId !== id);
}


CollectionHelper.shareAnyElements = (arr1, arr2) => {
    return arr1.some(function (v) {
        return arr2.indexOf(v) >= 0;
    });
}

CollectionHelper.shareAllElements = (arr1, arr2) => {
    let ret = true;
    arr1.forEach(v => {
        if (arr2.indexOf(v) < 0) {
            ret = false;
        }
    });
    return ret;
}

CollectionHelper.sortBy = (aList, key, sort) => {
    let list = Object.assign([], aList);
    const splittedKey = key.split('__');
    if (sort === 'asc') {
        list.sort((a, b) => {
            let aKey = Object.assign({}, a);
            let bKey = Object.assign({}, b);
            splittedKey.forEach(element => {
                aKey = aKey[element];
                bKey = bKey[element];
            });

            if (aKey < bKey) return -1;
            if (aKey > bKey) return 1;
        });
    } else if (sort === 'desc') {
        list.sort((a, b) => {
            let aKey = Object.assign({}, a);
            let bKey = Object.assign({}, b);
            splittedKey.forEach(element => {
                aKey = aKey[element];
                bKey = bKey[element];
            });
            if (aKey > bKey) return -1;
            if (aKey < bKey) return 1;
        });
    }
    for (let i = 0; i < list.length; i++) {
        list[i]['sort_id'] = i;
    }
    return list;
}

CollectionHelper.getLowestValue = (aList, key) => {
    let list = Object.assign([], aList);
    if (aList.length == 0) {
        return null;
    }
    list.sort((a, b) => {
        if (a[key] < b[key]) return -1;
        if (a[key] > b[key]) return 1;
        return 0
    });
    return list[0][key];
}

CollectionHelper.getHighestValue = (aList, key) => {
    let list = Object.assign([], aList);
    if (aList.length == 0) {
        return null;
    }
    list.sort((a, b) => {
        if (a[key] > b[key]) return -1;
        if (a[key] < b[key]) return 1;
        return 0
    });
    return list[0][key];
}

export default CollectionHelper;