import React from 'react';

import './loader.css';

class Loader extends React.Component {
    render() {
        const { className, color } = this.props;
        let allClassNames = ["website-loader"];
        if (className) {
            allClassNames.push(className);
        }


        return (
            <svg className={allClassNames.join(' ')} viewBox="0 0 24 24">
                <defs>
                    {!!color && (
                        <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="7%" stopColor={color} />
                            <stop offset="58%" stopColor={color} />
                            <stop offset="98%" stopColor={color} />
                        </linearGradient>
                    )}
                    {!color && (
                        <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="7%" stopColor="#4bd69d" />
                            <stop offset="58%" stopColor="#30beec" />
                            <stop offset="98%" stopColor="#3a79e6" />
                        </linearGradient>
                    )}
                </defs>
                <circle className="loader__value" cx="12" cy="12" r="10" stroke="url(#linear)" />
                <circle className="loader__value" cx="12" cy="12" r="10" stroke="url(#linear)" />
                <circle className="loader__value" cx="12" cy="12" r="10" stroke="url(#linear)" />
                <circle className="loader__value" cx="12" cy="12" r="10" stroke="url(#linear)" />
                <circle className="loader__value" cx="12" cy="12" r="10" stroke="url(#linear)" />
                <circle className="loader__value" cx="12" cy="12" r="10" stroke="url(#linear)" />
            </svg>
        );
    }
}

export default Loader;