export const PLAN_JSON_LOGIC_KEYS = {
    CREATE_PROJECT: 'CREATE_PROJECT',
    CUSTOMIZE_INTEGRATED_CLASS: 'CUSTOMIZE_INTEGRATED_CLASS',
    CREATE_CUSTOM_CLASS: 'CREATE_CUSTOM_CLASS',
    FULL_REPORT_ACCESS: 'FULL_REPORT_ACCESS',
    EXPORTS: 'EXPORTS',
    MEDIAS_ADD_MEDIA: 'MEDIAS_ADD_MEDIA',
    MEDIAS_YOUTUBE_VIDEO_LANGUAGE: 'MEDIAS_YOUTUBE_VIDEO_LANGUAGE',
    USERS_ADD_USER: 'USERS_ADD_USER',
    TEAMS_ADD_TEAM: 'TEAMS_ADD_TEAM',
    DOWNGRADE_PLAN_EXPIRATION: 'DOWNGRADE_PLAN_EXPIRATION',
    DOWNGRADE_RETROGRADE: 'DOWNGRADE_RETROGRADE',
    ENTERPRISE_FEATURE: 'ENTERPRISE_FEATURE',
    GENERATIVE_REPORTS: 'GENERATIVE_REPORTS'
};
