import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";
import SettingsTeamListItem from "../../../../components/list/SettingsTeamListItem";
import CollectionHelper from "../../../../app/helpers/CollectionHelper";
import CreateTeamModal from "../modals/CreateTeamModal";
import HttpHelper from "../../../../app/helpers/HttpHelper";
import EditTeamModal from "../modals/EditTeamModal";
import EmptyStateTeams from "./EmptyStateTeams";
import { SETTINGS_USERS_AND_TEAMS } from "../../../../app/routing";

import { teamService } from "../../../../app/services/team.service";
import UserHelper from "../../../../app/helpers/UserHelper";
import EmptyStateSearch from "./EmptyStateSearch";
import { planJsonLogicService } from "../../../../app/services/plan.jsonLogic.service";
import {
  getAccountPlan,
  PLAN_JSON_LOGIC_KEYS,
} from "../../../../app/constants";
import DeleteModal from "../../../../components/modal/DeleteModal";
import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

class TeamsTab extends React.Component {
  constructor(props) {
    super(props);
    const urlParams = HttpHelper.getUrlParameters(props.location.search);
    const modale = urlParams.modale || null;
    const createDisplay = modale === "create";
    this.state = {
      sort: {
        key: null,
        order: null,
      },
      search: "",
      delete: {
        display: false,
        item: null,
        loading: false,
        error: null,
      },
      edit: {
        display: false,
        item: null,
        loading: false,
        error: null,
      },
      create: {
        display: createDisplay,
        loading: false,
        error: null,
        currentPlan: null,
        errorPlan: false,
      },
    };

    this.getTeams = this.getTeams.bind(this);
    this.getThContent = this.getThContent.bind(this);
    this.onThClick = this.onThClick.bind(this);
    this.onSearch = this.onSearch.bind(this);

    this.onOpenCreateModal = this.onOpenCreateModal.bind(this);
    this.onCloseCreateModal = this.onCloseCreateModal.bind(this);
    this.onCreateItem = this.onCreateItem.bind(this);

    this.onOpenEditModal = this.onOpenEditModal.bind(this);
    this.onCloseEditModal = this.onCloseEditModal.bind(this);
    this.onEditItem = this.onEditItem.bind(this);

    this.onOpenDeleteModal = this.onOpenDeleteModal.bind(this);
    this.onCloseDeleteModal = this.onCloseDeleteModal.bind(this);
    this.onDeleteItem = this.onDeleteItem.bind(this);
  }

  componentDidMount() {
    this._ismounted = true;
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  getTeams() {
    const { teams } = this.props;
    let ret = Object.assign([], teams);

    // order if needed
    if (this.state.sort.key) {
      ret = CollectionHelper.sortBy(
        ret,
        this.state.sort.key,
        this.state.sort.order
      );
    }
    // search if needed
    if (this.state.search) {
      ret = CollectionHelper.findBy(ret, "title", this.state.search, {
        transform: "trim",
        comparison: "includes",
      });
    }
    return ret;
  }

  onThClick(item) {
    let newSort = Object.assign({}, this.state.sort);
    if (this.state.sort.key === item.value) {
      newSort.order = this.state.sort.order === "desc" ? "asc" : "desc";
    } else {
      newSort.key = item.value;
      newSort.order = "desc";
    }
    this.setState({ sort: newSort });
  }

  getThContent(item) {
    let classNames = ["sortable-th"];
    if (this.state.sort.key === item.value) {
      classNames.push("active");
      classNames.push(this.state.sort.order);
    }
    return (
      <span
        className={classNames.join(" ")}
        onClick={() => {
          this.onThClick(item);
        }}
      >
        {item.label}
        <span className="icon" />
      </span>
    );
  }

  onSearch(event) {
    this.setState({
      search: event.target.value,
    });
  }

  onOpenCreateModal() {
    this.setState({
      create: {
        display: true,
        loading: false,
        error: null,
      },
    });
  }

  onCloseCreateModal() {
    this.props.history.replace({ pathname: SETTINGS_USERS_AND_TEAMS("teams") });
    this.setState({
      create: {
        display: false,
        loading: false,
        error: null,
        currentPlan: null,
        errorPlan: false,
      },
    });
  }

  onCreateItem(formData) {
    const { t } = this.props;
    let create = Object.assign({}, this.state.create);
    create.loading = true;
    this.setState({ create: create });
    planJsonLogicService.playRule(
      PLAN_JSON_LOGIC_KEYS.USERS_ADD_USER,
      {},
      (response) => {
        if (response.status === "error") {
          create.errorPlan = true;
          create.loading = false;
          create.currentPlan = getAccountPlan(response.data.plan);
          this.setState({
            create: create,
          });
        } else {
          let account = UserHelper.getCurrentAccount();
          formData.account_id = account.id;
          teamService.create(
            formData,
            (response2) => {
              this.onCloseCreateModal();
              this.props.fetchTeams();
              this.props.addToast(
                t('Team "{{team_title}}" successfully created!', {
                  team_title: formData.title,
                }),
                { type: "success", autoDismiss: true }
              );
            },
            (error) => {
              create.loading = false;
              create.error = HttpHelper.getErrorMessage(error);
              this.setState({
                create: create,
              });
            }
          );
        }
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        create.loading = false;
        create.error = HttpHelper.getErrorMessage(error);
        this.setState({
          create: create,
        });
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      }
    );
  }

  onOpenEditModal(item) {
    this.setState({
      edit: {
        display: true,
        item: item,
        loading: false,
        error: null,
      },
    });
  }

  onCloseEditModal() {
    this.setState({
      edit: {
        display: false,
        item: null,
        loading: false,
        error: null,
      },
    });
  }

  onEditItem(formData) {
    const { t } = this.props;
    let edit = Object.assign({}, this.state.edit);
    edit.loading = true;
    this.setState({ edit: edit });
    teamService.edit(
      formData,
      (response) => {
        this.onCloseEditModal();
        this.props.fetchTeams(true);
        this.props.addToast(
          t('Team "{{team_title}}" successfully updated', {
            team_title: formData.title,
          }),
          { type: "success", autoDismiss: true }
        );
      },
      (error) => {
        edit.loading = false;
        edit.error = HttpHelper.getErrorMessage(error);
        this.setState({
          edit: edit,
        });
      }
    );
  }

  onOpenDeleteModal(item) {
    this.setState({
      delete: {
        display: true,
        item: item,
        loading: false,
        error: null,
      },
    });
  }

  onCloseDeleteModal() {
    this.setState({
      delete: {
        display: false,
        item: null,
        loading: false,
        error: null,
      },
    });
  }

  onDeleteItem() {
    const { t } = this.props;
    let deleteData = Object.assign({}, this.state.delete);
    deleteData.loading = true;
    this.setState({ delete: deleteData });
    teamService.deleteTeam(
      deleteData.item.id,
      (response) => {
        this.onCloseDeleteModal();
        this.props.fetchTeams(true);
        this.props.addToast(
          t('Team "{{team_title}}" successfully deleted!', {
            team_title: deleteData.title,
          }),
          { type: "success", autoDismiss: true }
        );
      },
      (error) => {
        deleteData.loading = false;
        deleteData.error = HttpHelper.getErrorMessage(error);
        this.setState({
          delete: deleteData,
        });
      }
    );
  }

  render() {
    const { t } = this.props;
    const teams = this.getTeams();
    if (teams.length === 0 && this.state.search === "") {
      return (
        <Fragment>
          <EmptyStateTeams onOpenCreateModal={this.onOpenCreateModal} />
          {this.state.create.display && (
            <CreateTeamModal
              onClose={this.onCloseCreateModal}
              loading={this.state.create.loading}
              error={this.state.create.error}
              errorPlan={this.state.create.errorPlan}
              currentPlan={this.state.create.currentPlan}
              onCreateItem={this.onCreateItem}
            />
          )}
        </Fragment>
      );
    }
    return (
      <Fragment>
        <div className="font-inter-500 font-size-15 line-height-22 color-7f7f7f">
          {t("Create new teams, add and remove users from teams.")}
        </div>
        <div className="padding-top-44" />
        <div className="position-relative">
          <div className="position-absolute-top-right">
            <button className="button" onClick={this.onOpenCreateModal}>
              {t("Create Team")}
            </button>
          </div>
          <TextField
            type="search"
            className="search-input"
            placeholder={t("Search")}
            onChange={this.onSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ mb: "32px", width: "200px", height: "34px" }}
          />
        </div>
        <div className="padding-top-25" />
        <table className="sensia-table table-layout-fixed width-percent-100">
          <thead>
            <tr>
              <th className="empty-left-td-th" />
              <th>
                {this.getThContent({ label: t("Team name"), value: "title" })}
              </th>
              <th width={270} className="hide-760">
                {this.getThContent({
                  label: t("Members"),
                  value: "nb_members",
                })}
              </th>
              <th width={30} />
            </tr>
          </thead>
        </table>
        <div className="padding-top-5" />
        {this.state.search !== "" && teams.length === 0 && <EmptyStateSearch />}
        {teams.map((item, i) => {
          return (
            <SettingsTeamListItem
              key={`settings-team-list-item-${i}`}
              item={item}
              onOpenEditModal={() => {
                this.onOpenEditModal(item);
              }}
              onOpenDeleteModal={() => {
                this.onOpenDeleteModal(item);
              }}
            />
          );
        })}
        {this.state.create.display && (
          <CreateTeamModal
            onClose={this.onCloseCreateModal}
            loading={this.state.create.loading}
            error={this.state.create.error}
            errorPlan={this.state.create.errorPlan}
            currentPlan={this.state.create.currentPlan}
            onCreateItem={this.onCreateItem}
          />
        )}
        {this.state.edit.display && (
          <EditTeamModal
            onClose={this.onCloseEditModal}
            loading={this.state.edit.loading}
            item={this.state.edit.item}
            onEditItem={this.onEditItem}
          />
        )}
        {this.state.delete.display && (
          <DeleteModal
            itemValue={
              this.state.delete.item ? this.state.delete.item.title : null
            }
            title={t("Delete team")}
            subtitle={t("You are going to delete the team")}
            inputLabel={t("Enter team name to confirm")}
            buttonLabel={t("Delete team")}
            onClose={this.onCloseDeleteModal}
            loading={this.state.delete.loading}
            onDeleteItem={this.onDeleteItem}
          />
        )}
      </Fragment>
    );
  }
}

export default withTranslation()(TeamsTab);
