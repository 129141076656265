import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import {
  globalConstants,
  MEDIA_STATUS,
  PLAN_JSON_LOGIC_KEYS,
} from "../../app/constants";
import LoadingView from "../loading/LoadingView";
import Header from "../../components/header/Header";

import { withTranslation, Trans } from "react-i18next";
import { projectService } from "../../app/services/project.service";
import HttpHelper from "../../app/helpers/HttpHelper";
import Link from "../../components/Link";
import {
  WORKSPACE_BY_KEYWORDS,
  PROJECT_INSIGHTS,
  // PROJECT_MEDIA_INSIGHTS,
} from "../../app/routing";

import { reportService } from "../../app/services/report.service";
import { planJsonLogicService } from "../../app/services/plan.jsonLogic.service";
import ListOptSelect from "../../components/listOptSelect/ListOptSelect";
import { mediaService } from "../../app/services/media.service";
import RelevantList from "../projectReport/elements/RelevantList";
import Loader from "../../components/loader/Loader";
import AjaxKeywordTooltipContent from "../../components/tooltip/AjaxKeywordTooltipContent";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import IntercomHelper from "../../app/helpers/IntercomHelper";

class ProjectInsightsView extends React.Component {
  constructor(props) {
    super(props);

    const projectId = props.match.params.id
      ? parseInt(props.match.params.id)
      : null;
    const datasourceId = props.match.params.datasource_id
      ? parseInt(props.match.params.datasource_id)
      : null;

    this.state = {
      projectId: projectId,
      datasourceId: datasourceId,
      project: null,
      isLoaded: false,
      inlineFullLoading: false,
      initialInsightLoading: true,
      inlineLoading: true,
      isFirstLoad: true,
      mediasList: [],
      insightsList: [],
      stats: {
        score: null,
        emotions: [],
        occurences: 0,
        report: [],
      },
      formData: {
        datasource_id: datasourceId,
        page: 1,
        page_size: 40,
      },
      update_keyword_class: {
        display: false,
        keyword: null,
      },
      listItems: [],
      totalItems: 0,
    };

    this.fetchInitial = this.fetchInitial.bind(this);
    this.fetchProject = this.fetchProject.bind(this);
    this.fetchInsights = this.fetchInsights.bind(this);
    this.fetchInsightsSeeMore = this.fetchInsightsSeeMore.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.fetchMediaList = this.fetchMediaList.bind(this);
    this.displayToastErrorMessage = this.displayToastErrorMessage.bind(this);
    // this.onChangeMediaScope = this.onChangeMediaScope.bind(this);
    this.getMediaScopeList = this.getMediaScopeList.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.fetchInsightsCount = this.fetchInsightsCount.bind(this);
    this.getWorkspaceLinkByTarget = this.getWorkspaceLinkByTarget.bind(this);
    this.getKeywordTooltipContent = this.getKeywordTooltipContent.bind(this);
    this.onOpenUpdateKeywordClassModal =
      this.onOpenUpdateKeywordClassModal.bind(this);

    IntercomHelper.updateIntercom();
  }

  componentDidMount() {
    this._ismounted = true;
    this.fetchInitial();
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    this._ismounted = false;
    window.removeEventListener("scroll", this.handleScroll);
  }

  fetchInitial() {
    planJsonLogicService.playRule(
      PLAN_JSON_LOGIC_KEYS.FULL_REPORT_ACCESS,
      {},
      (response) => {
        this.setState({
          hasLimitedAccess: response.status === "error",
          limitation_data: response.data,
        });
        this.fetchProject();
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
        this.fetchData();
      }
    );
  }

  handleScroll() {
    if (this.state.hasLimitedAccess) {
      return;
    }
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );

    const windowBottom = windowHeight + window.pageYOffset + 5;
    if (
      windowBottom >= docHeight &&
      this.state.listItems.length < this.state.totalItems
    ) {
      const infos = {
        page: this.state.formData.page + 1,
      };
      this.fetchData(infos);
    }
  }

  async fetchProject() {
    await projectService.get.get(
      this.state.projectId,
      (response) => {
        this.setState({
          project: response,
          changeAttributes: true,
        });
        this.fetchMediaList();
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      },
      { schema: "report" }
    );
  }

  fetchData(data, resetListItem) {
    data = data || {};
    if (
      !this.state.isFirstLoad &&
      !resetListItem &&
      this.state.listItems.length >= this.state.totalItems
    ) {
      return;
    }

    if (this._ismounted) {
      this.setState({
        inlineLoading: true,
      });
    }
    let newFormData = Object.assign(this.state.formData, data);
    if (!newFormData.datasource_id) {
      delete newFormData["datasource_id"];
    }
    if (resetListItem) {
      this.setState({
        initialInsightLoading: true,
        initialRelevantLoading: true,
        inlineFullLoading: false,
        insightsList: [],
        listItems: [],
        totalItems: 0,
        datasourceId: newFormData.datasource_id || null,
        stats: {
          score: this.state.stats.score,
          emotions: [],
          report: [],
          occurences: 0,
        },
        statsLoading: true,
      });
    }
    reportService.get.getGraphData(
      this.state.projectId,
      newFormData,
      (response) => {
        let currentListItems = Object.assign([], this.state.listItems);
        const newListItems = resetListItem
          ? response.data
          : currentListItems.concat(response.data);

        newFormData.page = response.meta.page;
        newFormData.page_size = response.meta.page_size;
        const totalItems = response.meta.total;
        const range = response.meta.range;
        this.setState({
          formData: newFormData,
          initialRelevantLoading: false,
          listItems: newListItems,
          totalItems: totalItems,
          range: range,
          isFirstLoad: false,
          inlineLoading: false,
          inlineFullLoading: false,
        });
        this.fetchInsights();
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.setState({
          formData: newFormData,
          inlineLoading: false,
          inlineFullLoading: false,
        });
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      }
    );
  }

  fetchMediaList() {
    mediaService.get.getProjectMedias(
      this.state.projectId,
      {
        sorted_by: "updated_at",
      },
      (response) => {
        let mediasList = [];
        let mediaExist = false;
        response.data.forEach((element) => {
          if (element.status === MEDIA_STATUS.SUCCESS) {
            mediasList.push({
              id: element.id,
              title: element.title,
            });
            if (
              this.state.formData.datasource_id &&
              element.id === this.state.formData.datasource_id
            ) {
              mediaExist = true;
            }
          }
        });

        if (this.state.formData.datasource_id && !mediaExist) {
          let formData = Object.assign({}, this.state.formData);
          formData.datasource_id = null;
          this.setState({
            datasourceId: null,
            formData: formData,
            mediasList: mediasList,
          });
          this.props.history.replace({
            pathname: PROJECT_INSIGHTS(this.state.projectId),
          });
          this.fetchData(formData);
          this.fetchInsightsCount(formData, () => {
            this.fetchInsights();
          });
        } else {
          this.setState({
            mediasList: mediasList,
          });
          this.fetchData();
          this.fetchInsightsCount(null, () => {
            this.fetchInsights();
          });
        }
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      }
    );
  }

  fetchInsights(data, changeScope = false) {
    data = data || {};
    const newFormData = Object.assign(this.state.formData, data);

    if (changeScope) {
      delete newFormData.datasources;
      delete newFormData.attributes;
      delete newFormData.languages;
    }

    // this.fetchStats(newFormData);
    reportService.get.getInsight(
      this.state.projectId,
      newFormData,
      (response) => {
        let insightsList = Object.assign([], response.data);
        for (const item of insightsList) {
          item.meta = {
            offset: 0,
            limit: 10,
            startLimit: 3,
            loading: false,
          };
        }
        this.setState({
          insightsList: insightsList,
          initialInsightLoading: false,
        });
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      }
    );
  }

  fetchInsightsCount(data, onThen) {
    data = data || {};
    const newFormData = Object.assign(this.state.formData, data);

    reportService.get.getInsightCount(
      this.state.projectId,
      newFormData,
      (response) => {
        let insightsList = Object.assign([], response.data);
        for (const item of insightsList) {
          item.meta = {
            offset: 0,
            limit: 10,
            startLimit: 3,
            loading: false,
          };
        }
        this.setState({
          insightsList: insightsList,
          isLoaded: true,
        });
        if (onThen) {
          onThen();
        }
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        if (onThen) {
          onThen();
        }
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      }
    );
  }

  fetchInsightsSeeMore(targetClassId) {
    let insightsList = Object.assign([], this.state.insightsList);
    let targetListId = null;
    let targetClass = null;
    insightsList.forEach((element, id) => {
      if (element.id === targetClassId) {
        targetListId = id;
        targetClass = element;
      }
    });
    if (!targetClass) {
      return false;
    }

    targetClass.meta.offset = targetClass.keywords.length;
    targetClass.meta.loading = true;

    let formData = this.state.formData;
    formData.offset = targetClass.meta.offset;
    formData.limit = targetClass.meta.limit;
    formData.classe_id = targetClassId;

    if (this.state.datasourceId) {
      formData.datasource_id = this.state.datasourceId;
    }
    insightsList[targetListId] = targetClass;
    this.setState({
      insightsList: insightsList,
    });
    reportService.get.getInsightMore(
      this.state.projectId,
      formData,
      (response) => {
        const keywordsToAppend =
          response.data.length > 0 ? response.data[0].keywords : [];
        const newKeywords = targetClass.keywords.concat(keywordsToAppend);
        targetClass.keywords = newKeywords;
        targetClass.meta.loading = false;
        if (keywordsToAppend.length < targetClass.meta.limit) {
          targetClass.meta.stop = true;
        }
        insightsList[targetListId] = targetClass;
        this.setState({
          insightsList: insightsList,
        });
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        targetClass.meta.loading = false;
        targetClass.meta.stop = true;
        insightsList[targetListId] = targetClass;
        this.setState({
          insightsList: insightsList,
        });
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      }
    );
  }

  getWorkspaceLinkByTarget(target) {
    const projectId = this.state.projectId;
    const datasourceId = this.state.formData.datasource_id;
    return WORKSPACE_BY_KEYWORDS(projectId, datasourceId, target);
  }

  getKeywordTooltipContent(keyword) {
    const project = this.state.project;
    if (!project) {
      return null;
    }
    const report = this.state.stats.report;
    if (!report || (report && report.length == 0)) {
      return null;
    }
    return (
      <div className="base-tooltip">
        <AjaxKeywordTooltipContent
          projectId={this.state.projectId}
          datasourceId={this.state.datasourceId}
          keyword={keyword}
          onOpenUpdateKeywordClassModal={this.onOpenUpdateKeywordClassModal}
        />
      </div>
    );
  }

  onOpenUpdateKeywordClassModal(aKeyword) {
    this.setState({
      update_keyword_class: {
        display: true,
        keyword: aKeyword,
      },
    });
  }

  displayToastErrorMessage(error, onThen, updatedState) {
    if (!this._ismounted) {
      return;
    }
    this.props.addToast(HttpHelper.getErrorMessage(error), {
      type: "error",
      autoDismiss: true,
    });
    if (typeof onThen == "function") {
      onThen();
    }
    if (updatedState) {
      this.setState(updatedState);
    }
  }

  // onChangeMediaScope(infos) {
  //   const currentInfos = {
  //     datasource_id: this.state.formData.datasource_id,
  //   };
  //   if (JSON.stringify(infos) === JSON.stringify(currentInfos)) {
  //     return false;
  //   }
  //   const currentUrl = window.location.href.replace(window.location.origin, "");
  //   const targetUrl = infos.datasource_id
  //     ? PROJECT_MEDIA_INSIGHTS(this.state.projectId, infos.datasource_id)
  //     : PROJECT_INSIGHTS(this.state.projectId);
  //   if (currentUrl !== targetUrl) {
  //     this.props.history.replace({ pathname: targetUrl });
  //   }
  //   infos.page = 1;

  //   this.setState({
  //     selectedLanguages: [],
  //     selectedDataSource: [],
  //     changeAttributes: true,
  //   });

  //   this.fetchData(infos, true);
  //   this.fetchInsightsCount(null, () => {
  //     this.fetchInsights({}, true);
  //   });
  // }

  getMediaScopeList() {
    const { t } = this.props;
    let ret = {};
    const currentDatasourceIdValue = this.state.formData.datasource_id;
    ret.top_message = [
      {
        label: t("Choose insights scope"),
      },
    ];
    ret.datasource_id = [
      {
        label: t("Project"),
        value: null,
        active: currentDatasourceIdValue == null,
        activeLabel: t("Project"),
      },
    ];
    const mediasList = this.state.mediasList;
    mediasList.forEach((element) => {
      ret.datasource_id.push({
        label: element.title,
        value: element.id,
        active: currentDatasourceIdValue === element.id,
        activeLabel: element.title,
      });
    });

    return ret;
  }

  render() {
    const { t } = this.props;
    let page_title = `${
      this.state.project ? "Insights: " + this.state.project.name : "Keywords"
    } - ${globalConstants.WEBSITE_TITLE}`;

    const ITEM_HEIGHT = 41;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

    if (this.state.isLoaded) {
      const project = this.state.project;

      return (
        <Fragment>
          <Header isProject={true} />
          <Helmet>
            <title>{page_title}</title>
          </Helmet>

          <div className="padding-top-60 responsive-920-padding-top-20">
            <div className="central-content">
              {project && (
                <div className="display-flex padding-bottom-10 align-items-center vertical-align-center">
                  <Breadcrumb project={this.state.project} />
                </div>
              )}
              <div className="font-size-36 font-inter-700 color-000 padding-bottom-15">
                {t("Keywords")}
              </div>
              <div
                className={
                  !this.state.hasLimitedAccess
                    ? "padding-bottom-50 responsive-920-padding-bottom-20"
                    : "padding-bottom-5"
                }
              >
                <ListOptSelect
                  displayFamily="datasource_id"
                  customClass="big-blue"
                  defaultActive={this.state.formData.datasource_id}
                  position="left"
                  // onChange={this.onChangeMediaScope}
                  lists={this.getMediaScopeList()}
                />
              </div>

              {!this.state.inlineFullLoading &&
                !this.state.initialInsightLoading &&
                this.state.listItems &&
                this.state.totalItems > 0 && (
                  <RelevantList
                    title="Keywords"
                    hasLimitedAccess={this.state.hasLimitedAccess}
                    listItems={this.state.listItems}
                    projectId={this.state.projectId}
                    datasourceId={this.state.datasourceId}
                    min={this.state.range.min}
                    max={this.state.range.max}
                    getWorkspaceLinkByTarget={this.getWorkspaceLinkByTarget}
                    getKeywordTooltipContent={this.getKeywordTooltipContent}
                  />
                )}
              {(this.state.inlineLoading ||
                this.state.initialInsightLoading) && (
                <div className="padding-top-20">
                  <Loader />
                </div>
              )}
            </div>
          </div>
        </Fragment>
      );
    }
    return (
      <LoadingView
        title={page_title}
        withHeader={true}
        headerProps={{ isProject: true }}
      />
    );
  }
}
export default withTranslation()(ProjectInsightsView);
