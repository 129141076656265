import React, { Fragment } from 'react';
import './classListItem.css';
import { withTranslation } from "react-i18next";
import ClassModal from './ClassModal';
import RoundSelect from '../roundSelect/RoundSelect';
import { CLASS_EDIT } from '../../app/routing';
import Link from "../../components/Link";
import ReportHelper from '../../app/helpers/ReportHelper';

class ClassListItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalIsOpened: false
        };
        this.onOpenModalClick = this.onOpenModalClick.bind(this);
        this.onCloseModalClick = this.onCloseModalClick.bind(this);
    }

    onOpenModalClick() {
        this.setState({ modalIsOpened: true });
    }

    onCloseModalClick() {
        this.setState({ modalIsOpened: false });
    }
    
    render() {
        const { t, classItem, name, isOther } = this.props;
        let projectId = this.props.projectId;
        let propsContentClassList = {};
        let classContentClassList = ["content-class-list", "transition-color"];
        if (isOther) {
            classContentClassList.push('isOther');
        }
        if (this.props.openModalOnClick) {
            classContentClassList.push('open-modal-onclick')
            propsContentClassList['onClick'] = this.onOpenModalClick;
        }
        propsContentClassList['className'] = classContentClassList.join(' ');
        
        const nbKeywords = (classItem.keywords > 1) ? t('{{count}} attributes', { count: classItem.keywords }) : t('{{count}} attribute', { count: classItem.keywords });
        const typology = (classItem.consumer && classItem.lab_expert) ? "Consumer, Lab / Expert" : classItem.consumer ? "Consumer" : classItem.lab_expert ? "Lab / Expert" : "";

        let integrated = classItem.isIntegrated === true && (<span className="font-size-13 color-0e004b font-inter-500"> • {t('Integrated')}</span>)
        return (
            <div className="content-class-list-item">
                {!isOther &&
                    <div className="content-checkbox">
                        <input type="checkbox" id={`class-list-item-${classItem.id}`} value={classItem.id} name={name} title={classItem.title} onChange={this.props.onChange} checked={this.props.checked} disabled={this.props.disabled || ReportHelper.classIsExcludedWords(classItem)} />
                        <label className="fake-checkbox" htmlFor={`class-list-item-${classItem.id}`} />
                    </div>
                }
                {this.props.rightIsLabel && (
                    <label {...propsContentClassList} htmlFor={`class-list-item-${classItem.id}`}>
                        <div className="font-size-18 color-000 font-inter-600 padding-bottom-4 text-break-line">{classItem.display_name}{integrated}</div>
                        <div className="font-size-13 color-7f7f7f">{nbKeywords}</div>
                    </label>
                )}
                {!this.props.rightIsLabel && (
                    <div {...propsContentClassList}>
                        <div className="font-size-18 color-000 font-inter-600 padding-bottom-4 text-break-line">{classItem.display_name} ({classItem.title}){integrated}</div>
                        <div className="font-size-13 color-7f7f7f">{nbKeywords} {classItem.title !== "Excluded words" && `• ${typology}`}</div>
                        {this.props.displayButtonEdit &&
                            <Fragment>
                                <div className="content-edit-button transition-opacity">
                                    <Link to={CLASS_EDIT(projectId, classItem.id)}>
                                        <button className="edit-button button light-blue">
                                            {((this.props.canEditIntegratedClass && classItem.isIntegrated) || (this.props.canEditCustomClass && !classItem.isIntegrated)) ? t('Edit') : t('View')}
                                        </button>
                                    </Link>
                                </div>
                                {!classItem.isIntegrated && !this.props.hideDeleteAction &&
                                    <div className="button-position">
                                        <RoundSelect
                                            className="light-blue"
                                            lists={{
                                                other: [
                                                    { label: t('Delete class…'), action: this.props.onOpenDeleteModal, style: { color: '#e04f77' } }
                                                ]
                                            }} />
                                    </div>
                                }
                            </Fragment>
                        }
                        {this.state.modalIsOpened && <ClassModal onClose={this.onCloseModalClick} classItem={classItem} />}
                    </div>
                )}
            </div>
        );
    }
}

export default withTranslation()(ClassListItem);