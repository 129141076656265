import React from "react";
import { Doughnut } from 'react-chartjs-2';
import { withTranslation } from 'react-i18next';
import { backofficeService } from "../../app/services/backoffice.service";
import Loader from "../loader/Loader";

export const chartColors = [
  "#3d5a80",
  "#98c1d9",
  "#ee6c4d",
  "#05668d",
  "#00a896",
  "#e0fbfc",
  "#02c39a",
  "#f0f3bd",
  "#028090",
  "#e63946",
  "#a8dadc",
  "#1d3557",
];
class AdminMediaByTypeChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mediaByService: null,
      mediaServiceIsLoaded: false,
    };
  }

  componentDidMount() {
    this.fetchMediaByService(this.props.year);
  }

  fetchMediaByService() {
    backofficeService.getMediaByService(
      (response) => {
        this.setState({
          mediaByService: response.data,
          mediaServiceIsLoaded: true,
        });
      },
      (error) => {
        console.log(error);
        this.setState({
          mediaServiceIsLoaded: true,
        });
      }
    );
  }

  chartData() {
    const chartData = {
      labels: Object.keys(this.state.mediaByService),

      datasets: [
        {
          label: "Data sources by Service",
          data: Object.values(this.state.mediaByService),
          backgroundColor: chartColors,
          borderWidth: 1,
        },
      ],
    };

    return chartData;
  }

  option() {
    const options = {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        legend: {
          position: "bottom",
          align: "center",
        },
      },
    };

    return options;
  }

  render() {
    const { t } = this.props;
    return (
      <div className="doughnutChartBox">
        {!this.state.mediaServiceIsLoaded && <Loader />}
        {this.state.mediaServiceIsLoaded && (
          <div className="chartTitle">{t("Data sources uploaded")}</div>
        )}
        {this.state.mediaServiceIsLoaded && (
          <Doughnut
            className="chart chart-modified"
            data={this.chartData()}
            options={this.option()}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(AdminMediaByTypeChart);