import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { ROUTES } from '../../../app/routing';
import Link from "../../../components/Link";
import UserHelper from'../../../app/helpers/UserHelper';


class SettingsMenu extends React.Component {
    render() {
        const { t } = this.props;
        const userIsAdmin = UserHelper.isAdmin();
        let links = []
        if (userIsAdmin) {
            links = [
                { label: t('Profile & Preferences'), to: ROUTES.SETTINGS_PROFILE_AND_PREFERENCES },
                { label: t('Default Account Settings'), to: ROUTES.SETTINGS_DEFAULT_ACCOUNT },
                { label: t('Users & Teams'), to: ROUTES.SETTINGS_USERS_AND_TEAMS_BASE },
                { label: t('Plan & Billing'), to: ROUTES.SETTINGS_PLAN_AND_BILLING_BASE },
            ];
        } else {
            links = [
                { label: t('Profile & Preferences'), to: ROUTES.SETTINGS_PROFILE_AND_PREFERENCES },
            ];
        }
        
        return (
            <Fragment>
                <div className="padding-bottom-10">
                    <Link to={ROUTES.HOME} className="settings-button-go-back transition-opacity">
                        <span className="icon"></span>
                        <span className="font-inter-400 font-size-18">{t('Back')}</span>
                    </Link>
                </div>
                <div className="font-inter-700 font-size-28 color-000">{t('Settings')}</div>
                <div className="padding-top-20" />
                <div className="content-settings-menu">
                    {
                        links.map((item, j) => {
                            let classNames = ['font-inter-500'];
                            if(item.to === this.props.current){
                                classNames.push('active');
                            }
                            if(item.to === "#"){
                                return (
                                    <a key={`sml-${j}`} href={item.to} className={classNames.join(' ')}>{item.label}</a>  
                                );
                            }
                            return (
                                <Link key={`sml-${j}`} to={item.to} className={classNames.join(' ')}>{item.label}</Link>
                            );
                        })
                    }
                </div>
            </Fragment>
        );
    }
}

export default withTranslation()(SettingsMenu);