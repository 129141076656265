import * as React from "react";
import { Button, FunctionField, Link } from "react-admin";
import moment from "moment-timezone";
import ColoredScore from "../../../components/ui/ColoredScore";
import EllipsisTooltip from "../../../components/tooltip/EllipsisTooltip";
import { ArrowBack } from "@mui/icons-material";

export const DateFieldForTimestamp = (props) => {
  return (
    <FunctionField
      label={props.label}
      render={(record) => {
        const timestamp = record[props.source]
          ? parseInt(record[props.source], 10)
          : null;
        if (!timestamp) {
          return props.emptyText;
        }
        return (
          <span
            title={moment
              .utc(timestamp * 1000)
              .local()
              .format("DD/MM/YYYY HH:mm:ss")}
          >
            {moment
              .utc(timestamp * 1000)
              .local()
              .format("DD/MM/YYYY")}
          </span>
        );
      }}
    />
  );
};

export const TextFieldEllipsis = (props) => {
  return (
    <FunctionField
      label={props.label}
      render={(record) => {
        const value = record[props.source];
        const customStyle = props.style ? props.style : {};
        return (
          <div className="field-cell-text-ellipsis" style={customStyle}>
            <EllipsisTooltip content={value} placement={"bottom"}>
              {value}
            </EllipsisTooltip>
          </div>
        );
      }}
    />
  );
};

export const ScoreField = (props) => {
  return (
    <FunctionField
      label={props.label}
      render={(record) => {
        const score = record[props.source];
        return <ColoredScore score={score} withHelpTooltip={false} />;
      }}
    />
  );
};

export const BackButton = (props) => {
  return (
    <Button
      sx={{ "& .MuiButton-startIcon": { width: "100%", m: 0 } }}
      component={Link}
      to={{ pathname: props.to }}
    >
      <ArrowBack fontSize="38px" />
      Back
    </Button>
  );
};
