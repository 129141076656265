import React from 'react'
import PropTypes from 'prop-types'
import './rounded-emotion.css';
import StringHelper from '../../app/helpers/StringHelper';
import EmotionHelper from '../../app/helpers/EmotionHelper';
import AjaxEmotionTooltipContent from '../tooltip/AjaxEmotionTooltipContent';
import Tooltip from '../tooltip/Tooltip';
import BlurDataHelper from '../../app/helpers/BlurDataHelper';
import EmotionTooltipContent from '../tooltip/EmotionTooltipContent';


export const RoundedEmotion = ({ emotion, className, withTooltip, Id, projectId, datasourceId, start_at, keyword, hasLimitedAccess, allEmotions, ...props }) => {
  let contentClasses = ['content-rounded-emotion'];
  if (className) {
    contentClasses.push(className);
  }
  if(hasLimitedAccess){
    return <div className={`blured-emotion ${BlurDataHelper.getRandomClass()}`} />
  }
  if (!emotion) {
    return <div className="padding-top-25"></div>
  }

  const getTooltipContent = () => {
    if(allEmotions){
      return <EmotionTooltipContent data={allEmotions}/>
    }
  
    return (
      <AjaxEmotionTooltipContent id={Id} projectId={projectId} datasourceId={datasourceId} keyword={keyword} start_at={start_at}/>
    );
  }

  if (withTooltip) {
    return (
      <Tooltip content={getTooltipContent()} placement="bottom">
        <span className={contentClasses.join(' ')} {...props}>
          <div className="round-emotion-color" style={{ backgroundColor: EmotionHelper.getEmotionColor(emotion) }} />
          <div className="vertical-align-middle display-inline-block font-inter font-size-15 line-height-19 color-7f7f7f cursor-default">
            {emotion && StringHelper.capitalizeFirstLetter(emotion)}
          </div>
        </span>
      </Tooltip>
    )
  }

  return (
    <span className={contentClasses.join(' ')} {...props}>
      <div className="round-emotion-color" style={{ backgroundColor: EmotionHelper.getEmotionColor(emotion) }} />
      <div className="vertical-align-middle display-inline-block font-inter font-size-15 line-height-19 color-7f7f7f cursor-default">
        {emotion && StringHelper.capitalizeFirstLetter(emotion)}
      </div>
    </span>
  )
}

RoundedEmotion.propTypes = {
  emotion: PropTypes.string,
  className: PropTypes.string,
}

export default RoundedEmotion
