import HttpHelper from "../helpers/HttpHelper";

async function getCategories(accountId, onSuccess, onError) {
  return HttpHelper.authApiFetch(
    "/api/v1/account-categories/" + accountId,
    HttpHelper.method.GET,
    null,
    onSuccess,
    onError
  );
}

async function getProjectCategories(accountId, projectId, onSuccess, onError) {
  return HttpHelper.authApiFetch(
    "/api/v1/account-categories/" + accountId + "/project/" + projectId,
    HttpHelper.method.GET,
    null,
    onSuccess,
    onError
  );
}

async function getIntegrated(onSuccess, onError) {
  return HttpHelper.authApiFetch(
    "/api/v1/integrated-classes",
    HttpHelper.method.GET,
    null,
    onSuccess,
    onError
  );
}

async function getCategorie(categorieId, onSuccess, onError) {
  return HttpHelper.authApiFetch(
    "/api/v1/classes/" + categorieId,
    HttpHelper.method.GET,
    null,
    onSuccess,
    onError
  );
}

async function getClassStats(projectId, formData, onSuccess, onError) {
  return HttpHelper.authApiFetch(
    "/api/v1/report/classes-stats/" + projectId,
    HttpHelper.method.GET,
    formData,
    onSuccess,
    onError
  );
}

async function deleteClass(classId, onSuccess, onError) {
  return HttpHelper.authApiFetch(
    "/api/v1/classes/" + classId,
    HttpHelper.method.DELETE,
    null,
    onSuccess,
    onError
  );
}

async function updateClass(classId, formData, onSuccess, onError) {
  return HttpHelper.authApiFetch(
    "/api/v1/classes/" + classId,
    HttpHelper.method.PUT,
    formData,
    onSuccess,
    onError
  );
}

async function updateKeywordClass(projectId, formData, onSuccess, onError) {
  return HttpHelper.authApiFetch(
    "/api/v1/categories/update-keyword-class/" + projectId,
    HttpHelper.method.PUT,
    formData,
    onSuccess,
    onError
  );
}

async function createClass(formData, onSuccess, onError) {
  return HttpHelper.authApiFetch(
    "/api/v1/classes",
    HttpHelper.method.POST,
    formData,
    onSuccess,
    onError
  );
}

export const classService = {
  get: {
    getAll: getCategories,
    getProjectCategories: getProjectCategories,
    get: getCategorie,
    stats: getClassStats,
    getIntegrated: getIntegrated,
  },
  delete: {
    delete: deleteClass,
  },
  put: {
    update: updateClass,
    updateKeywordClass: updateKeywordClass,
  },
  post: {
    create: createClass,
  },
};
