import React from 'react';
import './tooltip.css';
import { withTranslation } from "react-i18next";
import LoadingTooltipContent from './LoadingTooltipContent';
import { keywordService } from '../../app/services/keyword.service';
import HttpHelper from '../../app/helpers/HttpHelper';
import ErrorTooltipContent from './ErrorTooltipContent';
import EmotionTooltipContent from './EmotionTooltipContent';
import { workspaceService } from '../../app/services/workspace.service';


class AjaxEmotionTooltipContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      error: null,
      data: null,
      type: 'keyword'
    };

    this.fetchData = this.fetchData.bind(this);
    this.fetchKeywordService = this.fetchKeywordService.bind(this);
    this.updateErrorMessage = this.updateErrorMessage.bind(this);
  }

  componentDidMount() {
    this._ismounted = true;
    this.fetchData();
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  updateErrorMessage(error){
    if (this._ismounted) {
      this.setState({
        isLoaded: true,
        error: HttpHelper.getErrorMessage(error),
      });
    }
  }

  fetchKeywordService(formData) {
    keywordService.get.emotion(
      this.props.keyword.toLowerCase(),
      formData,
      (response) => {
        if (this._ismounted) {
          this.setState({
            data: response.data,
            isLoaded: true
          });
        }
      },
      (error) => {
        this.updateErrorMessage(error);
      }
    );
  }

  fetchData() {
    if (this.state.isLoaded || !this._ismounted) {
      return;
    }
    let formData = {};
     
    if (this.props.projectId) {
      formData.project_id = this.props.projectId;
    }
    if (this.props.datasourceId) {
      formData.datasource_id = this.props.datasourceId;
    }
    if (this.props.keyword){
      this.fetchKeywordService(formData)
    }
    else if(this.props.start_at || this.props.start_at === 0) {

      let start_at = Number.isInteger(this.props.start_at) === true ? this.props.start_at.toFixed(2) : this.props.start_at
      
      workspaceService.get.getEmotions(
        //this.props.projectId, this.props.datasourceId, start_at,
        this.props.id,
        (response) => {
          if (this._ismounted) {
            this.setState({
              data: response.data,
              isLoaded: true,
              type: 'start_at'
            });
          }
        },
        (error) => {
          this.updateErrorMessage(error);
        }
      );
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="base-tooltip">
          <LoadingTooltipContent />
        </div>
      );
    }
    if (this.state.error) {
      return (
        <div className="base-tooltip">
          <ErrorTooltipContent error={this.state.error} />
        </div>
      );
    }
    return (
      <EmotionTooltipContent data={this.state.data} type={this.state.type}/>
    );
  }
}

export default withTranslation()(AjaxEmotionTooltipContent);