
import React, { Fragment } from 'react';
import { withTranslation } from "react-i18next";
import MediaHelper from '../../../app/helpers/MediaHelper';

class InformationsTxtFile extends React.Component {
    render() {
        const { t, data, fileData } = this.props;
        let listItemsClasses = ["content-information-media", 'txt-file'];
        if (data.status) {
            listItemsClasses.push(data.status);
        }

        const defaultLanguage = (fileData.language_infos && fileData.language_infos.status === 'success') ? fileData.language_infos.language : null;

        return (
            <div className={listItemsClasses.join(" ")}>
                <div className="close-btn" onClick={this.props.onCloseClick} />
                {data.status === 'error' && <div className="padding-10">{data.message}</div>}
                {data.status === 'success' &&
                    <Fragment>
                        <div className="picture background-image-cover" />
                        <div className="content-left">
                            <div className="font-inter-600 font-size-15 color-000 text-ellipsis">{data.title}</div>
                            {data.size > 0 && <div className="font-inter-400 font-size-13 color-7f7f7f padding-bottom-24">{MediaHelper.formatBytes(data.size)}</div>}
                            <div className="content-captions-infos">
                                {!!defaultLanguage && <div className="font-inter-400 font-size-13 color-7f7f7f padding-bottom-2">{t('Detected language')}: {defaultLanguage}</div>}
                            </div>
                        </div>
                    </Fragment>
                }
            </div>
        );
    }
}

export default withTranslation()(InformationsTxtFile);