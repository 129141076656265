import React, { Fragment } from 'react';
import './list.css';
import { withTranslation } from "react-i18next";
import RoundSelect from '../roundSelect/RoundSelect';
import { USER_ROLES, USER_STATUS, USER_ACCOUNT_STATUS } from '../../app/constants';
import UserHelper from '../../app/helpers/UserHelper';

class SettingsUserListItem extends React.Component {
    constructor(props) {
        super(props);
        const currentUser = UserHelper.getUser();
        this.isCurrentUser = (props.item && currentUser && currentUser.id === props.item.id)
        this.getRoleLibelle = this.getRoleLibelle.bind(this);
        this.getRoundSelectOption = this.getRoundSelectOption.bind(this);
        this.userRoleHandlerRoundSelect = this.userRoleHandlerRoundSelect.bind(this);
    }
    getRoleLibelle() {
        const { t, item } = this.props;
        if (item.role === USER_ROLES.USER) {
            return t('User');
        }
        if (item.role === USER_ROLES.ADMIN) {
            return t('Administrator');
        }
        return item.role;
    }

    userRoleHandlerRoundSelect(t, item) {
        let roundSelectList = {}
        
        roundSelectList['actions'] = [{ label: t('Edit'), action: this.props.onOpenEditModal }];
        if (item.role !== USER_ROLES.ADMIN) {
            roundSelectList.actions.push({ label: t('Set as administrator'), action: this.props.onSetAsAdministrator },)
        }
        if (item.status === USER_STATUS.ACTIVE) {
            roundSelectList.actions.push({ label: t('Reset password'), action: this.props.onResetPasswordUser });
        } else if (item.status === USER_STATUS.PENDING) {
            roundSelectList.actions.push({ label: t('Resent invitation'), action: this.props.onResentInvitationEmail });
        }
        return roundSelectList
    }

    getRoundSelectOption() {
        const { t, item, account } = this.props;
        let roundSelectList = this.userRoleHandlerRoundSelect(t, item)

        if (account) {
            if (!this.isCurrentUser && (account.owner_id !== item.user_id)) {
                roundSelectList['other'] = [
                    { label: t('Delete user…'), action: this.props.onOpenDeleteModal, style: { color: '#e04f77' } }
                ];
                if (item.user_status === USER_ACCOUNT_STATUS.ACTIVE) {
                    roundSelectList.actions.push(
                        { label: t('Deactivate'), action: this.props.onOpenActivateModal }
                    )
                }
            }
            if (item.user_status === USER_ACCOUNT_STATUS.INACTIVE) {
                roundSelectList['actions'] = [{ label: t('Activate user'), action: this.props.onActiveUserAccount }]
            }
        }
        return {
            roundSelectList,
        }
    }

    render() {
        const { t, item } = this.props;
        let listItemsClasses = ["settings-user-list-item", "transition-color"];
        let roundClasses = ['colored-round-user', 'font-inter-500', item.color];
        let roundStyle = {}

        if (item.profile_picture) {
            roundStyle = { backgroundImage: `url("${item.profile_picture}")` }
            roundClasses = ['colored-round-user', 'pictured']
        }
        const { roundSelectList} = this.getRoundSelectOption()

        return (
            <div className={listItemsClasses.join(" ")}>
                <table className="sensia-table table-layout-fixed width-percent-100">
                    <tbody>
                        <tr>
                            <td width={36}>
                                <div className="text-align-center">
                                    <div className={roundClasses.join(' ')} style={roundStyle}>{item.initial}</div>
                                </div>
                            </td>
                            <td>
                                <div className="position-relative">
                                    <div className="font-inter-600 text-ellipsis line-height-20 color-000 font-size-15">
                                        {item.status === USER_STATUS.PENDING && (<Fragment><span className="round-pending margin-right-6" />{t('Invite pending')}</Fragment>)}
                                        {item.status !== USER_STATUS.PENDING && item.fullname}
                                        <span className="margin-right-6" />
                                        {item.user_status === USER_ACCOUNT_STATUS.INACTIVE && (<Fragment><span className="deactivate_user ">{t('(Deactivated)')}</span></Fragment>)}
                                    </div>
                                    <div className="font-inter-400 text-ellipsis line-height-20 color-7f7f7f font-size-13">
                                        {item.email}
                                    </div>
                                </div>
                            </td>
                            <td width={130} className="hide-760">
                                <span className="font-inter-400 font-size-14 color-000">{item.team.title}</span>
                            </td>
                            <td width={100} className="hide-760">
                                <span className="font-inter-400 font-size-14 color-000">{this.getRoleLibelle()}</span>
                            </td>
                            <td width={30}>
                                <RoundSelect lists={roundSelectList} disableHover={this.props.hideRoundHover} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withTranslation()(SettingsUserListItem);