import "../unconnected.css";
import React from "react";
import { globalConstants } from "../../../app/constants";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { ADD_TOKEN_TO_ROUTE, ROUTES } from "../../../app/routing";
import HttpHelper from "../../../app/helpers/HttpHelper";
import LoadingView from "../../loading/LoadingView";

import { userService } from "../../../app/services/user.service";

class RegisterInvitationView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorToken: false,
    };

    this.fetchInitial = this.fetchInitial.bind(this);
  }

  componentDidMount() {
    this._ismounted = true;
    this.fetchInitial();
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  fetchInitial() {
    if (this._ismounted) {
      this.setState({ inlineLoading: true });
    }
    const urlParameters = HttpHelper.getUrlParameters(
      this.props.location.search
    );
    const token = urlParameters.token || null;
    if (!token) {
      this.props.history.push(ROUTES.OOPS);
    }
    userService.checkTokenInvitation(
      token,
      (response) => {
        userService.logout();

        this.props.history.push(
          HttpHelper.addParametersToUrl(ROUTES.LOGIN, {
            step: "SIGNUP",
            token: token,
          }),
          token
        );
      },
      (error) => {
        this.props.history.push(ROUTES.OOPS);
      }
    );
  }

  render() {
    const { t } = this.props;
    let page_title = `${t("Invitation")} - ${globalConstants.WEBSITE_TITLE}`;
    return <LoadingView title={page_title} />;
  }
}

function mapStateToProps(state) {
  return {};
}

export default withTranslation()(
  connect(mapStateToProps)(RegisterInvitationView)
);
