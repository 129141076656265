import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";

import { globalConstants } from '../../app/constants';
import Header from "../../components/header/Header";

import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import './models.css';

import Footer from '../../components/footer/Footer';
import Loader from '../../components/loader/Loader';
import HttpHelper from '../../app/helpers/HttpHelper';
import UserHelper from '../../app/helpers/UserHelper';
import { modelService } from '../../app/services/model.service';
import SelectField from '../../components/form/field/SelectField';
import ModelsMenu from './elements/ModelsMenu';
import { ROUTES } from '../../app/routing';
import ModelsLoadingView from '../loading/ModelsLoadingView';
import { Redirect } from 'react-router-dom';

import { Checkbox } from "@mui/material";

class ModelsAnalyseView extends React.Component {
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      isLoaded: false,
      accounts: [],
      default_account_value: {
        label: (
          <div>
            <span className="font-weight-500">{t("All accounts")}</span>{" "}
            <span className="font-size-12">
              ({t("This may take a lot of time")})
            </span>
          </div>
        ),
        value: -1,
        target: {
          name: "target_account",
          value: -1,
        },
      },
      hasNoAccess: false,
      trainingTaskId: null,
      submitLoading: false,
      analyseFormData: {
        target_account: -1,
        reload_data: false,
      },
      analyseLoading: false,
      analyseFormError: null,
      analyseResult: null,
    };
    this.fetchInitial = this.fetchInitial.bind(this);
    this.isDisabled = this.isDisabled.bind(this);

    this.getAccountsOptions = this.getAccountsOptions.bind(this);

    this.handleChangeAnalyse = this.handleChangeAnalyse.bind(this);
    this.onLaunchAnalyse = this.onLaunchAnalyse.bind(this);

    this.handleChangeReload = this.handleChangeReload.bind(this);
  }

  componentDidMount() {
    this._ismounted = true;
    this.fetchInitial();
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  fetchInitial() {
    if (UserHelper.userIsCorellis()) {
      modelService.getInfosModel(
        { model_type: "analyse" },
        (response) => {
          this.setState({
            hasNoAccess: false,
            isLoaded: true,
            accounts: response.accounts,
            submitLoading: false,
          });
        },
        (error) => {
          if (!this._ismounted) {
            return;
          }
          this.setState({
            isLoaded: true,
            submitLoading: false,
          });
          this.props.addToast(HttpHelper.getErrorMessage(error), {
            type: "error",
            autoDismiss: true,
          });
        }
      );
    } else {
      this.setState({
        isLoaded: true,
        hasNoAccess: true,
      });
    }
  }

  handleChangeReload() {
    let formData = Object.assign({}, this.state.analyseFormData);
    formData.reload_data = !this.state.analyseFormData.reload_data;
    this.setState({
      analyseFormData: formData,
    });
  }

  isDisabled() {
    return this.state.submitLoading || this.state.trainingTaskId;
  }

  handleChangeAnalyse(event) {
    let name = event.target.name;
    let newValue = event.target.value;
    let formData = Object.assign({}, this.state.analyseFormData);
    formData[name] = newValue;
    this.setState({
      analyseFormData: formData,
    });
  }

  getAccountsOptions() {
    const accounts = this.state.accounts;
    const defaultValue = Object.assign({}, this.state.default_account_value);
    let accountOptions = [];
    accountOptions.push(defaultValue);
    accounts.forEach((element) => {
      const label = (
        <div>
          <span className="font-weight-500">{element.account_name}</span>{" "}
          <span className="font-size-12">
            ({(element.id + "").padStart(7, "0")})
          </span>
        </div>
      );
      accountOptions.push({
        label: label,
        value: element.id,
        target: {
          name: "target_account",
          value: element.id,
        },
      });
    });
    return accountOptions;
  }

  onLaunchAnalyse(e) {
    e.preventDefault();
    let formData = this.state.analyseFormData;
    this.setState({
      analyseLoading: true,
      analyseFormError: null,
      analyseResult: null,
    });
    if (formData) {
      modelService.launchAnalyse(
        formData,
        (response) => {
          this.setState({
            analyseResult: response.result,
            analyseLoading: false,
          });
        },
        (error) => {
          if (!this._ismounted) {
            return;
          }
          this.setState({
            analyseResult: null,
            analyseLoading: false,
          });
          this.props.addToast(HttpHelper.getErrorMessage(error), {
            type: "error",
            autoDismiss: true,
          });
        }
      );
    }
  }

  render() {
    const { t } = this.props;
    let page_title = `${t("Models")} - ${globalConstants.WEBSITE_TITLE}`;
    const isDisabled = this.isDisabled();

    if (this.state.isLoaded) {
      if (this.state.hasNoAccess) {
        return <Redirect to={ROUTES.MODELS_SCORE} />;
      }
      return (
        <Fragment>
          <Header isModels={true} />
          <Helmet>
            <title>{page_title}</title>
          </Helmet>
          <div className="padding-top-30" />
          <div
            className="central-content settings-content responsive"
            key={this.state.formKey}
          >
            <div className="left">
              <ModelsMenu
                current={ROUTES.MODELS_ANALYSE}
                history={this.props.history}
              />
            </div>
            <div className="right">
              <div className="padding-top-32" />
              <div className="font-inter-700 font-size-28 color-000 padding-bottom-40 responsive-hide-1000">
                {t("Analyse & Other")}
              </div>
              {!isDisabled && (
                <Fragment>
                  <div className="font-size-24 font-inter-700 color-000">
                    {t("Launch Analyse")}
                  </div>
                  <div className="padding-top-20">
                    <SelectField
                      label={t("Choose an account")}
                      name="target_account"
                      disabled={this.state.analyseLoading}
                      isSearchable={false}
                      options={this.getAccountsOptions()}
                      defaultValue={this.state.default_account_value}
                      onChange={this.handleChangeAnalyse}
                    />
                    <div className="checkbox margin-top-25">
                      <Checkbox
                        className="typology-checkbox"
                        id="keep_header"
                        type="checkbox"
                        onClick={this.handleChangeReload}
                        checked={this.state.analyseFormData.reload_data}
                        sx={{
                          color: "#b4b4b4",
                          "&$checked": {
                            color: "#3a79e6",
                          },
                          "&:hover": {
                            backgroundColor: "transparent !important",
                          },
                        }}
                      />
                      <div>{t("Reload data source")}</div>
                    </div>
                    {this.state.analyseFormError && (
                      <div className="color-e04f77 font-size-15 padding-top-4">
                        {this.state.analyseFormError}
                      </div>
                    )}
                  </div>
                  <div className="overflow-hidden">
                    {this.state.analyseResult !== null && (
                      <div
                        className="float-left padding-top-5 font-inter-500 font-size-15 color-27c986"
                        style={{ maxWidth: "calc(100% - 150px)" }}
                      >
                        {this.state.analyseResult}
                      </div>
                    )}
                    {!this.state.analyseLoading && (
                      <div className="float-right">
                        <button
                          onClick={this.onLaunchAnalyse}
                          className="button"
                        >
                          {t("Launch analyse")}
                        </button>
                      </div>
                    )}
                    {this.state.analyseLoading && (
                      <div className="float-right" style={{ padding: "2px 0" }}>
                        <Loader />
                      </div>
                    )}
                  </div>
                </Fragment>
              )}
            </div>
          </div>
          <Footer />
        </Fragment>
      );
    }
    return (
      <ModelsLoadingView
        title={page_title}
        withHeader={true}
        rightTitle={t("Analyse & Other")}
        settingsMenuProps={{
          current: ROUTES.MODELS_ANALYSE,
          history: this.props.history,
        }}
      />
    );
  }
}

function mapStateToProps(state) {
    return {};
}

export default withTranslation()(connect(mapStateToProps)(ModelsAnalyseView));