import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";
import './testGraphComponents.css';
import Header from '../../components/header/Header';
import LineAreaGraphComponents from '../../components/lineAreaGraphComponents/lineAreaGraphComponents';
import ParentSize from '@visx/responsive/lib/components/ParentSize'
import RadarChart from '../../components/radarGraphComponents/radarGraphComponents';

class testGraphComponents extends React.Component {

    render() {

        const data = {
            "data": [
                { "industry_avg": 3, "avg_score": 1, "date": "2022-06-01 08:58:02.106804" },
                { "industry_avg": 3, "avg_score": 2.5, "date": "2022-06-02 08:58:02.106804" },
                { "industry_avg": 3, "avg_score": 1.75, "date": "2022-06-03 08:58:02.106804" },
                { "industry_avg": 3, "avg_score": 2, "date": "2022-06-04 08:58:02.106804" },
                { "industry_avg": 3, "avg_score": 2.75, "date": "2022-06-05 08:58:02.106804" },
                { "industry_avg": 3, "avg_score": 2.5, "date": "2022-06-06 08:58:02.106804" },
                { "industry_avg": 3, "avg_score": 3.25, "date": "2022-06-07 08:58:02.106804" },
                { "industry_avg": 3, "avg_score": 2, "date": "2022-06-08 08:58:02.106804" },
                { "industry_avg": 3, "avg_score": 0, "date": "2022-06-09 08:58:02.106804" },
                { "industry_avg": 3, "avg_score": -2, "date": "2022-06-10 08:58:02.106804" },
                { "industry_avg": 3, "avg_score": -1.75, "date": "2022-06-11 08:58:02.106804" },
                { "industry_avg": 3, "avg_score": -0.75, "date": "2022-06-12 08:58:02.106804" },
                { "industry_avg": 3, "avg_score": 0, "date": "2022-06-13 08:58:02.106804" },
                { "industry_avg": 3, "avg_score": 1, "date": "2022-06-14 08:58:02.106804" },
                { "industry_avg": 3, "avg_score": 1.25, "date": "2022-06-15 08:58:02.106804" },
            ],
            "status": "success"
        }

        const dataBis = {
            "data": [
                { "name": 'Ingredient Performance', "score": 1.25, "industry": 0.5, "date": "2022-06-15 08:58:02.106804"},
                { "name": 'Sensory Attributes', "score": 0.75, "industry": 2, "date": "2022-06-15 08:58:02.106804"},
                { "name": 'Purchase Intention', "score": 3, "industry": 0.5, "date": "2022-06-15 08:58:02.106804"},
                { "name": 'Consumer Outcome', "score": 1.5, "industry": 2, "date": "2022-06-15 08:58:02.106804"},
                { "name": 'Product Experience', "score": 1, "industry": 0.5, "date": "2022-06-15 08:58:02.106804"},
                { "name": 'Packaging', "score": 0.25, "industry": 2, "date": "2022-06-15 08:58:02.106804" }
            ],
            "status": "success"
        }

        let test = 0

        // ! test only
        data.data.forEach(elm => {
            test += elm["avg_score"]
        });
        // ! ----------
        

        data.data.forEach(elm => {
            let random = test / data.data.length
            elm["industry_avg"] = random
        });

        dataBis.data.forEach(elm => {
            test += elm["avg_score"]
        });

        dataBis.data.forEach(elm => {
            let random = test / dataBis.data.length
            elm["industry_avg"] = random
        });
        // ! ----------

        const width = 540
        const height = 300

        return (
            <Fragment>
                <Header isProject={false} />
                <Helmet>
                    <title>{"TEST ONLY"}</title>
                </Helmet>

                <div id='test-zone'>
                    <h1>Test zone - stagging only - do not push on prod / zone de test - stagging seulement - ne pas pousser en prod</h1>
                    <div id="test-area">
                        <LineAreaGraphComponents legend={{ x: "Projects", aux_x: "Industry average" }} name={'History'} data={data.data} width={width} height={height} />
                        <LineAreaGraphComponents legend={{ x: "Projects", aux_x: "Industry average" }} name={'Story'} data={data.data} width={width} height={height} />
                        <RadarChart legend={{ x: "Projects", aux_x: "Industry average" }} name={'KEY PRODUCT METRICS'} data={dataBis.data} width={width} height={height} />
                    </div>
                </div>
            </Fragment >
        )
    }
}

export default testGraphComponents;