import React from 'react';
import { withTranslation } from 'react-i18next';
import StringHelper from '../../../app/helpers/StringHelper';
import '../form.css';


class PasswordField extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            type: props.defaultType,
            password: null
        };
        this.onChange = this.onChange.bind(this);
        this.renderErrorMessage = this.renderErrorMessage.bind(this);
        this.renderForceHelper = this.renderForceHelper.bind(this);
        this.switchType = this.switchType.bind(this);
    }

    switchType() {
        const newType = (this.state.type === 'password') ? 'text' : 'password';
        this.setState({
            type: newType
        });
    }

    onChange(event) {
        this.setState({
            password: event.target.value
        });
        this.props.onChange(event);
    }

    renderErrorMessage(errorMessage) {
        if (errorMessage) {
            return (
                <div className="form-error font-work-sans-400 color-fd3e7b font-size-12 text-align-right">
                    {errorMessage}
                </div>
            );
        }
        return null;
    }

    renderHelper(checked, name, libelle) {
        let labelClassNames = ['font-inter-400 font-size-15 padding-left-10 display-inline-block vertical-align-middle'];
        if(checked){
            labelClassNames.push('color-000');
        }else{
            labelClassNames.push('color-7f7f7f');
        }
        return (
            <div className="position-relative padding-bottom-10">
                <div className="content-checkbox size-16">
                    <input type="checkbox" id={name} value={name} name={name} checked={checked} disabled={true} />
                    <label className="fake-checkbox" htmlFor={name} />
                </div>
                <label htmlFor={name} className={labelClassNames.join(' ')}>{libelle}</label>
            </div>
        );
    }
    renderForceHelper() {
        const { t } = this.props;
        const checks = StringHelper.getPasswordChecks(this.state.password);

        return (
            <div className="content-password-helper">
                {this.renderHelper(checks.size, 'password_size', t('8 characters minimum'))}
                {this.renderHelper(checks.lowercase, 'password_lowercase', t('A lowercase character'))}
                {this.renderHelper(checks.uppercase, 'password_uppercase', t('An uppercase character'))}
                {this.renderHelper(checks.symbol, 'password_symbol', t('A number, symbol or space'))}
            </div>
        );
    }

    render() {
        let formElementClassName = ["form-element", 'password-field'];
        if (this.props.errorMessage) {
            formElementClassName.push('error');
        }
        if (this.props.formElementClassName) {
            formElementClassName.push(this.props.formElementClassName);
        }
        let buttonClassnames = ['button-password-switch-type', 'transition-opacity'];
        if (this.state.type === 'text') {
            buttonClassnames.push('visible');
        }
        return (
            <div className={formElementClassName.join(' ')}>
                {!this.props.hideLabel && (
                    <label className="font-inter-600 font-size-15 color-969696">
                        {this.props.label}
                    </label>
                )}
                <div className="position-relative">
                    <input
                        className={this.props.className}
                        style={this.props.style}
                        type={this.state.type}
                        id={this.props.id}
                        disabled={this.props.disabled}
                        readOnly={this.props.readonly}
                        required={this.props.required}
                        placeholder={this.props.placeholder}
                        name={this.props.name}
                        value={this.props.value}
                        defaultValue={this.props.defaultValue}
                        onChange={this.onChange}
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        autoComplete={this.props.autoComplete}
                    />
                    <div className={buttonClassnames.join(' ')} onClick={this.switchType} />
                </div>
                {this.props.withForceHelper && (
                    this.renderForceHelper()
                )}
                {(!this.props.withForceHelper || StringHelper.isPasswordValid(this.state.password)) && this.renderErrorMessage(this.props.errorMessage)}
            </div>
        );
    }
}

PasswordField.defaultProps = {
    defaultType: 'password',
    autoComplete: 'nope'
}

export default withTranslation()(PasswordField);