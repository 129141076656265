class MediaHelper { }

// ! a mettre a jour !
MediaHelper.type = {
    YOUTUBE: 'YOUTUBE',
    TXT_FILE: 'TXT_FILE',
    WEBSITE_URL: 'WEBSITE_URL',
    AMAZON_URL: 'AMAZON_URL',
    VIDEO_FILE: 'VIDEO_FILE',
    SHEET_FILE: 'SHEET_FILE',
}

MediaHelper.test = {
    CONSUMER: 'Consumer',
    LAB_EXPERT: 'Lab expert',
}

MediaHelper.service = {
    Youtube: 'Youtube',
    Zoom: 'Zoom',
    Vimeo: 'Vimeo',
    Dailymotion: 'Dailymotion',
    Twitch: 'Twitch',
    Wistia: 'Wistia',
    Amazon: 'Amazon',
    Walmart: 'Walmart',
    Sephora: 'Sephora',
    Nocibe: 'Nocibe',
    Flipkart: 'Flipkart',
    CSV: 'CSV',
    XLSX: 'XLSX',
    XLS: 'XLS',
    TXT: 'TXT',
}

MediaHelper.formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export default MediaHelper;