import React, { Fragment } from "react";
import { Helmet } from "react-helmet";

import { debounce } from "lodash";
import { globalConstants } from "../../app/constants";
import LoadingView from "../loading/LoadingView";
import Header from "../../components/header/Header";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import Footer from "../../components/footer/Footer";
import Link from "../../components/Link";
import { projectService } from "../../app/services/project.service";
import Loader from "../../components/loader/Loader";
import { PROJECT_DETAIL, PROJECT_REPORT } from "../../app/routing";
import HttpHelper from "../../app/helpers/HttpHelper";
import LimitedTextareaField from "../../components/form/field/LimitedTextareaField";
import SuggestionsList from "./elements/SuggestionsList";
import AjaxKeywordTooltipContent from "../../components/tooltip/AjaxKeywordTooltipContent";
import UpdateKeywordClassModal from "../../components/modal/UpdateKeywordClassModal";
import Tooltip from "../../components/tooltip/Tooltip";
import ColoredScore from "../../components/ui/ColoredScore";
import ScoreTooltipContent from "../../components/tooltip/ScoreTooltipContent";
import ReportHelper from "../../app/helpers/ReportHelper";
import backArrow from "../../res/images/icons/icon-left.svg";

import "./RefineProjectView.css";

const SUGGESTION_PAGE_SIZE = 3;
class RefineProjectView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectId: props.match.params.id,
      is_loaded: false,
      inline_loading: false,
      isModified: false,
      formData: {
        main_characteristics: "",
      },
      original_main_characteristics: "",
      estimated_score: null,
      estimated_score_report: null,
      estimated_score_loading: false,
      project: null,
      projectStatistics: null,
      value: "",
      submitLoading: false,
      classesList: [],
      otherClassesList: [],
      delete: {
        display: false,
        item: null,
        loading: false,
        error: null,
      },
      suggestions: {
        keywords: [],
        meta: {
          page: 1,
          page_size: SUGGESTION_PAGE_SIZE,
          loading: false,
          inner_loading: false,
          stop: false,
        },
      },
      update_keyword_class: {
        display: false,
        keyword: null,
      },
    };
    this.fetchProject = this.fetchProject.bind(this);
    this.fetchProjectStatistics = this.fetchProjectStatistics.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onSeeMoreSuggestions = this.onSeeMoreSuggestions.bind(this);
    this.getKeywordTooltipContent = this.getKeywordTooltipContent.bind(this);
    this.estimateScore = debounce(this._estimateScore.bind(this), 700);
    this.getProductScore = this.getProductScore.bind(this);
    this.getDisplayedProductScore = this.getDisplayedProductScore.bind(this);
    this.getProductScoreTooltipContent =
      this.getProductScoreTooltipContent.bind(this);

    this.onOpenUpdateKeywordClassModal =
      this.onOpenUpdateKeywordClassModal.bind(this);
    this.onCloseUpdateKeywordClassModal =
      this.onCloseUpdateKeywordClassModal.bind(this);
    this.onSucessUpdateKeywordClassModal =
      this.onSucessUpdateKeywordClassModal.bind(this);
  }

  componentDidMount() {
    this._ismounted = true;
    this.fetchProject();
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  fetchProject() {
    let project_id = this.state.projectId;
    projectService.get.get(
      project_id,
      (response) => {
        let newFormData = Object.assign({}, this.state.formData);
        newFormData.main_characteristics = response.main_characteristics;
        this.setState({
          original_main_characteristics: newFormData.main_characteristics,
          project: response,
          formData: newFormData,
          is_loaded: true,
        });
        this.fetchProjectStatistics();
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      }
    );
  }

  async fetchProjectStatistics() {
    await projectService.get.getStatistics(
      this.state.projectId,
      (response) => {
        this.setState({
          projectStatistics: response.statistics,
        });
      },
      (error) => {
        this.displayToastErrorMessage(error);
      }
    );
  }

  onSeeMoreSuggestions() {
    let suggestionsMetas = Object.assign({}, this.state.suggestions.meta);
    suggestionsMetas.page += 1;
  }

  onSubmitEdit = (e) => {
    e.preventDefault();
    let formData = Object.assign({}, this.state.formData);
    if (formData) {
      this.setState({ submitLoading: true });
      projectService.put.update_main_characteristics(
        this.state.project.id,
        formData,
        (response) => {
          setTimeout(() => {
            this.props.addToast("Story matching successfully updated", {
              type: "success",
              autoDismiss: true,
            });
          }, 800);
          this.props.history.push(PROJECT_DETAIL(this.state.project.id));
        },
        (error) => {
          if (!this._ismounted) {
            return;
          }
          this.setState({ submitLoading: false });
          this.props.addToast(HttpHelper.getErrorMessage(error), {
            type: "error",
            autoDismiss: true,
          });
        }
      );
    }
  };

  onInputChange(newValue) {
    const newFormData = {
      main_characteristics: newValue,
    };
    this.setState({
      isModified:
        newValue !== this.state.original_main_characteristics &&
        newValue.length > 0,
      formData: newFormData,
    });
    //this.estimateScore(newFormData);
  }

  _estimateScore(formData) {
    formData.suggestion_page_size = SUGGESTION_PAGE_SIZE;
    let suggestions = Object.assign({}, this.state.suggestions);
    suggestions.keywords = [];
    suggestions.meta.page = 1;
    suggestions.meta.inner_loading = true;
    this.setState({
      estimated_score: null,
      estimated_score_report: null,
      estimated_score_loading: true,
      suggestions: suggestions,
    });
    projectService.post.estimate_score(
      this.state.project.id,
      formData,
      (response) => {
        let newSuggestions = Object.assign({}, this.state.suggestions);
        newSuggestions.keywords = response.suggestion_keywords;
        newSuggestions.meta.inner_loading = false;
        this.setState({
          estimated_score: response.estimated_score,
          estimated_score_report: response.estimated_report,
          estimated_score_loading: false,
          suggestions: newSuggestions,
        });
      },
      (error) => {
        this.setState({ estimated_score_loading: false });
      }
    );
  }

  getProductScoreTooltipContent() {
    const { t } = this.props;
    const productScore = this.getProductScore();
    const statisticsReport = this.state.projectStatistics
      ? this.state.projectStatistics.report
      : [];
    const report = this.state.estimated_score_report
      ? this.state.estimated_score_report
      : statisticsReport;
    const nbKeywords = ReportHelper.getProjectNbKeywordsByReport(report);
    let item = null;
    if (productScore) {
      item = {
        value: t("Average score"),
        occurence: nbKeywords,
        score: productScore,
        isGlobalScore: true,
      };
    }
    return (
      <div className="base-tooltip">
        <ScoreTooltipContent item={item} />
      </div>
    );
  }

  getProductScore() {
    if (this.state.estimated_score_loading) {
      return null;
    }
    let score = this.state.projectStatistics
      ? this.state.projectStatistics.product_score
      : null;
    if (this.state.estimated_score) {
      score = this.state.estimated_score;
    }
    return score;
  }

  getDisplayedProductScore() {
    const productScore = this.getProductScore();
    if (!productScore) {
      return <span className="color-3a79e6">-</span>;
    }
    return (
      <Tooltip
        content={this.getProductScoreTooltipContent()}
        placement="bottom"
      >
        <ColoredScore score={productScore} />
      </Tooltip>
    );
  }

  getKeywordTooltipContent(keyword) {
    const project = this.state.project;
    if (!project || !this.state.projectStatistics) {
      return null;
    }
    const report = this.state.projectStatistics.report;
    if (!report || (report && report.length === 0)) {
      return null;
    }
    return (
      <div className="base-tooltip">
        <AjaxKeywordTooltipContent
          projectId={this.state.projectId}
          datasourceId={this.state.datasourceId}
          keyword={keyword}
          onOpenUpdateKeywordClassModal={this.onOpenUpdateKeywordClassModal}
        />
      </div>
    );
  }

  onOpenUpdateKeywordClassModal(aKeyword) {
    this.setState({
      update_keyword_class: {
        display: true,
        keyword: aKeyword,
      },
    });
  }

  onCloseUpdateKeywordClassModal() {
    this.setState({
      update_keyword_class: {
        display: false,
        keyword: null,
      },
    });
  }

  onSucessUpdateKeywordClassModal(response) {
    this.onCloseUpdateKeywordClassModal();
  }

  render() {
    const { t } = this.props;
    let page_title = `${t("Edit story")} - ${globalConstants.WEBSITE_TITLE}`;
    const project = this.state.project;
    if (this.state.is_loaded) {
      return (
        <Fragment>
          <Header isProject={true} />
          <Helmet>
            <title>{page_title}</title>
          </Helmet>
          <div className="padding-top-60 padding-bottom-20 responsive-920-padding-top-20">
            <div className="central-content">
              {project && (
                <div className="padding-bottom-16">
                  <div
                    onClick={this.props.history.goBack}
                    className="font-size-15 font-weight-500 link-grey transition-color text-break-line display-flex align-items-center gap-12"
                  >
                    <img src={backArrow} alt="" /> {t("Back")}
                  </div>
                </div>
              )}
              <div className="font-size-30 font-inter-700 color-000 padding-bottom-40">
                {project.name}
              </div>
              <div className="font-size-15 font-inter-400 color-757575 padding-bottom-36 refine-subtitle column-percent-75-no-float padding-right-30">
                {t(
                  "After some lab testing, you may want to refine this description to an actual product claim that you want to test against with your consumers. When phrasing your claims, try to be specific about your product's benefit, particularly sustainability, so that no confusion will arise among consumers."
                )}
              </div>
              <form className="position-relative">
                <p id="textareaTitle">{t("Edit story")}</p>
                <div className="content-column display-flex project-refine-content-top-column">
                  <div className="column-percent-75">
                    <div className="padding-right-30">
                      <LimitedTextareaField
                        defaultValue={this.state.formData.main_characteristics}
                        onChange={this.onInputChange}
                        placeholder={t(
                          "Describe your product hypotheses in terms of value proposition, consumer traction and business potential."
                        )}
                        className="width-percent-100 min-height-300 color-212121"
                        formElementClassName="width-percent-100"
                        disabled={this.state.submitLoading}
                        limit={null}
                      />
                    </div>
                  </div>
                </div>
                <div className="padding-bottom-36" />
                <div
                  className="overflow-hidden"
                  style={{
                    width: "100%",
                    maxWidth: "825px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {!this.state.submitLoading && (
                    <div style={{ marginRight: "18px" }}>
                      <button
                        type="button"
                        className="button backgroundless"
                        style={{ color: "#6d9eee", fontSize: "15px" }}
                        onClick={this.props.history.goBack}
                      >
                        {t("Cancel")}
                      </button>
                      <button
                        disabled={!this.state.isModified}
                        onClick={this.onSubmitEdit}
                        className="margin-left-5 button backgroundless font-weight-600"
                        style={{ fontSize: "15px" }}
                      >
                        {t("Save")}
                      </button>
                    </div>
                  )}
                  {this.state.submitLoading && (
                    <div className="float-right" style={{ padding: "2px 0" }}>
                      <Loader />
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
          <Footer />
          {this.state.update_keyword_class.display && (
            <UpdateKeywordClassModal
              projectId={this.state.projectId}
              project={this.state.project}
              keyword={this.state.update_keyword_class.keyword}
              onClose={this.onCloseUpdateKeywordClassModal}
              onSuccess={this.onSucessUpdateKeywordClassModal}
              parentProps={this.props}
            />
          )}
        </Fragment>
      );
    }
    return (
      <LoadingView
        title={page_title}
        withHeader={true}
        headerProps={{ isProject: true }}
      />
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withTranslation()(connect(mapStateToProps)(RefineProjectView));
