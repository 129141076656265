
export const SessionStorageService = {};

SessionStorageService.get = (key) => {
  if (typeof (sessionStorage) !== 'undefined') {
    return sessionStorage.getItem(key);
  }
  return null;
}

SessionStorageService.getJson = (key) => {
  if (typeof (sessionStorage) !== 'undefined') {
    return JSON.parse(sessionStorage.getItem(key));
  }
  return null;
}

SessionStorageService.set = (key, value) => {
  if (typeof (sessionStorage) !== 'undefined') {
    return sessionStorage.setItem(key, value);
  }
  return null;
}
SessionStorageService.setJson = (key, value) => {
  if (typeof (sessionStorage) !== 'undefined') {
    return sessionStorage.setItem(key, JSON.stringify(value));
  }
  return null;
}
SessionStorageService.delete = (key) => {
  if (typeof (sessionStorage) !== 'undefined') {
    return sessionStorage.removeItem(key);
  }
  return null;
}


export const LocalStorageService = {};

LocalStorageService.get = (key) => {
  if (typeof (localStorage) !== 'undefined') {
    return localStorage.getItem(key);
  }
  return null;
}

LocalStorageService.getJson = (key) => {
  if (typeof (localStorage) !== 'undefined') {
    if (
      localStorage.getItem(key) == undefined ||
      localStorage.getItem(key) == "undefined"
    ) {
      localStorage.removeItem(key);
      return null;
    }

    return JSON.parse(localStorage.getItem(key));
  }
  return null;
}

LocalStorageService.set = (key, value) => {
  if (typeof (localStorage) !== 'undefined') {
    return localStorage.setItem(key, value);
  }
  return null;
}

LocalStorageService.setJson = (key, value) => {
  if (typeof (localStorage) !== 'undefined') {
    return localStorage.setItem(key, JSON.stringify(value));
  }
  return null;
}

LocalStorageService.delete = (key) => {
  if (typeof (localStorage) !== 'undefined') {
    return localStorage.removeItem(key);
  }
  return null;
}

LocalStorageService.getAll = () => {
  if (typeof (localStorage) !== 'undefined') {
    return Object.entries(localStorage);
  }
  return [];
}

LocalStorageService.deleteAllStartWithExcept = (start, exception) => {
  if (typeof (localStorage) !== 'undefined') {
    const all = LocalStorageService.getAll();
    all.forEach(element => {
      if (element[0] != exception && element[0].startsWith(start)) {
        LocalStorageService.delete(element[0]);
      }
    });
  }
  return null;
}