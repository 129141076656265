import React from 'react';
import { withTranslation } from 'react-i18next';
import Loader from '../loader/Loader';
import Modal from './Modal';


class ReloadMediaModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue: ''
        };
        this.modalRef = React.createRef();
        this.onCancelClick = this.onCancelClick.bind(this);
    }

    onCancelClick() {
        if (this.modalRef && this.modalRef.current) {
            this.modalRef.current.onModalCloseClick();
        }
    }

    render() {
        const { t } = this.props;
        return (
            <Modal ref={this.modalRef} display={this.props.display} onClose={this.props.onClose}>
                <div className="font-size-30 font-inter-700 color-000 padding-bottom-40">{t('Update')}</div>
                <div className="font-size-20 font-inter-700 color-000 padding-bottom-20">{t('You are about to update the data, please note that any manual observation changes will be lost.')}</div>
                {!!this.props.error && <div className="color-e04f77 font-size-15 line-height-34"><span className="display-inline-block line-height-1-2">{this.props.error}</span></div>}
                <form>
                    <div className="overflow-hidden">
                        {!this.props.loading &&
                            <div className="float-right">
                                <button className="button backgroundless" type="button" onClick={this.onCancelClick}>{t('Cancel')}</button>
                                <button className="button backgroundless margin-left-5" onClick={this.props.onReloadItem}>{t('Update')}</button>
                            </div>
                        }
                        {this.props.loading &&
                            <div className="float-right" style={{ padding: "2px 0" }}>
                                <Loader />
                            </div>
                        }
                    </div>
                </form>
            </Modal>
        );
    }
}

export default withTranslation()(ReloadMediaModal);