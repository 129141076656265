import React from "react";
import { withTranslation } from "react-i18next";
import { ACCOUNT_PLAN, globalConstants } from "../../../app/constants";
import { ROUTES } from "../../../app/routing";
import Link from "../../../components/Link";
import Modal from "../../../components/modal/Modal";
import imgIllustration from "../../../res/images/illustrations/illus-upgrade-add-class.png";
class UpgradePlanToAddClassModal extends React.Component {
  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
    this.onCancelClick = this.onCancelClick.bind(this);
    this.getDescription = this.getDescription.bind(this);
    this.onClickContactSales = this.onClickContactSales.bind(this);
  }

  onCancelClick() {
    if (this.modalRef && this.modalRef.current) {
      this.modalRef.current.onModalCloseClick();
    }
  }

  getDescription() {
    const { t, currentPlan } = this.props;
    switch (currentPlan) {
      case ACCOUNT_PLAN.PRO:
        return t(
          "You are on the Pro plan and can create up to 1 custom class. Please upgrade your plan first to create more classes."
        );
      case ACCOUNT_PLAN.BUSINESS:
        return t(
          "You are on the Business plan and can create up to 4 custom classes. Please upgrade your plan first to create more classes."
        );
      default:
        return t(
          "You are on the Free Plan and can create up to 0 custom class. Please upgrade your plan first to create more classes."
        );
    }
  }

  onClickContactSales() {
    window.open(globalConstants.SALES_URL, "_blank", "noreferrer");
  }

  render() {
    const { t, currentPlan } = this.props;
    return (
      <Modal
        ref={this.modalRef}
        display={this.props.display}
        onClose={this.props.onClose}
      >
        <div
          className="margin-auto margin-top-20"
          style={{ maxWidth: "426px" }}
        >
          <img alt="" src={imgIllustration} className="img-100" />
        </div>
        <div className="text-align-center padding-top-40 font-inter-700 color-000 font-size-24">
          {t("Upgrade to create more classes")}
        </div>
        <div
          className="text-align-center padding-top-24 font-inter-500 color-7f7f7f font-size-18 line-height-1-4"
          style={{ letterSpacing: "-0.2px" }}
        >
          {this.getDescription()}
        </div>
        <div className="text-align-center padding-top-24">
          <a
            className="font-inter-500 link-blue font-size-18 line-height-1-4 color-3a79e6"
            rel="noreferrer"
            target={"_blank"}
            href={ROUTES.PLAN_CHOOSE}
          >
            {t("See what you get when you upgrade")}
          </a>
        </div>
        <div className="text-align-center padding-top-40">
          <button
            className="button light-blue margin-right-20 responsive-370-margin-right-0 width-138 font-weight-500 margin-bottom-4 color-3a79e6"
            onClick={this.onCancelClick}
          >
            {t("Maybe later")}
          </button>
          {currentPlan !== ACCOUNT_PLAN.BUSINESS && (
            <Link
              className="button blue width-138 font-weight-500 margin-bottom-4"
              to={ROUTES.PLAN_CHOOSE}
            >
              {t("Upgrade")}
            </Link>
          )}
          {currentPlan === ACCOUNT_PLAN.BUSINESS && (
            <button
              className="button blue width-138 font-weight-500 margin-bottom-4"
              onClick={this.onClickContactSales}
            >
              {t("Contact sales")}
            </button>
          )}
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(UpgradePlanToAddClassModal);
