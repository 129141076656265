
import React from 'react';
import './tooltip.css';
import { withTranslation } from "react-i18next";
import LoadingTooltipContent from './LoadingTooltipContent';
import KeywordTooltipContent from './KeywordTooltipContent';
import { keywordService } from '../../app/services/keyword.service';
import HttpHelper from '../../app/helpers/HttpHelper';
import ErrorTooltipContent from './ErrorTooltipContent';


class AjaxKeywordTooltipContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      error: null,
      item: null,
    };

    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    this._ismounted = true;
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify({ keyword: prevProps.keyword, projectId: prevProps.projectId, datasourceId: prevProps.datasourceId }) != JSON.stringify({ keyword: this.props.keyword, projectId: this.props.projectId, datasourceId: this.props.datasourceId })) {
      this.setState({isLoaded: false})
      this.fetchData(true);
    }
}

  componentWillUnmount() {
    this._ismounted = false;
  }

  fetchData(forceReload) {
    if (this.state.isLoaded && !forceReload) {
      return;
    }
    if (!this._ismounted) {
      return;
    }
    let formData = {};
    if (this.props.projectId) {
      formData.project_id = this.props.projectId;
    }
    if (this.props.datasourceId) {
      formData.datasource_id = this.props.datasourceId;
    }
    keywordService.get.get(
      this.props.keyword.toLowerCase(),
      formData,
      (response) => {
        if (this._ismounted) {
          this.setState({
            item: response.data,
            isLoaded: true
          });
          if(this.props.onIsLoaded){
            setTimeout(() => {
              this.props.onIsLoaded();
            }, 10);
          }
        }
      },
      (error) => {
        if (this._ismounted) {
          this.setState({
            isLoaded: true,
            error: HttpHelper.getErrorMessage(error),
          });
        }
      }
    );
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <LoadingTooltipContent />
      );
    }
    if (this.state.error) {
      return (
        <ErrorTooltipContent error={this.state.error} />
      );
    }
    return (
      <KeywordTooltipContent keyword={this.props.keyword} item={this.state.item} projectId={this.props.projectId} onAfterFollow={this.props.onAfterFollow} onOpenUpdateKeywordClassModal={this.props.onOpenUpdateKeywordClassModal} />
    );
  }
}

export default withTranslation()(AjaxKeywordTooltipContent);