import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { Box, Typography } from "@mui/material";
import upgradeIcon from "./../../res/images/icons/upgrade_icon.svg";

export default function SplitButton({
  title = "Button",
  className,
  options,
  optionIcons,
  optionsNeedUpgrade,
  buttonClick,
  menuItemClick,
}) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleClick = () => {
    if (buttonClick) {
      buttonClick();
    }
  };

  const handleMenuItemClick = (event, index) => {
    setOpen(false);
    if (menuItemClick) {
      menuItemClick(index);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  let optionsNeedUpgradeIndexes = [];

  if (optionsNeedUpgrade != null) {
    optionsNeedUpgradeIndexes = optionsNeedUpgrade;
  }

  return (
    <React.Fragment>
      <ButtonGroup
        className={className}
        variant="contained"
        ref={anchorRef}
        aria-label="Button group"
        sx={{
          borderRadius: "6px",
        }}
      >
        <Button
          sx={{
            width: "max-content",
            backgroundColor: "#306ed6",
            textTransform: "none",
            borderRadius: "6px 0 0 6px",
            p: "0 12px",
            "&:hover": {
              backgroundColor: "#0075ff",
            },
          }}
          onClick={handleClick}
        >
          <Typography
            sx={{
              fontSize: "15px",
              letterSpacing: "normal",
              lineHeight: "34px",
              fontFamily: "Inter",
            }}
          >
            {title}
          </Typography>
        </Button>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="menu selection"
          aria-haspopup="menu"
          onClick={handleToggle}
          sx={{
            backgroundColor: "#306ed6",
            borderRadius: "0 6px 6px 0",
            "&:hover": {
              backgroundColor: "#0075ff",
            },
          }}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
          width: "186px",
          pt: "2px",
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper
              sx={{
                borderRadius: 0,
                boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.3)",
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: "8px",
                          alignItems: "center",
                        }}
                      >
                        {optionIcons && optionIcons[index]}
                        <Typography
                          sx={{
                            fontSize: "100%",
                            fontWeight: 400,
                            lineHeight: "40px",
                            fontFamily: "Inter",
                          }}
                        >
                          {option}
                        </Typography>
                        {optionsNeedUpgradeIndexes.includes(index) && (
                          <img src={upgradeIcon} />
                        )}
                      </Box>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
