import React from 'react'
import { globalConstants } from '../../app/constants';

import './staging-ribbon.css';

export const StagingRibbon = ({ props }) => {
  const environment = globalConstants.ENV;
  if (['preprod', 'dev'].includes(environment)) {
    return (
      <div className="staging-ribbon">
        <span>{environment}</span>
      </div>
    )
  }
  else if (['staging'].includes(environment)) {
    return (
      <div className="staging-ribbon">
        <span>integ</span>
      </div>
    )
  }
  return null;
}


export default StagingRibbon
