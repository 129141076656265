import React, { Fragment } from 'react';
import '../form.css';
import Loader from "../../loader/Loader";




class ButtonField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_focused: false,
        };
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.renderContentButton = this.renderContentButton.bind(this);
    }

    setFocused(focused) {
        this.setState({
            is_focused: focused
        });
    }

    onFocus() {
        this.setFocused(true);
    }
    onBlur() {
        this.setFocused(false);
    }

    renderContentButton() {
        if (this.props.loading) {
            if (this.props.loaderPosition === 'right') {
                return (
                    <Fragment>
                        <span className="content-children">{this.props.children}</span>
                        <Loader className="loader-button" color="#ffffff" />
                    </Fragment>
                )
            }
            if (this.props.loaderPosition === 'left') {
                return (
                    <Fragment>
                        <Loader className="loader-button" color="#ffffff" />
                        <span className="content-children">{this.props.children}</span>
                    </Fragment>
                )
            }
            return <Loader className="loader-button" color="#ffffff" />;
        }
        if (this.props.children) {
            return this.props.children;
        }
        return this.props.value;
    }

    render() {
        let formElementClassName = ["form"];
        if (this.state.is_focused) {
            formElementClassName.push('focused');
        }
        if (this.props.formElementClassName) {
            formElementClassName.push(this.props.formElementClassName);
        }
        let buttonClassName = ['button', 'transition-background-color'];
        if (this.props.className) {
            buttonClassName.push(this.props.className);
        }
        if (this.props.loading) {
            buttonClassName.push('loading');
        }
        buttonClassName.push(`loader-position-${this.props.loaderPosition}`);
        return (
            <div className={formElementClassName.join(' ')} style={this.props.formElementstyle}>
                <button
                    className={buttonClassName.join(' ')}
                    type={this.props.type}
                    disabled={this.props.disabled || this.props.loading}
                    onClick={this.props.onClick}>
                    {this.renderContentButton()}
                </button>
            </div>
        );
    }
}

ButtonField.defaultProps = {
    loaderPosition: 'center'
}

export default ButtonField;