import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Line } from "react-chartjs-2";
import { backofficeService } from "../../app/services/backoffice.service";
import { withTranslation } from 'react-i18next';
import Loader from "../loader/Loader";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = { 
  responsive: true,
  maintainAspectRatio: true,
  plugins: {
    legend: {
      position: 'bottom',
      align: 'end'
    },
  }
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

class UserRegistrationChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        labels: [],
        datasets: [
          {
            label: "Number of new registration",
            data: [],
            borderWidth: 1,
            backgroundColor: "rgba(53, 162, 235, 0.5)",
            borderColor: "rgb(53, 162, 235)",
          }
        ]
      },
      nbOfUsers: 0,
      lineChartIsLoaded: false,
    }

    this.fetchLineChart = this.fetchLineChart.bind(this);

  }

  componentDidMount() {
    this.fetchLineChart()
  }

  fetchLineChart() {
    backofficeService.getPlanUserRegister(
      (response) => {
        let chart_data = []
        let labels = []
        for (const [key, value] of Object.entries(response.data)) {
          chart_data.push(value.nb_user)
          labels.push(value.month)
        }
        this.setState({
          data: {
            labels: labels,
            datasets: [{
              data: chart_data,
              label: "Number of new registration",
              borderWidth: 1,
              borderColor: "rgb(53, 162, 235)",
              backgroundColor: "rgba(53, 162, 235, 0.5)" 
            }]
          },
          nbOfUsers: chart_data.reduce((a, b) => a + b, 0),
          lineChartIsLoaded: true
        })
      },
      (error) => {
        console.log(error);
        this.setState({
          lineChartIsLoaded: true
        })
      }
    )
  }
  render() {
    const { t } = this.props;
    return (
      <div className="chartBox">
      { this.state.lineChartIsLoaded && <div className="usersChartInfos">
        <div>{t("User Registration")}</div>
        <div>{"Total : " + this.state.nbOfUsers}</div>
      </div>}
        { !this.state.lineChartIsLoaded && <Loader />}
        { this.state.lineChartIsLoaded && <Line className="chart" options={options} data={this.state.data} />}
      </div>
    )
  }

}

export default withTranslation()(UserRegistrationChart);