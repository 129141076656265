
import React from 'react';
import { withTranslation } from 'react-i18next';
import Lottie from 'react-lottie-player';
import laptopLottieJson from '../../res/lotties/laptop-open-error.json';


class InvalidTokenInfo extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { t, children, withIcon } = this.props;
        return (
            <div className="invalid-token-info">
                <div className="centered">
                    {withIcon && 
                    <Lottie
                        loop
                        animationData={laptopLottieJson}
                        play
                        style={{ width: 200, height: 180, margin: "0 auto" }}
                    />}
                    <div className="text-align-center padding-top-5 font-size-16 color-7f7f7f font-weight-500">{t('Your link seems to be invalid or has expired')}</div>
                    <div className="padding-top-20">
                        {children}
                    </div>
                </div>
            </div>
        );
    }
}

InvalidTokenInfo.defaultProps = {
    withIcon: true
}

export default withTranslation()(InvalidTokenInfo);