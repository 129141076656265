import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import "../../settings.css"
import HttpHelper from '../../../../app/helpers/HttpHelper';
import { invoiceService } from '../../../../app/services/invoice.service';
import ChangeBillingMailModal from './modal/ChangeBillingMailModal';
import { accountService } from '../../../../app/services/account.service';
import Loader from '../../../../components/loader/Loader';
import moment from 'moment-timezone';
import CollectionHelper from '../../../../app/helpers/CollectionHelper';
import { globalConstants } from "../../../../app/constants";

class InvoiceTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        page: 1,
        per_page: 5,
      },
      edit: {
        display: false,
        item: null,
        loading: false,
        error: null,
      },
      inlineLoading: false,
      isLoaded: false,
      account: props.account,
      displayPricingModal: false,
      invoices_list: [],
      sendEmailLoading: {},
    };
    this.fetchInitial = this.fetchInitial.bind(this);
    this.onClickResentInvoice = this.onClickResentInvoice.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.onOpenModal = this.onOpenModal.bind(this);
    this.onEditMail = this.onEditMail.bind(this);
    this.onCloseEditModal = this.onCloseEditModal.bind(this);
    this.displayToastErrorMessage = this.displayToastErrorMessage.bind(this);
    this.handleErrorData = this.handleErrorData.bind(this);
  }

  componentDidMount() {
    this._ismounted = true;
    this.fetchInitial();
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    this._ismounted = false;
    window.removeEventListener("scroll", this.handleScroll);
  }

  displayToastErrorMessage(error, updatedState) {
    if (!this._ismounted) {
      return;
    }
    this.props.addToast(HttpHelper.getErrorMessage(error), {
      type: "error",
      autoDismiss: true,
    });
    if (updatedState) {
      this.setState(updatedState);
    }
  }

  onOpenModal() {
    this.setState({
      edit: {
        display: true,
        loading: false,
        error: null,
      },
    });
  }

  onCloseEditModal() {
    this.setState({
      edit: {
        display: false,
        item: null,
        loading: false,
        error: null,
      },
    });
  }

  onEditMail(NewBillingMail) {
    let formData = {
      email: NewBillingMail.email,
    };
    let edit = Object.assign({}, this.state.edit);
    edit.loading = true;
    this.setState({
      edit: edit,
    });
    accountService.editEmailBilling(
      formData,
      (response) => {
        this.onCloseEditModal();
        this.setState({
          account: response,
        });
        this.props.fetchAccount();
      },
      (error) => {
        this.displayToastErrorMessage(error);
      }
    );
  }

  onClickResentInvoice(invoice_id) {
    const { t } = this.props;
    let sendEmailLoading = Object.assign({}, this.state.sendEmailLoading);
    sendEmailLoading[invoice_id] = true;
    this.setState({ sendEmailLoading: sendEmailLoading });
    invoiceService.resendInvoice(
      invoice_id,
      (response) => {
        let newSendEmailLoading = Object.assign(
          {},
          this.state.sendEmailLoading
        );
        delete newSendEmailLoading[invoice_id];
        this.setState({ sendEmailLoading: newSendEmailLoading });
        this.props.addToast(t("Your invoice has been sent to you by email."), {
          type: "success",
          autoDismiss: true,
        });
      },
      (error) => {
        let errorSendEmailLoading = Object.assign(
          {},
          this.state.sendEmailLoading
        );
        delete errorSendEmailLoading[invoice_id];
        this.setState({ sendEmailLoading: errorSendEmailLoading });
        this.displayToastErrorMessage(error);
      }
    );
  }

  handleScroll() {
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset + 5;
    if (
      windowBottom >= docHeight &&
      this.state.invoices_list.length < this.state.totalItems
    ) {
      this.fetchData();
    }
  }

  handleErrorData(error) {
    this.displayToastErrorMessage(error, {
      isLoaded: true,
      inlineLoading: false,
    });
  }

  fetchData() {
    invoiceService.getInvoicesData(
      this.state.account.id, //account ID
      this.state.formData,
      (response) => {
        const newListItems = CollectionHelper.concatWithoutDuplicate(
          this.state.invoices_list,
          response.invoices_list
        );
        this.setState({
          isLoaded: true,
          inlineLoading: false,
          invoices_list: newListItems,
          totalItems: response.meta.total,
          formData: {
            page: this.state.formData.page + 1,
            per_page: 5,
          },
        });
      },
      (error) => {
        this.handleErrorData(error);
      }
    );
  }

  fetchInitial() {
    invoiceService.getInvoicesData(
      this.state.account.id, //account ID
      this.state.formData,
      (response) => {
        this.setState({
          isLoaded: true,
          inlineLoading: false,
          invoices_list: response.invoices_list,
          totalItems: response.meta.total,
          formData: {
            page: this.state.formData.page + 1,
            per_page: 5,
          },
        });
      },
      (error) => {
        this.handleErrorData(error);
      }
    );
  }

  render() {
    const { t } = this.props;
    const listItems = this.state.invoices_list;
    const inlineLoading = this.state.inlineLoading;
    const parentLoaderClass =
      listItems.length > 0
        ? "text-align-center padding-vertical-20"
        : "text-align-center padding-vertical-80";
    const contactLink = `mailto:${globalConstants.CONTACT_EMAIL}?subject=${t(
      "Sensia - Invoice"
    )}`;
    return (
      <Fragment>
        <div className="content-column invoice-tab responsive-900">
          <div className="column-percent-39 responsive-900 float-right">
            <div className="text-align-right">
              <div className="title-right-box">
                {t("Billing notifications")}
              </div>
              <div className="box-right">
                <div className="billing-notif">
                  <div>{t("Billing notifications are sent to")}:</div>
                  <div className="font-weight-700 text-ellipsis">
                    {this.state.account.billingEmail}
                  </div>
                </div>
                <div className="btn-invoice ">
                  <button className="button" onClick={this.onOpenModal}>
                    {t("Update email address")}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {!this.state.isLoaded && (
            <div className="column-percent-61">
              <div className={parentLoaderClass}>
                <Loader />
              </div>
            </div>
          )}
          {this.state.isLoaded && listItems.length === 0 && (
            <div className="column-percent-61 empty-state-invoice">
              {t("No payment has been made.")}
            </div>
          )}
          {this.state.isLoaded && listItems.length > 0 && (
            <div className="column-percent-61 responsive-900 float-right">
              <div className="font-inter-500 color-7f7f7f font-size-15 padding-bottom-10">
                {t(
                  "Below are all the invoices for the charges effected by Sensia. Please note that every time a charge happens, we also send you an email titled ‘Sensia payment invoice’."
                )}
              </div>
              <div className="font-inter-500 color-7f7f7f font-size-15 padding-bottom-35">
                {t("Don’t hesitate to ")}{" "}
                <a href={contactLink} className="surline-link link-blue">
                  {t("contact us")}
                </a>
                {t(" if you have any queries about a charge.")}
              </div>
              <table className="sensia-table table-layout-fixed width-percent-100">
                <thead className="big-border-b4b4b4">
                  <tr>
                    <th className="empty-left-td-th" />
                    <th width={145}>
                      <span className="color-000 font-size-13 font-weight-500">
                        {t("Date issued")}
                      </span>
                    </th>
                    <th>
                      <span className="color-000 font-size-13 font-weight-500">
                        {t("Id invoice")}
                      </span>
                    </th>
                    <th width={60}>
                      <span className="color-000 font-size-13 font-weight-500">
                        {t("Invoice")}
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody className="border-td td-padding-vertical-16">
                  {this.state.invoices_list.map((element, i) => {
                    return (
                      <tr key={`invoice-${element.invoice_id}`}>
                        <td className="empty-left-td-th" />
                        <td>
                          <span className="font-size-15 color-7f7f7f font-weight-500">
                            {moment
                              .utc(element.created_at)
                              .local()
                              .format("DD/MM/YY")}
                          </span>
                        </td>
                        <td>
                          <span className="font-size-15 color-7f7f7f font-weight-500">
                            {element.invoice_no}
                          </span>
                        </td>
                        <td>
                          {this.state.sendEmailLoading[element.invoice_id] && (
                            <Loader className="size-20" />
                          )}
                          {!this.state.sendEmailLoading[element.invoice_id] && (
                            <span
                              onClick={() => {
                                this.onClickResentInvoice(element.invoice_id);
                              }}
                              className="link-blue font-weight-500"
                            >
                              {t("Resend")}
                            </span>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {inlineLoading && (
                <div className={parentLoaderClass}>
                  <Loader />
                </div>
              )}
            </div>
          )}
        </div>
        <div className="padding-top-60" />
        {this.state.edit.display && (
          <ChangeBillingMailModal
            onClose={this.onCloseEditModal}
            error={this.state.edit.error}
            loading={this.state.edit.loading}
            currentEmail={this.state.account.billingEmail}
            onEditMail={this.onEditMail}
          />
        )}
      </Fragment>
    );
  }
}

export default withTranslation()(InvoiceTab);