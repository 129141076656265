import { MEDIA_STATUS } from "../constants";
import CollectionHelper from "./CollectionHelper";
import StringHelper from "./StringHelper";

class ReportHelper {}

ReportHelper.CLASS_IDS = {
  UNKNOWN: -1,
  MAIN_CHARACTERISTICS: 0,
};

ReportHelper.EXCLUDED_CLASS_TITLE = "Excluded words";

ReportHelper.classIsExcludedWords = (aClass) => {
  return aClass.is_excluded === true && aClass.isIntegrated;
};

ReportHelper.getUniqueKeywords = (report) => {
  let ret = [];
  if (!report) {
    return ret;
  }
  let uniqueKeywords = {};
  report.forEach((element) => {
    const keyword = element["keyword"];
    const classe_title = element["class"]["title"];

    if (typeof uniqueKeywords[keyword] == "undefined") {
      uniqueKeywords[keyword] = {
        scores: [],
        element: {
          value: keyword,
          label: StringHelper.capitalize(keyword),
          keyword: keyword,
          classes: [],
          classes_titles: [],
          occurence: element["occurence"],
        },
      };
    }

    if (
      !uniqueKeywords[keyword].element.classes_titles.includes(classe_title)
    ) {
      uniqueKeywords[keyword].element.classes_titles.push(classe_title);
      uniqueKeywords[keyword].element.classes.push(element["class"]);
    }
    uniqueKeywords[keyword].scores.push(element["score"]);
  });
  for (const [_, keywordContent] of Object.entries(uniqueKeywords)) {
    const scores = keywordContent.scores;
    let keywordObject = keywordContent.element;
    const average_score = scores.reduce((a, b) => a + b, 0) / scores.length;
    const adder = keywordObject.classes_titles.includes("unknown") ? 100 : 1000;
    const sort_score = average_score + adder;
    keywordObject["average_score"] = Math.round(average_score * 100) / 100;
    keywordObject["sort_score"] = sort_score;
    ret.push(keywordObject);
  }
  return ret;
};

ReportHelper.getUniqueKeywordsForProjectReport = (report) => {
  let ret = [];
  if (!report) {
    return ret;
  }
  let uniqueKeywords = {};
  report.forEach((element) => {
    const keyword = element["keyword"];
    if (typeof uniqueKeywords[keyword] == "undefined") {
      uniqueKeywords[keyword] = {
        scores: [],
        element: {
          value: keyword,
          label: StringHelper.capitalize(keyword),
          keyword: keyword,
          occurence: element["occurence"],
        },
      };
    }
    uniqueKeywords[keyword].scores.push(element["score"]);
  });
  for (const [_, keywordContent] of Object.entries(uniqueKeywords)) {
    const scores = keywordContent.scores;
    let keywordObject = keywordContent.element;
    const average_score = scores.reduce((a, b) => a + b, 0) / scores.length;
    const adder = 1000;
    const sort_score = average_score + adder;
    keywordObject["average_score"] = Math.round(average_score * 100) / 100;
    keywordObject["sort_score"] = sort_score;
    ret.push(keywordObject);
  }
  return ret;
};

ReportHelper.getKeywordsWithoutUnknowns = (list) => {
  let ret = [];
  for (const item of list) {
    if (!item.classes_titles.includes("unknown")) {
      ret.push(item);
    }
  }
  return ret;
};

ReportHelper.getKeywordsByClassId = (list, classId) => {
  let ret = [];
  for (const item of list) {
    item.classes.forEach((a_class) => {
      if (a_class["id"] == classId) {
        ret.push(item);
      }
    });
  }
  return ret;
};

ReportHelper.getKeywordsWithoutIntegrated = (list) => {
  let ret = [];
  for (const item of list) {
    let isOnlyIntegrated = true;
    item.classes.forEach((a_class) => {
      if (!a_class.isIntegrated) {
        isOnlyIntegrated = false;
      }
    });
    if (!isOnlyIntegrated) {
      ret.push(item);
    }
  }
  return ret;
};

ReportHelper.sortByOccurence = (ret, nb) => {
  ret.sort(function (a, b) {
    if (a.occurence > b.occurence) return -1;
    if (a.occurence < b.occurence) return 1;
    return 0;
  });
  return ret.slice(0, nb);
};

ReportHelper.getHighestValueKeywords = (keywords, nb) => {
  nb = typeof nb != "undefined" ? nb : 3;
  keywords = ReportHelper.getKeywordsByClassId(
    keywords,
    ReportHelper.CLASS_IDS.MAIN_CHARACTERISTICS
  );
  let ret = [];
  keywords.forEach((element) => {
    if (element.average_score > 0) {
      ret.push(element);
    }
  });
  return ReportHelper.sortByOccurence(ret, nb);
};

ReportHelper.getLowestValueKeywords = (keywords, nb) => {
  nb = typeof nb != "undefined" ? nb : 3;
  keywords = ReportHelper.getKeywordsByClassId(
    keywords,
    ReportHelper.CLASS_IDS.MAIN_CHARACTERISTICS
  );
  let ret = [];
  keywords.forEach((element) => {
    if (element.average_score < 0) {
      ret.push(element);
    }
  });
  return ReportHelper.sortByOccurence(ret, nb);
};

ReportHelper.sortKeywordsByKey = (keywords, key, sort) => {
  let list = Object.assign([], keywords);
  if (sort === "asc") {
    list.sort((a, b) => {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    });
  } else if (sort === "desc") {
    list.sort((a, b) => {
      if (a[key] > b[key]) return -1;
      if (a[key] < b[key]) return 1;
      return 0;
    });
  }
  for (let i = 0; i < list.length; i++) {
    list[i]["sort_id"] = i;
  }
  return list;
};

ReportHelper.keywordsWithDuration = (keywords, key, sort) => {
  let ret = [];
  let data = Object.assign([], keywords);
  data = ReportHelper.sortKeywordsByKey(data, key, sort);
  data.forEach((element) => {
    ret.push(element);
    let elementDuration = Object.assign({}, element);
    elementDuration.start_at += elementDuration.duration;
    ret.push(elementDuration);
  });
  return ret;
};

ReportHelper.getKeywordByName = (list, name) => {
  for (const item of list) {
    if (item.keyword == name) {
      return item;
    }
  }
  return null;
};

ReportHelper.filterKeywords = (list, filters) => {
  let ret = [];
  let properFilters = [];
  filters.forEach((element) => {
    properFilters.push(element.value);
  });
  for (const item of list) {
    if (properFilters.includes(item.keyword)) {
      ret.push(item);
    }
  }
  return ret;
};

ReportHelper.getSimpleSelectedKeywords = (list) => {
  let ret = [];
  list.forEach((element) => {
    ret.push(element.value);
  });
  return ret;
};

ReportHelper.removeNotContaining = (list, children) => {
  if (!children) {
    return children;
  }
  let ret = [];
  children.forEach((element) => {
    if (CollectionHelper.findOneBy(list, "value", element.value)) {
      ret.push(element);
    }
  });
  return ret;
};

ReportHelper.getProjectMediasGlobalScore = (mediasList) => {
  let scores = [];
  if (!mediasList || (mediasList && mediasList.length === 0)) {
    return null;
  }
  let returnNull = false;
  mediasList.forEach((element) => {
    if (element.status === MEDIA_STATUS.INPROGRESS) {
      returnNull = true;
    } else if (element.isCaption) {
      const score = Math.round(element.average_score * 100) / 100;
      scores.push(score);
    }
  });
  if (returnNull) {
    return null;
  }
  let globalScore = scores.reduce((a, b) => a + b, 0) / scores.length;
  return Math.round(globalScore * 100) / 100;
};

ReportHelper.getProjectNbKeywordsByReport = (report) => {
  let nb = 0;
  let doublons = [];
  if (!report || (report && report.length === 0)) {
    return 0;
  }
  report.forEach((element) => {
    if (!doublons.includes(element["keyword"])) {
      doublons.push(element["keyword"]);
      nb++;
    }
  });
  return nb;
};

ReportHelper.getProjectReportNbKeywords = (statistics) => {
  if (!statistics) {
    return 0;
  }
  const report = statistics.report;
  return ReportHelper.getProjectNbKeywordsByReport(report);
};

ReportHelper.getScoreClass = (score) => {
  if (score > 0.5) {
    return "color-score-positive";
  }
  if (score < -0.5) {
    return "color-score-negative";
  }
  return "color-score-neutral";
};

ReportHelper.keywordColors = [
  "#30beec",
  "#924ac4",
  "#e04fc5",
  "#f78f64",
  "#c0e149",
  "#3A6EFA",
  "#C8C137",
  "#FFA07A",
  "#DAF7A6",
  "#A3E4D7",
  "#30beec",
  "#924ac4",
  "#e04fc5",
  "#f78f64",
  "#c0e149",
  "#3A6EFA",
  "#C8C137",
  "#FFA07A",
  "#DAF7A6",
  "#A3E4D7",
];

ReportHelper.getKeywordsArrayFromReport = (report) => {
  let ret = [];
  report.forEach((keyword) => {
    ret.push(keyword.keyword);
  });
  return ret;
};

ReportHelper.getComposedKeywords = (list) => {
  let ret = [];
  list.forEach((keyword) => {
    if (/\s/.test(keyword)) {
      ret.push(keyword);
    }
  });
  return ret;
};
ReportHelper.getNonComposedKeywords = (list) => {
  let ret = [];
  list.forEach((keyword) => {
    if (!/\s/.test(keyword)) {
      ret.push(keyword);
    }
  });
  return ret;
};

ReportHelper.getDefaultSelectedKeywords = (keywordsArray) => {
  let ret = [];
  keywordsArray.forEach((element) => {
    if (typeof element === "string" || element instanceof String) {
      ret.push({
        average_score: 0,
        label: element,
        occurence: 0,
        value: element,
      });
    } else {
      ret.push({
        average_score: element.average_score,
        label: element.word,
        occurence: element.occurence,
        value: element.word,
      });
    }
  });

  console.log("default selected keywords", ret);
  return ret;
};

ReportHelper.getStringKeywords = (keywordsObjectArray, key) => {
  let ret = [];
  keywordsObjectArray.forEach((aKeyword) => {
    ret.push(aKeyword[key]);
  });
  return ret;
};

export default ReportHelper;
