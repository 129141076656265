import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Filter,
  SearchInput,
  TopToolbar,
  EditButton,
  ShowButton,
  ListButton,
  Show,
  SimpleShowLayout,
  BooleanInput,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  TabbedForm,
  required,
  NumberInput,
  SelectArrayInput,
  ReferenceArrayInput,
  ReferenceManyField,
  useNotify,
  useRedirect,
  ReferenceInput,
  AutocompleteInput,
  DeleteButton,
  useRecordContext,
  Button,
  Link,
  BooleanField,
  SelectInput,
  useGetList,
} from "react-admin";
import { BackButton } from "./elements/CustomFields";
import AddIcon from "@mui/icons-material/Add";
import { Box, Hidden, Typography } from "@mui/material";
import { getUrlParameters } from "./SectionList";

const ClasseFilters = (props) => {
  const categoryFilterChoices = [];
  const { data, isLoading } = useGetList("categories");
  data?.map((category) => {
    categoryFilterChoices.push({ id: category.id, name: category.title });
  });

  return (
    <Filter {...props}>
      <SearchInput source="q" className="remove-line-height" alwaysOn />
      <SelectInput
        choices={categoryFilterChoices}
        label="Category"
        source="category"
        allowEmpty={false}
        isLoading={isLoading}
      />
    </Filter>
  );
};

const ClasseShowButton = ({ record }) => [
  <EditButton key="Edit" label="Edit" record={record} />,
  <ShowButton key="Show" label="Show" record={record} />,
];

export const ClasseList = (props) => (
  <>
    <List filters={<ClasseFilters />} {...props} perPage={25}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField label="Classe ID" source="id" />
        <TextField label="Name" source="title" />
        <TextField label="Display Name" source="display_name" />
        <BooleanField
          label="Enabled"
          source="is_enabled"
          valueLabelTrue="active"
          valueLabelFalse="inactive"
        />
        <TextField label="Order" source="sort_order" />
        <TextField label="Updated At" source="updated_at" />
        <ClasseShowButton />
      </Datagrid>
    </List>
  </>
);

const ClasseShowActions = () => (
  <TopToolbar>
    <EditButton />
    <ListButton />
  </TopToolbar>
);

export const ClasseShow = () => (
  <Show actions={<ClasseShowActions />}>
    <BackButton to={"/classes"} />
    <SimpleShowLayout>
      <TextField label="Classe ID" source="id" />
      <TextField label="Name" source="title" />
      <TextField label="Display Name" source="display_name" />
      <BooleanField
        label="Enabled"
        source="is_enabled"
        valueLabelTrue="active"
        valueLabelFalse="inactive"
      />
      <TextField label="Order" source="sort_order" />
      <TextField label="Updated At" source="updated_at" />
    </SimpleShowLayout>
  </Show>
);

export const ClasseCreate = () => (
  <Create resource="classes">
    <BackButton to={"/classes"} />
    <SimpleForm>
      <TextInput label="Name" source="title" />
    </SimpleForm>
  </Create>
);

const ClasseEditActions = () => (
  <TopToolbar>
    <ShowButton />
    <ListButton />
  </TopToolbar>
);

const ClasseAccountsEmptyState = () => {
  return (
    <Box sx={{ p: "30px" }}>
      <Typography
        sx={{
          fontFamily: "Inter",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "normal",
          letterSpacing: "0.15px",
          mt: "5px",
          mb: "8px",
        }}
      >
        The account selection is currently empty, indicating that all accounts
        have access to this classe.
      </Typography>
    </Box>
  );
};

const classeAccountsRowClick = (id, resource, record) => {
  return `/accounts/${record.account_id}/show`;
};

export const ClasseEdit = () => {
  return (
    <Edit mutationMode="pessimistic" actions={<ClasseEditActions />}>
      <BackButton to={"/classes"} />
      <TabbedForm>
        <TabbedForm.Tab label="General">
          <TextInput
            size="medium"
            source="title"
            label="Name"
            validate={[required()]}
            sx={{ width: "50%" }}
          />
          <TextInput
            size="medium"
            source="display_name"
            label="Display Name"
            validate={[required()]}
            sx={{ width: "50%" }}
          />

          <BooleanInput
            label="Enabled"
            source="is_enabled"
            helperText="The classe is enabled to all users"
          />
          <NumberInput label="Order" source="sort_order" />
          <BooleanInput
            label="Excluded"
            source="is_excluded"
            helperText="Excluded keywords classe"
          />
          <ReferenceArrayInput
            source="product_category_ids"
            reference="categories"
          >
            <SelectArrayInput optionText="title" />
          </ReferenceArrayInput>
          <Typography>Test type</Typography>
          <BooleanInput label="Consumer" source="consumer" />
          <BooleanInput label="Lab expert" source="lab_expert" />
          <TextInput disabled label="Updated at" source="updated_at" />
          <TextInput disabled label="Created at" source="created_at" />
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Attribut">
          <TextInput
            resettable="true"
            size="medium"
            fullWidth
            multiline
            source="keywords_txt"
            label="Keywords"
          />
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Accounts" path="accounts">
          <ReferenceManyField
            reference="classe-accounts"
            target="id"
            label="Account"
          >
            <Datagrid
              rowClick={classeAccountsRowClick}
              empty={<ClasseAccountsEmptyState />}
              bulkActionButtons={false}
            >
              <TextField label="ID" source="id" />
              <TextField label="Account ID" source="account_id" />
              <TextField label="Classe ID" source="classe_id" />
              <TextField label="Name" source="account_name" />
              <TextField label="Created At" source="created_at" />
              <DeleteButton redirect="" />
            </Datagrid>
          </ReferenceManyField>
          <CreateRelatedAccountButton />
        </TabbedForm.Tab>
      </TabbedForm>
    </Edit>
  );
};

export const CreateRelatedAccountButton = () => {
  const classe = useRecordContext();
  return (
    <>
      <Button
        component={Link}
        to={`/classe-accounts/create?classeId=${classe.id}`}
        color="primary"
        label="Create"
      >
        <AddIcon />
      </Button>
    </>
  );
};

// Note we declared the function outside the component to avoid rerenders

const optionRenderer = (choice) => `${choice.id} - ${choice.account_name}`;

export const AccountClasseCreate = () => {
  const notify = useNotify();
  const redirect = useRedirect();

  const urlParams = getUrlParameters();
  const classeId = urlParams["classeId"];

  const onSuccess = () => {
    // display a notification to confirm the creation and redirect to classe accounts list
    notify("ra.notification.created");
    redirect(`/classes/${classeId}/accounts`);
  };

  return (
    <Create mutationOptions={{ onSuccess, meta: { classeId: classeId } }}>
      <SimpleForm>
        <Hidden source="classe_id" />
        <ReferenceInput
          label="Account"
          source="account_id"
          reference="accounts"
          sort="ASC"
        >
          <AutocompleteInput optionText={optionRenderer} optionValue="id" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
