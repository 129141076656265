import React, { Fragment } from 'react';
import { Redirect, Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import '../unconnected.css';
import PasswordField from '../../../components/form/field/PasswordField';
import ButtonField from '../../../components/form/field/ButtonField';
import { globalConstants } from "../../../app/constants";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { ROUTES } from '../../../app/routing';
import HeaderUnconnected from '../../../components/header/HeaderUnconnected';
import FooterUnconnected from '../../../components/footer/FooterUnconnected';
import StringHelper from '../../../app/helpers/StringHelper';
import HttpHelper from '../../../app/helpers/HttpHelper';
import LoadingView from '../../loading/LoadingView';
import { userService } from '../../../app/services';
import illus from '../../../res/images/illustrations/illus-pw-lock.png';
import InvalidTokenInfo from '../../../components/invalidToken/InvalidTokenInfo';

class ForgotPasswordSetNewView extends React.Component {
    constructor(props) {
        super(props);
        const urlParams = HttpHelper.getUrlParameters(props.location.search);

        this.state = {
            formData: {
                password: '',
                token: (urlParams.token ? urlParams.token : null)
            },
            isLoaded: false,
            loading: false,
            formErrors: null,
            errorToken: false,
            redirectToReferer: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getErrorMessage = this.getErrorMessage.bind(this);
        this.checkToken = this.checkToken.bind(this);
    }

    componentDidMount() {
        this._ismounted = true;
        this.checkToken();
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    checkToken() {
        const token = HttpHelper.getUrlParameters(this.props.location.search)
        userService.checkValidEmailToken(
            token.token,
            (response) => {
                this.setState({ isLoaded: true });
            },
            (error) => {
                this.setState({ isLoaded: true, errorToken: true });
            }
        );
    }

    handleChange(event) {
        const { t } = this.props;
        let formData = Object.assign({}, this.state.formData);
        formData[event.target.name] = event.target.value;
        let formErrors = null
        if (event.target.name === 'password' && !StringHelper.isPasswordValid(event.target.value)) {
            formErrors = { password: t('Please enter a valid password') };
        }
        this.setState({
            formData: formData,
            formErrors: formErrors
        });
    }

    handleSubmit(event) {
        if (!this.state.formErrors) {
            this.setState({ loading: true });
            userService.passwdSetNew(
                this.state.formData,
                (response) => {
                    this.props.history.push(ROUTES.HOME);
                },
                (error) => {
                    this.setState({
                        loading: false,
                        formErrors: HttpHelper.getErrorMessage(error)
                    });
                }
            );
        }
        event.preventDefault();
    }

    getErrorMessage(target) {
        if (this.state.formErrors && (this.state.formErrors.hasOwnProperty(target))) {
            return this.state.formErrors[target];
        }
        return null;
    }

    render() {
        const { t } = this.props;
        const { from } = { from: { pathname: "/" } };
        let formElementClassName = (this.state.error) ? 'error' : '';
        if (this.state.redirectToReferer) {
            return <Redirect to={from} />;
        }
        let formClassName = (this.state.loading) ? 'loading-form' : '';
        const page_title = t('Set your new password') + ' - ' + globalConstants.WEBSITE_TITLE;
        if (this.state.isLoaded) {
            return (
                <Fragment>
                    <Helmet>
                        <title>{page_title}</title>
                    </Helmet>
                    <div className="unconnected-content central">
                        <div className="content-white">
                            <HeaderUnconnected />
                            <div className="c-content-table padding-horizontal-80">
                                <div className="c-content-table-cell">
                                    <div className="centralized-content">
                                        <div className="margin-auto" style={{ maxWidth: '300px' }}>
                                            <img alt="" src={illus} className="img-100" />
                                        </div>
                                    </div>
                                    <div className="centralized-content width-420">
                                        <div className="font-inter-700 font-size-36 text-align-center color-0e004b padding-bottom-50">{t('Set your new password')}</div>
                                    </div>
                                    {this.state.errorToken &&
                                        <InvalidTokenInfo withIcon={false}>
                                            <div className="">
                                                {t('You need to ask a new password by')} <Link to={ROUTES.FORGOT_PASSWORD} className="link-blue font-size-15 font-inter-500">
                                                {t('clicking here')}
                                            </Link>.
                                            </div>
                                        </InvalidTokenInfo>
                                    }
                                    {!this.state.errorToken &&
                                        <div className="centralized-content padding-horizontal-30">
                                            <form onSubmit={this.handleSubmit} className={formClassName}>
                                                <PasswordField
                                                    label={t('Password')}
                                                    name="password"
                                                    id="new-password"
                                                    autoComplete="new-password"
                                                    required={true}
                                                    disabled={this.state.loading}
                                                    value={this.state.formData.password}
                                                    onChange={this.handleChange}
                                                    withForceHelper={true}
                                                    errorMessage={this.getErrorMessage('password')}
                                                    formElementClassName={formElementClassName}
                                                />
                                                <div className="padding-top-10" />
                                                <ButtonField
                                                    type="submit"
                                                    value={t('Validate')}
                                                    disabled={this.state.loading || this.state.formErrors}
                                                    loading={this.state.loading}
                                                    className="font-work-sans-600 height-45 width-170"
                                                    formElementClassName="padding-vertical-20 text-align-center"
                                                />
                                            </form>
                                        </div>
                                    }
                                </div>
                            </div>
                            <FooterUnconnected />
                        </div>
                    </div>
                </Fragment>
            );
        }
        return <LoadingView title={page_title} />;
    }
}

function mapStateToProps(state) {
    return {};
}

export default withTranslation()(connect(mapStateToProps)(ForgotPasswordSetNewView));