import React from "react";
import "../header.css";
import { withTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";

import close from "../../../res/images/help-menu/close.png";
import faq from "../../../res/images/help-menu/faq.png";
import glossary from "../../../res/images/help-menu/glossary.png";
import link from "../../../res/images/help-menu/link.png";
import setup from "../../../res/images/help-menu/setup.png";
import started from "../../../res/images/help-menu/started.png";
import viewfile from "../../../res/images/help-menu/viewfile.png";

class HelpMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerWidth: 364,
      menuItems: {
        first: [
          {
            text: "Getting started guide",
            icon: started,
            fontWeight: "normal",
            link: "https://sensia.atlassian.net/servicedesk/customer/portal/1/article/2687061",
          },
          {
            text: "Find supported datasource",
            icon: viewfile,
            fontWeight: "normal",
            link: "https://intercom.help/sensia-app/en/articles/9083807-find-supported-data-sources",
          },
          {
            text: "Glossary",
            icon: glossary,
            fontWeight: "normal",
            link: "https://sensia.atlassian.net/servicedesk/customer/portal/1/article/1245186",
          },
          {
            text: "FAQ",
            icon: faq,
            fontWeight: "normal",
            link: "https://sensia.atlassian.net/servicedesk/customer/portal/1/article/2949121",
          },
          {
            text: "Suggest an improvement",
            icon: setup,
            fontWeight: "normal",
            link: "https://sensia.atlassian.net/servicedesk/customer/portal/1/group/1/create/8",
          },
        ],
        second: [
          {
            text: "Report and studies",
            icon: null,
            fontWeight: "normal",
            link: "https://sensia.ai/reports-studies",
          },
          {
            text: "Blog",
            icon: null,
            fontWeight: "normal",
            link: "https://sensia.ai/blog",
          },
        ],
        third: [
          {
            text: "Help Center",
            icon: null,
            fontWeight: "600",
            link: "https://sensia.atlassian.net/servicedesk/customer/portals",
          },
        ],
        bottom: [
          {
            text: "About Us",
            link: "https://sensia.ai/about-us",
          },
          {
            text: "Privacy Policy",
            link: "https://sensia.ai/privacy-policy",
          },
          {
            text: "Terms of Service",
            link: "https://sensia.ai/terms-of-service",
          },
        ],
      },
    };

    this.renderItem = this.renderListItem.bind(this);
  }

  /* The `renderListItem(item)` function is a helper function that takes an item object as a parameter
  and returns a JSX element representing a list item. It uses the properties of the item object to
  render the icon, text, and link of the list item. The function is used in the `render()` method to
  map over the menu items and generate the list items for each menu item. */
  renderListItem(item) {
    return (
      <ListItem key={item.text} disablePadding>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            paddingLeft: `${item.icon ? "14px" : "34px"}`,
            paddingRight: "30px",
          }}
        >
          <ListItemButton component="a" href={item.link} target="_blank">
            {item.icon && (
              <ListItemIcon sx={{ justifyContent: "center" }}>
                <img width={26} src={item.icon} alt={item.text} />
              </ListItemIcon>
            )}
            <ListItemText
              primary={item.text}
              primaryTypographyProps={{
                fontFamily: "'Inter', sans-serif",
                fontSize: 15,
                fontWeight: item.fontWeight,
                color: "#212121",
                lineHeight: "1.47",
                letterSpacing: "normal",
              }}
            />
            <img width={26} height={26} src={link} alt="Link" />
          </ListItemButton>
        </Box>
      </ListItem>
    );
  }

  render() {
    const { toggleDrawer } = this.props;

    return (
      <Box
        sx={{ width: this.state.drawerWidth, paddingTop: "60px" }}
        role="presentation"
        onClick={this.toggleDrawer}
        onKeyDown={this.toggleDrawer}
      >
        <Box
          sx={{
            height: "86px",
            backgroundColor: "#f6f7fd",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontSize: "20px",
            color: "#212121",
            fontWeight: 600,
            paddingLeft: "48px",
            paddingRight: "48px",
          }}
        >
          Help
          <img
            onClick={toggleDrawer}
            src={close}
            style={{ width: "24px", height: "24px", cursor: "pointer" }}
            alt="close"
          />
        </Box>
        <Divider />
        <List>
          {this.state.menuItems.first.map((item, index) =>
            this.renderListItem(item)
          )}
        </List>
        <List sx={{ marginTop: "30px" }}>
          {this.state.menuItems.second.map((item, index) =>
            this.renderListItem(item)
          )}
        </List>
        <List sx={{ marginTop: "30px" }}>
          {this.state.menuItems.third.map((item, index) =>
            this.renderListItem(item)
          )}
        </List>
        <Box
          sx={{
            height: "45px",
            width: `${this.state.drawerWidth - 36}px`, // drawer width - padding (2 * 18)
            backgroundColor: "#f6f7fd",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontSize: "12px",
            color: "#212121",
            fontWeight: 600,
            paddingLeft: "18px",
            paddingRight: "18px",
            position: "absolute",
            bottom: "0px",
          }}
        >
          {this.state.menuItems.bottom.map((item, index) => {
            return (
              <ListItemButton
                disableGutters
                component="a"
                href={item.link}
                target="_blank"
              >
                <ListItemText
                  primary={item.text}
                  primaryTypographyProps={{
                    fontFamily: "'Inter', sans-serif",
                    fontSize: 12,
                    textAlign: "center",
                    fontWeight: 600,
                    color: "#212121",
                    lineHeight: "1.33",
                    letterSpacing: "normal",
                  }}
                />
              </ListItemButton>
            );
          })}
        </Box>
      </Box>
    );
  }
}

export default withTranslation()(HelpMenu);
