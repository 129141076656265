import React from 'react';
import {withTranslation} from 'react-i18next';


class EmptyStateSearch extends React.Component {
    render() {
        const {t} = this.props;
        return (
            <div className="padding-vertical-55 border-radius-6 padding-horizontal-30 text-align-center background-color-fafafa">
                <div className="font-inter-700 font-size-20 padding-bottom-4 color-000">{t('No result found')}</div>
            </div>
        );
    }
}

export default withTranslation()(EmptyStateSearch);