import '../settings.css';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { globalConstants, USER_ROLES_ENUM } from "../../../app/constants";
import { connect } from 'react-redux';
import Header from "../../../components/header/Header";
import Footer from '../../../components/footer/Footer';
import LoadingView from '../../loading/LoadingView';
import Link from "../../../components/Link";

import { withTranslation } from 'react-i18next';
import { PROJECT_ADD_MEDIA, PROJECT_REFINE, PROJECT_REPORT, PROJECT_WORKSPACE, ROUTES, SETTINGS_USERS_AND_TEAMS } from '../../../app/routing';
import HttpHelper from '../../../app/helpers/HttpHelper';
import UserGuideAccordion from './elements/UsersGuideAccordion';
import UsersGuideShowMoreButton from './elements/UsersGuideShowMoreButton';
import { userService } from '../../../app/services/user.service';


class UserGuideView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            inlineLoading: false,
            tasks: [],
            percentCompletion: 0,
            opened: false
        };

        this.fetchInitial = this.fetchInitial.bind(this);
        this.onSkipTask = this.onSkipTask.bind(this);
        this.generateTasks = this.generateTasks.bind(this);
        this.getSortedTasks = this.getSortedTasks.bind(this);
        this.onHandleOpen = this.onHandleOpen.bind(this);
    }

    componentDidMount() {
        this._ismounted = true;
        this.fetchInitial();
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    fetchInitial() {
        if (this._ismounted) {
            this.setState({ inlineLoading: true });
        }
        userService.updateCurrentUser((user) => {
            userService.userGuideCompletionInfos(
                (response) => {
                    let tasks = this.generateTasks(response);
                    let percentCompletion = user.user_guide_percent_completion;
                    this.setState({
                        isLoaded: true,
                        inlineLoading: false,
                        tasks: tasks,
                        percentCompletion: percentCompletion
                    });
                },
                (error) => {
                    if (!this._ismounted) {
                        return;
                    }
                    this.setState({
                        isLoaded: true,
                        inlineLoading: false
                    });
                    this.props.addToast(HttpHelper.getErrorMessage(error), { type: 'error', autoDismiss: true });
                }
            )
        });
    }

    onSkipTask(task_id) {
        if (this._ismounted) {
            this.setState({ inlineLoading: true });
        }
        let tasks = Object.assign([], this.state.tasks);
        let targetTask = null;
        for (const item of tasks) {
            if (item.id === task_id) {
                item.is_skipped = true;
                targetTask = item;
            }
        }
        this.setState({ tasks: tasks });
        if (!targetTask) {
            return;
        }
        const formData = {
            'guide_key': targetTask.key
        }
        userService.skipUserGuide(
            formData,
            null,
            (error) => {
                if (!this._ismounted) {
                    return;
                }
                this.props.addToast(HttpHelper.getErrorMessage(error), { type: 'error', autoDismiss: true });
            }
        );
    }

    generateTasks(response) {
        const { t } = this.props;
        const projectId = response.project_id || 0;
        const guide = response.guide;
        const userRole = response.user_role_account;
        let ret = [
            {
                id: 1,
                key: 'create_account',
                title: t('Create your account'),
                description: t("You've done it! Now, let's get started."),
                button: null,
                is_skipped: guide.create_account.is_skipped,
                is_completed: guide.create_account.is_completed
            }
        ];
        if (userRole === USER_ROLES_ENUM.ADMIN) {
            ret.push({
                id: 2,
                key: 'invite_users',
                title: t('Invite users'),
                description: t('Analyze your products together.'),
                button: {
                    to: HttpHelper.addParametersToUrl(ROUTES.SETTINGS_USERS_AND_TEAMS_BASE, { modale: 'create' }),
                    label: t('Invite users')
                },
                is_skipped: guide.invite_users.is_skipped,
                is_completed: guide.invite_users.is_completed
            },
                {
                    id: 3,
                    key: 'create_team',
                    title: t('Create a team'),
                    description: t('Organize your workforce.'),
                    button: {
                        to: HttpHelper.addParametersToUrl(SETTINGS_USERS_AND_TEAMS('teams'), { modale: 'create' }),
                        label: t('Create team')
                    },
                    is_skipped: guide.create_team.is_skipped,
                    is_completed: guide.create_team.is_completed
                });
        }
        ret.push({
            id: 4,
            key: 'create_project',
            title: t('Create a project'),
            description: t('Analyze a product with Sensia.'),
            button: {
                to: ROUTES.CREATE_PROJECT,
                label: t('Create project')
            },
            is_skipped: guide.create_project.is_skipped,
            is_completed: guide.create_project.is_completed
        },
            {
                id: 5,
                key: 'upload_media',
                title: t('Upload a data source'),
                description: t('Feed the beast with data sources.'),
                button: {
                    to: PROJECT_ADD_MEDIA(projectId),
                    label: t('Upload data source')
                },
                is_skipped: guide.upload_media.is_skipped,
                is_completed: guide.upload_media.is_completed
            },
            {
                id: 6,
                key: 'analyse_report',
                title: t('Analyze the report'),
                description: t('Discover how people feel about the product.'),
                button: {
                    to: PROJECT_REPORT(projectId),
                    label: t('Open in-depth')
                },
                is_skipped: guide.analyse_report.is_skipped,
                is_completed: guide.analyse_report.is_completed
            },
            {
                id: 7,
                key: 'work_on_keyword',
                title: t('Work on attributes'),
                description: t('Deepen your analysis.'),
                button: {
                    to: PROJECT_WORKSPACE(projectId),
                    label: t('Open inspect')
                },
                is_skipped: guide.work_on_keyword.is_skipped,
                is_completed: guide.work_on_keyword.is_completed
            },
            {
                id: 8,
                key: 'refine_hypotheses',
                title: t('Refine your Story matching'),
                description: t('Refine your product description and value.'),
                button: {
                    to: PROJECT_REFINE(projectId),
                    label: t('Refine Story matching')
                },
                is_skipped: guide.refine_hypotheses.is_skipped,
                is_completed: guide.refine_hypotheses.is_completed
            },
        );
        return ret;
    }

    getSortedTasks() {
        let ret = Object.assign([], this.state.tasks);
        ret = ret.sort((a, b) => {
            if (a.id < b.id) return -1;
            if (a.id > b.id) return 1;
        });
        ret = ret.sort((a, b) => {
            if (a.is_skipped && !b.is_skipped) return 1;
            if (!a.is_skipped && b.is_skipped) return -1;
            return 0;
        });
        ret = ret.sort((a, b) => {
            if (a.is_completed && !b.is_completed) return 1;
            if (!a.is_completed && b.is_completed) return -1;
            return 0;
        });
        return ret;
    }

    onHandleOpen() {
        this.setState({
            opened: !this.state.opened
        });
    }

    render() {
        const { t } = this.props;
        let page_title = `${t('User Guide')} - ${globalConstants.WEBSITE_TITLE}`;
        if (this.state.isLoaded) {
            const tasks = this.getSortedTasks();
            let contentClassNames = ['content-all-user-guide-accordion'];
            if (this.state.opened) {
                contentClassNames.push('opened');
            }
            return (
                <Fragment>
                    <Helmet>
                        <title>{page_title}</title>
                    </Helmet>
                    <Header />
                    <div className="padding-top-30" />
                    <div className="central-content">
                        <div className="padding-bottom-10">
                            <Link to={ROUTES.HOME} className="settings-button-go-back transition-opacity">
                                <span className="icon"></span>
                                <span className="font-inter-400 font-size-18">{t('Back')}</span>
                            </Link>
                        </div>
                        <div className="font-inter-700 font-size-28 color-000 padding-bottom-12">{t('User guide')}</div>
                        <div className="font-inter-400 font-size-16 color-7f7f7f padding-bottom-50">{t('Here are some setup tasks to help you get started.')}</div>
                        <div className="font-inter-700 font-size-22 color-000 padding-bottom-12">{t('Steps to get started with Sensia')}</div>

                        <div className="user-guide-full-content-progress-bar">
                            <div className="content-progress-bar">
                                <div className="progress-bar" style={{ width: `${this.state.percentCompletion}%` }}></div>
                            </div>
                            <div className="percent-progression font-inter-500 font-size-15 text-align-right color-000">{this.state.percentCompletion}%</div>
                        </div>
                        <div className="padding-top-5" />
                        <div className={contentClassNames.join(' ')}>
                            {tasks.map((item, i) => {
                                return (<UserGuideAccordion defaultOpened={i === 0} item={item} key={`ug-task-${i}`} onSkipTask={() => { this.onSkipTask(item.id) }} />);
                            })}
                        </div>
                        <UsersGuideShowMoreButton opened={this.state.opened} onHandleOpen={this.onHandleOpen} />
                    </div>
                    <div className="padding-top-20" />
                    <Footer />
                </Fragment>
            );
        }
        return <LoadingView title={page_title} withHeader={true} />;
    }
}

function mapStateToProps(state) {
    return {};
}

export default withTranslation()(connect(mapStateToProps)(UserGuideView));