import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";
import UploadField from "../../../components/form/field/UploadField";
import InformationsTxtFile from "./InformationsTxtFile";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";

class FormTxtFile extends React.Component {
  render() {
    const {
      t,
      formData,
      submitLoading,
      enterpriseFeatureIsDisabled,
      handleChangeFile,
      uploadError,
      uploadProgress,
      formIsValid,
      placeHolder,
      uploadSize,
      languageInfos,
      onCloseInformations,
      inputFileKey,
      fileData,
    } = this.props;
    const fieldsAreDisabled =
      submitLoading ||
      enterpriseFeatureIsDisabled ||
      (uploadProgress !== null && uploadProgress !== 100);
    const informationsData =
      languageInfos && languageInfos.status === "error"
        ? {
            status: "error",
            message: languageInfos.message,
          }
        : {
            status: "success",
            title: formData.title,
            size: uploadSize,
            default_language: languageInfos ? languageInfos : null,
          };
    return (
      <Fragment>
        <div className="padding-top-20" />
        <div className="position-relative padding-bottom-10">
          {fileData &&
            fileData.language_infos &&
            fileData.language_infos.status === "warning" && (
              <div
                className={`content-error-plan ${fileData.language_infos.status} margin-bottom-5`}
              >
                {fileData.language_infos.message}
              </div>
            )}
          {(!formIsValid ||
            (languageInfos && languageInfos.status === "error")) && (
            <UploadField
              accept=".csv, .xlsx, .xls, .txt"
              placeHolder={placeHolder}
              hasValidFile={false}
              hasError={
                !!uploadError ||
                (languageInfos && languageInfos.status === "error")
              }
              fileTypeLibelle={t("TXT file")}
              handleChange={handleChangeFile}
              disabled={fieldsAreDisabled}
              inputKey={inputFileKey}
              withProgressBar={true}
              uploadProgress={uploadProgress}
            />
          )}
          {!!uploadError && (
            <div className="display-flex content-information-media error">
              <ReportProblemOutlinedIcon />
              <div className="padding-5">{uploadError}</div>
            </div>
          )}
        </div>
        {(formIsValid ||
          (languageInfos && languageInfos.status === "error")) && (
          <InformationsTxtFile
            data={informationsData}
            fileData={fileData}
            onCloseClick={onCloseInformations}
          />
        )}
        <div className="padding-bottom-20" />
      </Fragment>
    );
  }
}

export default withTranslation()(FormTxtFile);
