import "../settings.css";
import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { globalConstants } from "../../../app/constants";
import { connect } from "react-redux";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";
import LoadingView from "../../loading/LoadingView";

import { withTranslation } from "react-i18next";
import SettingsMenu from "../elements/SettingsMenu";
import { ROUTES } from "../../../app/routing";
import TextField from "../../../components/form/field/TextField";
import ProfilePictureField from "../../../components/form/field/ProfilePictureField";
import SelectField from "../../../components/form/field/SelectField";
import HttpHelper from "../../../app/helpers/HttpHelper";
import SettingsSaveBar from "../elements/SettingsSaveBar";
import { uniqueId } from "lodash";
import PhoneNumberSelectField from "../../../components/form/field/PhoneNumberSelectField";
import UserHelper from "../../../app/helpers/UserHelper";
import { userService } from "../../../app/services/user.service";
import CollectionHelper from "../../../app/helpers/CollectionHelper";
import SettingsLoadingView from "../../loading/SettingsLoadingView";
import Link from "../../../components/Link";

import { accountService } from "../../../app/services/account.service";

import TooltipWithAction from "../../../components/tooltip/TooltipWithAction";
import DeleteModal from "../../../components/modal/DeleteModal";

import { history } from "../../../../src/app/helpers";
import IntercomHelper from "../../../app/helpers/IntercomHelper";

class ProfileAndPreferencesView extends React.Component {
  constructor(props) {
    super(props);
    const { t } = props;

    let languagesOptions = [
      {
        label: t("English (US)"),
        value: globalConstants.DEFAULT_LANGUAGE,
        target: {
          name: "language",
          value: 'English',
        },
      },
      {
        label: t("French"),
        value: 'fr',
        target: {
          name: "language",
          value: 'French',
        },
      },
      {
        label: t("Spanish"),
        value: 'es',
        target: {
          name: "language",
          value: 'Spanish',
        },
      },
      {
        label: t("Deutsch"),
        value: 'de',
        target: {
          name: "language",
          value: 'Deutsch',
        },
      },
    ];
    let dateAnNumberFormatsOptions = [
      {
        label: t("English (US)"),
        value: globalConstants.DEFAULT_LANGUAGE,
        target: {
          name: "date_and_number_format",
          value: 'English',
        },
      },
      {
        label: t("French"),
        value: 'fr',
        target: {
          name: "date_and_number_format",
          value: 'French',
        },
      },
      {
        label: t("Spanish"),
        value: 'es',
        target: {
          name: "date_and_number_format",
          value: 'Spanish',
        },
      },
      {
        label: t("Deutsch"),
        value: 'de',
        target: {
          name: "date_and_number_format",
          value: 'Deutsch',
        },
      },
    ];

    this.state = {
      isLoaded: false,
      inlineLoading: false,
      formKey: uniqueId(),
      languagesOptions: languagesOptions,
      dateAnNumberFormatsOptions: dateAnNumberFormatsOptions,
      formData: {},
      pictureToUpload: null,
      formErrors: {},
      isDeleteDisabled: false,
      displayDeleteModal: false,
      loadingDeleteModal: false,
      isUserOnly: false,
      account_id_to_manage: null,
    };

    this.fetchInitial = this.fetchInitial.bind(this);
    this.fetchUserAccountData = this.fetchUserAccountData.bind(this);
    this.getNbChanges = this.getNbChanges.bind(this);
    this.cancelChanges = this.cancelChanges.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangePicture = this.handleChangePicture.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.getErrorMessage = this.getErrorMessage.bind(this);

    this.redirectToManageUser = this.redirectToManageUser.bind(this);

    this.getLanguageDefaultValue = this.getLanguageDefaultValue.bind(this);
    this.getDateAndNumberFormatsDefaultValue =
      this.getDateAndNumberFormatsDefaultValue.bind(this);
    this.handleErrorInitial = this.handleErrorInitial.bind(this);

    this.getDeleteTooltipContent = this.getDeleteTooltipContent.bind(this);
    this.displayDeleteModal = this.displayDeleteModal.bind(this);
    this.onDeleteAccount = this.onDeleteAccount.bind(this);
    this.onCloseDeleteModal = this.onCloseDeleteModal.bind(this);
  }

  componentDidMount() {
    this._ismounted = true;
    this.fetchInitial();
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  handleErrorInitial(error) {
    if (!this._ismounted) {
      return;
    }
    this.props.addToast(HttpHelper.getErrorMessage(error), {
      type: "error",
      autoDismiss: true,
    });
    this.setState({
      isLoaded: true,
      inlineLoading: false,
    });
  }

  fetchInitial() {
    if (this._ismounted) {
      this.setState({ inlineLoading: true });
    }
    let user = UserHelper.getUser();
    userService.GetProfilUserData(
      user.id,
      (response) => {
        this.setState({
          formData: response.user,
          initialFormData: response.user,
        });
        this.fetchUserAccountData();
      },
      (error) => {
        this.handleErrorInitial(error);
      }
    );
  }

  fetchUserAccountData() {
    if (this._ismounted) {
      this.setState({ inlineLoading: true });
    }
    let user = UserHelper.getUser();
    let account = UserHelper.getCurrentAccount();

    accountService.getUserAccountData(
      account.id,
      user.id,
      (response) => {
        this.setState({
          isUserOnly: response.isUserOnly,
          isDeleteDisabled: response.isDeleteDisabled,
          account_id_to_manage: response.account_id_to_manage,
          isLoaded: true,
          inlineLoading: false,
        });
      },
      (error) => {
        console.log("error ", error);
      }
    );
  }

  getLanguageDefaultValue() {
    const targetValue = this.state.formData.language
      ? this.state.formData.language
      : globalConstants.DEFAULT_LANGUAGE;
    return this.state.languagesOptions.find(
      option => option.target.value === targetValue
    );
  }

  getDateAndNumberFormatsDefaultValue() {
    const targetValue = this.state.formData.date_and_number_format
      ? this.state.formData.date_and_number_format
      : globalConstants.DEFAULT_LANGUAGE;
    return this.state.dateAnNumberFormatsOptions.find(
      option => option.target.value === targetValue
    );
  }

  redirectToManageUser() {
    UserHelper.updateCurrentAccountById(this.state.account_id_to_manage);
    window.location.href = ROUTES.SETTINGS_USERS_AND_TEAMS_BASE;
  }

  handleChange(event, resetForm) {
    let formData = Object.assign({}, this.state.formData);
    formData[event.target.name] = event.target.value;
    this.setState({
      formData: formData,
    });
  }

  handleChangePicture(event, resetForm) {
    this.setState({
      pictureToUpload: event.target.files[0],
    });
    if (resetForm) {
      this.setState({
        formKey: uniqueId(),
      });
    }
  }

  getNbChanges() {
    let nbChanges = 0;
    const formData = this.state.formData;
    const initialFormData = this.state.initialFormData;
    for (const [key, value] of Object.entries(formData)) {
      if (
        !initialFormData.hasOwnProperty(key) ||
        initialFormData[key] !== value
      ) {
        nbChanges++;
      }
    }
    if (this.state.pictureToUpload != null) {
      nbChanges++;
    }
    return nbChanges;
  }

  cancelChanges() {
    this.setState({
      formData: this.state.initialFormData,
      pictureToUpload: null,
      formKey: uniqueId(),
    });
  }

  saveChanges() {
    const { t } = this.props;
    this.setState({
      inlineLoading: true,
    });
    let user = UserHelper.getUser();
    const currentFormData = Object.assign({}, this.state.formData);
    currentFormData.user_id = user.id;
    const formData = new FormData();
    formData.append("file", this.state.pictureToUpload);

    for (const [key, value] of Object.entries(currentFormData)) {
      formData.append(key, value);
    }
    userService.editProfil(
      formData,
      (response) => {
        setTimeout(() => {
          this.setState({
            inlineLoading: false,
            initialFormData: this.state.formData,
            pictureToUpload: null,
          });
          this.props.addToast(t("Changes saved!"), {
            type: "success",
            autoDismiss: true,
          });
          userService.updateCurrentUser();
        }, 1000);
      },
      (error) => {
        this.handleErrorInitial(error);
      }
    );
  }

  getErrorMessage(target) {
    if (this.state.formErrors && this.state.formErrors.hasOwnProperty(target)) {
      return this.state.formErrors[target];
    }
    return null;
  }

  getDeleteTooltipContent() {
    return (
      <div className="tooltip-delete">
        <div className="tooltip-delete-text">
          You can’t be removed. There needs to be another Admin on one of your
          accounts.
          <br />
          <div
            onClick={() => {
              this.redirectToManageUser();
            }}
            className="link-blue font-inter-500 font-weight-500 color-3a79e6 text-decoration-underline"
          >
            Manage users.
          </div>
        </div>
      </div>
    );
  }

  displayDeleteModal() {
    this.setState({
      displayDeleteModal: true,
    });
  }

  onCloseDeleteModal() {
    this.setState({
      displayDeleteModal: false,
    });
  }

  onDeleteAccount(e) {
    e.preventDefault();

    let user = UserHelper.getUser();
    let account = UserHelper.getCurrentAccount();

    IntercomHelper.deleteIntercomUser(user.id);

    accountService.deleteAccount(
      account.id,
      user.id,
      (response) => {
        this.props.history.push(ROUTES.LOGIN);
      },
      (error) => {
        console.log("error ", error);
      }
    );
  }

  render() {
    const { t } = this.props;
    let page_title = `${t("Profile & Preferences")} - ${
      globalConstants.WEBSITE_TITLE
    }`;
    let subtitle2 = "";

    this.state.isUserOnly
      ? (subtitle2 = t(
          "”. This will also delete any reference to you in all Corellis accounts you use this email for. You will no longer be able to log in to any Corellis account with this email address. This can't be undone."
        ))
      : (subtitle2 = t(
          "\". You will no longer be able to log in with this email address. This can't be undone."
        ));
    if (this.state.isLoaded) {
      return (
        <Fragment>
          <Helmet>
            <title>{page_title}</title>
          </Helmet>
          <Header />
          <div className="padding-top-30" />
          <div
            className="central-content settings-content"
            key={this.state.formKey}
          >
            <div className="left">
              <SettingsMenu
                current={ROUTES.SETTINGS_PROFILE_AND_PREFERENCES}
                history={this.props.history}
              />
            </div>
            <div className="right">
              <div className="padding-top-32" />
              <div className="font-inter-700 font-size-28 color-000 padding-bottom-40">
                {t("Profile & Preferences")}
              </div>
              <div className="font-inter-700 font-size-18 color-000 padding-bottom-8">
                {t("Global")}
              </div>
              <div className="font-inter-500 font-size-15 color-7f7f7f padding-bottom-30">
                {t("This applies to all of your Sensia accounts.")}
              </div>
              <ProfilePictureField
                label={t("Profile picture")}
                name="profile_picture"
                defaultValue={this.state.formData.profile_picture}
                disabled={this.state.inlineLoading}
                onChange={this.handleChangePicture}
                errorMessage={this.getErrorMessage("profile_picture")}
              />
              <div className="padding-top-30" />
              <div className="content-column no-hidden">
                <div className="column-percent-50 padding-right-10 responsive-900">
                  <TextField
                    type="text"
                    label={t("First Name")}
                    name="firstname"
                    defaultValue={this.state.formData.firstname}
                    disabled={this.state.inlineLoading}
                    onChange={this.handleChange}
                    errorMessage={this.getErrorMessage("firstname")}
                  />
                </div>
                <div className="column-percent-50 padding-left-10 responsive-900">
                  <TextField
                    type="text"
                    label={t("Last Name")}
                    name="lastname"
                    defaultValue={this.state.formData.lastname}
                    disabled={this.state.inlineLoading}
                    onChange={this.handleChange}
                    errorMessage={this.getErrorMessage("lastname")}
                  />
                </div>
              </div>
              <div className="content-column no-hidden">
                <div className="column-percent-50 padding-right-10 responsive-900">
                  <SelectField
                    label={t("Language")}
                    name="language"
                    isSearchable={false}
                    defaultValue={this.getLanguageDefaultValue()}
                    options={this.state.languagesOptions}
                    onChange={this.handleChange}
                    errorMessage={this.getErrorMessage("language")}
                  />
                  <SelectField
                    label={t("Date and Number Format")}
                    name="date_and_number_format"
                    isSearchable={false}
                    defaultValue={this.getDateAndNumberFormatsDefaultValue()}
                    options={this.state.dateAnNumberFormatsOptions}
                    onChange={this.handleChange}
                    errorMessage={this.getErrorMessage(
                      "date_and_number_format"
                    )}
                  />
                  <div
                    className="font-inter-400 font-size-15 color-7f7f7f padding-bottom-25"
                    style={{ marginTop: "-14px" }}
                  >
                    {t("Format")}: May 4th 2021, 05/04/2021 and 1 234.56
                  </div>
                  <PhoneNumberSelectField
                    formElementClassName="margin-bottom-0"
                    label={t("Phone number")}
                    name="phone_number"
                    namePhoneCountry="phone_country"
                    isSearchable={false}
                    defaultValue={this.state.formData.phone_number}
                    defaultValuePhoneCountry={this.state.formData.phone_country}
                    onChange={this.handleChange}
                    errorMessage={this.getErrorMessage("phone_number")}
                    placeholder=""
                  />
                </div>
              </div>
              <div
                className="font-inter-400 font-size-15 color-7f7f7f line-height-22"
                style={{ marginTop: "4px" }}
              >
                {t(
                  "We may use this phone number to contact you regarding security events, for sending workflow SMS and owner property values. Please see our"
                )}{" "}
                <a
                  href={ROUTES.PRIVACY_POLICY}
                  className="link-blue font-inter-500"
                >
                  {t("privacy policy")}
                </a>{" "}
                {t("for more information")}.
              </div>
              <div className="font-inter-700 font-size-18 color-000 padding-top-40 padding-bottom-30">
                {t("Permanently Remove")}
              </div>
              {this.state.isDeleteDisabled ? (
                <TooltipWithAction
                  placement="bottom"
                  content={this.getDeleteTooltipContent()}
                >
                  <button className="delete-btn delete-btn-disabled">
                    Delete my account
                  </button>
                </TooltipWithAction>
              ) : (
                <button
                  className="delete-btn"
                  onClick={() => this.displayDeleteModal()}
                >
                  Delete my account
                </button>
              )}
            </div>
          </div>
          <div className="padding-top-20" />
          <SettingsSaveBar
            nbChanges={this.getNbChanges()}
            cancelChanges={this.cancelChanges}
            saveChanges={this.saveChanges}
            loading={this.state.inlineLoading}
          />
          {this.state.displayDeleteModal && (
            <DeleteModal
              title={t("Permanently delete your account ?")}
              subtitle1={t(
                "You're about to permanently delete your account \""
              )}
              subtitle2={subtitle2}
              emailAddress={this.state.formData.email}
              inputLabel={t(
                "Type your email address below to delete your user"
              )}
              buttonLabel={t("Delete account")}
              onClose={this.onCloseDeleteModal}
              loading={this.state.loadingDeleteModal}
              onDeleteItem={this.onDeleteAccount}
              deleteAccount={true}
            />
          )}
        </Fragment>
      );
    }
    return (
      <SettingsLoadingView
        title={page_title}
        withHeader={true}
        rightTitle={t("Profile & Preferences")}
        settingsMenuProps={{
          current: ROUTES.SETTINGS_PROFILE_AND_PREFERENCES,
          history: this.props.history,
        }}
      />
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withTranslation()(
  connect(mapStateToProps)(ProfileAndPreferencesView)
);
