
import React from 'react';
import '../list.css';
import { withTranslation } from "react-i18next";
import Link from "../../Link";
import { PROJECT_MEDIA_REPORT } from '../../../app/routing';
import Loader from '../../loader/Loader';
import { MEDIA_STATUS } from '../../../app/constants';
import StringHelper from '../../../app/helpers/StringHelper';
import EmotionHelper from '../../../app/helpers/EmotionHelper';

class MediaButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            buttonLoading: false,
        };
        this.onButtonClick = this.onButtonClick.bind(this);
        this.onThenAnalyse = this.onThenAnalyse.bind(this);
        this.isPositionAbsolute = this.isPositionAbsolute.bind(this);
    }

    onThenAnalyse() {
        this.setState({ buttonLoading: false });
    }

    onButtonClick() {
        const item = this.props.item;
        if (typeof this.props.onLaunchAnalyse != 'undefined') {
            this.setState({ buttonLoading: true });
            this.props.onLaunchAnalyse(item.id, this.onThenAnalyse);
        }
    }

    isPositionAbsolute() {
        const isPositionAbsolute = this.props.isPositionAbsolute;
        let defaultClassName = "";
        isPositionAbsolute ? defaultClassName = "position-absolute-vertical-center responsive-920-position-normal" : defaultClassName = "responsive-920-position-normal";
        return defaultClassName
    }

    render() {
        const { t } = this.props;
        const projectId = this.props.projectId;
        const item = this.props.item;
        const status = item.status;
        if ([MEDIA_STATUS.TOPROCESS, MEDIA_STATUS.INPROGRESS, MEDIA_STATUS.FAILED].includes(status) && this.state.buttonLoading) {
            return (
                <div className="responsive-920-position-normal">
                    <div className="left-button">
                        <div className="loader-button" style={{ padding: "2px 0" }}>
                            <Loader />
                        </div>
                    </div>
                </div>
            )
        }
        switch (status) {
            case MEDIA_STATUS.TOPROCESS:
                return (
                    <div className="responsive-920-position-normal no-responsive-920">
                        <div className="left-button">
                            <button onClick={this.onButtonClick}>{t('Analyse')}</button>
                        </div>
                    </div>
                );
            case MEDIA_STATUS.INPROGRESS:
            case MEDIA_STATUS.FAILED:
                return (
                    <div className="responsive-920-position-normal no-responsive-920">
                        <div className="left-button">
                            <button onClick={this.onButtonClick}>{t('Retry')}</button>
                        </div>
                    </div>
                );
            default:
                if(!item.main_emotion){
                    return null;
                }
                return (
                    <div className={`${this.isPositionAbsolute}`}>
                        <Link to={PROJECT_MEDIA_REPORT(projectId, item.id)} className="left-button text-decoration-none">
                            <div className="margin-left-90 display-inline-block emotion">
                                <div className="round-emotion-color vertical-align-middle display-inline-block" style={{backgroundColor: EmotionHelper.getEmotionColor(item.main_emotion) }} />
                                <div className="vertical-align-middle display-inline-block font-inter font-size-15 line-height-19 color-7f7f7f">
                                    {StringHelper.capitalizeFirstLetter(t('{{feeling}}', { feeling: item.main_emotion }))}
                                </div>
                            </div>
                        </Link>
                    </div>
                );
        }

    }
}

export default withTranslation()(MediaButton);