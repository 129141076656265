import React from 'react'
import PropTypes from 'prop-types'
import ReportHelper from '../../app/helpers/ReportHelper';
import Tooltip from '../tooltip/Tooltip';

import './colored-score.css';
import ScoreTooltipContent from '../tooltip/ScoreTooltipContent';
import BlurDataHelper from '../../app/helpers/BlurDataHelper';
import TooltipWithAction from '../tooltip/TooltipWithAction';
import WorkspaceScoreTooltipContent from '../tooltip/WorkspaceScoreTooltipContent';

export const ColoredScore = ({ score, maxRange, onUpdateScore, updateScoreLoading, content, withHelpTooltip, tooltipType, tooltipItem, tooltipScoreLibelle, hasLimitedAccess, className, scoreTooltipRefs, scoreTooltipRef, scoreTooltipRefIndex, ...props }) => {
  let theScore = (typeof score !== 'undefined' && score !== undefined && score !== "-") ? parseFloat(score).toFixed(2) : undefined;
  const tooltipRef = scoreTooltipRef || React.createRef();
  if (hasLimitedAccess) {
    return <div className={`blured-score ${BlurDataHelper.getRandomClass()}`} />
  }
  let displayedValue = (content) ? content : '-';
  if (!content && (typeof theScore !== 'undefined')) {
    displayedValue = theScore;
    if (isNaN(displayedValue)) {
      displayedValue = ""
    }
  }
  const getTooltipContent = () => {
    if (tooltipType === 'workspace_score') {
      return (
        <div className="base-tooltip">
          <WorkspaceScoreTooltipContent onUpdateScore={onUpdateScore} updateScoreLoading={updateScoreLoading} type={tooltipType} maxRange={maxRange} item={tooltipItem} scoreLibelle={tooltipScoreLibelle} tooltipRef={tooltipRef} scoreTooltipRefs={scoreTooltipRefs} scoreTooltipRefIndex={scoreTooltipRefIndex} />
        </div>
      );
    }
    return (
      <div className="base-tooltip">
        <ScoreTooltipContent type={tooltipType} item={tooltipItem} scoreLibelle={tooltipScoreLibelle} />
      </div>
    );
  };

  const scoreClass = (theScore !== undefined) ? ReportHelper.getScoreClass(theScore) : 'color-d2d2d2';
  let scoreClassNames = [scoreClass, 'cursor-default-important'];
  if (className) {
    scoreClassNames.push(className);
  }
  if (withHelpTooltip) {
    if (tooltipType === 'workspace_score') {
      return (
        <TooltipWithAction content={getTooltipContent()} placement="bottom" ref={tooltipRef}>
          <span className={scoreClassNames.join(' ')} {...props}>
            {displayedValue}
          </span>
        </TooltipWithAction>
      )
    }
    return (
      <Tooltip content={getTooltipContent()} placement="bottom">
        <span className={scoreClassNames.join(' ')} {...props}>
          {displayedValue}
        </span>
      </Tooltip>
    )
  }
  return (
    <span className={scoreClassNames.join(' ')} {...props}>
      {displayedValue}
    </span>
  )
}

ColoredScore.propTypes = {
  score: PropTypes.any,
  withHelpTooltip: PropTypes.bool,
  className: PropTypes.string,
  hasLimitedAccess: PropTypes.bool
}

export default ColoredScore
