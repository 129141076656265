// basic import
import React, { Fragment } from "react";
import "./ProjectFilters.css";

// images & icons import
import FiltersIcon from "../../res/images/icons/filter-list-filled.svg";
import FiltersArrow from "../../res/images/icons/icon-right.svg";
import DatasourceFiltersArrow from "../../res/images/icons/right-action.svg";
import CloseBtn from "../../res/images/icons/cancel-filled.svg";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

class ProjectFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openFilters: false,
      response: {},
      showedItem: {},
      toShow: [],
      disabled: false,
      allDisabled: false,
    };

    this.wrapperRef = React.createRef();

    this.openFilters = this.openFilters.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.onChangeFilter = this.onChangeFilter.bind(this);
    this.onClickOutside = this.onClickOutside.bind(this);
    // this.showFilters = this.showFilters.bind(this);
    this.resetAllFilters = this.resetAllFilters.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.onClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.onClickOutside);
  }

  openFilters(force = false) {
    if (this.state.openFilters || force) {
      document.getElementById("filters-content").classList.remove("active");
      document.getElementById("filters-arrow").classList.remove("active");
      document.getElementById("filtersBtn").classList.remove("active");

      this.setState({
        openFilters: !this.state.openFilters,
      });
    } else {
      document.getElementById("filters-content").classList.add("active");
      document.getElementById("filters-arrow").classList.add("active");
      document.getElementById("filtersBtn").classList.add("active");

      this.setState({
        openFilters: !this.state.openFilters,
      });
    }
  }

  onClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.openFilters(true);
    }
  }

  toggleFilter(target) {
    document.getElementById(`${target}-box`).classList.toggle("active-filter");
    document
      .getElementById(`${target}-filter-arrow`)
      .classList.toggle("active-arrow");
  }

  onChangeFilter(e, infos, key) {
    let selectedItem = this.state.response[key] || [];
    let showedItem = this.state.showedItem[key] || [];

    let labelIndex = selectedItem.indexOf(infos.value);

    let datasources = this.props.filters.find(
      (elm) => elm.key === "datasources"
    );

    if (infos.value === "All data sources") {
      if (selectedItem.length !== datasources.filterItems.length) {
        selectedItem = [];
        showedItem = [];

        datasources.filterItems.forEach((elm) => {
          selectedItem.push(elm.value);
          showedItem.push(elm.label);
        });

        this.setState({
          checked: true,
        });
      } else {
        selectedItem = [];
        showedItem = [];

        this.setState({
          checked: false,
        });
      }
    } else {
      if (labelIndex === -1) {
        selectedItem.push(infos.value);
        showedItem.push(infos.label);
      } else {
        selectedItem.splice(labelIndex, 1);
        showedItem.splice(labelIndex, 1);
      }

      if (key === "datasources") {
        this.setState({
          checked: false,
        });
      }
    }

    const response = {
      ...this.state.response,
      [key]: selectedItem,
    };

    const finalShowedItem = {
      ...this.state.showedItem,
      [key]: showedItem,
    };

    let filtersSource = [];

    for (const key in finalShowedItem) {
      filtersSource.push(key);
    }

    const toShow = [];

    filtersSource.forEach((key) => {
      if (finalShowedItem[key].length > 0) {
        toShow.push({
          key: key,
          list: finalShowedItem[key].join(", "),
        });
      }
    });

    this.setState({
      toShow: toShow,
      response: response,
      showedItem: finalShowedItem,
    });

    this.props.onChange(
      { ...this.state.response, [key]: selectedItem },
      finalShowedItem
    );
  }

  resetAllFilters() {
    let response = this.state.response;

    for (const key in response) {
      response[key] = [];
    }

    this.setState({
      response: {},
      toShow: [],
      showedItem: {},
      checked: false,
    });

    this.props.onChange({ ...response });
  }

  resetFilter(key) {
    let response = this.state.response;
    let toShow = this.state.toShow;
    let showedItem = this.state.showedItem;

    response[key] = [];
    toShow.splice(toShow.indexOf(toShow.find((elm) => elm.key === key)), 1);
    delete showedItem[key];

    this.setState({
      response: response,
      toShow: toShow,
      showedItem: showedItem,
      checked: key === "datasources" ? false : this.state.checked,
    });

    this.props.onChange({ ...response });
  }

  render() {
    return (
      <div id="projectFilters" ref={this.wrapperRef} style={this.props.style}>
        <button id="filtersBtn" onClick={() => this.openFilters()}>
          <img id="filters-icon" src={FiltersIcon} alt="filters's icon" />
          Filters
          <img id="filters-arrow" src={FiltersArrow} alt="filter's arrow" />
        </button>

        <div id="filtersView">
          {this.state.toShow.map((filters, i) => {
            return (
              <div className="tag" key={i}>
                <p>
                  {filters.key === "datasources"
                    ? this.state.checked === true
                      ? "All data sources"
                      : filters.list
                    : filters.list}
                </p>
                <img
                  style={{ marginLeft: "6px" }}
                  src={CloseBtn}
                  alt="close button"
                  onClick={() => this.resetFilter(filters.key)}
                />
              </div>
            );
          })}
          {this.state.toShow.length > 0 && (
            <button onClick={() => this.resetAllFilters()}>Clear all</button>
          )}
        </div>

        <div id="filters-content">
          <div id="filters-box">
            {this.props.filters.map((filters, i) => {
              let skipAllSelect =
                this.props.filters.find((elm) => elm.key === "datasources")
                  ?.filterItems.length <= 1
                  ? true
                  : false;

              if (filters.key === "datasources" && skipAllSelect) {
                // ne s'affiche pas
              } else if (filters.filterItems.length > 1) {
                return (
                  <div
                    id={`${filters.key.split(" ").join("-")}-box`}
                    className={`filter active-filter`}
                    key={i}
                  >
                    <div
                      id="clickArea"
                      onClick={() =>
                        this.toggleFilter(filters.key.split(" ").join("-"))
                      }
                    ></div>
                    <h3 id={`${filters.key.split(" ").join("-")}-title`}>
                      <span>
                        {filters.title}{" "}
                        {this.state.response[filters.key] !== undefined ? (
                          this.state.response[filters.key].length !== 0 ? (
                            <span>
                              ({this.state.response[filters.key].length})
                            </span>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </span>{" "}
                      <img
                        id={`${filters.key.split(" ").join("-")}-filter-arrow`}
                        src={DatasourceFiltersArrow}
                        alt="filter's arrow"
                        className=" active-arrow"
                      />
                    </h3>

                    <div className="filters">
                      <FormGroup>
                        {filters.filterItems.map((filter, i) => {
                          if (filters.key === "datasources" && i === 0) {
                            return (
                              <Fragment key={i}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      onChange={(e) =>
                                        this.onChangeFilter(
                                          e,
                                          {
                                            label: "All data sources",
                                            value: "All data sources",
                                          },
                                          filters.key
                                        )
                                      }
                                      sx={{
                                        color: "#306ed6",
                                        "&.Mui-checked": {
                                          color: "#306ed6",
                                        },
                                      }}
                                    />
                                  }
                                  label={"All data sources"}
                                  checked={this.state.checked ? true : false}
                                />

                                <FormControlLabel
                                  control={
                                    <Fragment>
                                      <Checkbox
                                        onChange={(e) =>
                                          this.onChangeFilter(
                                            e,
                                            {
                                              label: filter.label,
                                              value: filter.value,
                                            },
                                            filters.key
                                          )
                                        }
                                        sx={{
                                          color: "#306ed6",
                                          "&.Mui-checked": {
                                            color: "#306ed6",
                                          },
                                        }}
                                        checked={
                                          this.state.response[
                                            filters.key
                                          ]?.indexOf(filter.value) > -1
                                            ? true
                                            : false
                                        }
                                      />
                                      <img
                                        loading="lazy"
                                        width="20"
                                        src={
                                          process.env.REACT_APP_API_URL +
                                          filter.icon
                                        }
                                        alt={`test`}
                                      />
                                    </Fragment>
                                  }
                                  label={filter.label}
                                />
                              </Fragment>
                            );
                          }
                          if (filters.key === "languages") {
                            return (
                              <FormControlLabel
                                key={i}
                                control={
                                  <Fragment>
                                    <Checkbox
                                      onChange={(e) =>
                                        this.onChangeFilter(
                                          e,
                                          {
                                            label: filter.label,
                                            value: filter.value,
                                          },
                                          filters.key
                                        )
                                      }
                                      sx={{
                                        color: "#306ed6",
                                        "&.Mui-checked": {
                                          color: "#306ed6",
                                        },
                                      }}
                                      checked={
                                        this.state.response[
                                          filters.key
                                        ]?.indexOf(filter.value) > -1
                                          ? true
                                          : false
                                      }
                                    />
                                    {filter.icon && (
                                      <img
                                        loading="lazy"
                                        width="20"
                                        src={
                                          process.env.REACT_APP_API_URL +
                                          filter.icon
                                        }
                                        alt={`test`}
                                      />
                                    )}
                                  </Fragment>
                                }
                                label={filter.label}
                              />
                            );
                          } else {
                            return (
                              <FormControlLabel
                                key={i}
                                control={
                                  <Fragment>
                                    <Checkbox
                                      onChange={(e) =>
                                        this.onChangeFilter(
                                          e,
                                          {
                                            label: filter.ValueLabel
                                              ? filter.ValueLabel
                                              : filter.label,
                                            value: filter.Value
                                              ? filter.Value
                                              : filter.value,
                                          },
                                          filters.key
                                        )
                                      }
                                      sx={{
                                        color: "#306ed6",
                                        "&.Mui-checked": {
                                          color: "#306ed6",
                                        },
                                      }}
                                      checked={
                                        this.state.response[
                                          filters.key
                                        ]?.indexOf(
                                          filter.Value
                                            ? filter.Value
                                            : filter.value
                                        ) > -1
                                          ? true
                                          : false
                                      }
                                    />
                                    {filter.icon && (
                                      <img
                                        loading="lazy"
                                        width="20"
                                        src={
                                          process.env.REACT_APP_API_URL +
                                          filter.icon
                                        }
                                        alt={`logo`}
                                      />
                                    )}
                                  </Fragment>
                                }
                                label={
                                  filter.ValueLabel
                                    ? filter.ValueLabel
                                    : filter.label
                                }
                              />
                            );
                          }
                        })}
                      </FormGroup>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectFilters;
