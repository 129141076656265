import React, { Fragment } from 'react';
import './LoadingView.css';

import Loader from '../../components/loader/Loader';
import { Helmet } from "react-helmet";
import Header from '../../components/header/Header';
import SettingsMenu from '../settings/elements/SettingsMenu';
import Footer from '../../components/footer/Footer';

class SettingsLoadingView extends React.Component {

    render () {
        return (
            <Fragment>
                <Helmet>
                    <title>{this.props.title}</title>
                </Helmet>
                {this.props.withHeader && <Header {...this.props.headerProps} />}
                <div className="padding-top-30" />
                <div className="central-content settings-content">
                    <div className="left">
                        <SettingsMenu  {...this.props.settingsMenuProps} />
                    </div>
                    <div className="right">
                        <div className="padding-top-32" />
                        <div className="font-inter-700 font-size-28 color-000 padding-bottom-40">
                            {this.props.rightTitle}
                        </div>
                        <div className={`content-loading-view ${this.props.withHeader ? 'with-header' : ''}`}>
                            <Loader />
                        </div>
                    </div>
                </div>
                <div className="padding-top-60" />
                <Footer />
            </Fragment>
        );
    }
}

export default SettingsLoadingView;