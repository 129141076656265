import React, { useEffect, useState } from "react";
import SignupHelper from "../../../app/helpers/SignupHelper";
import HttpHelper from "../../../app/helpers/HttpHelper";
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import sensia_logo from "../../../res/images/logo/logo_sensia.svg";
import signinImg from "../../../res/images/illustrations/illus-signin.svg";
import signupImg from "../../../res/images/illustrations/illus-signup.png";
import { googleLogout } from "@react-oauth/google";
import { userService } from "../../../app/services";
import { ROUTES } from "../../../app/routing";
import { MicrosoftSigninButton } from "./MicrosoftSigninButton";
import { Trans, useTranslation } from "react-i18next";
import StringHelper from "../../../app/helpers/StringHelper";
import UserHelper from "../../../app/helpers/UserHelper";
import Link from "../../../components/Link";
import { userActions } from "../../../app/actions";
import Loader from "../../../components/loader/Loader";
import { GoogleSigninButton } from "./GoogleSigninButton";
import IntercomHelper from "../../../app/helpers/IntercomHelper";

const LoginView = (props) => {
  const { t } = useTranslation();
  const isNonMobileScreen = useMediaQuery("(min-width:1000px)");
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const currentUser = SignupHelper.getUser();
  const urlParameters = HttpHelper.getUrlParameters(props.location.search);

  var googleToken = urlParameters.googleToken;
  var microsoftToken = null;

  var urlHash = props.location.hash;

  if (urlHash && urlHash.includes("#id_token=")) {
    // Supprime le texte '#id_token='
    microsoftToken = urlHash.substring(10);
  }

  const defaultStep =
    urlParameters.step || googleToken || microsoftToken ? "SIGNUP" : "SIGNIN";

  const [isLoading, setIsLoading] = useState(false);
  const [invitation_account, setInvitationAccount] = useState(null);
  const [email, setEmail] = useState(null);
  const [emailErrorMessage, setEmailErrorMessage] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState(null);
  const [token, setToken] = useState(
    urlParameters.token || currentUser.invitation_token
  );
  const [step, setStep] = useState(defaultStep); // 'SIGNUP' or 'SIGNIN' or 'VERIFY or 'DELETED_ACCOUNT'

  const [googleorMsalAuthErrorMessage, setGoogleOrMsalAuthErrorMessage] =
    useState(null);

  const isSignIn = step == "SIGNIN";
  const isVerify = step == "VERIFY";
  const isDeletedAccount = step == "DELETED_ACCOUNT";

  useEffect(() => {
    let search = "?step=";
    search = search + step;

    if (token) {
      search = search + "&token=" + token;
    }

    props.history.replace({
      pathname: ROUTES.LOGIN,
      search: search,
    });
  }, [step, token]);

  useEffect(() => {
    if (token) {
      userService.checkTokenInvitation(
        token,
        (response) => {
          setInvitationAccount(response.account);
          console.log("Invitation account", response.account);
        },
        (error) => {
          setToken(null);
          console.log(error);
        }
      );
    }
  }, [token]);

  useEffect(() => {
    if (googleToken) {
      const credential = { jwt_token: googleToken };
      handleSubmitGoogleAuth(credential);
    }
    googleToken = null;
  }, [googleToken]);

  useEffect(() => {
    if (microsoftToken) {
      handleSubmitMicrosoftAuth(microsoftToken);
    }
    microsoftToken = null;
  }, [microsoftToken]);

  const submitSignup = (formData) => {
    userService.signup(
      formData,
      (response) => {
        setIsLoading(false);
        UserHelper.setUser(response.user);
        SignupHelper.updateUser(response.user);
        if (response.is_google_or_msal_login) {
          if (response.account) {
            UserHelper.updateCurrentAccount(response.account);
            props.history.push(ROUTES.HOME);
          } else if (
            !response.account &&
            (response.is_create_account_completed || token)
          ) {
            setStep("DELETED_ACCOUNT");
          } else {
            props.history.push(ROUTES.CREATE_ACCOUNT);
          }
        } else {
          setStep("VERIFY");
        }
      },
      (error) => {
        googleLogout();
        setGoogleOrMsalAuthErrorMessage(error.message);
        setIsLoading(false);
      }
    );
  };

  const handleSubmitGoogleAuth = (credentialResponse) => {
    setIsLoading(true);
    const formData = {
      invitation_token: token,
      google_credential: credentialResponse,
    };
    submitSignup(formData);
  };

  const handleSubmitMicrosoftAuth = (msalTokenId) => {
    setIsLoading(true);
    const formData = {
      invitation_token: token,
      msal_token_id: msalTokenId,
    };
    submitSignup(formData);
  };

  const handleSubmitEmail = () => {
    if (!StringHelper.isEmailValid(email)) {
      setEmailErrorMessage(t("Please enter a valid email"));
      return;
    }
    const formData = {
      invitation_token: token,
      email: email,
    };

    setIsLoading(true);
    if (isSignIn) {
      formData.password = password;
      userService.login(
        formData,
        (response) => {
          console.log("Login response.account", response.account);
          console.log(
            "Login response.is_create_account_completed",
            response.is_create_account_completed
          );
          setIsLoading(false);
          SignupHelper.updateUser(response.user);
          if (response.account) {
            UserHelper.updateCurrentAccount(response.account);
            props.history.push(ROUTES.HOME);
          } else if (
            !response.account &&
            (response.is_create_account_completed || token)
          ) {
            setStep("DELETED_ACCOUNT");
          } else {
            console.log("push to create new account");
            props.history.push(ROUTES.CREATE_ACCOUNT);
          }
        },
        (error) => {
          console.log("response error", error);
          setIsLoading(false);
          setPasswordErrorMessage(error.message);
        }
      );
    } else {
      userService.signup(
        formData,
        (response) => {
          setIsLoading(false);
          setStep("VERIFY");
          IntercomHelper.updateIntercomUser(response.user, true);
        },
        (error) => {
          setEmailErrorMessage(error.message);
          setIsLoading(false);
        }
      );
    }
  };

  const leftCentralContentSignup = (
    <Box>
      <Typography
        sx={{
          color: "#0E004B",
          fontFamily: "Inter",
          fontSize: "24px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "normal",
        }}
      >
        {invitation_account && (
          <p>
            Join{" "}
            <span style={{ color: "#30BEEC" }}>{invitation_account.title}</span>{" "}
            on Sensia!
          </p>
        )}
        {!invitation_account && isSignIn && <p>Welcome back!</p>}
        {!invitation_account && !isSignIn && (
          <p>
            Start using <span style={{ color: "#30BEEC" }}>Sensia</span> today!
          </p>
        )}
      </Typography>
      <Typography
        sx={{
          color: "#666D80",
          fontFamily: "Inter",
          fontSize: "15px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "22px",
        }}
      >
        {!isSignIn && t("Start Transforming Reviews into Strategic Assets")}
        {isSignIn && t("Hi there! We're overjoyed to welcome you back!")}
      </Typography>
      <Box
        sx={{
          mt: "52px",
          display: "flex",
          gap: "10px",
          flexWrap: isSmallScreen ? "wrap" : "nowrap",
          justifyContent: "center",
        }}
      >
        <GoogleSigninButton
          title={isSignIn ? "Sign in with Google" : "Sign up with Google"}
          onSuccess={(credentialResponse) => {
            handleSubmitGoogleAuth(credentialResponse);
          }}
          onError={(errorResponse) => {
            setGoogleOrMsalAuthErrorMessage(errorResponse.error);
          }}
        />

        <MicrosoftSigninButton
          title={isSignIn ? "Sign in with Microsoft" : "Sign up with Microsoft"}
          handleResponse={(response) => handleSubmitMicrosoftAuth(response)}
        />
      </Box>
      {googleorMsalAuthErrorMessage && (
        <Typography
          sx={{
            mt: "10px",
            color: "#e04f77",
            fontSize: "12px",
            width: "100%",
            textAlign: "center",
          }}
        >
          {googleorMsalAuthErrorMessage}
        </Typography>
      )}
      <Divider sx={{ mt: "25px", mb: "25px" }}>
        <Typography
          sx={{
            color: "#000",

            fontFamily: "Inter",
            fontSize: "15px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "22px",
          }}
        >
          {t("Or continue with")}
        </Typography>
      </Divider>
      <TextField
        error={emailErrorMessage}
        size="medium"
        type="email"
        label={t("Email")}
        name="email"
        required={true}
        disabled={isLoading}
        placeholder={t("Enter your work email")}
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
          setEmailErrorMessage(null);
        }}
        helperText={emailErrorMessage}
        sx={{ height: "50px", width: "100%" }}
      />
      {isSignIn && (
        <TextField
          error={passwordErrorMessage}
          size="medium"
          type="password"
          label={t("Password")}
          name="password"
          required={true}
          disabled={isLoading}
          placeholder={t("Enter your password")}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            setEmailErrorMessage(null);
          }}
          helperText={passwordErrorMessage}
          sx={{
            mt: emailErrorMessage ? "35px" : "25px",
            height: "50px",
            width: "100%",
          }}
        />
      )}

      <Box sx={{ mt: "25px", display: "flex", justifyContent: "flex-end" }}>
        {isLoading && (
          <Box sx={{ width: "20px", padding: "14px 52px" }}>
            <Loader />
          </Box>
        )}
        {!isLoading && (
          <Button
            variant="contained"
            disabled={isLoading || !email || (isSignIn && !password)}
            onClick={() => handleSubmitEmail()}
            sx={{
              background: "#306ED6",
              textTransform: "none",
              padding: "14px 52px",
            }}
          >
            <Typography
              sx={{
                color: "#FFF",
                fontFamily: "Inter",
                fontSize: "15px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
              }}
            >
              {isSignIn ? t("Sign in") : t("Sign up")}
            </Typography>
          </Button>
        )}
      </Box>
      {!isSignIn && (
        <Box
          sx={{
            mt: "46px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography>
            {t("Already have an account?")}{" "}
            <span
              style={{ color: "#3A79E6", cursor: "pointer" }}
              onClick={() => {
                setStep("SIGNIN");
              }}
            >
              {t("Sign in")}
            </span>
          </Typography>
        </Box>
      )}
      {isSignIn && (
        <Box
          sx={{
            mt: "46px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Link style={{ textDecoration: "none" }} to={ROUTES.FORGOT_PASSWORD}>
            <Typography
              sx={{
                color: "#3A79E6",
                cursor: "pointer",
                mb: "10px",
              }}
            >
              {t("Forgot your password?")}
            </Typography>
          </Link>

          <Typography>
            {t("Don’t have an account yet?")}{" "}
            <span
              style={{ color: "#3A79E6", cursor: "pointer" }}
              onClick={() => {
                setStep("SIGNUP");
              }}
            >
              {t("Sign up")}
            </span>
          </Typography>
        </Box>
      )}
      <Box sx={{ width: "330px" }} />
      <Box sx={{ width: "330px" }} />
    </Box>
  );

  const leftCentralContentVerify = (
    <Box sx={{ height: "449px" }}>
      <Typography
        sx={{
          color: "#0E004B",
          fontFamily: "Inter",
          fontSize: "24px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "normal",
          textAlign: "center",
        }}
      >
        {t("Great, please verify your email")}
      </Typography>
      <Typography
        sx={{
          mt: "16px",
          color: "#666D80",
          fontFamily: "Inter",
          fontSize: "15px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "22px",
          textAlign: "center",
        }}
      >
        {t(
          "We emailed you a link to verify your email address and create your password."
        )}
      </Typography>
      <Typography
        sx={{
          mt: "50px",
          color: "#000",
          textAlign: "center",
          fontFamily: "Inter",
          fontSize: "15px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "22px",
        }}
      >
        <Trans i18nKey="check_spam_folder">
          Don’t forget to check your spam forder or{" "}
          <span
            style={{ color: "#3A79E6", cursor: "pointer" }}
            onClick={() => {
              props.history.push(ROUTES.FORGOT_PASSWORD);
            }}
          >
            {t("click here")}
          </span>
        </Trans>
      </Typography>

      <Box
        sx={{
          display: "flex",
          gap: "10px",
          flexWrap: isSmallScreen ? "wrap" : "nowrap",
        }}
      >
        <Box sx={{ width: "240px" }} />
        <Box sx={{ width: "240px" }} />
      </Box>
    </Box>
  );

  const leftCentralContentDeletedAccount = (
    <Box sx={{ height: "449px" }}>
      <Typography
        sx={{
          color: "#000",
          fontFamily: "Inter",
          fontSize: "28px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "normal",
        }}
      >
        {t("You Have No Longer Access To Any Account On Sensia.")}
      </Typography>
      <Typography
        sx={{
          mt: "10px",
          color: "#000",
          fontFamily: "Inter",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "22px",
        }}
      >
        {t(
          "It seems your account has been deleted or deactivated by a team admin."
        )}
      </Typography>
      <Typography
        sx={{
          mt: "50px",
          color: "#000",
          fontFamily: "Inter",
          fontSize: "20px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "22px",
        }}
      >
        {t("What to Do next ? ")}
      </Typography>
      <Typography
        sx={{
          mt: "10px",
          color: "#000",
          fontFamily: "Inter",
          fontFeatureSettings: "'clig' off, 'liga' off",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "22px",
        }}
      >
        {t("To continue using Sensia, you can create a new account.")}
      </Typography>
      <Box sx={{ mt: "50px", display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          onClick={() => props.history.push(ROUTES.CREATE_ACCOUNT)}
          sx={{
            background: "#306ED6",
            textTransform: "none",
            padding: "14px 52px",
          }}
        >
          <Typography
            sx={{
              color: "#FFF",
              fontFamily: "Inter",
              fontSize: "15px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
            }}
          >
            {t("Create an account")}
          </Typography>
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          flexWrap: isSmallScreen ? "wrap" : "nowrap",
        }}
      >
        <Box sx={{ width: "240px" }} />
        <Box sx={{ width: "240px" }} />
      </Box>
    </Box>
  );

  const leftCentralContent = () => {
    if (isVerify) {
      return leftCentralContentVerify;
    } else if (isDeletedAccount) {
      return leftCentralContentDeletedAccount;
    }
    return leftCentralContentSignup;
  };

  const leftBottomContent = () => {
    if (isSignIn || isVerify || isDeletedAccount) {
      return null;
    }
    return (
      <Box
        sx={{
          width: "100%",
          pb: "48px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            color: "#7F7F7F",
            textAlign: "center",
            fontFamily: "Inter",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
          }}
        >
          {t("By sign up you agree to our")}{" "}
          <a
            href={ROUTES.TERMS_OF_SERVICE}
            className="link-blue font-inter-500"
          >
            {t("Terms of Service")}
          </a>{" "}
          and{" "}
          <a href={ROUTES.PRIVACY_POLICY} className="link-blue font-inter-500">
            {t("Privacy policy")}
          </a>{" "}
          .
        </Typography>
      </Box>
    );
  };

  const leftSide = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        height: "100%",
        pl: "10px",
        pr: "10px",
      }}
    >
      <Grid
        container
        direction="column"
        spacing={2}
        sx={{
          width: "min-content",
        }}
      >
        <Grid item sx={{ height: "12%" }}>
          <Box sx={{ pt: "30px" }}>
            <img src={sensia_logo} alt="logo sensia" width="126px" />
          </Box>
        </Grid>
        <Grid
          item
          sx={{
            height: isSignIn ? "min-content" : "75%",
            display: "flex",
            alignItems: "flex-start",
            pt: "100px",
          }}
        >
          {leftCentralContent()}
        </Grid>
        <Grid item sx={{ height: "10%" }}>
          {/* Contenu de la partie inférieure */}
          {leftBottomContent()}
        </Grid>
      </Grid>
    </Box>
  );

  const rightSide = () => {
    return (
      <Box
        sx={{
          height: "100%",
          backgroundImage: isSignIn ? `url("${signinImg}")` : null,
          backgroundRepeat: "no-repeat",
          backgroundPositionX: "right",
          backgroundSize: "cover",
          backgroundColor: isSignIn ? null : "#1D1393",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {!isSignIn && (
          <Box sx={{ p: "70px" }}>
            <img src={signupImg} alt="SignUp image" width="100%" />
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Grid
      container
      spacing={0}
      sx={{ position: "absolute", height: "100%", overflowY: "scroll" }}
    >
      {isNonMobileScreen ? (
        <>
          <Grid item xs={7}>
            {leftSide}
          </Grid>
          <Grid item xs={5}>
            {rightSide()}
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          {leftSide}
        </Grid>
      )}
    </Grid>
  );
};

export default LoginView;
