import '../plan.css';
import './upgradePlanView.css';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PlanHeader from '../elements/PlanHeader';
import { format } from 'date-fns';
import ButtonField from '../../../components/form/field/ButtonField';
import StringHelper from '../../../app/helpers/StringHelper';


class UpgradePlanStep1 extends React.Component {
    render() {
        const { t, pageTitle, history, targetPlan, selectedFreq, currentPlan, nextRenewalDate, account, paymentIcon, last4str, purchaseLoading, currencyCode } = this.props;
        const language = navigator.language
        const planName = `${targetPlan} ${t('plan')}`;
        return (
            <Fragment>
                <Helmet>
                    <title>{pageTitle}</title>
                </Helmet>
                <PlanHeader history={history} title={t('Upgrade to ')} boldTitle={t('{{plan}} plan', { plan: targetPlan })} />
                <div className="purchase-plan central-content">
                    <div className="content-column">
                        <div className="column-percent-33 responsive-900 text-align-right padding-top-30 float-right">
                            <div className="purchase-plan-info-card">
                                <div className="upgrade-plan-info-box-selector">
                                    <div className="font-size-15 line-height-1 color-fff font-inter-700">{planName}</div>
                                    <div className="font-size-13 line-height-1-2 color-f2f2f2 font-inter-500 margin-top-2">
                                        {selectedFreq === 'Monthly' ? t('Billed monthly') : t('Billed yearly')}
                                    </div>
                                </div>
                                <div className="upgrade-plan-info-box-price">
                                    <div className="content-column">
                                        <div className="column-percent-55">
                                            <div className="font-size-13 line-height-1-5 color-7f7f7f font-inter-500 text-break-line">
                                                {planName} - {selectedFreq === 'Monthly' ? t('1 month') : t('1 year')}
                                            </div>
                                        </div>
                                        <div className="column-percent-45">
                                            <div className="font-size-15 color-7f7f7f font-inter-700 text-align-right">
                                                {new Intl.NumberFormat(language, { style: 'currency', currency: currencyCode }).format(currentPlan.priceHT)}
                                            </div>
                                        </div>
                                    </div>
                                    {(currentPlan.credit != 0) &&
                                        <Fragment>
                                            <div className="content-column margin-top-16">
                                                <div className="column-percent-50">
                                                    <div className="font-size-13 line-height-1-5 color-7f7f7f font-inter-500">
                                                        {t('Credits')}
                                                    </div>
                                                </div>
                                                <div className="column-percent-50">
                                                    <div className="font-size-15 color-7f7f7f font-inter-700 text-align-right">
                                                        {new Intl.NumberFormat(language, { style: 'currency', currency: currencyCode }).format(currentPlan.credit)}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="content-column">
                                                <div className="font-size-10 line-height-1-5 color-7f7f7f font-inter-500">
                                                    {t('Credits for {{plan}} end', { plan: targetPlan })}: {new Intl.NumberFormat(language, { style: 'currency', currency: currencyCode }).format(currentPlan.credit)}
                                                </div>
                                            </div>
                                        </Fragment>
                                    }
                                    {(currentPlan.discount !== 0) &&
                                        <Fragment>
                                            <div className="content-column margin-top-16">
                                                <div className="column-percent-50">
                                                    <div className="font-size-13 line-height-1-5 color-7f7f7f font-inter-500">
                                                        {t('Discount')}
                                                    </div>
                                                </div>
                                                <div className="column-percent-50">
                                                    <div className="font-size-15 color-7f7f7f font-inter-700 text-align-right">
                                                        {new Intl.NumberFormat(language, { style: 'currency', currency: currencyCode }).format(currentPlan.discount)}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="content-column">
                                                <div className="font-size-10 line-height-1-5 color-7f7f7f font-inter-500">
                                                    {t('Discount for {{plan}}', { plan: targetPlan })} {t(': {{discountCode}}', { discountCode: currentPlan.discountCode})}
                                                </div>
                                            </div>
                                        </Fragment>
                                    }
                                    <div className="upgrade-plan-info-box-divider-2" />
                                    <div className="content-column">
                                        <div className="column-percent-50">
                                            <div className="font-size-13 line-height-1-5 color-7f7f7f font-inter-500">{t('VAT')}</div>
                                        </div>
                                        <div className="column-percent-50">
                                            <div className="font-size-15 color-7f7f7f font-inter-700 text-align-right">
                                                {new Intl.NumberFormat(language, { style: 'currency', currency: currencyCode }).format(currentPlan.vat)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="upgrade-plan-info-box-divider" />
                                    <div className="content-column">
                                        <div className="column-percent-50">
                                            <div className="font-size-15 line-height-1-3 color-2f2f2f font-inter-700">{t('Due today')}</div>
                                        </div>
                                        <div className="column-percent-50">
                                            <div className="font-size-15 color-2f2f2f font-inter-700 text-align-right">
                                                {new Intl.NumberFormat(language, { style: 'currency', currency: currencyCode }).format(currentPlan.priceTTC)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column-percent-66 responsive-900 float-right padding-top-30">
                            <div className="plan-title">
                                {t('Your order')}
                            </div>
                            <div className='purchase-plan-title-divider' />
                            <div className="font-size-15 line-height-1-6 color-7f7f7f font-inter-500">
                                {selectedFreq === 'Monthly' ? t('Monthly purchase') : t('Yearly purchase')}
                                <br />
                                {t('Next renewal')} : {format(nextRenewalDate, "MMMM do, yyyy")}
                            </div>
                            <div className="padding-top-30" />
                            <div className="font-size-18 line-height-1-3 color-000 font-inter-500">{t('Company info')}</div>
                            <div className="font-size-15 line-height-1-6 color-7f7f7f font-inter-500 margin-top-10">
                                {account.company}
                                <br />
                                {account.adress1}
                                <br />
                                {account.adress2 && <Fragment>{account.adress2}<br /></Fragment>}
                                {account.city} {account.zip_code}
                                <br />
                                {this.props.getCountryLabelFromIsoCode(account.country)}
                                <br />
                                {t('VAT ID')}: {account.vat_id}
                            </div>
                            <div className="padding-top-30" />
                            <div className="font-size-18 line-height-1-3 color-000 font-inter-500">{t('Payment method')}</div>
                            <div className="font-size-15 line-height-1-6 color-7f7f7f font-inter-500 margin-top-10">
                                {paymentIcon}{last4str}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="padding-top-30" />
                <div className="central-content">
                    <div className="font-size-13 color-7f7f7f font-inter-500 text-align-center">
                        {t('You can cancel your plan at any time. Purchases made by credit card can’t be refunded, althrough your credit can be transferred to another account. By submitting this form, you confirm that you agree to our Terms of service and Privacy Policy.')}
                    </div>
                </div>
                <div className="padding-top-30" />
                <div className="central-content button-action text-align-center">
                    <button className="button light-blue margin-left-8 margin-right-15" onClick={this.props.goBack}>{t('Edit order')}</button>
                    <ButtonField
                        loaderPosition="right"
                        className="button blue"
                        formElementClassName="display-inline-block"
                        loading={purchaseLoading}
                        onClick={this.props.purchasePlan}>
                        {t('Purchase {{planName}}', { planName: planName })}
                    </ButtonField>
                </div>
                <div className="padding-top-80" />
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

export default withTranslation()(connect(mapStateToProps)(UpgradePlanStep1));