export const globalConstants = {
  WEBSITE_TITLE: "Sensia",
  CLIENT_URL: process.env.REACT_APP_CLIENT_URL,
  API_URL: process.env.REACT_APP_API_URL,
  SHOWCASE_URL: process.env.REACT_APP_SHOWCASE_URL,
  DEFAULT_LANGUAGE: "en",
  FRENCH_LANGUAGE: "fr",
  ENV: process.env.REACT_APP_SENSIA_ENV,
  AVAILABLE_LANGUAGES: ["en"],
  REPORT_VERSION: 1.2,
  STRIPE_API_PUBLIC_KEY: process.env.REACT_APP_STRIPE_API_PUBLIC_KEY,
  CONTACT_EMAIL: "support@sensia.ai",
  SALES_EMAIL: "support@sensia.ai",
  SALES_URL: "https://sensia.ai/contact-sales",
  GOOGLE_CLIENT_ID:
    "182352847616-0srujihlvtcbspbhr4brhhvv5503gc7b.apps.googleusercontent.com",
};
