import React from 'react';
import '../form.css';


class TextField extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            is_focused: false,
        };
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onInput = this.onInput.bind(this);
        this.renderErrorMessage = this.renderErrorMessage.bind(this);
    }

    setFocused(focused) {
        this.setState({
            is_focused: focused
        });
    }

    onFocus() {
        this.setFocused(true);
    }

    onBlur() {
        this.setFocused(false);
    }

    onInput(e) {
        if (this.props.type === 'number') {
            const newValue = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1') || 0;
            e.target.value = newValue;
        } 
    }

    renderErrorMessage(errorMessage) {
        if (errorMessage) {
            return (
                <div className="form-error font-work-sans-400 color-fd3e7b font-size-12 text-align-right">
                    {errorMessage}
                </div>
            );
        }
        return null;
    }

    render() {
        let formElementClassName = ["form-element"];
        if (this.state.is_focused) {
            formElementClassName.push('focused');
        }
        if (this.props.errorMessage) {
            formElementClassName.push('error');
        }
        if (this.props.formElementClassName) {
            formElementClassName.push(this.props.formElementClassName);
        }
        return (
            <div className={formElementClassName.join(' ')}>
                {!this.props.hideLabel && (
                    <label className="font-inter-600 font-size-15 color-969696">
                        {this.props.label}
                    </label>
                )}
                { this.props.customlabel && <div className="customLabelBis" customlabel={this.props.customlabel}></div>}
                <input
                    className={this.props.className}
                    style={this.props.style}
                    type={this.props.type}
                    disabled={this.props.disabled}
                    readOnly={this.props.readonly}
                    required={this.props.required}
                    placeholder={this.props.placeholder}
                    name={this.props.name}
                    value={this.props.value}
                    defaultValue={this.props.defaultValue}
                    onChange={this.props.onChange}
                    onInput={this.onInput}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    maxLength={this.props.maxLength}
                />
                {this.renderErrorMessage(this.props.errorMessage)}
            </div>
        );
    }
}

export default TextField;