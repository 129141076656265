import "../unconnected.css";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { globalConstants } from "../../../app/constants";
import { connect } from "react-redux";

import { withTranslation } from "react-i18next";
import { PLAN_UGRAPDE, ROUTES } from "../../../app/routing";
import HeaderUnconnected from "../../../components/header/HeaderUnconnected";
import FooterUnconnected from "../../../components/footer/FooterUnconnected";
import SignupHelper from "../../../app/helpers/SignupHelper";
import HttpHelper from "../../../app/helpers/HttpHelper";

class SignupStep3View extends React.Component {
  constructor(props) {
    super(props);
    const currentStep = SignupHelper.getCurrentStep();
    if (currentStep < 3) {
      props.history.push(ROUTES.SIGN_UP_STEP_1);
    }
    this.state = {};
    this.getStartedLink = this.getStartedLink.bind(this);
  }

  componentDidMount() {
  }

  getStartedLink() {
    const currentUser = SignupHelper.getUser();
    if (!currentUser.plan) {
      return ROUTES.HOME;
    }
    return currentUser.freq
      ? HttpHelper.addParametersToUrl(PLAN_UGRAPDE(currentUser.plan), {
          freq: currentUser.freq,
        })
      : PLAN_UGRAPDE(currentUser.plan);
  }

  render() {
    const { t } = this.props;
    const currentUser = SignupHelper.getUser();
    return (
      <Fragment>
        <Helmet>
          <title>
            {t("Check your email")} - {globalConstants.WEBSITE_TITLE}
          </title>
        </Helmet>
        <div className="unconnected-content double-percent-50">
          <div className="content-white">
            <HeaderUnconnected />
            <div className="content-max-width-590 min-height-100vh margin-auto">
              <div className="c-content-table">
                <div className="c-content-table-cell">
                  <div className="centralized-content">
                    <div className="font-inter-700 font-size-36 text-align-center color-0e004b padding-bottom-50">
                      {t("Check your email")}
                    </div>
                    <div className="text-align-center font-inter-400 font-size-20 color-000 line-height-22">
                      <div className="padding-bottom-8">
                        {t("We just sent an email to")}
                      </div>
                      <div className="font-weight-700 color-3a79e6 padding-bottom-30">
                        {currentUser.email}
                      </div>
                      <div className="padding-bottom-50">
                        {t(
                          "Please click on the link inside the email to confirm your address and activate your account."
                        )}
                      </div>
                      <div className="padding-bottom-50">
                        <Link to={this.getStartedLink()}>
                          <button
                            href=""
                            className="button font-work-sans-600 width-170 height-45"
                          >
                            {t("Get Started")}
                          </button>
                        </Link>
                      </div>
                      <div className="font-inter-500 font-size-14">
                        {t("You didn’t receive the email?")}
                        <br />
                        {t("Check your spam or")}{" "}
                        <Link to={ROUTES.FORGOT_PASSWORD} className="link-blue">
                          {t("click here")}
                        </Link>
                        .
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <FooterUnconnected />
            </div>
          </div>
          <div className="content-blue background-color-fff">
            <div className="icon email width-percent-40" />
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withTranslation()(connect(mapStateToProps)(SignupStep3View));
