import React from 'react';
import { withTranslation } from 'react-i18next';
import AjaxKeywordTooltipContent from '../../../components/tooltip/AjaxKeywordTooltipContent';
import { debounce } from "lodash";


class HighlightedSelectionTooltip extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_loaded: false
        }
        this._maxSelectionLength = 50;
        this.updatePosition = this.updatePosition.bind(this);
        this.getTooltipContent = this.getTooltipContent.bind(this);
        this.checkViewportPosition = this.checkViewportPosition.bind(this);
        this.onResize = debounce(this.checkViewportPosition.bind(this), 300);
        this.selection = null;
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify({ keyword: prevProps.keyword, projectId: prevProps.projectId }) != JSON.stringify({ keyword: this.props.keyword, projectId: this.props.projectId })) {
            this.updatePosition();
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    updatePosition() {
        let element = document.getElementById('selection-tooltip');
        if (this.props.keyword && window.getSelection() && this.props.keyword.length < this._maxSelectionLength) {
            this.selection = window.getSelection().getRangeAt(0);
            element.style.display = 'block'; 
            this.checkViewportPosition();
        } else {
            element.style.display = 'none';
            this.selection = null;
        }
    }

    checkViewportPosition() {
        let element = document.getElementById('selection-tooltip');
        if (element.style.display === 'none' || !this.selection) {
            return false;
        }
        let r = this.selection.getBoundingClientRect();
        let relative = document.body.parentNode.getBoundingClientRect();
        element.style.removeProperty('left');
        element.style.top = (r.bottom - relative.top + 2) + 'px';//this will place ele below the selection
        element.style.right = -(r.right - relative.right) + 'px';//this will align the right edges together
        const elementHeight = element.offsetHeight;
        const bounding = element.getBoundingClientRect();
        const isFullyVisible = (
            bounding.top >= 0 &&
            bounding.left >= 0 &&
            bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
        if (isFullyVisible) {
            return false;
        }
        if (bounding.left < 0) {
            // Left side is out of viewoprt
            element.style.removeProperty('right');
            element.style.left = '-80px'; // Align to left
        } else if (bounding.right > (window.innerWidth || document.documentElement.clientWidth)) {
            // Right side is out of viewport
            element.style.right = '90px'; // Align to right
        }
        if (bounding.top < 0) {
            // Top is out of viewport
            // I can do nothing about that
        } else if ((bounding.bottom) > (window.innerHeight || document.documentElement.clientHeight)) {
            // Bottom is out of viewport
            if (r) {
                element.style.top = (r.top - elementHeight - 5) + 'px'; // Align to to of element
            }
        }
    }

    getTooltipContent() {
        const projectId = this.props.projectId;
        const keyword = this.props.keyword;
        if (!projectId || !keyword) {
            return null;
        }
        return (
            <div className="base-tooltip">
                <AjaxKeywordTooltipContent projectId={projectId} keyword={keyword} onOpenUpdateKeywordClassModal={this.props.onOpenUpdateKeywordClassModal} onIsLoaded={this.checkViewportPosition} />
            </div>
        );
    }

    render() {
        if (!this.props.keyword || this.props.keyword == '' || this.props.keyword.length > this._maxSelectionLength) {
            return (
                <div id="selection-tooltip"></div>
            );
        }
        return (
            <div id="selection-tooltip">
                {this.getTooltipContent()}
            </div>
        );
    }
}

export default withTranslation()(HighlightedSelectionTooltip);