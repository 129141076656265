import '../plan.css';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { getAccountPlan, getAdvantagesByPlan, globalConstants } from "../../../app/constants";
import { connect } from 'react-redux';
import LoadingView from '../../loading/LoadingView';
import { withTranslation, Trans } from "react-i18next";
import PlanHeader from '../elements/PlanHeader';
import { SessionStorageService } from '../../../app/services/storage.service';
import { accountService } from '../../../app/services/account.service';
import UserHelper from '../../../app/helpers/UserHelper';
import HttpHelper from '../../../app/helpers/HttpHelper';
import Check from "../../../res/images/icons/check.svg"
import { ROUTES } from '../../../app/routing';


class ContactYourAdminView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            inlineLoading: false,
            account: null,
            currentPlan: null
        };

        this.fetchInitial = this.fetchInitial.bind(this);
        this.getDisplayedAdvantages = this.getDisplayedAdvantages.bind(this);

        const referrer = (props.location.state && props.location.state.referrer) ? props.location.state.referrer : null;
        if (!SessionStorageService.get('update_plan_referrer') && referrer) {
            SessionStorageService.set('update_plan_referrer', referrer)
        }
    }

    componentDidMount() {
        this._ismounted = true;
        this.fetchInitial();
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    fetchInitial() {
        const isAdmin = UserHelper.isAdmin();
        if (isAdmin) {
            this.props.history.push(ROUTES.PLAN_CHOOSE);
        }
        accountService.getAccountData(
            (response) => {
                const currentPlan = getAccountPlan(response.plan_key)
                this.setState({
                    isLoaded: true,
                    inlineLoading: false,
                    account: response,
                    currentPlan: currentPlan
                });
            },
            (error) => {
                if(!this._ismounted){
                    return;
                }
                this.setState({
                    isLoaded: true,
                    inlineLoading: false
                });
                this.props.addToast(HttpHelper.getErrorMessage(error), { type: 'error', autoDismiss: true });
            }
        );
    }

    getDisplayedAdvantages() {
        const { t } = this.props;
        const advantages = getAdvantagesByPlan(t, this.state.currentPlan);
        if (!advantages) {
            return null;
        }
        let list = [];
        for (const advantageGroup of advantages.advantages) {
            for (const advantage of advantageGroup.advantage) {
                list.push(advantage);
            }
        }
        if (list.length === 0) {
            return null;
        }
        return (
            <Fragment>
                <div className="content-raw-advantages">
                    {list.map((adv, i) => {
                        return (
                            <div key={`list-adv-${i}`} className="raw-advantage">
                                <img className="icon-check" src={Check} alt="" />
                                <div className="advantage-lst">{adv}</div>
                            </div>
                        )
                    })}
                </div>
                <div className="content padding-top-5" style={{marginLeft: '22px'}}>
                    <a className="link-blue font-size-14 font-weight-500" rel="noreferrer" target={'_blank'} href={`${globalConstants.SHOWCASE_URL}/pricing`}>{t('View more plans')}</a>
                </div>
            </Fragment>
        );
    }

    render() {
        const { t } = this.props;
        let page_title = `${t('Contact your administrator')} - ${globalConstants.WEBSITE_TITLE}`;
        if (this.state.isLoaded) {
            const contactLink = `mailto:${
              globalConstants.CONTACT_EMAIL
            }?subject=${t("Sensia - upgrade plan")}`;
            return (
              <Fragment>
                <Helmet>
                  <title>{page_title}</title>
                </Helmet>
                <PlanHeader history={this.props.history} />
                <div className="central-content-550">
                  <div className="padding-top-40" />
                  <div className="font-size-24 font-weight-500 padding-bottom-20">
                    <Trans i18nKey="your_sensia_workspace_is_on_the_x_plan">
                      Your Sensia workspace is on the{" "}
                      <span className="font-weight-700">
                        {this.state.currentPlan}
                      </span>{" "}
                      plan
                    </Trans>
                  </div>
                  <div className="font-size-15 line-height-1-5 font-weight-500 padding-bottom-20 color-7f7f7f">
                    <Trans i18nKey="on_the_x_plan_you_can">
                      On the{" "}
                      <span className="font-weight-700">
                        {this.state.currentPlan}
                      </span>{" "}
                      plan, you can…
                    </Trans>
                  </div>
                  {this.getDisplayedAdvantages()}
                  <div className="padding-top-30" />
                  <div className="font-size-15 line-height-1-5 font-weight-500 color-7f7f7f">
                    <Trans i18nKey="you_need_specia_privileges_to_change_the_subscription_please_contact_your_administrator">
                      You need special privileges to change the subscription.
                      Please reach out to the{" "}
                      <a href={contactLink} className="link-blue">
                        Sensia team
                      </a>{" "}
                      with any questions.
                    </Trans>
                  </div>
                  <div className="padding-top-20" />
                </div>
              </Fragment>
            );
        }
        return <LoadingView title={page_title} />;
    }
}

function mapStateToProps(state) {
    return {};
}

export default withTranslation()(connect(mapStateToProps)(ContactYourAdminView));