import React from 'react'
import PropTypes from 'prop-types'
import { Overlay, Popover } from 'react-bootstrap'
import './tooltip.css';

/*
* Usage:
* <TooltipWithAction
*    content={<div>Holy guacamole! I'm Sticky.</div>}
*    placement="top"
*    onMouseEnter={() => { }}
*    delay={200}
* >
*   <div>Show the sticky tooltip</div>
* </PopoverStickOnHover>
*/

export default class TooltipWithAction extends React.Component {
  constructor(props) {
    super(props)

    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
    this.closeTooltip = this.closeTooltip.bind(this)
    this.updateStayOpened = this.updateStayOpened.bind(this)
    this.onClickOutside = this.onClickOutside.bind(this);
    this.state = {
      showPopover: props.show || false,
      stayOpened: props.stayOpened || false
    }
    this._child = React.createRef();
    this._content = React.createRef();
  }

  handleMouseEnter() {
    const { delay, onMouseEnter } = this.props

    this.setTimeoutConst = setTimeout(() => {
      this.setState({ showPopover: true }, () => {
        if (onMouseEnter) {
          onMouseEnter()
        }
      })
    }, delay);
  }

  closeTooltip() {
    if (!this.state.stayOpened) {
      clearTimeout(this.setTimeoutConst)
      this.setState({ showPopover: false })
    }
  }

  updateStayOpened(stayOpened, showPopover) {
    if (typeof showPopover !== 'undefined') {
      this.setState({
        stayOpened: stayOpened,
        showPopover: showPopover
      })
    } else {
      this.setState({ stayOpened: stayOpened })
    }

  }

  componentDidMount() {
    this._ismounted = true;
    window.addEventListener("mousedown", this.onClickOutside);
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  handleMouseLeave() {
    this.setTimeoutLeaveConst = setTimeout(() => {
      this.closeTooltip();
    }, 70);
  }

  componentWillUnmount() {
    if (this.setTimeoutConst) {
      clearTimeout(this.setTimeoutConst);
    }

    if (this.setTimeoutLeaveConst) {
      clearTimeout(this.setTimeoutLeaveConst);
    }
    document.removeEventListener('mousedown', this.onClickOutside);
  }

  onClickOutside(event) {
    if (this._content && this._content.current && !this._content.current.contains(event.target)) {
      this.updateStayOpened(false, false);
    }
  }

  render() {
    let { content, children, placement, onClickShowPopover } = this.props
    return (
      <React.Fragment>
        {onClickShowPopover ? <span onClick={this.handleMouseEnter} ref={this._child}>
          {children}
        </span>
          :
          <span onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} ref={this._child}>
            {children}
          </span>
        }
        <Overlay
          show={this.state.showPopover}
          placement={placement}
          flip={true}
          target={this._child}
          shouldUpdatePosition={true}
        >
          {onClickShowPopover ?
            <Popover
              onClick={() => {
                clearTimeout(this.setTimeoutLeaveConst);
                this.setState({ showPopover: true })
              }}
              
              id='popover'
            >
              {this.state.showPopover && <div ref={this._content}>{content}</div>}
            </Popover>
            :
            <Popover
              onMouseEnter={() => {
                clearTimeout(this.setTimeoutLeaveConst);
                this.setState({ showPopover: true })
              }}
              onMouseLeave={this.handleMouseLeave}
              id='popover'
            >
              {this.state.showPopover && <div ref={this._content}>{content}</div>}
            </Popover>
          }
        </Overlay>
      </React.Fragment>
    )
  }
}

TooltipWithAction.defaultProps = {
  delay: 0
}

TooltipWithAction.propTypes = {
  delay: PropTypes.number,
  onMouseEnter: PropTypes.func,
  children: PropTypes.element.isRequired,
  content: PropTypes.node
}