import Carousel from "react-spring-3d-carousel";
import { useState, useEffect } from "react";
import { config } from "react-spring";
import "./Carousel.css";
import loadArrow from "../../res/images/icons/cached-filled.svg";

export default function CarouselComponent(props) {
  const table = props.cards.map((element, index) => {
    return { ...element, onClick: () => setGoToSlide(index) };
  });

  const [offsetRadius, setOffsetRadius] = useState(2);
  const [showArrows, setShowArrows] = useState(false);
  const [goToSlide, setGoToSlide] = useState(0);
  const [cards] = useState(table);

  useEffect(() => {
    setOffsetRadius(props.offset);
    setShowArrows(props.showArrows);

    const interval = setInterval(() => {
      const nextSlide = (goToSlide + 1) % cards.length;
      setGoToSlide(nextSlide);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [props.offset, props.showArrows, cards.length, goToSlide]);

  return (
    <div
      id="carouselContent"
      style={{ width: props.width, height: props.height, margin: props.margin }}
    >
      <h3><img className="rotate-img" src={loadArrow} alt="" />{props.secondTitle}</h3>
      <h1>{props.mainTitle}</h1>
      <Carousel
        slides={cards}
        goToSlide={goToSlide}
        offsetRadius={offsetRadius}
        showNavigation={showArrows}
        animationConfig={config.gentle}
      />
      <div id="slideInfos">
        <div id="dots">
          {cards.map((card, index) => {
            return (
              <div
                className={`dot ${index === goToSlide ? "dot-selected" : ""}`}
                id={index}
                onClick={() => setGoToSlide(index)}
              ></div>
            );
          })}
        </div>

        <h2>{props.cards[goToSlide].title}</h2>
        <p>{props.cards[goToSlide].text}</p>
      </div>
    </div>
  );
}
