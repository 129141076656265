import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Filter,
  SelectInput,
  SearchInput,
  BooleanField,
  TopToolbar,
  DeleteButton,
  SimpleForm,
  TextInput,
  required,
  Create,
  Edit,
  TabbedForm,
  ReferenceManyField,
  ReferenceInput,
  AutocompleteInput,
  Button,
  Link,
  BooleanInput,
  useGetRecordId,
  useGetList,
  SelectArrayInput,
  ReferenceArrayInput,
  useNotify,
  useRedirect,
  useRecordContext,
  NumberInput,
  EditButton,
  ShowButton,
  ListButton,
  Show,
  SimpleShowLayout,
} from "react-admin";

import AddIcon from "@mui/icons-material/Add";
import { Box, Hidden, Typography } from "@mui/material";
import { getUrlParameters } from "./SectionList";
import { BackButton } from "./elements/CustomFields";

const ReportFilters = (props) => {
  var categoryFilterChoices = [];
  const { data, isLoading, error, refetch } = useGetList("categories");
  data?.map((category) => {
    categoryFilterChoices.push({ id: category.id, name: category.title });
  });

  return (
    <Filter {...props}>
      <SearchInput source="q" className="remove-line-height" alwaysOn />
      <SelectInput
        choices={categoryFilterChoices}
        label="Category"
        source="category"
        allowEmpty={false}
        isLoading={isLoading}
      />
    </Filter>
  );
};

const ReportShowButton = ({ record }) => [
  <EditButton label="Edit" record={record} />,
  <ShowButton label="Show" record={record} />,
];

export const ReportList = (props) => (
  <>
    <List filters={<ReportFilters />} {...props} perPage={25}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField label="Report ID" source="id" />
        <TextField label="Type" source="scope_type" />
        <TextField label="Name" source="title" />
        <TextField label="Display order" source="display_order" />
        <BooleanField
          label="Enabled"
          source="is_enabled"
          valueLabelTrue="active"
          valueLabelFalse="inactive"
        />
        <ReportShowButton />
      </Datagrid>
    </List>
  </>
);

const ReportShowActions = () => (
  <TopToolbar>
    <EditButton />
    {/* Add your custom actions */}
    <ListButton />
  </TopToolbar>
);

export const ReportShow = (props) => (
  <Show actions={<ReportShowActions />}>
    <SimpleShowLayout>
      <TextField label="Report ID" source="id" />
      <TextField label="Type" source="scope_type" />
      <TextField label="Name" source="title" />
      <TextField label="Display order" source="display_order" />
      <BooleanField
        label="Enabled"
        source="is_enabled"
        valueLabelTrue="active"
        valueLabelFalse="inactive"
      />
    </SimpleShowLayout>
  </Show>
);

export const ReportCreate = () => (
  <Create resource="reports">
    <BackButton to={"/reports"} />
    <SimpleForm>
      <SelectInput
        source="scope_type"
        label="Type"
        validate={[required()]}
        choices={[
          { id: "PROJECT", name: "PROJECT" },
          { id: "DATA", name: "DATA" },
        ]}
      />
      <TextInput source="title" label="Name" validate={[required()]} />
      <NumberInput label="Display Order" source="display_order" />

    </SimpleForm>
  </Create>
);

const ReportEditActions = () => (
  <TopToolbar>
    <ShowButton />
    {/* Add your custom actions */}
    <ListButton />
  </TopToolbar>
);

const ReportAccountsEmptyState = () => {
  return (
    <Box sx={{ p: "30px" }}>
      <Typography
        sx={{
          fontFamily: "Inter",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "normal",
          letterSpacing: "0.15px",
          mt: "5px",
          mb: "8px",
        }}
      >
        The account selection is currently empty, indicating that all accounts
        have access to this report.
      </Typography>
    </Box>
  );
};

const reportAccountsRowClick = (id, resource, record) => {
  return `/accounts/${record.account_id}/show`;
};

export const ReportEdit = (props) => {
  const reportId = useGetRecordId();
  const sectionRowClick = (id, resource, record) =>
    `/sections/${id}/edit?reportId=${reportId}`;

  return (
    <Edit mutationMode="pessimistic" actions={<ReportEditActions />}>
      <BackButton to={"/reports"} />
      <TabbedForm>
        <TabbedForm.Tab label="General">
          <SelectInput
            source="scope_type"
            label="Type"
            validate={[required()]}
            choices={[
              { id: "PROJECT", name: "PROJECT" },
              { id: "DATA", name: "DATA" },
            ]}
          />
          <TextInput
            size="medium"
            source="title"
            label="Name"
            validate={[required()]}
            sx={{ width: "50%" }}
          />
          <NumberInput label="Display Order" source="display_order" />

          <BooleanInput
            label="Enabled"
            source="is_enabled"
            helperText="The report is enabled to all users"
          />

          <ReferenceArrayInput
            source="product_category_ids"
            reference="categories"
          >
            <SelectArrayInput optionText="title" />
          </ReferenceArrayInput>


        </TabbedForm.Tab>
        <TabbedForm.Tab label="Sections">
          <ReferenceManyField reference="sections" target="id" label="Sections">
            <Button
              component={Link}
              to={`/sections/create?reportId=${reportId}`}
              color="primary"
            >
              Create new section
            </Button>
            <Datagrid
              rowClick={sectionRowClick}
              sx={{ width: "100%" }}
              bulkActionButtons={false}
            >
              <TextField label="ID" source="id" sortable={false} />
              <TextField label="Title" source="title" sortable={false} />
              <TextField
                label="Max Tokens"
                source="max_tokens"
                sortable={false}
              />
              <TextField
                label="Order"
                source="display_order"
                sortable={false}
              />
              <TextField label="Prompt" source="prompt" sortable={false} />
            </Datagrid>
          </ReferenceManyField>
        </TabbedForm.Tab>

        <TabbedForm.Tab label="Accounts" path="accounts">
          <ReferenceManyField
            reference="report-accounts"
            target="id"
            label="Account"
          >
            <Datagrid
              rowClick={reportAccountsRowClick}
              empty={<ReportAccountsEmptyState />}
              bulkActionButtons={false}
            >
              <TextField label="ID" source="id" />
              <TextField label="Account ID" source="account_id" />
              <TextField label="Report ID" source="report_id" />
              <TextField label="Name" source="account_name" />
              <TextField label="Created At" source="created_at" />
              <DeleteButton redirect="" />
            </Datagrid>
          </ReferenceManyField>
          <CreateRelatedAccountButton />
        </TabbedForm.Tab>
      </TabbedForm>
    </Edit>
  );
};

export const CreateRelatedAccountButton = () => {
  const report = useRecordContext();
  return (
    <>
      <Button
        component={Link}
        to={`/report-accounts/create?reportId=${report.id}`}
        color="primary"
        label="Create"
      >
        <AddIcon />
      </Button>
    </>
  );
};

// Note we declared the function outside the component to avoid rerenders

const optionRenderer = (choice) => `${choice.id} - ${choice.account_name}`;

export const AccountReportCreate = () => {
  const notify = useNotify();
  const redirect = useRedirect();

  const urlParams = getUrlParameters();
  const reportId = urlParams["reportId"];

  const onSuccess = () => {
    // display a notification to confirm the creation and redirect to report accounts list
    notify("ra.notification.created");
    redirect(`/reports/${reportId}/accounts`);
  };

  return (
    <Create mutationOptions={{ onSuccess, meta: { reportId: reportId } }}>
      <SimpleForm>
        <Hidden source="report_id" />
        <ReferenceInput
          label="Account"
          source="account_id"
          reference="accounts"
          sort="ASC"
        >
          <AutocompleteInput optionText={optionRenderer} optionValue="id" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
