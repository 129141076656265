import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import HttpHelper from '../../app/helpers/HttpHelper';
import { classService } from '../../app/services/class.service';
import Loader from '../loader/Loader';
import Modal from '../modal/Modal';


class ClassModal extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            classId: props.classItem.id,
            classItem: null,
            isLoaded: false
        };
        this.modalRef = React.createRef();
        this.fetchData = this.fetchData.bind(this);
    }

    componentDidMount () {
        this._ismounted = true;
        this.fetchData();
    }

    componentWillUnmount () {
        this._ismounted = false;
    }

    fetchData () {
        classService.get.get(
            this.state.classId,
            (response) => {
                this.setState({ isLoaded: true, classItem: response });
            },
            (error) => {
                if (this._ismounted) {
                    if (this.modalRef && this.modalRef.current) {
                        this.modalRef.current.onModalCloseClick();
                    }
                    this.props.addToast(HttpHelper.getErrorMessage(error), { type: 'error', autoDismiss: true });
                }
            }
        );
    }

    render () {
        const { t, classItem } = this.props;
        const nbKeywords = (classItem.keywords > 1) ? t('{{count}} attributes', { count: classItem.keywords }) : t('{{count}} attribute', { count: classItem.keywords });
        return (
            <Modal ref={this.modalRef} onClose={this.props.onClose}>
                <div className="font-size-30 font-inter-700 color-000 padding-bottom-5">{classItem.title}</div>
                {this.state.isLoaded &&
                    <Fragment>
                        <div className="font-size-15 color-7f7f7f padding-bottom-16">{nbKeywords}</div>
                        <textarea disabled={true} className="width-percent-100" style={{ height: "360px" }} defaultValue={this.state.classItem.keywords_txt} />
                    </Fragment>
                }
                {!this.state.isLoaded &&
                    <div className="text-align-center padding-vertical-30">
                        <Loader />
                    </div>
                }
            </Modal>
        );
    }
}

export default withTranslation()(ClassModal);