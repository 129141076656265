import { SessionStorageService } from "../services/storage.service";
import IntercomHelper from "./IntercomHelper";
import StringHelper from "./StringHelper";

class SignupHelper { }

SignupHelper.getUser = () => {
    let currentUser = SessionStorageService.getJson('signup-user');
    if(!currentUser){
        currentUser = {
            email: '',
            firstname: '',
            lastname: '',
            company_name: '',
            invitation_token: null,
            plan: null,
            freq: null,
        };
    }
    return currentUser;
}
SignupHelper.updateUser = (update) => {
    let currentUser = Object.assign(SignupHelper.getUser(), update);
    IntercomHelper.updateIntercomUser(currentUser, true);
    return SessionStorageService.setJson('signup-user', currentUser);
}

SignupHelper.deleteUser = () => {
    return SessionStorageService.delete('signup-user');
}

SignupHelper.getCurrentStep = () => {
    let currentUser = SignupHelper.getUser();
    if(currentUser.email && currentUser.firstname && currentUser.lastname && currentUser.company_name){
        return 3;
    }
    if(currentUser.email && currentUser.firstname && currentUser.lastname){
        return 2;
    }
    return 1;
}

SignupHelper.guessCompanyName = () => {
    let currentUser = SignupHelper.getUser();
    if(!currentUser.email){
        return '';
    }
    const first_split = currentUser.email.split("@")[1];
    const second_split = first_split.split(".");
    if(['gmail', 'outlook', 'icloud', 'yahoo'].includes(second_split[0].toLowerCase())){
        return '';
    }
    return StringHelper.capitalizeFirstLetter(second_split[0]);
}


export default SignupHelper;