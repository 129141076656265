import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { OverlayTrigger, Tooltip as BSTooltip } from 'react-bootstrap'
import './tooltip.css';
export const EllipsisTooltip = ({ children, content, placement, defaultShow, ...props }) => {

  const textElementRef = useRef();

  const compareSize = () => {
    if (textElementRef.current) {
      const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
      setHover(compare);
    }
  };


  useEffect(() => {
    setTimeout(() => {
      compareSize();
    }, 100);
  });


  useEffect(() => () => {
    window.removeEventListener('resize', compareSize);
  }, []);


  const [hoverStatus, setHover] = useState(false);

  if(!children){
    return null;
  }

  if (!content) {
    return children;
  }

  return (
    <div className="c-ellipsis-tooltip" {...props}>
      <OverlayTrigger
        trigger={hoverStatus ? ['hover', 'focus'] : ''}
        placement={placement}
        flip={true}
        defaultShow={defaultShow}
        overlay={<BSTooltip><div className="base-tooltip z-index-9">
        <div className="tooltip-content">{content}</div></div></BSTooltip>}
      >
        <div className="c-tooltip__trigger" ref={textElementRef}>{children}</div>
      </OverlayTrigger>
    </div>
  )
}

EllipsisTooltip.propTypes = {
  children: PropTypes.node,
  content: PropTypes.node,
  placement: PropTypes.string,
}

export default EllipsisTooltip
