import '../unconnected.css';
import React from 'react';
import { Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import {userActions} from "../../../app/actions";



class LogoutView extends React.Component {
    constructor(props) {
        super(props);
        this.props.dispatch(userActions.logout());
    }
    render() {
        const { from } = { from: { pathname: "/login" } };
        return <Redirect to={from} />;
    }
}

function mapStateToProps(state) {
    const { loggingIn } = state.authentication;
    const { alert } = state;
    return {
        alert,
        loggingIn
    };
}

export default connect(mapStateToProps)(LogoutView);