import React from 'react';
import { withTranslation } from 'react-i18next';
import Loader from '../../../../components/loader/Loader';
import Modal from '../../../../components/modal/Modal';
import { SETTINGS_PLAN_AND_BILLING } from '../../../../app/routing';

class DeletePaymentMethodModal extends React.Component {
    constructor(props) {
        super(props);

        this.modalRef = React.createRef();

        this.onCancelClick = this.onCancelClick.bind(this);
        this.onRedirect = this.onRedirect.bind(this)
    }

    onCancelClick() {
        if (this.modalRef && this.modalRef.current) {
            this.modalRef.current.onModalCloseClick();
        }
    }

    onRedirect(e) {
        e.preventDefault();
        this.props.onClose()
        this.props.history.push(SETTINGS_PLAN_AND_BILLING('Overview'));
        window.location.reload()
    }

    render() {
        const { t } = this.props;
        return (
            <Modal ref={this.modalRef} display={this.props.display} onClose={this.props.onClose}>
                <div className="font-size-30 font-inter-700 color-000 padding-bottom-30">{t('Remove payment method?')}</div>
                <div className="popup-message">
                    <div className="font-size-18 color-7f7f7f">{t('When you remove a payment method, be sure to set a valid one before the next payment is due.')}</div>
                    <div className="font-size-18 font-weight-bold color-7f7f7f padding-top-20">
                        {t('Note {{double_point}} If you’re trying to change your plan, you can do that from the ', { double_point: ":" })}
                        <span onClick={this.onRedirect} className="link-blue">{t('billing overview page')}.</span>
                    </div>
                </div>
                <form>
                    <div className="overflow-hidden">
                        {!this.props.loading &&
                            <div className="float-right padding-top-37">
                                <button className="button backgroundless" type="button" onClick={this.onCancelClick}>{t('Cancel')}</button>
                                <button className="button backgroundless margin-left-5" onClick={this.props.onDeleteItem}>{t('Remove')}</button>
                            </div>
                        }
                        {this.props.loading &&
                            <div className="float-right" style={{ padding: "2px 0" }}>
                                <Loader />
                            </div>
                        }
                    </div>
                </form>
            </Modal>
        );
    }
}

export default withTranslation()(DeletePaymentMethodModal);