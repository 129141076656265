class WebsiteHelper { }

WebsiteHelper.resetTidioZIndex = () => {
    const element = document.getElementById('tidio-chat-iframe');
    if(element){
        element.style.zIndex = 0
    }
}


export default WebsiteHelper;