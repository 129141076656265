import React, { Fragment } from 'react';
import './tooltip.css';
import { withTranslation } from "react-i18next";
class PercentTooltipContent extends React.Component {
  render() {
    const { t, item, percent, totalObs, percentValue} = this.props;
    let classNames = "padding-left-5 text-align-right"
    if (percent === "positive") {
      classNames += " positive-percent"
    }
    else if (percent === "negative") {
      classNames += " negative-percent"
    }
    return (
      <Fragment>
          <div className="content-column flex-tooltip">
            <div className="padding-right-5">
              {percent === "positive" && <div className="font-weight-600 font-size-18">{t('Positive data')}</div>}
              {percent === "negative" && <div className="font-weight-600 font-size-18">{t('Negative data')}</div>}
              <div className="font-weight-400 color-7f7f7f font-size-13 margin-top-3">{t('Out of ')}{totalObs}{t(' overall')}</div>
            </div>
            <div className={classNames}>{percentValue}</div>
          </div>
      </Fragment>
    );
  }
}
export default withTranslation()(PercentTooltipContent);