import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { countries } from "../app/constants";

export default function CountrySelect({
  title = "Country",
  sx = { width: 300 },
  onChange = () => null,
  displayPhone = false,
  MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 250,
      },
    },
  },
}) {
  return (
    <Autocomplete
      id="country-select-demo"
      sx={sx}
      options={countries}
      MenuProps={MenuProps}
      autoHighlight
      getOptionLabel={(option) => option.label}
      onChange={(event, values) => onChange(values)}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            alt=""
          />
          {option.label} ({option.code}) {displayPhone && `+ ${option.phone}`}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ fontFamily: "Inter" }}
          label={title}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}
