import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link, useLocation } from "react-router-dom";
import { HOME, PROJECT_DETAIL, PROJECT_REPORT } from "../../app/routing";

export default function Breadcrumb({ project }) {
  const { id, name } = project;
  
  const location = useLocation();
  

  return (
    <Stack spacing={2}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {location.pathname.startsWith("/project") && (
          <Link
            to={HOME}
            className="font-size-16 link-b4b4b4 transition-color"
          >
            {"Projects"}
          </Link>
        )}
        {location.pathname.startsWith(`/project/${id}`) && (
          <Link
            to={PROJECT_DETAIL(id)}
            className="font-size-16 link-b4b4b4 transition-color"
          >
            {name}
          </Link>
        )}
        {location.pathname.startsWith(`/project/${id}/report/insights`) && (
          <Link
            to={PROJECT_REPORT(id)}
            className="font-size-16 link-b4b4b4 transition-color"
          >
            {"Report"}
          </Link>
        )}
      </Breadcrumbs>
    </Stack>
  );
}
