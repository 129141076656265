import React, { Fragment } from "react";
import AllIndicators from "../../components/adminIndicators/AllIndicators";
import SalesChart from "../../components/adminSalesChart/adminSalesChart";
import AdminMediaByTypeChart from "../../components/adminSalesChart/adminMediaTypeChart";
import PlanChart from "../../components/adminSalesChart/PlanChart";
import UserRegistrationChart from "../../components/adminSalesChart/UserRegistrationChart";
import ProjectStatusChart from "../../components/adminSalesChart/ProjectStatusChart";
import ProductCategoryUseChart from "../../components/adminSalesChart/ProductCategoryUseChart";

class BackOfficeDashboard extends React.Component {
  render() {
    return (
      <Fragment>
        <AllIndicators />
        <div>
          <div className="doughnutGrid">
            <ProductCategoryUseChart />
            <PlanChart />
            <ProjectStatusChart />
            <AdminMediaByTypeChart />
          </div>
          <div className="lineOrBarGrid">
            <UserRegistrationChart />
            <SalesChart className="barGraph" year={new Date().getFullYear()} />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default BackOfficeDashboard;
