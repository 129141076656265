import React, { Fragment } from 'react';
import '../form.css';


class RadioGroupField extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
        this.renderErrorMessage = this.renderErrorMessage.bind(this);
    }


    renderErrorMessage(errorMessage) {
        if (errorMessage) {
            return (
                <div className="form-error font-work-sans-400 color-fd3e7b font-size-12 text-align-right">
                    {errorMessage}
                </div>
            );
        }
        return null;
    }

    render() {
        let formElementClassName = ["form-element"];
        if (this.state.is_focused) {
            formElementClassName.push('focused');
        }
        if (this.props.errorMessage) {
            formElementClassName.push('error');
        }
        if (this.props.formElementClassName) {
            formElementClassName.push(this.props.formElementClassName);
        }
        const baseId = `rgi-id-${this.props.name}-`;
        return (
            <div className={formElementClassName.join(' ')}>
                {!this.props.hideLabel && (
                    <Fragment>
                    <label className="font-inter-600 font-size-15 color-969696">
                        {this.props.label}
                    </label>
                    <div className="padding-top-14" />
                    </Fragment>
                )}
                {this.props.options.map((item, i) => {
                    const parentClass = (this.props.disabled) ? 'full-content-radio disabled' : 'full-content-radio'
                    return (
                        <div className={parentClass} key={`rgi-key-${this.props.name}-${i}`}>
                            <div className="content-radio" style={this.props.style}>
                                <input 
                                    type="radio" 
                                    id={`${baseId}-${i}`} 
                                    value={item.value} 
                                    label={item.label} 
                                    required={this.props.required}
                                    name={this.props.name} 
                                    onChange={this.props.onChange} 
                                    defaultChecked={item.value === this.props.defaultValue} 
                                    disabled={this.props.disabled} 
                                    />
                                <label className="fake-radio" htmlFor={`${baseId}-${i}`} />
                            </div>
                            <label htmlFor={`${baseId}-${i}`} className="font-inter-00 font-size-15 color-000 padding-left-10 display-inline-block vertical-align-middle">{item.label}</label>
                        </div>
                    );
                })}
                {this.renderErrorMessage(this.props.errorMessage)}
            </div>
        );
    }
}

RadioGroupField.defaultProps = {
    name: null,
    label: null,
    options: []
}

export default RadioGroupField;