import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { withTranslation } from 'react-i18next';
import { backofficeService } from '../../app/services/backoffice.service';
import Loader from '../../components/loader/Loader';
import { chartColors } from "./adminMediaTypeChart";

ChartJS.register(ArcElement, Tooltip, Legend);

class PlanChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      planDetail: {
        nbFreeAccount: null,
        nbProAccount: null,
        nbBusinessAccount: null,
        nbEnterpriseAccount: null,
      },

      planDetailsIsLoaded: false,
    };
  }

  componentDidMount() {
    this.fetchPlanData();
  }

  fetchPlanData() {
    backofficeService.getPlanInfo(
      (response) => {
        this.setState({
          planDetail: {
            nbFreeAccount: response.nb_free_acount,
            nbProAccount: response.nb_pro_acount,
            nbBusinessAccount: response.nb_business_acount,
            nbEnterpriseAccount: response.nb_enterprise_acount,
          },
          planDetailsIsLoaded: true,
        });
      },
      (error) => {
        console.log(error);
        this.setState({
          planDetailsIsLoaded: true,
        });
      }
    );
  }
  option() {
    const options = {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        legend: {
          position: "bottom",
          align: "center",
          labels: {
            boxWidth: 25,
          },
        },
      },
    };

    return options;
  }

  render() {
    const { t } = this.props;
    const data = {
      labels: ["Free", "Pro", "Business", "Enterprise"],
      datasets: [
        {
          label: "Plan",
          data: [
            this.state.planDetail.nbFreeAccount,
            this.state.planDetail.nbProAccount,
            this.state.planDetail.nbBusinessAccount,
            this.state.planDetail.nbEnterpriseAccount,
          ],
          backgroundColor: chartColors,
          borderWidth: 1,
        },
      ],
    };

    return (
      <div className="doughnutChartBox">
        {!this.state.planDetailsIsLoaded && <Loader />}
        {this.state.planDetailsIsLoaded && (
          <div className="chartTitle">{t("Plan")}</div>
        )}
        {this.state.planDetailsIsLoaded && (
          <Doughnut
            data={data}
            className="chart chart-modified"
            options={this.option()}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(PlanChart);
