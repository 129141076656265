import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import Loader from '../../../components/loader/Loader';


class SettingsSaveBar extends React.Component {
    render() {
        const { t, nbChanges, loading } = this.props;
        let classNames = ['content-settings-save-bar', 'transition-opacity'];
        let changesLibelles = '';
        if (nbChanges && nbChanges > 0) {
            classNames.push('visible');
            changesLibelles = (nbChanges > 1) ? t('You’ve change {{count}} settings.', { count: nbChanges }) : t('You’ve change {{count}} setting.', { count: nbChanges });
        }
        return (
            <div className={classNames.join(' ')}>
                <div className="padding-vertical-15 padding-horizontal-20">
                    {loading && <div className="text-align-left padding-vertical-2"><Loader /></div>}
                    {!loading && (
                        <Fragment>
                            <button className="button" onClick={this.props.saveChanges}>
                                {t('Save')}
                            </button>
                            <button className="button light-blue margin-left-8 margin-right-15" onClick={this.props.cancelChanges}>
                                {t('Cancel')}
                            </button>
                            <span className="display-inline-block vertical-align-middle font-inter-500 font-size-15 color-7f7f7f changes-libelle">{changesLibelles}</span>
                        </Fragment>
                    )}
                </div>
            </div>
        );
    }
}

export default withTranslation()(SettingsSaveBar);