import * as React from "react";
import {
  List,
  Datagrid,
  SearchInput,
  Loading,
  useListContext,
} from "react-admin";
import {
  DateFieldForTimestamp,
  ScoreField,
  TextFieldEllipsis,
} from "./elements/CustomFields";
import IconUrlField from "../../components/form/field/IconUrlField";

const postFilters = [
  <SearchInput source="q" className="remove-line-height" alwaysOn />,
];

const CaptionExpand = ({ id, record, resource }) => (
  <div className="padding-vertical-10">{record.sentence}</div>
);

export const UpdatedScoreList = (props) => (
  <List
    filters={postFilters}
    {...props}
    sort={{ field: "score_updated_at_timestamp", order: "DESC" }}
    perPage={25}
  >
    <MyDatagrid />
  </List>
);

const MyDatagrid = () => {
  const { data, isLoading, isFetching } = useListContext();

  if (isLoading || isFetching) {
    return <Loading />;
  }

  return (
    <Datagrid bulkActionButtons={false} expand={<CaptionExpand />}>
      <DateFieldForTimestamp
        label="Updated at"
        source="score_updated_at_timestamp"
      />
      <TextFieldEllipsis
        label="Product"
        source="product"
        style={{ maxWidth: "200px" }}
      />
      <TextFieldEllipsis label="Observation" source="sentence" />
      <TextFieldEllipsis label="Category" source="product_category" />
      <IconUrlField label="Link" source="data" />
      <ScoreField label="IA score" source="initial_score" />
      <ScoreField label="Updated score" source="score" />
    </Datagrid>
  );
};
