import React from 'react';
import './footer.css';
import { withTranslation } from 'react-i18next';

class FooterUnconnected extends React.Component {

    render() {
        const { children } = this.props;
        return (
            <div className="footer-unconnected font-inter-500 font-size-13 color-b4b4b4">
                {!!children && children}
            </div>
        );
    }
}

export default withTranslation()(FooterUnconnected);