import React from "react";
import { Helmet } from "react-helmet";
import { globalConstants } from "../../app/constants";
import { connect } from "react-redux";
import Header from "../../components/header/Header";
import LoadingView from "../loading/LoadingView";
import { withTranslation } from "react-i18next";

import { Admin, Resource, localStorageStore } from "react-admin";
import authProvider from "./providers/AuthProvider";
import dataProvider from "./providers/DataProvider";
import dashboard from "./dashboard";
import { UserList, UserShow } from "./UserList";
import { AccountList, AccountShow } from "./AccountList";
import { ProjectList } from "./ProjectList";
import { MediaList } from "./MediaList";
import { UpdatedScoreList } from "./UpdatedScoreList";
import UserHelper from "../../app/helpers/UserHelper";

import NotFoundView from "../../views/errors/NotFoundView";

import "../../res/style/admin.css";
import { AdminCustomLayout } from "./elements/CustomLayouts";
import { KPIList } from "./KPIList";
import {
  AccountReportCreate,
  ReportCreate,
  ReportEdit,
  ReportList,
  ReportShow,
} from "./ReportList";
import { SectionCreate, SectionEdit, SectionList } from "./SectionList";

import {
  ServiceList,
  ServiceEdit,
  ServiceShow,
  AccountProductSourceCreate,
} from "./ServiceList";
import {
  AccountClasseCreate,
  ClasseCreate,
  ClasseEdit,
  ClasseList,
  ClasseShow,
} from "./ClasseList";
import { PromptTestCreate } from "./PromptTest";

class BackOfficeView extends React.Component {
  constructor(props) {
    super(props);

    const userIsCorellis = UserHelper.userIsCorellis();
    this.state = {
      isLoaded: false,
      isAuthenticated: userIsCorellis,
    };

    this.updatedScoreCustomOptions = { label: "Updated score" };
  }

  componentDidMount() {
    this._ismounted = true;

    this.setState({
      isLoaded: true,
    });
    document.documentElement.classList.add("background-color-fafafa");
  }

  componentWillUnmount() {
    this._ismounted = false;
    document.documentElement.classList.remove("background-color-fafafa");
  }

  render() {
    const { t } = this.props;
    let page_title = `${t("Back Office")} - ${globalConstants.WEBSITE_TITLE}`;

    if (this.state.isLoaded) {
      if (!this.state.isAuthenticated) {
        return <NotFoundView />;
      }
      return (
        <div className="admin-view">
          <Helmet>
            <title>{page_title}</title>
          </Helmet>
          {/* <Header /> */}

          <Header withoutFake={true} />

          <Admin
            store={localStorageStore()}
            basename="/admin"
            dashboard={dashboard}
            layout={AdminCustomLayout}
            authProvider={authProvider}
            dataProvider={dataProvider}
          >
            <Resource name="users" list={UserList} show={UserShow} />
            <Resource
              name="accounts"
              list={AccountList}
              show={AccountShow}
              edit={AccountShow}
            />
            <Resource
              hasShow={false}
              name="reports"
              list={ReportList}
              create={ReportCreate}
              edit={ReportEdit}
              show={ReportShow}
            />
            <Resource
              hasShow={false}
              name="sections"
              list={SectionList}
              create={SectionCreate}
              edit={SectionEdit}
            />
            <Resource
              hasShow={false}
              name="classes"
              list={ClasseList}
              show={ClasseShow}
              create={ClasseCreate}
              edit={ClasseEdit}
            />
            <Resource
              hasShow={false}
              name="prompt-test"
              create={PromptTestCreate}
            />
            <Resource name="analytics" list={KPIList} />
            <Resource name="projects" list={ProjectList} />
            <Resource name="data-sources" list={MediaList} />
            <Resource
              name="services"
              list={ServiceList}
              edit={ServiceEdit}
              show={ServiceShow}
            />
            <Resource
              name="updated-score"
              options={this.updatedScoreCustomOptions}
              list={UpdatedScoreList}
            />
            <Resource
              name="service-accounts"
              create={AccountProductSourceCreate}
            />
            <Resource name="report-accounts" create={AccountReportCreate} />
            <Resource name="classe-accounts" create={AccountClasseCreate} />
          </Admin>
        </div>
      );
    }
    return <LoadingView title={page_title} withHeader={true} />;
  }
}

function mapStateToProps(state) {
  return {};
}

export default withTranslation()(connect(mapStateToProps)(BackOfficeView));
