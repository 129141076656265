import * as React from "react";
import { List, Datagrid, TextField, Filter, SearchInput } from "react-admin";

const KPIFilters = (props) => {
  return (
    <Filter {...props}>
      <SearchInput source="q" className="remove-line-height" alwaysOn />
    </Filter>
  );
};

export const KPIList = (props) => (
  <List
    filters={<KPIFilters />}
    {...props}
    perPage={25}
    sort={{ field: "month", sortOrder: "ASC" }}
  >
    <Datagrid bulkActionButtons={false}>
      <TextField label="Month" source="month" />
      <TextField label="Account Id" source="account_id" />
      <TextField label="Project creation" source="project_creation" />
      <TextField label="Review extraction" source="review_extraction" />
      <TextField label="Open AI call" source="open_ai_call" />
      <TextField label="Status" source="account_status" sortable={false} />
    </Datagrid>
  </List>
);
