import React, { useMemo, useCallback } from "react";
import { AreaClosed, Line, Bar } from "@visx/shape";
import { curveMonotoneX } from "@visx/curve";
import { GridRows } from "@visx/grid";
import { scaleTime, scaleLinear } from "@visx/scale";
import { Group } from "@visx/group";
import {
  withTooltip,
  TooltipWithBounds,
  defaultStyles,
} from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { LinearGradient } from "@visx/gradient";
import { AxisLeft, AxisBottom } from "@visx/axis";
import { extent, bisector } from "d3-array";
import { timeFormat } from "d3-time-format";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import "./lineAreaGraphComponents.css";
import moment from "moment";
import { useEffect } from "react";

export const background = "#fff";
export const background2 = "#fff";
export const accentColor = "#27c986";
export const accentColorDark = "#000";
const tooltipStyles = {
  ...defaultStyles,
  background,
  border: "1px solid white",
  color: "white",
};

// accessors
const getHorizontalAxis = (d) => new Date(d["date"]);
const getVerticalAxis = (d) => d["avg_score"];
const bisectDate = bisector((d) => new Date(d["date"])).left;

// util
const formatDate = timeFormat("%d %b");

export default withTooltip(
  ({
    getData,
    handleError,
    projectTitle,
    name,
    refresh = null,
    legend,
    width,
    height,
    margin = { top: 40, right: 60, bottom: 40, left: 40 },
    showTooltip,
    hideTooltip,
    tooltipData,
    tooltipTop = 0,
    tooltipLeft = 0,
    horizontalAxis,
    verticalAxis,
  }) => {
    if (width < 10) return null;

    // state
    const [timeRange, setRange] = React.useState(90);
    const [data, setData] = React.useState([]);
    const [stateRefresh, setrefresh] = React.useState(refresh);
    const [internalLoad, setInternalLoad] = React.useState(false);

    useEffect(() => {
      if (refresh !== stateRefresh || data.length === 0) {
        setrefresh(refresh);
        setInternalLoad(true)

        getData(7, timeRange, refresh)
          .then((response) => {
            if (response.data.length === 0) {
              handleError()
            } else {
              setData(response.data);
              setInternalLoad(false)
            }
          })
          .catch((error) => {
            console.log(error);
            setInternalLoad(false)
            handleError()
          });
      }
    }, [data, getData, refresh, stateRefresh, timeRange]);

    // bounds
    // this also got changed to fit with new margins
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    // scales
    const dateScale = useMemo(() =>
      scaleTime({
        // has to start at 0
        range: [0, innerWidth + margin.left],
        domain: extent(data, getHorizontalAxis),
      })
    );

    const stockValueScale = useMemo(() =>
      scaleLinear({
        range: [innerHeight + margin.top, margin.top],
        domain: extent(data, getVerticalAxis),
        nice: true,
      })
    );

    // tooltip handler
    const handleTooltip = useCallback(
      (event) => {
        let { x } = localPoint(event) || { x: 0 };
        // adjust this for margin left so tooltip doesn't end up desynced
        x -= margin.left;
        const x0 = dateScale.invert(x);
        const index = bisectDate(data, x0, 1);
        const d0 = data[index - 1];
        const d1 = data[index];
        let d = d0;
        if (d1 && getHorizontalAxis(d1)) {
          d =
            x0.valueOf() - getHorizontalAxis(d0).valueOf() >
              getHorizontalAxis(d1).valueOf() - x0.valueOf()
              ? d1
              : d0;
        }
        showTooltip({
          tooltipData: d,
          tooltipLeft: x,
          tooltipTop: stockValueScale(getVerticalAxis(d)),
        });
      },
      [showTooltip, stockValueScale, dateScale, margin.left]
    );

    const handleChange = (event) => {
      let value = event.target.value;
      setInternalLoad(true);
      setRange(value);
      getData(7, value === "all" ? null : parseInt(value), stateRefresh)
        .then((response) => {
          setData(response.data);
          setInternalLoad(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    return (
      <div
        id="LineAreaGraphComponents"
        style={{ position: "relative", width: width + 40 }}
      >
        {internalLoad && <div id="fond" />}
        <div id="header" style={{ width: width }}>
          <h3 id="title">{name}</h3>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Age</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={timeRange}
              label="Range"
              onChange={handleChange}
              IconComponent={ExpandMore}
              sx={{ "& .MuiSvgIcon-root": { color: "#7f7f7f" } }}
            >
              <MenuItem style={{ color: "#757575" }} value={"90"}>
                Last quarter
              </MenuItem>
              <MenuItem style={{ color: "#757575" }} value={"365"}>
                Last year
              </MenuItem>
              <MenuItem style={{ color: "#757575" }} value={"all"}>
                All time
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <div>
          <svg width={width} height={height}>
            <rect
              x={0}
              y={0}
              width={width}
              height={height}
              fill="url(#area-background-gradient)"
              rx={14}
            />
            {/* Wrap everything into a group shifting */}
            <Group left={margin.left}>
              {/* If placed below the chart, it won't render properly */}
              <AxisLeft
                scale={stockValueScale}
                left={4}
                stroke={"#fff"}
                tickStroke={"#fff"}
                tickLabelProps={() => ({
                  fill: "#757575",
                  fontSize: 13,
                  textAnchor: "end",
                  fontWeight: 500,
                })}
                numTicks={7}
              />
              <LinearGradient
                id="area-background-gradient"
                from={background}
                to={background2}
              />
              <LinearGradient
                id="area-gradient"
                from={"#27c986"}
                fromOpacity={0.6}
                to={"#fff"}
              />
              <GridRows
                scale={stockValueScale}
                width={innerWidth}
                strokeDasharray="1,3"
                stroke={"#eee"}
                strokeOpacity={1}
                pointerEvents="none"
              />
              <AreaClosed
                data={data}
                x={(d) => dateScale(getHorizontalAxis(d)) ?? 0}
                y={(d) => stockValueScale(getVerticalAxis(d)) ?? 0}
                yScale={stockValueScale}
                strokeWidth={1}
                stroke="url(#area-gradient)"
                fill="url(#area-gradient)"
                curve={curveMonotoneX}
              />
              {/* Adjust this for the margin, I think this should actually be top */}
              <AxisBottom
                scale={dateScale}
                top={innerHeight + margin.bottom + 5}
                stroke={"#e0e0e0"}
                tickStroke={"#e0e0e0"}
                tickLabelProps={() => ({
                  fill: "#757575",
                  fontSize: 13,
                  textAnchor: "middle",
                  verticalAnchor: "middle",
                })}
                tickFormat={formatDate}
                numTicks={5}
              />
              <Bar
                y={margin.top}
                width={innerWidth + margin.left}
                height={innerHeight}
                fill="transparent"
                rx={14}
                onTouchStart={handleTooltip}
                onTouchMove={handleTooltip}
                onMouseMove={handleTooltip}
                onMouseLeave={() => hideTooltip()}
              />
              {tooltipData && (
                <g>
                  {/* Line had to be adjusted for top margins */}
                  <Line
                    from={{ x: tooltipLeft, y: margin.top }}
                    to={{ x: tooltipLeft, y: innerHeight + margin.top }}
                    stroke={"#e0e0e0"}
                    strokeWidth={1}
                    pointerEvents="none"
                    strokeDasharray="5,2"
                  />
                  <circle
                    cx={tooltipLeft}
                    cy={tooltipTop + 1}
                    r={4}
                    fill="white"
                    fillOpacity={0}
                    stroke="white"
                    strokeOpacity={0.1}
                    strokeWidth={2}
                    pointerEvents="none"
                  />
                  <circle
                    cx={tooltipLeft}
                    cy={tooltipTop}
                    r={4}
                    fill={"white"}
                    stroke="#27c986"
                    strokeWidth={2}
                    pointerEvents="none"
                  />
                </g>
              )}
            </Group>
          </svg>

          {tooltipData && (
            <div>
              <TooltipWithBounds
                key={Math.random()}
                top={tooltipTop + 80}
                left={tooltipLeft - 5}
                style={tooltipStyles}
              >
                <div id="box">
                  <h3 className="font-size-18 color-000 font-inter-600">
                    {projectTitle}
                  </h3>
                  <p className="font-size-13 color-7f7f7f">
                    Score card at{" "}
                    {moment(getHorizontalAxis(tooltipData)).format("DD/MM/YYYY")}
                  </p>

                  <div className="col-box margin-top-12">
                    <div className="col-box-item">
                      <h3 className="font-weight-500 color-000">
                        {getVerticalAxis(tooltipData)
                          ? getVerticalAxis(tooltipData).toFixed(2)
                          : "-"}
                      </h3>
                      <div className="font-size-13 color-7f7f7f padding-top-5">
                        {"Average score"}
                      </div>
                    </div>
                    {/* <div className="col-box-item">
                                    <h4 className="font-weight-500 color-7f7f7f"><RoundedEmotion emotion={"Neutral"} /></h4>
                                    <div className="font-size-13 color-7f7f7f ">{'Top feeling'}</div>
                                </div> */}
                  </div>

                  {/* <div className="col-box">
                                <div className="col-box-item">
                                    <h3 className="positive-percent-keyword-tooltip">45%</h3> */}
                  {/* <div className="positive-percent-keyword-tooltip">{this.getPercent("positive", (Number(item.percent_pos).toLocaleString('en-US', { style: 'percent', minimumFractionDigits: 0 })), item.total)}</div> */}
                  {/* <div className="font-size-13 color-7f7f7f padding-top-5">{'Positive'}</div>
                                </div>
                                <div className="col-box-item">
                                    <h3 className="negative-percent-keyword-tooltip">28%</h3> */}
                  {/* <div className="negative-percent-keyword-tooltip">{this.getPercent("negative", (Number(item.percent_neg).toLocaleString('en-US', { style: 'percent', minimumFractionDigits: 0 })), item.total)}</div> */}
                  {/* <div className="font-size-13 color-7f7f7f padding-top-5">{'Negative'}</div>
                                </div>
                            </div> */}
                </div>

                {/* <div id="box">
                            <div id="content">
                                <h3 className="font-weight-500 color-000">{getScoreScale(tooltipData) ? getScoreScale(tooltipData) : getScoreScale(tooltipData) === 0 ? getScoreScale(tooltipData) : '-'}</h3>
                                <p style={getScoreScale(tooltipData) > 0 ? { color: background3 } : getScoreScale(tooltipData) === 0 ? { color: "#9F99B7" } : getScoreScale(tooltipData) < 0 ? { color: "#e04f77" } : { color: "black" }}>{getScoreScale(tooltipData) > 0 && <span>+</span>}<ColoredScore score={getScoreScale(tooltipData)} />%</p>
                            </div>
                                </div>*/}
              </TooltipWithBounds>
            </div>
          )}
        </div>
        <div id="legend" style={{ width: width }}>
          <div className="legend-item">
            <div id="project"></div>
            {legend.x}
          </div>
          {legend.aux_x && (
            <div className="legend-item">
              <div id="industry"></div>
              {legend.aux_x}
            </div>
          )}
        </div>
      </div>
    );
  }
);
