import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { ROUTES } from '../../../app/routing';
import Link from "../../../components/Link";
import ListOptSelect from '../../../components/listOptSelect/ListOptSelect';


class ModelsMenu extends React.Component {
    constructor(props){
        super(props);

        this.getMenuScopeList = this.getMenuScopeList.bind(this);
        this.onChangeMenuScope = this.onChangeMenuScope.bind(this);
    }

    getMenuScopeList() {
        const { t } = this.props;
        let ret = {};
        const currentValue = this.props.current;
        ret.models_menu = [
            { 
                label: t('Score models'),
                activeLabel: t('Score models'),
                value: ROUTES.MODELS_SCORE, 
                active: (currentValue === ROUTES.MODELS_SCORE),
            },
            { 
                label: t('Feeling models'),
                activeLabel: t('Feeling models'),
                value: ROUTES.MODELS_EMOTION, 
                active: (currentValue === ROUTES.MODELS_EMOTION),
            },
            { 
                label: t('Analyse & Other'),
                activeLabel: t('Analyse & Other'),
                value: ROUTES.MODELS_ANALYSE, 
                active: (currentValue === ROUTES.MODELS_ANALYSE),
            },
            { 
                label: t('Model Settings'),
                activeLabel: t('Model Settings'),
                value: ROUTES.MODEL_SETTINGS, 
                active: (currentValue === ROUTES.MODEL_SETTINGS),
            },
        ]
        return ret;
    }

    onChangeMenuScope(infos) {
        window.location.href = infos.models_menu;
    } 

    render () {
        const { t } = this.props;
        const links = [
            { label: t('Score models'), to: ROUTES.MODELS_SCORE },
            { label: t('Feeling models'), to: ROUTES.MODELS_EMOTION },
            { label: t('Analyse & Other'), to: ROUTES.MODELS_ANALYSE },
            { label: t('Model Settings'), to: ROUTES.MODEL_SETTINGS },
        ];

        return (
            <Fragment>
                <div className="padding-bottom-10">
                    <Link to={ROUTES.HOME} className="settings-button-go-back transition-opacity">
                        <span className="icon"></span>
                        <span className="font-inter-400 font-size-18">{t('Back')}</span>
                    </Link>
                </div>
                <div className="font-inter-700 font-size-28 color-000 responsive-hide-1000">{t('Models')}</div>
                <div className="padding-top-20 responsive-hide-1000" />
                <div className="content-settings-menu responsive-hide-1000">
                    {
                        links.map((item, j) => {
                            let classNames = ['font-inter-500'];
                            if (item.to === this.props.current) {
                                classNames.push('active');
                            }
                            if (item.to === "#") {
                                return (
                                    <a key={`sml-${j}`} href={item.to} className={classNames.join(' ')}>{item.label}</a>
                                );
                            }
                            return (
                                <Link key={`sml-${j}`} to={item.to} className={classNames.join(' ')}>{item.label}</Link>
                            );
                        })
                    }
                </div>
                <div className="responsive-show-1000">
                    <ListOptSelect
                        displayFamily="models_menu"
                        customClass="big-blue black"
                        defaultActive={this.props.current}
                        position="left"
                        onChange={this.onChangeMenuScope}
                        lists={this.getMenuScopeList()} />
                </div>
            </Fragment>
        );
    }
}

export default withTranslation()(ModelsMenu);