import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";
import StringHelper from "../../../app/helpers/StringHelper";
import TooltipWithAction from "../../../components/tooltip/TooltipWithAction";
import ColoredScore from "../../../components/ui/ColoredScore";
import RoundedEmotion from "../../../components/ui/RoundedEmotion";

import CollectionHelper from "../../../app/helpers/CollectionHelper";
import { uniqueId } from "lodash";
import LinedGraphTooltip from "./LinedGraphTooltip";
import Link from "../../../components/Link";

import "../projectReport.css";

class RelevantList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.calculateScorePosition = this.calculateScorePosition.bind(this);
    this.getProperData = this.getProperData.bind(this);
    this.getFlowContent = this.getFlowContent.bind(this);
    this.getDataContent = this.getDataContent.bind(this);
    this.hasBadFlow = this.hasBadFlow.bind(this);
    this.hasNeutralFlow = this.hasNeutralFlow.bind(this);
    this.hasGoodFlow = this.hasGoodFlow.bind(this);
    this.getLinedGraph = this.getLinedGraph.bind(this);
    this.getGraphTooltipContent = this.getGraphTooltipContent.bind(this);
  }

  calculateScorePosition(aScore) {
    const { min, max } = this.props;
    const range = max - min;
    aScore = Math.round(aScore * 10) / 10;
    if (range === 0) {
      return 0;
    }
    const percent = ((aScore - min) * 100) / range;
    return Math.round(percent * 10) / 10;
  }

  getProperData(data) {
    let newData = CollectionHelper.sortBy(
      Object.assign([], data),
      "score",
      "asc"
    );
    let nbs = {};
    for (const item of newData) {
      const left = this.calculateScorePosition(item["score"], true);
      item["left"] = left;
      if (!nbs.hasOwnProperty(left)) {
        nbs[left] = 0;
      }
      nbs[left] += 1;
      const doublons = nbs[left];
      item["doublons"] = nbs[left];
      if (doublons == 1) {
        item["left_css"] = `${left}%`;
      } else {
        item["left_css"] = `calc(${left}% + ${(doublons - 1) * 2}px)`;
      }
      if (item["score"] < -0.5) {
        item["type"] = "bad";
      } else if (item["score"] <= 0.5) {
        item["type"] = "neutral";
      } else {
        item["type"] = "good";
      }
    }

    return newData;
  }

  hasBadFlow(data) {
    let ret = false;
    data.forEach((element) => {
      if (element.score < -0.5) {
        ret = true;
      }
    });
    return ret;
  }

  hasNeutralFlow(data) {
    let ret = false;
    data.forEach((element) => {
      if (element.score >= -0.5 && element.score <= 0.5) {
        ret = true;
      }
    });
    if (!ret) {
      return this.hasBadFlow(data) && this.hasGoodFlow(data);
    }
    return ret;
  }

  hasGoodFlow(data) {
    let ret = false;
    data.forEach((element) => {
      if (element.score > 0.5) {
        ret = true;
      }
    });
    return ret;
  }

  getFlowContent(item, data) {
    let ret = [];
    let left = 0;
    let width = 0;
    const lowest = CollectionHelper.getLowestValue(data, "score");
    const highest = CollectionHelper.getHighestValue(data, "score");
    if (this.hasBadFlow(data)) {
      left = this.calculateScorePosition(lowest);
      width =
        highest < -0.5
          ? this.calculateScorePosition(highest) - left
          : this.calculateScorePosition(-0.5) - left;
      ret.push(
        <div
          key="flow-bad"
          className="bad"
          style={{
            width: width + "%",
            left: left + "%",
          }}
        />
      );
    }
    if (this.hasNeutralFlow(data)) {
      left =
        lowest > -0.5
          ? this.calculateScorePosition(lowest)
          : this.calculateScorePosition(-0.5);
      width =
        highest < 0.5
          ? this.calculateScorePosition(highest) - left
          : this.calculateScorePosition(0.5) - left;
      ret.push(
        <div
          key="flow-neutral"
          className="neutral"
          style={{
            width: width + "%",
            left: left + "%",
          }}
        />
      );
    }
    if (this.hasGoodFlow(data)) {
      left =
        lowest > 0.5
          ? this.calculateScorePosition(lowest)
          : this.calculateScorePosition(0.5);
      width = this.calculateScorePosition(highest) - left;
      const width_css = `calc(${width}% + 2px)`;
      ret.push(
        <div
          key="flow-good"
          className="good"
          style={{
            width: width_css,
            left: left + "%",
          }}
        />
      );
    }
    return ret;
  }

  getDataContent(item, data) {
    let ret = [];
    data.forEach((element, index) => {
      let classNames = ["line", "transition-color", element.type];
      ret.push(
        <div
          key={`lgd-${index}-${uniqueId()}`}
          className={classNames.join(" ")}
          style={{ left: element.left_css }}
        >
          <TooltipWithAction
            content={this.getGraphTooltipContent({
              item: item,
              target: element,
            })}
            placement="bottom"
          >
            <div className="content" />
          </TooltipWithAction>
        </div>
      );
    });
    return ret;
  }

  getGraphTooltipContent(element) {
    return (
      <LinedGraphTooltip
        target={element.target}
        item={element.item}
        projectId={this.props.projectId}
        getWorkspaceLinkByTarget={this.props.getWorkspaceLinkByTarget}
      />
    );
  }

  getLinedGraph(anItem) {
    const data = this.getProperData(anItem.data);
    return (
      <div className="content-lined-graph">
        <div className="content-flow">{this.getFlowContent(anItem, data)}</div>
        {!this.props.hasLimitedAccess && (
          <div className="content-data">
            {this.getDataContent(anItem, data)}
          </div>
        )}
      </div>
    );
  }

  render() {
    const { t, listItems, title } = this.props;
    return (
      <Fragment>
        <div className="font-size-18 font-inter-700 color-000 padding-bottom-30 padding-top-18">
          {t(title)}{" "}
          <span style={{ color: "#9e9e9e", fontWeight: "600" }}>
            {listItems.length}
          </span>
        </div>
        <div className="full-content-list-relevant">
          <table className="width-percent-100">
            <tbody>
              {listItems.map((anItem, j) => {
                return (
                  <tr key={`clr-${j}`}>
                    <td width={180} className="td-name">
                      <div
                        className="text-ellipsis"
                        style={{ maxWidth: "180px" }}
                      >
                        {this.props.hasLimitedAccess && (
                          <Fragment>
                            <div
                              onClick={() =>
                                this.props.getWorkspaceLinkByTarget(
                                  anItem.keyword
                                )
                              }
                              className="font-inter-500 color-000 font-size-16 underline-on-hover"
                            >
                              {StringHelper.capitalizeFirstLetter(
                                anItem.keyword
                              )}
                            </div>
                            <span className="padding-left-4 font-inter-400 color-969696 font-size-15">
                              {anItem.occurence}
                            </span>
                          </Fragment>
                        )}
                        {!this.props.hasLimitedAccess && (
                          <TooltipWithAction
                            content={this.props.getKeywordTooltipContent(
                              anItem.keyword
                            )}
                            placement="bottom"
                          >
                            <Fragment>
                              <div
                                onClick={() =>
                                  this.props.getWorkspaceLinkByTarget(
                                    anItem.keyword
                                  )
                                }
                                className="font-inter-500 color-000 font-size-16 underline-on-hover"
                              >
                                {StringHelper.capitalizeFirstLetter(
                                  anItem.keyword
                                )}
                              </div>
                              <span className="padding-left-4 font-inter-400 color-969696 font-size-15">
                                {anItem.occurence}
                              </span>
                            </Fragment>
                          </TooltipWithAction>
                        )}
                      </div>
                    </td>
                    <td className="td-graph">
                      <div className="padding-horizontal-20">
                        {this.getLinedGraph(anItem)}
                      </div>
                    </td>
                    <td
                      width={55}
                      className="text-align-right font-size-18 font-inter-500"
                    >
                      <ColoredScore
                        hasLimitedAccess={this.props.hasLimitedAccess}
                        score={anItem.average_score}
                        withHelpTooltip={true}
                        tooltipType="score"
                        tooltipScoreLibelle={t("score")}
                        tooltipItem={{
                          value: "",
                          occurence: 0,
                          score: parseFloat(anItem.average_score.toFixed(2)),
                        }}
                      />
                    </td>
                    <td
                      width={155}
                      className="text-align-left font-size-15 font-inter-400 padding-left-15 td-emotion"
                    >
                      <div style={{ position: "relative", top: "-3px" }}>
                        <RoundedEmotion
                          hasLimitedAccess={this.props.hasLimitedAccess}
                          emotion={anItem.emotion}
                          withTooltip={true}
                          projectId={this.props.projectId}
                          datasourceId={this.props.datasourceId}
                          keyword={anItem.keyword}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Fragment>
    );
  }
}

export default withTranslation()(RelevantList);
