import '../plan.css';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { ACCOUNT_PLAN, getAccountPlan, getPlanAdvantages, globalConstants } from "../../../app/constants";
import { connect } from 'react-redux';
import LoadingView from '../../loading/LoadingView';
import { withTranslation } from 'react-i18next';
import { ROUTES } from '../../../app/routing';


import HttpHelper from '../../../app/helpers/HttpHelper';
import UserHelper from '../../../app/helpers/UserHelper';

import { accountService } from '../../../app/services/account.service';
import PlanHeader from '../elements/PlanHeader';
import Switch from '../../../components/switch/Switch'
import PricingBox from '../../../components/pricingBox/PricingBox';
import { SessionStorageService } from '../../../app/services/storage.service';


class ChoosePlanView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            inlineLoading: false,
            yearly: false,
            account: null,
            currentPlan: null,
            item_list: []
        };

        this.fetchInitial = this.fetchInitial.bind(this);
        this.onChangePriceYearly = this.onChangePriceYearly.bind(this);
        this.getAdvantagesForPlan = this.getAdvantagesForPlan.bind(this);
        this.fetchPlan = this.fetchPlan.bind(this);

        const referrer = (props.location.state && props.location.state.referrer) ? props.location.state.referrer : null;
        if(!SessionStorageService.get('update_plan_referrer') && referrer){
            SessionStorageService.set('update_plan_referrer', referrer)
        }
    }

    componentDidMount() {
        this._ismounted = true;
        this.fetchInitial();
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    fetchInitial() {
        if (this._ismounted) {
            this.setState({ inlineLoading: true });
        }
        const isAdmin = UserHelper.isAdmin();
        if (!isAdmin) {
            this.props.history.push(ROUTES.PLAN_CONTACT_YOUR_ADMINISTRATOR);
        }
        accountService.getAccountData(
            (response) => {
                this.setState({
                    inlineLoading: false,
                    account: response
                });
                this.fetchPlan()
            },
            (error) => {
                if(!this._ismounted){
                    return;
                }
                this.setState({
                    isLoaded: true,
                    inlineLoading: false
                });
                this.props.addToast(HttpHelper.getErrorMessage(error), { type: 'error', autoDismiss: true });
            }
        );
    }

    async fetchPlan() {

        const url = `${globalConstants.API_URL}`;
        const finalUrl = url + ROUTES.PLAN_PRICING_WS

        fetch(finalUrl).then(response => response.json()).then(data => {
            this.setState({ item_list: data.plans, isLoaded: true })
        });
    }
    
    getAdvantagesForPlan(plan) {
        const { t } = this.props;
        const advantages = getPlanAdvantages(t);

        for (const i in advantages) {
            for (const item in this.state.item_list) {
                if (advantages[i].title === this.state.item_list[item].key) {
                    advantages[i].monthlyPrice = this.state.item_list[item].monthly_price
                    advantages[i].yearlyPrice = this.state.item_list[item].yearly_price
                    advantages[i].currency_code = this.state.item_list[item].currency_code
                }
            }
        }

        let ret = [];
        switch (plan) {
            case ACCOUNT_PLAN.FREE:
                ret.push(Object.assign(advantages.pro, { rawClass: ['column-percent-33'] }));
                ret.push(Object.assign(advantages.business, { rawClass: ['column-percent-34'] }));
                ret.push(Object.assign(advantages.enterprise, { rawClass: ['column-percent-33'] }));
                break;
            case ACCOUNT_PLAN.PRO:
                ret.push(Object.assign(advantages.business, { rawClass: ['column-percent-50'] }));
                ret.push(Object.assign(advantages.enterprise, { rawClass: ['column-percent-50'] }));
                break;
            case ACCOUNT_PLAN.BUSINESS:
                ret.push(Object.assign(advantages.enterprise, { rawClass: ['column-percent-100'] }));
                break;
            case ACCOUNT_PLAN.ENTERPRISE:
                ret.push(Object.assign(advantages.enterprise, {rawClass: ['column-percent-100']}));
                break;
            default:
                break;
        }
        return ret;
    }

    onChangePriceYearly() {
        this.setState({
            yearly: !this.state.yearly
        });
    }


    render() {
        const { t } = this.props;
        let page_title = `${t('Choose your perfect plan')} - ${globalConstants.WEBSITE_TITLE}`;
        if (this.state.isLoaded) {
            const currentPlan = getAccountPlan(this.state.account.plan_key);
            const advantages = this.getAdvantagesForPlan(currentPlan);
            return (
                <Fragment>
                    <Helmet>
                        <title>{page_title}</title>
                    </Helmet>
                    <PlanHeader history={this.props.history} title={t('Choose your')} boldTitle={t('perfect plan')} />
                    <div className="central-content pricing-showcase">
                        <div className="padding-top-20" />
                        <div className="font-inter-400 color-7f7f7f line-height-1-3 font-size-15 text-align-center padding-bottom-5">{t('Pay by month or the year, and cancel at any time')}</div>
                        <div className="text-align-right padding-bottom-8">
                            <span className="font-size-13 color-7f7f7f line-height-1-7 padding-right-7 display-inline-block vertical-align-middle">{t('Monthly')}</span>
                            <Switch className="display-inline-block vertical-align-middle" onClick={this.onChangePriceYearly} />
                            <span className="font-size-13 color-7f7f7f line-height-1-7 font-weight-700 padding-left-4 display-inline-block vertical-align-middle">{t('Yearly (Save 20%)')}</span>
                        </div>
                        <div className={`content-column no-hidden container-pricing-box content-${advantages.length}-columns`}>
                            {advantages.map((item, i) => {
                                return (<PricingBox
                                    key={`pricing-box-${i}`}
                                    planType={item.planType}
                                    title={item.title}
                                    price={item.price}
                                    monthlyPrice={item.monthlyPrice}
                                    yearlyPrice={item.yearlyPrice}
                                    yearlyMonthlyPrice={item.yearlyMonthlyPrice}
                                    subtitle={item.subtitle}
                                    subprice={item.subprice}
                                    subpriceYearly={item.subpriceYearly}
                                    advantages={item.advantages}
                                    currency_code={item.currency_code}
                                    yearly={this.state.yearly}
                                    currentPlan={currentPlan}
                                    rawClass={item.rawClass}
                                />)
                            })}
                        </div>
                        <div className="padding-top-20" />
                    </div>
                </Fragment>
            );
        }
        return <LoadingView title={page_title} />;
    }
}

function mapStateToProps(state) {
    return {};
}

export default withTranslation()(connect(mapStateToProps)(ChoosePlanView));